import React from "react";

// Customizable Area Start
import {
    Grid,
    Theme,
    withStyles,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Box,
    Button,
    LinearProgress,
    TableContainer
} from "@material-ui/core";

import ReviewItemsFromLibraryController, {
    LibraryItem,
    Props,
    configJSON,
} from "./ReviewItemsFromLibraryController.web";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout";
import { importingScreenImage } from "./assets";
// Customizable Area End

export class ReviewItemsFromLibrary extends ReviewItemsFromLibraryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { selectedLibraryItems, importingItems, uploadedItems } = this.state;
        return (
            <>
                {
                    importingItems ? 
                    (
                        <Grid container className={`${classes.MainGrid} LoadingGrid`}>
                            <Grid item className={classes.LSGridItem}>
                                <Box className={classes.LSIconBox}>
                                    <img
                                    src={importingScreenImage}
                                    alt={configJSON.mainLoadingMessage}
                                    className={classes.LSNoItemIcon}
                                    />
                                </Box>
                                <Typography className={classes.LSPercentage}>
                                    {`${selectedLibraryItems.length > 0 ? ((uploadedItems / selectedLibraryItems.length) * 100).toFixed(2) : 100}%`}
                                </Typography>
                                <LinearProgress
                                    classes={{
                                        root: classes.ProgressRoot,
                                        colorPrimary: classes.ProgressColor,
                                        bar: classes.ProgressBar,
                                    }}
                                />
                                <Typography className={classes.LSTitleText}>
                                    {configJSON.mainLoadingMessage}
                                </Typography>
                                <Typography className={classes.LSSubTitleText}>
                                    {configJSON.sub1LoadingMessage}
                                </Typography>
                                <Typography className={classes.LSSubTitleText}>
                                    {configJSON.sub2LoadingMessage}
                                </Typography>
                            </Grid>
                        </Grid>
                    ) :
                    (
                        <Grid container className={classes.MainGrid}>
                            <Grid item xs={12} className={classes.TextGrid}>
                                <Typography className="Text">
                                    {`${configJSON.reviewItemsMessage1} ${selectedLibraryItems.length} ${configJSON.reviewItemsMessage2}`}
                                </Typography>
                            </Grid>
                            <Grid item className={classes.TableGridItem}>
                                <TableContainer className="TableContainerElemet">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={`${classes.headerCell} ${classes.alignLeft}`}>
                                                {configJSON.itemName}
                                            </TableCell>
                                            <TableCell className={`${classes.headerCell} ${classes.alignCenter}`}>
                                                {configJSON.image}
                                            </TableCell>
                                            <TableCell className={`${classes.headerCell} ${classes.alignLeft}`}>
                                                {configJSON.description}
                                            </TableCell>
                                            <TableCell className={`${classes.headerCell} ${classes.alignLeft}`}>
                                                {configJSON.price}
                                            </TableCell>
                                            <TableCell className={`${classes.headerCell} ${classes.alignLeft}`}>
                                                {configJSON.category}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={classes.tableBody}>
                                        {selectedLibraryItems.map((item: LibraryItem) => (
                                            <TableRow
                                                key={item.id}
                                                className={classes.tableRow}
                                            >
                                                <TableCell className={classes.alignLeft}>
                                                    <Typography
                                                        className={classes.dataText}>{item.name}</Typography>
                                                </TableCell>
                                                <TableCell className={`${classes.imageBodyCell} ${classes.alignCenter}`}>
                                                    <img src={item.image_url} className={'image'} />
                                                </TableCell>
                                                <TableCell className={classes.descriptionBodyCell}>
                                                    <Typography
                                                        className={classes.dataText}>{item.description}</Typography>
                                                </TableCell>
                                                <TableCell className={classes.alignLeft}>
                                                    <Typography
                                                        className={classes.dataText}>{`${String(item.selected_price).trim() !== '' ? this.state.currencySymbol+item.selected_price: ''}`}</Typography>
                                                </TableCell>
                                                <TableCell className={classes.alignLeft}>
                                                    <Typography
                                                        className={classes.dataText}>{item.updatedCategories.selectedCategory}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={12} className={classes.ButtonContainer}>
                                <Button
                                    className={classes.backButton}
                                    disableTouchRipple
                                    style={{backgroundColor:'#00D65900'}}
                                    onClick={this.goBack}
                                >
                                    <Typography className="Text">
                                        {configJSON.back}
                                    </Typography>
                                </Button>
                                <Button
                                    variant="contained"
                                    className={classes.nextButton}
                                    onClick={this.importItems}
                                    style={{backgroundColor:'#00D659'}}
                                    disableRipple
                                    disableTouchRipple
                                    disableElevation
                                    disableFocusRipple
                                >
                                    <Typography className="Text">{configJSON.import}</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    )
                }
            </>)
        // Customizable Area End
    }
}

// Customizable Area Start
export const ReviewItemsFromLibraryStyle = (theme: Theme) => ({
    ZIndexOnSidebar: {
        zIndex: 1
    },
    TableGridItem: {
        display: 'flex',
        justifyContent: 'center',
        padding: 16,
        width: '100%',
        paddingBottom: 148,
        "& .TableContainerElemet":{
            '&::-webkit-scrollbar': {
                display: 'none',
              },
              '-ms-overflow-style': 'none',
              scrollbarWidth: 'none',
        }
    },
    MainGrid: {
        overflowY: "auto" as const,
        height: '100vh',
        display:'block',
        "&.LoadingGrid":{
            display:'flex',
            justifyContent:'center'
        }
    },
    TextGrid: {
        display: 'flex',
        justifyContent: 'center',
        padding: 8,
        marginTop: '4%',
        "& .Text": {
            fontFamily: "Inter",
            fontSize: 14,
            lineHeight: 1.3,
            color: "#676B7E",
            textAlign:'center'
        }
    },
    headerCell: {
        fontFamily: "Rubik",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "22px",
        color: "#363636",
        "& .Text": {
            fontFamily: "Inter",
            fontSize: 14,
            lineHeight: 1.3,
            color: "#676B7E"
        }
    },
    alignLeft: {
        textAlign: 'left' as const
    },
    alignCenter: {
        textAlign: 'center' as const
    },
    descriptionBodyCell: {
        width: '40%',
        maxWidth: '40%',
        textAlign: 'left' as const
    },
    imageBodyCell: {
        "& .image": {
            height: 36,
            width: 36,
            objectFit: 'cover' as const,
            borderRadius:4
        }
    },
    dataText: {
        fontFamily: "Inter",
        fontSize: 14,
        lineHeight: 1.3,
        color: "#334155"
    },
    tableBody: {
        border: "2px solid #ECEDF0",
        padding: 4
    },
    tableRow: {
        borderBottom: "2px solid #ECEDF0",
        backgroundColor:'#FFF'
    },
    ButtonContainer: {
        position: 'fixed' as const,
        bottom: '0%',
        right: 0,
        width: '100%',
        height: 64,
        borderTop: '2px solid #ECEDF0',
        borderLeft: '2px solid #ECEDF0',
        backgroundColor: '#FFF',
        display: 'flex',
        justifyContent: 'flex-end' as const,
        [theme.breakpoints.down('xs')]:{
            height: 54,
        },
    },
    nextButton: {
        background: "#00D659",
        textTransform: "none" as const,
        padding: "20px 42px",
        boxShadow: 'none',
        height: '100%',
        marginLeft: 12,
        borderRadius: "3px",
        marginRight: 16,
        [theme.breakpoints.down('xs')]:{
            marginRight:0,
            padding: "16px 36px",
        },
        "&:hover": {
            background: "#00D659"
        },
        "& .Text": {
            fontFamily: "Rubik",
            fontSize: "16px",
            lineHeight: "24px",
            color: "#3C3E49",
            fontWeight: 500,
        }
    },
    backButton: {
        textTransform: "none" as const,
        padding: "10px 26px",
        "& .Text": {
            fontFamily: "Rubik",
            fontSize: "16px",
            lineHeight: "24px",
            color: "#3C3E49",
            fontWeight: 400,
            textDecoration: 'underline' as const
        }
    },
    LSGridItem: {
        backgroundColor: "#FFFFFF",
        width: '630px',
        height:326,
        padding: "48px 48px 24px 48px",
        borderRadius: 10,
        marginTop: "5%",
        [theme.breakpoints.down("xs")]: {
            padding: "48px 16px",
            width:'90%',
            height:420,
        },
    },
    LSIconBox: {
        display: "flex",
        justifyContent: "center",
        marginBottom: 16,
    },
    LSNoItemIcon: {
        height: 76,
        width: 76,
        objectFit: "contain" as const,
    },
    LSPercentage:{
        fontFamily: "Inter",
        fontSize: 20,
        fontWeight: 700,
        textAlign: "center" as const,
        lineHeight: 1.5,
        color: "#5500CC",
    },
    LSTitleText: {
        fontFamily: "Inter",
        fontSize: 24,
        fontWeight: 700,
        textAlign: "center" as const,
        lineHeight: 1.5,
        color: "#363636",
        margin: 8,
    },
    LSSubTitleText: {
        fontFamily: "Inter",
        fontSize: 14,
        fontWeight: 400,
        textAlign: "center" as const,
        lineHeight: 1.3,
        color: "#646464",
    },
    ProgressRoot: {
        height: 4,
        width:276,
        borderRadius: 5,
        margin:'auto',
        [theme.breakpoints.down('xs')]:{
            width:220,
        }
    },
    ProgressColor: {
        backgroundColor: '#E2E8F0',
    },
    ProgressBar: {
        borderRadius: 5,
        backgroundColor: '#8833FF',
    },

})
export default withLayout(withStyles(ReviewItemsFromLibraryStyle)(ReviewItemsFromLibrary));
// Customizable Area End
