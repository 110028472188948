// Customizable Area Start
//@ts-nocheck
import React from "react";
import {
    Box,
    Grid,
    TextField,
    Typography,
    Button,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AdminLoginController, { Props } from "./AdminLoginController.web";
import withStyles, { CSSProperties } from "@material-ui/core/styles/withStyles";
import { Formik } from "formik";
import * as Yup from "yup";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
    typography: {
        fontFamily: "Rubik",
    }

});

const validationSchema = Yup.object().shape({
    new_password: Yup.string().min(8, 'Password must be at least 8 characters')
        .required("New password is required"),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
        .required("Confirm password is required"),
})

export class ChangePassword extends AdminLoginController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const { classes } = this.props;
        return (
            <ThemeProvider theme={theme}>
                <Grid container item lg={12} md={12} xs={12} className={classes.root}>
                    <Grid>
                        <Typography className={classes.storeName}>&lt; Store Name &gt; </Typography>
                    </Grid>
                    <Box className={classes.adminLoginBox}>
                        <Formik
                            initialValues={{
                                new_password: this.state.new_password,
                                confirm_password: this.state.confirm_password,
                            }}
                            data-testId="formik"
                            onSubmit={(values) => {
                                console.log("ChangePassword", values)
                            }}
                            validationSchema={validationSchema}
                        >
                            {({
                                errors,
                                touched,
                                values,
                                handleBlur,
                                setFieldValue,
                                handleSubmit,
                            }) => (
                                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                    <Typography
                                        component="h5"
                                        style={{
                                            fontWeight: "500",
                                            fontSize: "20px",
                                            lineHeight: "28px",
                                            paddingBottom: "20px",
                                            color: "#3C3E49"
                                        }}
                                    >
                                        Change password
                                    </Typography>
                                    <Grid
                                        item
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        className={classes.bottomSpace}
                                    >
                                        <Typography
                                            variant="h6"
                                            component="h6"
                                            className={classes.titleText}
                                        >
                                            New password
                                        </Typography>
                                        <TextField
                                            value={values.new_password}
                                            type="text"
                                            name="new_password"
                                            data-testId="new_password"
                                            className={classes.placeholderText}
                                            onChange={(e) => {
                                                this.setState({
                                                    new_password: e.target.value,
                                                });
                                                setFieldValue("new_password", e.target.value);
                                            }}
                                            error={Boolean(touched.new_password && errors.new_password)}
                                            onBlur={handleBlur}
                                            helperText={touched.new_password && errors.new_password}
                                            fullWidth
                                            variant="outlined"
                                            placeholder="New password"
                                        />
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} className={classes.bottomSpace}>
                                        <Typography
                                            variant="h6"
                                            component="h6"
                                            className={classes.titleText}
                                        >
                                            Confirm password
                                        </Typography>
                                        <TextField
                                            value={values.confirm_password}
                                            type="text"
                                            name="confirm_password"
                                            data-testId="confirm_password"
                                            className={classes.placeholderText}
                                            onChange={(e) => {
                                                this.setState({
                                                    confirm_password: e.target.value,
                                                });
                                                setFieldValue("confirm_password", e.target.value);
                                            }}
                                            error={Boolean(touched.confirm_password && errors.confirm_password)}
                                            onBlur={handleBlur}
                                            helperText={touched.confirm_password && errors.confirm_password}
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Confirm password"
                                        />
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12}> <Button variant="contained" type="submit" className={classes.loginBtn}><Typography style={{
                                        fontSize: "16px",
                                        fontWeight: 500,
                                        lineHeight: "24px",
                                        color: "#3C3E49"
                                    }}>Update password</Typography></Button></Grid>
                                </form>
                            )}
                        </Formik>
                    </Box>
                    <Grid style={{ marginTop: "40px" }}><Typography className={classes.contactUs}>Having trouble logging in? Contact us xxx</Typography></Grid>
                </Grid>
            </ThemeProvider>
        );
    }
}

const useStyles: Record<string, CSSProperties> = {
    root: {
        display: "flex",
        background: "#FAFAFA",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    },
    contactUs: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        color: "#83889E"
    },
    titleText: {
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "20px",
    },
    storeName: {
        marginBottom: "20px",
        fontSize: "28px",
        fontWeight: 500,
        lineHeight: "32px",
        letterSpacing: "-0.02em",
        color: "#3C3E49"
    },
    bottomSpace: {
        marginBottom: "20px"
    },
    adminLoginBox: {
        width: "448px",
        background: "#FFF",
        border: "2px solid #ECEDF0",
        borderRadius: "3px",
        padding: "40px 20px"
    },
    placeholderText: {
        "& input, & textarea, & div": {
            fontFamily: "Rubik",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "22px",
            color: "#64748B",
        },
    },
    loginBtn: {
        background: "#00D659",
        borderRadius: "3px",
        "&:hover": {
            boxShadow: "none",
            background: "#00D659",
        },
        width: "100%",
        border: "none",
        padding: "12px",
        cursor: "pointer",
        boxShadow: "none",
        textTransform: "none",

    },
}

export default withStyles(useStyles)(ChangePassword);

// Customizable Area End