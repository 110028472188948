import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { dialogBoxProps } from "../../ss-cms-common-components/src/HOC/withDialog.web";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";
import { Scrollbars } from "react-custom-scrollbars";
import { createRef } from "react"
import { isTokenExpired, clearStorageData } from "../../ss-cms-common-components/src/Utilities/Utilities";
import { MetadataBanner, ProductPreviewContentInterface } from "./ProductPreviewScreenController.web";
export const configJSON = require("./config.js");
import { getStorageData } from "../../../framework/src/Utilities";
import { getResponseVariables } from "../../ss-cms-common-components/src/GetResponseVariables/GetResponseVariables";
import { emitter } from "../../../../packages/blocks/ss-cms-common-components/src/Layout/Layout";

// Customizable Area End

export type Props = DialogProps &
  dialogBoxProps &
  withHeadeActionBarProps &
  withLoaderProps & {
    navigation: {
      navigate: (to: string, params: object) => void;
      getParam: (param: string) => string;
      goBack: () => void;
    };
    id: string;
    // Customizable Area Start
    classes?: {};
    onTextChange?: (value: string) => void;
    // Customizable Area End
  };

// Customizable Area Start
export interface S {
  selectedTab: string | number;
  createId: string;
  updateData: ProductPreviewContentInterface;
  isApiCalled: boolean;
  imageUrl: string;
  categoryData: Array<{ [key: string]: string }>;
  productData: Array<ProductData>;
  storeName: string;
  sectionCategoryTitle: { [key: string]: string };
  SectionData: SectionDataInterfaceType[],
  token: token,
  isLoading: boolean
  selectedSection:string;
  expandedSection:string;
  secondaryAccent:string;

}

export interface ProductData {
  product_name: string,
  product_image: string,
  new_rate: string,
  old_rate: string,
  currency: string,
  discount: string
}

type token = string | null

export interface SectionDataInterfaceType {
  type: string;
  id: string;
  is_deleted: boolean;
  sectionName?: string;
  mobileBannerUpload?: string;
  mobileBannerName?: string;
  mobileBannerMetadata?: MetadataBanner;
  desktopBannerUpload?: string;
  desktopBannerName?: string;
  desktopBannerMetadata?: MetadataBanner;
  bannerUrl?: string;
  metadata?: Metadata;
}

interface Metadata {
  categories?: Category[];
  products?: ProductCategory[];
  isOpen?: boolean,
  sectionIdToSend?: string | number 
}

interface Category {
  category_name: string;
  category_image: string;
  selected: string;
  isSelected:boolean;
}

interface ProductCategory {
  productCatagoryID: string;
  productCatagoryName: string;
  selected: string;
  products: Product[];
}

interface Product {
  id: string;
  product_name: string;
  old_rate: string;
  new_rate: string;
  product_image: string;
  discount: number;
}

const productDataArray = [
  { id: 'prod1', product_name: 'AIR JORDAN 1 MID', new_rate: `$ 10.99`, old_rate: `$ 45.99`, product_image: 'https://cdn.pixabay.com/photo/2016/06/03/17/35/shoes-1433925_1280.jpg', discount: 50 },
  { id: 'prod1', product_name: 'AIR JORDAN 12', new_rate: `$ 10.99`, old_rate: `$ 45.99`, product_image: 'https://cdn.pixabay.com/photo/2016/06/03/17/35/shoes-1433925_1280.jpg', discount: 50 },
  { id: 'prod1', product_name: 'AIR JORDAN 13  RTS', new_rate: `$ 10.99`, old_rate: `$ 45.99`, product_image: 'https://cdn.pixabay.com/photo/2016/06/03/17/35/shoes-1433925_1280.jpg', discount: 50 },
  { id: 'prod1', product_name: 'AIR JORDAN 2D', new_rate: `$ 10.99`, old_rate: `$ 45.99`, product_image: 'https://cdn.pixabay.com/photo/2016/06/03/17/35/shoes-1433925_1280.jpg', discount: 50 },
]

const productStaticData = [
  {
    productCatagoryID: 'cat1',
    productCatagoryName: 'Prod Category 1',
    selected: "Select",
    products: productDataArray
  },
  {
    productCatagoryID: 'cat2',
    productCatagoryName: 'Prod Category 1',
    selected: "Select",
    products: productDataArray
  },
  {
    productCatagoryID: 'cat3',
    productCatagoryName: 'Prod Category 1',
    selected: "Select",
    products: productDataArray
  },
  {
    productCatagoryID: 'cat4',
    productCatagoryName: 'Prod Category 1',
    selected: "Select",
    products: productDataArray
  }
]

const catagoriesStaticData = [
  { category_name: 'Formal', category_image: 'https://cdn.pixabay.com/photo/2016/06/03/17/35/shoes-1433925_1280.jpg', selected: "Select", isSelected: false },
  { category_name: 'Casual', category_image: 'https://cdn.pixabay.com/photo/2016/06/03/17/35/shoes-1433925_1280.jpg', selected: "Select", isSelected: false },
  { category_name: 'Apparel', category_image: 'https://cdn.pixabay.com/photo/2016/06/03/17/35/shoes-1433925_1280.jpg', selected: "Select", isSelected: false },
  { category_name: 'Shirt', category_image: 'https://cdn.pixabay.com/photo/2016/06/03/17/35/shoes-1433925_1280.jpg', selected: "Select", isSelected: false }
]

// Customizable Area End

// Customizable Area Start
export interface SS {
  id: string;
}
// Customizable Area End

class BrandingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  scrollRef: React.RefObject<Scrollbars>;
  getStoreNameAPI: string = "";
  getOrderNotificationId: string = ""
  getCanvasDataAPI: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.scrollRef = createRef();
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      selectedTab: "mobile",
      createId: "",
      imageUrl: "",
      updateData: {
        footer: {
          phone_number: 5656565656,
          copyright: "",
          facebook_url: "",
          instagram_url: "",
          x_url: "",
          youtube_url: "",
          linkedin_url: "",
          appstore_url: "",
          playstore_url: "",
          design_id: "",
          is_social_media: false,
          is_app_link: false,
          is_contact: false
        },
        header: {
          storeName: '',
          isStoreNameEnabled: false,
          isLogoUploaded: false,
          selectedLogo: null,
          selectedFavicon: null,
          selectedLogoName:"",
          selectedFaviconName:"",
          isMobileMenuOpen: false,
          navigationMenuOptions: {
            menuData: [
              {
                id: 1,
                label: '1st Menu Item ',
                options: ['Shoe', 'Blazers', 'Casual Shirts', 'Casual Shoes', 'Flip Flops', 'Foot wear', 'Forage cap', 'Forage cap'],
                selectedOption: 'Select',
              },
              {
                id: 2,
                label: '2nd Menu Item ',
                options: ['Shoe', 'Blazers', 'Casual Shirts', 'Casual Shoes', 'Flip Flops', 'Foot wear', 'Forage cap', 'Forage cap'],
                selectedOption: 'Select',
              },
              {
                id: 3,
                label: '3rd Menu Item ',
                options: ['Shoe', 'Blazers', 'Casual Shirts', 'Casual Shoes', 'Flip Flops', 'Foot wear', 'Forage cap', 'Forage cap'],
                selectedOption: 'Select',
              },
              {
                id: 4,
                label: '4th Menu Item ',
                options: ['Shoe', 'Blazers', 'Casual Shirts', 'Casual Shoes', 'Flip Flops', 'Foot wear', 'Forage cap', 'Forage cap'],
                selectedOption: 'Select',
              },
            ]
          }
        },
        sections: {
          data: [{
            id: "",
            type: "",
            attributes: {
              section_type: "",
              section_name: "",
              banner_url: "",
              mobile_banner_url: "",
              desktop_banner_url: "",
              type: "",
              mobileBannerMetadata: {
                font_colour: "",
                text_alignment: "",
                text_position: "",
                header_text: "",
                font_size: "",
              },
              mobileBannerUpload: "",
              desktopBannerMetadata: {
                font_colour: "",
                text_alignment: "",
                text_position: "",
                header_text: "",
                font_size: "",
              },
              desktopBannerUpload: "",
              is_deleted: false
            }
          }
          ]
        },
      },
      categoryData: [],
      isApiCalled: false,
      productData: [],
      storeName: "",
      sectionCategoryTitle: {
        sectionID: "",
        categoryTitle: "category"
      },
      token: "",
      selectedSection:"",
      expandedSection:"",
      SectionData: [
        {
          type: 'banner',
          id: 'banner_1',
          is_deleted: false,
          mobileBannerUpload: '',
          mobileBannerName: '',
          mobileBannerMetadata: {
            font_colour: "",
            text_alignment: "",
            text_position: "",
            header_text: "",
            font_size: "",
          },
          desktopBannerUpload: '',
          desktopBannerName: '',
          desktopBannerMetadata: {
            font_colour: "",
            text_alignment: "",
            text_position: "",
            header_text: "",
            font_size: "",
          },
          bannerUrl: '',
          metadata: {
            isOpen: false,
          }
        },
        {
          type: 'category',
          id: 'Category_1',
          is_deleted: false,
          sectionName: 'Category',
          metadata: {
            categories: catagoriesStaticData,
            isOpen: false,
          }
        },
        {
          type: 'product',
          id: 'Product_1',
          is_deleted: false,
          sectionName: 'Product Display',
          metadata: {
            products: productStaticData,
            isOpen: false,
          }
        }
      ],
      isLoading:false,
      secondaryAccent:"rgb(226, 232, 240)"
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const token = await getStorageData("admintoken")
    const id = await getStorageData("id")
    this.setState({ token })
    if (token.length) {
      this.getStoreNameAPI = this.apiCallHandle(token, configJSON.storeProfileEndPointAPI, configJSON.getApiMethod)
      this.getOrderNotificationId = this.apiCallHandle(token, "/bx_block_order_management/orders/new_order_count", configJSON.getApiMethod)
    }
    if (id) {
      this.setState({isLoading:true})
      this.getCanvasDataAPI = this.apiCallHandle(token, `${configJSON.createContentWebsiteBuilderEndPoint}`, configJSON.getApiMethod)
    }
    // Customizable Area End
  }

  // Customizable Area Start

  receive = (from: string, message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }
    }
    if (apiRequestCallId == this.getOrderNotificationId) {
       emitter.emit("changeNotiNumber", responseJson.new_order_count)
    }
    if (this.getStoreNameAPI === apiRequestCallId) {
      if (responseJson.data) {
        this.setState({ storeName: responseJson.data.attributes.name })
      }
    }

    if (this.getCanvasDataAPI === apiRequestCallId) {
      if (responseJson.data) {
        this.HandleCanvasDataGetAPIResponse(responseJson.data.attributes)
      }
    }

  };

  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  handleTabChange = (event: React.ChangeEvent<{}>, selectedTab: number) => {
    this.setState({ selectedTab: selectedTab === 0 ? "mobile" : "desktop" });
  };

  creatIdHandler = (createId: string, responseJson: ProductPreviewContentInterface) => {
    this.setState({
      createId,
    })
    if(!responseJson.data && responseJson.footer){
      this.setState({
        updateData: responseJson
      })
    }
  };

  realTimeHandler = (fieldValue: string | boolean, fieldName: string, fieldType: string, fieldLinkType: string) => {
    let tempData = { ...this.state.updateData }
    let updatedData: any = Object.fromEntries(
      Object.entries(tempData).map((item: any = {}) => {

        if (item[0] === fieldType) {
          item[1][fieldName] = fieldValue;
          if (fieldLinkType) {
            item[1][fieldLinkType] = true;
          }
        }
        return item
      })
    )
    this.setState({
      updateData: updatedData,
      isApiCalled: false
    })
  };

  HandleStoreNameEnabled = (isStoreNameTrigged: boolean) => {
    this.setState(prevState => ({
      updateData: {
        ...prevState.updateData,
        header: {
          ...prevState.updateData.header,
          isStoreNameEnabled: isStoreNameTrigged,
        },
      },
    }));
  };
   hexToRGBA = (hex:string, opacity:number) => {
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r},${g},${b},${opacity})`;
  };
  handleThemeColor = (primaryColor:string,secondaryColor:string)=>{
    let secondaryAccent = this.hexToRGBA(secondaryColor, 0.6);
    this.setState(prevState => ({
      updateData: {
        ...prevState.updateData,
        themes_and_font: {
          ...prevState.updateData.themes_and_font,
          primary_colour: primaryColor,
          secondary_colour:secondaryColor,
         
        },
      },
    }));
    this.setState({secondaryAccent:secondaryAccent})
  }

  handleFont = (font:string, type:string)=>{
    if(type == 'body'){
      this.setState(prevState => ({
        updateData: {
          ...prevState.updateData,
          themes_and_font: {
            ...prevState.updateData.themes_and_font,
           body_text:font
           
          },
        },
      }));
    }
    if(type == 'header'){
      this.setState(prevState => ({
        updateData: {
          ...prevState.updateData,
          themes_and_font: {
            ...prevState.updateData.themes_and_font,
           header_text:font
           
          },
        },
      }));
    }

  }
  HandleNavigationMenuOptions = (APIResponse: any) => {
    this.setState(prevState => ({
      updateData: {
        ...prevState.updateData,
        header: {
          ...prevState.updateData.header,
          navigationMenuOptions: {
            menuData: APIResponse
          }
        }
      }
    }))
  };

  HandleLogoUpload = (files: string) => {
    this.setState(prevState => ({
      updateData: {
        ...prevState.updateData,
        header: {
          ...prevState.updateData.header,
          isLogoUploaded: true,
          selectedLogo: files
        },
      },
    }))
  };

  HandleMobileMenyTrigger = (trigger: boolean) => {
    this.setState(prevState => ({
      updateData: {
        ...prevState.updateData,
        header: {
          ...prevState.updateData.header,
          isMobileMenuOpen: trigger
        },
      },
    }))
  };

  HandleCanvasDataGetAPIResponse = (APIResponseUnFiltered: any) => {
    const APIResponse = APIResponseUnFiltered?.header?.data?.attributes;
    const metadata = APIResponseUnFiltered?.metadata;
    const MenuItems = metadata && Object.keys(metadata).length !== 0 ? JSON.parse(metadata) : null;

    let mainData = APIResponseUnFiltered.sections?.data.find((item: any) => {
      return item.attributes.section_type === "category";
    });
    if(APIResponseUnFiltered.themes_and_font?.data.attributes.secondary_colour != ""){
      let secondaryAccent = this.hexToRGBA(APIResponseUnFiltered.themes_and_font?.data.attributes.secondary_colour,0.6)
      this.setState({secondaryAccent:secondaryAccent})
    }
    let isLogoUploaded:boolean;
    if(APIResponse?.logo_url !== null){
      if(APIResponse.logo_url.length){
        isLogoUploaded =true;
      }
      else{
        isLogoUploaded = false;
      }
    }
    else{
      isLogoUploaded = false;
    }
    this.setState(prevState => ({
      updateData: {
        ...prevState.updateData,
        header: {
          ...prevState.updateData.header,
          isStoreNameEnabled: APIResponse?.show_business_name,

          isLogoUploaded: isLogoUploaded,
          selectedLogo: APIResponse?.logo_url,
          isMobileMenuOpen: APIResponse?.is_mobile_menu,
          navigationMenuOptions: {
            menuData: MenuItems?.headerNavigationData?.menuData
          }
        },
      },
      sectionCategoryTitle: {
        categoryTitle: mainData?.attributes.section_type
      },
    }));

   this.HandleCanvasDataGetAPIResponse2(APIResponseUnFiltered)
  };

  HandleCanvasDataGetAPIResponse2 = (APIResponseUnFiltered:any)=>{
    if (APIResponseUnFiltered.sections?.data) {
      const sectionData = APIResponseUnFiltered.sections.data.map((section: any) => {
        let sectionInfo: any = {};

        switch (section?.attributes?.section_type?.toLowerCase()) {
          case 'category':
            sectionInfo = this.categoryDataHandle(section);
            break;
          case 'product':
            sectionInfo = this.productDataHandle(section);
            break;
          case 'banner':
            sectionInfo = this.bannerDataHandle(section);
            break;
          default:
            break;
        }
        if (section.id && sectionInfo.metadata) {
          sectionInfo.metadata.sectionIdToSend = section.id;
        }
        return sectionInfo;
      });

      this.setState({
        SectionData: sectionData.filter((item: any) => item),
        isLoading: false
      });
    }
  }
  categoryDataHandle(section:any){
  return {
    type: 'category',
    id: section.id,
    is_deleted: section.attributes.is_deleted,
    sectionName: section.attributes.section_name,
    metadata: section.attributes.metadata && Object.keys(section.attributes.metadata).length ? JSON.parse(section.attributes.metadata) : { isOpen: false }
  }
}
productDataHandle(section:any){
  return {
    type: 'product',
    id: section.id,
    is_deleted: section.attributes.is_deleted,
    sectionName: section.attributes.section_name,
    metadata: section.attributes.metadata && Object.keys(section.attributes.metadata).length ? JSON.parse(section.attributes.metadata) : { isOpen: false, products: productStaticData }
  }
}
bannerDataHandle(section:any){
  return {
    type: 'banner',
    id: section.id,
    is_deleted: section.attributes.is_deleted,
    bannerUrl: section.attributes.banner_url,
    mobileBannerUpload: section.attributes.mobile_banner_url,
    desktopBannerUpload: section.attributes.desktop_banner_url,
    mobileBannerName: section.attributes.mobile_banner_filename,
    desktopBannerName: section.attributes.desktop_banner_filename,
    mobileBannerMetadata: section.attributes.mobile_banner_metadata && Object.keys(section.attributes.mobile_banner_metadata).length ? JSON.parse(section.attributes.mobile_banner_metadata) : {},
    desktopBannerMetadata: section.attributes.desktop_banner_metadata && Object.keys(section.attributes.desktop_banner_metadata).length ? JSON.parse(section.attributes.desktop_banner_metadata) : {},
    metadata: section.attributes.metadata && Object.keys(section.attributes.metadata).length ? JSON.parse(section.attributes.metadata) : { isOpen: false, categories: catagoriesStaticData }
  }
}
  realTimeSectionCategoryName = (sectionID: string, sectionName: string, section_type: string) => {

    this.setState({
      sectionCategoryTitle: {
        sectionID: sectionID,
        categoryTitle: sectionName
      }
    })
  }

  realTimeSectionHandler = (fieldValue: any, fieldType: string, section_type: string, fieldName: string) => {
    let tempData = { ...this.state.updateData }
    const imageUrl = URL.createObjectURL(fieldValue);
    let updatedData: any = {
      ...tempData,
      [fieldType]: {
        ...tempData[fieldType],
        data: tempData[fieldType].data.map((item: any, index: number) => {
          if (index === index) {
            item.attributes[fieldName] = imageUrl;
          }
          return item;
        }),
      },
    };
    this.setState({
      updateData: updatedData,
      isApiCalled: false
    })
  };

  realTimeCategoryHandler = (fieldValue: any) => {
    let categoryData = fieldValue.filter((item: any) => {
      return item.section_type === "category";
    })[0];

    let CategoryList = [];
    for (const key in categoryData) {
      if (key.startsWith("category")) {
        CategoryList.push({
          category_name: categoryData[key],
          category_image: "https://cdn.pixabay.com/photo/2016/06/03/17/35/shoes-1433925_1280.jpg"
        });
      }
    }
    this.setState({
      categoryData: CategoryList,
      isApiCalled: false
    })
  };

  realTimeProductHandler = (fieldValue: any) => {
    let productData = fieldValue.filter((item: any) => {
      return item.section_type === "product";
    })[0];

    let ProductList = [];
    for (const key in productData) {
      if (key.startsWith("product")) {
        ProductList.push({
          product_name: productData[key],
          product_image: "https://cdn.pixabay.com/photo/2016/06/03/17/35/shoes-1433925_1280.jpg",
          new_rate: "129,95",
          old_rate: "149,00",
          currency: "�",
          discount: "20%"
        });
      }
    }
    this.setState({
      productData: ProductList,
      isApiCalled: false
    })
  };
  generateRandomString(length:number) {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const randomValues = new Uint8Array(length);
    window.crypto.getRandomValues(randomValues);
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(randomValues[i] % characters.length);
    }
    return result;
}
  handleSectionAdd = (type: string, name?: string): void => {
    let newSection: SectionDataInterfaceType;
    let randomString = this.generateRandomString(11);
    if (type.toLowerCase() === "banner") {
      newSection = {
        type: "banner",
        id: "banner_" + randomString + "_" + Date.now(),
        is_deleted: false,
        bannerUrl: '',
        mobileBannerUpload: '',
        mobileBannerName: '',
        mobileBannerMetadata: {
          font_colour: "",
          text_alignment: "",
          text_position: "",
          header_text: "",
          font_size: "",
        },
        desktopBannerUpload: '',
        desktopBannerName: '',
        desktopBannerMetadata: {
          font_colour: "",
          text_alignment: "",
          text_position: "",
          header_text: "",
          font_size: "",
        },
        metadata: {
          isOpen: true,
        },
        ...(name ? { sectionName: name } : {})
      };
    } else if (type.toLowerCase() === "category") {
      newSection = {
        type: "category",
        id: "Category_" + randomString + "_" + Date.now(),
        is_deleted: false,
        sectionName: name || 'Category',
        metadata: {
          isOpen: true,
          categories: catagoriesStaticData
        }
      };
    } else if (type.toLowerCase() === "product") {
      newSection = {
        type: "product",
        id: "Product_" + randomString + "_" + Date.now(),
        is_deleted: false,
        sectionName: name || 'Product Display',
        metadata: {
          isOpen: true,
          products: productStaticData
        }
      };
    }
    this.setState(prevState => ({
      SectionData: [...prevState.SectionData, newSection]
    }));

  }

  updateSectionType = (index: number, type: string) => {
    let updatedSectionData = [...this.state.SectionData];
    let sectionToUpdate = updatedSectionData[index];

    if (sectionToUpdate.type !== type) {
      if (type === "banner") {
        sectionToUpdate = {
          type: "banner",
          id: sectionToUpdate.id,
          is_deleted: false,
          bannerUrl: '',
          mobileBannerUpload: '',
          mobileBannerName: '',
          mobileBannerMetadata: {
            font_colour: "",
            text_alignment: "",
            text_position: "",
            header_text: "",
            font_size: "",
          },
          desktopBannerUpload: '',
          desktopBannerName: '',
          desktopBannerMetadata: {
            font_colour: "",
            text_alignment: "",
            text_position: "",
            header_text: "",
            font_size: "",
          },
          sectionName: 'Banner',
          metadata: {
            isOpen: true,
          },
        };
      } else if (type === "product") {
        sectionToUpdate = {
          type: "product",
          id: sectionToUpdate.id,
          is_deleted: false,
          sectionName: 'Product Display',
          metadata: {
            isOpen: true,
            products: productStaticData
          }
        };
      } else if (type === "category") {
        sectionToUpdate = {
          type: "category",
          id: sectionToUpdate.id,
          is_deleted: false,
          sectionName: "Catagory",
          metadata: {
            isOpen: true,
            categories: catagoriesStaticData
          }
        };
      }

      sectionToUpdate.type = type;

      updatedSectionData[index] = sectionToUpdate;

      this.setState({
        SectionData: updatedSectionData
      });
    }
  };

  updateSectionName = (sectionId: string, sectionItemIndex: number, newName: string): void => {
    this.setState(prevState => ({
      SectionData: prevState.SectionData.map((section, index) => {
        if (index === sectionItemIndex) {
          return {
            ...section,
            sectionName: newName
          };
        } else {
          return section;
        }
      })
    }));
  }

  deleteSection = (sectionIndex: number) => {
    this.setState(prevState => ({
      SectionData: prevState.SectionData.map((section, index) =>
        index === sectionIndex ? { ...section, is_deleted: true } : section
      )
    }));
  }

  updateBannerMobileData = (index: number, base64Image: string, fileName: string, isBannerType: string) => {
    this.setState(prevState => {
      const updatedSectionData = [...prevState.SectionData];
      const bannerToUpdate = updatedSectionData[index];
      if (bannerToUpdate && bannerToUpdate.type.toLowerCase() === 'banner') {
        if (isBannerType === "mobile") {
          bannerToUpdate.mobileBannerUpload = base64Image;
          bannerToUpdate.mobileBannerName = fileName;
          bannerToUpdate.mobileBannerMetadata = {
            font_colour: "",
            text_alignment: "",
            text_position: "",
            header_text: "",
            font_size: "",
          }
        } else {
          bannerToUpdate.desktopBannerUpload = base64Image;
          bannerToUpdate.desktopBannerName = fileName;
          bannerToUpdate.desktopBannerMetadata = {
            font_colour: "",
            text_alignment: "",
            text_position: "",
            header_text: "",
            font_size: "",
          }
        }
        updatedSectionData[index] = bannerToUpdate;
      }
      return { SectionData: updatedSectionData };
    });
  }

  updateBannerMobileMetadata = (index: number, metadata: MetadataBanner) => {
    this.setState(prevState => {
      const updatedSectionData = [...prevState.SectionData];
      const bannerToUpdate = updatedSectionData[index];
      if (bannerToUpdate && bannerToUpdate.type.toLowerCase() === 'banner') {
        bannerToUpdate.mobileBannerMetadata = metadata;
        updatedSectionData[index] = bannerToUpdate;
      }
      return { SectionData: updatedSectionData };
    });
  }

  updateBannerDesktopMetadata = (index: number, metadata: MetadataBanner) => {
    this.setState(prevState => {
      const updatedSectionData = [...prevState.SectionData];
      const bannerToUpdate = updatedSectionData[index];
      if (bannerToUpdate && bannerToUpdate.type.toLowerCase() === 'banner') {
        bannerToUpdate.desktopBannerMetadata = metadata;
        updatedSectionData[index] = bannerToUpdate;
      }
      return { SectionData: updatedSectionData };
    });
  }
  updateBannerUrl = (index: number, bannerUrl: string) => {
    this.setState(prevState => {
      const updatedSectionData = [...prevState.SectionData];
      const bannerToUpdate = updatedSectionData[index];
      if (bannerToUpdate && bannerToUpdate.type.toLowerCase() === 'banner') {
        bannerToUpdate.bannerUrl = bannerUrl;
        updatedSectionData[index] = bannerToUpdate;
      }
      return { SectionData: updatedSectionData };
    });
  }

  handleSectionDropDownOpen = (index: number) => {
    this.setState(prevState => {
      const updatedSectionData = prevState.SectionData.map((section, i) => {
        if (i === index && section.metadata) {
          section.metadata.isOpen = !section.metadata.isOpen;
        } else if (section.metadata) {
          section.metadata.isOpen = false;
        }
        return section;
      });
      return { SectionData: updatedSectionData };
    });
  }

  handleCloseAllSections = () => {
    this.setState(prevState => {
      const updatedSectionData = prevState.SectionData.map(section => {
        if (section.metadata) {
          section.metadata.isOpen = false;
        }
        return section;
      });
      return { SectionData: updatedSectionData };
    });
  }  

  overrideCategoryOrProductSelectedValue = (sectionId: string, sectionType: string, index: number, selectedValue: string) => {
    const updatedSectionData = this.state.SectionData.map((section) => {
      if (section.id === sectionId && section.type.toLowerCase() === sectionType.toLowerCase()) {
        if (sectionType.toLowerCase() === "category" && section.metadata && section.metadata.categories && section.metadata.categories.length > index) {
          const updatedCategories = section.metadata.categories.map((category, categoryIndex) => {
            if (categoryIndex === index) {
              return {
                ...category,
                selected: selectedValue,
                isSelected:true
              };
            }
            return category;
          });
          return {
            ...section,
            metadata: {
              ...section.metadata,
              categories: updatedCategories
            }
          };
        } else if (sectionType.toLowerCase() === "product" && section.metadata && section.metadata.products && section.metadata.products.length > index) {
          const updatedProducts = section.metadata.products.map((product, productIndex) => {
            if (productIndex === index) {
              return {
                ...product,
                selected: selectedValue
              };
            }
            return product;
          });
          return {
            ...section,
            metadata: {
              ...section.metadata,
              products: updatedProducts
            }
          };
        }
      }
      return section;
    });

    this.setState({ SectionData: updatedSectionData });
  };

  updateFooter = (eventName: string, value: boolean | string) => {
    this.setState((prevState) => ({
      updateData: {
        ...prevState.updateData,
        footer: {
          ...prevState.updateData.footer,
          [eventName]: value,
        },
      },
    }));
  };

  apiCalledHandler = () => {
    this.setState({
      isApiCalled: true
    })
  };

  handleGetAPITrigger = async () => {
    this.setState({
      isLoading: true
    })
    let id = 1
    this.getCanvasDataAPI = this.apiCallHandle(this.state.token, `${configJSON.createContentWebsiteBuilderEndPoint}`, configJSON.getApiMethod)
  }

  apiCallHandle = (token: token, endPoint: string, method: string, body?: object) => {
    const header = { token: token };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), header);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), body)

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleSocialLinkToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState((prevState: any) => ({
      updateData: {
        ...prevState.updateData,
        footer: {
          ...prevState.updateData.footer,
          is_social_media: event,
        },
      },
    }))
  }

  updateSelectedSection = (sectionName: string) => {
    this.setState({ selectedSection: sectionName });
  }
  
  updateExpandedSection = (sectionName:string)=>{
    this.setState({expandedSection:sectionName});
  }

  // Customizable Area End
}

export default BrandingController;
