export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");

// Customizable Area Start
import React from "react";
export const DeleteBinIcon = require("../assets/delete_bin.svg");
export const GooglePlayIcon = require("../assets/google_play.svg");
export const AppStore = require("../assets/appstore.svg");
export const AddIcon = require("../assets/add.svg");
export const Facebook = require("../assets/facebook.png");
export const Instagram = require("../assets/instagram.png");
export const Twitter = require("../assets/twitter.png");
export const MenuIcon = require("../assets/menuIcon.png");
export const minusIcon = require("../assets/minusImage.png");
export const plusIcon = require("../assets/plusImage.png");
export const searchIcon = require("../assets/search.png");
export const Youtube = require("../assets/youtube.png");
export const Linkdin = require("../assets/linkedin.svg");
export const WhatsApp = require("../assets/whatsapp.svg");
export const CallIcon = require("../assets/call.svg");
export const EmailIcon = require("../assets/email.svg");
export const InfoIcon = require("../assets/Info.png");
export const checkedImg = require("../assets/checkedImage.svg");
export const SectionIcon = require("../assets/section.png");
export const dropDownArrowIcon = require("../assets/dropdownArrow.png");
export const addSectionIcon = require("../assets/addSectionIcon.png")
export const deleteIcon = require("../assets/redDelete.png");
export const uploadIcon = require("../assets/upload.png");
export const CloseIcon = require("../assets/close.png");
export const AddSectionIcon = require("../assets/group.png");
export const ModalSearchIcon = require("../assets/searchIcon.png");
export const checkIcon = require("../assets/checkIcon.png");
export const dropUpArrowIcon = require("../assets/dropUpArrow.png");
export const EditIcon = require("../assets/editImage.png");
export const TooltipIcon = require("../assets/TooltipIcon.svg");
export const SideArrow = require("../assets/SideArrow.svg");
export const TwitterSvg = require("../assets/twitterSvg.svg");
export const callDesktop = require("../assets/callDesktop.svg");
export const emailDesktop = require("../assets/emailDesktop.svg");
export const whatsappDesktop = require("../assets/whatsappDesktop.svg");
export const leftIcon = require("../assets/leftIcon.png");
export const rightIcon = require("../assets/rightIcon.png");
export const closeBold = require("../assets/closeBold.png");
export const greySearch = require("../assets/greySearch.png");
export const toggle = require("../assets/toggle.png");
export const item = require("../assets/item.webp");
export const li = require("../assets/li.png");









export interface DesktopIconProps {
    color?: string;
    size?: number;
    isActive?: boolean;
    className?: string;
    style?: object;
  }
  
  export function DesktopIcon(props: DesktopIconProps) {
    const { color = "#6200EA", size = 20, className, style } = props;
  
    return (
      <span style={style} className={className}>
        <svg
          width={size}
          height={(size * 17) / 16}
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5 15.88H5.5L6 13H10L10.5 15.88V15.88Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4 15.8809H12"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M0.5 11.0801H15.5"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.5 4.48047C0.5 2.82362 1.84315 1.48047 3.5 1.48047H12.5C14.1569 1.48047 15.5 2.82361 15.5 4.48047V10.0005C15.5 11.6573 14.1569 13.0005 12.5 13.0005H3.5C1.84315 13.0005 0.5 11.6573 0.5 10.0005V4.48047Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    );
  }
  
  export interface MobileIconProps {
    color?: string;
    size?: number;
    className?: string;
    style?: object;
  }
  
  export function MobileIcon(props: MobileIconProps) {
    const { color = "#9B9FB1", size = 20, className, style } = props;
    return (
      <span style={style} className={className}>
        <svg
          width={size}
          height={size}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.5 0.500976C11.1569 0.500977 12.5 1.84412 12.5 3.50098L12.5 12.501C12.5 14.1578 11.1569 15.501 9.5 15.501L6.5 15.501C4.84314 15.501 3.5 14.1578 3.5 12.501L3.5 3.50098C3.5 1.84412 4.84315 0.500976 6.5 0.500976L9.5 0.500976Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.5 12.501L12.5 12.501"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    );
  }
  
 
// Customizable Area End