//@ts-nocheck
import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

const closeIcon = require("../assets/closeIcon.png");

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        margin: "15px 15px 0px 0px",
        color: "#000",
    },
    textName: {
        fontFamily: "Rubik",
        fontSize: "26px",
        fontWeight: "500",
        lineHeight: "31px",
        color: "#3C3E49"
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography className={classes.textName}>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <img src={closeIcon} alt="closeIcn" style={{ height: "24px", width: "24px" }} />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const useStyles = makeStyles({
    dialogModalText: {
        fontFamily: "Rubik",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "24px",
        color: "#3C3E49"
    },
    cancelBtn: {
        textTransform: "none",
        textDecoration: "underline",
        fontFamily: "Rubik",
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "20px",
        color: "#3C3E49"
    },
    deleteBtn: {
        borderRadius: "3px",
        textTransform: "none",
        background: "#FF1744",
        fontFamily: "Rubik",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        padding: "15px 30px",
        color: "#FFFFFF",
    }
});

export default function CustomizedDialogs(props: any) {
    const classes = useStyles();
    return (
        <div>
            <Dialog onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
                <DialogTitle style={{ padding: "30px 0px 10px 30px" }} onClose={props.handleClose}>
                    Are you sure?
                </DialogTitle>
                <DialogContent style={{ padding: "0px 30px 30px 30px", margin: "0px 150px 40px 0px" }}>
                    <Typography gutterBottom className={classes.dialogModalText}>
                        Are you sure you want to delete this {props.name} ?
                    </Typography>
                </DialogContent>
                <DialogActions style={{ borderTop: "1px solid #E8E8E8", padding: "0px" }}>
                    <Button onClick={props.handleClose} className={classes.cancelBtn}>
                        Cancel
                    </Button>
                    <Button onClick={props.deleteCategory} style={{ background: "#FF1744", }} className={classes.deleteBtn}>
                        Yes, delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


