// Customizable Area Start
//@ts-nocheck
import React from "react";
import {
  Box,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import CategoriesController, { Props } from "./CategoriesController.web";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {
  noCategories,
  editIcon,
  deleteIcon,
  backIcon,
  searchIcon,
} from "./assets";
import withStyles, { CSSProperties } from "@material-ui/core/styles/withStyles";
import CustomizedDialogs from "../../../components/src/ConfirmModal.web";
import AlertMessage from "../../../components/src/AlertMessage.web";
import SubmitButtonPopup from "../../../components/src/SubmitButtonPopup.web";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout";
import { Scrollbars } from "react-custom-scrollbars";
import SectionLoader from "../../inventorymanagement/src/SectionLoader.web";
import Pagination from "../../ss-cms-common-components/src/Pagination/Pagination";

export class Categories extends CategoriesController {
  constructor(props: Props) {
    super(props);
  }

  categoriesBlock = (classes: any) => {
    return this.state.categories && this.state.categories.length > 0 ? (
      <Grid item>
        <Grid
          item
          lg={12}
          md={12}
          xs={12}
          style={{ display: "flex", marginBottom: "20px" }}
        >
          <Grid item lg={6} md={6} xs={6}>
            <Typography
              variant="h5"
              style={{
                fontFamily: "Rubik",
                fontSize: "26px",
                fontWeight: "500",
                lineHeight: "31px",
                letterSpacing: "0em",
              }}
            >
              Categories
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} xs={6} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              className={classes.categoryButton}
              style={{ background: "#00D659" }}
              data-testId="addCategory"
              onClick={() => {
                this.setState({
                  category: "",
                  isAddEdit: true,
                  isEdit: false,
                  setTouched: false,
                  searchCategory: "",
                  noSearchCategories: false,
                });
              }}
            >
              Create New Category
            </Button>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} xs={12} style={{ margin: "30px 0px" }}>
          <form style={{ width: "100%", background: "#fff" }} noValidate>
            <TextField
              id="searchCategory"
              fullWidth
              variant="outlined"
              size="small"
              placeholder="Search Categories"
              data-testId="searchCategoryInput"
              value={this.state.searchCategory}
              className={classes.InputStyle}
              onChange={(e) => {
                this.searchCategoryWithText(e.target.value);
              }}
              onKeyDown={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              autoComplete="off"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      style={{ height: "16.13px", width: "16.13px" }}
                      src={searchIcon}
                      alt="searchIcon"
                    />
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </Grid>

        <Grid item lg={12} md={12} xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "22px",
                    letterSpacing: "0em",
                    color: "#3C3E49",
                  }}
                >
                  Category Title
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "22px",
                    letterSpacing: "0em",
                    paddingRight: "45px",
                  }}
                  align="right"
                >
                  Edit
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ border: "2px", background: "#fff" }}>
              {this.state.loadingSearch ? (
                <SectionLoader loading={true} />
              ) : (
                this.tableBodySection(classes)
              )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    ) : (
      <Grid
        item
        lg={12}
        md={12}
        xs={12}
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box sx={useStyles.noCategoriesBox}>
          <Grid item className={classes.categoriesContent}>
            <Typography style={{ marginBottom: "10px" }}>
              <img src={noCategories} alt="no categories " />
            </Typography>
            <Typography
              variant="h5"
              component="h2"
              style={{
                fontFamily: "Rubik",
                fontSize: "26px",
                fontWeight: "500",
                lineHeight: "31px",
                marginBottom: "5px",
              }}
            >
              You have no categories
            </Typography>
            <Typography
              style={{
                fontFamily: "Rubik",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "22px",
                color: "#676B7E",
                marginBottom: "10px",
              }}
            >
              Start adding your categories so that your menu items can be better
              organised
            </Typography>
            <Typography
              style={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontStyle: "italic",
                fontWeight: "400",
                lineHeight: "18px",
                marginBottom: "10px",
              }}
            >
              For example: Pizza, Starters, Specials, Deals etc.
            </Typography>
            <Grid>
              <Button
                variant="contained"
                className={classes.categoryButton}
                style={{ background: "#00D659" }}
                onClick={() => {
                  this.setState({
                    category: "",
                    isAddEdit: true,
                    isEdit: false,
                  });
                }}
                data-test-id="categorybuttonTest"
              >
                Add Category
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    );
  };
  tableBodySection = (classes) => {
    return this.state.categories &&
      this.state.categories.length > 0 &&
      !this.state.noSearchCategories ? (
      this.state.categories.map((data: Categories) => {
        return (
          <TableRow
            key={data.attributes.id}
            style={{ border: "2px solid #ECEDF0" }}
          >
            <TableCell
              component="th"
              scope="row"
              style={{
                fontFamily: "Rubik",
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "16px",
                letterSpacing: "0em",
                color: "#3C3E49",
              }}
            >
              {data.attributes.name}
            </TableCell>
            <TableCell align="right">
              <img
                src={editIcon}
                style={{ cursor: "pointer" }}
                data-testId="editCategoryBtn"
                onClick={() => {
                  this.editCateoryText(data);
                }}
                alt="edit icon"
              />
              <img
                src={deleteIcon}
                style={{
                  cursor: "pointer",
                  marginLeft: "15px",
                  height: "18.33px",
                  width: "18.33px",
                }}
                data-testId="deleteCategoryBtn"
                onClick={() => {
                  this.setState({
                    openModal: true,
                    categoryId: data.attributes.id,
                  });
                }}
                alt="delete icon"
              />
            </TableCell>
          </TableRow>
        );
      })
    ) : (
      <TableRow style={{ border: "2px solid #ECEDF0" }}>
        <TableCell component="th" scope="row" colSpan={2}>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            style={{ background: "#fff", margin: "10px 0px" }}
          >
            <Grid item className={classes.categoriesContent}>
              <Typography style={{ marginBottom: "10px" }}>
                <img src={noCategories} alt="no categories" />
              </Typography>
              <Typography
                variant="h5"
                component="h2"
                style={{
                  fontFamily: "Rubik",
                  fontSize: "20px",
                  fontWeight: "500",
                  lineHeight: "26px",
                  letterSpacing: "-0.02em",
                  marginBottom: "5px",
                }}
              >
                No results found
              </Typography>
              <Typography
                color="textSecondary"
                style={{
                  fontFamily: "Rubik",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  marginBottom: "10px",
                  color: "#83889E",
                }}
              >
                Revise search text and try again
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    );
  };
  addEditCateoryBlock = (classes: any) => {
    return (
      <>
        <Grid item xs={12} className={classes.backArrow}>
          <img
            src={backIcon}
            alt="back"
            style={{
              height: "12px",
              width: "12.88px",
              cursor: "pointer",
              marginRight: "5px",
            }}
            data-testId="backBtn"
            onClick={() => this.pressBackEditAdd()}
          />
          <Typography
            style={{
              fontFamily: "Rubik",
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "16px",
              letterSpacing: "0em",
              color: "#676B7E",
            }}
          >
            Back
          </Typography>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          xs={12}
          style={{ display: "flex", margin: "30px 0px" }}
        >
          <Grid item lg={6} md={6} xs={6}>
            <Typography
              variant="h5"
              style={{
                fontFamily: "Rubik",
                fontSize: "26px",
                fontWeight: "500",
                lineHeight: "31px",
                letterSpacing: "0em",
              }}
            >
              {" "}
              {this.state.isEdit ? "Edit" : "Add a"} Category
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} xs={6} style={{ textAlign: "right" }} />
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          xs={12}
          className={classes.categoriesContent}
        >
          <Box
            sx={useStyles.addCategoriesBox}
            style={{
              border: "2px solid #ECEDF0",
              textAlign: "start",
              borderRadius: "3px",
            }}
          >
            <Grid item>
              <Typography
                variant="h5"
                component="h2"
                style={{
                  marginBottom: "30px",
                  fontFamily: "Rubik",
                  fontSize: "20px",
                  fontWeight: "500",
                  lineHeight: "26px",
                  letterSpacing: "-0.02em",
                }}
              >
                Category details
              </Typography>
              <Grid>
                <Typography
                  variant="h6"
                  component="h2"
                  style={{
                    marginBottom: "5px",
                    fontFamily: "Rubik",
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "19px",
                  }}
                >
                  Category name *
                </Typography>
                <TextField
                  id="addCategory"
                  value={this.state.category}
                  type="text"
                  data-testId="categoryInput"
                  onChange={(e) => {
                    this.handleInputChange(e);
                    this.setState({ openSubmit: true });
                  }}
                  fullWidth
                  variant="outlined"
                  placeholder={
                    this.state.isEdit ? "Edit Category" : "Add Category"
                  }
                  required
                  autoComplete="off"
                  error={this.state.setTouched}
                  helperText={
                    this.state.setTouched ? this.state.errorText : null
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </>
    );
  };

  renderScreen(classes) {
    return !this.state.isAddEdit
      ? this.categoriesBlock(classes)
      : this.addEditCateoryBlock(classes);
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <AlertMessage
          data-testId="alertMessage"
          open={this.state.openAlert}
          success={this.state.alertSuccess}
          message={this.state.alertMessage}
          close={this.handleAlertMessageClose}
        />
        <SubmitButtonPopup
          data-testId="categorySubmit"
          open={this.state.openSubmit}
          close={this.discardBtn}
          submit={this.submitBtn}
        />
        <Scrollbars>
          <Grid container item lg={12} md={12} xs={12} className={classes.root}>
            <Grid item lg={11} md={11} xs={11} style={{ marginTop: "40px" }}>
              {this.state.loading ? (
                <SectionLoader loading={true} />
              ) : (
                this.renderScreen(classes)
              )}
              {!!this.state.pagination?.total_count &&
                !this.state.isAddEdit &&
                !this.state.noSearchCategories &&
                !this.state.loading &&
                !this.state.loadingSearch && (
                  <Box marginBottom={4}>
                    <Pagination
                      data-testId="pagination"
                      count={this.state.pagination?.total_count}
                      onChange={this.handlePageChange}
                      pageSize={this.state.pagination?.per_page}
                      itemText="Categories"
                      pageNo={this.state.pagination?.current_page || 1}
                    />
                  </Box>
                )}
            </Grid>
          </Grid>
        </Scrollbars>
        <CustomizedDialogs
          data-testId="deleteCategory"
          open={this.state.openModal}
          name="category"
          handleClose={this.handleClose}
          deleteCategory={this.deleteCategory}
        />
      </>
    );
  }
}

const useStyles: Record<string, CSSProperties> = {
  root: {
    justifyContent: "center",
    backgroundColor: "#FAFAFA",
  },
  addCategoriesBox: {
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "40px 50px",
    width: "684px",
    height: "216px",
  },
  categoriesContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  discardButton: {
    textDecoration: "underline",
    paddingRight: "20px",
    textTransform: "none",
  },
  backArrow: {
    display: "flex",
    alignItems: "center",
  },
  categoryButton: {
    background: "#00D659",
    textTransform: "none",
    boxShadow: "none",
    borderRadius: "3px",
    fontFamily: "Rubik",
    fontSize: "16px",
    fontweight: "500",
    lineHeight: "24px",
    padding: "10px 26px 10px 26px",
    letterSspacing: "0em",
    color: " #3C3E49",
  },
  noCategoriesBox: {
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "50px 50px 0px 50px",
    width: "528px",
    height: "357px",
    marginTop: "60px",
  },
  InputStyle: {
    color: "red",
    "& input": {
      fontFamily: "Rubik",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "22px",
      color: "#AFB2C0",
    },
    "& fieldset": {
      border: "1px solid #D0D2DA",
      top: "0px",
    },
  },
};

export default withLayout(withStyles(useStyles)(Categories));

// Customizable Area End
