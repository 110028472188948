Object.defineProperty(exports, "__esModule", {
  value: true
});
// Customizable Area Start
exports.shippingTitle = "Shipping";
exports.labelBaseCharge = "Base charge";
exports.labelShippingZones = "Shipping zones";
exports.labelThirdPartyIntegration = "3rd party integration";
exports.labelShippingPackages = "Shipping packages";
exports.shippingChargesTitle = "Shipping charges";
exports.freeShipping = "Free shipping for all items";
exports.defineShipping = "Define shipping charges";
exports.shippingChargeTitle = "Shipping charge";
exports.otherwise = "Otherwise, free shipping;";
exports.basketLessThan = "If basket total is less than";
exports.headerBarSuccessType = "success";
exports.headerBarErrorType = "error";
exports.somethingWentWrong = "Something went wrong";
exports.saveChanges = "SAVECHANGES";
exports.discardChanges = "DISCARDCHANGES";
exports.shippingChargeAdminEndpoint = `/bx_block_shipping_charge/shipping_charges`;
exports.postMethod = "POST";
exports.putMethod = "PUT";
exports.deleteMethod = "DELETE";
exports.getMethod = "GET";
exports.patchMethod = "PATCH";
exports.labelName = "Name";
exports.labelDimensions = "Dimensions *";
exports.update = "Update";
exports.add = "Add";
exports.cancel = "Cancel";
exports.shippingPackageName = "Shipping package name";
exports.lengthPlaceholder = "Length";
exports.widthPlaceholder = "Width";
exports.heightPlaceholder = "Height";
exports.adminPackagesEndpointBase = "bx_block_shipping_package/shipping_packages";
exports.deleteModalEndpoint = "/bx_block_shipping_zip_code/shipping_zip_codes/bulk_destroy";
exports.zipcodesBulkDeleteEndpoint = "/bx_block_shipping_zip_code/shipping_zip_codes/bulk_destroy"
exports.deleteModalTitle = "Are you sure?";
exports.deleteModalMessage = "Are you sure you want to delete this packages.";
exports.deletePackages = "Delete packages";
exports.addNewPackage = "Add new package";
exports.packagesItemText = "package's";
exports.shippingPackagesModalTitle = "Add shipping packages";
exports.editPackages = "Edit shipping packages";
exports.shippingPackages = "Shipping packages";
exports.haveNotDefined = "You have not defined shipping packages";
exports.labelZipcode = "Zipcode";
exports.enterState = "Enter the state to apply a specific shipping charge";
exports.activated = "Activated";
exports.notActivated = "Not Activated";
exports.deleteZipcodes = "Delete zipcodes";
exports.addZipcode = "Add zipcode";
exports.zipcodesItemText = "zipcode's";
exports.shippingChargesError = "You have not defined shipping charges for a particular zipcode";
exports.editZipcode = "Edit zipcode";
exports.deleteModalMessageZipcode = "Are you sure you want to delete this zipcodes.";
exports.zipcodesEndpointBase = "/bx_block_shipping_zip_code/shipping_zip_codes";
exports.labelUserEmail = "User email *";
exports.labelPassword = "Password *";
exports.togglePasswordVisibility = "toggle password visibility";
exports.save = "Save";
exports.labelLogisticApiKey = "Logistic api key";
exports.labelClientSecret = "Client Secret";
exports.labelClientID = "Client ID";
exports.labelClient = "Client";
exports.labelBaseUrl = "Base url";
exports.labelOAuthSiteUrl = "Oauth site url";
exports.configurationSetup = "Configuration setup";
exports.active = "Active";
exports.notActive = "Not Active";
exports.activateYourAccount = "Please activate your account";
exports.activateAccount = "Activate account";
exports.edit = "Edit";
exports.consoleErrorBase = "Async: Could not copy text: ";
exports.copied = "Copied !";
exports.shippingIntegrationsEndpoint = "/bx_block_shiprocket_india/shiprocket_india_configurations";
exports.shiprocketIndiaIntegrationEndpoint = "/bx_block_shiprocket_india/shiprocket_india_configurations"
exports.shiprocketUAEIntegrationEndpoint = "/bx_block_shiprocket_uae/shiprocket_uae_configurations"
exports.stuartUKIntegrationEndpoint = "/bx_block_stuart/stuart_configurations"
exports.countryDataApi = "/bx_block_store_details/store_profile";
exports.importCsv = "Import CSV";
exports.downloadCsvAPI = "bx_block_shipping_zip_code/shipping_zip_codes/download_sample_csv";
exports.uploadCsvAPI = "bx_block_shipping_zip_code/shipping_zip_codes/upload_csv";
exports.errorOnCsvUpload = "Error on CSV Upload";
exports.csvFileError = "Select CSV file";
exports.textShiprocket = "Shiprocket"
exports.textStuart = "Stuart"
exports.textEdit = "Edit"
exports.textCopied = "Copied"
exports.textCouldNotCopy = "Could not copy text"
exports.textThisFieldIsRequired = "This field is required."
exports.textStuartFormClientId = "Stuart Client Id *"
exports.textStuartFormClientSecret = "Stuart Client Secret *"
exports.textStuartFormBaseUrl = "Stuart Base Url *"
exports.textErrorOnInitialize = "Error on page initialization"
exports.textIntegrationNotAvailable = (country) => `3rd party integration will be available soon for ${country}`
exports.deliveryIntegration="Delivery Integration"
exports.labelDeliveryArea="Delivery Area"
exports.labelExcludedZones="Excluded Zones"
exports.deliveryTitle="Delivery"
// Customizable Area End