// Customizable Area Start
import * as React from "react";
import AccountDetailsController from "./AccountDetailsController.web";
import "../assets/accountDetails.css";
import "../assets/account.css";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import {
  withStyles,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import MenuItem from "@material-ui/core/MenuItem";
import { Dialog } from "../../ss-cms-common-components/src/Dialog/Dialog";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout"
import { FlagIconUK, FlagIconIndia, FlagIconUS, FlagIconUE } from "./assets";

export const configJSON = require("./config");

const countryCodes = [
  { flag: FlagIconUK, flagValue: configJSON.flagValueUK, code: configJSON.phonoCodeUK },
  { flag: FlagIconIndia, flagValue: configJSON.flagValueIndia, code: configJSON.phonoCodeIndia },
  { flag: FlagIconUS, flagValue: configJSON.flagValueUS, code: configJSON.phonoCodeUS },
  { flag: FlagIconUE, flagValue: configJSON.flagValueUE, code: configJSON.phonoCodeUE },
];
export class AccountDetails extends AccountDetailsController {
  render() {
    const { classes } = this.props;
    const {
      showPassword,
      showConfirmPassword,
      errors,
      showPasswordChangeModal,
      passwordErrors,
      passwordInput,
      confirmPasswordInput,
    } = this.state;
    const {
      email,
      name,
      country_phone_code,
      phone_number,
    } = this.state?.accountDetails;

    const passwordType = showPassword ? "text" : "password";
    const PasswordIcon = showPassword ? (
      <Visibility style={{ fontSize: "1.2rem" }} />
    ) : (
      <VisibilityOff style={{ fontSize: "1.2rem" }} />
    );
    const confirmPasswordType = showConfirmPassword ? "text" : "password";
    const ConfirmPasswordIcon = showConfirmPassword ? (
      <Visibility style={{ fontSize: "1.2rem" }} />
    ) : (
      <VisibilityOff style={{ fontSize: "1.2rem" }} />
    );

    const getErrorStatus = (fieldName: string) => {
      return errors[fieldName as keyof typeof errors] !== "";
    };
    const getErrorMessage = (fieldName: string) => {
      return errors[fieldName as keyof typeof errors];
    };

    return (
      <div className="account-detail-main-container">
        <div className="header">
          <Link to={configJSON.homeLink} className="account-back-link">
            <ArrowBackIcon className="account-arrow-back-icon" />
            <label className="account-label-back">
              {configJSON.backButtonLabel}
            </label>
          </Link>
          <div className="account-header">{configJSON.profileTitle}</div>
        </div>
        <div className="account-detail-container justify-content-between p-5">
          <label className="label-account-details">
            {configJSON.profileTitle}
          </label>
          <div className="d-flex flex-column pt-3 pb-1">
            <label className="input-label">{configJSON.emailLabel} *</label>
            <TextField
              InputProps={{
                className: classes?.textField,
              }}
              name="email"
              value={email}
              error={getErrorStatus("email")}
              helperText={getErrorMessage("email")}
              placeholder={configJSON.emailPlaceholder}
              variant="outlined"
              onChange={this.handleChangeInput}
            />
          </div>
          <div className="d-flex flex-column pt-3 pb-1">
            <label className="input-label">{configJSON.nameLabel} *</label>
            <TextField
              InputProps={{
                className: classes?.textField,
              }}
              name="name"
              value={name}
              error={getErrorStatus("name")}
              helperText={getErrorMessage("name")}
              placeholder={configJSON.namePlaceholder}
              variant="outlined"
              onChange={this.handleChangeInput}
            />
          </div>
          <div className="d-flex flex-column pt-3 pb-1">
            <label className="input-label">{configJSON.phoneLabel}</label>
            <div className="account-detail-phone-container">
              <TextField
                name="country_phone_code"
                className={classes?.textFieldCountryCode}
                select
                InputProps={{
                  className: classes?.textField,
                }}
                variant="outlined"
                value={country_phone_code}
                onChange={this.handleChangeInput}
              >
                {countryCodes?.map((data) => (
                  <MenuItem key={data.flagValue} value={data.code}>
                    <img
                      style={{
                        display: "inline-block",
                        width: "20px",
                        marginRight: 6,
                      }}
                      src={data.flag}
                    />
                    {data.code}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                className={classes?.textFieldPhone}
                InputProps={{
                  className: classes?.textField,
                }}
                name="phone_number"
                value={phone_number}
                placeholder={configJSON.phonePlaceholder}
                variant="outlined"
                error={getErrorStatus("phone_number")}
                helperText={getErrorMessage("phone_number")}
                onChange={this.handleChangeInput}
              />
            </div>
          </div>
          <Button
            className="password-change-button"
            onClick={() => this.setOpenPasswordChangeModel(true)}
          >
            {configJSON.changePassword}
          </Button>

          <Dialog
            open={showPasswordChangeModal}
            setOpen={this.setOpenPasswordChangeModel}
            onSubmit={this.handleSubmitPasswordChange}
            title={configJSON.changePassword}
            customContent
            okay="Change"
            ignoreClickAway
            confirmDisabled={
              !(
                passwordErrors.password === "" &&
                passwordErrors.confirmPassword === "" &&
                passwordInput === confirmPasswordInput
              )
            }
          >
            <div className="change-password-dialog" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: "20px" }}>
              <div className="d-flex flex-column pt-3 pb-1" style={{ width: "calc(100% - 80px)", gap: "6px"}}>
                <label className="input-label">
                  {configJSON.passwordLabel} *
                </label>
                <TextField
                  autoComplete="off"
                  name="password"
                  value={passwordInput}
                  type={passwordType}
                  error={passwordErrors.password !== ""}
                  helperText={
                    passwordErrors.password ||
                    "Must contain 8 char, 1 uppercase, 1 digit, 1 symbol"
                  }
                  InputProps={{
                    className: classes?.textField,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          style={{ fontSize: "1.2rem" }}
                          onClick={this.handleClickShowPassword}
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {PasswordIcon}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={this.handleChangePassword}
                  variant="outlined"
                />
              </div>

              <div className="d-flex flex-column pt-3 pb-1" style={{ width: "calc(100% - 80px)", gap: "6px"}}>
                <label className="input-label">
                  {configJSON.passwordConfirmation} *
                </label>
                <TextField
                  autoComplete="off"
                  name="confirmPassword"
                  value={confirmPasswordInput}
                  type={confirmPasswordType}
                  error={passwordErrors.confirmPassword !== ""}
                  helperText={passwordErrors.confirmPassword}
                  variant="outlined"
                  InputProps={{
                    className: classes?.textField,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          style={{ fontSize: "1.2rem" }}
                          onClick={this.handleClickShowConfirmPassword}
                          onMouseDown={this.handleMouseDownConfirmPassword}
                        >
                          {ConfirmPasswordIcon}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={this.handleChangePassword}
                />
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    );
  }
}

const styles = () => ({
  textField: {
    width: "100%",
    height: 48,
    color: "#3C3E49",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 14,
  },
  textFieldCountryCode: {
    minWidth: "25%",
    display: "flex",
  },
  textFieldPhone: {
    minWidth: "74%",
    display: "flex",
  },
});

export default withLayout(withStyles(styles)(
  withHeaderBar(withLoader(withDialog(AccountDetails)))
));
// Customizable Area End