Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "adminconsole";
exports.labelBodyText = "adminconsole Body";

exports.btnExampleTitle = "CLICK ME";

exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpPutType = "PUT";
exports.httpDeleteType = "DELETE";

exports.adminLoginAPIEndPoint = "bx_block_login/logins"
// Customizable Area End