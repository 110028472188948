Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "InventoryManagement";
exports.labelBodyText = "InventoryManagement Body";
exports.InventoryEndPoint = "bx_block_inventory_management/products";

exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpPutType = "PUT";
exports.httpDeleteType = "DELETE";
exports.validateFormData = "multipart/form-data";

exports.getProductsAPIEndPoint = "bx_block_catalogue/catalogues";
exports.getCategoriesAPIEndPoint = "bx_block_categories/categories";
exports.addCategoryAPIEndPoint = "bx_block_categories/categories?[categories][name]=";
exports.searchProductAPIEndPoint = "bx_block_categories/categories/product_search?search="
exports.searchSuggestiveProductAPIEndPoint = "bx_block_catalogue/catalogues/suggestive_search?product_name="

exports.btnExampleTitle = "CLICK ME";
exports.searchItemPlaceholder = "Search our menu library to view and add items. Eg-Pizza, Biryani, etc";
exports.noResultFoundText= "No results found";
exports.noResultFoundTryAgain= "Revise search text and try again";
exports.cancel= "Cancel";
exports.back= "Back";
exports.next= "Next";
exports.weFoundInitials = "We found ";
exports.foundMessage= " items matching your search criteria. Please review and update the information as necessary.";
exports.description= "Description";
exports.itemName= "Item Name";
exports.price= "Price";
exports.category="Category";
exports.noItemsTitle = "Add your first menu items";
exports.noItemsDescription = "To get started, select the option that suits you the best.";
exports.addItem = "Add Item";
exports.addItemMsg= "Create your menu items one at a time.";
exports.builderLibrary = "Builder Library";
exports.builderLibraryMsg= "Select from our wide range of pre-defined menu items.";
exports.importMenu = "Import Menu";
exports.importMenuMsg = "Quikly add multiple menu items from your spreadsheet.";
exports.filterItems = "Filter items"
exports.itemName= "Item Name"
exports.reviewItemsMessage1 = "You have selected";
exports.reviewItemsMessage2 = "items to add to your menu. Take a moment to review your choices. Once you're satisfied, click on the Import button below to finish.";
exports.image = "Image";
exports.description = "Description"
exports.price = "Price"
exports.category = "Category"
exports.mainLoadingMessage= "Great Choice. We're Preparing Your Selection";
exports.sub1LoadingMessage= "This might take a few moments – we're making sure everything looks perfect."
exports.sub2LoadingMessage="Thank you for your patience."
exports.import = "Import"
exports.importSuccessMessage = "Items added successfully. Update further as necessary and activate them to display on your website."
exports.image= "Image"
exports.addCategory= "+ Add"
exports.packageDetails = "Packaging Details *"
exports.packageHeight = "Height (cm)"
exports.packageWidth = "Length (cm)"
exports.packageBreadth = "Breadth (cm)"
exports.packageWeight = "Weight (Kg)"
exports.itemValidations = {
  heightRequired:"Height is Required",
  widthRequired:"Length is Required",
  breadthRequired:"Breadth is Required",
  weightRequired:"Weight is Required",
  onlyNumberAllowed:"Only numbers are allowed",
  greaterThanZero:"Must be greater than 0"
}

exports.STORED_SEACHDATA_KEY = "itemsFromLibrary";


exports.getVariantsAPIEndPoint = "bx_block_catalogue/catalogues_variants/search_variant?search_variant=";
exports.deleteVariantAPIEndPoint = "bx_block_catalogue/catalogues_variants/";
exports.getSearchedMenuCategories = "bx_block_menu_ordering/menus?keyword=";
exports.getCurrencySymbolApiEndPoint = "bx_block_store_details/fetch_currency_symbol";
exports.uploadLibraryItemsApiEndPoint = "bx_block_menu_ordering/menus/upload_items";
// Customizable Area End
