// Customizable Area Start
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  fullWidth: {
    width: "100%",
  },
  inputLabelWrapper: {
    whiteSpace: "nowrap",
    display: "flex",
    justifyContent: "space-between",
  },
  inputLabel: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    color: "#000000",
    marginBottom: "8px",
  },
  disabledInputLabel: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    color: "#83889E",
    marginBottom: "8px",
  },
  resizeable: {
    resize: "vertical",
  },
});
// Customizable Area End