// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { createRef } from "react";
import { customerObject } from "./CustomerListWebController";
import * as Yup from "yup";
import { getStorageData } from "../../../framework/src/Utilities";
import MessageEnum, {getName} from "../../../framework/src/Messages/MessageEnum";


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  id: string;
  // Customizable Area Start
  classes: Record<string, string>,
  goToBack : (apicall:boolean)=>void,
  customerData : customerObject | null
  // Customizable Area End
}


interface S {
  // Customizable Area Start
  formInitialValues : {
    recive_update: boolean,
    phone_number : string | number,
    email: string,
    full_name : string,
    countryCode:string
  },
  showSubmit:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomerEditWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  
  editCustomerApiCallId:string="";
  submitRef = createRef<HTMLButtonElement>();
  
  formSchema = Yup.object().shape({
    full_name: Yup.string()
      .required("FullName is required."),
    email: Yup.string().email('Email must be in valid format.').required("Email is required."),
    countryCode:Yup.string(),
    phone_number: Yup.string().when("countryCode", (code:string) => {
        if(code === 'ae') return Yup.string().matches(configJSON.mobileRegex.ae, 'Invalid phone number').required("Phone number is required.")
        else return Yup.string().matches(configJSON.mobileRegex.in, 'Invalid phone number').required("Phone number is required.")
        }),
    recive_update: Yup.boolean()
  });
  

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    // Customizable Area End
  


    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      formInitialValues : {
        email: '',
        full_name : '',
        recive_update: false,
        phone_number : '',
        countryCode : ''
      },
      showSubmit: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (responseJson && responseJson.data) {
        this.handleAllApiResponse(apiRequestCallId);
      }
    }
    
    // Customizable Area End
  }


  // Customizable Area Start

  async componentDidMount() {
    if(this.props.customerData){
      let {customerData:{attributes:{email,full_name,recive_update,contact_number,country_name:{code}}}} = this.props
      this.setState({formInitialValues:{...this.state.formInitialValues, email,full_name,phone_number:contact_number,recive_update,countryCode:code}})
    }
  }
  
  discardBtn = (even:unknown , reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.props.goToBack(false)
  }

  editCustomer = async(values:{
    full_name : string,
    email: string,
    phone_number : string | number,
    recive_update: boolean
  })=>{
    let {full_name, email, phone_number, recive_update} = values
    const token = await getStorageData("admintoken");
    const header = {
      "Content-Type": configJSON.editCustomerListApiContentType ,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editCustomerApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editCustomerListApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.editCustomerListApiEndpoint}/${this.props.customerData?.id}?full_name=${full_name}&email=${email}&phone_number=${phone_number}&recive_update=${recive_update}`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleAllApiResponse = (apiRequestCallId:string)=>{
    if(apiRequestCallId==this.editCustomerApiCallId){
      this.handleEditCustomerResp();
    }
  }

  handleEditCustomerResp = () =>{
    this.props.goToBack(true)
  }

  ShowSubmitPopup = ()=>{
    this.setState({showSubmit:true})
  }

  // Customizable Area End
}
