// Customizable Area Start
//@ts-nocheck
import React from "react";
import {
    Box,
    Checkbox,
    Grid,
    TextField,
    Typography,
    Button
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AdminLoginController, { Props } from "./AdminLoginController.web";
import withStyles, { CSSProperties } from "@material-ui/core/styles/withStyles";
import { Formik } from "formik";
import * as Yup from "yup";
import Loader from "../../../components/src/Loader.web";
import AlertMessage from "../../../components/src/AlertMessage.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
    typography: {
        fontFamily: "Rubik",
    }

});

const PurpleCheckbox = withStyles({
    root: {
        '&$checked': {
            color: "#364F6B",
        },
    },
    checked: {},
})((props) => <Checkbox style={{ marginLeft: "-9px" }} color="default" {...props} />);

const validationSchema = Yup.object().shape({
    admin_email: Yup.string().email('Invalid email address')
        .required("Email is required"),
    admin_password: Yup.string()
        .required("Password is required"),
})

export class AdminLogin extends AdminLoginController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const { classes } = this.props;
        return (
            <>
                <Loader loading={this.state.loading} />
                <AlertMessage data-testId="alertMessage" open={this.state.openAlert} success={this.state.alertSuccess} message={this.state.alertMessage} close={this.handleAlertMessageClose} />
                <ThemeProvider theme={theme}>
                    <Grid container item lg={12} md={12} xs={12} className={classes.root}>
                        <Grid>
                            <Typography className={classes.storeName}>&lt; Store Name &gt; </Typography>
                        </Grid>
                        <Box className={classes.adminLoginBox}>
                            <Formik
                                initialValues={{
                                    admin_email: this.state.admin_email,
                                    admin_password: this.state.admin_password,
                                    remember_me: this.state.remember_me
                                }}
                                data-testId="adminloginformik"
                                onSubmit={(values) => {
                                    this.adminLogin();
                                }}
                                validationSchema={validationSchema}
                            >
                                {({
                                    errors,
                                    touched,
                                    values,
                                    handleBlur,
                                    setFieldValue,
                                    handleSubmit,
                                }) => (
                                    <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                        <Typography
                                            component="h5"
                                            style={{
                                                fontWeight: "500",
                                                fontSize: "20px",
                                                lineHeight: "28px",
                                                paddingBottom: "20px",
                                                color: "#3C3E49"
                                            }}
                                        >
                                            Login
                                        </Typography>
                                        <Grid
                                            item
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            className={classes.bottomSpace}
                                        >
                                            <Typography
                                                variant="h6"
                                                component="h6"
                                                className={classes.titleText}
                                            >
                                                Email
                                            </Typography>
                                            <TextField
                                                value={values.admin_email}
                                                type="text"
                                                name="admin_email"
                                                data-testId="admin_email"
                                                className={classes.placeholderText}
                                                onChange={(e) => {
                                                    this.setState({
                                                        admin_email: e.target.value,
                                                    });
                                                    setFieldValue("admin_email", e.target.value);
                                                }}
                                                error={Boolean(touched.admin_email && errors.admin_email)}
                                                onBlur={handleBlur}
                                                helperText={touched.admin_email && errors.admin_email}
                                                fullWidth
                                                variant="outlined"
                                                placeholder="Enter email"
                                            />
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} className={classes.bottomSpace}>
                                            <Typography
                                                variant="h6"
                                                component="h6"
                                                className={classes.titleText}
                                            >
                                                Password
                                            </Typography>
                                            <TextField
                                                value={values.admin_password}
                                                type="text"
                                                name="admin_password"
                                                data-testId="admin_password"
                                                className={classes.placeholderText}
                                                onChange={(e) => {
                                                    this.setState({
                                                        admin_password: e.target.value,
                                                    });
                                                    setFieldValue("admin_password", e.target.value);
                                                }}
                                                error={Boolean(touched.admin_password && errors.admin_password)}
                                                onBlur={handleBlur}
                                                helperText={touched.admin_password && errors.admin_password}
                                                fullWidth
                                                variant="outlined"
                                                placeholder="Enter password"
                                            />
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} style={{ display: "flex", alignItems: "center" }} className={classes.bottomSpace}>
                                            <Grid sm={6} style={{ display: "flex", alignItems: "center" }}>
                                                <PurpleCheckbox
                                                    value={values.remember_me}
                                                    data-testId="rememberme"
                                                    onClick={() => { this.setState({ remember_me: !this.state.remember_me }, () => { setFieldValue("remember_me", this.state.remember_me) }); }}
                                                    className={classes.rememberCheckbox}
                                                    checked={Boolean(this.state.remember_me)}
                                                    icon={<span className={classes.icon} />}
                                                    checkedIcon={<span className={classes.checkedIcon} />}
                                                /><Typography className={classes.TypographyText}>Remember me</Typography></Grid>
                                            <Grid sm={6} style={{ display: "flex", justifyContent: "end" }} data-testId="loginbtn" onClick={() => { this.redirectNavigation("ForgetPassword") }}><Typography style={{ textDecoration: "underline", cursor: "pointer" }} className={classes.TypographyText}>Forgot password</Typography></Grid>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}> <Button variant="contained" type="submit" className={classes.loginBtn}><Typography style={{
                                            fontSize: "16px",
                                            fontWeight: 500,
                                            lineHeight: "24px",
                                            color: "#3C3E49"
                                        }}>Login</Typography></Button></Grid>
                                    </form>
                                )}
                            </Formik>
                        </Box>
                        <Grid style={{ marginTop: "40px" }}><Typography className={classes.contactUs}>Having trouble logging in? Contact us xxx</Typography></Grid>
                    </Grid>
                </ThemeProvider>
            </>
        );
    }
}

const useStyles: Record<string, CSSProperties> = {
    root: {
        display: "flex",
        background: "#FAFAFA",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    },
    loginBtn: {
        "&:hover": {
            boxShadow: "none",
            background: "#00D659",
        },
        background: "#00D659",
        borderRadius: "3px",
        width: "100%",
        border: "none",
        padding: "12px",
        cursor: "pointer",
        boxShadow: "none",
        textTransform: "none",
    },
    placeholderText: {
        "& input, & textarea, & div": {
            fontFamily: "Rubik",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "22px",
            color: "#64748B",
        },
    },
    titleText: {
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "20px",
    },
    storeName: {
        marginBottom: "20px",
        fontSize: "28px",
        fontWeight: 500,
        lineHeight: "32px",
        letterSpacing: "-0.02em",
        color: "#3C3E49"
    },
    checkedIcon: {
        backgroundColor: '#364F6B',
        borderRadius: "3px",
        '&:before': {
            display: 'block',
            borderRadius: "3px",
            width: 21.6,
            height: 21.6,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
    },
    bottomSpace: {
        marginBottom: "20px"
    },
    icon: {
        border: "1px solid #64748B",
        borderRadius: "3px",
        height: "20px",
        width: "20px",
    },

    adminLoginBox: {
        width: "448px",
        background: "#FFF",
        border: "2px solid #ECEDF0",
        borderRadius: "3px",
        padding: "40px 20px"
    },
    TypographyText: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        color: "#3C3E49"
    },
    contactUs: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        color: "#83889E"
    },
    rememberCheckbox: {
        "&:hover": {
            background: "none",
        },
        "&.Mui-checked": {
            color: "#6200EA",
        },
    },
}

export default withStyles(useStyles)(AdminLogin);

// Customizable Area End