import React from "react";

import {
  Box,
  Typography,
  InputAdornment,

  // Customizable Area Start
  Grid,
  TextField,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  MenuItem,
  FormControl,
  Select,
  withWidth,
  styled,
  CircularProgress,
  IconButton
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { eyeIcon, printIcon, searchIcon, noOrdersIcon, menuitem, nocategories } from './assets';
import withStyles, { CSSProperties } from "@material-ui/core/styles/withStyles";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout";
import FilterListIcon from '@material-ui/icons/FilterList';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import OrderListController, { OrderStatusObjectType, Props, configJSON, orderStatusObj, orderStatusObjKey } from "./OrderListController.web";
import SectionLoader from "../../inventorymanagement/src/SectionLoader.web";
import PaginationComponent from '../../ss-cms-common-components/src/Pagination/Pagination'
import moment from "moment";
import OrderListingFliterModal from "./OrderListingFliterModal.web";
import OrderDetail  from "./OrderDetail.web";
import AlertMessage from "../../../components/src/AlertMessage.web";
import Scrollbars from "react-custom-scrollbars";
import { Alert } from '@material-ui/lab';
import { OrderDetailDataType, OrderItem } from "./OrderDetailController.web";

export const SelectComponent = styled(Select)(({ theme }) => ({
  "& .MuiSelect-select:focus": {
    backgroundColor: "rgba(0,0,0,0)",
  },
  "& .MuiSelect-selectMenu": {
    height: 0
  }
}));
// Customizable Area End



export class OrderList extends OrderListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  noOrderBlock = (classes: Record<string, string>, isMobile: boolean) => {
    return (
      <Grid
        item
        lg={12}
        md={12}
        xs={12}
        style={webStyles.viewNoOrder}
      >
        <Box className={classes.noOrdersBox} style={{ backgroundColor: this.getBackgroundColorNoOrder(isMobile) }}>
          <Grid item className={classes.ordersContent}>
            <img src={noOrdersIcon} alt="no products" style={webStyles.noOrderIcon} />
            <Typography
              data-test-id="noorders"
              variant="h5"
              component="h2"
              style={webStyles.noOrderTitle}
            >
              {configJSON.noOrder}
            </Typography>
            <Typography style={webStyles.font400} className={classes.noordersText}>
              {configJSON.noOrderDescription}
            </Typography>
          </Grid>
        </Box>
      </Grid>
    );
  };

  renderMobileOrders = (classes: Record<string, string>, isMobile: boolean) => {
    return this.state.ordersData &&
      this.state.ordersData.length > 0 && !this.state.filterOrderLoading ? (
      this.state.ordersData.map((data: OrderDetailDataType, index: number) => {
        return (
          <Grid container spacing={0} style={webStyles.viewMobileOrder} key={`MOBILE_${index}`}>
            <Grid item xs={12} className={classes.itemdetails}>
              <Grid container justifyContent="center" className={classes.iteminfo}>
                <Grid item xs={12}>
                  <Grid container className={classes.padding16} style={webStyles.marginTop16}>
                    <Grid container xs={5} style={webStyles.column}>
                      <Grid item>
                        <Typography style={webStyles.font400} className={classes.fieldtitle}>
                          Customer Name
                        </Typography>
                        <Typography className={classes.fieldvalue} >
                          {`${data.attributes.account.attributes.full_name}`}
                        </Typography>
                      </Grid>
                      <Grid item style={webStyles.marginTop16}>
                        <Typography style={webStyles.font400} className={classes.fieldtitle}>
                          Order Number
                        </Typography>
                        <Typography className={classes.fieldvalue}>
                          {data.attributes.order_number}
                        </Typography>
                      </Grid>
                      <Grid item style={webStyles.marginTop12}>
                        <Typography style={webStyles.font400} className={classes.fieldtitle}>
                          Total with tax
                        </Typography>
                        <Typography className={classes.fieldvalue}>
                          {`${this.state.currencyType}${Number(data.attributes.total).toFixed(2)}`}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container xs={6} style={webStyles.column}>
                      <Grid item>
                        <Typography style={webStyles.font400} className={classes.fieldtitle} >
                          Order Date
                        </Typography>
                        <Typography className={classes.fieldvalue}>
                          {moment(data.attributes.order_date).format("DD/MM/YYYY, HH:mm")}
                        </Typography>
                      </Grid>
                      <Grid item style={webStyles.marginTop16}>
                        <Typography style={webStyles.font400} className={classes.fieldtitle}>
                          Status
                        </Typography>
                        {this.renderSelect(classes, data, index)}
                      </Grid>
                    </Grid>

                    <Grid container xs={1} className={classes.activityIcons}>
                      <img
                        src={eyeIcon}
                        style={webStyles.eyeIcon}
                        data-testId="editProductDetails"
                        onClick={() => { this.orderDetail(data.attributes.id) }}
                        alt="edit icon"
                      />
                      <img
                        src={printIcon}
                        className={classes.printIcon}
                        data-testId="editProductDetails"
                        alt="print icon"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.orderDetails}>
              <Typography style={webStyles.font400} className={classes.fieldtitle}>
                Items Ordered
              </Typography>
              {this.viewVariantList(data)}
            </Grid>
          </Grid>
        );
      })
    ) : (
      <>
        {this.state.filterOrderLoading ? (
          <div className={classes.mobileProgressContainer}>
            <CircularProgress />
          </div>
        ) : (
          <div className={classes.mobileNoFilterOrderContainer}>{this.noOrders(classes, isMobile)}</div>
        )}
      </>
    )
  }

  viewVariantList(data: OrderDetailDataType) {
    const { classes } = this.props
    return (
      <div style={webStyles.flexColumn}>
        {
          data.attributes.order_items && data.attributes.order_items.length > 0 && data.attributes.order_items.map((ele: OrderItem) => (
            <div key={`order_${ele.id}`} style={webStyles.viewVariant}>
              <Typography className={classes.fieldvalue} style={webStyles.shrink}>
                {`${ele.attributes.catalogue.attributes.name} x ${ele.attributes.quantity}`}
              </Typography>
              {ele.attributes.catalogue_variants?.length > 0 &&
                <>
                  <Typography className={classes.fieldvalue} style={webStyles.textVariant}>
                    -
                  </Typography>
                  <Typography className={classes.fieldvalue} style={webStyles.wrap}>
                    {this.getVariants(ele.attributes.catalogue_variants)}
                  </Typography>
                </>
              }
            </div>
          ))}
      </div>
    )
  }

  renderDesktopOrders = (classes: Record<string, string>, isMobile: boolean) => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.headerText}>Customer Name</TableCell>
            <TableCell className={classes.headerText}>Order number</TableCell>
            <TableCell className={classes.headerText}>Order date</TableCell>
            <TableCell className={classes.headerText}>Items Ordered</TableCell>
            <TableCell className={classes.headerText}> Status </TableCell>
            <TableCell className={classes.headerText}>Total (with Tax)</TableCell>
            <TableCell className={classes.headerText} align="right">
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody style={webStyles.white}>
          {this.state.ordersData &&
            this.state.ordersData.length > 0 && !this.state.filterOrderLoading ? (
            this.state.ordersData.map((data: OrderDetailDataType, index: number) => {
              return (
                <TableRow
                  key={data.id}
                  style={webStyles.tableRow}
                  data-test-id="tablerows"
                >
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.dataText}
                  >
                    {data.attributes.account.attributes.full_name}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.dataText}
                  >
                    {data.attributes.order_number}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.dataText}
                  >
                    {moment(data.attributes.order_date).format("DD/MM/YYYY, HH:mm")}
                  </TableCell>
                  <TableCell component="th" scope="row" width={"25%"}>
                    {this.viewVariantList(data)}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.dataText}
                    width={"10%"}
                  >
                    {this.renderSelect(classes, data, index)}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={webStyles.totalText}
                    width={"13%"}
                  >
                    {`${this.state.currencyType}${Number(data.attributes.total).toFixed(2)}`}
                  </TableCell>
                  <TableCell align="right" width={"10%"}>
                    <img
                      src={printIcon}
                      className={classes.printIconWeb}
                      data-testId="orderprint"
                      alt="print icon"
                    />
                    <img
                      src={eyeIcon}
                      style={webStyles.iconEdit}
                      data-testId="orderEdit"
                      onClick={() => { this.orderDetail(data.attributes.id) }}
                      alt="order edit icon"
                    />
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <>
              {this.state.filterOrderLoading ? (
                <TableRow style={webStyles.rowLoading}>
                  <TableCell colSpan={7} style={webStyles.cellLoading} >
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow style={webStyles.tableRow}>
                  <TableCell component="th" scope="row" colSpan={8}>
                    {this.noOrders(classes, isMobile)}
                  </TableCell>
                </TableRow>
              )}
            </>
          )}
        </TableBody>
      </Table>
    )
  }

  renderSelect(classes: Record<string, string>, data: OrderDetailDataType, index: number) {
    return (
      <FormControl variant="filled">
        <SelectComponent
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          data-test-id={`selectelement${index}`}
          value={this.handleValueSelect(data.attributes.status)}
          onChange={(e) => this.handleChange(data.id)}
          className={classes.customdropdown}
          style={{
            color: this.getStatusTextColor(data.attributes.status),
            backgroundColor: this.getStatusColor(data.attributes.status),
          }}
          IconComponent={
            () => (
              <ExpandMoreIcon 
                style={{ ...webStyles.iconSelect , ...{
                  color: this.getStatusTextColor(data.attributes.status)
                }}} 
              />
            )
          }
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}
        >
          {this.state.orderStatus.map((item: OrderStatusObjectType) =>
            <MenuItem
              className={classes.dropdownitem}
              style={{
                backgroundColor: item.color,
                color: item.textColor
              }}
              key={item.name}
              value={item.value}
              onClick={() => this.selectOption(item.key)}
            >
              {item.value}
            </MenuItem>
          )}
        </SelectComponent>
      </FormControl>
    )
  }

  noOrders = (classes: Record<string, string>, isMobile: boolean) => {
    return (
      <Grid
        item
        lg={12}
        md={12}
        xs={12}
        style={{ ...webStyles.viewNoOrderMargin, ...{ background: this.getBackgroundColorNoOrder(isMobile) }}}
      >
        <Grid item className={classes.ordersContent}>
          <Typography style={webStyles.marginBot10}>
            <img src={this.filterNoDataMessage() ? menuitem : nocategories} alt="no orders" />
          </Typography>
          <span
            data-test-id="noResultsfound"
            style={webStyles.textNoResult}
          >
            {configJSON.noResultsFoundMsg}
          </span>
          <span style={webStyles.textFilterNoData}>
            {this.filterNoDataMessage() ? configJSON.noResultMatch :configJSON.reviseSearch}
          </span>
        </Grid>
      </Grid>

    )
  }

  renderCSVButton = (classes: string, testId: string) => {
    return (
      <button data-test-id={`download_csv_button_${testId}`} className={classes} onClick={this.downloadCsvFile}>Download CSV</button>
    )
  }

  renderChipButton = (label: string, number: number, deleteChip: () => void, testId: string) => {
    let { classes } = this.props
    return (
      <div className={classes.chipcontainer}>
        {label}
        <IconButton data-test-id={`${testId}_${number}`} size="small" onClick={deleteChip} style={webStyles.viewCloseIcon}>
          <CloseIcon fontSize="inherit" style={webStyles.colorCloseIcon} />
        </IconButton>
      </div>
    )
  }

  renderUpperContent(classes: Record<string, string>) {
    let { filterPayload: { orderStatus } } = this.state
    return (
      <div className={classes.parentDiv}>
        <Grid
          item
          lg={12}
          md={12}
          xs={12}
          style={webStyles.flex}
        >
          <Grid item lg={12} md={12} xs={12}>
            <Box width='100%' display='flex' justifyContent='space-between' alignItems='center'>
              <span
                data-test-id="title"
                style={webStyles.orderTitle}
                className="order-listing-title"
              >
                {configJSON.pageTitle}
              </span>
              {this.renderCSVButton(`${classes.csvDownloadBtn} ${classes.hideMobCsvBtn}`, 'pc')}
            </Box>
          </Grid>
        </Grid>
        {/* Placement for store availability alert */}
        { !this.state.isStoreAvailable && 
          <Box className={classes.storeAvailabilityContainer}>
            <Alert icon={false} className={`${classes.storeAvailabilityAlertBox}`}>
                <Typography className={"warningText"}>
                    {configJSON.storeNotAvailable1} <span className='Link' onClick={this.goToHome}>{configJSON.home}</span> {configJSON.storeNotAvailable2}
                </Typography>
            </Alert>
          </Box>
        }
        {/* Placement for ship rocket alert fail*/}
        {this.state.shipRocketMessage !== "" && 
          <Box className={classes.storeAvailabilityContainer}>
            <Alert 
              data-test-id="shipRocketMessage"
              icon={false} className={this.state.shipRocketMessage === configJSON.shipRocketFailed ? `${classes.shipRocketFailed}` : `${classes.storeAvailabilityAlertBox}`}
              onClose={() => this.setState({ shipRocketMessage: '' })}
            >
                <Typography className={"warningText"}>
                    {this.state.shipRocketMessage}
                </Typography>
            </Alert>
          </Box>
        }
        {/* Placement for store availability alert End*/}
        <Grid lg={12} md={12} xs={12} className="order-listing-search">
          {/* filter  */}
          <Box width='100%' display='flex'>
            {/* filter btn */}
            <Box data-test-id="filter_btn" className={classes.filterContainer} onClick={this.showHideFilterModal}>
              <button className={`${classes.filterBtn} ${classes.filterBtnPc}`}>Filter orders <ExpandMoreIcon /></button>
              <button className={`${classes.filterBtn} ${classes.filterBtnMob}`}><FilterListIcon /></button>
            </Box>

            {/* search Input */}
            <Box className={classes.searchContainer}>
              <TextField
                data-test-id="searchOrderInput"
                className={`${classes.placeholderText} ${classes.OrderInputStyle} `}
                placeholder="Search orders"
                variant="standard"
                fullWidth
                value={this.state.filterPayload.searchText}
                onChange={this.updateSearchText}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={searchIcon}
                        alt="searchIcon"
                        style={webStyles.paddingLeft}
                      />
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
              />
            </Box>
          </Box>
          {/* ------ */}
          <Box width='100%' display='flex' marginTop='10px' alignItems={"flex-start"}>
            {/* filter option wrapper */}
            <Box display='flex' className={classes.filterOptionWrapper}>
              {/* title */}
              {this.renderDatePayloadChip().length || orderStatus.length ? <div className={classes.filterLable}>Filters:</div> : null}
              {/* option  */}

              {this.renderDatePayloadChip().map((label, number) => {
                return this.renderChipButton(label, number, this.removeDateFilter(), 'date_chip')
              })}

              {orderStatus.map((label: orderStatusObjKey, number) => {
                return this.renderChipButton(orderStatusObj[label], number, this.removeFilterOrder(label), 'order_status')
              })}

              {/*  */}
            </Box>
            {/* mobile download csv btn */}
            <Box display='flex' className={classes.mobDowloadCsvWrapper}>
              {this.renderCSVButton(`${classes.csvDownloadBtn} ${classes.csvDownloadBtnMob} `, 'mob')}
            </Box>
          </Box>
        </Grid>
      </div>
    )
  }

  renderPagination(isShow: boolean) {
    if (isShow) {
      return (
        <Grid item lg={12} md={12} xs={12}>
          <PaginationComponent
            count={this.state.paginationData.total_count}
            pageSize={this.state.paginationData.per_page}
            pageNo={this.state.paginationData.current_page}
            itemText={'orders'}
            paginationContainerStyle={webStyles.padding0}
            onChange={(page) => this.pageChange(page)}
            data-test-id="paginationEle"
          />
        </Grid>
      )
    } else return null
  }

  renderOrder(classes: Record<string, string>, isMobile: boolean) {
    if (isMobile) {
      return (
        <Grid item>
          {this.renderUpperContent(classes)}
          <div style={webStyles.viewMobile} className="scroll-order-list">
            <Grid item lg={12} md={12} xs={12} style={webStyles.viewContentMobile}>
              {this.renderMobileOrders(classes, isMobile)}
            </Grid>
            {this.renderPagination(this.state.paginationData && this.state.ordersData.length > 0)}
            <div style={webStyles.gap120} />
          </div>
        </Grid>
      );
    } else {
      return (
        <Grid item style={webStyles.marginTop40}>
          {this.renderUpperContent(classes)}
          <Grid item lg={12} md={12} xs={12}>
            {this.renderDesktopOrders(classes, isMobile)}
          </Grid>
          {this.renderPagination(this.state.paginationData && this.state.ordersData.length > 0)}
          <div style={webStyles.gap30} />
        </Grid>
      )
    }
  }

  renderScreen(classes: Record<string, string>, isMobile: boolean) {
    return this.ordersBlock(classes, isMobile);
  }

  ordersBlock = (classes: Record<string, string>, isMobile: boolean) => {
    if ((this.state.showfilterNodataMessage && !this.state.ordersData.length) || this.state.ordersData.length) {
      if (this.state.isOrderDetail) {
        return (
          <OrderDetail data-testId="OrderDetailBlock" id="orderDetail" currencyType={this.state.currencyType} goToBack={this.goToBack} orderId={this.state.orderId}/>
        )
      } else {
        return (
          <>
            {this.state.filterModal && <OrderListingFliterModal hideModal={this.showHideFilterModal} filterPayload={this.state.filterPayload} updateFilterPayload={this.updateFilterPayload} />}
            {this.renderOrder(classes, isMobile)}
          </>
        )
      }
    } else {
      return this.noOrderBlock(classes, isMobile);
    }
  };

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes, width } = this.props;
    const isMobile = /s/.test(width.toString());
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <AlertMessage
          data-test-id="orderAlertMsg"
          open={this.state.openOrderAlert}
          success={this.state.alertOrderSuccess}
          message={this.state.alertOrderMessage}
          close={this.handleOrderAlertMessageClose}
        />
        {
          isMobile ? (
            <div className={classes.mobileWrapper}>
              <div className={classes.mobileWrapperContainer}>
                <Grid container item lg={12} md={12} xs={12} className={classes.root}>
                  <Grid item lg={11} md={11} xs={11}>
                    {this.state.loading ? (
                      <SectionLoader loading={true} />
                    ) : (
                      this.renderScreen(classes, isMobile)
                    )}
                  </Grid>
                </Grid>
              </div>
            </div>
          ) : (
            <Scrollbars hideTracksWhenNotNeeded={isMobile}>
              <Grid container item lg={12} md={12} xs={12} className={classes.root}>
                <Grid item lg={11} md={11} xs={11}>
                  {this.state.loading ? (
                    <SectionLoader loading={true} />
                  ) : (
                    this.renderScreen(classes, isMobile)
                  )}
                </Grid>
              </Grid>
            </Scrollbars>
          )
        }
      </>
      // Customizable Area End
    );
  }
}

const webStyles = {
  viewNoOrderMargin: { margin: "10px 0px" },
  eyeIcon: {
    cursor: "pointer",
    width: "24px",
    height: "24p"
  } as React.CSSProperties,
  column: {
    flexDirection: "column"
  } as React.CSSProperties,
  noOrderIcon:{
    marginBottom: "10px",
    width: "76px",
    height: "76px"
  },
  noOrderTitle: {
    marginBottom: "5px",
    fontFamily: "Rubik",
    fontSize: "26px",
    fontWeight: "500",
    lineHeight: "31px",
    color: "#363636",
  },
  viewMobileOrder: {
    marginBottom: "8px",
    backgroundColor: "#fff",
    borderRadius: "4px"
  },
  viewNoOrder: {
    display: "flex",
    justifyContent: "center",
    marginTop: "143px"
  },
  font400: { fontWeight: '400 !important' },
  white: { background: "#fff" },
  iconSelect: {
    position: 'absolute',
    right: "8px",
    pointerEvents: "none",
    width: "20px"
  } as React.CSSProperties,
  paddingLeft: { paddingLeft: "15px" },
  flex: {
    display: "flex"
  } as React.CSSProperties,
  gap30: { height: "30px" },
  marginTop40: { marginTop: "40px" },
  marginTop16: { marginTop: "16px" },
  marginTop12: { marginTop: "12px" },
  marginBot10: { marginBottom: "10px" },
  gap120: { height: "120px" },
  padding0: { padding: "0px" },
  wrap: { whiteSpace: "normal"},
  viewMobile: {
    height: "74vh",
    overflowY: "auto",
    overflowX: "hidden",
    scrollbarColor: "#5500CC transparent",
    scrollbarWidth: "auto"
  } as React.CSSProperties,
  viewContentMobile: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: 'column',
    paddingRight: "12px"
  } as React.CSSProperties,
  orderTitle: {
    fontFamily: "Rubik",
    fontWeight: 500,
    color: "#3C3E49"
  },
  colorCloseIcon: { color: "#5500CC" },
  viewCloseIcon: { marginLeft: '6px' },
  textVariant: { margin: "0 8px" },
  shrink: { flexShrink: 0 },
  viewVariant: {
    display: 'flex',
    alignItems: 'center',
    marginTop: "4px"
  } as React.CSSProperties,
  tableRow: {
    border: "2px solid #ECEDF0"
  },
  rowLoading: {
    background: '#F1F5F9',
    border: 'none'
  } as React.CSSProperties,
  cellLoading: {
    textAlign: 'center',
    border: 'none'
  } as React.CSSProperties,
  flexColumn: {
    display: "flex",
    flexDirection: 'column',
    whiteSpace: "nowrap"
  } as React.CSSProperties,
  totalText: {
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "16px",
  },
  iconEdit:{
    marginLeft: '10px',
    cursor: "pointer"
  } as React.CSSProperties,
  textFilterNoData: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    marginBottom: "10px",
    color: "#83889E",
    whiteSpace: "pre-line"
  },
  textNoResult: {
    fontFamily: "Rubik",
    fontSize: "20px",
    fontWeight: "500",
    lineHeight: "26px",
    letterSpacing: "-0.02em",
    marginBottom: "5px",
    color: "#000000"
  }
}

const useStyles: Record<string, CSSProperties> = {
  root: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: " #f8f9fa",
    height: "100%",
  },
  mobileWrapper:{ 
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: '100%'
  },
  mobileWrapperContainer:{
    position: 'absolute',
    inset: '0px'
  },
  menuItemNameSearch: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#334155",
  },
  menuList: {
    "&:hover": {
      background: "#F6F0FF",
    },
    "&.Mui-selected": {
      background: "#F6F0FF",
    },
  },
  menuItemCheckbox: {
    "&:hover": {
      background: "rgba(0, 0, 0, 0.08)",
    },
    "&.Mui-checked": {
      color: "#6200EA",
    },
  },
  countText: {
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
  },
  menuItemName: {
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19px",
    color: "#646464",
  },
  ordersContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  dataText: {
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    color: "#3C3E49",
  },
  selectFoodLable: {
    "& div": {
      fontFamily: "Rubik",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "24px",
      color: "#64748B",
    },
    "& fieldset": {
      top: "0px",
    },
  },
  headerText: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#676B7E",
  },
  placeholderText: {
    "& input, & textarea, & div": {
      fontFamily: "Rubik",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "22px",
      color: "#64748B",
    },
    "& fieldset": {
      top: "0px",
    },
  },
  chipText: {
    background: "#F6F0FF",
    "& span": {
      fontFamily: "Rubik",
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "16px",
      color: "#646464",
    },
  },
  imageText: {
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: "16px",
    color: "#676B7E",
  },
  categoryText: {
    fontFamily: "Rubik",
    fontSize: "12px",
    lineHeight: "20px",
    paddingBottom: "10px",
    color: "#676B7E",
  },
  switchLabel: {
    "& .MuiTypography-body1": {
      fontFamily: "Rubik",
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "16px",
      color: "#646464",
    },
  },
  OrderInputStyle: {
    "& > div": {
      background: "#FFFFFF",
      height: '41px',
      padding: '0px',
      border: "1px solid #D0D2DA",
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
      borderLeft: 'none',
      "@media (max-width: 480px)": {
        height: '42px',
        border: 'none',
        borderRadius: '4px'
      },
    },
    "& input": {
      fontFamily: "Rubik",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "22px",
      "&::placeholder": {
        color: "#AFB2C0"
      },
    },
    "& fieldset": {
      top: "0px",
    },
  },
  customdropdown: {
    borderRadius: "100px",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    letterSpacing: "0em",
    '&::before, &::after': {
      borderBottom: "none !important"
    },
    '&:hover': {
      textDecoration: "none !important"
    }
  },
  activityIcons: {
    display: "flex",
    flexDirection: "column"
  },
  fieldtitle: {
    fontSize: "10px",
    fontWeight: 500,
    color: "#94A3B8",
    fontFamily: "Inter",
  },
  fieldvalue: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#3C3E49",
    fontFamily: "Inter"
  },
  progressRoot: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center'
  },
  paginationRoot: {
    marginTop: '8px'
  },
  dropdownitem: {
    borderRadius: "100px",
    padding: "6px 12px 6px 12px",
    marginLeft: "4px",
    marginRight: "4px",
    marginTop: "5px",
    paddingRight: '20px',
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    letterSpacing: "0em",
  },
  itemdetails: {
    backgroundColor: "#fff",
    borderRadius: "4px"
  },
  orderDetails: {
    backgroundColor: "#fff",
    padding: "12px 16px 8px 16px",
    borderTop: "solid 1px #E2E8F0",
    marginTop: "12px",
    borderRadius: "4px"
  },
  noordersText: {
    fontSize: "14px",
    color: "#676B7E",
    maxWidth: "395px",
    width: "100%"
  },
  noOrdersBox: {
    width: "100%",
    maxWidth: "528px",
    borderRadius: "10px",
    height: "100%",
    maxHeight: "267px",
    display: "flex",
    justifyContent: "center"
  },
  borderTop: {
    borderTop: '1px solid #E2E8F0'
  },
  // kk css start
  filterBtn: {
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Rubik',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '22px',
    color: '#676B7E',
    border: '1px solid #D0D2DA',
    background: 'linear-gradient(0deg, #FAFAFA, #FAFAFA),linear-gradient(0deg, #D0D2DA, #D0D2DA)',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    "@media (max-width: 480px)": {
      border: 'none',
      borderRadius: '4px',
      background: '#FFFFFF'
    },
  },
  filterBtnPc: {
    "@media (max-width: 480px)": {
      display: 'none'
    },
  },
  filterBtnMob: {
    display: 'none',
    "@media (max-width: 480px)": {
      display: 'block'
    },
  },
  filterContainer: {
    width: '128px',
    height: '41px',
    "@media (max-width: 480px)": {
      width: "42px",
      height: '42px',
      marginRight: '4px'
    },
  },
  searchContainer: {
    width: 'calc(100% - 128px)',
    height: '41px',
    "@media (max-width: 480px)": {
      width: 'calc(100% - 46px)',
      height: '42px',
    },
  },
  csvDownloadBtn: {
    width: '94px',
    height: '21px',
    fontFamily: 'Rubik',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#3C3E49',
    border: 'none',
    background: 'none',
    textDecoration: 'underline'
  },
  filterOptionWrapper: {
    width: '100%',
    alignItems: 'center',
    minHeight: '26px',
    flexWrap: 'wrap',
    gap: '0px 12px',
    "@media (max-width: 480px)": {
      width: 'calc(100% - 100px)'
    },
  },
  mobDowloadCsvWrapper: {
    display: 'none',
    "@media (max-width: 480px)": {
      width: '110px',
      display: 'flex',
      justifyContent: "flex-end",
      alignItems: "center",
      marginTop: "4px"
    },
  },
  chipcontainer: {
    minHeight: '26px',
    borderRadius: '50px',
    backgroundColor: '#F6F0FF',
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    color: "#5500CC",
    padding: '4px 10px',
    display: 'flex',
    alignItems: 'center',
    marginRight: '8px',
    marginBottom: "6px"
  },
  csvDownloadBtnMob: {
    width: '110px !important',
  },
  hideMobCsvBtn: {
    "@media (max-width: 480px)": {
      display: 'none'
    },
  },
  filterLable: {
    height: '26px',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    color: '#5500CC',
    marginRight: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: "6px"
  },
  mobileProgressContainer: {
    display: "flex",
    width: '100%',
    height: '200px',
    alignItems: "center",
    justifyContent: "center"
  },
  mobileNoFilterOrderContainer: {
    border: '2px solid #ECEDF0',
    padding: '0px 12px',
    backgroundColor: '#fff',
    "@media (max-width: 480px)": {
      border: 'none',
      borderRadius: '4px',
      background: 'transparent'
    },
  },
  // kk css end
  padding16: {
    padding: '0 16px'
  },
  parentDiv:{ 
    display: 'flex', 
    flexDirection: "column",
    "& .order-listing-search":{
      margin: '20px 0px'
    }
  },
  storeAvailabilityContainer:{
    marginTop:16
  },
  storeAvailabilityAlertBox: {
      borderRadius: "4px",
      padding: "4px 25px 4px 16px",
      margin: '0px 0px 10px',
      borderLeft: "4px solid #D97706",
      backgroundColor: "#FEF3C7",
      "& .warningText":{
        fontSize: 14,
        lineHeight: 1.3,
        fontWeight: 400,
        fontFamily: "Inter",
        color:'#D97706',
        "& .Link":{
            fontWeight: 700,
            textDecoration:'underline',
            cursor:'pointer'
        }
    }
  },
  shipRocketFailed: {
    borderRadius: "4px",
    padding: "4px 25px 4px 16px",
    margin: '0px 0px 10px',
    borderLeft: "4px solid #dc2626",
    backgroundColor: "#fee2e2",
    "& .warningText":{
      fontSize: 14,
      lineHeight: 1.3,
      fontWeight: 400,
      fontFamily: "Inter",
      color:'#DC2626',
      "& .Link":{
          fontWeight: 700,
          textDecoration:'underline',
          cursor:'pointer'
      }
    }
  },
  printIcon:{
    cursor: "pointer", 
    width: "24px",
    height: "24px",
    display:'none'
  },
  printIconWeb:{ 
    cursor: "pointer", 
    marginRight: '10px',
    display:'none' }
};
const StyledComponent = withLayout(withStyles(useStyles)(OrderList));
export default withWidth()(StyledComponent);
// Customizable Area End
