// Customizable Area Start
import React from "react";
import { CreateDialogTypes, SubmitDataType } from "./Types";
import { FormikProps } from "formik";
import * as Yup from "yup";

const configJSON = require("./config");

export const Schema = Yup.object().shape({
  title: Yup.string()
    .required(configJSON.thisFieldIsRequired)
    .max(65, configJSON.titleMustBeMaximum_65Characters),
  message: Yup.string()
    .required(configJSON.thisFieldIsRequired)
    .max(178, configJSON.messageMustBeMaximum_178Characters),
});

export default class CreateDialogController extends React.Component<CreateDialogTypes> {
  formRef = React.createRef<FormikProps<SubmitDataType>>();

  handleSubmit = (values: SubmitDataType) => {
    this.props.onCreate(
      !this.props.editClicked
        ? values
        : { ...values, id: this.props.notificationItem?.id }
    );
  };

  handleDialogSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    }
    return false;
  };
}
// Customizable Area End