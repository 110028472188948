Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpPutType = "PUT";
exports.httpDeleteType = "DELETE";

exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";

exports.getCategoriesAPIEndPoint = "bx_block_categories/categories"
exports.addCategoryAPIEndPoint = "bx_block_categories/categories?[categories][name]="
exports.editCategoryAPIEndPoint = "bx_block_categories/categories/"
exports.searchCategoryAPIEndPoint = "bx_block_categories/categories/search_category?search="


exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"


// Customizable Area End