// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import {
  getStorageData,
} from "framework/src/Utilities";
import { runEngine } from "../../../framework/src/RunEngine";
const configJSON = require("./config.js");
import React, { RefObject, createRef } from "react";

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>;
  // Customizable Area End
}

export interface IDistanceRate {
  id: string;
  min_distance: string;
  max_distance: string;
  rate: string;
  color: string;
}

export interface IDeliveryArea {
  deliveryAreaId: string,
  max_radius: string,
  amount: string,
  delivery_type: string,
  free_delivery_offer_amount: string,
  status_free_delivery: boolean,
  distance_rates: IDistanceRate[]
}

  interface DeliveryOptions {
    label: string;
    description: string;
    value:string;
  }

  declare global {
    interface Window {
      google: any;
    }
  }

  export interface APIDeliveryArea {
    max_radius: string,
    amount: string,
    delivery_type: string,
    free_delivery_offer_amount: string,
    status_free_delivery: boolean,
    distance_rates_attributes?: {
      min_distance: number | string;
      max_distance: number | string;
      rate: number | string;
    }[]
  }

  export interface IAddressDetails {
    lat: number;
    lng: number;
  }

interface S {
  pageInitialized: boolean;
  deliveryValue: DeliveryOptions;
  deliveryOptions: DeliveryOptions[];
  range: number;
  userLocation: google.maps.LatLngLiteral | null;
  openSubmit: boolean;
  setTouched: boolean;
  openAlert: boolean;
  alertSuccess: boolean;
  alertMessage: string;
  distanceArea: IDeliveryArea;
  distanceErrors: string[];
  isLoadingPage: boolean;
  addressDetails: IAddressDetails;
  errorMessageOfferAmount: string;
}

interface SS {
  id: string | number;
}

class HyperLocalDeliveryController extends BlockComponent<Props, S, SS> {
  public mapRef: RefObject<HTMLDivElement>;
  public userCircle: google.maps.Circle | null;
  public userMarker: google.maps.Marker | null;
  public map: google.maps.Map | null;
  public outerCircle: google.maps.Circle[] = [];
  subScribedMessages: any[];

  getDeliveryAreaApiId: string = "";
  updateDeliveryAreaApiId: string = "";
  createDeliveryAreaApiId: string = "";
  getAddressDetailsApiId: string = "";

  constructor(props: Props) {
    super(props);
    this.mapRef = createRef();
    this.userMarker = null;
    this.userCircle = null;
    this.map = null;
    this.outerCircle = [];

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

      this.state = {
        pageInitialized: true,
        deliveryValue: { label: 'Free Delivery', value: 'free_delivery',description: 'Free delivery for all orders' },
        deliveryOptions: [
          { label: 'Free Delivery', value: 'free_delivery', description: 'Free delivery for all orders' },
          { label: 'Rate by Distance', value: 'rate_by_distance', description: 'Apply charges based on distance from store' }
        ],
        userLocation: null,
        range: 0,
        openSubmit: false,
        setTouched: false,
        openAlert: false,
        alertSuccess: false,
        alertMessage: "",
        errorMessageOfferAmount: "",
        distanceArea: {
          deliveryAreaId: "",
          max_radius: "",
          amount: "",
          delivery_type: "",
          free_delivery_offer_amount: "",
          status_free_delivery: false,
          distance_rates: [],
        },
        distanceErrors: [],
        isLoadingPage: true,
        addressDetails: {
          lat: 0,
          lng: 0,
        }
      };
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getDeliveryAreaApiId) {
      if (responseJson?.data && responseJson?.data?.length > 0) {
        const deliveryAreaOb: IDeliveryArea = {
          deliveryAreaId: responseJson.data[0].id,
          max_radius: responseJson.data[0].attributes.max_radius,
          amount: responseJson.data[0].attributes.amount,
          free_delivery_offer_amount: responseJson.data[0].attributes.free_delivery_offer_amount,
          status_free_delivery: responseJson.data[0].attributes.status_free_delivery,
          delivery_type: responseJson.data[0].attributes.delivery_type,
          distance_rates: responseJson.data[0].attributes.distance_rates.map((item: IDistanceRate) => ({
            id: item.id,
            min_distance: item.min_distance,
            max_distance: item.max_distance,
            rate: item.rate,
            color: this.getRandomColor()
          }))
        }
        this.setState({
          distanceArea: deliveryAreaOb,
          deliveryValue: !deliveryAreaOb.distance_rates.length ? this.state.deliveryOptions[0] : this.state.deliveryOptions[1],
        }, () => {
          this.drawCircleMaxRadius(Number(deliveryAreaOb.max_radius))
        });
      }
    }
    if(apiRequestCallId === this.getAddressDetailsApiId) {
      if(responseJson?.data && responseJson?.data?.id) {
        const addressDetailsObj: IAddressDetails = {
          lat: responseJson.data?.attributes?.lat,
          lng: responseJson.data?.attributes?.lon,
        }
        this.setState({
          addressDetails: addressDetailsObj,
          userLocation:addressDetailsObj
        }, () => {
          this.loadGoogleMapsAPI();
        })
      }
    }
    if (apiRequestCallId === this.updateDeliveryAreaApiId) {
      this.setUpdateAreaResponse(responseJson);
    }
    if (apiRequestCallId === this.createDeliveryAreaApiId) {
      this.setUpdateAreaResponse(responseJson);
    }
  }

  async componentDidMount() { 
    this.getAddressDetails();
    this.setState({ isLoadingPage: false });
  }

  loadGoogleMapsAPI() {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${configJSON.mapAPIKey}&callback=initMap`;
    script.async = true;
    script.defer = true;
    script.onload = () => {
      this.initMap();
      this.getDeliveryAreas();
    };
    document.head.appendChild(script);
  }

  setUpdateAreaResponse = (responseJson: { data: { attributes: unknown } }) => {
    if (responseJson.data && responseJson.data.attributes) {
      this.getDeliveryAreas();
      this.setState({
        openAlert: true,
        alertSuccess: true,
        alertMessage: `Delivery area ${this.state.distanceArea.deliveryAreaId ? "updated": "created"} successfully.`,
        openSubmit: false
      }, () => {
        setTimeout(() => {
          this.setState({ openAlert: false, alertMessage: "" });
        }, 3000)
      });
    }
  }

  setDistanceErrors = () => {
    const distanceRates = this.state.distanceArea.distance_rates;
    let errorsArray: string[] = [];
    if (distanceRates.length > 0) {
      distanceRates.forEach((item) => {
        if (this.state.distanceArea.max_radius == item.max_distance || this.state.distanceArea.max_radius == item.min_distance ) {
          errorsArray.push("");
        } else if (this.state.distanceArea.max_radius <= item.max_distance || this.state.distanceArea.max_radius <= item.min_distance ){
          errorsArray.push(configJSON.distanceErrorText);
        } 
        else if (!item.max_distance || !item.min_distance || !item.rate ) {
          errorsArray.push(configJSON.fieldRequired)
        } 
        else if (item.min_distance >= item.max_distance){
          errorsArray.push(configJSON.outOFRange);
        } else {
          const prevRange = distanceRates[errorsArray.length - 1];
          if (prevRange && parseFloat(item.min_distance) <= parseFloat(prevRange.max_distance)) {
            errorsArray.push(configJSON.invalidRangeError);
          } else {
            errorsArray.push("");
          }
        }
      })
    }
    this.setState({ distanceErrors: errorsArray });
    return errorsArray;
  }

  initMap() {
    this.map = new window.google.maps.Map(this.mapRef.current, {
      zoom: 4,
      mapTypeControl: false,
      disableDefaultUI: true,
    });
    this.setMapLocation(this.state.userLocation ?? {lat:0, lng:0});
  }

  async setMapLocation(location: google.maps.LatLngLiteral) {
    if(this.userMarker) {
      this.userMarker.setPosition(location);
    }else {
      this.userMarker = new window.google.maps.Marker({
        position: location,
        map: this.map!,
        title: 'Store Location'
      })
    }
    if (this.map) {
      this.map.setCenter(location);
    }
    const radius = this.calculateRadius();
    this.drawCircles(radius);
  }

  handleChangeDeliveryOptions = (index: number, field: keyof IDistanceRate) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    const regex = /^[0-9.]+$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      this.setState(prevState => {
        const newDistanceArray: IDistanceRate[] = [...prevState.distanceArea.distance_rates];
        const obj = newDistanceArray[index];
        obj[field] = value;
        newDistanceArray[index] = obj;

        return {
          distanceArea: {
            ...prevState.distanceArea,
            distance_rates: newDistanceArray
          },
        };
      }, () => {
        if (this.state.distanceErrors.every(error => error === "")) {
          const radius = this.calculateRadius();
          this.drawCircles(radius);
        }
        this.setState({ openSubmit: true, alertSuccess: true });
      });
    }
  };

  calculateRadius() {
    const { distance_rates } = this.state.distanceArea;
    let maxRange = 0;
    distance_rates.forEach(range => {
      const start = parseFloat(range.min_distance);
      const end = parseFloat(range.max_distance);
      if ((start) && (end) && start < end) {
        const rangeDistance = (end - start) * 1000;
        if (rangeDistance > maxRange) {
          maxRange = rangeDistance;
        }
      }
    });
    return maxRange;
  }

  drawCircles(radius1: number) {
    if (!this.map || !this.state.userLocation || this.state.distanceArea.distance_rates.length === 0) return;

    if (this.userCircle) this.userCircle.setMap(null);
    if (this.outerCircle) this.outerCircle.forEach(circle => circle.setMap(null));
    this.outerCircle = [];

    this.setMapZoomLevel((Number(this.state.distanceArea.max_radius) + 2) *1000);

    this.state.distanceArea.distance_rates.forEach((range, index) => {
      const circleColor = range.color;
      let drawRadius = Number(range.max_distance) * 1000;
      drawRadius = drawRadius === 0 ? 1000 : drawRadius;
      const circle = new window.google.maps.Circle({
        strokeColor: circleColor,
        fillColor: circleColor,
        map: this.map!,
        center: this.state.userLocation!,
        radius: drawRadius,
        strokeWeight: 0.5
      });

      if (index === 0) {
        this.userCircle = circle;
      } else {
        this.outerCircle.push(circle);
      }
    });
    const maxRadiusCircle = new window.google.maps.Circle({
      strokeColor: '#FF0000', 
      fillColor: '#FF000080',
      map: this.map,
      center: this.state.userLocation,
      radius: Number(this.state.distanceArea.max_radius)*1000, 
    });
    this.outerCircle.push(maxRadiusCircle);
  }

  drawCircleMaxRadius = (radius: number) => {
    if(this.state.deliveryValue.value === 'free_delivery'){
      this.drawMaxCircleOnly()
    }else{
      this.drawCircles(radius);
    }
  }
  

  setMapZoomLevel(outerRadius: number) {
    const zoomLevel = Math.round(16 - Math.log2(outerRadius / 500));
    this.map?.setZoom(zoomLevel);
  }

  handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      distanceArea: {
        ...this.state.distanceArea,
        status_free_delivery: event.target.checked
      }
    });
    this.setState({ openSubmit: true, alertSuccess: true });
  };

  handleAddRange = () => {
    const randomColor = this.getRandomColor();
    const newDistanceOb: IDistanceRate = {
      id: "",
      min_distance: "",
      max_distance: "",
      rate: "",
      color: randomColor,
    }
    this.setState((prevState) => ({
      distanceArea: {
        ...this.state.distanceArea,
        distance_rates: [...this.state.distanceArea.distance_rates, newDistanceOb]
      }
    }), () => {
    });
  };

  getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 3; i++) {
        let randomValue = Math.floor(Math.random() * 9); 
        color += letters[randomValue]; 
        color += letters[randomValue]; 
    }
    return color;
  }

  handleRemoveRange = (index: number) => {
    let updatedRatesArray = [...this.state.distanceArea.distance_rates];
    updatedRatesArray.splice(index, 1);
    this.setState({
      distanceArea: {
        ...this.state.distanceArea,
        distance_rates: updatedRatesArray
      },
    }, () => {
      if (updatedRatesArray.length > 0) {
        const radius = this.calculateRadius();
        this.drawCircles(radius);
      } else {
        this.map?.setZoom(5);
      }
      this.setState({ openSubmit: true, alertSuccess: true });
    });
  };

  handleUpdateDeliveryType = (newValue: DeliveryOptions) => {
    this.setState({ deliveryValue: newValue }, () => {
      this.setDistanceArray();
    });
    this.setState({ openSubmit: true, alertSuccess: true });
  }

  drawMaxCircleOnly = () => {
    if (this.userCircle) this.userCircle.setMap(null);
    if (this.outerCircle) this.outerCircle.forEach(circle => circle.setMap(null));
    this.outerCircle = [];
    this.setMapZoomLevel((Number(this.state.distanceArea.max_radius) + 2) *1000);
    this.outerCircle.push(new window.google.maps.Circle({
      strokeColor: '#FF0000', 
      fillColor: '#FF000080',
      map: this.map,
      center: this.state.userLocation,
      radius: Number(this.state.distanceArea.max_radius)*1000, 
    }));
  }
  setDistanceArray = () => {
    if (this.state.deliveryValue === this.state.deliveryOptions[0]) {
      this.setState({ distanceArea: { ...this.state.distanceArea, distance_rates: [] } }, () => {
        this.drawMaxCircleOnly()
      });
    } else {
      const newDistanceArray: IDistanceRate[] = [{
        id: "",
        min_distance: "",
        max_distance: "",
        rate: "",
        color: this.getRandomColor()
      }];
      this.setState({ distanceArea: { ...this.state.distanceArea, distance_rates: newDistanceArray } }, () => {
        this.drawMaxCircleOnly()
      });
    }
  }

  handleChangeKm = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const regex = /^[0-9.]+$/; 
    const distance = parseFloat(value);

    if (value === '' || (regex.test(value) && distance >= 0 && distance <= 100)) {
      this.setState({
        distanceArea: {
          ...this.state.distanceArea,
          max_radius: value
        },
      },() => {
        const radius = parseFloat(value) * 1000; 
        this.drawCircleMaxRadius(radius);
      });
    }
      this.setState({ openSubmit: true, alertSuccess: true });
  };

  handleChangeDeliveryRates = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9.]+$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      this.setState({
        distanceArea: {
          ...this.state.distanceArea,
          amount: event.target.value
        }
      });
    }
    this.setState({ openSubmit: true, alertSuccess: true });
  };

  handleChangeMinFreeDeliveryAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9.]+$/;
    const value = event.target.value;
    const isEmpty = value.trim() === '';
    
    if (isEmpty  || regex.test(value)) {
      this.setState({
        distanceArea: {
          ...this.state.distanceArea,
          free_delivery_offer_amount: value
        },
        openSubmit: !isEmpty,
        alertSuccess: !isEmpty
      });
    }
  };
  
    submitBtn = () => {
      const errorList = this.setDistanceErrors();
      const hasDistanceErrors = errorList.some(error => error !== "");

      if (hasDistanceErrors || this.state.distanceArea.distance_rates?.[0]?.max_distance === "") {
        this.setState({
          setTouched: false,
          openSubmit: false,
        });
      } else if (this.state.distanceArea.deliveryAreaId) {
        this.updateDeliveryAreas();
      } else {
        this.createDeliveryAreas();
      }
    };

  discardBtn = () => {
    this.setState({ openSubmit: false });
    this.getDeliveryAreas()
  };


  getDeliveryAreas = async () => {
    const headers = {
      token: await getStorageData("admintoken"),
      "Content-Type": configJSON.validationApiContentType,
    };

    const distanceDeliveryAreaMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDeliveryAreaApiId = distanceDeliveryAreaMsg.messageId;

    distanceDeliveryAreaMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDistanceAreas
    );

    distanceDeliveryAreaMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    distanceDeliveryAreaMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(
      distanceDeliveryAreaMsg.id,
      distanceDeliveryAreaMsg
    );
  };

  getAddressDetails = async () => {
    const headers = {
      token: await getStorageData("admintoken"),
      "Content-Type": configJSON.validationApiContentType,
    };

    const addressDetailsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAddressDetailsApiId = addressDetailsMsg.messageId;

    addressDetailsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAddressDetails
    );

    addressDetailsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    addressDetailsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(
      addressDetailsMsg.id,
      addressDetailsMsg
    );
  };

  updateDeliveryAreas = async () => {
    const headers = {
      token: await getStorageData("admintoken"),
      "Content-Type": configJSON.validationApiContentType,
    };

      let body:{delivery_area_attributes:APIDeliveryArea} = {
        delivery_area_attributes: {
          max_radius: this.state.distanceArea.max_radius,
          amount: this.state.distanceArea.amount,
          delivery_type: this.state.deliveryValue.value,
          status_free_delivery: this.state.distanceArea.status_free_delivery,
          free_delivery_offer_amount: this.state.distanceArea.free_delivery_offer_amount
        }
      }
      if(this.state.deliveryValue.value === 'rate_by_distance'){
        body = {
          ...body,
          delivery_area_attributes:{
            ...body.delivery_area_attributes,
            distance_rates_attributes: this.state.distanceArea.distance_rates.map((item: IDistanceRate) => ({
              min_distance: item.min_distance,
              max_distance: item.max_distance,
              rate: item.rate,
            }))
          }
        }
      }

    const updateDistanceDeliveryAreaMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateDeliveryAreaApiId = updateDistanceDeliveryAreaMsg.messageId;

    updateDistanceDeliveryAreaMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateDistanceAreas + `/${this.state.distanceArea.deliveryAreaId}`
    );

    updateDistanceDeliveryAreaMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    updateDistanceDeliveryAreaMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )

    updateDistanceDeliveryAreaMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutType
    );
    runEngine.sendMessage(
      updateDistanceDeliveryAreaMsg.id,
      updateDistanceDeliveryAreaMsg
    );
  };

  createDeliveryAreas = async () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("admintoken"),
    };

    const requestBody = {
      delivery_area_attributes: {
        max_radius: this.state.distanceArea.max_radius,
        amount: this.state.distanceArea.amount,
        delivery_type: this.state.distanceArea.delivery_type,
        status_free_delivery: this.state.distanceArea.status_free_delivery,
        free_delivery_offer_amount: this.state.distanceArea.free_delivery_offer_amount,
        distance_rates_attributes: this.state.distanceArea.distance_rates.map((item: IDistanceRate) => ({
          min_distance: item.min_distance,
          max_distance: item.max_distance,
          rate: item.rate,
        }))
      }
    }

    const createDistanceDeliveryAreaMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createDeliveryAreaApiId = createDistanceDeliveryAreaMsg.messageId;

    createDistanceDeliveryAreaMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateDistanceAreas
    );

    createDistanceDeliveryAreaMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createDistanceDeliveryAreaMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    )

    createDistanceDeliveryAreaMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(
      createDistanceDeliveryAreaMsg.id,
      createDistanceDeliveryAreaMsg
    );
  };
}

export default HyperLocalDeliveryController;
// Customizable Area End