// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";
import { createRequest } from "./Helpers";
import { getStorageData } from "../../../framework/src/Utilities";
import { clearStorageData, isTokenExpired } from "../../../../packages/blocks/ss-cms-common-components/src/Utilities/Utilities";

const baseURL = require("../../../framework/src/config.js").baseURL;

export const configJSON = require("./config");

export interface PaymentMethod {
  id: string,
  type: string,
  attributes: {
    id: number,
    payment_method: string,
    active: boolean
  }
}

export interface PaymentMethodError {
  errors: Array<{message: string}>
}

export type Props = DialogProps & {
  id: string
  showHeader: (obj?: object) => void;
  showLoader:() => void;
  hideLoader: () => void;
  setDialogState: () => void;
};

interface S {
  currentPaymentId: number;
  message: string;
  cashValue: boolean;
  onlineValue: boolean;
}

interface SS {
  id: number;
}

class PaymentMethodsController extends BlockComponent<Props, S, SS> {
  getPaymentMethodsMessageId: string = "";
  updatePaymentMethodMessageId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ActionMessageFromToaster),
    ];

    this.state = {
      currentPaymentId: 0,
      message: "",
      cashValue: false,
      onlineValue: false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    const messageEle = document.getElementById('message');
    const counterEle = document.getElementById('counter');

    messageEle!.addEventListener('input', function (e) {
      const currentLength = (e.target as HTMLInputElement).value.length;
      counterEle!.innerHTML = `${currentLength}/500`;
    });

    this.handleGetPaymentMethod()
  }

  receive = (from: string, message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
 
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
 
      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getPaymentMethodsMessageId:
            const payment = responseJson.payments[0]
            this.setState({ cashValue: payment.cod, onlineValue: payment.online_payment, message: payment.message, currentPaymentId: payment.id })
            break;
        }
      }
    } else if (getName(MessageEnum.ActionMessageFromToaster)) {
      this.handleToasterMessageResponse(message);
    }
  };

  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };
  
  handleToasterMessageResponse = (message: Message) => {
    const type = message.getData(
      getName(MessageEnum.ActionMessageFromToasterMessage)
    );
    if (type === "SAVECHANGES") {
      this.handlePutPaymentMethod();
    } else if (type === "DISCARDCHANGES") {
      this.handleGetPaymentMethod();
    }
  };

  async handlePutPaymentMethod() {
    const token = await getStorageData("admintoken");
    const request = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    )
 
    this.updatePaymentMethodMessageId = request.messageId;
    const params = `cod=${this.state.cashValue}&online_payment=${this.state.onlineValue}&message=${this.state.message}`
 
    createRequest({
      token,
      requestMsg: request,
      method: "PUT",
      endPoint: baseURL + `${configJSON.getPaymentMethod}/${this.state.currentPaymentId}?${params}`,
    });
  }

  async handleGetPaymentMethod() {
    const token = await getStorageData("admintoken");
    const request = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    )
 
    this.getPaymentMethodsMessageId = request.messageId;
 
    createRequest({
      token,
      requestMsg: request,
      method: "GET",
      endPoint: baseURL + `${configJSON.getPaymentMethod}`
    });
  }

  updateMessage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.props.showHeader();
    this.setState({ message: event.target.value })
  }

  onChangeCash = () => {
    this.props.showHeader();
    this.setState({ cashValue: !this.state.cashValue })
  }

  onChangeOnline = () => {
    this.props.showHeader();
    this.setState({ onlineValue: !this.state.onlineValue })
  }
}

export default PaymentMethodsController;
// Customizable Area End
