// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";
import { clearStorageData, isTokenExpired } from "../../ss-cms-common-components/src/Utilities/Utilities";
import { getResponseVariables } from "../../../../packages/blocks/ss-cms-common-components/src/GetResponseVariables/GetResponseVariables";
import { emitter } from "../../../../packages/blocks/ss-cms-common-components/src/Layout/Layout";
import { getStorageData } from "../../../framework/src/Utilities";
const configJSON = require("./config");

export type Props = DialogProps &
  withLoaderProps &
  withHeadeActionBarProps & {
    navigation: {
      navigate: (to: string, params: object) => void;
      getParam: (param: string) => string;
      goBack: () => void;
    };
    id: string;
  };

interface S {
  page: number;
  showQrCodeDialog: boolean;
  selectedTab: number;
}

interface SS {
  id: string;
}

class MobileAppController extends BlockComponent<Props, S, SS> {
  getOrderNotificationId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ActionMessageFromToaster),
    ];

    this.state = {
      page: 1,
      showQrCodeDialog: false,
      selectedTab: 0,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  receive = (from: string, message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);
    if (isTokenExpired(message)) {
      return this.logoutAndNavigateLogin();
    }
    if (apiRequestCallId == this.getOrderNotificationId) {
       emitter.emit("changeNotiNumber", responseJson.new_order_count)
    }
  };

  async componentDidMount(): Promise<void> {
    this.getOrderNotification()
  }

  getOrderNotification = async () => {
    const token = await getStorageData("admintoken");
    const header = {
      "Content-Type": 'application/json',
      token
    };
    const requestSAMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getOrderNotificationId = requestSAMessage.messageId;
    requestSAMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "/bx_block_order_management/orders/new_order_count");
    requestSAMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestSAMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestSAMessage.id, requestSAMessage);
  }

  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  showGenerateQrCodeDialog = () => {
    this.setState({
      showQrCodeDialog: true
    });
  };
  hideGenerateQrCodeDialog = () => {
    this.setState({
      showQrCodeDialog: false,
    });
  };

  hideLoader = () => {
    this.props.hideLoader();
  };

  handleTabChange = (event: React.ChangeEvent<{}>, selectedTab: number) => {
    this.setState({ selectedTab });
  };

  getTabContent = () => {
    return this.state.selectedTab === 2
      ? configJSON.qrCodeHint
      : configJSON.appRequirementHint;
  };

  handleSetting = () => {
    this.setState({
      selectedTab: 0
    })
  }
}
export default MobileAppController;
// Customizable Area End