// Customizable Area Start
import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  withStyles,
  createStyles,
  Grid,
  withTheme,
} from "@material-ui/core";
import { Formik, Field, Form } from "formik";
import TextField from "./InputFieldWithFormik.web";
import withLoader from "../../ss-cms-common-components/src/HOC/withLoader.Web";
import withSnackBar from "../../ss-cms-common-components/src/HOC/withSnackBar.Web";
import LoginController, { Props, Schema } from "./LoginController.web";
import { LoginDataType } from "./LoginController.web";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout"
const configJSON = require("./config");
const checkboxIcon = require("../assets/checkbox_icon.svg");
const uncheckboxIcon = require("../assets/uncheckbox_icon.svg");

// Customizable Area End

export class Login extends LoginController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <Formik<LoginDataType>
        initialValues={{
          email: this.state.email,
          password: this.state.password,
          isRemember: this.state.isRemember
        }}
        validationSchema={Schema}
        onSubmit={this.handleSubmitLogin}
        enableReinitialize
      >
        {(formikProps) => {
          const { values, errors, touched, handleChange, isValid, setFieldValue } =
            formikProps;
          return (
            <Form
              style={{ height: "100%" }}
              autoComplete="off"
              noValidate
              translate="no"
              className="login-page-form"
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{
                  height: theme?.breakpoints?.down?.("md") ? "100%" : "100vh",
                  background: "#f8f9fa",
                }}
              >
                <Grid item lg={3} md={5} sm={5} xs={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography
                        className={classes?.logoHeading}
                        align="center"
                      >
                        {this.state.storeName || configJSON.textDefaultStoreName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box py={3} style={{ background: "#fff", border: "2px solid #ECEDF0", borderRadius: "4px" }}>
                        <Grid container justifyContent="center" spacing={1}>
                          <Grid item xs={10}>
                            <Typography
                              variant="h6"
                              align="left"
                              className={classes?.signinHeading}
                            >
                              {configJSON.textLoginHeading}
                            </Typography>
                          </Grid>

                          <Grid item xs={10}>
                            <Field
                              type="email"
                              component={TextField}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                if (this.invalidEmailError) {
                                  this.invalidEmailError = "";
                                }
                                handleChange(event);
                                this.setState({ email: event.target.value })
                              }}
                              error={
                                (errors?.email && touched?.email) ||
                                  this.invalidEmailError
                                  ? true
                                  : false
                              }
                              helperText={this.handleInvalidEmailError(
                                errors,
                                touched
                              )}
                              name="email"
                              value={values.email}
                              fullWidth
                              label={configJSON.textEmail}
                            />
                          </Grid>

                          <Grid item xs={10}>
                            <Field
                              type="password"
                              component={TextField}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                if (this.invalidPasswordError) {
                                  this.invalidPasswordError = "";
                                }
                                handleChange(event);
                                this.setState({ password: event.target.value })
                              }}
                              error={
                                (errors?.password && touched?.password) ||
                                  this.invalidPasswordError
                                  ? true
                                  : false
                              }
                              helperText={this.handleInvalidPasswordError(
                                errors,
                                touched
                              )}
                              name="password"
                              value={values.password}
                              fullWidth
                              label={configJSON.textPassword}
                            />
                          </Grid>
                          <Grid item xs={10} md={10} xl={10} style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                            <div
                              data-testId="rememberme"
                              style={{ display: "flex", alignItems: "center" }}
                              onClick={() => { this.setState({ isRemember: !this.state.isRemember }, () => { setFieldValue("isRemember", this.state.isRemember) }); }}
                            >
                              <img
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "8px",
                                }}
                                src={values.isRemember ? checkboxIcon : uncheckboxIcon}
                              />
                              <Typography className={classes?.rememberText}>Remember me</Typography>
                            </div>
                            <Typography
                              id="forgot-password-field"
                              data-testId="forgot-password-field"
                              onClick={this.goToForgotPassword}
                              color="textPrimary"
                              className={classes?.forgotPassword}
                            >
                              {configJSON.textForgotPassword}
                            </Typography>
                          </Grid>

                          <Grid item style={{ marginTop: "16px" }} xs={10}>
                            <Button
                              color="primary"
                              type="submit"
                              variant="contained"
                              disabled={!isValid}
                              classes={{
                                label: classes?.buttonText,
                              }}
                              fullWidth
                            >
                              {configJSON.textLoginButton}
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}
const LoginWithLoader = withLoader(Login);
const LoginWithToast = withSnackBar(LoginWithLoader);

export const styles = () =>
  createStyles({
    signinHeading: {
      fontSize: "20px",
      lineHeight: "28px",
      fontWeight: 500,
      letterSpacing: "-0.4px",
      color: "#3C3E49",
      fontFamily: "Rubik",
    },
    footerText: {
      fontSize: "0.75rem",
      lineHeight: "1.25rem",
      fontWeight: 400,
      letterSpacing: "normal",
      color: "#83889E",
    },
    labelText: {
      fontFamily: "Lato",
      fontSize: "0.75rem",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      color: "rgba(0, 0, 0, 0.6)",
    },
    buttonText: {
      color: "#3C3E49",
      fontWeight: 500,
      fontFamily: "Rubik",
      fontSize: "16px",
      lineHeight: "24px"
    },
    rememberText: {
      color: "#3C3E49",
      fontWeight: 400,
      textDecoration: "underline",
      textAlign: "start",
      cursor: "pointer",
      fontFamily: "Rubik",
      fontSize: "14px",
      lineHeight: "20px"
    },
    forgotPassword: {
      color: "#3C3E49",
      fontWeight: 400,
      textDecoration: "underline",
      textAlign: "end",
      cursor: "pointer",
      fontFamily: "Rubik",
      fontSize: "14px",
      lineHeight: "20px"
    },
    logoHeading: {
      color: "#3C3E49",
      fontWeight: 600,
      fontSize: "1.5rem",
      letterSpacing: "0.02px",
    },
  });

export default withLayout(withStyles(styles)(withTheme(LoginWithToast)), true);
// Customizable Area End