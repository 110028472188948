Object.defineProperty(exports, '__esModule', {
  value: true
});
// Customizable Area Start
exports.postAPiMethod = 'POST';
exports.putAPiMethod = 'PUT';
exports.getApiMethod = 'GET';
exports.patchApiMethod = 'PATCH';
exports.deleteApiMethod = 'DELETE';
exports.getPromoCodeApi = 'bx_block_promo_code/promo_codes';
exports.postBulkDelete = 'bx_block_promo_code/promo_codes/destroy_multiple';

exports.promoCodes = 'Promo Codes';
exports.promoCodesError = 'Promo Codes Error';
exports.editPromoCode = 'Edit Promo Code';
exports.promoCodeDetails = 'Promo code details';
exports.promoCodeName = 'Promo code name';
exports.description = 'Description';
exports.discountType = 'Discount Type';
exports.discount = 'Discount';
exports.restrictions = 'Restrictions';
exports.validFromText = 'Valid from *';
exports.validToText = 'Valid to *';
exports.dateFormat = 'dd/MM/yyyy';
exports.dateFormatPlaceholder = 'dd/mm/yyyy';
exports.deletePromoCodes = 'Delete promo codes';
exports.createPromoCode = 'Create new promo code';
exports.paginationText = 'promo codes';
exports.emptyPageTitle = 'You have no promo codes';
exports.emptyPageMessage =
  'You have not set up promo codes for customers to apply at checkout.';
exports.emptyPageButtonLabel = 'Create new promo code';
exports.errorMessage = 'Something went wrong';
exports.deleteModalTitle = 'Are you sure?';
exports.deleteModalMessage =
  'Are you sure you want to delete this promo codes.';
exports.deleteModaConfirmColor = 'white';
exports.deleteModalConfirmBackground = '#FF1744';
exports.deleteModalConfirmHoverBackground = 'rgb(240, 25, 73)';
exports.addPromoCodeSuccess = 'Promo Code added successfully';
exports.updatePromoCodeSuccess = 'Promo Code updated successfully';
exports.deletePromoCodeSuccess = 'Promo Code deleted successfully';
exports.requiredField = 'This field is required.';
exports.promoCodeNameValidation =
  'Promo code can not be more than 15 characters.';
exports.promoCodeDescriptionValidation =
  'Description can not be more than 50 characters.';
exports.promoCodeValidation = 'Code can not be more than 10 characters.';
exports.promoCodeDiscountValidation1 = "Discount can't be less than 0";
exports.promoCodeDiscountValidation2 = "Discount can't be more than 100000";
exports.promoCodeUserLimitValidation1 = 'Only numbers are allowed.';
exports.promoCodeUserLimitValidation2 = 'Minimum user limit value is 1';
exports.promoCodeMinCartValueValidation1 =
  "Min cart value can't be less than 0";
exports.promoCodeMinCartValueValidation2 =
  "Min cart value can't be more than 99999";
exports.promoCodeMaxCartValueValidation1 =
  "Max cart value can't be less than 1";
exports.promoCodeMaxCartValueValidation2 =
  "Max cart value can't be more than 100000";
exports.promoCodeMaxCartValueValidation3 =
  "Max cart value can't be less than Min cart value";
exports.minCartValue = 'minCartValue';
exports.maxCartValue = 'maxCartValue';
exports.minCartValueText = 'Min cart value';
exports.maxCartValueText = 'Max cart value';
exports.promoCodeValidFromValidation =
  'This field is required, please check the date format';
exports.promoCodeValidToValidation =
  'This field is required, please check the date format';
exports.promoCodeValidToValidation2 =
  'Valid to date should not be less than Valid From date';
exports.pastDate = "Date cannot be in the past";
exports.validFrom = 'validFrom';
exports.validTo = 'validTo';
exports.limitTo = 'Limit to';
exports.usePerCustomer = 'use per customer';
exports.inValidDateTxt = "Invalid Date";
exports.promoCodeUrl = '/business-settings/promo-code/create';
exports.promoCodeUrl2 = '/business-settings/promo-code';
exports.titles = ['Title', 'Description', 'Code', 'Validity'];
exports.discountDataType = [{
  value: "flat",
  label: "Fixed Amount",
},
{
  value: "percentage",
  label: "Percentage",
}];

exports.class_selectInput = "selectInput";

exports.key_start = "start";
exports.key_inline = "inline";
exports.key_outlined = "outlined";

exports.number_type = "number";

exports.label_code = "Code";
exports.label_minCartValue = "Min cart value";
exports.label_maxCartValue = "Max cart value";

exports.label_description = "Description";
exports.valid_checkDateInPast = "checkDateInPast";
exports.field_userLimitDisabled = "userLimitDisabled";
exports.field_userLimit = "userLimit";
exports.field_promoCodeName= "promoCodeName";
exports.field_description = "description";
exports.field_code = "code";
exports.field_discountType = "discountType";
exports.field_discount = "discount";
exports.field_validFrom = "validFrom";
exports.field_minCartValue = "minCartValue";
exports.field_maxCartValue = "maxCartValue";

exports.class_deleteBtn = "delete-items";

exports.testid_deleteItems = "deleteItems";


exports.months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

// Customizable Area End
