// Customizable Area Start
import React from "react";
import HyperLocalDeliveryController, { IDistanceRate } from "../../storecontentmanagement2/src/HyperLocalDeliveryController";
import { Box, Checkbox, InputAdornment, TextField, Tooltip, Typography, withStyles } from "@material-ui/core";
import { infoIcon } from "./assets";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SubmitButtonPopup from "../../../components/src/SubmitButtonPopup.web";
import AlertMessage from "../../../components/src/AlertMessage.web";
import SectionLoader from "../../inventorymanagement/src/SectionLoader.web";

const configJSON = require("./config");
export class HyperLocalDelivery extends HyperLocalDeliveryController {
  hasInvalidRateDistance (range: IDistanceRate) {
    return !range.rate || range.rate === "";
  }

  hasInvalidMaxDistance (range: IDistanceRate) {
    return !range.max_distance || range.max_distance === "";
  }

  hasInvalidMinDistance (range: IDistanceRate) {
    return !range.min_distance || range.min_distance === "";
  }

  hasInvalidOfferAmount (){
    return !this.state.distanceArea.free_delivery_offer_amount || this.state.distanceArea.free_delivery_offer_amount === "";
  }

  shouldShowAddRangeButton() {
    return this.state.distanceArea.distance_rates.length >= 1 ? "+ Add Range" : "Remove";
  }

  renderRemoveButton(index: number) {
    const { classes } = this.props;
    if (index !== 0) {
      return (
        <div className={classes.removeBtnDiv}>
          <span data-testId="remove-btn" className={classes.removeInputsBtn} onClick={() => this.handleRemoveRange(index)}>{configJSON.remove}</span>
        </div>
      );
    }
    return null; 
  }
  render() {

    const { classes } = this.props;
    const { pageInitialized, deliveryOptions, deliveryValue, isLoadingPage,  } = this.state;

    if (!pageInitialized) {
      return null;
    }
    const showDeliveryInfo =
      parseFloat(this.state.distanceArea.max_radius) >= 0.1 && parseFloat(this.state.distanceArea.max_radius) <= 100;

    return (
      <Box className={classes.mainContainer} style={{ overflowY: "auto" }}>
        {isLoadingPage ? (
          <SectionLoader loading={true} />
        ) : (
          <>
            <Box className={classes.tabsDiv} >
              <div className={classes.mapDiv} style={{ display: showDeliveryInfo ? 'flex' : 'none', float: "right" }}>
                <div id="google-map" data-testId="googleMap" className={classes.mapInnerDiv} style={{ display: showDeliveryInfo ? 'flex' : 'none', float: "right" }} ref={this.mapRef} >
                </div>
              </div>
              <div className={classes.mainMaximumDeliveryRadius}>
                <div className={classes.maxDeliveryDiv} >
                  <span className={classes.subMaxDelivery} >{configJSON.maxDeliveryRadius}</span>
                </div>
                <div className={classes.deliveryDistance} >
                  <span className={classes.subDistanceDelivery} >{configJSON.deliveryDistanceText}</span>
                </div>
                <div className={classes.distanceInput} >
                  <TextField
                    variant="outlined"
                    data-testId="distanceKm"
                    inputProps={{maxLength: 5}}
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end">
                          <Typography variant="body1" className={classes.fontKm}>
                            {configJSON.Km}
                          </Typography>
                        </InputAdornment>,
                    }}
                    value={this.state.distanceArea.max_radius}
                    className={classes.inputPrice}
                    onChange={this.handleChangeKm}
                    disabled={false}
                  />
                  <Tooltip title={configJSON.toolTipTitle} placement="right" classes={{ tooltip: classes.customTooltip }}>
                    <img src={infoIcon} className={classes.infoIcon1} alt="information-icon"/>
                  </Tooltip>
                </div>
              </div>
            </Box>
            {showDeliveryInfo && (
              <Box className={classes.mainDeliveryRatesDiv}>
                <div className={classes.deliveryRatesDiv} >
                  <span className={classes.subDeliveryRates} >{configJSON.deliveryRates}</span>
                </div>
                <div className={classes.deliveryDistance} >
                  <span className={classes.subDistanceDelivery} >{configJSON.minimumOderText}</span>
                </div>
                <div className={classes.distanceRatesDiv} >
                  <TextField
                    variant="outlined"
                    type="text"
                    data-testId="minimumAmountValue"
                    className={classes.inputRates}
                    value={this.state.distanceArea.amount}
                    onChange={this.handleChangeDeliveryRates}
                  />
                </div>
                <div className={classes.distanceCalculated} >
                  <div className={classes.subDistanceCalculate} >{configJSON.distanceCalculatedText}</div>
                  <Autocomplete
                    id="autocomplete"
                    className={classes.deliverOptionsBar}
                    options={deliveryOptions}
                    data-testId="deliverySelectDropdown"
                    disableClearable={true}
                    getOptionLabel={(option) => `${option.label}   ${option.description}`}
                    onChange={(event, newValue) => this.handleUpdateDeliveryType(newValue)}
                    value={this.state.deliveryValue}
                    renderOption={(option) => {
                      return (
                        <div className={classes.autoCompleteLabel} aria-label="mailbox folders">
                          <span>{option.label}</span>
                          <span className={classes.autoCompleteDescription}>
                            {option.description}
                          </span>
                        </div>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        value={this.state.deliveryValue ? `${this.state.deliveryValue.label}   -   ${this.state.deliveryValue.description}` : ''}
                      />
                    )}
                  />
                </div>
                <div className={classes.distanceCalculated} >
                  {deliveryValue && deliveryValue.label === "Rate by Distance" && (
                    <>
                      {this.state.distanceArea.distance_rates.map((range, index) => (
                        <div key={range.color}>
                          <div className={classes.distanceAndRateInputs}>
                            <div style={{ flexDirection: "column" }} className={classes.distanceInputsDiv}>
                              <span className={classes.distanceText}>{configJSON.distance}</span>
                              <div className={classes.distanceInputsDiv}>
                                <TextField
                                  variant="outlined"
                                  data-testId="minDistance"
                                  value={range.min_distance}
                                  onChange={this.handleChangeDeliveryOptions(index, "min_distance")}
                                  className={classes.inputPrice}
                                  InputProps={{
                                    endAdornment:
                                      <InputAdornment position="end">
                                        <Typography variant="body1" className={classes.fontKm}>
                                          {configJSON.Km}
                                        </Typography>
                                      </InputAdornment>,
                                  }}
                                  error={this.hasInvalidMinDistance(range)}
                                />
                                <TextField
                                  variant="outlined"
                                  data-testId="maxDistance"
                                  value={range.max_distance}
                                  onChange={this.handleChangeDeliveryOptions(index, "max_distance")}
                                  className={classes.inputDistance2}
                                  InputProps={{
                                    endAdornment:
                                      <InputAdornment position="end">
                                        <Typography variant="body1" className={classes.fontKm}>
                                          {configJSON.Km}
                                        </Typography>
                                      </InputAdornment>,
                                  }}
                                  error={this.hasInvalidMaxDistance(range)}
                                />
                              </div>
                            </div>
                            <div style={{ flexDirection: "column" }} className={classes.rateInputsDiv}>
                              <span className={classes.rateInputs}>{configJSON.rate}</span>
                              <div>
                                <TextField
                                  data-testId="distanceRate"
                                  variant="outlined"
                                  value={range.rate}
                                  onChange={this.handleChangeDeliveryOptions(index, "rate")}
                                  className={classes.inputRatesCalculate}
                                  error={this.hasInvalidRateDistance(range)}
                                />
                              </div>
                            </div>
                            {this.renderRemoveButton(index)}
                          </div>
                          <div className={classes.distanceErrorDiv}>{this.state.distanceErrors[index]}</div>
                        </div>
                      ))}
                      <span data-testId="add-remove-btn" className={classes.addInputsBtn} onClick={this.handleAddRange}>
                        {this.shouldShowAddRangeButton()}
                        <div className={classes.borderAddBtn} style={{}}></div>
                      </span>
                      <div className={classes.checkBoxDiv}>
                        <label>
                          <Checkbox
                            className={classes.checkAllCheckbox}
                            checked={this.state.distanceArea.status_free_delivery}
                            onChange={this.handleCheckboxChange}
                            data-testId="checkCheckbox"
                          />
                          <span className={classes.checkBoxText} >{configJSON.checkBoxText}</span>
                        </label>
                      </div>
                      <div className={classes.offerInputDiv} >
                        <TextField
                          variant="outlined"
                          data-testId="offerInput"
                          className={classes.offerInput}
                          disabled={!this.state.distanceArea.status_free_delivery}
                          value={this.state.distanceArea.free_delivery_offer_amount}
                          onChange={this.handleChangeMinFreeDeliveryAmount}
                          InputProps={{
                            className: classes?.textField,
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title={configJSON.toolTipOfferTitle} placement="right" classes={{ tooltip: classes.customTooltip }}>
                                  <img src={infoIcon} className={classes.infoIcon2} alt="information-icon"/>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                          error={this.hasInvalidOfferAmount()}
                          helperText={
                            (!this.state.distanceArea.free_delivery_offer_amount || this.state.distanceArea.free_delivery_offer_amount === "")
                              ? configJSON.fieldRequired : ''
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
                <div>
                  <AlertMessage
                    data-testId="alertMessages"
                    success={this.state.alertSuccess}
                    open={this.state.openAlert}
                    message={this.state.alertMessage}
                  />
                  <SubmitButtonPopup
                    close={this.discardBtn}
                    data-testId="distanceSubmit"
                    open={this.state.openSubmit}
                    submit={this.submitBtn}
                  />
                </div>
              </Box>
            )}
          </>

        )}
      </Box>
    );
  }
}

const useStyles = {
  mapDiv: {
    width: "531px",
    height: "494px",
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "26px",
    borderRadius: "3px",
    "@media (max-width: 1024px)": {
      marginTop:"20px"
    }
  },
  mapInnerDiv: {
    width: '467px',
    height: '443px',
    "@media (max-width: 1024px)": {
      width: '400px',
      height: '400px',
    }
    
  },
  mainMaximumDeliveryRadius: {
    width: "50%",
    height: "48%",
    backgroundColor: "#fff",
    marginTop: "24px",
    borderRadius: "3px",
   
  },
  mainDeliveryRatesDiv: {
    backgroundColor: "#fff",
    width: "50%",
    marginTop: "24px",
    borderRadius: "3px",

  },
  mainContainer: {
    height: 'calc(100% - 0%)',
    paddingBottom: "30px"
  },
  subDeliveryDiv: {
    color: "#3C3E49",
    fontSize: "28px",
    fontWeight: 500,
  },
  deliveryDiv: {
    padding: "40px 31px 0"
  },
  tabsDiv: {
    marginTop: "20px",
      "@media (max-width: 1024px)": {
        display: "flex",
        flexDirection:"column-reverse",
      }
  },
  maxDeliveryDiv: {
    "@media (min-width: 1024px)": {
      padding: "36px 40px 0",
    },
    "@media (max-width: 1024px)": {
      padding: "13px 33px 0",
    }
  },
  deliveryRatesDiv: {
    "@media (min-width: 1024px)": {
      padding: "50px 40px 0",
    },
    "@media (max-width: 1024px)": {
      padding: "25px 33px 0",
    }
  },
  subMaxDelivery: {
    color: "#3C3E49",
    fontSize: "28px",
    fontWeight: 500,
  },
  subDeliveryRates: {
    color: "#3C3E49",
    fontSize: "28px",
    fontWeight: 500,
  },
  deliverOptionsBar: {
    width: "495px",
    height: "48px",
    "@media (max-width: 1024px)": {
      width: "300px !important",
    }
  },
  deliveryDistance: {
    "@media (min-width: 1024px)": {
      padding: "12px 40px 0"
    },
    "@media (max-width: 1024px)": {
      padding: "13px 33px 0",
    }
  },
  distanceCalculated: {
    "@media (min-width: 1024px)": {
      padding: "25px 40px 0",
    },
    "@media (max-width: 1024px)": {
      padding: "12.5px 33px 0",
    }
  },

  subDistanceDelivery: {
    color: "#3C3E49",
    fontSize: "12px",
    fontWeight: 500,
  },
  subDistanceCalculate: {
    color: "#0F172A",
    fontSize: "12px",
    fontWeight: 500,
    marginBottom: "5px"
  },
  distanceInput: {
    display: "flex",
    alignItems: "center",
    "@media (min-width: 1024px)": {
      padding: "25px 40px",
    },
    "@media (max-width: 1024px)": {
      padding: "25px 33px",
    }

  },
  distanceRatesDiv: {
    "@media (min-width: 1024px)": {
      padding: "15px 40px 0",
    },
    "@media (max-width: 1024px)": {
      padding: "15px 33px 0",
    }
  },
  inputPrice: {
    "& .MuiOutlinedInput-input": {
      padding: "15px",
    },
    width: "115px",
    marginRight: "10px",
  },
  inputDistance2: {
    "& .MuiOutlinedInput-input": {
      padding: "15px",
    },
    width: "105px",
    marginRight: "10px",
    marginBottom: "10px"
  },
  inputRates: {
    "& .MuiOutlinedInput-input": {
      padding: "15px",
    },
    width: "119px",
    height: "48px",
    marginRight: "10px",
  },
  inputRatesCalculate: {
    "& .MuiOutlinedInput-input": {
      padding: "15px",
    },
    width: "119px",
    height: "48px",
    marginLeft: "35px",
  },
  infoIcon1: {
    width: "18px",
    height: "18px",
  },
  infoIcon2: {
    width: "18px",
    height: "18px",
  },
  customTooltip: {
    fontSize: "12px",
    color: "#334155",
    fontWeight: 400,
    borderRadius: "3px",
    padding: "6px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.19)",
  },
  checkAllCheckbox: {
    "&$checked": {
      color: "#364F6B",
    },
    "&:hover": {
      background: "none",
    },
    "&.Mui-checked": {
      color: "#6200EA",
    },
    "&.MuiIconButton-root": {
      padding: "0px"
    }
  },
  checkBoxDiv: {
    padding: "27px 0 0"
  },
  offerInput: {
    "& .MuiOutlinedInput-input": {
      padding: "15px",
    },
    width: "202px",
    marginRight: "10px",
    marginBottom: "20px"
  },
  offerInputDiv: {
    padding: "30px 0px 0",
    display: "flex",
    alignItems: "center",
  },
  checkBoxText: {
    color: "#000000",
    marginLeft: "10px",
    fontSize: "12px",
    fontWeight: 400,
    cursor: "pointer",
  },
  addInputsBtn: {
    color: "#000000",
    fontSize: "14px",
    fontWeight: 400,
    cursor: "pointer",
    marginTop: "10px"
  },
  removeInputsBtn: {
    color: "red",
    borderBottom: "1px solid red"
  },
  removeBtnDiv: {
    display: "flex",
    alignItems: "center",
    marginTop: "16px",
    marginLeft: "40px",
    cursor: "pointer"
  },
  rateInputs: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#000000",
    marginBottom: "10px",
    marginLeft: "35px",
    marginTop: "10px",
  },
  rateInputsDiv: {
    display: "flex",
  },
  distanceInputsDiv: {
    display: "flex",
  },
  distanceAndRateInputs: {
    display: "flex",
  },
  fontKm: {
    fontWeight: 400,
    color: "#64748B"
  },
  distanceText: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#000000",
    marginBottom: "10px",
    marginTop: "10px",
  },
  autoCompleteDescription: {
    color: '#666',
    fontSize: "14px",
    fontWeight: 400,
    marginLeft: "30px"
  },
  autoCompleteLabel: {
    color: '#000',
    fontSize: "14px",
    fontWeight: 400,
  },
  distanceErrorDiv: {
    color: "#DC2626",
    fontSize: "12px",
    marginBottom: "14px"
  },
  borderAddBtn: {
    width: "90px",
    borderBottom: "2px solid #00D659",
    marginTop: "3px"
  },
}

export default (withStyles(useStyles)(HyperLocalDelivery));
// Customizable Area End
