// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    id: string;
    // Customizable Area Start
    classes: Record<string, string>,
    width: string,
    goToBack: () => void,
    orderId: number,
    currencyType: string,
    // Customizable Area End
}

// Customizable Area Start
export interface OrderDetailDataType {
    id: string,
    attributes: {
        id: number
        order_number: string,
        order_type: string,
        order_date: string,
        sub_total: string,
        payment_type: string,
        promo_code: string,
        applied_discount: string,
        total_tax: string,
        total: string,
        status: string,
        delivery_address: {
            attributes: {
                flat_no: string,
                landmark: string,
                address_line_2: string,
                address: string,
                city: string,
                state: string,
                country: string,
                zip_code: string,
            }
        },
        account: {
            attributes: {
                full_name: string,
                phone_number: string,
                country_code: number,
            }
        },
        order_items: OrderItem[];
    }
}
export interface OrderItem {
    attributes: {
        quantity: number;
        catalogue_variants: CatalogueVariantType[];
        total_price: string
        catalogue: {
            attributes: {
                name: string;
                price: GLfloat;
                after_discount: number;
                image: {
                    url: string;
                };
            }
        }
    },
    id: string
}
export interface CatalogueVariantType {
    attributes: {
        catalogue_variant: {
            attributes: {
                title: string,
            }
        }
    }
}
export interface MetaInfoType {
    address_line_1: string,
    address_line_2: string,
    city_name: string,
    state_name: string,
    country_name: string,
    zipcode: string,
    phone_code: string,
}
export interface OrderStatus {
    id: number,
    name: string,
    value: string,
    color: string,
    textColor: string,
    key: string,
}

// Customizable Area End


interface S {
    // Customizable Area Start
    orderDetailData: OrderDetailDataType | null,
    metaInfo: MetaInfoType | null,
    orderStatus: OrderStatus[],
    statusKey: string,
    totalQuantity: number,
    orderStatusSelect: string,
    alertOrderMessage: string;
    openOrderAlert: boolean;
    alertOrderSuccess: boolean;
    openSubmit: boolean;
    shipRocketMessage: string
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class OrderDetailController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start

    orderDetailViewApiCallId: string = "";
    updateOrdersStatusApiCallId: string = "";

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start

        // Customizable Area End



        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            orderDetailData: null,
            metaInfo: null,
            statusKey: "",
            totalQuantity: 0,
            orderStatusSelect: "",
            orderStatus: configJSON.orderStatusList,
            alertOrderMessage: '',
            openOrderAlert: false,
            alertOrderSuccess: false,
            openSubmit: false,
            shipRocketMessage: '',
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (responseJson) {
                if (apiRequestCallId == this.orderDetailViewApiCallId && responseJson.data) {
                    let totalQuantity = 0
                    responseJson?.data?.attributes?.order_items.forEach((data: OrderItem) => {
                        totalQuantity += data.attributes.quantity
                    })
                    this.setState({ orderDetailData: responseJson?.data, totalQuantity: Number(totalQuantity), orderStatusSelect: this.handleValueSelect(responseJson?.data?.attributes?.status), metaInfo: responseJson.meta.store_profile.attributes })
                }
                if (apiRequestCallId == this.updateOrdersStatusApiCallId && responseJson.data) {
                    this.setState({ openSubmit: false, alertOrderMessage: configJSON.orderUpdateMessage, openOrderAlert: true, alertOrderSuccess: true })
                    if (responseJson.meta.shiprocket_status === true) {
                        if (responseJson.meta.ship_msg) {
                            this.setState({ shipRocketMessage: responseJson.meta.ship_msg })
                        } else {
                            this.setState({ shipRocketMessage: '' })
                        }
                    } else {
                        this.setState({ shipRocketMessage: configJSON.shipRocketFailed })
                    }
                }
            }
        }

        // Customizable Area End
    }


    // Customizable Area Start

    async componentDidMount() {
        if (this.props.orderId) {
            this.orderDetailView(this.props.orderId)
        }
    }

    orderDetailView = async (orderId: number) => {
        const token = await getStorageData("admintoken");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.orderDetailViewApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.orderDetailApiEndpoint}/${orderId}`
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    discardBtn = (even: unknown, reason: string) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ openSubmit: false })
    }
    updateOrderStatus = async () => {
        const token = await getStorageData("admintoken");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token
        };
        let data = {
            "status": this.state.statusKey
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.updateOrdersStatusApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.ordersListEndpoint}/${this.state.orderDetailData?.id}/${configJSON.updateOrder}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.examplePutAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    getStatusColor = (value: string) => {
        let color = ""
        for (let item of this.state.orderStatus) {
            if (item.value === this.handleValueSelect(value)) {
                color = item.color
            }
        }
        return color;
    }
    getStatusTextColor = (value: string) => {
        let color = ""
        for (let item of this.state.orderStatus) {
            if (item.value === this.handleValueSelect(value)) {
                color = item.textColor
            }
        }
        return color;
    }
    handleValueSelect = (value: string | undefined) => {
        switch (value) {
            case "Placed":
                return "Order placed"
            case "Ready for pick up":
                return "Ready for Pick Up"
            case "Cancelled":
                return "Cancelled"
            case "Delivered":
                return "Delivered"
            case "Preparing":
                return "Preparing"
            default:
                return "Out for delivery"
        }
    }
    selectOption = (status: string) => {
        this.setState({ statusKey: status });
    }

    handleChange = (value: string) => {
        this.setState({ orderStatusSelect: value, openSubmit: true })
    }

    getVariants = (values: CatalogueVariantType[]) => {
        let names = values.map((a: CatalogueVariantType) => a.attributes.catalogue_variant.attributes.title);
        if (names.length > 0) {
            let variant = "";
            names.forEach((element: string, index: number) => {
                if (index === 0) {
                    variant += element
                } else {
                    variant += `, ${element}`
                }
            });
            return variant
        }
        else {
            return "";
        }
    }
    handleOrderAlertMessageClose = (reason: string) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ openOrderAlert: false });
    };

    // Customizable Area End
}
