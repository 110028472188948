// Customizable Area Start
import * as React from "react";
import Tabs from "@material-ui/core/Tabs";
import MuiTab from "@material-ui/core/Tab";
import { withStyles, createStyles } from "@material-ui/core";
import withDialog from "../../ss-cms-common-components/src/HOC/withDialog.web";
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import DialogProvider from "../../ss-cms-common-components/src/Dialog/DialogContext";
import "../assets/shipping.css";
import { Scrollbars } from "react-custom-scrollbars";
import withSnackBar from "../../ss-cms-common-components/src/HOC/withSnackBar.Web";
import { withRouter } from "react-router-dom";
import ShippingController from "./ShippingController.web";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout"
import  Storecontentmanagement2 from '../../storecontentmanagement2/src/Storecontentmanagement2.web';
import HyperLocalDelivery from "../../../blocks/storecontentmanagement2/src/HyperLocalDelivery.web";


const configJSON = require("./config.js");

export class Shipping extends ShippingController {
  renderShippingTabsCompontents(param: number) {
   if (param === 0) {
      return <HyperLocalDelivery
      id=""
      navigation={this.props.navigation}
      />;
    } 
    else if (param === 1) {
      return <Storecontentmanagement2 
      showLoader={this.props.showLoader}
      navigation={this.props.navigation}
      displaySpinner={this.props.displaySpinner}
      hideLoader={this.props.hideLoader}
      setDialogState={this.props.setDialogState}
      showToast={this.props.showToast}
      id='Storecontentmanagement2'/>;
    }
  }

  render() {
    const { view } = this.state;
    return (
      <DialogProvider>
        <Scrollbars>
          <div className="shipping_container">
            <div className="shipping_view_container">
              <div className="shipping_head_view_container">
                <div className="shipping_text">{configJSON.deliveryTitle}</div>
                <div className="shipping_tabs" style={{ flex: 1 }}>
                  <Tabs
                    value={view}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    className="tabFlexContainer tabsroot"
                  >
                    <MuiTab
                      className={`${
                        view === 0
                          ? "tabIconWrapperSelected tablabelIcon tabroot"
                          : "tabIconWrapper tablabelIcon tabroot"
                      }`}
                      label={configJSON.labelDeliveryArea}
                    />
                         <MuiTab
                      className={`${
                        view === 1
                          ? "tabIconWrapperSelected tablabelIcon tabroot"
                          : "tabIconWrapper tablabelIcon tabroot"
                      }`}
                      label={configJSON.deliveryIntegration}
                    />
                  </Tabs>
                </div>
              </div>
              {this.renderShippingTabsCompontents(view)}
            </div>
          </div>
        </Scrollbars>
      </DialogProvider>
    );
  }
}

const ShippingWithDialog = withDialog(Shipping);
const styles = () =>
  createStyles({
    root: {
      padding: "2px 8px",
      minHeight: "30px",
    },
    tabSelected: {
      color: "#6200EA",
    },
  });
export default withLayout(withStyles(styles)(
  withSnackBar(
    withLoader(withHeaderBar(withRouter(withDialog(ShippingWithDialog))))
  )
));
// Customizable Area End