// Customizable Area Start
import React from "react";
import PaymentGatewayActivisionController, { PaymentGatewayType, configJSON } from "./PaymentGatewayActivisionController.web";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import "../assets/styles.css";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import { clipboard, globalIcon } from "./assets";
import { ActivateAccountDialog } from "./ActivateAccountDialog.web";

export class PaymentGatewayActivision extends PaymentGatewayActivisionController {
  renderEmptyDetail() {
    return (
      <div style={webStyles.containerEmpty}>
        <div style={webStyles.contentEmpty}>
          <img src={globalIcon} style={webStyles.iconEmpty} />
          <span style={webStyles.emptyTextTitle}>{configJSON.emptyTextTitle}</span>
          <span style={webStyles.emptyTextDetail}>{configJSON.emptyTextDetail}</span>
          <div style={webStyles.addStore}>{configJSON.addStore}</div>
        </div>
      </div>
    )
  }

  renderPayment(gateway?: PaymentGatewayType) {
    if (gateway?.attributes.name) {
      return (
        <div style={webStyles.containerPayment}>
          <div style={webStyles.viewPaymentImage}>
            <img src={this.getCurrentPayment().image} style={webStyles.paymentImage} />
          </div>
          <div style={webStyles.contentPayment}>
            <div style={webStyles.viewKey}>
              <span style={webStyles.keyTitle}>{`Secret Key:`}</span>
              <div style={webStyles.key}>
                <span style={webStyles.keyText}>{`${gateway.key1}`}</span>
                <img src={clipboard} style={webStyles.clipboard} onClick={() => this.handleClipboard(gateway.key1 || "")} />
              </div>
            </div>
            <div style={webStyles.viewKey}>
              <span style={webStyles.keyTitle}>{`${this.getCurrentPayment().titleSecondField}:`}</span>
              <div style={webStyles.key}>
                <span style={webStyles.keyText}> {`${gateway.key2}`}</span>
                <img src={clipboard} style={webStyles.clipboard} onClick={() => this.handleClipboard(gateway.key2 || "")} />
              </div>
            </div>
            <div style={webStyles.activateAccount} onClick={() => this.handleModal(true)}>{configJSON.editAccount}</div>
          </div>
        </div>
      )
    }else {
      return (
        <div style={webStyles.containerPayment}>
          <div style={webStyles.viewPaymentImage}>
            <img src={this.getCurrentPayment().image} style={webStyles.paymentImage} />
          </div>
          <div style={webStyles.contentPayment}>
            <span style={webStyles.paymentName}>{this.getCurrentPayment().title}</span>
            <span style={webStyles.paymentDescription}>{configJSON.descriptionPayment}</span>
            <div style={webStyles.activateAccount} onClick={() => this.handleModal(true)}>{configJSON.activateAccount}</div>
          </div>
        </div>
      )
    }
  }

  render() {
    return (
      <div>
        {
          this.state.storeCountry !== '' ? this.renderPayment(this.state.paymentGateway) : this.renderEmptyDetail()
        }
        <ActivateAccountDialog
          title={this.getCurrentPayment().title}
          handleSubmit={(data) => this.handlePutPaymentGateway(data)}
          isShowModal={this.state.isShowModal}
          setShowModal={(v) => this.handleModal(v)}
          titleSecondField={this.getCurrentPayment().titleSecondField}
          currentGateway={this.state.paymentGateway}
        />
      </div>
    );
  }
}

const webStyles = {
  clipboard: {
    width: "16px",
    cursor: "pointer",
  } as React.CSSProperties,
  viewKey: {
    display: 'flex',
    flexDirection: 'column'
  } as React.CSSProperties,
  keyText: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "12px",
    lineHeight: "18px",
    letterSpacing: "0em",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "190px",
  },
  key: {
    display: 'flex',
    justifyContent: "space-between"
  },
  keyTitle: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
    letterSpacing: "0em",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  activateAccount: {
    color: "#364F6B",
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    border: "1px solid #364F6B",
    width: "100%",
    borderRadius: "8px",
    textAlign: "center",
    padding: "12px 0",
    marginTop: "15px"
  } as React.CSSProperties,
  paymentDescription: {
    color: "#8C8C8C",
    fontFamily: "Rubik",
    fontSize: "14px",
    lineHeight: "20px",
    marginTop: "9px"
  },
  paymentName: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "26px",
    letterSpacing: "0em",
  },
  viewPaymentImage: {
    backgroundColor: "#ecedf0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "153px",
    width: "271px",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
  contentPayment: {
    padding: "13px 21px 21px 21px",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    width: "271px",
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
  } as React.CSSProperties,
  containerEmpty: {
    width: "100%",
    paddingTop: "94px",
    display: "flex",
    justifyContent: "center"
  },
  iconEmpty: {
    width: "76px",
    height: "76px",
  },
  contentEmpty: {
    backgroundColor: "#fff",
    padding: "44px 73px",
    display: 'flex',
    flexDirection: "column",
    alignItems: "center",
    width: "66%"
  } as React.CSSProperties,
  emptyTextTitle: {
    whiteSpace: "pre-line",
    marginTop: "20px",
    textAlign: "center",
    fontWeight: 700,
    fontFamily: "Inter",
    color: "#363636",
    fontSize: "24px",
    lineHeight: "32px"
  } as React.CSSProperties,
  emptyTextDetail: {
    whiteSpace: "pre-line",
    marginTop: "8px",
    textAlign: "center",
    fontFamily: "Rubik",
    color: "#676B7E",
    fontSize: "14px",
    lineHeight: "22px",
  } as React.CSSProperties,
  addStore: {
    backgroundColor: "#00D659",
    borderRadius: "3px",
    padding: "12px 40px",
    alignItems: "center",
    display: "flex",
    color: "#3C3E49",
    fontFamily: "Rubik",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 500,
    marginTop: "16px"
  } as React.CSSProperties,
  paymentImage: {
    width: "160px",
    height: "71px"
  },
  containerPayment: {
    marginTop: "28px",
    paddingLeft: "24px",
    paddingRight: "24px"
  }
}

export default withDialog(withLoader(PaymentGatewayActivision));
// Customizable Area End