import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { emitter } from "../../../../packages/blocks/ss-cms-common-components/src/Layout/Layout";
import { getStorageData } from "../../../framework/src/Utilities";
import { isTokenExpired, clearStorageData } from "../../ss-cms-common-components/src/Utilities/Utilities";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:Record<string, string>
  // Customizable Area End
}

interface S {
  txtInputValueData: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  switchState:boolean;
  dataModified:boolean;
  inputValue:string;
  initialDataState:{
    switchState:boolean;
    dataModified:boolean;
    inputValue:string;
  };
  isLoading:boolean;
  storeId:number | string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ItemAvailabilityController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getStoreAvailabilityApiCallId:string = "";
  updateStoreAvailabilityApiCallId:string="";
  getOrderNotificationId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValueData: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      switchState:false,
      dataModified:false,
      inputValue:'',
      initialDataState:{
        switchState:false,
        dataModified:false,
        inputValue:''
      },
      isLoading:false,
      storeId:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.getStoreAvailabilityApiCallId = "";
    this.updateStoreAvailabilityApiCallId = "";
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (isTokenExpired(message)) {
      return this.logoutAndNavigateLogin();
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if(!responseJson.errors || !responseJson.error){
        if (apiRequestCallId === this.getStoreAvailabilityApiCallId) {
          this.handleStoreAvailabilityResponse(responseJson.payments[0]);
        } else if(apiRequestCallId === this.updateStoreAvailabilityApiCallId){
          this.handleStoreAvailabilityResponse(responseJson.payment);
        } else if (apiRequestCallId == this.getOrderNotificationId) {
           emitter.emit("changeNotiNumber", responseJson.new_order_count)
        }
      }else{
        this.parseApiErrorResponse(responseJson.errors && responseJson);
      } 
      this.parseApiCatchErrorResponse(errorJson);
      this.setState({
        isLoading:false
      })
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();
    this.getStoreAvailability()
    this.getOrderNotification()
  }

  getOrderNotification = async () => {
    const token = await getStorageData("admintoken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const requestSAMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestSAMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.validationApiMethodType);
    requestSAMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "/bx_block_order_management/orders/new_order_count");
    requestSAMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    this.getOrderNotificationId = requestSAMessage.messageId;
    runEngine.sendMessage(requestSAMessage.id, requestSAMessage);
  }

  setInitialState = () => {
    this.setState({
      ...this.state.initialDataState
    })
  }
  closeSavePopup = () => {
    this.setState({dataModified:false}, () => {
      this.setInitialState()
    })
  }
  handleDataModified = () => {
    const {inputValue, switchState, initialDataState} = this.state;
    if(inputValue?.trim() === initialDataState.inputValue?.trim() && switchState === initialDataState.switchState){
      this.setState({
        dataModified:false
      })
    }else{
      this.setState({dataModified:true})
    }
  }
  onChangeSwitch = () => {
    this.setState(prevState => ({
      switchState: !prevState.switchState,
    }), () => {
      this.handleDataModified()
    })
  }
  onInputChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= 100) {
      this.setState({
        inputValue:e.target.value
      }, () => {
        this.handleDataModified()
      })
    }
    
  }
  getStoreAvailability = async () => {
    this.setState({
      isLoading:true
    })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token:await getStorageData("admintoken")
    };
    const getStoreAvailabilityMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getStoreAvailabilityApiCallId = getStoreAvailabilityMessage.messageId;
    getStoreAvailabilityMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.storeAvailabilityEndPoint);
    getStoreAvailabilityMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    getStoreAvailabilityMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.validationApiMethodType);
    runEngine.sendMessage(getStoreAvailabilityMessage.id, getStoreAvailabilityMessage);
  }
  updateStoreAvailability = async () => {
    this.setState({
      isLoading:true
    })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token:await getStorageData("admintoken")
    };
    const endpoint = `${configJSON.storeAvailabilityEndPoint}/${this.state.storeId}?online_ordering=${this.state.switchState}&online_store_message=${this.state.inputValue}`
    const updateStoreAvailabilityMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateStoreAvailabilityApiCallId = updateStoreAvailabilityMessage.messageId;
    updateStoreAvailabilityMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endpoint);
    updateStoreAvailabilityMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    updateStoreAvailabilityMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.putApiMethodType);
    runEngine.sendMessage(updateStoreAvailabilityMessage.id, updateStoreAvailabilityMessage);
  }
  handleStoreAvailabilityResponse = (storeData:{id:number|string; online_ordering:boolean; online_store_message:string}) => {
    this.setState({
      storeId:storeData.id,
      switchState:storeData.online_ordering,
      inputValue:storeData.online_store_message,
      dataModified:false,
      initialDataState:{
        switchState:storeData.online_ordering,
        inputValue:storeData.online_store_message,
        dataModified:false
      }
    })
  }
  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };
  // Customizable Area End
}
