// Customizable Area Start
import React from "react";

type PropsType = {
    title: string,
    children?: object
};

const Header = (props: PropsType) =>  {
    return (
      <div className="header">
        <div className="header-title-wrapper">
          <span className="header-title">{props.title}</span>
        </div>
        {props.children}
      </div>
    );
}
export default Header;
// Customizable Area End
