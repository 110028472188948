// Customizable Area Start
import React from "react";
import { Dialog } from "../../ss-cms-common-components/src/Dialog/Dialog";
import TextField from "@material-ui/core/TextField";
import { Formik, Form, FormikProps } from "formik";
import CreateDialogController, { Schema } from "./CreateDialogController.web";

const configJSON = require("./config");

export default class CreateDialog extends CreateDialogController {
  render(): React.ReactNode {
    return (
      <Dialog
        minWidth="684px"
        open={true}
        setOpen={this.props.setOpen}
        onSubmit={this.handleDialogSubmit}
        title={
          (!this.props.editClicked
            ? configJSON.createLabel
            : configJSON.editLabel) +
          " " +
          configJSON.notificationLabel
        }
        okay={configJSON.saveLabel}
        containerClassName="notification-create-dialog"
        titleClassName="notification-create-title"
        customContent
      >
        <Formik
          innerRef={this.formRef}
          initialValues={{
            title: this.props.editClicked
              ? this.props.notificationItem?.title || ""
              : "",
            message: this.props.editClicked
              ? this.props.notificationItem?.message || ""
              : "",
          }}
          validationSchema={Schema}
          onSubmit={this.handleSubmit}
        >
          {(formikProps) => {
            const { values, errors, touched, handleChange } = formikProps;

            return (
              <Form autoComplete="off" noValidate translate="no">
                <div className="content">
                  <span className="input-title">{configJSON.titleLabel} *</span>
                  <TextField
                    variant="outlined"
                    placeholder={configJSON.titlePlaceholder}
                    className="notification-create-input"
                    onChange={handleChange}
                    name="title"
                    value={values.title}
                    error={!!(errors?.title && touched?.title)}
                    helperText={
                      errors?.title && touched?.title ? errors?.title : ""
                    }
                    required
                  />

                  <span className="input-title">
                    {configJSON.messageLabel} *
                  </span>
                  <TextField
                    variant="outlined"
                    className="notification-create-input"
                    placeholder={configJSON.messagePlaceholder}
                    onChange={handleChange}
                    name="message"
                    value={values.message}
                    error={!!(errors?.message && touched?.message)}
                    helperText={
                      errors?.message && touched?.message ? errors?.message : ""
                    }
                    required
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    );
  }
}
// Customizable Area End