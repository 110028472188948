// Customizable Area Start
//@ts-nocheck
import React, { useEffect } from "react";
import { Form, Formik } from "formik";
import withHeaderActionBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import {
  CheckboxInput,
  ImageInput,
  RadioInput,
  SelectInput,
  TextInput,
} from "../../ss-cms-common-components/src/Inputs/Inputs.web";
import _ from "lodash";
import { FormikProps } from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";
const configJSON = require("./config");
import { AppRequirementFormController, ICountry, Schema, IState, ICity, reqInitialType } from "./AppRequirementFormController.web";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { AppCategoryInput } from "./AppCategoryInput.web";
import {
  withStyles,
  Grid,
  TextField,
  InputAdornment,
  createStyles,
  Theme,
  Button,
  InputLabel
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { DownloadJson } from "./DownloadJson";

export class AppRequirementForm extends AppRequirementFormController {

  renderCountryIndia = (formikProps: FormikProps<reqInitialType>) => {
    const { classes } = this.props;
    const { setFieldValue } = formikProps;
    const { cityState, city } = this.state;
    return (
      <>
        <Grid item xs={12} md={3}>
          <div className="d-flex flex-column pb-24">
            <span className={classes.inputLabelWrapper}>
              <span className={classes.inputLabel}>{configJSON.state}</span>
            </span>
            <Autocomplete<IState>
              id="state-list"
              data-testid="ac-state-list-overseas"
              options={this.state.states}
              classes={{
                option: classes.option,
                root: classes.countryTextField,
              }}
              open={this.state.openState}
              onOpen={this.handleStateOpen}
              onClose={this.handleStateClose}
              value={cityState}
              getOptionLabel={(option) => option?.attributes?.name || ""}
              renderOption={(option) => <>{option?.attributes?.name}</>}
              onChange={(event, value) => this.handleChangeState(event, value, setFieldValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="state"
                  placeholder={configJSON.statePlaceholder}
                  InputProps={{
                    ...params.InputProps,
                    className: classes.countryAutocomplete,
                    startAdornment: <>{params.InputProps.startAdornment}</>,
                    endAdornment: (
                      <div
                        className="cursor-pointer"
                        onClick={this.handleStateInverse}
                      >
                        <InputAdornment position="end">
                          <ArrowDropDownIcon />
                        </InputAdornment>
                      </div>
                    ),
                  }}
                  variant="outlined"
                />
              )}
            />
            <div className="h04">
              {formikProps?.errors?.state && (
                <FormHelperText error={true} id="state-error">
                  {formikProps?.errors?.state}
                </FormHelperText>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <div id="city-dropdown" className="d-flex flex-column pb-24">
            <span className={classes.inputLabelWrapper}>
              <span className={classes.inputLabel}>{configJSON.city}</span>
            </span>
            <Autocomplete<ICity>
              data-testid="ac-city-list"
              id="city-list"
              options={this.state.cities}
              classes={{
                option: classes.option,
                root: classes.countryTextField,
              }}
              open={this.state.openCity}
              onOpen={this.handleCityOpen}
              onClose={this.handleCityClose}
              value={city}
              getOptionLabel={(option) => option?.attributes?.name || ""}
              renderOption={(option) => <>{option?.attributes?.name}</>}
              onChange={(event, value) => this.handleChangeCity(event, value, setFieldValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="city"
                  data-testid="txt-city"
                  placeholder={configJSON.cityPlaceholder}
                  InputProps={{
                    ...params.InputProps,
                    className: classes.countryAutocomplete,
                    startAdornment: <>{params.InputProps.startAdornment}</>,
                    endAdornment: (
                      <div
                        className="cursor-pointer"
                        onClick={this.handleCityInverse}
                      >
                        <InputAdornment position="end">
                          <ArrowDropDownIcon />
                        </InputAdornment>
                      </div>
                    ),
                  }}
                  variant="outlined"
                />
              )}
            />
            <div className="h04">
              {formikProps?.errors?.city && (
                <FormHelperText error={true} id="city-error">
                  {formikProps?.errors?.city}
                </FormHelperText>
              )}
            </div>

          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <div className="d-flex flex-column pb-24">

            <TextInput
              {...formikProps}
              showHeaderBar={this.showHeaderBar}
              name="postal_code"
              label={configJSON.postcodeZipPin}
              required
            />

          </div>
        </Grid>
      </>
    );
  };

  renderNonIndianCountry = (formikProps: FormikProps<reqInitialType>) => {
    const { classes } = this.props;
    const { setFieldValue } = formikProps;
    const { cityState, initialValues } = this.state;
    return (
      <>
        <Grid item xs={12} md={4}>
          <div className="d-flex flex-column pb-24">
            <span className={classes.inputLabelWrapper}>
              <span className={classes.inputLabel}>{configJSON.cityState}</span>
            </span>
            <Autocomplete<IState, false, true>
              id="state-list"
              data-testid="ac-state-list"
              options={this.state.states}
              classes={{
                option: classes.option,
                root: classes.countryTextField,
              }}
              open={this.state.openState}
              onOpen={this.handleStateOpen}
              onClose={this.handleStateClose}
              value={cityState}
              getOptionLabel={(option) => option?.attributes?.name || ""}
              renderOption={(option) => <>{option?.attributes?.name}</>}
              onChange={(event, value) => this.handleChangeState(event, value, setFieldValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="state"
                  data-testid="txt-cityState"
                  placeholder={configJSON.cityStatePlaceholder}
                  InputProps={{
                    ...params.InputProps,
                    className: classes.countryAutocomplete,
                    startAdornment: <>{params.InputProps.startAdornment}</>,
                    endAdornment: (
                      <div
                        className="cursor-pointer"
                        onClick={this.handleStateInverse}
                      >
                        <InputAdornment position="end">
                          <ArrowDropDownIcon />
                        </InputAdornment>
                      </div>
                    ),
                  }}
                  variant="outlined"
                />
              )}
            />
            <div className="h04">
              {formikProps?.errors?.state && (
                <FormHelperText error={true} id="state-error">
                  {formikProps?.errors?.state}
                </FormHelperText>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className="d-flex flex-column pb-24">
            <TextInput
              {...formikProps}
              showHeaderBar={this.showHeaderBar}
              name="postal_code"
              label={configJSON.postcodeZipPin}
              required
            />
          </div>
        </Grid>
      </>
    );
  };


  render() {
    const { initialValues, tags_count, country } = this.state;
    const { showHeaderBar, classes } = this.props;

    return (
      <div className="app-requirement">
        <Formik
          innerRef={this.formRef}
          initialValues={initialValues}
          validationSchema={Schema}
          onSubmit={this.handleSubmit}
          enableReinitialize
        >
          {(formikProps) => {
            const { values, errors, touched, handleBlur, setFieldValue } = formikProps;
            return (
              <Form autoComplete="off" noValidate className="form" translate={undefined}>
                <Card title={configJSON.mobileAppTitle}>

                  <TextInput
                    {...formikProps}
                    showHeaderBar={showHeaderBar}
                    name="app_name"
                    label={configJSON.appName}
                    required
                  />
                  <TextInput
                    {...formikProps}
                    showHeaderBar={showHeaderBar}
                    name="short_description"
                    label={configJSON.promotionalText}
                    limit={170}
                    required
                    multiline
                    rows={4}
                  />
                  <TextInput
                    {...formikProps}
                    showHeaderBar={showHeaderBar}
                    name="description"
                    label={configJSON.description}
                    limit={4000}
                    required
                    multiline
                    rows={4}
                  />
                  <ImageInput
                    {...formikProps}
                    showHeaderBar={showHeaderBar}
                    name="app_icon"
                    label={configJSON.appIcon}
                    height="100px"
                    width="140px"
                    required
                  />
                  <ImageInput
                    {...formikProps}
                    showHeaderBar={showHeaderBar}
                    name="common_feature_banner"
                    label={configJSON.commonFeatureBanner}
                    height="180px"
                    required
                  />

                  <label className="fc16">
                    {Array.from({ length: tags_count || 1 }).map((i, index) => (
                      <TextInput
                        key={`tags[${index}]`}
                        {...formikProps}
                        showHeaderBar={showHeaderBar}
                        name={`tags[${index}]`}
                        label={!index ? configJSON.tags : ""}
                      />
                    ))}
                    <button className="add-tag" onClick={this.handleClickNewTag} />
                  </label>
                </Card>
                <Card title={configJSON.contactDetails}>
                  <TextInput
                    {...formikProps}
                    showHeaderBar={showHeaderBar}
                    name="website"
                    label={configJSON.website}
                  />
                  <TextInput
                    {...formikProps}
                    showHeaderBar={showHeaderBar}
                    name="email"
                    label={configJSON.email}
                    required
                  />
                  <TextInput
                    {...formikProps}
                    showHeaderBar={showHeaderBar}
                    name="phone"
                    label={configJSON.phone}
                    type="number"
                  />
                  <TextInput
                    {...formikProps}
                    showHeaderBar={showHeaderBar}
                    name="first_name"
                    label={configJSON.firstName}
                    required
                  />
                  <TextInput
                    {...formikProps}
                    showHeaderBar={showHeaderBar}
                    name="last_name"
                    label={configJSON.lastName}
                    required
                  />
                  <Grid
                    container
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Grid
                      item
                      xs={12}
                      md={this.state.country?.attributes?.name !== "India" ? 4 : 3}
                    >
                      <div className="d-flex flex-column pb-24">
                        <span className={classes.inputLabelWrapper}>
                          <span className={classes.inputLabel}>{configJSON.country}</span>
                        </span>
                        <Autocomplete<ICountry>
                          id="country-list"
                          data-testid="ac-country-list"
                          options={this.state.locations}
                          classes={{
                            option: classes.option,
                            root: classes.countryTextField,
                          }}
                          open={this.state.openCountry}
                          onOpen={this.handleCountryOpen}
                          onClose={this.handleCountryClose}
                          value={country}
                          getOptionLabel={(option) => option?.attributes?.name || ""}
                          renderOption={(option) => (
                            <>
                              {option && (
                                <span>
                                  {this.countryToFlag(option?.attributes?.code)}
                                </span>
                              )}
                              {option?.attributes?.name} ({option?.attributes?.code})
                            </>
                          )}
                          onChange={(event, value) => this.handleChangeCountry(event, value, setFieldValue)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="country"
                              data-testid="txt-country"
                              placeholder={configJSON.countryPlaceholder}
                              InputProps={{
                                ...params.InputProps,
                                className: classes.countryAutocomplete,
                                startAdornment: (
                                  <>
                                    <InputAdornment position="start">
                                      <span className="mt3">
                                        {this.countryToFlag(country?.attributes?.code)}
                                      </span>
                                    </InputAdornment>
                                  </>
                                ),
                                endAdornment: (
                                  <div
                                    className="cursor-pointer"
                                    onClick={this.handleCountryInverse}
                                  >
                                    <InputAdornment position="end">
                                      <ArrowDropDownIcon />
                                    </InputAdornment>
                                  </div>
                                ),
                              }}
                              variant="outlined"
                            />
                          )}
                        />
                        <div className="h04">
                          {!this.state.country?.id && this.state.localValidation && (
                            <div id="country-error" className="validation-field">
                              {configJSON.requiredField}
                            </div>
                          )}
                        </div>
                      </div>
                    </Grid>
                    {this.state.country?.attributes?.name !== "India" ||
                      !this.state.country
                      ? this.renderNonIndianCountry(formikProps)
                      : this.renderCountryIndia(formikProps)}
                  </Grid>
                  <TextInput
                    {...formikProps}
                    showHeaderBar={showHeaderBar}
                    name="address"
                    label={configJSON.addressLine_1}
                    placeholder={configJSON.enterTheAddressDetailsPlaceholder}
                    required
                  />
                </Card>
                <Card title={configJSON.pageUrLs}>
                  <TextInput
                    {...formikProps}
                    showHeaderBar={showHeaderBar}
                    name="privacy_policy_url"
                    label={configJSON.privacyPolicyUrl}
                    onBlur={formikProps.handleBlur}
                  />
                  <TextInput
                    {...formikProps}
                    showHeaderBar={showHeaderBar}
                    name="support_url"
                    label={configJSON.supportUrl}
                    onBlur={formikProps.handleBlur}
                  />
                  <TextInput
                    {...formikProps}
                    showHeaderBar={showHeaderBar}
                    name="marketing_url"
                    label={configJSON.marketingUrl}
                    onBlur={formikProps.handleBlur}
                  />
                  <TextInput
                    {...formikProps}
                    showHeaderBar={showHeaderBar}
                    name="terms_and_conditions_url"
                    label={configJSON.termsAndConditionsUrl}
                    onBlur={formikProps.handleBlur}
                  />
                  <SelectInput
                    {...formikProps}
                    showHeaderBar={showHeaderBar}
                    name="target_audience_and_content"
                    label={configJSON.targetAudienceAndContent}
                    placeholder={configJSON.selectAudiencePlaceholder}
                    options={[
                      { label: configJSON.label5AndUnder, value: "5 and under" },
                      { label: configJSON.label6_8, value: "6-8" },
                      { label: configJSON.label9_12, value: "9-12" },
                      { label: configJSON.label13_15, value: "13-15" },
                      { label: configJSON.label16_17, value: "16-17" },
                      { label: configJSON.label18AndOver, value: "18 and under" },
                    ]}
                  />
                </Card>
                <Card title={configJSON.pricingAndDistribution}>
                  <RadioInput
                    {...formikProps}
                    onChange={(event) => this.handleChangeIsPaid(event, setFieldValue)}
                    showHeaderBar={showHeaderBar}
                    name="is_paid"
                    options={[
                      { label: configJSON.labelFreeApp, value: "false" },
                      { label: configJSON.labelPaidApp, value: "true" },
                    ]}
                  />
                  <div style={webStyle.defaultValueHeader}>
                  <InputLabel style={webStyle.defaultValueLabel}>{configJSON.defaultPrice}</InputLabel>
                  <TextInput
                    {...formikProps}
                    showHeaderBar={showHeaderBar}
                    style={webStyle.defaultValue}
                    name="default_price"
                    placeholder=""
                    disabled={this.state.is_paid === "false"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <img src={require("../assets/lock.svg")} alt="" />
                        </InputAdornment>
                      ),
                    }}
                    type="number"
                  />
                  </div>
                  <TextInput
                    {...formikProps}
                    showHeaderBar={showHeaderBar}
                    name="distributed_countries"
                    label={configJSON.distributedCountries}
                  />
                  <div className="checkbox-section-wrapper">
                    <CheckboxInput
                      {...formikProps}
                      showHeaderBar={showHeaderBar}
                      name="auto_price_conversion"
                      label={configJSON.autoPriceConversion}
                    />
                    <CheckboxInput
                      {...formikProps}
                      showHeaderBar={showHeaderBar}
                      name="android_wear"
                      label={configJSON.androidWear}
                    />
                    <CheckboxInput
                      {...formikProps}
                      showHeaderBar={showHeaderBar}
                      name="google_play_for_education"
                      label={configJSON.googlePlayForEducation}
                    />
                    <CheckboxInput
                      {...formikProps}
                      showHeaderBar={showHeaderBar}
                      name="us_export_laws"
                      label={configJSON.usExportLaws}
                    />
                  </div>
                  <TextInput
                    {...formikProps}
                    showHeaderBar={showHeaderBar}
                    name="copyright"
                    label={configJSON.copyright}
                  />
                </Card>
                <Card title={configJSON.appCategory}>
                  {this.state.app_categories_attributes_count ? (
                    <div className="app-category-wrapper">
                      {Array.from({
                        length: this.state.app_categories_attributes_count,
                      }).map((initial, index) => (
                        <AppCategoryInput
                          formikProps={formikProps}
                          showHeaderBar={this.showHeaderBar} itemIndex={index} />
                      ))}
                    </div>
                  ) : null}

                  <Button
                    className="add-app-type"
                    onClick={this.handleClickNew("app_categories_attributes", formikProps.setFieldValue)}
                  >
                    <img src={require("../assets/add.svg")} alt="add app type" />
                    <span>{configJSON.addAppType}</span>
                  </Button>

                  {_.get(formikProps.errors, "app_categories_attributes") &&
                    _.get(formikProps.touched, "app_categories_attributes") &&
                    typeof formikProps.errors?.app_categories_attributes === "string" && (
                      <FormHelperText error={true}>
                        {_.get(formikProps.errors, "app_categories_attributes")}
                      </FormHelperText>
                    )}
                </Card>
                <button type="submit" className="d-none" />
              </Form>
            );
          }}
        </Formik>
        <div className="flex flex-row">
          <SampleDocuments />
          <DownloadJson />
        </div>
      </div>
    );
  }
};



export const SampleDocuments = () => {
  return (
    <div className="app-requirement-documents">
      <div className="title">{configJSON.sampleDocuments}</div>
      <div className="hint">{configJSON.sampleDocumentsHint}</div>
      <a className="link" href={configJSON.sampleDocumentsLink} target="_blank">
        <img src={require("../assets/download.svg")} />
        <span>{configJSON.iOsSampleDocument} </span>
      </a>
      <a
        className="link"
        href={configJSON.sampleDocumentsLink2}
        target="_blank"
      >
        <img src={require("../assets/download.svg")} />
        <span>{configJSON.googlePlaySampleDocument} </span>
      </a>
    </div>
  );
};

interface CardProps {
  title: string;
  children?: React.ReactNode;
}

export const Card = ({ title, children }: CardProps) => {
  return (
    <div className="input-card">
      <span className="input-card-title">{title}</span>
      {children}
    </div>
  );
};

export const styles = (theme: Theme) =>
  createStyles({
    areaCodeSelect: {
      height: 48,
      width: "110px",
      color: "#3C3E49",
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 14,
    },
    selectIconStyle: {
      color: "black",
    },
    inputLabelWrapper: {
      whiteSpace: "nowrap",
      display: "flex",
      justifyContent: "space-between",
    },
    inputLabel: {
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "20px",
      display: "flex",
      alignItems: "center",
      color: "#000000",
      marginBottom: "8px",
    },
    textField: {
      width: "100%",
      color: "#3C3E49",
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 14,
    },
    countryTextField: {
      width: "100%",
      color: "#3C3E49",
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 14,
    },
    countryAutocomplete: {
      padding: "0px",
      paddingLeft: "12px",
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 14,
      paddingRight: 4,
      textOverflow: "ellipsis",
    },
    textArea: {
      width: "100%",
      color: "#3C3E49",
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 14,
    },
    whatsappLabel: {
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 12,
      display: "flex",
      alignItems: "center",
      color: "#3C3E49",
    },
    option: {
      fontSize: 15,
      "& > span": {
        marginRight: 10,
        fontSize: 18,
      },
    },
    editUploadBoxContainer: {
      position: "relative",
      height: "160px",
      margin: "16px 0px 0px 0px",
      width: "144px",
    },
    crossOverImage: {
      right: "-5px",
      top: "-5px",
      position: "absolute",
      backgroundColor: theme.palette.primary.main,
      color: "white",
      padding: "3px",
      width: "16px",
      height: "16px",
      borderRadius: "50%",
      cursor: "pointer",
    },
    editUploadBoxImage: {
      borderRadius: "3px",
      width: "100%",
      maxHeight: "100%",
      objectFit: "contain",
      objectPosition: "center",
    },
    cropperBox: {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#FFF",
      borderRadius: "10px",
    },
    modalTitle: {
      color: "#3C3E49",
      fontSize: 28,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: 1.21,
      fontStretch: "normal",
      fontFamily: "Rubik",
    },
    closeIcon: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  });

const webStyle={
    defaultValue: {
      width: "30%"
    } as React.CSSProperties,
    defaultValueLabel: {
      fontFamily: "Rubik",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#83889E",
      marginBottom: "7px",
    } as React.CSSProperties,
    defaultValueHeader: {
      marginLeft: "30px",
      marginTop: "-15px"
    }
}

export default withStyles(styles)(withDialog(withHeaderActionBar(withLoader(AppRequirementForm))));
// Customizable Area End
