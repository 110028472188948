import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start
// Customizable Area End

export type Props = {
  // Customizable Area Start
  handleInputChangeClick: (value: string) => void;
  value?: ValueType;
  isDisabled: boolean;
  isError: boolean;
  isHelperText: string;
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  optionValue: ValueType;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

type ValueType = {
  title: string;
  type: string;
  page_no: number;
};
export default class CustomAutoCompletePickerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.state = {
      optionValue: this.props.value || {} as ValueType,
    };
  }

  handleOptionChange = (object: ValueType) => {
    this.setState({ optionValue: object });
    this.props.handleInputChangeClick(object?.type);
  };
}
