import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start


// Customizable Area End
export const configJSON = require("./config");
export const baseURL = require("../../../framework/src/config.js").baseURL;

export interface Props extends WithStyles {
    navigation: any;
    id: string;
    // Customizable Area Start
    width: string
    // Customizable Area End
}

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { WithStyles } from "@material-ui/core";

export interface StripeObj{
    client_secret: string,
    payment_intent_id: string,
    customer_id: string,
    orderId: string,
    stripe_publishable_key: string,
    amount: string
}

// Customizable Area End

interface S {
    // Customizable Area Start
    token: string;
    currency: string;
    stripeInfo:StripeObj | null;
    value:string
    alertOrderMessage: string;
    openOrderAlert: boolean;
    alertOrderSuccess: boolean;
    showStripe:boolean
    // Customizable Area End
}


interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class StripePaymentsHomeController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start


    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            currency: "",
            stripeInfo:{
                client_secret: '',
                payment_intent_id: '',
                customer_id: '',
                orderId: '',
                stripe_publishable_key: '',
                amount: ''
            },
            value:'',
            alertOrderMessage: '',
            openOrderAlert: false,
            alertOrderSuccess: false,
            showStripe:true
         
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();

        // Customizable Area Start
        window.scrollTo(0, 0);
        this.getCartItems()
        this.doButtonPressed()
        // Customizable Area End
    }



 

    receive = async (from: string, message: Message) => {
        // Customizable Area Start
        if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
            let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
        this.setState({value:value})
          }
        // Customizable Area End
    };

    // Customizable Area Start


    getCartItems = async () => {
        let data = JSON.parse(await getStorageData('stipeInfo'));
    this.setState({stripeInfo:data})     
    }

    pressBackButton=()=>{
        let return_url=`${window.location.origin}/CartOrders?status=cancel`   
        window.open(return_url,"_self")
    }

    doButtonPressed=()=>{
        let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
        message.addData(
          getName(MessageEnum.AuthTokenDataMessage),
          'sample'
        );
        this.send(message);
      }

      handleOrderAlertMessageClose = (reason: string) => {
        if (reason === 'clickaway') {
            return;
        }
        let return_url=`${window.location.origin}/orderconfirmation` 
        window.open(return_url,"_self")
    };

    paymentSuccessCallBack=()=>{
        this.setState({openOrderAlert:true,alertOrderSuccess:true,alertOrderMessage:'Payment was successful',showStripe:false})
    }

  
    // Customizable Area End
}
