// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import { getResponseVariables } from "../../../../packages/blocks/ss-cms-common-components/src/GetResponseVariables/GetResponseVariables";
import { clearStorageData, isTokenExpired } from "../../../../packages/blocks/ss-cms-common-components/src/Utilities/Utilities";
import { emitter } from "../../../../packages/blocks/ss-cms-common-components/src/Layout/Layout";

const configJSON = require("./config.js");

export interface PaymentMethod {
  id: string,
  type: string,
  attributes: {
    id: number,
    payment_method: string,
    active: boolean
  }
}

export type Props = {
  navigation: {
    navigate: (to: string, params: object) => void;
    getParam: (param: string) => string;
    goBack: () => void;
  };
  id: string;
  hideLoader: () => void;
  showLoader: () => void;
  showHeaderBar: () => void;
  setDialogState: (status: boolean, {
    title,
    message,
    confirmBackground,
    confirmHoverBackground,
    hideCancel
  }: {
    title: string;
    message: string;
    confirmBackground: string
    confirmHoverBackground: string
    hideCancel: boolean
  }) => void
};

interface S {
  pageInitialized: boolean;
  paymentStatus: string;
  upiPaymentStatus: string;
  showActivateDialog: boolean;
  paymentMethods: PaymentMethod[];
  view: number;
}

interface SS {
  id: string | number;
}

class PaymentController extends BlockComponent<Props, S, SS> {
  getOrderNotificationId: string = "";
  countryMessageId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      view: 0,
      pageInitialized: true,
      showActivateDialog: false,
      paymentStatus: "loading",
      upiPaymentStatus: "loading",
      paymentMethods: [],
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  receive = (from: string, message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }
    }
    if (apiRequestCallId == this.getOrderNotificationId) {
      emitter.emit("changeNotiNumber", responseJson.new_order_count);
    }
  }

  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  getOrderNotification = async () => {
    const token = await getStorageData("admintoken");
    const header = {
      "Content-Type": 'application/json',
      token
    };
    const requestSAMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getOrderNotificationId = requestSAMessage.messageId;
    requestSAMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "/bx_block_order_management/orders/new_order_count");
    requestSAMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestSAMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestSAMessage.id, requestSAMessage);
  }

  openErrorHeaderBar = () => {
    this.props.setDialogState(true, {
      title: configJSON.dialog_title,
      message: configJSON.dialog_msg,
      confirmBackground: configJSON.dialog_bg,
      confirmHoverBackground: configJSON.dialogCFHover,
      hideCancel: true,
    });
  };

  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      view: newValue,
    });
  };

  async componentDidMount(): Promise<void> {
    this.getOrderNotification()
  }
}

export default PaymentController;
// Customizable Area End
