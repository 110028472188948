// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  FirebaseSettingsResponse,
  IntegrationResponseTypes,
  IntegrationSettingsResponse,
  IntegrationTypes,
  KaleyraSettingsResponse,
  LiveChatTawkSettingsResponse,
  IntegrationResponseWithTypeTypes
} from "./OtherIntegrations.types";
import { RouterProps } from "react-router";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withLoader.Web"
import { withToastProps } from "../../ss-cms-common-components/src/HOC/withSnackBar.Web";
import {generateRequestMessage, showError, isTokenExpired, clearStorageData} from "../../ss-cms-common-components/src/Utilities/Utilities";
import { getResponseVariables } from "../../ss-cms-common-components/src/GetResponseVariables/GetResponseVariables";
const configJSON = require("./config.js");

export type Props = RouterProps &
  DialogProps &
  withHeadeActionBarProps &
  withLoaderProps &
  withToastProps & {
    navigation: {
      navigate: (to: string, params: object) => void;
      getParam: (param: string) => string;
      goBack: () => void;
    };
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  pageInitialized: boolean;
  integrationData: IntegrationSettingsResponse;
  selectedIntegrationData: IntegrationResponseTypes;
  dialogStatus: boolean;
  cardShowStatus: { [key: string]: boolean };
  [key: string]: unknown;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export interface MessageProps {
  id: string;
  type: IntegrationTypes;
}

class OtherIntegrationsController extends BlockComponent<Props, S, SS> {
  getFirebaseSettingsMessageId: string = "";
  updateMessageId: string = "";
  activationStatusMessageId: string = "";
  updateIntegrationMessageId: string = "";
  otherIntegrationsMessageId: MessageProps[] = [];
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      pageInitialized: false,
      showActivateDialog: false,
      dialogStatus: false,
      selectedIntegrationData: {} as IntegrationResponseTypes,
      isUpdateOnProgress: false,
      cardShowStatus: { kaleyra: false },
      integrationData: {
        firebase: {
          loading: true,
          integrationName: configJSON.typeFirebase,
          showDialog: false,
        } as FirebaseSettingsResponse,
        tawk: {
          loading: true,
          integrationName:configJSON.typeTawk,
          showDialog: false,
        } as LiveChatTawkSettingsResponse,
        kaleyra: {
          loading: true,
          integrationName: configJSON.typeKaleyra,
          showDialog: false,
        } as KaleyraSettingsResponse,
      },
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.initPage();
    // Customizable Area Start
    // Customizable Area End
  }

  initPage() {
    this.getOtherIntegrationData();
    this.props.showLoader();
  }

  getOtherIntegrationData() {
    const sortedIntegration = configJSON.integrations.sort(
      (orderA: {order: number}, orderB: {order: number}) => orderA.order - orderB.order
      );
    this.otherIntegrationsMessageId = Array.from({length: sortedIntegration.length});
    sortedIntegration.forEach(async (integration: {[key: string]: string}, index: number) => {
      let requestMessage = await generateRequestMessage(
        integration.apiEndpoint as string,
        configJSON.getApiMethodType
      );
      if (integration?.fetchEndpoint) {
        requestMessage = await generateRequestMessage(
          `${integration.fetchEndpoint}${integration.fetchQuery ?? ""}`,
          configJSON.getApiMethodType
        );
      }

      this.otherIntegrationsMessageId[index] = {
        type: integration.integrationName as IntegrationTypes,
        id: requestMessage.messageId,
      };
      this.send(requestMessage);
    });
  }

  getIntegrationData = async (integrationType: IntegrationTypes) => {
    const integration = this.findIntegrationData(integrationType);
    let requestMessage = await generateRequestMessage(
      integration?.apiEndpoint,
      configJSON.getApiMethodType
    );
    if (integration?.fetchEndpoint) {
      requestMessage = await generateRequestMessage(
        `${integration.fetchEndpoint}${integration.fetchQuery ?? ""}`,
        configJSON.getApiMethodType
      );
    }
    this.otherIntegrationsMessageId = [
      { type: integration.integrationName, id: requestMessage.messageId },
    ];
    this.send(requestMessage);
  };
  findIntegrationData(integrationType: IntegrationTypes | undefined) {
    return configJSON[integrationType || ""]
  }

  updateOtherIntegrations = async (integrationData: IntegrationResponseWithTypeTypes) => {
    if (this.state.isUpdateOnProgress) {
      return;
    }
    this.props.showLoader();
    this.setState({ isUpdateOnProgress: true });
    const updateData = integrationData;
    if (!updateData?.is_active) {
      updateData.is_active = false;
    }
    const integration = this.findIntegrationData(
      integrationData?.integrationType
    );
    if(updateData?.integrationType){
      delete updateData.integrationType;
    }

    let updateEndpoint = integration?.apiEndpoint;
    let updateMethod = configJSON.putApiMethodType;
    if (integration?.updateWithPut && integrationData?.id) {
      updateMethod = configJSON.putApiMethodType;
      updateEndpoint = `${updateEndpoint}`;
    }
    const requestMessage = await generateRequestMessage(
      updateEndpoint,
      updateMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(updateData)
    );
    this.updateIntegrationMessageId = requestMessage.messageId;
    this.send(requestMessage);
  };

  receive = (_from: string, message: Message) => {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const integration = this.otherIntegrationsMessageId.find(
        (integrationMessage) => integrationMessage?.id === apiRequestCallId
      );
      if (integration) {
        this.setIntegrationData(message, integration);
      } else if (this.updateIntegrationMessageId === apiRequestCallId) {
        this.updateIntegrationData(message);
      }
    }
  };

  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  setIntegrationData = (message: Message, integration: {type: IntegrationTypes}) => {
    const [responseJson] = getResponseVariables(message);
    this.props.hideLoader();
    if (responseJson?.errors || responseJson?.error) {
      this.setState({
        integrationData: {
          ...this.state.integrationData,
          [integration.type]: {
            integrationName:
              this.state.integrationData[integration.type]?.integrationName,
            loading: false,
            showDialog: false,
            isFetched: false,
          },
        },
      });
      return this.handleError(
        `${integration.type} ${configJSON.errors.integration}`,
        message
      );
    } else {
      this.setIntegartionIsData(message, integration)
    }
    this.props.hideLoader();
  };
  setIntegartionIsData = (message: Message, integration: {type: IntegrationTypes}) => {
    const [responseJson] = getResponseVariables(message);
    let response = responseJson;
    if (responseJson?.data) {
      if (Array.isArray(responseJson?.data)) {
        if (responseJson?.data.length) {
          response = responseJson.data[0].attributes;
        }
      } else {
        response = responseJson?.data?.attributes || responseJson?.data;
      }
    }
    this.setState({
      integrationData: {
        ...this.state.integrationData,
        [integration.type]: {
          loading: false,
          showDialog: false,
          isFetched: true,
          integrationName: integration.type,
          ...response,
        },
      },
    });
    if (integration.type === configJSON.typeKaleyra && !!responseJson?.data) {
      this.setState({
        cardShowStatus: {
          ...this.state.cardShowStatus,
          kaleyra: true,
        },
      });
    }
  }

  updateIntegrationData = (message: Message) => {
    const [responseJson] = getResponseVariables(message);
    this.setState({ isUpdateOnProgress: false });
    this.props.hideLoader();
    if (responseJson.errors || responseJson.error) {
      return this.handleError(
        `${this.state.selectedIntegrationData?.integrationName || ""} ${
          configJSON.errors.updateIntegration
        }`,
        message
      );
    } else {
      this.setState({
        showActivateDialog: false,
      });
      this.props.showToast({
        type: "success",
        message: configJSON.updateIntegrationSuccess,
      });
      this.getIntegrationData(
        this.state.selectedIntegrationData?.integrationName as IntegrationTypes
      );
    }
  };
  updateIntegrationActivation = async (integrationData: IntegrationResponseTypes, isActive: boolean) => {
    if (this.state.isUpdateOnProgress) {
      return;
    }
    this.props.showLoader();
    this.setState({ isUpdateOnProgress: true });

    const { integrationName, ...updateData } = integrationData;
    updateData.is_active = !isActive;
    const integration = this.findIntegrationData(
      integrationData?.integrationName as IntegrationTypes
    );
    let updateEndpoint = integration?.apiEndpoint;
    let updateMethod = configJSON.putApiMethodType;
    if (integration?.updateWithPut) {
      updateMethod = configJSON.putApiMethodType;
      updateEndpoint = `${updateEndpoint}`;
    }

    const requestMessage = await generateRequestMessage(
      updateEndpoint,
      updateMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(updateData)
    );
    this.setState({
      selectedIntegrationData: integrationData,
    });
    this.updateIntegrationMessageId = requestMessage.messageId;
    this.send(requestMessage);
  };

  handleError = (title: string, message: Message) => {
    const [responseJson] = getResponseVariables(message);
    if (responseJson?.errors?.length > 1) {
      return showError(
        title,
        responseJson,
        this.props.hideLoader,
        this.props.setDialogState
      );
    }
    this.props.showToast({
      type: "error",
      message:
        responseJson?.errors?.[0]?.detail ||
        responseJson?.error ||
        configJSON.errors.default,
    });
  };

  selectIntegration = (integration: IntegrationResponseTypes) => {
    this.setState({
      showActivateDialog: true,
      selectedIntegrationData: integration,
    });
  };

  setActivateDialog = (newState: boolean) => {
    this.setState({
      showActivateDialog: newState,
    });
  };
  handleChangeDialogStatus = () => {
    this.setState({ dialogStatus: !this.state.dialogStatus });
  };
}

export default OtherIntegrationsController;
// Customizable Area End