// Customizable Area Start
import PromoCodeController from './PromoCodeController.web';
import * as React from 'react';
import withLoader from '../../ss-cms-common-components/src/HOC/withBrandingSpinner.web';
import withSnackBar from '../../ss-cms-common-components/src/HOC/withSnackBar.Web';
import TableComponent from '../../ss-cms-common-components/src/TableComponent/TableComponent';
import EmptyPage from '../../ss-cms-common-components/src/EmptyPage/EmptyPage';
import Pagination from '../../ss-cms-common-components/src/Pagination/Pagination';
import { Scrollbars } from 'react-custom-scrollbars';
import { withDialog } from '../../ss-cms-common-components/src/Dialog/withDialog';
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout"
export const configJSON = require("./config.js");
import "../assets/promoCode.css";
import withStyles, { CSSProperties } from '@material-ui/core/styles/withStyles';

export class PromoCode extends PromoCodeController {
  render() {
    const { promoCodeData, showEmptyPage, totalCount, checkedPromoList } = this.state;
    if (promoCodeData?.length > 0) {
      return (
        <Scrollbars ref={this.scrollRef}>
          <div className='tableContainer'>
            <div className='header'>
              <div className='header-title-wrapper'>
                <span className="header-title">{configJSON.promoCodes}</span>
                <section className="d-flex align-items-center">
                  {checkedPromoList?.length > 0 &&
                    <p
                      className={"delete-promo-code"}
                      data-testid={configJSON.testid_deleteItems}
                      onClick={this.handleDeleteModal}>{configJSON.deletePromoCodes}
                    </p>
                  }
                  <div className='header-buttons'>
                    <button className='header-create-button-promo' onClick={this.goToAddPromoCode}>
                      {configJSON.createPromoCode}
                    </button>
                  </div>
                </section>
              </div>
            </div>
            <TableComponent
              titles={configJSON.titles}
              data={promoCodeData}
              edit
              handleEditClick={this.handleEditClick}
              getCheckedItems={this.checkedItems}
              ignoredKeys={['id']}
              checkbox
              showBorderCheckboxLeft={true}
              showBorderEdit={true}
            />
            <div className="pagination-wrapper">
              <Pagination
                count={totalCount}
                onChange={this.handlePageChange}
                itemText={configJSON.paginationText}
                pageSize={this.state.per_page}
              />
            </div>
          </div>
        </Scrollbars>
      );
    } else if (showEmptyPage || promoCodeData?.length==0) {
      return (
          <div className='emptyPageContainerPromoCode'>
            <EmptyPage
              title={configJSON.emptyPageTitle}
              message={configJSON.emptyPageMessage}
              button_label={configJSON.emptyPageButtonLabel}
              handleClick={this.goToAddPromoCode}
            />
          </div>
      );
    } else {
      return null;
    }
  }
}
const useStyles: Record<string, CSSProperties> = {
  
};

export default withLayout(
  withSnackBar(withLoader(withDialog(withHeaderBar(withStyles(useStyles)(PromoCode))))
));;
// Customizable Area End
