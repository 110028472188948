// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { isTokenExpired, clearStorageData } from "../../ss-cms-common-components/src/Utilities/Utilities";
import { emitter } from "../../../../packages/blocks/ss-cms-common-components/src/Layout/Layout";
import {FormikProps, FormikTouched, FormikErrors} from 'formik';
import React from 'react';
import { difference } from "lodash";
// Customizable Area End

// Customizable Area Start
import {
  veg_icon,
  nonVeg_icon,
  vegan_icon,
  glutenfree_icon,
  halal_icon,
  kosher_icon,
  eggs_icon,
  nuts_icon,
  wheat_icon,
  soy_icon,
  peanuts_icon,
  celery_icon,
  fish_icon,
  crustaceans_icon,
  lupin_icon,
  mollusc_icon,
  mustard_icon,
  sesameseeds_icon,
  sulphurdioxide_icon,
  milk_icon,
  mild_icon,
  spicy_icon,
  extraspicy_icon
} from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  // Customizable Area Start
  product_title: any;
  product_desc: any;
  product_category: any;
  product_price: any;
  discount_checked: boolean;
  product_discountValue: any;
  product_discountPrice: any;
  product_selectedImage: any;
  product_previewImage: any;
  product_stock: any;
  product_dietary: any;
  product_allergens: any;
  product_spicelevel: any;
  productsData: any;
  categoriesData: any;
  isEdit: boolean;
  isAddEdit: boolean;
  categoryId: any;
  categoryName: string;
  setTouched: boolean;
  loading: boolean;
  loadingSearch: boolean;
  errorText: string;
  searchCategory: string;
  noSearchCategories: boolean;
  product_status: boolean;
  product_Id: any;
  dietaryData: any;
  allergenData: any;
  spiceLevelData: any;
  image_check: any;
  titleCount: any;
  descCount: any;
  imageError: any;
  openAlert: boolean;
  alertSuccess: boolean;
  alertMessage: any;
  openSubmit: boolean;
  InputValue: any;
  inputValueVariant: any;
  productVariants: any;
  searchProductVal: any;
  productSearchData: any;
  deleteVariantId: any;
  productStatus: any;
  foodLablesData: any;
  selectedFoodLabels: any;
  appliedFoodLables: any;
  isFoodLabelOpen: any;
  isDialogOpen: any;
  pagination: any;
  packageHeight:number | string;
  packageBreadth:number | string;
  packageWidth:number | string;
  packageWeight:number | string;
  expanded: string | boolean;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export type FormikItemValues = {
  product_title: string;
  product_desc: string;
  product_price: number;
  product_category: string[];
  product_discountValue: number | string;
  product_stock: boolean;
  product_image: string;
  packageHeight: number | string;
  packageWidth: number | string;
  packageBreadth: number | string;
  packageWeight: number | string;
}

export interface ItemMenuFilter {
  name: string
  value: string
  id: number
  img: string
}
// Customizable Area End

export default class InventoryManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  formikRef: React.RefObject<FormikProps<FormikItemValues>> = React.createRef();
  getOrderNotificationId: string = ""
  getCategoriesApiCallId = "";
  addCategoryApiCallId = "";
  addProductApiCallId = "";
  editProductApiCallId = "";
  postMenuItemsCallId = "";
  searchProductApiCallId = "";
  searchSuggestiveProductApiCallId = "";
  deleteProductVariantApiCallId = "";
  getCurrencyTypeApiCallId = "";
  currencyType: any
  unblock: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.PostEntityMessage)
      // Customizable Area End
    ];

    this.setCurrencyType();
    this.state = {
      txtInputValue: "",
      txtSavedValue: "",
      enableField: false,
      // Customizable Area Start
      product_title: "",
      product_desc: "",
      product_category: [],
      product_price: "",
      discount_checked: false,
      product_discountValue: "",
      product_discountPrice: 0,
      product_selectedImage: null,
      product_previewImage: null,
      product_stock: "",
      product_dietary: [],
      product_allergens: [],
      productVariants: [],
      product_spicelevel: "",
      product_Id: "",
      imageError: "Image is required!",
      openAlert: false,
      alertSuccess: false,
      alertMessage: "",
      openSubmit: false,
      InputValue: "",
      inputValueVariant: "",
      searchProductVal: "",
      productsData: [],
      categoriesData: [],
      productSearchData: [],
      isEdit: false,
      isAddEdit: false,
      categoryId: 0,
      categoryName: "",
      setTouched: false,
      loading: false,
      loadingSearch: false,
      errorText: "",
      searchCategory: "",
      noSearchCategories: false,
      product_status: false,
      image_check: false,
      titleCount: 0,
      descCount: 0,
      deleteVariantId: "",
      productStatus: false,
      dietaryData: [
        { id: 1, img: veg_icon, name: "Vegetarian", value: "Vegetarian", color: "#DDFFCF" },
        { id: 2, img: nonVeg_icon, name: "Non-Vegetarian", value: "Non-Vegetarian", color: "#FFA6A6" },
        { id: 3, img: vegan_icon, name: "Vegan", value: "Vegan", color: "#C4E1BD" },
        { id: 4, img: glutenfree_icon, name: "Gluten free", value: "Gluten free", color: "#EFE1B0" },
        { id: 5, img: halal_icon, name: "Halal", value: "Halal", color: "#D4EDCA" },
        { id: 6, img: kosher_icon, name: "Kosher", value: "Kosher", color: "#CAE2FF" },
      ],
      allergenData: [
        { id: 1, img: milk_icon, name: "Milk", value: "Milk", color: "#F2F2F2" },
        { id: 2, img: eggs_icon, name: "Eggs", value: "Eggs", color: "#FFFDD6" },
        { id: 3, img: nuts_icon, name: "Nuts", value: "Nuts", color: "#E1C9C1" },
        { id: 4, img: wheat_icon, name: "Wheat", value: "Wheat", color: "#E4D7A6" },
        { id: 5, img: soy_icon, name: "Soy", value: "Soy", color: "#FCEDAA" },
        { id: 6, img: peanuts_icon, name: "Peanuts", value: "Peanuts", color: "#F8D494" },
        { id: 7, img: celery_icon, name: "Celery", value: "Celery", color: "#9ECF9B" },
        { id: 8, img: fish_icon, name: "Fish", value: "Fish", color: "#BAD7F1" },
        { id: 9, img: crustaceans_icon, name: "Crustaceans", value: "Crustaceans", color: "#D2EAFF" },
        { id: 10, img: lupin_icon, name: "Lupin", value: "Lupin", color: "#E7D6F4" },
        { id: 11, img: mollusc_icon, name: "Mollusc", value: "Mollusc", color: "#D2EAFF" },
        { id: 12, img: mustard_icon, name: "Mustard", value: "Mustard", color: "#FFF5CA" },
        { id: 13, img: sesameseeds_icon, name: "Sesame Seeds", value: "Sesame Seeds", color: "#F5E5C1" },
        { id: 14, img: sulphurdioxide_icon, name: "Sulphur Dioxide", value: "Sulphur Dioxide", color: "#E8F8FF" },
      ],
      spiceLevelData: [
        { id: 1, img: mild_icon, name: "Mild", value: "Mild" },
        { id: 2, img: spicy_icon, name: "Spicy", value: "Spicy" },
        { id: 3, img: extraspicy_icon, name: "Extra Hot", value: "Extra Hot" },
      ],
      foodLablesData: [],
      selectedFoodLabels: [],
      appliedFoodLables: [],
      isFoodLabelOpen: true,
      expanded: "" || false,
      isDialogOpen: false,
      pagination: {},
      packageBreadth:10,
      packageHeight:10,
      packageWidth:10,
      packageWeight:0.5,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
    // Customizable Area Start

  }

  // Customizable Area Start
  /* istanbul ignore next */
  async componentDidMount() {
    this.searchProduct();
    this.getCategories();
    this.getCurrencyType();
    this.getOrderNotification()
    this.handleBackBrowser()
    window.addEventListener('popstate', () => this.pressBackEditAddItem());
    this.setState({
      foodLablesData: [{ id: 1, name: "Dietary", children: [...this.state.dietaryData] },
      { id: 2, name: "Allergens", children: [...this.state.allergenData] },
      { id: 3, name: "Spice Level", children: [...this.state.spiceLevelData] }
      ]
    })
  }
  // Customizable Area End


  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleFlashMessageFromLibrary(message)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson?.data && !responseJson.errors && !responseJson.error) {
        this.apiRequestCall(apiRequestCallId, responseJson);
      } else if (apiRequestCallId === this.searchProductApiCallId && responseJson.message == "Products Not Found") {
        this.setState({ loadingSearch: false, noSearchCategories: true })
      } else if (apiRequestCallId === this.getCurrencyTypeApiCallId) {
        if (responseJson.currency_symbol) {
          setStorageData("currency_type", responseJson.currency_symbol);
        } else {
          setStorageData("currency_type", "$");
        }
      } else if (apiRequestCallId == this.getOrderNotificationId) {
         emitter.emit("changeNotiNumber", responseJson.new_order_count)
      }
      else if (responseJson?.errors) {
        this.setState({ loading: false, loadingSearch: false });
        this.parseApiErrorResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End

  }

  // Customizable Area Start
  getOrderNotification = async () => {
    const token = await getStorageData("admintoken");
    const header = {
      token,
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getOrderNotificationId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_order_management/orders/new_order_count"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleBackBrowser() {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, "", window.location.href);
    };
  }

  componentWillUnmount(): any {
    window.removeEventListener('popstate', () => this.pressBackEditAddItem());
  }

  getCurrencyType = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCurrencyTypeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_store_details/fetch_currency_symbol"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setCurrencyType = async () => {
    this.currencyType = await getStorageData("currency_type") || "$"
  }

  /* istanbul ignore next */
  pressBackEditAddItem = () => {
    if (this.state.isAddEdit) {
      this.setState({
        isAddEdit: false,
        openSubmit: false,
        searchProductVal: "",
        noSearchCategories: false,
      });
    } else {
      window.onpopstate = null;
    }
  }

  apiRequestCall = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getCategoriesApiCallId) {
      const categories = responseJson.data.map((cat: any) => {
        return { id: cat.attributes.id, name: cat.attributes.name }
      })
      this.setState({ categoriesData: categories, loading: false });
    } else if (apiRequestCallId === this.addCategoryApiCallId) {
      const category = { id: responseJson.data.attributes.id, name: responseJson.data.attributes.name }
      this.setState({
        product_category: [...this.state.product_category, category], loading: false
      });
      this.getCategories();
    } else if (apiRequestCallId === this.addProductApiCallId) {
      this.addProductApiCall(responseJson);
    } else if (apiRequestCallId === this.editProductApiCallId) {
      this.editProductApiCall(responseJson);
    } else if (apiRequestCallId === this.searchProductApiCallId) {
      this.setState({ loadingSearch: false, noSearchCategories: false, productsData: responseJson.data, pagination: responseJson?.metadata?.meta?.pagination });
    } else if (apiRequestCallId === this.searchSuggestiveProductApiCallId) {
      if (responseJson.data.length > 0 || responseJson.categories.length > 0) {
        const product = responseJson?.data?.map((val: any) => {
          return val.attributes
        })
        this.setState({ loadingSearch: false, productSearchData: [...product, ...responseJson?.categories] });
      } else {
        this.setState({ loadingSearch: false, productSearchData: [] });
      }
    }
  }
  editProductApiCall = (responseJson: any) => {
    if (responseJson.data.type == 'error' && responseJson.data.attributes.errors.name[0] == "This product has already been taken.") {
      this.setState({ loading: false, setTouched: true, errorText: "This product title has already been taken. Please enter different title" })
    } else {
      this.setState({ loading: false, noSearchCategories: false, isAddEdit: false, setTouched: false, openAlert: true, alertSuccess: true });
      if (this.state.productStatus) {
        if (responseJson?.data?.attributes?.status) {
          this.setState({ alertMessage: "The item is visible on the website now." });
        } else {
          this.setState({ alertMessage: "The item is hidden from the website now." });
        }
      } else {
        this.setState({ alertMessage: "Item edited successfully." });
      }
      this.setInitialState();
      this.searchProduct();
    }
  }
  addProductApiCall = (responseJson: any) => {
    if (responseJson?.data?.type == 'error' && responseJson?.data?.attributes?.errors?.name[0] == "This product has already been taken.") {
      this.setState({ loading: false, setTouched: true, errorText: "This product title has already been taken. Please enter different title" })
    } else {
      this.setInitialState();
      this.searchProduct();
      this.setState({ loading: false, isAddEdit: false, noSearchCategories: false, setTouched: false, openAlert: true, alertSuccess: true, alertMessage: "Item added successfully." });
    }
  }
  discardBtn = (even: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ isAddEdit: false, openSubmit: false, productSearchData: [], searchProductVal: "" })
    this.searchProduct();
  }

  setInitialState = () => {
    this.setState({
      product_Id: "",
      product_title: "",
      product_desc: "",
      product_category: [],
      product_previewImage: null,
      product_selectedImage: null,
      product_price: "",
      discount_checked: false,
      product_discountPrice: 0,
      product_discountValue: "",
      product_dietary: [],
      product_allergens: [],
      product_spicelevel: "",
      product_status: false,
      product_stock: "",
      titleCount: 0,
      descCount: 0,
      image_check: false,
      searchProductVal: "",
      noSearchCategories: false,
      productVariants: [],
      productStatus: false,
      productSearchData: [],
      packageBreadth:10,
      packageHeight:10,
      packageWidth:10,
      packageWeight:0.5,
    });
  };

  editProductDetails = (values: any, Id: any) => {
    const category: any = [];
    values.categories[0].map((val: any) => {
      category.push(this.state.categoriesData.find((cat: any) => cat.id === val[1]))
    });
    const dietary: any = [];
    const allergen: any = [];
    this.state.allergenData.map((item: any) => {
      if (values.food_lables.includes(item.value)) {
        allergen.push(item.name);
      }
    });
    this.state.dietaryData.map((item: any) => {
      if (values.food_lables.includes(item.value)) {
        dietary.push(item.name);
      }
    });
    const spiceLevel: any = [];
    this.state.spiceLevelData.map((item: any) => {
      if (values.food_lables.includes(item.value)) {
        spiceLevel.push(item.name);
      }
    });
    this.imagePreview(values.images.url);

    this.setState({
      product_Id: Id,
      isEdit: true,
      isAddEdit: true,
      product_title: values.name,
      product_desc: values.description,
      product_category: category,
      product_price: values.price,
      discount_checked: values.discount > 0 ? true : false,
      product_discountValue: values.discount ?? 0,
      product_discountPrice: values.after_discount,
      product_status: values.status,
      product_stock: values.availability,
      product_dietary: dietary,
      product_allergens: allergen,
      product_spicelevel: spiceLevel.length > 0 ? spiceLevel[0] : "",
      titleCount: values.name?.length,
      descCount: values.description?.length,
      productVariants: values.variants,
      packageHeight:values.height,
      packageBreadth:values.breadth,
      packageWidth:values.length,
      packageWeight:values.weight,
      imageError: "",
      openSubmit: false,
    });
  };
  addProduct = async () => {
    this.setState({ loading: true });
    let formData = new FormData();
    formData.append("name", this.state.product_title.trim());
    formData.append("description", this.state.product_desc);
    formData.append("price", this.state.product_price);
    formData.append("discount", this.state.product_discountValue);
    formData.append("image", this.state.product_selectedImage);
    formData.append("availability", this.state.product_stock);
    formData.append("status", JSON.stringify(this.state.product_status));
    formData.append("height", Number(this.state.packageHeight).toFixed(2));
    formData.append("breadth", Number(this.state.packageBreadth).toFixed(2));
    formData.append("length", Number(this.state.packageWidth).toFixed(2));
    formData.append("weight", Number(this.state.packageWeight).toFixed(2));
    formData.append(
      "category_ids[]",
      this.state.product_category
        .map((val: any) => {
          return val.id;
        })
        .join(",")
    );
    this.state.product_allergens.forEach((val: any) => {
      formData.append("lables[]", val);
    });
    this.state.product_dietary.forEach((val: any) => {
      formData.append("lables[]", val);
    });
    if (this.state.product_spicelevel) {
      formData.append("lables[]", this.state.product_spicelevel);
    }
    if (this.state.productVariants.length > 0) {
      this.state.productVariants.map((variant: any) => {
        formData.append("catalogue_variant_ids[]", variant.id);
      })
    } else {
      formData.append("catalogue_variant_ids[]", "");
    }
    const token = await getStorageData("admintoken");

    const header = {
      "Content-Type": undefined,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addProductApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProductsAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  editStatusProduct = async (Categories: any, status: any, productId: any) => {
    this.setState({ loading: true });
    const category: any = [];
    Categories.map((val: any) => {
      category.push(this.state.categoriesData.find((cat: any) => cat.id === val[1]))
    });
    let formData = new FormData();
    formData.append("status", JSON.stringify(status));
    formData.append(
      "category_ids[]",
      category
        .map((val: any) => {
          return val.id;
        })
        .join(",")
    );
    const token = await getStorageData("admintoken");

    const header = {
      "Content-Type": undefined,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editProductApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProductsAPIEndPoint + "/" + productId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  editProduct = async () => {
    this.setState({ loading: true });
    let formData = new FormData();
    formData.append("name", this.state.product_title.trim());
    formData.append("description", this.state.product_desc);
    formData.append(
      "category_ids[]",
      this.state.product_category
        .map((val: any) => {
          return val.id;
        })
        .join(",")
    );
    formData.append("price", this.state.product_price);
    formData.append("image", this.state.product_selectedImage);
    formData.append("discount", this.state.product_discountValue);
    if (this.state.product_allergens.length > 0 || this.state.product_dietary.length > 0 || this.state.product_spicelevel != "") {
      this.state.product_allergens.forEach((val: any) => {
        formData.append("lables[]", val);
      });
      this.state.product_dietary.forEach((val: any) => {
        formData.append("lables[]", val);
      });
      if (this.state.product_spicelevel) {
        formData.append("lables[]", this.state.product_spicelevel);
      }
    } else {
      formData.append("lables[]", "");
    }
    if (this.state.productVariants.length > 0) {
      this.state.productVariants.map((variant: any) => {
        formData.append("catalogue_variant_ids[]", variant.id);
      })
    } else {
      formData.append("catalogue_variant_ids[]", "");
    }

    formData.append("availability", this.state.product_stock);
    formData.append("status", JSON.stringify(this.state.product_status));
    formData.append("height", Number(this.state.packageHeight).toFixed(2));
    formData.append("breadth", Number(this.state.packageBreadth).toFixed(2));
    formData.append("length", Number(this.state.packageWidth).toFixed(2));
    formData.append("weight", Number(this.state.packageWeight).toFixed(2));
    const token = await getStorageData("admintoken");

    const header = {
      "Content-Type": undefined,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editProductApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProductsAPIEndPoint + "/" + this.state.product_Id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCategories = async () => {
    this.setState({ loading: true });
    const token = await getStorageData("admintoken");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoriesAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleDiscountValueChange = (event: any) => {
    const discountAfterPrice =
      this.state.product_price -
      (this.state.product_price * event.target.value) / 100;
    this.setState({
      product_discountValue: event.target.value,
      product_discountPrice: discountAfterPrice,
      openSubmit: true,
    });
  };
  handleClickSpiceLevelChange = (event: any) => {
    if (event.target.value == 0 || event.target.value == undefined) {
      this.setState({ product_spicelevel: "", openSubmit: true });
    }
  };
  handleSpiceLevelChange = (event: any) => {
    this.setState({ product_spicelevel: event.target.value, openSubmit: true });
  };

  handleAllergensChange = (event: any) => {
    this.setState({ product_allergens: event.target.value, openSubmit: true });
  };

  handleDietaryChange = (event: any) => {
    this.setState({
      product_dietary: event.target.value,
      openSubmit: true,
    });
  };

  handleImageChange = (e: any) => {
    this.setState({ openSubmit: true })
    const inputElement = e.target;
    if (inputElement.files && inputElement.files[0]) {
      const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
      if (allowedTypes.includes(inputElement.files[0].type)) {
        if (inputElement.files[0].size > (5242880)) {
          this.setState({ image_check: true, product_selectedImage: null, product_previewImage: null, imageError: "Image size can not more than 5 MB" })
        } else {
          this.setState({ product_selectedImage: inputElement.files[0], image_check: false });
          const product_selectedImage = inputElement.files[0];
          const reader = new FileReader();
          reader.onloadend = () => {
            this.setState({
              product_previewImage: reader.result,
            });
          };
          reader.readAsDataURL(product_selectedImage);
        }
      }
      else {
        this.setState({ image_check: true, product_selectedImage: null, product_previewImage: null, imageError: "Invalid file type. Please select a valid image. ex - jpg, jpeg, png etc." })
      }
    } else {
      if (this.state.product_previewImage == null) {
        this.setState({ image_check: true, imageError: "Image is required!" })
      }
    }
  };
  imagePreview = async (img: any) => {
    const response = await fetch(img);
    const blob = await response.blob();

    const file = new File([blob], `image.${blob.type.split("/")[1]}`, {
      type: blob.type,
    });
    if (file) {
      this.setState({ product_selectedImage: file });
      this.setState({
        product_previewImage: img,
      });
    }
  };

  handleParentChange = (isChecked: boolean, parent: { id: string; name: string; children: Array<ItemMenuFilter> }) => {
    let newSelected = [...this.state.selectedFoodLabels];
    if (!isChecked) {
      const arrayItemAdd: Array<string> = []
      parent.children.forEach((item: ItemMenuFilter) => {
        const indexItem = this.state.selectedFoodLabels.findIndex((label: string) => label === item.value)
        if (indexItem < 0) {
          arrayItemAdd.push(item.value)
        }
      })
      newSelected = [...newSelected, ...arrayItemAdd]
    } else {
      const arrayRemoveItem = parent.children.map((item: ItemMenuFilter) => item.value)
      newSelected = difference(newSelected, arrayRemoveItem)
    }
    this.setState({ selectedFoodLabels: newSelected });
  }

  handleOpenAccordion = (name: string) => {
    if (this.state.expanded == name) {
      this.setState({ expanded: "" })
    } else {
      this.setState({ expanded: name })
    }
  }

  handleAlertMessageClose = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openAlert: false });
  };

  searchProduct = async (page = 1) => {
    this.setState({ loadingSearch: true });
    const token = await getStorageData("admintoken");

    let apiEndPoint = configJSON.searchProductAPIEndPoint + this.state.searchProductVal + "&page=" + page + "&per_page=10";
    if (this.state.appliedFoodLables.length > 0) {
      this.state.appliedFoodLables.map((foodLabel: any) => {
        apiEndPoint += "&search_lable[]=" + foodLabel
      })
    }
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchProductApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  searchProductWithText = (val: any) => {
    if (val == "") {
      this.setState({ searchProductVal: "", noSearchCategories: false, productSearchData: [] })
      this.searchProduct();
    } else {
      this.setState({ searchProductVal: val })
      this.searchProduct();
      if (val.length > 2) {
        this.searchSuggestiveProduct();
      } else {
        this.setState({
          productSearchData: []
        })
      }
    }
  }
  deleteProductVariant = async () => {
    const token = await getStorageData("admintoken");

    const header = {
      "Content-Type": configJSON.VariantApiContentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteProductVariantApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteVariantAPIEndPoint +
      this.state.deleteVariantId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };

  createCategory = async (value: any) => {
    this.setState({ loading: true });
    const token = await getStorageData("admintoken");

    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addCategoryAPIEndPoint + value
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  searchSuggestiveProduct = async () => {
    this.setState({ loadingSearch: true });
    const token = await getStorageData("admintoken");

    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchSuggestiveProductApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchSuggestiveProductAPIEndPoint + this.state.searchProductVal
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  handleDialogOpen = () => {
    this.setState({ selectedFoodLabels: this.state.appliedFoodLables })
    this.setState({ isDialogOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ isDialogOpen: false });
  };

  setIsFoodLabelOpen = () => {
    this.setState({ isFoodLabelOpen: true });
  };

  setIsFoodLableClose = () => {
    this.setState({ isFoodLabelOpen: false });
  };

  handlePageChange = (page: any) => {
    this.searchProduct(page)
  }

  openAddItemsForm = () => {
    this.setState({
      isAddEdit: true,
      isEdit: false,
    }, () => {
      this.setInitialState();
    })
  }
  navigateToItemsLibrary = () => {
    removeStorageData(configJSON.STORED_SEACHDATA_KEY);
    const toItemLibraryMessage:Message = new Message(getName(MessageEnum.NavigationMessage));
    toItemLibraryMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "InventoryManagementLibrary"
    );
    toItemLibraryMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toItemLibraryMessage);
  }

  handleFlashMessageFromLibrary = (message:Message) => {
    if(message.id === getName(MessageEnum.PostEntityMessage))
    {
        if(message.properties.PostDetailDataMessage.success) {
            this.setState({
              openAlert:true,
              alertSuccess:true,
              alertMessage:message.properties.PostDetailDataMessage.message
            })
        } 
    }
  }

  onChangePackageHeight = (event:React.ChangeEvent<HTMLInputElement>) => {
    const height = event.target.value;
    if (this.isDecimalValid(height)) {
      this.setState({
        openSubmit: true,
        packageHeight:height,
        setTouched: false,
      })
      this.formikRef?.current?.setFieldValue(
        "packageHeight",
        height
      );
    }
  }

  onChangePackageBreadth = (event:React.ChangeEvent<HTMLInputElement>) => {
    const breadth = event.target.value;
    if (this.isDecimalValid(breadth)) {
      this.setState({
        packageBreadth:breadth,
        openSubmit: true,
        setTouched: false,
      })
      this.formikRef?.current?.setFieldValue(
        "packageBreadth",
        breadth
      );
    }
  }

  onChangePackageWidth = (event:React.ChangeEvent<HTMLInputElement>) => {
    const width = event.target.value;
    if (this.isDecimalValid(width)) {
      this.setState({
        setTouched: false,
        openSubmit: true,
        packageWidth:width,
      })
      this.formikRef?.current?.setFieldValue(
        "packageWidth",
        width
      );
    }
  }

  onChangePackageWeight = (event:React.ChangeEvent<HTMLInputElement>) => {
    const weight = event.target.value;
    if (this.isDecimalValid(weight)) {
      this.setState({
        packageWeight:weight,
        setTouched: false,
        openSubmit: true,
      })
      this.formikRef?.current?.setFieldValue(
        "packageWeight",
        weight
      );
    }
    
  }
  isDecimalValid = (value:string) => {
    const regex = /^\d*\.?\d{0,2}$/; 
    if (regex.test(value) || value === '') {
      return true;
    }

    return false;
  }
  getItemFieldErrorData = (touchedData:boolean | undefined, errorData:string | undefined) => {
    const {setTouched, errorText} = this.state;
    return {
      hasError:Boolean(touchedData && errorData) || setTouched,
      errorText:setTouched ? errorText : touchedData && errorData
    }
  }
  getItemFieldValidations = (touched:FormikTouched<FormikItemValues>, errors:FormikErrors<FormikItemValues>) => {
    const heightData = this.getItemFieldErrorData(touched.packageHeight, errors.packageHeight);
    const breadthData = this.getItemFieldErrorData(touched.packageBreadth, errors.packageBreadth);
    const widthData = this.getItemFieldErrorData(touched.packageWidth, errors.packageWidth);
    const weightData = this.getItemFieldErrorData(touched.packageWeight, errors.packageWeight);
    return {
      packageHeightError:heightData.hasError,
      packageHeightErrorText:heightData.errorText,
      packageBreadthError:breadthData.hasError,
      packageBreadthErrorText:breadthData.errorText,
      packageWidthError:widthData.hasError,
      packageWidthErrorText:widthData.errorText,
      packageWeightError:weightData.hasError,
      packageWeightErrorText:weightData.errorText,
    }
  }
  // Customizable Area End
}