Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.apiContentType = 'application/json'
exports.getApiMethod = 'GET'
exports.postApiMethod = 'POST'
exports.deleteApiMethod = 'DELETE'
exports.keyGoogle = 'AIzaSyAAdnoL9ZLaHUAZQ0z1QDjgQWXJCkFlCuE'

exports.designApiEndPoint = `bx_block_websitebuilder/designs`
exports.getOrdersApiEndPoint = 'shopping_cart/orders'
exports.createOrderItemApiEndPoint = 'shopping_cart/order_items'
exports.deleteOrderItemApiEndPoint = 'shopping_cart/orders'
exports.getCurrency = 'bx_block_store_details/get_country_and_currency_symbol'
exports.promocodeValidate = 'bx_block_promo_code/promo_codes/validate'
exports.itemsSearch = 'bx_block_catalogue/catalogues/search_product?search='
exports.placeOrder = "bx_block_shopping_cart/orders"
exports.paymentTypes = "bx_block_payment_admin/payment_option_methods/payment_option_list"
exports.getTaxes = "bx_block_store_details/public/store_profiles"
exports.getTapPaymentStatus = "bx_block_tappaymentsintegration/tappayment_checkout/webhook"
exports.areaSearch = "bx_block_location_management/areas"

exports.houseTitle = "House / Flat / Floor #"
exports.buildingTitle = "Apartment / Street / Building"
exports.landmarkTitle = "Landmark (Optional)"
exports.thisFieldNotEmpty = `This field can't be empty`;
exports.addAddress = '+ Add Delivery address'
exports.addArea = "Area/Zone*"
exports.confirmAddress = 'Confirm Address'
exports.enterAddress = "Enter Address manually"
exports.errorTitle = 'Error'
exports.cartEmpty='Your Cart is Empty!'
exports.noDataAddedToCart="You have not added any item to your cart."
exports.backToMenu="Back to Menu"
exports.home="Home"
exports.cart='Cart'
exports.contactNumber='Contact number*'
exports.name='Name*'
exports.namePlaceholder='Your Name'
exports.emailIdLabel='Email Address*'
exports.emailIdPlaceholder='Enter Your Email'
exports.emailNotification="I want to receive updates about products and promotions."

exports.size="Size"
exports.toppings='Toppings'
exports.notAvailable="Not Available"
exports.summary='Summary'
exports.subtotal='Subtotal'
exports.gst='GST'
exports.delivery='Delivery'
exports.free='N/A'
exports.total='Total'
exports.merchantNote="Message from Restaurant"
exports.secureMsg='Secure checkout with SSL data encryption'
exports.checkoutBtn='Checkout securely'
exports.promocodeApplied="Promo Code Applied"
exports.promocodeValidateMsg='Enter a Valid Promo Code'
exports.applied="Applied"
exports.dummyPromocode="NEWYEAR2024"
exports.apply='Apply'
exports.collection='Collection'
exports.proceed='Proceed'
exports.collectionString="collection"
exports.orderType='Order type:'
exports.collectionAddress="Collection Address"
exports.yourPosition = "Your location"
exports.dummyAdress='1901 Thornridge Cir. Shiloh, Hawaii 81063'
exports.orderTypeWarningMsg='Choose at least 1 order type before checkout.'
exports.chooseAddress='Choose your address for delivery'
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.';
exports.usePosition = "Use current location"
exports.resName = {
  fName: "Restaurant",
  lName: "Name"
};
exports.thankYouText = "Thank you for your order";
exports.orderSuccess = "Your order was successfully placed.";
exports.orderDate = "Order date :"
exports.status = "Status";
exports.orderNumber = "Order number";
exports.noteFromMerchant = "Message from Restaurant";
exports.note = "Due to the heavy rain, there may be delays in the delivery of your orders. We apologise for any inconvenience."
exports.deliverTo = "Deliver to";
exports.deliverAddress = "Delivery Address";
exports.deliveryAddressDetails = {
  country: "United States",
  city: "San Francisco",
  street: "490 Post St, Suite 900, San Francisco, CA",
  pincode: "94762"
}
exports.continueButton = "Continue Shopping";
exports.backTOMenuButton = "Back to Menu";
exports.placeOrderBtn="Place Order";
exports.continueToPayment="Continue to Payment";
exports.paymentType="Payment type";
exports.online="Online"
exports.cashOnDelivery="Cash on Delivery";
exports.cod="cod";
exports.onlineType="online";
exports.itemUpdateMsg=" updated."
exports.itemFailMsg="Uh oh! It looks like your recent payment didn’t work. Please retry after sometime.."
exports.apiCallEndpointOrderSummary = "bx_block_shopping_cart/orders/";
exports.apiGetMethod = "GET"

exports.themeColor = "#6200EE";
exports.projectNameText = 'Pay your order'
exports.verifySignature = 'bx_block_razorpay/razorpay/verify_payment_signature'
exports.verifySignature = 'bx_block_razorpay/razorpay/verify_payment_signature';
exports.storeCloseMsgTitle="We are currently closed for online orders";
exports.fieldRequired="This field can’t be empty";
exports.validContactMsg='Please enter a valid number';
exports.validEmailIdMsg="Please enter a valid email"
 exports.mobileRegex=[
    {
       country:'in' ,
       regex:/^\+91[0123456789]{10}$/
    },
    {
      country:'gb' ,
        regex:/^((\+44)|(0)) ?\d{4} ?\d{6}$/
       
    },
    { 
      country:'us' ,
            regex:/^(?:\+?1[-.●]?)?\(?([0123456789]{3})\)?[-.●]?([0123456789]{3})[-.●]?([0123456789]{4})$/
        
    },{
      country:'ae' ,
        regex:/\+971([0123456789]{9}$)/  
    }
 ]
 exports.personalDetails='Personal Details'
 exports.name='Name*'
 exports.contactNumber='Contact number*'
 exports.emailAddress='E-mail Address*'
 exports.promotionEnableText="I want to receive updates about products and promotions."
 exports.emailAddressPlaceHolder="Enter e-mail address"
 exports.contactNumberPlaceHolder="Enter contact number"
 exports.namePlaceHolder="Enter name"
 exports.emailRegex="^[a-zA-Z0-9.!\\#$%&‘*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
exports.getCartItems='bx_block_catalogue/catalogues/catalogues_list_by_ids?ids='
 // Customizable Area End
