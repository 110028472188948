//@ts-nocheck
import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    Icon,
    Button,
} from "@material-ui/core";

// Customizable Area End

// Customizable Area Start
import { egg, sandwich, wheat, carrotIcon } from './assets';

// Customizable Area End

import MenuItemDetailsPageController from "./MenuItemDetailsPageController.web";
export default class MenuItemDetailsPage extends MenuItemDetailsPageController {


    // Customizable Area Start
    renderCard() {
        return (
            <div style={menuItemDetailsStyle.itemDetailsContainer} data-test-id="itemDetailsContainer">
                <div style={menuItemDetailsStyle.itemImage}>
                    <img src={this.state.categoriesItemDetails.attributes?.images?.url} style={menuItemDetailsStyle.itemImageStyles} alt="Card" />
                </div>
                <div style={menuItemDetailsStyle.itemDetails}>

                    <Typography gutterBottom variant="h5" component="h5" style={menuItemDetailsStyle.titleStyles}>
                        {this.state.categoriesItemDetails.attributes?.name}
                    </Typography>

                    <Typography variant="body2" color="textSecondary" component="p" style={menuItemDetailsStyle.paraStyles}>
                        {this.state.categoriesItemDetails.attributes?.description}
                    </Typography>

                    <Box style={menuItemDetailsStyle.menuIcon}>
                        <button style={{ borderRadius: '20px', border: 'none', background: '#DDFFCF', marginRight: '10px', padding: '7px 10px 7px 10px', display: 'flex', justifyContent: 'space-around' }}>
                            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Icon>
                                    <img src={carrotIcon} style={{ backgroundColor: '#DDFFCF' }} />
                                </Icon>
                                <Typography>vegetarian</Typography>
                            </Box>
                        </button>
                        <button style={{ borderRadius: '20px', border: 'none', background: '#E4D7A6', marginRight: '10px', padding: '7px 10px 7px 10px' }}>
                            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Icon ><img src={wheat} alt="Brown Icon" /></Icon>
                                <Typography>wheat</Typography>
                            </Box>
                        </button>
                        <button style={{ borderRadius: '20px', border: 'none', background: '#FFFDD6', marginRight: '10px', padding: '7px 10px 7px 10px' }}>
                            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>

                                <Icon><img src={egg} alt="Veg Icon" /></Icon>
                                <Typography>Egg</Typography>
                            </Box>
                        </button>
                        <button style={{ borderRadius: '20px', border: 'none', background: '#FCEDAA', marginRight: '20px' }}>

                            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Icon ><img src={sandwich} alt="Brown Icon" /></Icon>
                                <Typography>soy</Typography>
                            </Box>
                        </button>
                    </Box>
                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1rem' }}>
                        
                        <Typography style={{ fontWeight: 'bold' }}>${this.state.categoriesItemDetails.attributes?.after_discount}  <del style={{ color: 'grey' }}>${this.state.categoriesItemDetails.attributes?.price}</del></Typography>
                        <button style={menuItemDetailsStyle.saveBtn}><i>save{this.state.categoriesItemDetails.attributes?.discount} %</i></button>
                    </Box>
                    <Box style={{ marginTop: '2rem' }}>
                        <Button style={{ height: '50px', width: '65%', color: 'white', background: 'linear-gradient(45deg, #C399FF , #6200EA)' }}>
                            Place Order
                        </Button>
                    </Box>
                </div>
            </div>
        )
    }

    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <div data-test-id="menuItemDetails">
                {this.renderCard()}
            </div>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
const menuItemDetailsStyle = {
    itemDetailsContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '3rem',
        alignItem: 'center'
    },
    itemDetails: {
        width: '40%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: '50px'
    },
    itemImage: {
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center'
    },
    itemImageStyles: {
        width: '350px',
    },
    titleStyles: {
        fontWeight: 'bold',
        fontSize: '36px',
        lineHeight: '70px'
    },
    paraStyles: {
        color: 'grey',
        fontSize: '1rem',
    },
    saveBtn: {
        borderRadius: '15px',
        padding: '6px',
        gap: '10px',
        background: '#F6F0FF',
        color: '#5500CC',
        fontSize: '14px',
        borderStyle: 'none',
        fontWeight: 'bold'
    },
    menuIcon: {
        display: 'flex',
        justifyContent: 'start',
        padding: '10px 10px 10px 0px',
        marginTop: '1rem'
    },
    '@media (max-width: 780px)': {
        itemDetailsContainer: {
            flexDirection: 'column',
            alignItems: 'center',
        },
        itemImageStyles: {
            width: '100%',
            marginBottom: '20px',
        },
        itemDetails: {
            width: '80%',
            textAlign: 'center',
            marginLeft: '30px'
        },
    },
};
// Customizable Area End
