import React from "react";
import { TextField } from "@material-ui/core";

type Props = {
  onTextChange: (value: string) => void;
  value: string;
  error: boolean;
  helperText: string;
};

export class SearchChange extends React.Component<Props> {
  render() {
    return (
      <TextField
        name="imageName"
        size="small"
        variant="outlined"
        fullWidth
        onChange={(event) => {
          this.props.onTextChange?.(event.target.value || "");
        }}
        value={this.props.value}
        id="standard-size-small"
        style={{
          minHeight: 68,
        }}
        error={this.props.error}
        helperText={this.props.helperText}
      />
    );
  }
}

export default SearchChange;
