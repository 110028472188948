// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  getStorageData,
  removeStorageData,
  setStorageData,
} from "framework/src/Utilities";
import { IBlock } from "../../../framework/src/IBlock";
export const configJSON = require("./config");
import {createFilterOptions} from "@material-ui/lab/Autocomplete"; 
import { FilterOptionsState } from "@material-ui/lab";

export interface ICategories {
  categoryList: string[];
  searchedText: string;
  selectedCategory: string;
}

export interface ILibraryItem {
  id: number;
  created_at: string;
  name: string;
  description: string;
  price: string;
  old_price: string | null;
  on_sale: string;
  image_url: string;
  store_name: string;
  categories: string[];
  checked: boolean;
  selected_price: number | string;
  updatedCategories: ICategories;
  updatedCategory: ICategories;
}

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  category: string;
  categories: ILibraryItem[];
  loading: boolean;
  loadingSearch: boolean;
  allChecked: boolean;
  noSearchCategories: boolean;
  searchCategory: string;
  searchPerformed: boolean;
  inputValue: string;
  selectedCategory: string;
  isDataEdited: boolean;
  isNameEmpty: boolean;
  isNextButtonDisabled: boolean;
  currentPasswordValue: string;
  showCurrentPasswordValidation: boolean;
  currentPasswordErrorTxt: string;

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ItemsLibraryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSearchedMenuCategoriesApiId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      category: "",
      loading: false,
      loadingSearch: false,
      allChecked: false,
      noSearchCategories: false,
      searchCategory: "",
      searchPerformed: false,
      categories: [],
      inputValue: "",
      selectedCategory: "",
      isDataEdited: false,
      isNameEmpty: false,
      isNextButtonDisabled: true,
      currentPasswordValue: "",
      showCurrentPasswordValidation: false,
      currentPasswordErrorTxt: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getSearchedMenuCategoriesApiId) {
      if (responseJson?.data && responseJson?.data?.length > 0) {
        const filteredCategories: ILibraryItem[] = responseJson.data.map(
          (item: ILibraryItem) => {
            return {
              ...item,
              checked: false,
              selected_price:'',
              updatedCategories: {
                categoryList: item.categories,
                searchedText: item.categories[0],
                selectedCategory: item.categories[0],
              },
            };
          }
        );

        this.setState(
          {
            categories: filteredCategories,
            searchPerformed: true,
            noSearchCategories: false,
            loadingSearch:false
          },
          () => {
            this.updateCheckboxWithButton();
          }
        );
      } else {
        this.setState({ noSearchCategories: true, searchPerformed: true });
      }
    }
    this.setState({
      loadingSearch:false
    })
  }

  async componentDidMount() {
    super.componentDidMount();
    const searchedCategoryItems = await getStorageData(
      configJSON.STORED_SEACHDATA_KEY,
      true
    );
    if (searchedCategoryItems?.length > 0) {
      this.setState(
        {
          searchPerformed: true,
          noSearchCategories: false,
          categories: searchedCategoryItems,
        },
        () => {
          this.updateCheckboxWithButton();
        }
      );
    }
  }
  updateCheckboxWithButton = () => {
    const { categories } = this.state;
    this.setState({
      isNextButtonDisabled: !this.isNexButtonEnabled(categories),
    });
  };

  filter = createFilterOptions<string>();
  getFilterItems = (options: string[], params:FilterOptionsState<string>) => {
    const filtered = this.filter(options, params);

    if (params.inputValue !== "") {
      if(!options.includes(params.inputValue)){
        filtered.push(params.inputValue);
      }
    }

    return filtered;
  }

  handleNextButtonWithInput = () => {
    const { categories } = this.state;
    setStorageData(configJSON.STORED_SEACHDATA_KEY, JSON.stringify(categories));
    const goToReviewItems: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    goToReviewItems.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "InventoryManagementLibraryReview"
    );
    goToReviewItems.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(goToReviewItems);
  };

  handleChangeSearchInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState({ searchCategory: e.target.value });
  };

  handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string | number
  ) => {
    const updatedCategories = this.state.categories.map(
      (category: ILibraryItem) =>
        category.id === id
          ? { ...category, checked: event.target.checked }
          : category
    );
    const allChecked = updatedCategories.every(
      (category: { checked: boolean }) => category.checked
    );
    this.setState({ categories: updatedCategories, allChecked });
    this.setState({ categories: updatedCategories }, () => {
      this.updateCheckboxWithButton();
    });
  };

  isNexButtonEnabled(categories: ILibraryItem[]): boolean {
    const isAtLeastOneCheckedItemWithEmptyName = categories.some(
      (item) => item.checked && item.name.trim() === ""
      );
    const isAtLeastOneCheckedItemWithEmptyDiscription = categories.some(
      (item) => item.checked && (item.description === null || item.description?.trim() === "")
      );
      
    const isAtLeastOneCheckedItemWithEmptyPrice = categories.some(
      (item) => item.checked && this.checkPriceValidation(item)
      );
          

    return (
      categories.some((item) => {
        return item.checked && !isAtLeastOneCheckedItemWithEmptyName && !isAtLeastOneCheckedItemWithEmptyDiscription && !isAtLeastOneCheckedItemWithEmptyPrice 
      }) 
    );
  }

  handleSelectAllCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    const updatedCategories = this.state.categories.map(
      (category: ILibraryItem) => ({
        ...category,
        checked: isChecked,
      })
    );
    this.setState({ categories: updatedCategories, allChecked: isChecked });
    this.setState({ categories: updatedCategories }, () => {
      this.updateCheckboxWithButton();
    });
  };

  handleSearchItemsKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      this.getSearchedMenuCategoriesList(this.state.searchCategory);
    }
  }

  navigateToInventoryManagement = () => {
    const toinventoryManagmentMessage: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toinventoryManagmentMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "InventoryManagement"
    );
    toinventoryManagmentMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toinventoryManagmentMessage);
  };

  backFromSearchedCategories = async () => {
    await removeStorageData(configJSON.STORED_SEACHDATA_KEY);
    this.setState({
      categories: [],
      searchPerformed: false,
      noSearchCategories: false,
      searchCategory: "",
    });
  };

  handlePriceInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
    const { value } = e.target;  
    const numericValue = value.replace(/[^\d.]/g, '');
    const syntheticEvent = {
      target: {
        value: numericValue
      }
    };  
    this.handleUpdateCategoriesData(syntheticEvent as React.ChangeEvent<HTMLInputElement>, id, "selected_price");
  };
  
  handleUpdateCategoriesData = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: number,
    param: string
  ) => {
    let categoryList = [...this.state.categories];
    const val = e.target.value;

    categoryList = categoryList.map((item: ILibraryItem) => {
      if (item.id === id) {
        return {
          ...item,
          [param]: val,
        };
      }
      return item;
    });
    this.setState({ categories: categoryList }, () => {
      this.updateCheckboxWithButton();
    });
  };

  handleUpdateCategoriesSelectedData = (
    val: string | null,
    id: number,
    param: string,
  ) => {
    if(typeof val === 'string'){

      if(param === 'selectedCategory'){
        this.onChangeSelectCategory(val, id, param)
    }else if(param === 'searchedText'){
      this.onChangeSelectCategoryInput(val, id, param)
    }
    }
    
  };
  
  onChangeSelectCategory = (val:string, id:number, param:string) => {
    let categoryListData = [...this.state.categories];
        categoryListData = categoryListData.map((item: ILibraryItem) => {
          if (item.id === id) {
            if(item.updatedCategories.categoryList.includes(val)){
              return {
                ...item,
                updatedCategories: {
                  ...item.updatedCategories,
                  [param]: val,
                },
              };
            }else{
              let newUpdatedCat = [...item.updatedCategories.categoryList, val];
              return {
                ...item,
                updatedCategories: {
                  ...item.updatedCategories,
                  [param]: val,
                  categoryList:newUpdatedCat
                },
              };
            }
          }
          return item;
        });
        this.setState({ categories: categoryListData });
  }

  onChangeSelectCategoryInput = (val:string, id:number, param:string) => {
    let categoryListData = [...this.state.categories];
      categoryListData = categoryListData.map((item: ILibraryItem) => {
        if (item.id === id) {
          return {
            ...item,
            updatedCategories: {
              ...item.updatedCategories,
              [param]: val,
            },
            categories: val ? [...item.categories, val] : item.categories,
          };
        }
        return item;
      });
      this.setState({ categories: categoryListData });
  }
  
  getSearchedMenuCategoriesList = async (searchText: string) => {
    this.setState({
      loadingSearch:true
    })
    const headers = {
      token: await getStorageData("admintoken"),
      "Content-Type": configJSON.validationApiContentType,
    };

    const searchedMenuCategoriesMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSearchedMenuCategoriesApiId = searchedMenuCategoriesMsg.messageId;

    searchedMenuCategoriesMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSearchedMenuCategories + searchText
    );

    searchedMenuCategoriesMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    searchedMenuCategoriesMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(
      searchedMenuCategoriesMsg.id,
      searchedMenuCategoriesMsg
    );
  };

  checkPriceValidation =(category:ILibraryItem)=>{
    if(typeof category.selected_price === 'string'){
      return Boolean(category.checked && category.selected_price.trim() === "")
    }else if(typeof category.selected_price === 'number'){
      return Boolean(category.checked && category.selected_price >=0)
    }

  }
  // Customizable Area End
}
