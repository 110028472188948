// Customizable Area Start
import * as React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/DialogContext";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { withToastProps } from "../../ss-cms-common-components/src/HOC/withSnackBar.Web";
import { checkResponseError } from "../../ss-cms-common-components/src/ErrorHandler/ErrorHandler";
export const configJSON = require("./config.js");
import { getResponseVariables } from "../../ss-cms-common-components/src/GetResponseVariables/GetResponseVariables";
import {generateRequestMessage, showError, isTokenExpired, clearStorageData} from "../../ss-cms-common-components/src/Utilities/Utilities";
import {Scrollbars} from "react-custom-scrollbars";
import { emitter } from "../../../../packages/blocks/ss-cms-common-components/src/Layout/Layout";
import { getStorageData } from "../../../framework/src/Utilities";

export type Props = RouterProps &
    DialogProps &
    withHeadeActionBarProps &
    withLoaderProps &
    withToastProps & {
        navigation: {
            navigate: (to: string, params: object) => void;
            getParam: (param: string) => string;
            goBack: () => void;
          };
        id: string;
        setDialogState: (status: boolean, params: object) => void;
        classes: any;
    };
interface promoCodeObjType {
    code: string,
    descriptionPromo: string,
    id: string,
    title: string,
    validity: string
}
interface promocodeType {
    "id": string,
    "type": string,
    "attributes": {
      "id": number,
      "title": string,
      "description": string,
      "code": string,
      "discount_type": string,
      "discount": string,
      "valid_from": string,
      "valid_to": string,
      "min_cart_value": string,
      "max_cart_value": string,
      "limit": string | number | null
    }
  }
  
interface getPromoCodeTypes {
    data: Array<promocodeType>,
    meta: {
        pagination: {
            current_page: number,
            next_page: number | null,
            prev_page: number | null,
            total_pages: number,
            total_count: number,
            current_count: number,
            per_page: number
        }
    }
}
interface onlyIdsType {
    [index: number]: number
}
interface S {
    promoCodeData: Array<promoCodeObjType>;
    allPromoCodeDataLength: number;
    showEmptyPage: boolean;
    page: number;
    per_page: number;
    totalCount: number;
    checkedPromoList: Array<promoCodeObjType>;
}

interface SS {
    id: number;
}
export default class PromoCodeController extends BlockComponent<Props, S, SS> {
    getPromoCodesApiCallId: string = "";
    getPromoCodesLengthApiCallId: string = "";
    postBulkDeleteCallId: string = "";
    getOrderNotificationId: string = ""

    scrollRef:  React.RefObject<Scrollbars>;
    constructor(props: Props) {
        super(props);
        this.scrollRef = React.createRef();
        this.receive = this.receive.bind(this);
        this.state = {
            promoCodeData: [],
            allPromoCodeDataLength: 0,
            showEmptyPage: false,
            page: 1,
            per_page: 30,
            totalCount: 0,
            checkedPromoList: []
        };
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AlertMessage),
            getName(MessageEnum.ActionMessageFromToaster)
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    async receive(from: string, message: Message) {
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            if (isTokenExpired(message)) {
                return this.logoutAndNavigateLogin();
              }
            this.messageGetPromoCodesApiCall(message)
            this.messageGetOrderNotiApiCall(message)
            this.messagePostBulkDeleteCall(message)
        }
    }

    logoutAndNavigateLogin = () => {
        clearStorageData();
        const to = new Message(getName(MessageEnum.NavigationMessage));
        to.addData(
          getName(MessageEnum.NavigationTargetMessage),
          "EmailAccountLogin"
        );
        to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        runEngine.sendMessage(to.messageId, to);
      };
      
    messageGetPromoCodesApiCall = (message: Message) => {
        const [responseJson, apiRequestCallId] = getResponseVariables(message);
        if (this.getPromoCodesApiCallId === apiRequestCallId) {
            this.props.hideLoader();
            if (checkResponseError(message)) {
                return showError(
                  configJSON.promoCodesError,
                  responseJson,
                  this.props.hideLoader,
                  this.props.setDialogState
                );
            }
            if (responseJson && responseJson?.data) {
                this.initPromoCodeData(responseJson);
            }
        }
    }

    getOrderNotification = async () => {
      const token = await getStorageData("admintoken");
      const header = {
        "Content-Type": 'application/json',
        token
      };
      const requestSAMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.getOrderNotificationId = requestSAMessage.messageId;
      requestSAMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "/bx_block_order_management/orders/new_order_count");
      requestSAMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
      requestSAMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
      runEngine.sendMessage(requestSAMessage.id, requestSAMessage);
    }
      
    messageGetOrderNotiApiCall = (message: Message) => {
        const [responseJson, apiRequestCallId] = getResponseVariables(message);
        if (this.getOrderNotificationId === apiRequestCallId) {
            this.props.hideLoader();
             emitter.emit("changeNotiNumber", responseJson.new_order_count)
        }
    }

    messagePostBulkDeleteCall = (message: Message) => {
        const [responseJson, apiRequestCallId] = getResponseVariables(message);
        if (this.postBulkDeleteCallId === apiRequestCallId) {
            this.props.hideLoader();
            if (responseJson && responseJson?.messages) {
                this.props.showHeaderBar({ type: 'success' })
                this.getPromoCodeDataApi()
                this.setState({ ...this.state, checkedPromoList: [] })
            } else if (responseJson?.errors) {
                return this.props.showHeaderBar({ message: configJSON.errorMessage, type: 'error' })
            }
        }
    }

    async componentDidMount() {
        await this.getPromoCodeDataApi();
        await this.getOrderNotification()
    }

    goToAddPromoCode = () => {
        const to = new Message(getName(MessageEnum.NavigationMessage));
        to.addData(getName(MessageEnum.NavigationTargetMessage), "AdminPromoCodeCreate");
        to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        runEngine.sendMessage(to.messageId, to);
    };

    handleEditClick = (item: promoCodeObjType) => {
        this.props.navigation.navigate("AdminPromoCodeCreate", {id: item.id});
    };

    handlePageChange = async(value: number) => {
        this.setPageState(value, await this.getPromoCodeDataApi())
    };

    checkedItems = (item: Array<promoCodeObjType>) => {
        this.setCheckedPromoListState(item)
    }

    handleDeleteModal = () => {
        this.props.setDialogState(true, {
            title: configJSON.deleteModalTitle,
            message: configJSON.deleteModalMessage,
            confirmColor: configJSON.deleteModaConfirmColor,
            confirmBackground: configJSON.deleteModalConfirmBackground,
            containerClassName: "promo-delete-dialog",
            confirmHoverBackground: configJSON.deleteModalConfirmHoverBackground,
            onSubmit: () => this.handleBulkDeletePromoCodeAPI()
        });
    }
    
    handleBulkDeletePromoCodeAPI = async () => {
        const { checkedPromoList } = this.state;
        let onlyIDs = checkedPromoList?.map((element: promoCodeObjType) => +element.id)
        await this.postBulkDelete(onlyIDs)
    }

    initPromoCodeData = (responseJson: getPromoCodeTypes) => {
        if (responseJson.data && responseJson.data.length > 0) {
            let promoData = responseJson.data;
            const responseData = promoData.map((item) => {
                const fromDay = item.attributes.valid_from.split('-')[2];
                const fromMonth = item.attributes.valid_from.split('-')[1];
                const fromYear = item.attributes.valid_from.split('-')[0];
                const toDay = item.attributes.valid_to.split('-')[2];
                const toMonth = item.attributes.valid_to.split('-')[1];
                const toYear = item.attributes.valid_to.split('-')[0];

                return {
                    id: item.id,
                    title: item.attributes.title,
                    descriptionPromo: item.attributes.description,
                    code: item.attributes.code,
                    validity: `${fromDay} ${configJSON.months[parseInt(fromMonth) - 1]}  ${fromYear} - ${toDay} ${configJSON.months[parseInt(toMonth) - 1]} ${toYear}`,
                };
            });
            const totalCount = responseJson?.meta?.pagination?.total_count || 0;
            this.setState({
                promoCodeData: responseData, showEmptyPage: false,
                totalCount: totalCount,
            });
        } else {
            this.setState({ promoCodeData: [], showEmptyPage: true });
        }
    };
    getPromoCodeDataApi = async () => {
        this.props.showLoader();
        const requestMessage = await generateRequestMessage(`${configJSON.getPromoCodeApi}?page=${this.state.page}&per_page=${this.state.per_page}`, "GET");
        this.getPromoCodesApiCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    
    postBulkDelete = async (data: onlyIdsType) => {
        this.props.showLoader();
        const requestMessage = await generateRequestMessage(configJSON.postBulkDelete, configJSON.deleteApiMethod);
        let body = {
            "promo_code_ids": data
        }
        this.postBulkDeleteCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    
    setPageState(state: number, callback?: void) {
        this.setState({ page: state }, () => callback)
    }

    setCheckedPromoListState(state: Array<promoCodeObjType>, callback?: void) {
        this.setState({ ...this.state, checkedPromoList: state }, () => callback)
    }
}
// Customizable Area End
