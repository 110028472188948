// Customizable Area Start
import React from "react";
import DialogAddressController, { AreaType } from "./DialogAddressController.web";
import { Dialog, DialogContent, InputAdornment } from "@material-ui/core";
import TextField from "../../../../packages/blocks/ss-cms-common-components/src/TextField/TextField.web";
import CloseIcon from "@material-ui/icons/Close";
import MapComponentWeb from "./MapComponent.web";
import AutoCompleteInputPlaces from "./AutoCompleteInputPlaces.web";
import { Autocomplete } from "@material-ui/lab";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const configJSON = require("./config");

export class DialogAddress extends DialogAddressController {
    renderMap() {
        return (
            <div style={webStyles.viewMap}>
                <MapComponentWeb
                    countryCode={this.props.countryCode}
                    latitude={this.state.location.latitude || 0}
                    longitude={this.state.location.longitude || 0}
                    address={this.state.address}
                    getCurrentPosition={() => this.getCurrentLocation()}
                />
            </div>
        )
    }

    renderAddressInput = () => {
        return (
            <div style={webStyles.viewAddress}>
                <span style={webStyles.title}>{configJSON.enterAddress}</span>
                <AutoCompleteInputPlaces
                    id="AutoCompleteInputPlaces"
                    initData={this.state.address}
                    countryCode={this.props.countryCode}
                    onBlurInput={() => this.setState({ scrollDisabled: false })}
                    onFocusInput={() => this.setState({ scrollDisabled: true })}
                    onChangeLocation={(lat, long, address) => {
                        this.onChangeLocation(lat, long)
                        this.setState({ 
                            address, 
                            areaError: false, 
                            inputValue: "",
                            areaState: {
                                id: "0",
                                attributes: {
                                    name: ""
                                }
                            },
                        })
                    }}
                />
                {this.state.addressError && <span style={webStyles.error}>{configJSON.thisFieldNotEmpty}</span>}
            </div>
        )
    }

    renderAreaPicker(isVisible: boolean) {
        if (isVisible) {
            return (
                <div style={webStyles.gap}>
                    <span style={webStyles.title}>{configJSON.addArea}</span>
                    <div style={webStyles.areaGap} />
                    <Autocomplete<AreaType>
                        id="state-list"
                        data-testId="ac-state-list-overseas"
                        options={this.state.areaList}
                        classes={{
                            option: this.props.classes?.option,
                            root: this.props.classes?.countryTextField,
                            noOptions:this.props.classes?.noOption
                        }}
                        ListboxProps={{ style:webStyles.zoneScrollBar }}
                        open={this.state.openState}
                        onOpen={() => this.handleStateOpen()}
                        onClose={() => this.handleStateClose()}
                        value={this.state.areaState}
                        getOptionLabel={(option) => option?.attributes?.name || ""}
                        renderOption={(option) => <span style={{ color:'var(--website-builder-secondary-color)' }}>{option?.attributes?.name}</span>}
                        inputValue={this.state.inputValue}
                        onInputChange={(e, val) => this.onHandleInputChange(val)}
                        onChange={this.handleChangeState}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="areaState"
                                placeholder={configJSON.statePlaceholder}
                                InputProps={{
                                    ...params.InputProps,
                                    classes: {
                                        input: this.props.classes?.inputDialog,
                                    },
                                    className: this.props.classes?.countryAutocomplete,
                                    startAdornment: <>{params.InputProps.startAdornment}</>,
                                    endAdornment: (
                                        <div
                                            style={webStyles.pointer}
                                            onClick={this.handleStateInverse}
                                        >
                                            <InputAdornment position="end">
                                                <ExpandMoreIcon style={webStyles.expandIcon} />
                                            </InputAdornment>
                                        </div>
                                    ),
                                }}
                                variant="outlined"
                                error={this.state.areaError}
                            />
                        )}
                    />
                    {this.state.areaError && <span style={webStyles.error}>{configJSON.thisFieldNotEmpty}</span>}
                </div>
            )
        } else return <></>
    }

    renderHouseInput() {
        return (
            <TextField
                onChange={(event) => { this.setState({ house: event.target.value }) }}
                error={this.state.houseError && this.state.isHouseFocused ? true : false}
                helperText={this.state.houseError && this.state.isHouseFocused ? this.state.houseError : ""}
                value={this.state.house}
                label={configJSON.houseTitle}
                required
                fullWidth
                id={"house-input"}
                name={"house-input"}
                onFocus={() => this.onFocusHouse()}
                onBlur={() => this.onBlurHouse()}
                InputProps={{
                    classes: {
                        input: this.props.classes?.inputDialog,
                        root: this.props.classes?.inputTextLable
                    },
                }}
            />
        )
    }

    renderBuildingInput() {
        return (
            <TextField
                onChange={(event) => { this.setState({ building: event.target.value }) }}
                error={this.state.buildingError && this.state.isBuildingFocused ? true : false}
                helperText={this.state.buildingError && this.state.isBuildingFocused ? this.state.buildingError : ""}
                value={this.state.building}
                label={configJSON.buildingTitle}
                required
                fullWidth
                name={"building-input"}
                id={"building-input"}
                onFocus={() => this.onFocusBuilding()}
                onBlur={() => this.onBlurBuilding()}
                InputProps={{
                    classes: {
                        input: this.props.classes?.inputDialog,
                        root: this.props.classes?.inputTextLable
                    },
                }}
            />
        )
    }

    renderLandmarkInput() {
        return (
            <TextField
                onChange={(event) => { this.setState({ landmark: event.target.value }) }}
                value={this.state.landmark}
                label={configJSON.landmarkTitle}
                required={false}
                fullWidth
                name={"landmark-input"}
                id={"landmark-input"}
                InputProps={{
                    classes: {
                        input: this.props.classes?.inputDialog,
                        root: this.props.classes?.inputTextLable
                    },
                }}
            />
        )
    }

    render() {
        const { isDialogOpen, countryCode } = this.props
        return (
            <Dialog
                onClose={() => this.closeDialog()}
                aria-labelledby="customized-dialog-title"
                open={isDialogOpen}
                fullWidth
                PaperProps={{
                    style: webStyles.viewDialog
                }}
            >
                <DialogContent className="dialog-address-content" style={{ overflow: this.state.scrollDisabled ? "hidden" : "auto" }}>
                    <div style={webStyles.textTitle}>
                        {configJSON.confirmAddress}
                        <CloseIcon
                            onClick={() => this.closeDialog()}
                            style={webStyles.closeIcon}
                            data-test-id="close-icon"
                        />
                    </div>
                    {this.renderMap()}
                    <div style={webStyles.containerInput}>
                        {this.renderAddressInput()}
                        <div style={webStyles.gap} />
                        {this.renderAreaPicker(countryCode === "ae")}
                        {this.renderHouseInput()}
                        <div style={webStyles.gap} />
                        {this.renderBuildingInput()}
                        <div style={webStyles.gap} />
                        {this.renderLandmarkInput()}
                        <div style={webStyles.proceedButton} data-test-id="proceed-btn" onClick={() => this.onHandleProceed()}>{configJSON.proceed}</div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

const webStyles = {
    pointer: { cursor: "pointer" },
    error: {
        color: "#f44336",
        fontSize: "13px",
        fontFamily: "var(--website-builder-body-font)",
        lineHeight: "18px",
        marginTop: "2px"
    },
    proceedButton: {
        background: "linear-gradient(99.09deg, var(--website-builder-primary-dark-accent-color) 2.64%, var(--website-builder-primary-color) 100%)",
        width: "100%",
        fontFamily: "var(--website-builder-body-font)",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        letterSpacing: "0em",
        color: "#fff",
        textAlign: "center",
        paddingTop: "11px",
        paddingBottom: "11px",
        marginTop: "20px",
        borderRadius: "8px"
    } as React.CSSProperties,
    inputPlaces: {
        padding: "2px 8px",
        fontSize: "14px",
        lineHeight: "24px",
        fontWeight: 600,
        color: "#000"
    },
    viewDialog: {
        borderRadius: '6px',
        border: '0px solid #D0D2DA',
        height: "85%"
    },
    containerInput: {
        paddingLeft: "28px",
        paddingRight: "28px",
    },
    areaGap: {
        height: "6px"
    },
    textTitle: {
        width: "100%",
        textAlign: "center",
        color: "var(--website-builder-secondary-color)",
        fontFamily: "var(--website-builder-header-font)",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "26px",
        letterSpacing: "0em",
        position: "sticky",
        top: 0,
        paddingBottom: "20px",
        paddingTop: "30px",
        zIndex: 2,
        backgroundColor: "#fff"
    } as React.CSSProperties,
    closeIcon: {
        width: "24px",
        position: "absolute",
        zIndex: 1,
        right: "20px",
        color:"var(--website-builder-secondary-color)"
    } as React.CSSProperties,
    gap: {
        marginBottom: "12px"
    },
    viewMap: {
        marginBottom: "30px"
    },
    title: {
        color: "var(--website-builder-secondary-color)",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "var(--website-builder-body-font)",
        marginLeft: "4px"
    },
    viewAddress: {
        display: 'flex',
        flexDirection: "column"
    } as React.CSSProperties,
    expandIcon:{
        color:'#64748B'
    },
    zoneScrollBar:{
        scrollbarColor: "var(--website-builder-primary-color) #E2E8F0",
        scrollbarWidth: "auto"
    } as React.CSSProperties
}

export default DialogAddress;
// Customizable Area End
