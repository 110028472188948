import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import {
  NotificationFormatted,
  NotificationType,
} from "./Types";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { withToastProps } from "../../ss-cms-common-components/src/HOC/withSnackBar.Web";
import { RouteComponentProps } from "react-router-dom";
import { getResponseVariables } from "../../ss-cms-common-components/src/GetResponseVariables/GetResponseVariables";
import { checkResponseError } from "../../ss-cms-common-components/src/ErrorHandler/ErrorHandler";
import {Scrollbars} from "react-custom-scrollbars";
import {createRef} from "react"
import {generateRequestMessage, showError, isTokenExpired, clearStorageData} from "../../ss-cms-common-components/src/Utilities/Utilities";

const configJSON = require("./config");

export type Props = withHeadeActionBarProps &
  withToastProps &
  withLoaderProps &
  RouteComponentProps &
  DialogProps & {
    navigation: {
      navigate: (to: string, params: object) => void;
      getParam: (param: string) => string;
      goBack: () => void;
    };
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  pageInitialized: boolean;
  page: number;
  allNotifications: NotificationFormatted[];
  showNotificationCreate: boolean;
  notificationItem?: NotificationType;
  totalCount: number;
  checkedNotificationList: NotificationType[];
  isEditClicked: boolean;
  per_page: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id?: string | number;
  // Customizable Area End
}

class NotificationsController extends BlockComponent<Props, S, SS> {
  scrollRef:  React.RefObject<Scrollbars>;
  initMessageId: string = "";
  editMessageId: string = "";
  createMessageId: string = "";
  postBulkDeleteCallId: string = "";
  sendNotificationApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.scrollRef = createRef();
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      pageInitialized: false,
      page: 1,
      allNotifications: [],

      showNotificationCreate: false,

      per_page: 30,
      totalCount: 0,
      checkedNotificationList: [],
      isEditClicked: false,
      notificationItem: undefined,
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getNotificationsDataApi();
  }

  // Customizable Area Start

  scrollToTop = (ref?: React.RefObject<Scrollbars>) => {
    (ref || this.scrollRef).current?.scrollToTop();
  }

  handleCreateClick = () => {
    this.setState({ isEditClicked: false });
    this.handleCreateDialogState(true);
  };

  handleCreateDialogState = (newState: boolean) => {
    this.setState({
      showNotificationCreate: newState,
    });
  };

  setOpen = (isOpen: boolean) => {
    this.setState({
      showNotificationCreate: isOpen,
    });
  };

  handleEditClick = (item: NotificationType) => {
    this.setState({
      isEditClicked: true,
      notificationItem: item,
    });
    this.handleCreateDialogState(true);
  };

  handlePushClick = async (data: NotificationType) => {
    const requestMessage = await generateRequestMessage(
      `${configJSON.notificationAPiEndPoint}/${data.id}/${configJSON.sendNotification}`,
      configJSON.sendNotificationApiMethodType
    );
    this.sendNotificationApiCallId = requestMessage.messageId;
    this.props.showLoader();
    this.send(requestMessage);
  };

  handlePageChange = (value: number) => {
    this.setState(
      {
        page: value,
      },
      () => {
        this.scrollToTop();
        this.getNotificationsDataApi();
      }
    );
  };

  checkedItems = (item: NotificationType[]) => {
    this.setState({ ...this.state, checkedNotificationList: item });
  };

  handleDeleteModal = () => {
    this.props.setDialogState(true, {
      title: configJSON.areYouSure,
      message: configJSON.areYouSureYouWantToDeleteThisNotifications,
      confirmColor: "white",
      confirmBackground: "#FF1744",
      confirmHoverBackground: "rgb(240, 25, 73)",
      containerClassName: "notifications-delete-dialog",
      onSubmit: () => this.handleBulkDeleteNotificationAPI(),
    });
  };
  handleBulkDeleteNotificationAPI = async () => {
    const { checkedNotificationList } = this.state;
    let onlyIDs = checkedNotificationList?.map(
      (element: { id: number | string }) => +element.id
    );
    await this.postBulkDelete(onlyIDs);
  };

  handleCreateNotification = async (data: { title: string; message: string }) => {
    const requestMessage = await generateRequestMessage(
      configJSON.notificationAPiEndPoint,
      configJSON.createNotificationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    this.createMessageId = requestMessage.messageId;
    this.props.showLoader();
    this.send(requestMessage);
  };

  handleEditNotification = async (data: {
    title: string;
    message: string;
    id?: number | string;
  }) => {
    const requestMessage = await generateRequestMessage(
      `${configJSON.notificationAPiEndPoint}/${data.id}`,
      configJSON.updateNotificationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        title: data.title,
        message: data.message,
      })
    );
    this.editMessageId = requestMessage.messageId;
    this.props.showLoader();
    this.send(requestMessage);
  };

  receive = (from: string, message: Message) => {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }
      this.messageInitCall(message);
      this.messagePostBulkDeleteCall(message);
      this.messageCreateEditCall(message);
      this.messageSendNotificationApiCall(message);
    }
  };

  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  messageInitCall = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);

    if (this.initMessageId === apiRequestCallId) {
      this.setState({ pageInitialized: true });
      if (checkResponseError(message)) {
        return showError(
          configJSON.errorOnPageInitialization,
          responseJson,
          this.props.hideLoader,
          this.props.setDialogState
        );
      }
      const allNotifications =
        responseJson.data?.map(
          (element: {
            attributes: { id: number; title: string; message: string };
          }) =>
            ({
              id: element?.attributes?.id,
              title: element?.attributes?.title,
              message: element?.attributes?.message,
            } as NotificationFormatted)
        ) || [];

      const totalCount = responseJson?.meta?.pagination?.total_count || 0;
      this.setState({
        allNotifications,
        totalCount: totalCount,
      });
      this.props.hideLoader();
    }
  };
  messagePostBulkDeleteCall = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);

    if (this.postBulkDeleteCallId === apiRequestCallId) {
      if (checkResponseError(message)) {
        return showError(
          configJSON.somethingWentWrong,
          responseJson,
          this.props.hideLoader,
          this.props.setDialogState
        );
      }
      this.props.showHeaderBar({ type: "success" });
      this.getNotificationsDataApi();
      this.setState({ ...this.state, checkedNotificationList: [] });
    }
  };
  messageCreateEditCall = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);

    if (
      this.createMessageId === apiRequestCallId ||
      this.editMessageId === apiRequestCallId
    ) {
      if (checkResponseError(message)) {
        return showError(
          configJSON.somethingWentWrong,
          responseJson,
          this.props.hideLoader,
          this.props.setDialogState
        );
      }
      this.setState({
        showNotificationCreate: false,
      });
      this.getNotificationsDataApi();
      this.props.hideLoader();
    }
  };
  messageSendNotificationApiCall = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);

    if (this.sendNotificationApiCallId === apiRequestCallId) {
      if (checkResponseError(message)) {
        return showError(
          configJSON.somethingWentWrong,
          responseJson,
          this.props.hideLoader,
          this.props.setDialogState
        );
      }
      this.props.hideLoader();
      this.props.showHeaderBar({
        type: "success",
        message: configJSON.textNotificationSentSuccessfully,
      });
    }
  };

  getNotificationsDataApi = async () => {
    this.props.displaySpinner();
    const requestMessage = await generateRequestMessage(
      `${configJSON.notificationAPiEndPoint}?page=${this.state.page}&per_page=${this.state.per_page}`,
      "GET"
    );
    this.initMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  postBulkDelete = async (data: Array<number>) => {
    this.props.showLoader();
    const requestMessage = await generateRequestMessage(
      configJSON.bulkDeleteAPiEndPoint+data.join(","),
      configJSON.bulkDeleteAPiMethodType
    );
    this.postBulkDeleteCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}

export default NotificationsController;
