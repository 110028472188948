// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";
import { getStorageData } from "../../../framework/src/Utilities";
import { razorpay, stripe, tapLogo } from "./assets";
import { createRequest } from "./Helpers";
const baseURL = require("../../../framework/src/config.js").baseURL;
export const configJSON = require("./config");


export interface PaymentGatewayType {
  id: string
  type: string
  attributes: {
    name: string
    active: boolean
    config: {
      key_id?: string
      secret_key?: string
      publishable_key?: string
      key_secret?: string
      webhook_secret?: string
    }
  }
  key1?: string
  key2?: string
}

export type Props = DialogProps & {
  id: string
  showHeader: (obj?: object) => void;
  showLoader: () => void;
  hideLoader: () => void;
  setDialogState: () => void;
};

interface S {
  storeCountry: string
  isShowModal: boolean
  paymentGateway?: PaymentGatewayType
}

interface SS {
  id: number;
}

class PaymentGatewayActivisionController extends BlockComponent<Props, S, SS> {
  getPaymentGatewayId: string = ""
  putPaymentGatewayId: string = ""
  getCurrentCountryId: string = ""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ActionMessageFromToaster)
    ];

    this.state = {
      storeCountry: "",
      isShowModal: false,
      paymentGateway: undefined
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  receive = (from: string, message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getPaymentGatewayId:
            const currentGateway: PaymentGatewayType = responseJson.data.find((item: PaymentGatewayType) => item.attributes.name === this.getCurrentPayment().title)
            const newGateway = this.handleObjectGateway(currentGateway)
            this.setState({ paymentGateway: newGateway })
            break;
          case this.putPaymentGatewayId:
            this.handleGetPaymentGateway()
            break;
          case this.getCurrentCountryId:
            this.setState({ storeCountry: responseJson.data.attributes.country_code }, () => {
              this.handleGetPaymentGateway()
            })
            break
        }
      }
    }
  };

  handleObjectGateway(gateway: PaymentGatewayType) {
    if (gateway.attributes.name === "Razorpay") {
      return {
        ...gateway,
        key1: gateway.attributes.config.key_secret,
        key2: gateway.attributes.config.key_id,
      }
    } else {
      return {
        ...gateway,
        key1: gateway.attributes.config.secret_key,
        key2: gateway.attributes.config.publishable_key,
      }
    }
  }

  async handlePutPaymentGateway(changeKey: { secretKey: string; otherKey: string; webhookKey: string }) {
    const token = await getStorageData("admintoken");
    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.putPaymentGatewayId = request.messageId;
    let data
    if (this.state.storeCountry === "in") {
      data = {
        data: {
          active: true,
          config: {
            key_id: changeKey.otherKey,
            key_secret: changeKey.secretKey,
          }
        }
      }
    } else if (this.state.storeCountry === "ae") {
      data = {
        data: {
          active: true,
          config: {
            publishable_key: changeKey.otherKey,
            secret_key: changeKey.secretKey,
          }
        }
      }
    } else {
      data = {
        data: {
          active: true,
          config: {
            secret_key: changeKey.secretKey,
            publishable_key: changeKey.otherKey,
            webhook_secret: changeKey.webhookKey
          }
        }
      }
    }

    createRequest({
      token,
      requestMsg: request,
      method: "PUT",
      endPoint: baseURL + `${configJSON.getPaymentGateway}/${this.state.paymentGateway?.id}`,
      body: JSON.stringify(data)
    });
  }

  async handleGetPaymentGateway() {
    const token = await getStorageData("admintoken");
    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getPaymentGatewayId = request.messageId;

    createRequest({
      token,
      requestMsg: request,
      method: "GET",
      endPoint: baseURL + `${configJSON.getPaymentGateway}`
    });
  }

  async getCurrentCountry() {
    const token = await getStorageData("admintoken");
    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getCurrentCountryId = request.messageId;

    createRequest({
      token,
      requestMsg: request,
      method: "GET",
      endPoint: baseURL + `${configJSON.storeDetailsAPiEndPoint}`
    });
  }

  handleModal(value: boolean) {
    this.setState({ isShowModal: value })
  }

  getCurrentPayment() {
    if (this.state.storeCountry === "in") {
      return {
        title: "Razorpay",
        image: razorpay,
        titleSecondField: "Key ID",
      }
    } else if (this.state.storeCountry === "ae") {
      return {
        title: "Tap Payments",
        image: tapLogo,
        titleSecondField: "Publishable Key"
      }
    } else {
      return {
        title: "Stripe",
        image: stripe,
        titleSecondField: "Publishable Key"
      }
    }
  }

  handleClipboard = (text: string) => {
    if (!navigator.clipboard) {
      return;
    }
    navigator.clipboard.writeText(text);
  };

  async componentDidMount(): Promise<void> {
    this.getCurrentCountry()
  }

  async componentWillUnmount(): Promise<void> {
    this.setState({ paymentGateway: undefined })
  }
}

export default PaymentGatewayActivisionController;
// Customizable Area End
