// Customizable Area Start
import * as React from "react";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import Pagination from "../../ss-cms-common-components/src/Pagination/Pagination";
import {
  createStyles, Theme, withStyles, Button, Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout"
import "../assets/tax.css";
import { Scrollbars } from 'react-custom-scrollbars';
import moment from "moment"

export const styles = (theme: Theme) =>
  createStyles({
    buttonContainer: {
      alignItems: "flex-end",
    },
    tableBorder: {
      border: "2px", background: "#fff"
    },
    tableBorderRow: {
      border: "2px solid #ECEDF0"
    },
    editImage: {
      cursor: "pointer"
    },
    tableTitle: {
      fontFamily: "Rubik",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "22px",
      letterSpacing: "0em",
      color: "#676B7E",
    },
    tableBodyTitle: {
      fontFamily: "Rubik",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "0px",
      textAlign: "left",
      color: "#3C3E49",
    },
    marinRightActive: {
      marginRight: "-8px",
    },
    tableBodyActive: {
      fontFamily: "Rubik",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "0px",
      textAlign: "left",
      color: "#3C3E49",
      display: "flex",
      justifyContent: "end"
    }
  });
import withSnackBar from "../../ss-cms-common-components/src/HOC/withSnackBar.Web";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { withRouter } from "react-router-dom";
import EmptyPage from "../../ss-cms-common-components/src/EmptyPage/EmptyPage";
import TaxesController, { taxResponseTypes } from "./TaxesController.web";
import AddTaxDialog from "./AddTaxDialog.web";

interface ClassesCustomSwitchType {
  root: string;
  switchBase: string;
  thumb: string;
  track: string;
  checked: string;
  focusVisible: string;
}

export type CustomSwitchProps = {
  classes: ClassesCustomSwitchType;
  checked: boolean;
  value: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
}
export const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 48,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 2,
    "&$checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + $track": {
        backgroundColor: "#6200EA",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#red",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 23,
    height: 22,
  },
  track: {
    borderRadius: 26 / 2,
    border: "none",
    backgroundColor: "#C0C3CE",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }: CustomSwitchProps) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
export const editIcon = require("../assets/editIcon.svg");
const configJSON = require("./config");

export class Taxes extends TaxesController {
  render() {
    const { classes } = this.props
    const { taxTable, showEmptyPage, totalCount } = this.state;
    return (
      <Scrollbars ref={this.scrollRef}>
        <div className="mainContainer-tax" >
          {taxTable.length > 0 ? (
            <div>
              <div className="header-section-tax">
                <div className="tax_page_title d-flex align-items-center">
                  <span className="tax_page_title">{configJSON.taxes}</span>
                </div>

                <div className="add-tax-button">
                  <Button data-testid="newTax" className="add-button" onClick={this.handleAddNewTax} variant="contained">
                    {configJSON.addTaxLabel}
                  </Button>
                </div>
              </div>

              <Grid item lg={12} md={12} xs={12}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.tableTitle}
                      >
                        {configJSON.taxTableTitle}
                      </TableCell>
                      <TableCell
                        className={classes.tableTitle}
                      >
                        {configJSON.percentageTable}
                      </TableCell>
                      <TableCell
                        className={classes.tableTitle}
                      >
                        {configJSON.lastupdate}
                      </TableCell>
                      <TableCell
                        className={classes.tableTitle}
                        align="right"
                      >
                        {configJSON.active}
                      </TableCell>
                      <TableCell
                        className={classes.tableTitle}
                        align="right"
                      >
                        {configJSON.edit}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableBorder}>
                    {this.state.taxTable &&
                      this.state.taxTable.length > 0 &&
                      (
                        this.state.taxTable.map((data: taxResponseTypes) => {
                          return (
                            <TableRow
                              key={data.attributes.id}
                              className={classes.tableBorderRow}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                className={classes.tableBodyTitle}
                              >
                                {data.attributes.tax_title}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                className={classes.tableBodyTitle}
                              >
                                {data.attributes.tax}{configJSON.percentageSign}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                className={classes.tableBodyTitle}
                              >
                                {moment(data.attributes.updated_at).format(
                                  configJSON.dateFormat)}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                className={classes.tableBodyActive}
                              >
                                <FormGroup>
                                  <FormControlLabel
                                    className={classes.marinRightActive}
                                    data-testId="editStatusProduct"
                                    control={
                                      <IOSSwitch
                                        value={data.attributes.active}
                                        checked={Boolean(data.attributes.active)}
                                        onChange={() => {
                                          this.statusTaxDataApi(data.attributes.id, !data.attributes.active)
                                        }}
                                        name={data.attributes.id}
                                      />
                                    }
                                    label=""
                                  />
                                </FormGroup>
                                {data.attributes.active}
                              </TableCell>
                              <TableCell align="right">
                                <img
                                  src={editIcon}
                                  className={classes.editImage}
                                  data-testId="editTaxBtn"
                                  onClick={() => {
                                    this.editTax(data);
                                  }}
                                  alt="edit icon"
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })
                      )}
                  </TableBody>
                </Table>
              </Grid>
              <div className="pagination-wrapper">
                <Pagination
                  count={totalCount}
                  onChange={this.handlePageChange}
                  itemText={configJSON.paginationItemText}
                  pageSize={this.state.per_page}
                />
              </div>

            </div>
          ) : showEmptyPage ? (
            <EmptyPage
              title={configJSON.emptyPageTitle}
              message={configJSON.emptyPageMassage}
              button_label={configJSON.emptyPageButton}
              handleClick={this.handleAddNewTax}
            />
          ) : null}

          <AddTaxDialog
            isOpen={this.state.isOpen}
            title={configJSON.addTaxTitle}
            onCancel={this.onCancelClick}
            taxId={this.state.taxId}
            taxTitle={this.state.taxTitle}
            taxPercentage={this.state.taxPercentage}
            getTaxDataApi={this.getTaxDataApi}
            handleErrorRespone={this.handleErrorRespone}
            helperTextValue={configJSON.addDialogTaxHelperText}
            textLabel={configJSON.addDialogTaxLabel}
            width="900px"
          />
        </div>
      </Scrollbars>
    );
  }
}

export default withLayout(withStyles(styles)(
  withLoader(withSnackBar(withHeaderBar(withRouter(withDialog(Taxes)))))
));
// Customizable Area End
