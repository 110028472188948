import React from "react";
import {
    withStyles,
    Theme,
    createStyles
} from "@material-ui/core";

interface Props {
    classes: any
}

export class NoSearchFound extends React.Component<Props>{
    constructor(props: Props) {
        super(props)
    }

    render() {
        const { classes } = this.props
        return (
            <div className={classes.mainDiv}>
                <img src={require("./assets/NoSearchFound.png")} style={{ width: "76px", height: "76px" }} />
                <span className={classes.noResultHeading}>
                    No results found
                </span>
                <span className={classes.noResultText}>
                    We couldn't find any matches for your filter criteria. Please <br /> reset your filters and try the search option.
                </span>
            </div>
        )
    }
}

export const Styles = (theme: Theme) =>
    createStyles({
        mainDiv: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "40px 0",
            backgroundColor: "white",
            border: "2px solid #ECEDF0",
            width: "70%",
            "@media (max-width: 900px)": {
                width: "85%",
                textAlign: "center",
                backgroundColor: "white",
            },
            "@media (max-width: 480px)": {
                width: "calc(100% - 32px)",
                textAlign: "center",
                backgroundColor: "white",
            },
        },
        noResultHeading: {
            fontFamily: "var(--website-builder-header-font)",
            fontSize: "20px",
            fontWeight: 500,
            lineHeight: "26px",
            color: "var(--website-builder-secondary-color)",
            textAlign: "center",
            marginTop: "20px"
        },
        noResultText: {
            fontFamily: "var(--website-builder-body-font)",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "22px",
            color: "var(--website-builder-secondary-dark-accent-color)",
            textAlign: "center",
            marginTop: "8px"
        }
    })

export default withStyles(Styles)(NoSearchFound)