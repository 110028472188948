import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Modal,
  TextField,
  Switch,
  withStyles,
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

interface ISwitch {
  classes: Record<string, string>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean,
}

interface IKeyData {
  apiKey: string,
  secretApiKey: string,
  status: boolean | string,
}

interface ICard {
  id: number;
  image: string;
  apiKey: string;
  secretApiKey: string;
  status: boolean;
  secretId: number;
  description: string;
  name: string;
}

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 48,
    height: 24,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    transform: 'translate(2px,2px)',
    '&$checked': {
      transform: 'translate(24px,2px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#059669',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#059669',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 18,
    height: 18,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(Switch);

type TName = "apiKey" | "secretApiKey"
interface IFeild {
  name: TName,
  label: string,
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Storecontentmanagement2Controller, {
  Props,
  configJSON,
} from "./Storecontentmanagement2Controller";

export default class Storecontentmanagement2 extends Storecontentmanagement2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderButtons = (isEditing: boolean) => {
    return (
      <Box sx={
        webStyle.buttonContainer
      }>
        {isEditing && <Button onClick={this.handleDelete} data-testid='delete button' className='cancel_button red_color'>
          {configJSON.deleteButtonText}
        </Button>}
        <Box sx={webStyle.buttonResponsiveCon}>
          <Button onClick={this.toggleModal} className='cancel_button'>
            {configJSON.cancelButtonText}
          </Button>
          <Button onClick={this.handleAdd} className='add_button' data-testid='add_button'>
            {isEditing ? configJSON.saveButtonLabel : configJSON.addButtonLabel}
          </Button>
        </Box>
      </Box>
    );
  }
  renderEmptyCardsData = () => (
    <Box sx={webStyle.mainContainer}>
      <Box sx={webStyle.addNewSppingTrackerCon}>
        <Typography className={"title"} variant="h2">{configJSON.addYourStoreDetailsTitle}</Typography>
        <Typography className={"description"} >{configJSON.addYourStoreDetailsDescription}</Typography>
        <Button onClick={this.navigateToStoreDetailsPage} className='button' data-testid='add store button'>{configJSON.addStoreDetailsButtonTitle}</Button>
      </Box>
    </Box>
  );
  renderShipRocketCard = (card: ICard) => {
    return (
      <Grid item xs={12} sm={6} md={4} lg={3} key={card.id}>
        <Box sx={webStyle.shipRocketContainer}>
          <Box className='image_container'>
            <img src={card.image} alt={card.name} />
          </Box>
          <Box className="description_container">
            <Box className="title_switch_container" >
              <Typography className='shiprocket_title' data-testid='shiprocket_title'>
                {card.name}
              </Typography>
              {card.secretApiKey && card.apiKey && <IOSSwitch
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.handleCheckboxChange(event, card) }}
                checked={card.status}
                data-testid='checkbox'
              />}
            </Box>
            <Typography className='shiprocket_description'>{card.description}</Typography>
            <Button variant="outlined"
              className='edit_button'
              data-testid='edit button'
              onClick={() => { this.handleEdit(card) }} >
              {card.secretApiKey || card.apiKey ? configJSON.editButton : configJSON.connectButton}
            </Button>
          </Box>
        </Box>
      </Grid>
    )
  }
  renderShipRocketList = () => {
    const { shipRocketList } = this.state;
    return (
      <Grid container spacing={3} >
        {shipRocketList.length > 0 && shipRocketList.map(card => this.renderShipRocketCard(card))}
      </Grid>
    )
  }
  renderModalCard = () => {
    return (
      <Modal open={this.state.openModal} onClose={this.toggleModal} style={webStyle.modalStyles}>
        <Box sx={webStyle.cardContainer}>
          <Box sx={webStyle.shirocketTextCon}>
            <Typography className="ship_rocket_title" data-testid='ship rocket title' variant="h2">{this.state.editCard?.name + configJSON.shiprocketIntegrationText}</Typography>
            {configJSON.textFeildsData.map((feild: IFeild) => (
              <Box key={feild.name} sx={webStyle.textFeildContainer}>
                <Typography className='textFeildTitle'>{feild.label}</Typography>
                <TextField
                  className="textFeild"
                  name={feild.name}
                  variant="outlined"
                  data-testid='text feild'
                  value={this.state.keysData[feild.name]}
                  FormHelperTextProps={{ style: webStyle.helperTextStyle }}
                  helperText={(this.state.isAddPressedOnce && this.state.errorData[feild.name]) ?
                    configJSON.requiredText : configJSON.emptyText}
                  onChange={this.handleChange} />
              </Box>
            ))}
          </Box>
          {this.renderButtons(!!(this.state.editCard?.apiKey && this.state.editCard?.secretApiKey))}
        </Box>
      </Modal>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {(this.state.selectedCountryInStore === null ||
          this.state.selectedCountryInStore.id === null
        ) ?
          this.renderEmptyCardsData() :
          this.renderShipRocketList()
        }
        {this.renderModalCard()}
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  helperTextStyle: {
    color: "red",
  },
  shipRocketContainer: {
    width: "min(100%,320px)",
    borderRadius: 5,
    background: '#FFF',
    boxShadow: '0px 4px 4px 0px #F5F5F5',
    "& > .image_container": {
      height: "153px",
      width: "100%",
      backgroundColor: "#ECEDF0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: ".5rem",
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      }
    },
    "& > .description_container": {
      padding: "1rem",
      width: "100%",
      display: "flex",
      flexDirection: "column" as 'column',
      gap: "1rem",
    },
    "&  .title_switch_container": {
      display: 'flex',
      justifyContent: "space-between",
      alignItems: "center",
      gap: "1rem",
      height: "2.5rem"
    },
    "& .shiprocket_title": {
      color: '#000',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Rubik',
      fontSize: 18,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '24px',
    },
    "& .shiprocket_description": {
      color: '#8C8C8C',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Rubik',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
    },
    "& .edit_button": {
      borderRadius: "5px",
      border: "1.5px solid #364F6B",
      width: "100%",
      height: "48px",
      textTransform: "none",
      color: '#364F6B',
      fontFamily: "Rubik",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",


    }
  },
  cardContainer: {
    bgcolor: "#FFF",
    borderRadius: "2px solid #ECEDF0",
    height: "min(100%,420px)",
    width: "min(100%,686px)",
    position: "relative" as 'relative',
    display: "flex",
    flexDirection: "column" as 'column'
  },
  mainContainer: {
    minHeight: "100%",
    width: "100%",
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
  },
  buttonContainer: {
    height: "4rem",
    borderTop: "",
    bgcolor: "#fff",
    width: "100%",
    marginTop: "auto",
    display: "flex",
    justifyContent: "sapce-between",
    flexShrink: 0,

    "& .cancel_button, & .cancel_button:hover": {
      color: '#3C3E49',
      textAlign: 'center',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Rubik',
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      textDecoration: 'underline',
      textTransform: "capitalize",
      padding: ".8rem 3rem",
      height: "inherit"
    },
    "& .red_color, & .red_color:hover": {
      color: "#FF1744",
    },
  },
  buttonResponsiveCon: {
    height: "100%",
    marginLeft: "auto",
    "& .cancel_button, & .cancel_button:hover": {
      color: '#3C3E49',
      textAlign: 'center',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Rubik',
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      textDecoration: 'underline',
      textTransform: "capitalize",
      padding: ".8rem 3rem",
      height: "inherit"
    },
    "& .add_button,& .add_button:hover": {
      borderRadius: 3,
      background: "#00D659",
      color: '#3C3E49',
      textAlign: 'center',
      fontFamily: 'Rubik',
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '24px',
      textTransform: "capitalize",
      padding: ".8rem 3rem",
      height: "inherit"
    }
  },
  modalStyles: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    padding: "max(5%,1rem)"
  },
  addNewSppingTrackerCon: {
    marginTop: "6rem",
    border: "1px solid #eee",
    backgroundColor: "#FFFFFF",
    padding: "2.1rem",
    width: "min(700px,70%)",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "1.1rem",
    "& > .title": {
      color: '#3C3E49',
      textAlign: 'center',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Rubik',
      fontSize: 28,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '32px',
      letterSpacing: '-0.56px',
    },
    "& > .description": {
      color: '#676B7E',
      textAlign: 'center',
      fontFamily: 'Rubik',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '22px',
      width: "80%",
    },
    "& > .button": {
      borderRadius: 3,
      background: "#00D659",
      color: '#3C3E49',
      textAlign: 'center',
      fontFamily: 'Rubik',
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '24px',
      textTransform: "capitalize",
      padding: ".8rem 2rem"
    }
  },
  shirocketTextCon: {
    padding: "2rem",
    display: "flex",
    flexDirection: "column" as "column",
    gap: "2rem",
    "& .ship_rocket_title": {
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Rubik',
      fontSize: 20,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '28px',
      letterSpacing: '-0.4px',
      color: "#3C3E49"
    }
  },
  textFeildContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: ".5rem",
    "& .textFeildTitle": {
      color: "#000",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: "Rubik",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "20px",
      textAlign: "left",
    },
    "& .textFeild": {
      color: "#000",
      borderColor: "#D0D2DA",
      width: "100%",
      "& .MuiInputBase-root-269": {
        outline: "none",
        backgroundColor: "transparent",
        borderColor: "#D0D2DA",
        fontFamily: "Rubik",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "24px",
        textAlign: "left"

      },
      "& .MuiInputBase-root-269:hover": {
        borderColor: "#D0D2DA",
        outline: "none",
        backgroundColor: "transparent",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderRadius: "3px",
        borderColor: "#D0D2DA",
        borderWidth: "1px",
        outline: "none",
      },

      "& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline":
      {
        borderRadius: "3px",
        borderColor: "#D0D2DA",
        borderWidth: "1px",
        outline: "none",
      },

      "& .MuiInput-underline:": {
        borderRadius: "3px",
        borderColor: "#D0D2DA",
      },
      "&:hover fieldset": {
        borderRadius: "3px",
        borderColor: "#D0D2DA !important",
      },
      "& fieldset": {
        borderRadius: "3px",
        borderColor: "#D0D2DA !important",
      },
      "& .MuiInputBase-input": {
        width: "100%",
      },
      "& .MuiFormHelperText-root": {
        color: "red",
      },
    },
  },
};
// Customizable Area End
