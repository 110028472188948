Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ordermanagement1";
exports.labelBodyText = "ordermanagement1 Body";

exports.btnExampleTitle = "CLICK ME";
exports.ordersListEndpoint = "bx_block_order_management/orders";
exports.ordersNotificationEndpoint = "/bx_block_order_management/orders/new_order_count"
exports.examplePutAPiMethod = "PUT";
exports.pageTitle="Orders";
exports.ordersListCsvDownloadEndpoint = "bx_block_data_import_export/export_orders";
exports.ordersListCsvDownloadMethodType = "GET";
exports.noResultsFoundMsg="No results found";
exports.getStoreAvailabilityEndPoint = "bx_block_payment_admin/payment_option_methods";
exports.storeNotAvailable1 = "Online orders are currently switched off. Go to";
exports.storeNotAvailable2 = "to activate.";
exports.home = "Home";
exports.orderDetailApiEndpoint = "bx_block_order_management/view_order_admin";

exports.orderUpdateMessage = "Order status update successfully.";
exports.shipRocketFailed = "Failed to send order to Shiprocket. Please check your credentials or reach out to our team."
exports.updateOrder = "update_order_status";
exports.orderNumber = "Order Number";
exports.orderType = "Order Type";
exports.orderDate = "Order Date";
exports.customer = "Customer";
exports.order = "Order #";
exports.orderStatus = "Order status *";
exports.item = "Item";
exports.quantity = "Quantity";
exports.totalPrice = "Total price";
exports.total = "Total";
exports.subTotal = "Sub total";
exports.taxes = "Taxes";
exports.deliveryCharges = "Delivery charges";
exports.orderDetails = "Order details";
exports.back = "Back";
exports.status = "Status";
exports.items = "Items";
exports.paymentMethod = "Payment Method";
exports.deliveryAddress = "Delivery Address";
exports.collectionAddress = "Collection Address";
exports.collection = "collection";
exports.collectionType = "Collection";
exports.deliveryType = "Delivery";
exports.collectionaddress = "Collection address";
exports.deliveryaddress = "Delivery address";
exports.free = "Free";
exports.backIcon = "backIcon";
exports.printIcon = "printIcon";
exports.notiText = "New orders placed. Go to Orders";
exports.from = "From";
exports.reset = "Reset all"
exports.apply = "Apply"
exports.reviseSearch = "Revise search text and try again"
exports.noResultMatch = "We couldn't find any matches for your filter criteria. Please reset\nyour filters and try the search option."
exports.noOrderDescription = "Looks like there aren't any orders at the moment. As soon as your customers start placing orders, they'll appear here.."
exports.noOrder = "No orders"

exports.orderStatusList = [
  { id: 1, name: "orderplaced", value: "Order placed", color: "#FEF3C7", textColor: '#D97706', key: "placed" },
  { id: 2, name: "preparing", value: "Preparing", color: "#FEF3C7", textColor: '#D97706', key: "preparing" },
  { id: 3, name: "readyforpickup", value: "Ready for Pick Up", color: "#FEF3C7", textColor: '#D97706', key: "ready_for_pick_up" },
  { id: 4, name: "outfordelivery", value: "Out for delivery", color: "#FEF3C7", textColor: '#D97706', key: "out_for_delivery" },
  { id: 5, name: "delivered", value: "Delivered", color: "#D1FAE5", textColor: '#059669', key: "delivered" },
  { id: 6, name: "cancelled", value: "Cancelled", color: "#FEE2E2", textColor: '#DC2626', key: "cancelled" }
]
// Customizable Area End