// Customizable Area Start
import React from "react";
import EmailTemplatesCreateController, {
  Props,
} from "./EmailTemplatesCreateController.web";
import { Box, FormHelperText, Grid, TextField, Typography, withStyles, withWidth, Tooltip } from "@material-ui/core";
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import withSnackBar from "../../ss-cms-common-components/src/HOC/withSnackBar.Web";
import "../assets/styles.css";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { Scrollbars } from "react-custom-scrollbars";
import { backIcon, eyeIcon, infoIcon } from "./assets";
import { Prompt } from "react-router";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout";
import { FacebookIcon, TwitterIcon, LinkedinIcon, DesktopIcon, TabletIcon, MobileIcon } from "./assets";
import MuiTabs from "@material-ui/core/Tabs";
import MuiTab from "@material-ui/core/Tab";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import Editor from "../../ss-cms-common-components/src/Editor-v4/Editor";


const configJSON = require("./config.js");

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    width:'192px',
    height:'46px',
    padding:'8px',
    fontFamily:'Rubik',
    fontWeight:400,
    fontSize:'10px',
    lineHeight:'15px',
    backgroundColor:'#FFFFFF',
    color:'#334155',
    boxShadow: '3px 4px 7.300000190734863px 0px #00000026'
  },
}))(Tooltip);

// Customizable Area End
export class EmailTemplatesCreate extends EmailTemplatesCreateController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  Tabs = (classes:Record<string, string>) => {
    return <MuiTabs
      classes={{
        root: classes.tabsRoot,
        indicator: classes.tabsIndcator,
        flexContainer: classes.tabsFlexContainer,
      }}
      value={this.state.selectedTab}
      onChange={this.handleTabChange}
    >
      {[
        [configJSON.textMobile, MobileIcon],
        [configJSON.textDesktop, DesktopIcon],
      ].map(([label, Icon], itemIndex) => (
        <MuiTab
          data-testid={`preview-device-${label}`}
          classes={{
            root: classes.tabRoot,
            labelIcon: classes.tabLabelIcon,
            selected: classes.tabLabelSelected,
            wrapper: classes.tabIconWrapper,
          }}
          label={label}
          icon={
            <Icon
              className={classes.tabIcon}
              color={this.state.selectedTab === itemIndex ? "#6200EA" : "#9B9FB1"}
            />
          }
        />
      ))}
    </MuiTabs>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
   
    const { classes } = this.props


    return (
      <Grid container item lg={12} md={12} xs={12} className={classes.EmailTemplateWrapper}>
          <Box width='100%' display='flex' height='100%'>
          <Box className={classes.EmailTemplateLeftPart}>
            <Scrollbars renderView={props => (
              <div {...props} className="overflowXhidden" />
          )}>
              <Box className={classes.headerWrapper}>
                <Box width='100%' marginLeft='24px'>
                  <Box className={classes.backIconContainer}>
                    <img data-test-id="back-button" src={backIcon} alt="backIcon" className={classes.backIcon} onClick={this.handleBackButtonClick} />
                    <p className={classes.backText}>{configJSON.textBack}</p>
                  </Box>
                  <Box width='100%' display='flex' justifyContent='space-between' alignItems='flex-end'>
                    <span
                      data-test-id="title"
                      className={`${classes.headerTitle}`}
                    >
                      {this.state.titleInit || "Create Email"}
                    </span>
                  </Box>

                  <Box width='100%' mt='24px'>
                    <span className={classes.hintPreviewText}>{configJSON.textQuickEmailPreview}</span>
                  </Box>
                </Box>
                {/* ------------------ */}
                <Box className={classes.tabsWrapper}>
                  {this.Tabs(classes)}
                  <Box className={classes.PreviewLabelWrapper}>
                    <img className={classes.PreviewIcon} src={eyeIcon} alt="preview email" />
                    <span className={classes.PreviewText}>{configJSON.textPreviewEmail}</span>
                  </Box>
                </Box>
              </Box>
             

              <Box className={classes.BodyWrapper}>

                <Box className={classes[`EmailTemplate${this.handleDeviceResolutionOriginal()}Wrapper`]}>
                  <Box className={classes.logoWrapper}>
                    {this.state.logoImage ? 
                    <img src={this.state.logoImage} className={classes.logoImage} alt="logoImage" />
                    :
                    this.state.storeName && <Box className={classes.logoContainer}>{this.state.storeName}</Box>
                  }
                  </Box>
                  <Box className={classes[`templateContent${this.handleDeviceResolutionOriginal()}Wrapper`]}>
                    <div data-test-id="emailPreview" className={`${this.handleDeviceResolution()}EmailContent ${this.handleDeviceResolutionOriginal()}EmailContentBlock`} dangerouslySetInnerHTML={{__html: this.state.emailContentShow}}></div>
                  </Box>
                  
                  <Box className={classes.footerWrapper}>
                    <Box className={classes.socialMediaContainer}>
                      <img src={FacebookIcon} alt="facebook" />
                      <img src={TwitterIcon} alt="twitter" />
                      <img src={LinkedinIcon} alt="linkedin" />
                    </Box>
                    <Typography className={classes.builderAddressText}>{configJSON.textBuilderAiAddress}</Typography>
                    <Box className={classes.footerUnderLineTextWrapper}>
                      <Typography className={classes.buttonUnderlineText}>{configJSON.textUnsubscribe}</Typography>
                      <Typography className={classes.buttonUnderlineText}>{configJSON.textEmailPreferences}</Typography>
                    </Box>
                  </Box>
                </Box>

              </Box>



            </Scrollbars>

          </Box>

          <Box className={classes.EmailTemplateRightPart}>
            <Scrollbars>
              <Typography variant="h6" className={classes.EditTitle}>{configJSON.textEdit}</Typography>

              <Box width='100%' marginTop="24px">
                <Typography variant="h6" className={classes.fieldTitle}>{configJSON.textTitle}</Typography>
                <TextField
                  data-testid="email-title-input"
                  variant="outlined"
                  fullWidth
                  className={classes.fieldInput}
                  value={this.state.title}
                  onChange={this.handleTitleChange}
                  multiline
                  maxRows={2}
                  error={this.state.titleError}
                  helperText={
                    this.state.titleError && configJSON.textThisFieldIsRequired
                  }
                />
              </Box>

              <Box width='100%'>
                <Typography variant="h6" className={classes.fieldTitle}>{configJSON.textContent} <CustomTooltip placement="right-start" title={configJSON.infoText}>
                  <img
                    src={infoIcon}
                    alt=""
                    style={{
                      width: 18,
                      height: 18,
                      marginLeft: "6px",
                    }}
                  />
                </CustomTooltip>
                </Typography>
                <Editor
                  value={this.state.content}
                  onChange={this.handleEditorChange}
                  height="1010px"
                  error={!!this.state.contentError}
                  EditerRefresh = {this.state.editerRefresh}
              />
              {this.state.contentError && (
                <FormHelperText error={true}>
                  {configJSON.textThisFieldIsRequired}
                </FormHelperText>
              )}
              </Box>
            </Scrollbars>
          </Box>
          {/*  */}
          </Box>
      </Grid>


      
    );
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End

// Customizable Area Start

export const useStyles: Record<string, CSSProperties> = {
  EmailTemplateWrapper: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: " #F5F5F5",
    height: "100%",
  },
  EmailTemplateLeftPart: {
    width: 'calc(100% - 354px)',
    height: '100%',
    },
  headerWrapper:{
    width: '100%',
    backgroundColor:'#f8f9fa'
  },
  backIconContainer: {
    marginTop: '40px',
    width: 'auto',
    marginBottom: '4px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    "@media (max-width: 480px)": {
      marginTop: '20px'
    }
  },
  backIcon: {
    cursor: 'pointer',
    width: '12px',
    height: '12px'
  },
  backText: {
    fontFamily: 'Rubik',
    fontSize: '12px',
    fontWeight: 400,
    marginLeft: '4px',
    marginBottom: '0px !important',
    color: '#676B7E'
  },

  headerTitle: {
    fontFamily: "Rubik",
    fontWeight: 500,
    color: "#3C3E49",
    fontSize: '28px',
    llineHeight: '32px',
    width: 'auto',
    textTransform: 'capitalize',
    "@media (max-width: 480px)": {
      fontSize: '16px',
      lineHeight: '24px',
      width: '100%',
    }
  },
  hintPreviewText:{
    fontFamily:'Rubik',
    fontWeight:400,
    fontSize:'12px',
    lineHeight:'20px',
    color:'#83889E'
  },
  tabsRoot:{
      display: "flex",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 12,
      color: "#83889e",
      textTransform: "none",
      padding: '0px',
      minHeight: 30,
      minWidth: "auto"
  },
  tabRoot:{
    display: "flex",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 12,
    color: "#83889e",
    textTransform: "none",
    padding: '0px 12px',
    minHeight: 30,
    minWidth: "auto"
},
  tabsIndcator:{
    backgroundColor: '#6200ea !important'
  },
  tabsFlexContainer:{
    display: 'flex !important',
    gap: '24px'
  },
  tabLabelIcon:{
    minHeight: '28px'
  },
  tabLabelSelected:{
    lineHeight: '20px',
    color: '#6200ea !important',
    fontWeight: 600,
  },
  tabIconWrapper:{
    flexDirection: "row",
    fontSize: 12,
    fontWeight: 400,
    gap: 8
  },
  tabIcon:{
    marginBottom: '0 !important',
    marginRight: '0px !important',
  },
  tabsWrapper:{
    marginTop:'13px',
    width:'100%',
    position :'relative'
  },
  PreviewLabelWrapper:{
    position : 'absolute',
    right: '25px',
    top:'3px',
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center'
  },
  PreviewIcon:{
    width:'16px',
    height:'16px'
  },
  PreviewText:{
    height:'21px',
    fontSize:'12px',
    fontWeight:400,
    lineHeight:'20px',
    fontFamily:'Rubik',
    textAlign:'center',
    marginLeft:'5px',
    color:'#3C3E49'
  },
  BodyWrapper:{
    display:'flex',
    width:'100%',
    justifyContent:'center',
    padding:'36px 0px',
    color:'#000000',
  },
  EmailTemplateDesktopWrapper:{
    width:'90%', 
    border:'2px solid #000000',
    borderRadius:'3px',
    backgroundColor:'#FFFFFF'
  },
  EmailTemplateMobileWrapper:{
    width:'375px',
    border:'2px solid #000000',
    borderRadius:'3px',
    backgroundColor:'#FFFFFF'
  },
  logoWrapper:{
    width:'100%',
    padding:'10px',
    minHeight:'69px',
    borderBottom:'2px solid #000000',
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },
  logoImage:{
    height:'42px',
  },
  logoContainer:{
    width: '107px',
    height:'44px',
    background:'#ECEDF0',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    fontFamily:'Rubik',
    fontWeight:500,
    fontSize:'12px',
    lineHeight:'20px',
    color:'#BFBFBF'
  },
  templateContentDesktopWrapper:{
    padding: '48px 14px 20px 50px'
  },
  templateContentMobileWrapper:{
    padding: '30px 22px 20px'
  },

  EmailTemplateRightPart: {
    width: '354px',
    height: '100%',
    backgroundColor:'#fff',
    paddingLeft:'24px',
    paddingRight:'27px',
    paddingTop:'70px'
  },
  EditTitle:{
    fontFamily:'Rubik',
    fontWeight:500,
    fontSize:'28px',
    lineHeight:'32px',
    color:'#3C3E49'
  },
  fieldTitle:{
    fontFamily:'Rubik',
    fontWeight:500,
    fontSize:'12px',
    lineHeight:'20px',
    color:'#000000',
    marginBottom:'3px'
  },
  fieldInput:{
    "& > div": {
      height: '66px',
      maxWidth:'100%',
      padding:'0px 12px',
      margin:'8px 0px 16px'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#D0D2DA', 
      },
      '&:hover fieldset': {
        borderColor: '#D0D2DA', 
      },
      '&.Mui-focused fieldset': {
        borderColor: '#D0D2DA', 
      },
      '&.Mui-error fieldset': {
        borderColor: 'red',
      },
    },
    '& .MuiInputBase-input':{
      fontFamily:'Rubik',
      fontWeight:400,
      fontSize:'14px',
      lineHeight:'21px',
      color:'#3C3E49'
    }
  },
  footerWrapper:{
    width:'100%',
    height:'150px',
    borderTop:'2px solid #000000',
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    padding: '20px 37px',
  },
  socialMediaContainer:{
    marginBottom:'22px',
    display: 'flex',
    justifyContent:'center',
    gap:'16px',
    '& img':{
      width:'24px',
      height:'24px'
    }
  },
  builderAddressText:{
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px',
    textAlign:'center',
    color:'#676B7E',
    marginBottom:'8px',
  },
  footerUnderLineTextWrapper:{
    display:'flex',
    justifyContent:'center',
    gap:'10px'
  },
  buttonUnderlineText:{
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px',
    color:'#676B7E',
    textDecoration : 'underline'
  }


}

const StyledComponent = withLayout(withLoader(withSnackBar(withHeaderBar(withStyles(useStyles)(EmailTemplatesCreate)))));
export default withWidth()(StyledComponent);
// Customizable Area End