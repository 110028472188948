import React from "react";

import {
  Container,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogContent,
  Button,
  // Customizable Area Start
  Theme,
  withStyles,
  Grid,
  TextField,
  Switch
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout";
import SubmitButtonPopup from "../../../components/src/SubmitButtonPopup.web";
import { homeScreenImage } from "./assets";
import CircularProgress from "@material-ui/core/CircularProgress";
// Customizable Area End

import ItemAvailabilityController, {
  Props,
  configJSON,
} from "./ItemAvailabilityController.web";

export default class ItemAvailability extends ItemAvailabilityController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        {
          this.state.isLoading ?
          <Box className={this.props.classes.loadingBox}>
            <CircularProgress />
          </Box> :
          <>
            <SubmitButtonPopup
              open={this.state.dataModified}
              close={this.closeSavePopup}
              submit={this.updateStoreAvailability}
            />
            <Box className={this.props.classes.MainBoxContainer}>
              <Grid container className={this.props.classes.HMainGrid}>
                  <Grid item className={this.props.classes.HMainGridItem}>
                    <Box className={this.props.classes.HIconBox}>
                        <img
                        src={homeScreenImage}
                        alt={''}
                        className={this.props.classes.HNoItemIcon}
                        />
                    </Box>
                    <Typography className={this.props.classes.HTitleText}>
                        {configJSON.titleText1}<span className="Highlight">{configJSON.titleText2}</span>
                    </Typography>
                    <Typography className={this.props.classes.HSubTitleText}>
                        {configJSON.subTitleText}
                    </Typography>
                  </Grid>
              </Grid>
              <Grid container className={this.props.classes.StoreActionGridContainer}>
                  <Grid item xs={12} className={this.props.classes.SwitchGridItem}>
                    <Box>
                      <Typography className={this.props.classes.OnlineOrderingText}>{configJSON.onlineOrdering}</Typography>
                    </Box>
                    <Box>
                        <Switch
                          classes={{
                            root: this.props.classes.SwitchRoot,
                            switchBase: this.props.classes.SwitchBase,
                            thumb: this.props.classes.SwitchThumb,
                            track: this.props.classes.SwitchTrack,
                            checked: this.props.classes.checked,
                          }}
                          value={this.state.switchState}
                          checked={this.state.switchState}
                          onChange={this.onChangeSwitch}
                        />
                    </Box>
                  </Grid>
                  {
                    !this.state.switchState && <Grid item xs={12}>
                    <TextField
                      value={this.state.inputValue}
                      type="text"
                      className={this.props.classes.MessageTextField}
                      onChange={this.onInputChange}
                      fullWidth
                      variant="outlined"
                      placeholder={configJSON.placeholderText}
                    />
                    <Typography className={this.props.classes.MessageLimitText}>{this.state.inputValue?.length}/100</Typography>
                  </Grid>
                  }
              </Grid>
            </Box>
          </>
        }
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyle = (theme: Theme) => ({
  MainBoxContainer:{
    padding:16,
    height:'100vh',
    overflow:'auto'
  },
  HMainGrid: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  HMainGridItem: {
      marginTop: "2%"
  },
  HIconBox: {
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: 16,
  },
  HTitleText: {
      fontFamily: "Rubik",
      fontSize: 26,
      fontWeight: 500,
      textAlign: "left" as const,
      lineHeight: 1.5,
      color: "#363636",
      margin: "0px 2px",
      "& .Highlight":{
        color: "#5500CC",
      }
  },
  HSubTitleText: {
      fontFamily: "Rubik",
      fontSize: 14,
      fontWeight: 400,
      textAlign: "left" as const,
      lineHeight: 1.3,
      color: "#676B7E",
      margin: "4px 8px 16px 2px",
  },
  HNoItemIcon: {
      height: 76,
      width: 76,
      objectFit: "contain" as const,
  },
  StoreActionGridContainer:{
    border:'1px solid #E2E8F0',
    gap:12,
    padding:"16px 20px",
    borderRadius:6,
    backgroundColor:"#FFFFFF",
    marginBottom:84
  },
  SwitchGridItem:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    "& .MuiSwitch-colorSecondary":{
      "&.Mui-checked":{
        "& + .MuiSwitch-track":{
          backgroundColor: "#6200EA",
          opacity: 1,
          border: "none",
        }
      }
    },
    "& .MuiSwitch-switchBase":{
      left:0,
      "&.Mui-checked":{
        left:1.5,
      },
    }
  },
  OnlineOrderingText:{
    fontFamily:'Rubik',
    fontWeight:500,
    fontSize:16,
    lineHeight:1.3,
    color:'#3C3E49'
  },
  SwitchRoot: {
    width: 48,
    height: 26,
    padding: 0,
    margin: 0,
  },
  SwitchBase: {
    padding: 2,
    "&$checked": {
      transform: "translateX(20px)",
      color: "#fff",
    },
    "&$focusVisible $thumb": {
      color: "#red",
      border: "6px solid #fff",
    },
  },
  SwitchThumb: {
    width: 23,
    height: 22,
  },
  SwitchTrack: {
    borderRadius: 26 / 2,
    border: "none",
    backgroundColor: "#E2E8F0",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {
  },
  MessageTextField:{
    "& .MuiOutlinedInput-root":{
      "&.Mui-focused":{
        "& .MuiOutlinedInput-notchedOutline":{
          border:'1px solid #5500CC',
        }
      }
    },
    "& .MuiOutlinedInput-input":{
      padding:'18px 12px',
      fontFamily:'Rubik',
      fontWeight:400,
      fontSize:14,
      lineHeight:1.3,
      color:'#64748B'
    },
    "& fieldset":{
      border:'1px solid #E2E8F0',
      borderRadius:3,
      top:0,
    }
  },
  MessageLimitText:{
    fontFamily:'Rubik',
    fontWeight:400,
    fontSize:12,
    lineHeight:1.3,
    color:'#64748B',
    marginTop:4,
    textAlign:'right' as const
  },
  loadingBox:{
    height:'100vh',
    display:'flex',
    justifyContent:'center',
    alignItems:'center' as const 
  }
})
const ItemAvailabilityLayout = withLayout(withStyles(webStyle)(ItemAvailability))
export { ItemAvailabilityLayout, ItemAvailability };
// Customizable Area End
