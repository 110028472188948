import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  categoriesItemDetails: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MenuItemDetailsPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCategoriesItemAPICallId: any;
  getLandingPageDataByIdApiCall: any;
  scrollCheck:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      categoriesItemDetails: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && !responseJson.error) {
        if (apiRequestCallId === this.getCategoriesItemAPICallId) {
          this.categoreisMenuItemResponceSuccess(responseJson)
        }      
      } else if (responseJson && responseJson.error) {
        if (apiRequestCallId === this.getCategoriesItemAPICallId) {
          this.showAlert("Error", responseJson.error);
        }      
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();
    this.getmMenuDatailsAPI(1);
  }
  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    let header = {
      "Content-Type": contentType,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  getmMenuDatailsAPI = async (id: any) => {
    this.getCategoriesItemAPICallId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: "bx_block_catalogue/catalogues/97",
    })
  }
  categoreisMenuItemResponceSuccess = (responseJson: any) => {
    this.setState({ categoriesItemDetails: responseJson.data })
  }
}

