// Customizable Area Start
import React from "react";
import CustomerEditWebController, { Props } from "./CustomerEditWebController";
import { Box, Switch, TextField, Tooltip, Typography, withStyles } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { backIcon, frame } from "./assets";
import SubmitButtonPopup from "../../../components/src/SubmitButtonPopup.web";
import { ErrorMessage, Form, Formik } from "formik";




const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    width:'275px',
    height:'104px',
    padding:'12px 16px',
    boxShadow: '0px 8px 32px 0px #0000000F',
    fontFamily:'Rubik',
    fontWeight:400,
    fontSize:'12px',
    lineHeight:'20px',
    backgroundColor:'#FFFFFF',
    color:'#64748B',
  },
}))(Tooltip);
// Customizable Area End

export class CustomerEdit extends CustomerEditWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  render() {
    // Customizable Area Start
      const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
      {this.state.showSubmit && 
      <SubmitButtonPopup
          data-test-id="customerSubmit"
          open={true}
          close={this.discardBtn}
          submit={() => {
            if(this.submitRef.current){
              this.submitRef.current.click()
            }
          }}
        />
      }
        <Box style={{ marginTop: "40px" }}>

          {/*  */}
          <Box className={classes.backIconContainer}>
            <img src={backIcon} alt="backIcon" className={classes.backIcon} onClick={()=>this.props.goToBack(false)} />
            <p className={classes.backText}>Back</p>
          </Box>
          {/*  */}
          <Box width='100%' display='flex' justifyContent='space-between' alignItems='flex-end'>
            <span
              data-test-id="title"
              className={`${classes.headerTitle}`}
            >
              Edit customer
            </span>
          </Box>
        </Box>
        {/* form */}
        <Box width='100%' marginTop='40px'>
          <Box className={classes.formWrapper}>
            <Typography className={classes.formHeader}>Customer details</Typography>

            <Formik
              data-test-id="formik"
              initialValues={this.state.formInitialValues}
              onSubmit={(values) => { this.editCustomer(values) }}
              validationSchema={this.formSchema}
              enableReinitialize={true}
            >
              {(props) => {
                const { values, handleBlur, errors, touched, handleChange } = props;
                return (
                  <Form>
                    {/* customer full name */}
                    <Box width="100%" marginTop="24px">
                      <Typography
                        component="h6"
                        variant="h6"
                        className={classes.fieldTitle}
                      >
                        Full name *
                      </Typography>
                      <TextField
                        name="full_name"
                        data-test-id="full_name"
                        className={`${classes.formInputField} `}
                        placeholder="Customer FullName"
                        variant="outlined"
                        fullWidth
                        value={values.full_name}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        onBlur={handleBlur}
                        onChange={(event)=>{
                          handleChange(event)
                          this.ShowSubmitPopup()
                        }}
                        error={Boolean(errors.full_name && touched.full_name)}
                      />
                      <ErrorMessage name="full_name">
                          {(msg:string) => (
                            <div style={{ color: "red", marginTop: "5px" }}>
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                    </Box>


                    {/* Email */}
                    <Box width="100%" marginTop="16px">
                      <Typography
                        component="h6"
                        variant="h6"
                        className={classes.fieldTitle}
                      >
                        Email
                      </Typography>
                      <TextField
                       name="email"
                        data-test-id="email"
                        className={`${classes.formInputField} `}
                        placeholder="Customer Email"
                        variant="outlined"
                        fullWidth
                        value={values.email}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        onBlur={handleBlur}
                        onChange={(event)=>{
                          handleChange(event)
                          this.ShowSubmitPopup()
                        }}
                        error={Boolean(errors.email && touched.email)}
                        

                      />
                      <ErrorMessage name="email">
                          {(msg:string) => (
                            <div style={{ color: "red", marginTop: "5px" }}>
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                    </Box>

                    {/* Full phone number */}
                    <Box width="100%" marginTop="16px">
                      <Typography
                        component="h6"
                        variant="h6"
                        className={classes.fieldTitle}
                      >
                        Contact Number
                      </Typography>
                      <TextField
                        name="phone_number"
                        data-test-id="phone_number"
                        className={`${classes.formInputField} ${classes.inputNumber}`}
                        placeholder="Customer Phone"
                        variant="outlined"
                        fullWidth
                        type="number"
                        value={values.phone_number}
                        onKeyDown={(e) => {
                          if(e.key === "Enter" || e.key === 'ArrowDown' || e.key === 'ArrowUp'){
                            e.preventDefault();
                          } 
                        }}
                        onBlur={handleBlur}
                        onChange={(event)=>{
                          handleChange(event)
                          this.ShowSubmitPopup()
                        }}
                        error={Boolean(errors.phone_number && touched.phone_number)}
                      />
                      <ErrorMessage name="phone_number">
                          {(msg:string) => (
                            <div style={{ color: "red", marginTop: "5px" }}>
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                    </Box>

                    {/* Opt-in */}
                    {/* Full phone number */}
                    <Box width="100%" marginTop="16px">
                      <Typography
                        component="h6"
                        variant="h6"
                        className={classes.fieldTitle}
                      >
                        Opt-in

                      </Typography>
                      <Switch
                        focusVisibleClassName={classes.focusVisible}
                        disableRipple
                        classes={{
                          root: classes.root,
                          switchBase: classes.switchBase,
                          thumb: classes.thumb,
                          track: classes.track,
                          checked: classes.checked,
                        }}
                        name="recive_update"
                        data-test-id={`recive_update`}
                        checked={values.recive_update}
                        onChange={(event)=>{
                          handleChange(event)
                          this.ShowSubmitPopup()
                        }}
                      />
                      <CustomTooltip placement="right-start" title="This signifies if customer has agreed to receive promotional communication. Please do not change unless you get customer consent.">
                        <img
                          src={frame}
                          alt=""
                          style={{
                            width: 16,
                            height: 16,
                            marginLeft: "0.5rem",
                          }}
                        />
                      </CustomTooltip>
                    </Box>

                    <button data-test-id="submitBtn" style={{display:'none'}} type="submit" ref={this.submitRef}>submit</button>

                  </Form>
                )
              }}

            </Formik>

            {/* ---- */}
          </Box>
        
        </Box>
      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start


export const useStyles : Record<string, CSSProperties> = {
  backIconContainer : {
    marginTop: '40px',
    width:'auto',
    marginBottom:'4px',
    height:'24px',
    display: 'flex',
    alignItems:'center',
    "@media (max-width: 480px)": {
      marginTop: '20px'
    }
  },
  formInputField: {
    "& > div": {
      height: '48px',
      width:'448px',
      maxWidth:'100%',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#D0D2DA', // Change this to your desired color
      },
      '&:hover fieldset': {
        borderColor: '#D0D2DA', // Change this to your desired hover color
      },
      '&.Mui-focused fieldset': {
        borderColor: '#D0D2DA', // Change this to your desired focus color
      },
      '&.Mui-error fieldset': {
        borderColor: 'red', // Change this to your desired focus color
      },
    },
  },
  backIcon:{
    cursor:'pointer',
    width:'12px',
    height:'12px'
  },
  backText:{
    fontFamily: 'Rubik',
    fontSize: '12px',
    fontWeight: 400,
    marginLeft:'4px',
    marginBottom:'0px !important',
    color : '#676B7E'
  },
  
  headerTitle: {
    fontFamily: "Rubik",
    fontWeight: 500,
    color: "#3C3E49",
    fontSize: '28px',
    llineHeight: '32px',
    width: 'auto',
    "@media (max-width: 480px)": {
      fontSize: '16px',
      lineHeight: '24px',
      width: '100%',
    }
  },
  inputNumber: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  formWrapper:{
    width:'802px',
    maxWidth:'100%',
    minHeight:'466px',
    borderRadius:'3px',
    border:'2px solid #ECEDF0',
    backgroundColor:'#FFFFFF',
    padding:'40px 43px'
  },
  formHeader:{
    fontFamily:'Rubik',
    fontWeight:500,
    fontSize:'20px',
    lineHeight:'28px',
    color:'#3C3E49'
  },
  fieldTitle: {
    color: "#000000",
    fontFamily: "Rubik",
    fontSize: "0.75rem",
    fontWeight: 500,
    lineHeight: "1.25rem",
    marginBottom:'8px',
  },
  
  
  track: {
    borderRadius: '40px',
    border: "none",
    backgroundColor: "#C0C3CE",
    opacity: 1,
  },
  root: {
    width: 48,
    height: 28,
    padding: 0,
  },
  checked: {},
  focusVisible: {},
  thumb: {
    width: 24,
    height: 24,
  },
  switchBase: {
    padding: 2,
    "&$checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + $track": {
        backgroundColor: "#6200EA",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#red",
      border: "6px solid #fff",
    },
  },
}


export default withStyles(useStyles)(CustomerEdit);



// Customizable Area End