// Customizable Area Start
import React from "react";
import { withStyles, createStyles, MenuItem, styled } from "@material-ui/core";
import CheckBox from "@material-ui/core/Checkbox";
import { Formik } from "formik";
import "../assets/promoCode.css";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import TextField from "../../ss-cms-common-components/src/TextField/TextField.web";
export const configJSON = require("./config.js");
import PromoCodeComponentController, { InputProps } from "./PromoCodeComponentController.web";

const DatePicker = styled(KeyboardDatePicker)(({ theme }) => ({
  "& .MuiOutlinedInput-adornedStart": {
    paddingLeft: "19px"
  },
  "& .MuiIconButton-root": {
    padding: 0,
  },
  "& .MuiInputAdornment-positionStart": {
    marginRight: "18px",
    marginTop: "-3px"
  },
  "& .MuiOutlinedInput-input": {
    padding: "7px 19px 12px 0px",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#000"
  },
}));
export class PromoCodeComponent extends PromoCodeComponentController {
  renderPromoCodeNameInput = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur
  }: InputProps) => {
    return (
      <TextField
        onChange={(event) => this.onTagsChange(event, setFieldValue)}
        error={this.getErrorStatus(errors, touched, configJSON.field_promoCodeName)}
        helperText={this.getHelperText(errors, touched, configJSON.field_promoCodeName)}
        value={values?.promoCodeName}
        fullWidth
        data-testid={configJSON.field_promoCodeName}
        label={configJSON.promoCodeName}
        required
        id={configJSON.field_promoCodeName}
        name={configJSON.field_promoCodeName}
        onBlur={handleBlur}
        InputProps={{
          classes: {
            input: this.props.classes.input,
            root: this.props.classes.inputfieledroot
          },
        }}
      />
    );
  };

  renderDescriptionInput = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur
  }: InputProps) => {
    return (
      <TextField
        onChange={(event) => this.onTagsChange(event, setFieldValue)}
        error={this.getErrorStatus(errors, touched, configJSON.field_description)}
        helperText={this.getHelperText(errors, touched, configJSON.field_description)}
        value={values?.description}
        fullWidth
        label={configJSON.label_description}
        required
        id={configJSON.field_description}
        name={configJSON.field_description}
        onBlur={handleBlur}
        InputProps={{
          classes: {
            input: this.props.classes.input,
            root: this.props.classes.inputfieledroot
          },
        }}
      />
    );
  };

  renderCodeAndDiscountType = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur
  }: InputProps) => {
    return (
      <div className="promo-code-details-input-row">
        <div className="promo-code-details-input-container-double">
          <TextField
            onChange={(event) => this.onTagsChange(event, setFieldValue)}
            error={this.getErrorStatus(errors, touched, configJSON.field_code)}
            helperText={this.getHelperText(errors, touched, configJSON.field_code)}
            value={values?.code}
            fullWidth
            label={configJSON.label_code}
            required
            id={configJSON.field_code}
            name={configJSON.field_code}
            onBlur={handleBlur}
            InputProps={{
              classes: {
                input: this.props.classes.input,
                root: this.props.classes.inputfieledroot
              },
            }}
          />
        </div>
        <div className="promo-code-details-input-container-double">
          <TextField
            onChange={(event) => this.onTagsChange(event, setFieldValue)}
            select
            error={this.getErrorStatus(errors, touched, configJSON.field_discountType)}
            helperText={this.getHelperText(errors, touched, configJSON.field_discountType)}
            value={values?.discountType}
            label={configJSON.discountType}
            required
            fullWidth
            className={configJSON.class_selectInput}
            id={configJSON.field_discountType}
            name={configJSON.field_discountType}
            onBlur={handleBlur}
            InputProps={{
              classes: {
                input: this.props.classes.input,
                root: this.props.classes.inputfieledroot,
              },
            }}
          >
            {this.state.discountTypes.length > 0 && this.state.discountTypes.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
    );
  };

  renderDiscountInput = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur
  }: InputProps) => {
    return (
      <TextField
        onChange={(event) => this.onTagsChange(event, setFieldValue)}
        error={this.getErrorStatus(errors, touched, configJSON.field_discount)}
        helperText={this.getHelperText(errors, touched, configJSON.field_discount)}
        value={values?.discount}
        label={configJSON.discount}
        required
        fullWidth
        id={configJSON.field_discount}
        name={configJSON.field_discount}
        onBlur={handleBlur}
        InputProps={{
          classes: {
            input: this.props.classes.input,
            root: this.props.classes.inputfieledroot
          },
        }}
      />
    );
  };

  renderValidFrom = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur
  }: InputProps) => {
    return (
      <div className="promo-code-details-input-container-double">
        <label className="promo-code-details-input-label">
          {configJSON.validFromText}
        </label>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            autoOk
            disablePast
            disableToolbar
            variant={configJSON.key_inline}
            inputVariant={configJSON.key_outlined}
            format={configJSON.dateFormat}
            placeholder={configJSON.dateFormatPlaceholder}
            name={configJSON.validFrom}
            InputAdornmentProps={{ position: configJSON.key_start }}
            value={values?.validFrom}
            onChange={(date) => this.handleOnChangeValidFrom(date, setFieldValue)}
            error={this.getErrorStatus(errors, touched, configJSON.field_validFrom)}
            helperText={this.getHelperText(errors, touched, configJSON.field_validFrom)}
            onBlur={handleBlur}
          />
        </MuiPickersUtilsProvider>
      </div>
    );
  };

  renderValidTo = (
    { values,
      errors,
      touched,
      setFieldValue,
      handleBlur
    }: InputProps) => {
    return (
      <div className="promo-code-details-input-container-double">
        <label className="promo-code-details-input-label">
          {configJSON.validToText}
        </label>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            autoOk
            disablePast
            disableToolbar
            variant={configJSON.key_inline}
            inputVariant={configJSON.key_outlined}
            format={configJSON.dateFormat}
            placeholder={configJSON.dateFormatPlaceholder}
            name={configJSON.validTo}
            value={values?.validTo}
            onChange={(date) => this.handleOnChangeValidTo(date, setFieldValue)}
            InputAdornmentProps={{ position: configJSON.key_start }}
            KeyboardButtonProps={{
              className: this.props.classes?.keyboardButton,
            }}
            error={this.getErrorStatus(errors, touched, "validTo")}
            helperText={this.getHelperText(errors, touched, "validTo")}
            onBlur={handleBlur}
          />
        </MuiPickersUtilsProvider>
      </div>
    );
  };

  renderCartValuesInputs = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur
  }: InputProps) => {
    return (
      <TextField
        onChange={(event) => this.onTagsChange(event, setFieldValue)}
        error={this.getErrorStatus(errors, touched, configJSON.field_minCartValue)}
        helperText={this.getHelperText(errors, touched, configJSON.field_minCartValue)}
        value={values?.minCartValue}
        fullWidth
        label={configJSON.label_minCartValue}
        required
        id={configJSON.field_minCartValue}
        name={configJSON.field_minCartValue}
        onBlur={handleBlur}
        InputProps={{
          classes: {
            input: this.props.classes.input,
            root: this.props.classes.inputfieledroot
          },
        }}
      />
    );
  };

  renderUserLimitInput = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur
  }: InputProps) => {
    return !values?.userLimitDisabled ? (
      <TextField
        disabled
        value={values?.userLimit}
        onChange={(event) => this.userLimit(event, setFieldValue)}
        error={this.getErrorStatus(errors, touched, configJSON.field_userLimit)}
        helperText={this.getHelperText(errors, touched, configJSON.field_userLimit)}
        id={configJSON.field_userLimit}
        name={configJSON.field_userLimit}
        InputProps={{
          classes: {
            input: this.props.classes.input,
            root: this.props.classes.inputfieledroot
          },
        }}
      />
    ) : (
      <TextField
        value={values.userLimit}
        onChange={(event) => this.userLimit(event, setFieldValue)}
        error={this.getErrorStatus(errors, touched, configJSON.field_userLimit)}
        helperText={this.getHelperText(errors, touched, configJSON.field_userLimit)}
        onBlur={handleBlur}
        id={configJSON.field_userLimit}
        name={configJSON.field_userLimit}
        InputProps={{
          classes: {
            input: this.props.classes.input,
            root: this.props.classes.inputfieledroot
          },
        }}
      />
    );
  };

  renderLimitTo = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur
  }: InputProps) => {
    return (
      <div
        className="promo-code-details-checkbox-row mt-30"
      >
        <CheckBox
          checked={values?.userLimitDisabled}
          onChange={(event) =>
            this.handleChangeUserLimitDisabled(event, setFieldValue)
          }
          id={configJSON.field_userLimitDisabled}
          name={configJSON.field_userLimitDisabled}
        />
        <div>{configJSON.limitTo}</div>
        <div className="promo-code-details-user-limit-input-container">
          {this.renderUserLimitInput({
            values,
            errors,
            touched,
            setFieldValue,
            handleBlur
          })}
        </div>
        <div>{configJSON.usePerCustomer}</div>
      </div>
    );
  };

  render() {
    const { formRef, promoCodeData } = this.props;
    let initialRecord: any = {
      promoCodeName: promoCodeData?.promoCodeName ? promoCodeData.promoCodeName : "",
      description: promoCodeData?.description ? promoCodeData.description : "",
      code: promoCodeData?.code ? promoCodeData.code : "",
      discountType: promoCodeData?.discountType ? promoCodeData.discountType : "flat",
      discount: promoCodeData?.discount ? promoCodeData.discount : "",
      minCartValue: promoCodeData?.minCartValue ? promoCodeData.minCartValue : "",
      validFrom: promoCodeData?.validFrom ? promoCodeData.validFrom : "",
      validTo: promoCodeData?.validTo ? promoCodeData.validTo : "",
      userLimit: promoCodeData?.userLimit ? promoCodeData.userLimit : null,
    }
    return (
      <Formik
        innerRef={formRef}
        initialValues={initialRecord}
        validationSchema={this.Schema}
        onSubmit={(values: any) => {
          this.handleSubmit(values);
        }}
      >
        {(formikProps) => {
          const { values, errors, touched, handleBlur, setFieldValue } =
            formikProps;
          return (
            <form autoComplete="off" noValidate>
              <div className="add-container">
                <div className="promo-code-details-container">
                  <label className="promo-code-details-title">
                    {configJSON.promoCodeDetails}
                  </label>
                  {this.renderPromoCodeNameInput({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleBlur
                  })}
                  <div style={{ height: "47px" }} />
                  {this.renderDescriptionInput({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleBlur
                  })}
                  <div style={{ height: "55px" }} />
                  {this.renderCodeAndDiscountType({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleBlur
                  })}
                  <div style={webStyles.gap} />
                  {this.renderDiscountInput({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleBlur
                  })}
                </div>
                <div className="promo-code-details-container mb-70">
                  <label className="promo-code-details-title">
                    {configJSON.restrictions}
                  </label>
                  <div className="promo-code-details-input-row">
                    {this.renderValidFrom({
                      values,
                      errors,
                      touched,
                      setFieldValue,
                      handleBlur
                    })}

                    {this.renderValidTo({
                      values,
                      errors,
                      touched,
                      setFieldValue,
                      handleBlur
                    })}
                  </div>
                  <div style={webStyles.gap} />
                  {this.renderCartValuesInputs({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleBlur
                  })}
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }
};

const webStyles = {
  gap: { height: "31px" }
}

export const styles: any = () =>
  createStyles({
    input: {
      lineHeight: "24px",
      padding: "7px 19px 12px 19px",
      borderRadius: '3px',
      position: 'relative',
      backgroundColor: '#FFF',
      color: '#000',
      fontSize: '14px',
      width: '100%',
    },
    inputfieledroot: {
      'label + &': {
        marginTop: 36,
      },
    },
  });

export default withStyles(styles)(withHeaderBar(PromoCodeComponent));

// Customizable Area End
