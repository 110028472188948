// Customizable Area Start
import React from "react";
import { CatalogueFormatted } from "./Types";

interface Props {
  data: CatalogueFormatted;
  onDelete: (id: string) => void;
  onEdit: (product: CatalogueFormatted) => void;
}

export default class CatalogueItemController extends React.Component<Props> {
  handleDelete = () => {
    this.props.onDelete(this.props.data.id);
  };
}
// Customizable Area End