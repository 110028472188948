// Customizable Area Start
import React, { Component, useEffect, useRef, useState } from "react";
import parser from "html-react-parser";
import { CKEditor as CKEditorV4, CKEditorEventHandler } from "ckeditor4-react";
import "./styles.css";

export type onMaximizeType = CKEditorEventHandler<"maximize">;

interface Props {
  value: string;
  onChange: (newValue: string, plainText: string) => void;
  onMaximize?: CKEditorEventHandler<"maximize">;
  height?: string | number;
  error?: boolean;
  EditerRefresh: boolean
}

interface Editer{
  setData:(value:string)=>void
}

let Editor = (props:Props)=>{

  const [editor, setEditor] = useState<Editer>()

  useEffect(() => {
    if (editor && props.EditerRefresh) {
        editor.setData(props.value);
    }
}, [props.EditerRefresh]);

  let handleChange: CKEditorEventHandler<"change"> = (e) => {
      props.onChange(
        e.editor.getData().replace(/[\r\n\t]/gm, ""),
        e.editor.document.getBody().getText()
      );
      };

  let onBeforeLoad: CKEditorEventHandler<"loaded">  = (e) => {
    setEditor(e.editor);
}


    return (
      <>
        {props.value  ? (
          <CKEditorV4
            config={{
              allowedContent: true,
              height: props.height || "auto",
              fullPage: true,
              resize_enabled: true,
              toolbar: [
                ['Source', '-', 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo', '-', 'Link', 'Unlink', 'Anchor'],
                ['Image', 'Flash', 'Table', 'HorizontalRule', 'SpecialChar', '-', 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
                ['Styles', 'Format', 'Font', 'FontSize'],
                ['TextColor', 'BGColor', '-', 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat']
              ],
            }}
            style={{
              borderColor: props.error ? "red" : undefined,
            }}
            editorUrl="https://cdn.ckeditor.com/4.15.0/full/ckeditor.js"
            initData={props.value}
            onChange={handleChange}
            onLoaded={onBeforeLoad}
            />
        ) : null}
      </>
    );
}

interface PreviewProps {
  value?: string;
  className?: string;
  [key: string]: any;
}

export const Preview = ({ value, className = "", ...props }: PreviewProps) => {
  return (
    <div className={"wysiwyg-editor-preview " + className} {...props}>
      {parser(value || "")}
    </div>
  );
};

export default Editor

// Customizable Area End