import * as React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import "../assets/editImage.css";
import withSnackBar from "../../ss-cms-common-components/src/HOC/withSnackBar.Web";
import UploadBox from "../../ss-cms-common-components/src/UploadBox/UploadBox.Web";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import EditImageController from "./EditImageController.web";
import AddCategoryProvider from "../../ss-cms-common-components/src/Dialog/DialogContext";
import SearchChange from "./SearchChange.web";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout"
import { addIcon } from "./assets";
export const configJSON = require("./config.js");

export class EditImage extends EditImageController {
  renderTopSection = () => {
    return (
      <div className="top-container-addctg">
        <Box className="top-container-box">
          <div className="subheading-addctg">{configJSON.imageDetails}</div>

          <div className="label-addctg">{configJSON.imageName}</div>

          <SearchChange
            onTextChange={this.onCategoryChange}
            value={this.state.imageName}
            error={this.state.categoryError ? true : false}
            helperText={
              this.state.categoryError ? this.state?.categoryError : ""
            }
          />

          <div className="d-flex flex-column pt-1">
            <div className="label-image">{configJSON.image}</div>
            <div className="message-image-addctg">
              {configJSON.imageDescription}
            </div>

            {!!this.state.selectedImage ? (
              <div className="image-upload-selected-image-wrapper">
                <img
                  className="image-upload-selected-image"
                  alt="selected image"
                  src={this.state.selectedImage}
                />
                <Button
                  data-testid="remove-logo"
                  className="image-upload-selected-image-remove-basic"
                  onClick={this.removeLogoImg}
                >
                  {configJSON.remove}
                </Button>
              </div>
            ) : (
              <UploadBox
                name="image"
                uploadIcon={addIcon}
                height="180px"
                width="100%"
                margin="8px 0 20px 0"
                multiple
                uploadMsg={
                  <div className="image-upload-message">
                    {configJSON.addImage}
                  </div>
                }
                sizeMsg={
                  <div className="image-upload-size-hint">
                    {configJSON.maxMb}
                  </div>
                }
                messageMargin="0"
                onSelectFile={this.selectImageFile}
                error={this.state.categoryImageError ? true : false}
                helperText={
                  
                  <p  style={{color: '#D50000',fontSize: '0.75rem'}} id="standard-size-small-helper-text">{this.state?.categoryImageError}</p>
                  
                }
              />
            )}
          </div>
        </Box>
      </div>
    );
  };

  render() {
    return (
      <AddCategoryProvider>
        <div className="container-addctg">
          <Typography
            className="backButton"
            onClick={this.handleBackButtonClick}
            component="div"
          >
            <i className="icon-arrow-left" />
            {configJSON.back}
          </Typography>

          <div className="title-addctg">{configJSON.textProductImage}</div>
          {this.renderTopSection()}
        </div>
      </AddCategoryProvider>
    );
  }
}

export default withLayout(
  withLoader(withSnackBar(withHeaderBar(withDialog(EditImage))))
);
