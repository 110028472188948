import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { dialogBoxProps } from "../../ss-cms-common-components/src/HOC/withDialog.web";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import {
  Tab,
  Meta,
  Catalogue,
  CatalogueFormattedMap,
  CatalogueFormatted,
} from "./Types";
import moment from "moment";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";
import {Scrollbars} from "react-custom-scrollbars";
import {createRef} from "react"
import {generateRequestMessage, showError, isTokenExpired, clearStorageData} from "../../ss-cms-common-components/src/Utilities/Utilities";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { emitter } from "../../../../packages/blocks/ss-cms-common-components/src/Layout/Layout";

export const configJSON = require("./config.js");

export type Props = DialogProps &
  dialogBoxProps &
  withHeadeActionBarProps &
  withLoaderProps & {
    navigation: {
      navigate: (to: string, params: object) => void;
      getParam: (param: string) => string;
      goBack: () => void;
    };
    id: string;
    // Customizable Area Start
    classes?: {};
    onTextChange?: (value: string) => void;
    // Customizable Area End
  };

export interface S {
  // Customizable Area Start
  pageInitialized: boolean;
  page: number;
  search: string | null | undefined;
  selectedTab: number;
  selectedViewType: number;
  headerTabs: Tab[];
  allCatalogues: CatalogueFormatted[];
  catalogues: CatalogueFormattedMap;
  meta: Meta;
  rowPerPage: number;
  checkedImageCatalogueList: CatalogueFormatted[];
  value: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export const Endpoint = configJSON.endPoint;

class ImageCatalogueController extends BlockComponent<Props, S, SS> {
  scrollRef:  React.RefObject<Scrollbars>;
  initMessageId: string = "";
  deleteMessageId: string = "";
  deleteItemId: string = "";
  getOrderNotificationId: string = ""
  postBulkDeleteCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.scrollRef = createRef();
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      page: 1,
      selectedTab: 0,
      selectedViewType: 0,
      pageInitialized: false,
      search: "",
      headerTabs: [
        {
          tab_name: "Images",
          count: 0,
        } as Tab,
      ],
      allCatalogues: [],
      catalogues: {},
      meta: {},
      rowPerPage: 30,
      checkedImageCatalogueList: [],
      value: "",
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.initPage();
    // Customizable Area Start
    this.getCurrentViewType()
    this.getOrderNotification()
    // Customizable Area End
  }

  // Customizable Area Start

  async getCurrentViewType() {
    const currentViewType =  await getStorageData("selectedViewType")
    this.setState({ selectedViewType: Number(currentViewType) || 0 })
  }

  scrollToTop = (ref?: React.RefObject<Scrollbars>) => {
    (ref || this.scrollRef).current?.scrollToTop();
  }

  handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ value: e.target.value });
  }

  handleSelectedViewTypeChange = (
    event: React.ChangeEvent<{}>,
    selectedViewType: number
  ) => {
    setStorageData("selectedViewType", selectedViewType.toString())
    this.setState({ selectedViewType });
  };

  handleNavigate = (routeTo?: string) => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(getName(MessageEnum.NavigationTargetMessage), "AdminImageCatalogueBulkUpload");
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  handlePageChange = (page: number) => {
    this.scrollToTop();
    this.setState({ page }, () => this.initPage());
  };

  checkedItems = (item: CatalogueFormatted[]) => {
    this.setState({ ...this.state, checkedImageCatalogueList: item });
  };

  handleDeleteModal = () => {
    this.props.setDialogState(true, {
      title: configJSON.dialogTitle,
      message: configJSON.deleteModalMessage,
      confirmColor: configJSON.deleteModaConfirmColor,
      confirmBackground: configJSON.deleteModalConfirmBackground,
      confirmHoverBackground: configJSON.deleteModalConfirmHoverBackground,
      onSubmit: () => this.handleBulkDeleteZipcodeAPI(),
    });
  };

  goToEdit = (product: CatalogueFormatted) => {
    this.props.navigation.navigate(configJSON.routeAdminEditImage, {id: product.id})
  };

  handleBulkDeleteZipcodeAPI = async () => {
    const { checkedImageCatalogueList } = this.state;
    let onlyIDs = checkedImageCatalogueList?.map((element: { id: string }) => +element.id);
    await this.postBulkDelete(onlyIDs);
  };

  postBulkDelete = async (data: number[]) => {
    this.props.showLoader();
    const requestMessage = await generateRequestMessage(
      configJSON.postBulkDeleteApi,
      configJSON.deleteApiMethod
    );
    let body = {
      ids: data,
    };
    this.postBulkDeleteCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  initPage = async () => {
    this.props.showLoader();
    const requestMessage = await generateRequestMessage(
      `${Endpoint}?page=${this.state.page}&per_page=${this.state.rowPerPage}`,
      configJSON.getApiMethod
    );
    this.initMessageId = requestMessage.messageId;
    this.send(requestMessage);
  };

  handleDelete = async (itemId: string) => {
    this.props.showLoader();

    const requestMessage = await generateRequestMessage(
      `${Endpoint}/${itemId}`,
      configJSON.deleteApiMethod
    );
    this.deleteItemId = itemId;
    this.deleteMessageId = requestMessage.messageId;
    this.send(requestMessage);
  };

  receive = (from: string, message: Message) => {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId == this.getOrderNotificationId) {
         emitter.emit("changeNotiNumber", responseJson.new_order_count)
      }
      this.messageInitCall(apiRequestCallId, responseJson);
      this.messagePostBulkDeleteCall(apiRequestCallId, responseJson);
      this.messageDeleteCall(apiRequestCallId, responseJson);
    }
  };

  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  messageInitCall = (apiRequestCallId: string, responseJson: { errors?: string, error?: string, data: Catalogue[], meta: Meta }) => {
    if (this.initMessageId === apiRequestCallId) {
      this.setState({ pageInitialized: true });
      if (responseJson?.errors) {
        return this.handleError(configJSON.errorOnInitialization, responseJson);
      }
      const allCatalogues = (responseJson.data as Catalogue[]).map(
        (catalogue) => {
          return ({
            id: catalogue.id,
            image: catalogue.attributes.url,
            name: catalogue.attributes.name,
            updated_at: moment(catalogue.attributes.updated_at).format("LLL"),
          } as unknown) as CatalogueFormatted;
        }
      );
      const catalogues = {} as CatalogueFormattedMap;
      allCatalogues.forEach((catalogue) => {
        catalogues[catalogue.id] = catalogue;
      });

      this.setState({
        allCatalogues,
        meta: responseJson.meta,
        headerTabs: [
          {
            tab_name: configJSON.productImages,
            count: responseJson?.meta?.pagination?.total_count,
          } as Tab
        ],
        catalogues,
      });
      this.props.hideLoader();
    }
  };

  messagePostBulkDeleteCall = (apiRequestCallId: string, responseJson: { message?: string, errors?: string, error?: string }) => {
    if (this.postBulkDeleteCallId === apiRequestCallId) {
      this.props.hideLoader();

      if (responseJson?.message) {
        this.props.showHeaderBar({ type: "success", message: "The image is deleted successfully" });
        this.setState({ ...this.state, checkedImageCatalogueList: [] });
        this.initPage();
      } else if (responseJson?.errors) {
        return this.props.showHeaderBar({
          message: configJSON.errorMessage,
          type: "error",
        });
      }
    }
  };

  messageDeleteCall = (apiRequestCallId: string, responseJson: { errors?: string, error?: string }) => {
    if (this.deleteMessageId === apiRequestCallId) {
      this.props.hideLoader();
      if (responseJson.errors) {
        return this.handleError(configJSON.errorOnDelete, responseJson);
      } else {
        this.props.showHeaderBar({ type: "success", message: "The image is deleted successfully" });
      }
      this.initPage();
    }
  };

  handleError = (title: string, responseJson: { errors?: string, error?: string }) => {
    showError(
      title,
      responseJson,
      this.props.hideLoader,
      this.props.setDialogState
    );
  };

  getOrderNotification = async () => {
    const token = await getStorageData("admintoken");
    const header = {
      "Content-Type": 'application/json',
      token
    };
    const requestSAMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getOrderNotificationId = requestSAMessage.messageId;
    requestSAMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "/bx_block_order_management/orders/new_order_count");
    requestSAMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestSAMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestSAMessage.id, requestSAMessage);
  }
  // Customizable Area End
}

export default ImageCatalogueController;
