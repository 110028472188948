// Customizable Area Start
// @ts-nocheck
import React from "react";
import { Dialog, TextField, Typography, Box, styled } from "@material-ui/core";
import withStyles, { CSSProperties } from "@material-ui/core/styles/withStyles";
import VariantsController from "./VariantsController.web";

export const configJSON = require("./config");
import AddEditVariant from "./AddNewVariant.web";
const CloseIcon = require("../../../components/assets/closeIcon.png");
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

const classes = {
  form: {
    margin: "40px",
    fontFamily: "Inter",
  },
  titleField: {
    width: "604px",
    height: "48px",
    borderRadius: "3px",
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    "& fieldset": {
      top: "0px",
    },
  },

  titleText: {
    fontFamily: "Rubik",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "20px",
  },
  placeholderText: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
  },
  textHint: {
    color: "#83889E",
    lineHeight: "18px",
    fontSize: "12px",
    fontFamily: "Inter",
  },
  dropDownTextDefault: {
    fontFamily: "Rubik",
    fontWeight: 400,
    lineHeight: "24px",
    fontSize: "14px",
    color: "#6200EA",
    borderRadius: "3px",
    border: "1px solid #D0D2DA",
  },
  dropDownText: {
    fontFamily: "Rubik",
    fontWeight: 400,
    lineHeight: "22px",
    fontSize: "14px",
  },
};
const useStyles: Record<string, CSSProperties> = {
  input: {
    "& fieldset": {
      top: "0px",
    },
  },
  select: {
    "& ul": {},
    "& li": {
      fontFamily: "Rubik",
      fontSize: "14px",
      lineHeight: "22px",
      fontWeight: 400,
    },
  },
  "& input, & textarea, & div": {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
  },
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "42.75rem",
    height: "437px",
    fontFamily: "Inter",
  },
  ".MuiTypography-root": {
    fontFamily: "Inter",
  },
}));

export class AddVariantSearch extends VariantsController {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    this.state = {
      ...this.state,
      isOpen: false,
      isEdit: false,
      variantName: "",
      searchSelectedVariant: null,
      productId: props.productId,
    };
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.searchVariant(true);
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const filter = createFilterOptions();
    if (this.state.isOpen) {
      return (
        <AddEditVariant
          data-test-id="addnewvariantTest"
          searchSelectedVariant={this.state.searchSelectedVariant}
          isEditStatus={true}
          isEdit={this.state.isEdit}
          handleClose={(apiresponse) => {
            this.setState({
              isOpen: false,
              variantName: "",
              searchSelectedVariant: null,
              dropdownFocus: false,
            });
          }}
          showCreateSuccessPopup={() => {}}
          isInventoryAdd={this.props.isInventoryAdd}
          showEditeSuccessPopup={() => {}}
          variantName={this.state.variantName}
          modifyDatapass={this.props.onSubmit}
        />
      );
    }
    return (
      <BootstrapDialog
        onClose={() => {
          this.setState({
            dropdownFocus: false,
            variantName: "",
          });
          this.props.closeSearch();
        }}
        aria-labelledby="customized-dialog-title"
        data-test-id="bootstrapdialogueTest"
        open={this.props.parentState.searchVariantModalOpen}
        fullWidth
        maxWidth="md"
      >
        <img
          src={CloseIcon}
          data-test-id="closeIcontest"
          onClick={() => {
            this.setState({
              dropdownFocus: false,
              variantName: "",
            });
            this.props.closeSearch();
          }}
          alt="close"
          style={{
            width: 24,
            height: 24,
            color: "#000000",
            position: "relative",
            left: "-40px",
            alignSelf: "end",
            top: "40",
            cursor: "pointer",
          }}
        />

        <div style={classes.form}>
          <Typography
            component="h5"
            style={{
              fontFamily: "Rubik",
              fontWeight: "500",
              fontSize: "26px",
              paddingBottom: "5px",
              lineHeight: "30.81px",
            }}
          >
            Variants
          </Typography>
          <Typography style={{ ...classes.textHint, marginBottom: "30px" }}>
            Setup additional customization that can be applied to your menu
            Item.
          </Typography>

          <Typography variant="h6" component="h6" style={classes.titleText}>
            Title *
          </Typography>
          <Typography style={{ ...classes.textHint, marginBottom: "12px" }}>
            Search for existing
          </Typography>
          <Autocomplete
            name="title"
            data-testId="variant_title"
            options={this.state.searchVariants}
            value={this.state.variantName}
            inputValue={this.state.inputValueVariant}
            onInputChange={(e, val) => {
              this.setState({ inputValueVariant: val });
            }}
            onChange={(e, val) => {
              if (val?.variant?.id) {
                this.props.closeSearch();
                this.setState({
                  searchSelectedVariant: val,
                  isOpen: true,
                  isEdit: true,
                });
              }
              if (val?.variant?.id == "") {
                this.props.closeSearch();
                this.setState({
                  variantName: val?.variant?.title ?? "",
                  isOpen: true,
                  isEdit: false,
                });
              }
            }}
            className={classes.titleField}
            disableCloseOnSelect={true}
            getOptionLabel={(option) => {
              if (typeof option === "string") {
                return option;
              }
              if (option?.variant?.id == "") {
                return (
                  <span
                    style={{
                      fontFamily: "Rubik",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "19px",
                      color: "#5500CC",
                    }}
                  >
                    {option?.variant?.title}
                  </span>
                );
              }
              return option?.variant?.title;
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;
              const isExisting = options.some(
                (option) =>
                  inputValue.toLocaleLowerCase().trim() ===
                  option?.variant?.title.toLocaleLowerCase().trim()
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push({
                  variant: {
                    id: "",
                    title: inputValue,
                  },
                });
              }
              return filtered;
            }}
            freeSolo
            renderOption={(option) =>
              option?.variant?.id ? (
                <span
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "22px",
                    color: "#475569",
                  }}
                >
                  {option?.variant?.title}
                </span>
              ) : (
                <span
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "19px",
                    color: "#5500CC",
                  }}
                >
                  + Add &lt;{option?.variant?.title}&gt;
                </span>
              )
            }
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.placeholderText}
                placeholder="Title"
                variant="outlined"
              />
            )}
            onFocus={() => {
              this.searchVariant(true);
            }}
          />
        </div>
      </BootstrapDialog>
    );
    // Customizable Area End
  }
}
export default withStyles(useStyles)(AddVariantSearch);
// Customizable Area End
