Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ItemAvailability";
exports.labelBodyText = "ItemAvailability Body";
exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";
exports.putApiMethodType = "PUT";
exports.getItemsURL = exports.cataloguesDataURL =
  "bx_block_item_availability/item_availabilities";
exports.cataloguesItemDataURL =
  "bx_block_item_availability/item_availabilities";
exports.loginInApiEndPoint = "bx_block_login/logins";
exports.btnExampleTitle = "CLICK ME";
exports.storeAvailabilityEndPoint = "bx_block_payment_admin/payment_option_methods"
exports.onlineOrdering="Online ordering";
exports.titleText1 = "Welcome to the ";
exports.titleText2 = "Admin Panel!";
exports.subTitleText="From menu creation to order management, this is where the magic happens. Set up payment and delivery options for a seamless customer experience, and switch on online ordering when you're ready. Use the navigation on the left to complete set up.";
exports.placeholderText="Add a friendly message to display for your customers when you are closed for orders.";
// Customizable Area End
