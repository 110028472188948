// Customizable Area Start
import React from "react";
import PaymentMethodsController from "./PaymentMethodsController.web";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import "../assets/styles.css";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import { Switch, withStyles } from "@material-ui/core";
const configJSON = require("./config");

interface ClassesCustomSwitchType {
  root: string;
  switchBase: string;
  thumb: string;
  track: string;
  checked: string;
  focusVisible: string;
}

export type CustomSwitchProps = {
  classes: ClassesCustomSwitchType;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
}

const CustomSwitch = withStyles((theme) => ({
  root: {
    width: 48,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 2,
    "&$checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + $track": {
        backgroundColor: "#6200EA",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#red",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 23,
    height: 22,
  },
  track: {
    borderRadius: 26 / 2,
    border: "none",
    backgroundColor: "#C0C3CE",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }: CustomSwitchProps) => {
  return (
    <Switch
      focusVisibleClassName={classes!.focusVisible}
      disableRipple
      classes={{
        root: classes!.root,
        switchBase: classes!.switchBase,
        thumb: classes!.thumb,
        track: classes!.track,
        checked: classes!.checked,
      }}
      {...props}
    />
  );
});

export class PaymentMethods extends PaymentMethodsController {
  renderSwitchItem({ title, description, onChange, status, testId }: {
    title: string;
    description: string;
    onChange: () => void;
    status: boolean;
    testId: string
  }) {
    return(
      <div style={webStyles.viewSwitch}>
        <div style={webStyles.viewContent}>
          <span style={webStyles.titleSwitch}>{title}</span>
          <span style={webStyles.descriptionSwitch}>{description}</span>
        </div>
        <CustomSwitch
          checked={status}
          onChange={() => {onChange()}}
          name={testId}
        />
      </div>
    )
  }

  render() {
    return (
      <div>
        <div className="payment-method-container">
          <div className="payment-method-title-container">
            <label className="payment-method-title">{configJSON.enablePaymentMode}</label>
          </div>
          <div className="payment-method-items-container">
            {this.renderSwitchItem({
              title: configJSON.cashSwitchTitle,
              description: configJSON.cashSwitchDescription,
              onChange: () => this.onChangeCash(),
              testId: "cashSwitch",
              status: this.state.cashValue
            })}
            <div style={{ height: "10px" }}/>
            {this.renderSwitchItem({
              title: configJSON.onlineSwitchTitle,
              description: configJSON.onlineSwitchDescription,
              onChange: () => this.onChangeOnline(),
              testId: "onlineSwitch",
              status: this.state.onlineValue
            })}
            <div style={{ height: "28px" }}/>
            <div style={webStyles.inputView}>
              <span style={{ ...webStyles.titleSwitch, marginLeft: "24px" }}>{configJSON.messageTitle}</span>
              <div>
                <textarea
                  id="message"
                  test-id='message'
                  placeholder={configJSON.messagePlaceholder}
                  value={this.state.message}
                  onChange={(event) => this.updateMessage(event)}
                  style={webStyles.input}
                  maxLength={500}
                />
                <div id="counter" style={webStyles.textCounter}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const webStyles = {
  textCounter: {
    color: "#334155",
    fontSize: "12px",
    lineHeight: "18px",
    fontFamily: "Inter",
    justifyContent: "flex-end",
    display: "flex"
  },
  inputView: {
    width: "60%",
    display: 'flex',
    flexDirection: "column",
  } as React.CSSProperties,
  input: {
    marginTop: "4px",
    marginBottom: "2px",
    color: "#64748B",
    fontSize: "14px",
    lineHeight: "22px",
    border: "2px solid #ECEDF0",
    borderRadius: "3px",
    backgroundColor: "#fff",
    fontFamily: "Rubik",
    padding: "12px 24px",
    width: "100%",
    height: "171px"
  },
  descriptionSwitch: {
    fontSize: "14px",
    lineHeight: "24px",
    fontFamily: "Rubik",
    color: "#64748B"
  },
  titleSwitch: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 500,
    fontFamily: "Rubik",
    color: "#3C3E49"
  },
  viewSwitch: {
    display: 'flex',
    width: "60%",
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "space-between",
    border: "2px solid #ECEDF0",
    borderRadius: "3px",
    padding: "30px 42px 30px 24px"
  },
  viewContent: {
    display: "flex",
    flexDirection: "column",
  } as React.CSSProperties
}

export default withDialog(withLoader(PaymentMethods));
// Customizable Area End