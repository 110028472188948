// Customizable Area Start
import * as React from "react"

import {
  Box,
  TextField,
  Button,
  IconButton,
  Typography,
  Icon,
  FormHelperText,
} from "@material-ui/core"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Editor from "../../ss-cms-common-components/src/Editor/Editor"

import CustomAutoCompletPicker from "./CustomAutoCompletePicker.web"
import "../assets/createstatic.css"
import withSnackBar from "../../ss-cms-common-components/src/HOC/withSnackBar.Web"
import IOSSwitch from "../../ss-cms-common-components/src/IOSSwitch/IOSSwitch"
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web"
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web"
import CreateStaticPageController from "./CreateStaticPageController.web"
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog"
import { Scrollbars } from "react-custom-scrollbars"
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout"
import { AddIcon, BackIcon, DeleteIcon } from "./assets"
const configJSON = require("./config")

export class CreateStaticPage extends CreateStaticPageController {
  renderRightSection = () => {
    return (
      <div className="container-right-side-panel">
        <div className="container-status">
          <label className="label-status">{configJSON.status}</label>
          <div>
            {this.state.help_center_type === "other" ? (
              <div className="pt-2">
                <FormControlLabel
                  control={
                    <IOSSwitch
                      name="checkedB"
                      isDisabled={true}
                      toggleEvent={this.handleActiveChange}
                      isChecked={
                        this.state.status
                      }
                    />
                  }
                  label=""
                />
                <label>
                  {this.state.status
                    ? configJSON.published
                    : configJSON.notPublished}
                </label>
              </div>
            ) : (
              <div className="pt-2">
                <FormControlLabel
                  control={
                    <IOSSwitch
                      name="checkedB"
                      toggleEvent={this.handleActiveChange}
                      isChecked={
                        this.state.status
                      }
                    />
                  }
                  label=""
                />
                <label>
                  {this.state.status
                    ? configJSON.published
                    : configJSON.notPublished}
                </label>
              </div>
            )}
          </div>
          <div className="pt-2">
            <label className="txt-sub-status">
              {this.state.status ? configJSON.textPageWillBeVisible : configJSON.textPageWillNotVisible}
            </label>
          </div>
        </div>
      </div>
    )
  }

  renderLeftSection = () => {
    const { editorStateDescription } = this.state
    return (
      <div className="static_page_view_container">
        <Box
          p={5}
          style={{
            background: "#fff",
            border: "2px solid #ECEDF0",
            borderRadius: "3px",
            padding: "40px",
          }}
        >
          <div className="static-page-title-create-static">
            {configJSON.staticPage}
          </div>
          <div className="static_pate_type">{configJSON.staticPageType} *</div>
          <CustomAutoCompletPicker
            isDisabled={this.state.pageId ? true : false}
            handleInputChangeClick={this.handlePageTypeChange}
            value={this.getFromPageNo(this.state.help_center_no)}
            isError={this.state?.helpCenterTypeError ? true : false}
            isHelperText={
              this.state?.helpCenterTypeError
                ? this.state?.helpCenterTypeError
                : ""
            }
          />
          <div>
            <div className="static_pate_type">{configJSON.title} *</div>

            <TextField
              id="standard-size-small"
              size="small"
              variant="outlined"
              fullWidth
              onChange={this.onTitleChange}
              value={this.state.title}
              required
              error={this.state?.titleError ? true : false}
              helperText={
                this.state?.titleError ? this.state?.titleError : ""
              }
            />

            {this.state.help_center_type === "other" ? null : (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 8,
                    marginTop: 25,
                  }}
                >
                  <div className="answer-label">{configJSON.description} *</div>
                </div>
                <Editor
                  editorState={editorStateDescription}
                  onEditorStateChange={this.handleEditorStateChangeDescription}
                  isErrorShow={
                    this.state.editorStateDescriptionError ? true : false
                  }
                  className="editor-border"
                />
                {this.state.editorStateDescriptionError ? (
                  <FormHelperText classes={{ root: "helper-text", error: "" }}>
                    {this.state.editorStateDescriptionError}
                  </FormHelperText>
                ) : null}
              </>
            )}
          </div>
        </Box>
        {this.state.help_center_type === "other"
          ? this.renderLeftFAQSection()
          : null}
      </div>
    )
  }

  renderLeftFAQSection = () => {
    const { faqDataArray } = this.state
    return (
      <div>
        {faqDataArray.map((item, index) => {
          if (item._destroy !== 1) {
            return (
              <div className="faq-bottom-sp" key={index}>
                <Box
                  p={5}
                  style={{
                    background: "#fff",
                    border: "2px solid #ECEDF0",
                    borderRadius: "3px",
                  }}
                >
                  <div className="faq-header-section">
                    <div className="faq-header-left">
                      <Typography className="quetion-create-static">
                        {`${configJSON.question} ${index + 1}`}
                      </Typography>

                      <IconButton
                        aria-label="delete"
                        size={"small"}
                        onClick={() => this.removeFields(index)}
                      >
                        <img src={DeleteIcon} />
                      </IconButton>
                    </div>
                    <div>
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            name="checkedB"
                            toggleEvent={(checked: boolean) => {
                              this.handleFaqStatusChanges(index, checked)
                            }}
                            isChecked={
                              item.status ? true : false
                            }
                          />
                        }
                        label=""
                      />
                      <label>
                        {item.status
                          ? configJSON.live
                          : configJSON.notLive}
                      </label>
                    </div>
                  </div>
                  <div className="static_pate_type">
                    {configJSON.question} *
                  </div>

                  <TextField
                    id="standard-size-small"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={item.title}
                    name={"title"}
                    onChange={(event) => this.handleQueChange(index, event)}
                    error={item.titleError ? true : false}
                    helperText={item.titleError ? item.titleError : ""}
                  />

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 8,
                      marginTop: 25,
                    }}
                  >
                    <div className="answer-label">{configJSON.answer} *</div>
                    <div className="description-length">0/500</div>
                  </div>
                  <Editor
                    editorState={item.content}
                    onEditorStateChange={(editor) =>
                      this.handleEditorStateChange(index, editor)
                    }
                    name={"content"}
                    isErrorShow={item.contentError ? true : false}
                    className="editor-border"
                  />
                  {item.contentError ? (
                    <FormHelperText
                      classes={{ root: "helper-text", error: "" }}
                    >
                      {item.contentError}
                    </FormHelperText>
                  ) : null}

                  {this.state.faqDataArray.length - 1 == index ? (
                    <Button
                      className="button-add-another"
                      variant="text"
                      color="primary"
                      startIcon={
                        <Icon>
                          <img src={AddIcon} />
                        </Icon>
                      }
                      onClick={() => this.onAddAnotherFaqItem()}
                    >
                      {configJSON.addAnotherQuestionAnswer}
                    </Button>
                  ) : null}
                </Box>
              </div>
            )
          }
        })}
      </div>
    )
  }

  render() {
    return (
      <Scrollbars>
        <div className="main-container-create-static">
          <Typography
            className="backButton"
            onClick={this.handleBack}
            component="div"
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              className="back-image"
              onClick={this.handleBack}
              src={BackIcon}
            />
            {configJSON.backButton}
          </Typography>
          <Typography className="create_static_page_title">
            {this.state.pageId ? configJSON.textEditStaticPage : configJSON.textCreateStaticPage}
          </Typography>

          <div className="static_page_container">
            {this.renderLeftSection()}

            {this.renderRightSection()}
          </div>
        </div>
      </Scrollbars>
    )
  }
}
export default withLayout(withSnackBar(withLoader(withHeaderBar(withDialog(CreateStaticPage)))))
// Customizable Area End