//@ts-nocheck
// Customizable Area Start
import React, { createRef } from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  FormGroup,
  FormControlLabel,
  Select,
  MenuItem,
  CardContent,
  Card,
  Checkbox,
  Chip,
  FormHelperText,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import withStyles, { CSSProperties } from "@material-ui/core/styles/withStyles";
import SectionLoader from "./SectionLoader.web";
import InventoryManagementController, {
  Props,
  configJSON
} from "./InventoryManagementController.web";
import {
  down_arrow,
  close,
  chevron_down,
  chevron_up,
  crossIcon,
  backIcon,
  noProducts,
  noCategories,
  editIcon,
  veg_icon,
  nonVeg_icon,
  vegan_icon,
  glutenfree_icon,
  halal_icon,
  kosher_icon,
  eggs_icon,
  nuts_icon,
  wheat_icon,
  soy_icon,
  peanuts_icon,
  celery_icon,
  fish_icon,
  crustaceans_icon,
  lupin_icon,
  mollusc_icon,
  mustard_icon,
  sesameseeds_icon,
  sulphurdioxide_icon,
  milk_icon,
  mild_icon,
  spicy_icon,
  extraspicy_icon,
  searchIcon,
  Veg_icon,
  Eggs_icon,
  Celery_icon,
  Crustaceans_icon,
  Extraspicy_icon,
  Fish_icon,
  Glutenfree_icon,
  Halal_icon,
  Kosher_icon,
  Lupin_icon,
  Mild_icon,
  Milk_icon,
  Mollusc_icon,
  Mustard_icon,
  NonVeg_icon,
  Nuts_icon,
  Peanuts_icon,
  Sesameseeds_icon,
  Soy_icon,
  Spicy_icon,
  Sulphurdioxide_icon,
  Vegan_icon,
  Wheat_icon,
} from "./assets";
import { Formik } from "formik";
import * as Yup from "yup";
import AlertMessage from "../../../components/src/AlertMessage.web";
import SubmitButtonPopup from "../../../components/src/SubmitButtonPopup.web";
import AddEditVariant from "./AddNewVariant.web";
import AddVariantSearch from "./AddNewVariantSearch.web";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout";
import { Scrollbars } from "react-custom-scrollbars";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from '@material-ui/icons/Close';
import Pagination from "../../ss-cms-common-components/src/Pagination/Pagination";
import NoItems from "./NoItems.web";

const PurpleCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "#6200EA",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export const foodLabelCheck = (item: any, background: boolean = true) => {
  switch (item) {
    case "Vegetarian":
      return background ? veg_icon : Veg_icon;
    case "Non-Vegetarian":
      return background ? nonVeg_icon : NonVeg_icon;
    case "Vegan":
      return background ? vegan_icon : Vegan_icon;
    case "Gluten free":
      return background ? glutenfree_icon : Glutenfree_icon;
    case "Halal":
      return background ? halal_icon : Halal_icon;
    case "Kosher":
      return background ? kosher_icon : Kosher_icon;
    case "Milk":
      return background ? milk_icon : Milk_icon;
    case "Eggs":
      return background ? eggs_icon : Eggs_icon;
    case "Nuts":
      return background ? nuts_icon : Nuts_icon;
    case "Wheat":
      return background ? wheat_icon : Wheat_icon;
    case "Soy":
      return background ? soy_icon : Soy_icon;
    case "Peanuts":
      return background ? peanuts_icon : Peanuts_icon;
    case "Celery":
      return background ? celery_icon : Celery_icon;
    case "Fish":
      return background ? fish_icon : Fish_icon;
    case "Crustaceans":
      return background ? crustaceans_icon : Crustaceans_icon;
    case "Lupin":
      return background ? lupin_icon : Lupin_icon;
    case "Mollusc":
      return background ? mollusc_icon : Mollusc_icon;
    case "Mustard":
      return background ? mustard_icon : Mustard_icon;
    case "Sesame Seeds":
      return background ? sesameseeds_icon : Sesameseeds_icon;
    case "Sulphur Dioxide":
      return background ? sulphurdioxide_icon : Sulphurdioxide_icon;
    case "Mild":
      return background ? mild_icon : Mild_icon;
    case "Spicy":
      return background ? spicy_icon : Spicy_icon;
    case "Extra Hot":
      return background ? extraspicy_icon : Extraspicy_icon;
    default:
      return background ? veg_icon : Veg_icon;
  }
};

export const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 48,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 2,
    "&$checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + $track": {
        backgroundColor: "#35B459",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#red",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 23,
    height: 22,
  },
  track: {
    borderRadius: 26 / 2,
    border: "none",
    backgroundColor: "#C0C3CE",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const validationSchema = Yup.object().shape({
  product_title: Yup.string()
    .max(36, "Must be less than 36 characters")
    .required("Title is required"),
  product_desc: Yup.string()
    .required("Description is required")
    .max(300, "Must be less than 300 characters"),
  product_category: Yup.array().min(1).required(),
  product_stock: Yup.string().required(),
  product_price: Yup.number().positive("price must be more than 0")
    .typeError("Only numbers are allowed")
    .required("Price is required"),
  product_discountValue: Yup.number().positive()
    .typeError("Only numbers are allowed")
    .min(0, "Discount must be 0 to 100")
    .max(100, "Discount must be 0 to 100"),
  packageHeight: Yup.number().positive().required(configJSON.itemValidations.heightRequired).typeError(configJSON.itemValidations.onlyNumberAllowed).min(0, configJSON.itemValidations.greaterThanZero),
  packageWidth: Yup.number().positive().required(configJSON.itemValidations.widthRequired).typeError(configJSON.itemValidations.onlyNumberAllowed).min(0, configJSON.itemValidations.greaterThanZero),
  packageBreadth: Yup.number().positive().required(configJSON.itemValidations.breadthRequired).typeError(configJSON.itemValidations.onlyNumberAllowed).min(0, configJSON.itemValidations.greaterThanZero),
  packageWeight: Yup.number().positive().required(configJSON.itemValidations.weightRequired).typeError(configJSON.itemValidations.onlyNumberAllowed).min(0, configJSON.itemValidations.greaterThanZero)
});

// Customizable Area End
export class InventoryManagement extends InventoryManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.imgRef = createRef();
    this.submitRef = createRef();
    this.formikRef = createRef()
    // Customizable Area End
  }

  // Customizable Area Start
  foodLabelBlock = (data: any, classes) => {
    return (
      <Grid style={{ display: "flex" }}>
        {data.attributes.food_lables.length > 0 ? (
          data.attributes.food_lables?.map((item: any, index_num: any) => {
            if (index_num < 3) {
              return (
                <Grid className={classes.foodLables}>
                  <img
                    src={foodLabelCheck(item)}
                    alt="veg"
                    style={{ height: "28px", width: "28px" }}
                  />
                </Grid>
              );
            }
          })
        ) : (
          <span className={classes.dataText}>N/A</span>
        )}

        {data.attributes.food_lables.length > 3 && (
          <Grid className={classes.foodLablesCount}>
            <Typography
              style={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "15px",
                color: "#64748B",
              }}
            >
              +{data.attributes.food_lables.length - 3}
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  };
  discountCheck = (data) => {
    return data.attributes.discount > 0
      ? this.currencyType + data.attributes.after_discount
      : "N/A";
  };

  handleViewAvailability(availability: string) {
    if (availability == "in_stock") {
      return {
        color: "#059669",
        title: "Available",
      };
    } else {
      return {
        color: "#DC2626",
        title: "Not available",
      };
    }
  }

  searchFilterFoodLable = (classes) => {
    return (
      <>
        <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Grid style={{ width: '15%', height: "41px", textAlign: 'center', background: "#FAFAFA", borderRadius: "3px 0px 0px 3px", border: '1px solid #D0D2DA', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Grid data-testId="foodlable_filter" onClick={this.handleDialogOpen} style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: 700,
              padding: "0px 10px",
              lineHeight: "22px", justifyContent: "center", cursor: "pointer"
            }}>
              {configJSON.filterItems}
              <img src={down_arrow} alt="downarrow" style={{ paddingLeft: "8px" }} />
            </Grid>
          </Grid>
          <Grid style={{ width: '85%', display: 'flex', alignItems: 'center' }}>
            <Autocomplete
              name="product_search"
              data-testId="searchproductInput"
              options={this.state.productSearchData}
              className={classes.InputStyle}
              inputValue={this.state.InputValue}
              onInputChange={(e, val) => {
                this.setState({ InputValue: val });
                this.searchProductWithText(val);
              }}
              size="small"
              getOptionLabel={(option) => option.name}
              renderOption={(option) => {
                return (
                  <Grid style={{ display: "flex", alignItems: "center" }}>
                    {option?.images?.url && (
                      <Grid style={{ marginRight: "5px" }}>
                        <img
                          src={option?.images?.url}
                          alt="icon"
                          style={{
                            width: "24px",
                            height: "24px",
                            marginRight: "10px",
                          }}
                        />
                      </Grid>
                    )}
                    <Grid className={classes.menuItemNameSearch}>
                      {option.name}
                    </Grid>
                  </Grid>
                );
              }}
              // disablePortal={true}
              ListboxProps={{
                style: {
                  maxHeight: "150px",
                  margin: "10px",
                },
              }}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.placeholderText}
                  placeholder="Search Menu"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        e.stopPropagation();
                      }
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={searchIcon}
                          alt="searchIcon"
                          style={{ paddingLeft: "15px" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        {
          this.state.appliedFoodLables?.length > 0 &&
          <Grid style={{ marginTop: "20px", display: "flex", alignItems: "center" }}>
            <Typography variant="h6" style={{
              fontFamily: "Inter",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "18px",
              color: "#5500CC"
            }}>Filter applied:</Typography>
            <Grid>
              {this.state.appliedFoodLables.map((foodLabel: any) => {
                return (
                  <Chip
                    key={foodLabel}
                    label={foodLabel}
                    data-testId="handleFoodLabelsFilterDelete"
                    deleteIcon={<CloseIcon style={{ color: "#5500CC", height: "16px", width: "16px" }} />}
                    onDelete={() => { this.handleFoodLabelsFilterDelete(foodLabel) }}
                    style={{
                      marginLeft: "5px",
                      marginBottom: "5px",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: 700,
                      lineHeight: "18px",
                      color: "#5500CC",
                      background: "#F6F0FF",
                      padding: "4px 5px"
                    }}
                  />
                )
              })}
            </Grid>
          </Grid>
        }
      </>
    )
  }

  renderProduct(classes) {
    return (
      <Grid item style={{ marginTop: "40px" }}>
        <Grid
          item
          lg={12}
          md={12}
          xs={12}
          style={{ display: "flex", marginBottom: "20px" }}
        >
          <Grid item lg={4} md={4}>
            <Typography
              variant="h5"
              style={{
                fontFamily: "Rubik",
                fontSize: "26px",
                fontWeight: "500",
                lineHeight: "31px",
              }}
            >
              Menu
            </Typography>
          </Grid>
          <Grid item lg={8} md={8}>
                <Box className={classes.MenuActionBox}>
                  <Button
                      className={classes.MenuActionButton}
                      variant="outlined"
                      style={{display:'none'}}
                  >
                    <div className={classes.ActionButtonSection}>
                      <Typography className="Text">
                          {configJSON.importMenu}
                      </Typography>
                      <span className={classes.ActionButtonMsg}>
                      {configJSON.importMenuMsg}
                      </span>
                    </div>

                  </Button>
                  <Button
                      className={classes.MenuActionButton}
                      variant="outlined"
                      onClick={this.navigateToItemsLibrary}
                  >
                    <div className={classes.ActionButtonSection}>

                      <Typography className="Text">
                          {configJSON.builderLibrary}
                      </Typography>
                      <span className={classes.ActionButtonMsg}>
                      {configJSON.builderLibraryMsg}
                      </span>
                      </div>
                  </Button>
                  <Button
                      variant="outlined"
                      data-testId="addProduct"
                      onClick={this.openAddItemsForm}
                      className={classes.MenuActionButton}
                  >
                    <div className={classes.ActionButtonSection}>

                      <Typography className="Text">
                          {configJSON.addItem}
                      </Typography>
                      <span className={classes.ActionButtonMsg}>
                      {configJSON.addItemMsg}
                      </span>
                      </div>
                  </Button>
              </Box>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} xs={12} style={{ margin: "30px 0px" }}>
          {this.searchFilterFoodLable(classes)}
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerText}>
                  {configJSON.itemName}
                </TableCell>
                <TableCell className={classes.headerText}>Price</TableCell>
                <TableCell className={classes.headerText}>
                  Discounted Price
                </TableCell>
                <TableCell className={classes.headerText}>Status</TableCell>
                <TableCell className={classes.headerText}>Category</TableCell>
                <TableCell className={classes.headerText}>
                  Availability
                </TableCell>
                <TableCell className={classes.headerText}>Food Label</TableCell>
                <TableCell className={classes.headerText} align="right">
                  Edit
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ background: "#fff" }}>
              {this.state.productsData &&
                this.state.productsData.length > 0 &&
                !this.state.noSearchCategories ? (
                this.state.productsData.map((data: any, index: any) => {
                  return (
                    <TableRow
                      key={data.attributes.id}
                      style={{ border: "2px solid #ECEDF0" }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.dataText}
                      >
                        {data.attributes.name}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.dataText}
                      >
                        {this.currencyType}
                        {data.attributes.price}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.dataText}
                      >
                        {this.discountCheck(data)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <FormGroup>
                          <FormControlLabel
                            data-testId="editStatusProduct"
                            control={
                              <IOSSwitch
                                value={this.state.product_status}
                                checked={Boolean(data.attributes.status)}
                                disabled={!data.attributes.price}
                                onChange={() => {
                                  this.editStatusProduct(
                                    data.attributes.categories[0],
                                    !data.attributes.status,
                                    data.id
                                  );
                                  this.setState({ productStatus: true });
                                }}
                                name={data.id}
                              />
                            }
                            label=""
                          />
                        </FormGroup>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.dataText}
                      >
                        { data?.attributes?.categories[0]
                          .map((val: any) => val[0])
                          .join(", ")}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          fontFamily: "Rubik",
                          fontSize: "12px",
                          fontWeight: "400",
                          lineHeight: "16px",
                          color:
                            data.attributes.availability == "in_stock"
                              ? "#059669"
                              : "#DC2626",
                        }}
                      >
                        {data.attributes.availability == "in_stock"
                          ? "Available"
                          : "Not available"}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {this.foodLabelBlock(data, classes)}
                      </TableCell>
                      <TableCell align="right">
                        <img
                          src={editIcon}
                          style={{ cursor: "pointer" }}
                          data-testId="editProductDetails"
                          onClick={() => {
                            this.editProductDetails(data.attributes, data.id);
                          }}
                          alt="edit icon"
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow style={{ border: "2px solid #ECEDF0" }}>
                  <TableCell component="th" scope="row" colSpan={8}>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      xs={12}
                      style={{ background: "#fff", margin: "10px 0px" }}
                    >
                      <Grid item className={classes.categoriesContent}>
                        <Typography style={{ marginBottom: "10px" }}>
                          {this.state.appliedFoodLables.length > 0 ? <img src={noProducts} alt="no products" /> : <img src={noCategories} alt="no categories" />}
                        </Typography>
                        <Typography
                          variant="h5"
                          style={{
                            fontFamily: "Rubik",
                            fontSize: "20px",
                            fontWeight: 500,
                            lineHeight: "26px",
                            letterSpacing: "-0.02em",
                            marginBottom: "5px",
                          }}
                        >
                          No results found
                        </Typography>
                        <Typography
                          style={{
                            fontFamily: "Rubik",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "22px",
                            marginBottom: "10px",
                            color: "#83889E",
                            width: "395px"
                          }}
                        >
                          {this.state.appliedFoodLables.length > 0 ? "We couldn't find any matches for your filter criteria. Please reset your filters and try the search option." : "Revise search text and try again"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    );
  }

  selectCategory = (
    setFieldValue,
    touched,
    errors,
    handleBlur,
    classes,
    filter
  ) => {
    return (
      <>
        <Autocomplete
          name="product_category"
          data-testId="product_category"
          multiple
          options={this.state.categoriesData}
          value={this.state.product_category}
          inputValue={this.state.InputValue}
          onInputChange={(e, val) => {
            this.setState({ InputValue: val });
          }}
          onChange={(e, val) => {
            const categories = [];
            val.forEach((newValue: any) => {
              if (typeof newValue === "string") {
                categories.push(newValue);
              } else if (newValue && newValue.id == "") {
                this.createCategory(newValue.name);
              } else {
                categories.push(newValue);
              }
            });
            this.setState({
              product_category: categories ?? [],
              openSubmit: true,
            });
            setFieldValue("product_category", val);
          }}
          disableCloseOnSelect={true}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }
            if (option.id == "") {
              return (
                <span
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "19px",
                    color: "#5500CC",
                  }}
                >
                  {option.name}
                </span>
              );
            }
            return option.name;
          }}
          renderOption={(option) =>
            option.id == "" ? (
              <span
                style={{
                  fontFamily: "Rubik",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "19px",
                  color: "#5500CC",
                }}
              >
                + Add &lt;{option.name}&gt;
              </span>
            ) : (
              <span
                style={{
                  fontFamily: "Rubik",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  color: "#475569",
                }}
              >
                {option.name}
              </span>
            )
          }
          disablePortal={true}
          ListboxProps={{
            style: {
              maxHeight: "150px",
              margin: "10px",
            },
          }}
          getOptionSelected={(option, value) => option.id === value.id}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            const isExisting = options.some(
              (option) =>
                inputValue.toLocaleLowerCase().trim() ===
                option.name.toLocaleLowerCase().trim()
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                id: "",
                name: inputValue,
              });
            }
            return filtered;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.placeholderText}
              placeholder="Select"
              variant="outlined"
            />
          )}
          renderTags={(tagValue, getTagProps) => {
            if (tagValue?.length == 0) {
              return "Select";
            } else {
              return tagValue.length + " categories selected";
            }
          }}
          error={Boolean(touched.product_category && errors.product_category)}
          onBlur={handleBlur}
        />
        {Boolean(touched.product_category && errors.product_category) && (
          <FormHelperText error={true} className="MuiFormHelperText-contained">
            Select atleast one category
          </FormHelperText>
        )}
        <Grid>
          {this.state.product_category &&
            this.state.product_category.length > 0 &&
            (this.state.product_category as string[])?.map((value: any) => {
              return (
                <Chip
                  className={classes.chipText}
                  key={value.id}
                  label={value.name}
                  style={{ margin: "5px 5px 5px 0px" }}
                />
              );
            })}
        </Grid>
      </>
    );
  };
  selectDietary = (setFieldValue, classes) => {
    return (
      <>
        <Select
          name="product_dietary"
          multiple
          fullWidth
          value={this.state.product_dietary}
          variant="outlined"
          data-testId="product_dietary"
          onChange={(e) => {
            this.handleDietaryChange(e);
            setFieldValue("product_dietary", e.target.value);
          }}
          displayEmpty
          className={classes.selectFoodLable}
          renderValue={(selected: any) => {
            if (selected.length == 0) {
              return "Select Dietary";
            } else {
              return selected.length + " Dietary selected";
            }
          }}
        >
          {this.state.dietaryData.map((item: any) => {
            return (
              <MenuItem
                key={item.key}
                value={item.value}
                className={classes.menuList}
              >
                <PurpleCheckbox
                  className={classes.menuItemCheckbox}
                  checked={this.state.product_dietary.indexOf(item.value) > -1}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={classes.checkedIcon} />}
                />
                <Grid
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    style={{
                      marginRight: "5px",
                    }}
                  >
                    <img
                      src={item.img}
                      alt={item.name}
                      style={{ height: "28px", width: "28px" }}
                    />
                  </Grid>
                  <Grid className={classes.menuItemName}>{item.name}</Grid>
                </Grid>
              </MenuItem>
            );
          })}
        </Select>
        {this.state.product_dietary &&
          (this.state.product_dietary as string[]).map((value) => {
            const dietary: any = [];
            this.state.dietaryData.find((val: any) => {
              if (val.value == value) {
                dietary.push({
                  img: val.img,
                  value: val.value,
                  color: val.color,
                });
              }
            });
            return (
              <Chip
                avatar={<Avatar src={dietary[0]?.img} />}
                key={dietary[0]?.value}
                label={dietary[0]?.value}
                style={{
                  margin: "5px 5px 5px 0px",
                  background: `${dietary[0]?.color}`,
                  fontFamily: "Rubik",
                  fontSize: "12px",
                  fontWeight: "400",
                  lineHeight: "16px",
                  color: "#334155",
                }}
              />
            );
          })}
      </>
    );
  };
  selectAllergen = (setFieldValue, classes) => {
    return (
      <>
        <Select
          name="product_allergens"
          multiple
          value={this.state.product_allergens}
          onChange={(e: any) => {
            this.handleAllergensChange(e);
            setFieldValue("product_allergens", e.target.value);
          }}
          variant="outlined"
          fullWidth
          displayEmpty
          data-testId="product_allergens"
          className={classes.selectFoodLable}
          renderValue={(selected: any) => {
            if (selected.length === 0) {
              return "Select Allergens";
            } else {
              return selected.length + " Allergens selected";
            }
          }}
        >
          {this.state.allergenData.map((item: any) => {
            return (
              <MenuItem
                key={item.id}
                value={item.value}
                className={classes.menuList}
              >
                <PurpleCheckbox
                  className={classes.menuItemCheckbox}
                  checked={
                    this.state.product_allergens.indexOf(item.value) > -1
                  }
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={classes.checkedIcon} />}
                />
                <Grid
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    style={{
                      marginRight: "5px",
                    }}
                  >
                    <img
                      src={item.img}
                      alt={item.name}
                      style={{ width: "28px", height: "28px" }}
                    />
                  </Grid>
                  <Grid className={classes.menuItemName}>{item.name}</Grid>
                </Grid>
              </MenuItem>
            );
          })}
        </Select>
        {this.state.product_allergens &&
          (this.state.product_allergens as string[]).map((value: any) => {
            const allergens: any = [];
            this.state.allergenData.find((val: any) => {
              if (val.value == value) {
                allergens.push({
                  img: val.img,
                  value: val.value,
                  color: val.color,
                });
              }
            });
            return (
              <Chip
                avatar={<Avatar src={allergens[0]?.img} />}
                key={allergens[0]?.value}
                label={allergens[0]?.value}
                style={{
                  margin: "5px 5px 5px 0px",
                  background: `${allergens[0]?.color}`,
                  fontFamily: "Rubik",
                  fontSize: "12px",
                  fontWeight: "400",
                  lineHeight: "16px",
                  color: "#334155",
                }}
              />
            );
          })}
      </>
    );
  };
  spiceLevelCheck = (selected) => {
    switch (selected) {
      case "Spicy":
        return spicy_icon;
      case "Mild":
        return mild_icon;
      case "Extra Hot":
        return extraspicy_icon;
      default:
        return spicy_icon;
    }
  };
  selectSpicyLevel = (setFieldValue, classes) => {
    return (
      <Select
        name="product_spicelevel"
        value={this.state.product_spicelevel}
        onClick={(e) => {
          this.handleClickSpiceLevelChange(e);
        }}
        onChange={(e) => {
          this.handleSpiceLevelChange(e);
          setFieldValue("product_spicelevel", e.target.value);
        }}
        fullWidth
        displayEmpty
        variant="outlined"
        data-testId="product_spicelevel"
        className={classes.selectFoodLable}
        renderValue={(selected: any) => {
          if (selected?.length === 0 || selected === undefined) {
            return "Select Spice Level";
          } else {
            return (
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={this.spiceLevelCheck(selected)}
                  style={{
                    marginRight: "5px",
                    height: "24px",
                    width: "24px",
                  }}
                />
                {selected}
              </span>
            );
          }
        }}
      >
        {this.state.spiceLevelData.map((item: any) => {
          return (
            <MenuItem
              key={item.id}
              value={item.value}
              className={classes.menuList}
            >
              <Grid
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Grid
                  style={{
                    marginRight: "5px",
                  }}
                >
                  <img
                    src={item.img}
                    alt={item.name}
                    style={{ width: "28px", height: "28px" }}
                  />
                </Grid>
                <Grid className={classes.menuItemName}>{item.name}</Grid>
              </Grid>
            </MenuItem>
          );
        })}
      </Select>
    );
  };
  selectAvailability = (
    setFieldValue,
    touched,
    errors,
    handleBlur,
    classes
  ) => {
    return (
      <>
        <Select
          name="product_stock"
          fullWidth
          variant="outlined"
          value={this.state.product_stock}
          className={classes.placeholderText}
          onChange={(e) => {
            this.setState({ product_stock: e.target.value, openSubmit: true });
            setFieldValue("product_stock", e.target.value);
          }}
          displayEmpty
          data-testId="product_stock"
          error={Boolean(touched.product_stock && errors.product_stock)}
          renderValue={(selected: any) => {
            if (selected.length === 0) {
              return "Select";
            } else {
              if (selected == "in_stock") {
                return (
                  <Grid
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid
                      style={{
                        background: "green",
                        borderRadius: "50%",
                        padding: "6px",
                        margin: "5px",
                      }}
                    />
                    <Grid>Available</Grid>
                  </Grid>
                );
              }
              if (selected == "out_of_stock") {
                return (
                  <Grid
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid
                      style={{
                        background: "red",
                        borderRadius: "50%",
                        padding: "6px",
                        margin: "5px",
                      }}
                    />
                    <Grid>Not available</Grid>
                  </Grid>
                );
              }
            }
          }}
          onBlur={handleBlur}
        >
          <MenuItem value="in_stock">
            <Grid
              style={{
                background: "green",
                borderRadius: "50%",
                padding: "6px",
                margin: "5px",
              }}
            />
            Available
          </MenuItem>
          <MenuItem value="out_of_stock">
            <Grid
              style={{
                background: "red",
                borderRadius: "50%",
                padding: "6px",
                margin: "5px",
              }}
            />
            Not available
          </MenuItem>
        </Select>
        {Boolean(touched.product_stock && errors.product_stock) && (
          <FormHelperText error={true} className="MuiFormHelperText-contained">
            Select atleast one
          </FormHelperText>
        )}
      </>
    );
  };

  priceWithDiscountBlock = (
    setFieldValue,
    touched,
    errors,
    handleBlur,
    classes
  ) => {
    return (
      <>
        <Grid item lg={12} md={12} sm={12} className={classes.bottomSpace}>
          <Typography variant="h6" component="h6" className={classes.titleText}>
            Price *
          </Typography>
          <Grid
            container
            item
            sm={12}
            md={12}
            lg={12}
            style={{ alignItems: "center" }}
          >
            <Grid item lg={5} md={5} sm={5}>
              <TextField
                value={this.state.product_price}
                type="text"
                name="product_price"
                data-testId="product_price"
                className={classes.placeholderText}
                onChange={(e) => {
                  this.setState({
                    product_price: e.target.value,
                    openSubmit: true,
                  });
                  setFieldValue("product_price", e.target.value);
                }}
                error={Boolean(touched.product_price && errors.product_price)}
                onBlur={handleBlur}
                helperText={touched.product_price && errors.product_price}
                fullWidth
                variant="outlined"
                placeholder={this.state.isEdit ? "Edit price" : "Enter price"}
              />
            </Grid>
            <Grid item lg={1} md={1} sm={1} />
            <Grid item lg={4} md={4} sm={4}>
              <FormGroup>
                <FormControlLabel
                  data-testId="discount_checked"
                  control={
                    <IOSSwitch
                      checked={Boolean(this.state.discount_checked)}
                      onChange={() => {
                        this.setState({
                          discount_checked: !this.state.discount_checked,
                          openSubmit: true,
                        });
                        setFieldValue(
                          "discount_checked",
                          !this.state.discount_checked
                        );
                        if (this.state.discount_checked) {
                          this.setState({
                            product_discountValue: 0,
                            product_discountPrice: 0,
                          });
                        }
                      }}
                      name="discount_checked"
                    />
                  }
                  className={classes.switchLabel}
                  label={
                    this.state.discount_checked
                      ? "Discount Added"
                      : "No discount"
                  }
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={5} md={5} sm={5} className={classes.bottomSpace}>
          {this.state.discount_checked && (
            <Grid container item sm={12} md={12} lg={12}>
              <Grid item sm={5} md={5} lg={5}>
                <Typography
                  variant="h6"
                  component="h6"
                  className={classes.titleText}
                >
                  Discount
                </Typography>
                <TextField
                  variant="outlined"
                  name="product_discountValue"
                  type="text"
                  placeholder="discount"
                  value={this.state.product_discountValue}
                  className={classes.placeholderText}
                  data-testId="product_discountValue"
                  error={Boolean(
                    touched.product_discountValue &&
                    errors.product_discountValue
                  )}
                  onBlur={handleBlur}
                  helperText={
                    touched.product_discountValue &&
                    errors.product_discountValue
                  }
                  onChange={(e) => {
                    this.handleDiscountValueChange(e);
                    setFieldValue("product_discountValue", e.target.value);
                  }}
                />
              </Grid>
              <Grid
                item
                sm={1}
                md={1}
                lg={1}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "25px",
                }}
              >
                %
              </Grid>
              <Grid item sm={6} md={6} lg={6}>
                <Typography
                  variant="h6"
                  component="h6"
                  className={classes.titleText}
                >
                  Price after discount
                </Typography>
                <TextField
                  variant="outlined"
                  name="product_discountPrice"
                  type="text"
                  style={{ background: "#F1F5F9" }}
                  className={classes.placeholderText}
                  disabled
                  placeholder="discount price"
                  value={this.state.product_discountPrice}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  addEditProductBlock = (classes, filter) => {
    return (
      <>
        <Grid item xs={12} className={classes.backArrow}>
          <img
            src={backIcon}
            data-testId="backBtn"
            onClick={() => this.pressBackEditAddItem()}
            alt="backIcon"
            style={{
              cursor: "pointer",
              marginRight: "5px",
              height: "12px",
              width: "12px",
            }}
          />
          <Typography
            style={{
              fontFamily: "Rubik",
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "16px",
              color: "#676B7E",
            }}
          >
            Back
          </Typography>
        </Grid>
        <Formik
          innerRef={this.formikRef}
          initialValues={{
            product_title: this.state.product_title,
            product_desc: this.state.product_desc,
            product_price: this.state.product_price,
            product_category: this.state.product_category,
            product_discountValue: this.state.product_discountValue,
            product_stock: this.state.product_stock,
            product_image: this.state.product_selectedImage,
            packageHeight: this.state.packageHeight,
            packageWidth: this.state.packageWidth,
            packageBreadth: this.state.packageBreadth,
            packageWeight: this.state.packageWeight
          }}
          data-testId="formik"
          onSubmit={(values) => {
            if (this.state.product_Id) {
              this.setState({ openSubmit: false });
              this.editProduct();
            } else {
              if (values.product_image && !this.state.image_check) {
                this.setState({ image_check: false, openSubmit: false });
                this.addProduct();
              } else {
                this.setState({ image_check: true });
              }
            }
          }}
          validationSchema={validationSchema}
        >
          {({
            errors,
            touched,
            values,
            handleBlur,
            setFieldValue,
            handleSubmit,
          }) => {
            const errorsData = this.getItemFieldValidations(touched, errors);
            return (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                style={{ display: "flex", margin: "30px 0px" }}
              >
                <Grid item lg={6} md={6} xs={6}>
                  <Typography
                    variant="h5"
                    style={{
                      fontFamily: "Rubik",
                      fontSize: "26px",
                      fontWeight: "500",
                      lineHeight: "31px",
                    }}
                  >
                    {this.state.isEdit ? "Edit" : "Add a"} Menu item
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={6} style={{ textAlign: "right" }}>
                  <Button
                    hidden
                    ref={this.submitRef}
                    variant="contained"
                    style={{ display: "none" }}
                    className={classes.categoryButton}
                    data-testId="categorySubmit"
                    type="submit"
                  >
                    Save Changes
                  </Button>
                </Grid>
              </Grid>
              <Grid item sm={12} md={12} lg={12} style={{ display: "flex" }}>
                <Grid
                  item
                  sm={9}
                  md={9}
                  lg={9}
                  style={{
                    marginRight: "15px",
                    borderRadius: "3px",
                    border: "2px solid #ECEDF0",
                  }}
                >
                  <Card style={{ alignItems: "center" }}>
                    <CardContent>
                      <Typography
                        component="h5"
                        style={{
                          fontFamily: "Rubik",
                          fontWeight: "500",
                          fontSize: "20px",
                          paddingBottom: "20px",
                        }}
                      >
                        Product Details
                      </Typography>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        className={classes.bottomSpace}
                      >
                        <Grid
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            variant="h6"
                            component="h6"
                            className={classes.titleText}
                          >
                            Title *
                          </Typography>
                          <span className={classes.countText}>
                            {this.state.titleCount}/36
                          </span>
                        </Grid>
                        <TextField
                          id="addCategory"
                          name="product_title"
                          value={this.state.product_title}
                          type="text"
                          size="small"
                          data-testId="product_title"
                          className={classes.placeholderText}
                          onChange={(e) => {
                            this.setState({
                              product_title: e.target.value.replace(
                                /\s+/g,
                                " "
                              ),
                              titleCount: e.target.value.replace(/\s+/g, " ")
                                .length,
                              openSubmit: true,
                              setTouched: false,
                            });
                            setFieldValue(
                              "product_title",
                              e.target.value.replace(/\s+/g, " ")
                            );
                          }}
                          fullWidth
                          variant="outlined"
                          placeholder={
                            this.state.isEdit ? "Edit title" : "Enter title"
                          }
                          onBlur={handleBlur}
                          error={
                            Boolean(
                              touched.product_title && errors.product_title
                            ) || this.state.setTouched
                          }
                          helperText={
                            this.state.setTouched
                              ? this.state.errorText
                              : touched.product_title && errors.product_title
                          }
                        />
                      </Grid>

                      <Grid
                        item
                        lg={7}
                        md={7}
                        sm={7}
                        className={classes.bottomSpace}
                      >
                        <Grid
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            variant="h6"
                            component="h6"
                            className={classes.titleText}
                          >
                            Description *
                          </Typography>
                          <span className={classes.countText}>
                            {this.state.descCount}/300
                          </span>
                        </Grid>
                        <TextField
                          id="addCategory"
                          value={this.state.product_desc}
                          name="product_desc"
                          type="text"
                          data-testId="product_desc"
                          className={classes.placeholderText}
                          onChange={(e) => {
                            this.setState({
                              product_desc: e.target.value,
                              descCount: e.target?.value?.length,
                              openSubmit: true,
                            });
                            setFieldValue("product_desc", e.target.value);
                          }}
                          fullWidth
                          multiline
                          minRows={4}
                          variant="outlined"
                          placeholder={
                            this.state.isEdit
                              ? "Edit description"
                              : "Enter description"
                          }
                          error={Boolean(
                            touched.product_desc && errors.product_desc
                          )}
                          onBlur={handleBlur}
                          helperText={
                            touched.product_desc && errors.product_desc
                          }
                        />
                      </Grid>

                      <Grid
                        item
                        lg={5}
                        md={5}
                        sm={5}
                        className={classes.bottomSpace}
                      >
                        <Typography
                          variant="h6"
                          component="h6"
                          className={classes.titleText}
                        >
                          Categories *
                        </Typography>
                        {this.selectCategory(
                          setFieldValue,
                          touched,
                          errors,
                          handleBlur,
                          classes,
                          filter
                        )}
                      </Grid>
                      <Grid
                        item
                        lg={10}
                        md={10}
                        sm={10}
                        className={classes.bottomSpace}
                      >
                        <Typography
                          variant="h6"
                          component="h6"
                          className={classes.titleText}
                        >
                          Image *
                        </Typography>
                        <Grid container lg={12} md={12} sm={12}>
                          <Grid item lg={3} md={4} sm={5}>
                            <Card
                              style={{
                                border: this.state.image_check
                                  ? "2px dashed #f44336"
                                  : "2px dashed #C0C3CE",
                                borderRadius: "10px",
                                textAlign: "center",
                                padding: "30px 10px",
                                boxShadow: "none",
                              }}
                            >
                              <CardContent
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <Grid
                                  onClick={() => this.imgRef.current.click()}
                                  style={{ cursor: "pointer" }}
                                >
                                  <Typography
                                    variant="h5"
                                    component="h5"
                                    className={classes.imageText}
                                  >
                                    +
                                  </Typography>
                                  <Typography
                                    variant="h5"
                                    component="h5"
                                    className={classes.imageText}
                                  >
                                    {this.state.product_previewImage ||
                                      this.state.product_selectedImage
                                      ? "Edit "
                                      : "Add "}
                                    image
                                  </Typography>
                                  <Typography
                                    variant="h5"
                                    component="h5"
                                    style={{
                                      fontFamily: "Rubik",
                                      fontSize: "12px",
                                      fontWeight: "400",
                                      lineHeight: "16px",
                                      color: "#AFB2C0",
                                    }}
                                  >
                                    Max 1024 x 1024px
                                  </Typography>

                                  <Button
                                    style={{
                                      fontFamily: "Rubik",
                                      fontWeight: "500",
                                      fontSize: "20px",
                                      color: "#C0C3CE",
                                      display: "none",
                                    }}
                                  >
                                    <input
                                      ref={this.imgRef}
                                      name="product_image"
                                      type="file"
                                      hidden
                                      accept="image/*"
                                      data-testId="product_image"
                                      onChange={(e: any) => {
                                        this.handleImageChange(e);
                                        setFieldValue(
                                          "product_image",
                                          e.target.files[0]
                                        );
                                      }}
                                    />
                                  </Button>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                          <Grid
                            item
                            lg={9}
                            md={8}
                            sm={7}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              paddingLeft: "20px",
                            }}
                          >
                            {this.state.product_previewImage && (
                              <div>
                                <img
                                  src={this.state.product_previewImage}
                                  alt="Selected"
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "200px",
                                  }}
                                />
                              </div>
                            )}
                          </Grid>
                          {this.state.image_check && (
                            <FormHelperText
                              error={true}
                              className="MuiFormHelperText-contained"
                            >
                              {this.state.imageError}
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                      {this.priceWithDiscountBlock(
                        setFieldValue,
                        touched,
                        errors,
                        handleBlur,
                        classes
                      )}
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        className={classes.bottomSpace}
                      >
                        <Typography
                          variant="h6"
                          component="h6"
                          className={classes.titleText}
                        >
                          Food Label
                        </Typography>

                        <Grid
                          container
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          spacing={2}
                        >
                          <Grid item lg={4} md={4} sm={4}>
                            {this.selectDietary(setFieldValue, classes)}
                          </Grid>
                          <Grid item lg={4} md={4} sm={4}>
                            {this.selectAllergen(setFieldValue, classes)}
                          </Grid>
                          <Grid item lg={4} md={4} sm={4}>
                            {this.selectSpicyLevel(setFieldValue, classes)}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className={classes.packageDetailsGridItem}>
                        <Typography
                          className={classes.packageDetailsTitleText}
                        >
                          {configJSON.packageDetails}
                        </Typography>
                        <Grid
                            container
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            spacing={2}
                          >
                            <Grid item lg={4} md={4} sm={4}>
                              <Typography
                                className={classes.packageDetailsSubTitleText}
                              >
                                {configJSON.packageHeight}
                              </Typography>
                              <TextField
                                  name="packageHeight"
                                  value={this.state.packageHeight}
                                  type="text"
                                  className={classes.packageDetailTextField}
                                  onChange={this.onChangePackageHeight}
                                  fullWidth
                                  variant="outlined"
                                  placeholder={configJSON.packageHeight}
                                  onBlur={handleBlur}
                                  error={errorsData.packageHeightError}
                                  helperText={errorsData.packageHeightErrorText}
                                />
                            </Grid>
                            <Grid item lg={4} md={4} sm={4}>
                              <Typography
                                className={classes.packageDetailsSubTitleText}
                              >
                                {configJSON.packageBreadth}
                              </Typography>
                              <TextField
                                  name="packageBreadth"
                                  value={this.state.packageBreadth}
                                  type="text"
                                  className={classes.packageDetailTextField}
                                  onChange={this.onChangePackageBreadth}
                                  fullWidth
                                  variant="outlined"
                                  placeholder={configJSON.packageBreadth}
                                  onBlur={handleBlur}
                                  error={errorsData.packageBreadthError}
                                  helperText={errorsData.packageBreadthErrorText}
                                  required
                                />
                            </Grid>
                            <Grid item lg={4} md={4} sm={4}>
                              <Typography
                                className={classes.packageDetailsSubTitleText}
                              >
                                {configJSON.packageWidth}
                              </Typography>
                              <TextField
                                  name="packageWidth"
                                  value={this.state.packageWidth}
                                  type="text"
                                  className={classes.packageDetailTextField}
                                  onChange={this.onChangePackageWidth}
                                  fullWidth
                                  placeholder={configJSON.packageWidth}
                                  variant="outlined"
                                  onBlur={handleBlur}
                                  error={errorsData.packageWidthError}
                                  helperText={errorsData.packageWidthErrorText}
                                />
                            </Grid>
                            <Grid item lg={4} md={4} sm={4}>
                              <Typography
                                className={classes.packageDetailsSubTitleText}
                              >
                                {configJSON.packageWeight}
                              </Typography>
                              <TextField
                                  name="packageWeight"
                                  value={this.state.packageWeight}
                                  type="text"
                                  className={classes.packageDetailTextField}
                                  onChange={this.onChangePackageWeight}
                                  fullWidth
                                  variant="outlined"
                                  onBlur={handleBlur}
                                  placeholder={configJSON.packageWeight}
                                  error={errorsData.packageWeightError}
                                  helperText={errorsData.packageWeightErrorText}
                                />
                            </Grid>
                          </Grid>
                      </Grid>
                      {this.renderVaraints({}, classes)}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item sm={3} md={3} lg={3}>
                  <Card
                    style={{
                      borderRadius: "3px",
                      boxShadow: "none",
                      border: "2px solid #ECEDF0",
                    }}
                  >
                    <CardContent style={{ padding: "0px" }}>
                      <Grid item sm={12} style={{ padding: "16px" }}>
                        <Typography
                          variant="h6"
                          style={{
                            fontFamily: "Rubik",
                            fontWeight: "500",
                            fontSize: "20px",
                            paddingBottom: "10px",
                          }}
                        >
                          Status
                        </Typography>

                        <Grid item sm={12}>
                          <FormGroup>
                            <FormControlLabel
                              data-testId="active_checked"
                              control={
                                <IOSSwitch
                                  checked={this.state.product_status}
                                  onChange={() => {
                                    this.setState({
                                      product_status:
                                        !this.state.product_status,
                                      openSubmit: true,
                                    });
                                  }}
                                  name="product_status"
                                />
                              }
                              className={classes.switchLabel}
                              label={
                                this.state.product_status
                                  ? "Active"
                                  : "Not active"
                              }
                            />
                          </FormGroup>
                          <Typography
                            style={{
                              fontFamily: "Rubik",
                              fontSize: "12px",
                              fontWeight: "400",
                              lineHeight: "16px",
                              color: "#83889E",
                            }}
                          >
                            Your product is not visible on your store
                          </Typography>
                        </Grid>
                      </Grid>
                      <hr style={{ border: "1px solid #E8E8E8" }} />

                      <Grid
                        item
                        sm={12}
                        style={{ marginBottom: "10px", padding: "16px" }}
                      >
                        <Typography
                          variant="h6"
                          component="h6"
                          className={classes.titleText}
                        >
                          Availability *
                        </Typography>
                        {this.selectAvailability(
                          setFieldValue,
                          touched,
                          errors,
                          handleBlur,
                          classes
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </form>
          )}}
        </Formik>
      </>
    );
  };
  variantsContentTable = (classes: any) => {
    if (!this.state.productVariants?.length) {
      return (
        <>
          <Typography
            style={{
              fontFamily: "Rubik",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "24px",
              color: "#3C3E49",
              marginTop: "8px",
            }}
          >
            To offer customisations on this menu item, add them below. For
            example- Crust Types, Size, Toppings, Ingredients, etc.
          </Typography>
        </>
      );
    }
    return (
      <>
        <Typography
          style={{
            fontFamily: "Rubik",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "24px",
            color: "#3C3E49",
            marginTop: "8px",
          }}
        >
          If this menu item has different options, like crust or toppings, add
          them below
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.variantTableTitle} style={{}}>
                {" "}
                Title
              </TableCell>
              <TableCell className={classes.variantTableTitle} style={{}}>
                {" "}
                Options
              </TableCell>
              <TableCell className={classes.variantTableTitle} style={{}}>
                {" "}
                Price
              </TableCell>
              <TableCell
                className={classes.variantTableTitle}
                style={{
                  paddingRight: "40px",
                }}
                align="right"
              >
                Edit
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ border: "2px", background: "#fff" }}>
            {this.state.productVariants &&
              this.state.productVariants.map(
                (variantArray: any, index: any) => {
                  let variant = { ...variantArray };
                  variant.options = variantArray?.options.filter(
                    (option) => option.data.attributes.status
                  );

                  return (
                    <>
                      <TableRow
                        key={variant.id}
                        style={{ border: "2px solid #ECEDF0" }}
                      >
                        <TableCell
                          rowSpan={
                            variant?.options?.length == 0
                              ? 1
                              : variant?.options?.length
                          }
                          className={classes.tableData}
                        >
                          {variant.title}
                        </TableCell>
                        <TableCell
                          className={classes.tableData}
                          style={{ borderLeft: "2px solid #ECEDF0" }}
                        >
                          {variant?.options.length == 0
                            ? ""
                            : variant?.options[0]?.data.attributes.option_name}
                        </TableCell>
                        <TableCell
                          className={classes.tableData}
                          style={{ borderRight: "2px solid #ECEDF0" }}
                        >
                          {variant?.options.length == 0
                            ? ""
                            : variant?.options[0]?.data.attributes.price}
                        </TableCell>
                        <TableCell
                          rowSpan={
                            variant?.options?.length == 0
                              ? 1
                              : variant?.options?.length
                          }
                          align="right"
                        >
                          <img
                            src={editIcon}
                            style={{ cursor: "pointer" }}
                            data-testId="editVariantBtn"
                            alt="edit icon"
                            onClick={() => {
                              this.setState(
                                {
                                  searchSelectedVariant: variantArray,
                                },
                                () => {
                                  this.setState({ isOpen: true,openSubmit: true, });
                                }
                              );
                            }}
                          />
                          <img
                            src={crossIcon}
                            style={{
                              cursor: "pointer",
                              marginLeft: "15px",
                              height: "18.33px",
                              width: "18.33px",
                            }}
                            data-testId="deleteVariantBtn"
                            onClick={() => {
                              this.setState({
                                productVariants:
                                  this.state.productVariants.filter(
                                    (v) => variant.id != v.id
                                  ),
                                  openSubmit: true,
                              });
                            }}
                            alt="delete icon"
                          />
                        </TableCell>
                      </TableRow>
                      {variant.options
                        .slice(1)
                        .map((option: any, index: any) => {
                          return (
                            <TableRow style={{ border: "2px solid #ECEDF0" }}>
                              <TableCell
                                className={classes.tableData}
                                style={{ borderLeft: "2px solid #ECEDF0" }}
                              >
                                {option.data.attributes.option_name}
                              </TableCell>
                              <TableCell
                                className={classes.tableData}
                                style={{ borderRight: "2px solid #ECEDF0" }}
                              >
                                {option.data.attributes.price}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </>
                  );
                }
              )}
          </TableBody>
        </Table>
      </>
    );
  };
  renderVaraints = (product: any, classes: any) => {
    return (
      <>
        <Typography
          variant="h6"
          component="h6"
          className={{ ...classes.titleText }}
        >
          Variants
        </Typography>
        {this.variantsContentTable(classes)}
        <Button
          variant="contained"
          className={classes.variantButton}
          style={{
            background: "#00D659",
            marginTop: "12px",
            textTransform: "none",
            boxShadow: "none",
            borderRadius: "3px",
            fontFamily: "Rubik",
            fontSize: "12px",
            fontweight: "700",
            lineHeight: "18px",
            padding: "10px 26px 10px 26px",
            letterSspacing: "0em",
            color: " #3C3E49",
          }}
          data-testId="addVarintBtn"
          // disabled={!this.state.product_Id}
          onClick={() => {
            this.setState({ searchVariantModalOpen: true, openSubmit: true });
          }}
        >
          Add Variants
        </Button>
        <AddVariantSearch
          data-testId="changeVariantsState"
          onSubmit={this.changeVariantsState}
          selectedVariantsclasses={classes}
          isItemEdit={true}
          isInventoryAdd={true}
          productId={this.state.product_Id}
          parentState={this.state}
          closeSearch={() => {
            this.setState({ searchVariantModalOpen: false });
          }}
          openAdd={() => {
            this.setState({ isOpen: true });
          }}
          closeAdd={() => {
            this.setState({ isOpen: false });
          }}
        />
        {this.state.isOpen && (
          <AddEditVariant
            searchSelectedVariant={{
              variant: {
                title: this.state.searchSelectedVariant.title,
                variant_type: this.state.searchSelectedVariant.variant_type,
                id: this.state.searchSelectedVariant.id,
                min: this.state.searchSelectedVariant.min,
                max: this.state.searchSelectedVariant.max
              },

              options: this.state.searchSelectedVariant.options.map(
                (option) => {
                  return {
                    id: option.data.attributes.id,
                    option_name: option.data.attributes.option_name,
                    price: option.data.attributes.price,
                    status: option.data.attributes.status,
                  };
                }
              ),
            }}
            isEdit={true}
            isEditStatus={true}
            handleClose={(apiresponse) => {
              this.setState({ isOpen: false });
            }}
            modifyDatapass={this.changeVariantsState}
            showEditeSuccessPopup={() => { }}
            showCreateSuccessPopup={() => { }}
          />
        )}
      </>
    );
  };
  changeVariantsState = (productVariant) => {
    this.setState({
      productVariants: [
        ...this.state.productVariants.filter((v) => v.id !== productVariant.id),
        productVariant,
      ],
    });
  };

  renderScreen(classes: any, filter: any) {
    return !this.state.isAddEdit
      ? this.renderProduct(classes)
      : this.addEditProductBlock(classes, filter);
  }

  handleChildChange = (event, parent, child) => {
    let newSelected = [...this.state.selectedFoodLabels];
    if (!event) {
      newSelected = [...newSelected, child.value];
    } else {
      newSelected = newSelected.filter((category) => category !== child.value);
      if (!parent.children.some((c) => newSelected.includes(c.value))) {
        newSelected = newSelected.filter((category) => category !== parent.name);
      }
    }
    this.setState({ selectedFoodLabels: newSelected });
  };

  handleApplyFilter = () => {
    this.handleDialogClose();
    this.setState({ appliedFoodLables: this.state.selectedFoodLabels })
    this.searchProduct();
  };

  handleClearAll = () => {
    this.setState({ selectedFoodLabels: [] });
  };

  handleFoodLabelsFilterDelete = (foodLabel) => {
    let newSelected = [...this.state.selectedFoodLabels];
    newSelected = newSelected.filter((food) => food != foodLabel)
    this.setState({ selectedFoodLabels: newSelected, appliedFoodLables: newSelected })
    this.searchProduct();
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const filter = createFilterOptions();

    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <SubmitButtonPopup
          data-testId="categorySubmit"
          open={this.state.openSubmit}
          close={this.discardBtn}
          submit={() => this.submitRef.current.click()}
        />
        <AlertMessage
          data-testId="alertMessage"
          open={this.state.openAlert}
          success={this.state.alertSuccess}
          message={this.state.alertMessage}
          close={this.handleAlertMessageClose}
        />
        <Scrollbars>
          {
            this.state.loading ?
              <SectionLoader loading={true} /> :
              <>
                {
                  this.state.productsData?.length > 0 ?
                    <>
                      <Grid container item lg={12} md={12} xs={12} className={classes.root}>
                        <Grid item lg={11} md={11} xs={11}>
                          {
                            this.renderScreen(classes, filter)
                          }
                        </Grid>
                      </Grid>
                      {!!this.state.pagination?.total_count && !this.state.isAddEdit && !this.state.noSearchCategories > 0 && <Box marginBottom={4}>
                        <Pagination
                          data-testId="pagination"
                          count={this.state.pagination?.total_count}
                          onChange={this.handlePageChange}
                          pageSize={this.state.pagination?.per_page}
                          itemText="Menu Items"
                          pageNo={this.state.pagination?.current_page || 1}
                        /></Box>}
                    </> :
                    <Grid container item lg={12} md={12} xs={12} className={classes.root}>
                      <Grid item lg={11} md={11} xs={11}>
                        {
                          this.state.isAddEdit ?
                          this.addEditProductBlock(classes, filter) :
                          <NoItems addItem={this.openAddItemsForm} onAddFromLibraryClick={this.navigateToItemsLibrary}/>
                         }
                      </Grid>
                    </Grid>
                }
              </>
          }
        </Scrollbars>
        <Dialog onClose={this.handleDialogClose} aria-labelledby="customized-dialog-title"
          open={this.state.isDialogOpen}
          fullWidth
          PaperProps={{
            style: {
              borderRadius: '3px',
              width: '309px',
              border: '0px solid #D0D2DA',
            },
          }}>
          <DialogTitle style={{ padding: "16px 24px 5px 24px" }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span style={{
                flexGrow: 1,
                fontFamily: 'Rubik',
                fontSize: '18px',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '-0.02em',
                textAlign: 'left',
                color: '#3C3E49',
              }}
                data-testid="filterTestId"
              >
                Filter
              </span>

              <IconButton aria-label="close" style={{ marginRight: "-12px" }} onClick={this.handleDialogClose}>
                <img src={close} alt="close" />
              </IconButton>
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingTop: '0.5rem',
              marginTop: '0.5rem',
              borderBottom: '1px solid #ECEDF0',
              paddingBottom: '10px'
            }}>
              <span className={classes.OrderTextStyle} data-testid="appoinmentDataTestID">
                Food Label
              </span>
              {this.state.isFoodLabelOpen ? (
                <img data-testid="AppoinmentCloseArrow" src={chevron_up} style={{ cursor: "pointer" }} alt="up Arrow" onClick={() => this.setIsFoodLableClose()} />
              ) : (
                <img src={chevron_down} style={{ width: '24px', height: '25px', cursor: "pointer" }} alt="pencil Icon" onClick={this.setIsFoodLabelOpen} data-testid="AppoinmentOpenArrow" />
              )}
            </div>
          </DialogTitle>
          <DialogContent style={{ padding: "0px 24px" }}>
            <FormGroup>
              {this.state.isFoodLabelOpen && this.state.foodLablesData.map((parent) => (
                <Grid container key={parent.id}>
                  <Accordion style={{ width: "100%", boxShadow: "none" }} expanded={this.state.expanded == parent.name}>
                    <AccordionSummary data-testId="handleOpenAccordion" expandIcon={<ExpandMoreIcon onClick={() => this.handleOpenAccordion(parent.name)} />} className={classes.foodFilter} style={{ padding: "0px" }}>
                      <Grid item xs={12}>
                        <MenuItem
                          key={parent.id}
                          value={parent.value}
                          style={{ marginLeft: "-25px" }}
                          className={classes.foodLabelMenu}
                          data-testId="handleParentChange"
                          onClick={() => {
                            this.handleParentChange(parent.children.every((c) => this.state.selectedFoodLabels.includes(c.value)), parent)
                          }}
                        >
                          <PurpleCheckbox
                            className={classes.menuItemCheckbox}
                            checked={parent.children.every((c) => this.state.selectedFoodLabels.includes(c.value))}
                            icon={<span className={classes.icon} />}
                            checkedIcon={<span className={classes.checkedIcon} />}
                          />
                          <Grid
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Grid className={classes.menuItemName} style={{ fontSize: "14px" }}>{parent.name}</Grid>
                          </Grid>
                        </MenuItem>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: "0px" }}>
                      <Grid item xs={12} >
                        {parent.children.map((child) => (
                          <MenuItem
                            key={child.id}
                            value={child.value}
                            data-testId="handleChildChange"
                            onClick={() => this.handleChildChange(this.state.selectedFoodLabels.includes(child.value), parent, child)}
                          >
                            <PurpleCheckbox
                              className={classes.menuItemCheckbox}
                              checked={this.state.selectedFoodLabels.includes(child.value)}
                              icon={<span className={classes.icon} />}
                              checkedIcon={<span className={classes.checkedIcon} />}
                            />
                            <Grid
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Grid
                                style={{
                                  marginRight: "5px",
                                }}
                              >
                                <img
                                  src={child.img}
                                  alt={child.name}
                                  style={{ height: "28px", width: "28px" }}
                                />
                              </Grid>
                              <Grid className={classes.menuItemName} style={{ fontSize: "14px" }}>{child.name}</Grid>
                            </Grid>
                          </MenuItem>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
            </FormGroup>
          </DialogContent>
          <DialogActions style={{ padding: "0px", marginTop: "10px" }}>
            <Button style={{
              fontFamily: "Inter",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "18px",
              color: "#3C3E49",
              textTransform: "none"
            }} data-testId="clearall" onClick={this.handleClearAll}>
              Reset all
            </Button>
            <Button style={{
              fontFamily: "Inter",
              fontSize: "12px",
              fontWeight: 700,
              lineHeight: "18px",
              color: "#3C3E49",
              padding: "10px 32px", background: "#00D659", borderRadius: "3px", textTransform: "none"
            }} data-testId="applyfilter" onClick={this.handleApplyFilter}>
              Apply
            </Button>
          </DialogActions>
        </Dialog >
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const useStyles: Record<string, CSSProperties> = {
  root: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#FAFAFA",
  },
  variantTableTitle: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    letterSpacing: "0em",
    color: "#3C3E49",
  },
  noCategoriesBox: {
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "50px",
    width: "684px",
    height: "309px",
    marginTop: "100px",
  },
  categoriesContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  variantButton: {
    background: "#00D659",
    textTransform: "none",
    boxShadow: "none",
    borderRadius: "3px",
    fontFamily: "Rubik",
    fontSize: "12px",
    fontweight: "700",
    lineHeight: "18px",
    padding: "10px 26px 10px 26px",
    letterSspacing: "0em",
    color: " #3C3E49",
  },
  discardButton: {
    textDecoration: "underline",
    paddingRight: "20px",
    textTransform: "none",
  },
  categoryButton: {
    background: "#00D659",
    textTransform: "none",
    padding: "10px 26px",
    borderRadius: "3px",
    gap: "8px",
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    color: "#3C3E49",
  },
  backArrow: {
    display: "flex",
    alignItems: "center",
    marginTop: "30px",
  },
  titleText: {
    marginBottom: "5px",
    fontSize: "14px",
    fontFamily: "Rubik",
  },
  foodLables: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "2px",
    height: "28px",
    width: "28px",
  },
  bottomSpace: {
    marginBottom: "20px",
  },
  foodLablesCount: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "2px",
    border: "1px solid #E2E8F0",
    borderRadius: "50%",
    height: "28px",
    width: "28px",
  },
  dataText: {
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "16px",
    color: "#3C3E49",
  },
  selectFoodLable: {
    "& div": {
      fontFamily: "Rubik",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "24px",
      color: "#64748B",
    },
    "& fieldset": {
      top: "0px",
    },
  },
  headerText: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    color: "#363636",
  },
  menuItemNameSearch: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "22px",
    color: "#334155",
  },
  menuList: {
    "&:hover": {
      background: "#F6F0FF",
    },
    "&.Mui-selected": {
      background: "#F6F0FF",
    },
  },
  menuItemCheckbox: {
    "&:hover": {
      background: "rgba(0, 0, 0, 0.08)",
    },
    "&.Mui-checked": {
      color: "#6200EA",
    },
  },
  countText: {
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "16px",
  },
  menuItemName: {
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "19px",
    color: "#646464",
  },
  placeholderText: {
    "& input, & textarea, & div": {
      fontFamily: "Rubik",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "22px",
      color: "#64748B",
    },
    "& fieldset": {
      top: "0px",
    },
  },
  chipText: {
    background: "#F6F0FF",
    "& span": {
      fontFamily: "Rubik",
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "16px",
      color: "#646464",
    },
  },
  imageText: {
    fontFamily: "Rubik",
    fontWeight: "500",
    fontSize: "16px",
    color: "#676B7E",
  },
  categoryText: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "22px",
    paddingBottom: "10px",
    color: "#676B7E",
  },
  switchLabel: {
    "& .MuiTypography-body1": {
      fontFamily: "Rubik",
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "16px",
      color: "#646464",
    },
  },
  InputStyle: {
    width: "100%",
    "& > div > div": {
      height: "41px",
      borderRadius: "0px 3px 3px 0px",
    },
    "& > div": {
      background: "#FFFFFF",
    },
    "& input": {
      fontFamily: "Rubik",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "22px",
      color: "#AFB2C0",
    },
    "& fieldset": {
      border: "1px solid #D0D2DA",
      top: "0px",
    },
  },
  icon: {
    border: "1px solid #64748B",
    borderRadius: "3px",
    height: "20px",
    width: "20px",
  },
  checkedIcon: {
    backgroundColor: "#6200EA",
    borderRadius: "3px",
    // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    "&:before": {
      display: "block",
      borderRadius: "3px",
      width: 20,
      height: 20,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
  },
  OrderTextStyle: {
    fontFamily: 'Rubik',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#3C3E49',
    width: '122px',
    height: '23px',
    top: '0.5px'
  },
  foodFilter: {
    "& > div": {
      margin: "0px !important"
    },
    "& .MuiAccordionSummary-expandIcon": {
      padding: "0px"
    }
  },
  foodLabelMenu: {
    "&:hover": {
      background: "none"
    }
  },
  MenuActionBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
    marginLeft: 8,
  },
  MenuActionButton: {
    padding: "10px 26px 10px 20px",
    border: "1px solid #CBD5E1",
    borderRadius: 3,
    width:226,
    "& .Text": {
        fontFamily: "Rubik",
        fontSize: 16,
        fontWeight: 500,
        textAlign: 'start' as const,
        lineHeight: 1.4,
        color: '#3C3E49',
        textTransform: 'initial',
        whiteSpace: 'nowrap',
        paddingBottom:"3px"
    }
  },
  ActionButtonSection:{
    display:"flex",
    flexDirection:"column",
    textAlign:"start"
  },
  ActionButtonMsg:{
    color:"#475569",
    fontSize:"12px",
    fontWeight:400,
    textTransform:"none"
  },
  packageDetailsGridItem:{
    marginBottom:16
  },
  packageDetailsTitleText:{
    fontFamily:'Inter',
    fontSize:14,
    fontWeight:700,
    lineHeight:1.3,
    marginBottom:16,
  },
  packageDetailsSubTitleText:{
    fontFamily:'Rubik',
    fontSize:12,
    fontWeight:500,
    lineHeight:1.3,
    marginBottom:8,
  },
  packageDetailTextField: {
    "& input, & div": {
      fontFamily: "Rubik",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "22px",
      color:"#64748B"
    },
    "& fieldset": {
      top:0,
      borderRadius:3,
      border:'1px solid #D0D2DA'
    },
  },
};

export default withLayout(withStyles(useStyles)(InventoryManagement));
// Customizable Area End
// Customizable Area End
