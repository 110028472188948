// Customizable Area Start
import React from "react";
import PaymentController from "./PaymentController.web";
import { PaymentMethods } from "./PaymentMethods.web";
import { PaymentGatewayActivision } from "./PaymentGatewayActivision.web";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import "../assets/styles.css";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout"
import Header from "../../ss-cms-common-components/src/Header/Header.web";
import MuiTab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
const configJSON = require("./config");

export class Payment extends PaymentController {

  renderShippingTabsCompontents(tabIndex: number) {
    if (tabIndex === 0) {
      return (
        <PaymentMethods
          id="PaymentMethods"
          setDialogState={this.openErrorHeaderBar}
          hideLoader={this.props.hideLoader}
          showLoader={this.props.showLoader}
          showHeader={this.props.showHeaderBar}
        />
      );
    } else {
      return (
        <PaymentGatewayActivision 
          id="PaymentGatewayActivision"
          setDialogState={this.openErrorHeaderBar}
          hideLoader={this.props.hideLoader}
          showLoader={this.props.showLoader}
          showHeader={this.props.showHeaderBar}
        />
      )
    }
  }

  render() {
    const { pageInitialized, view} = this.state;
    if (!pageInitialized) {
      return null;
    }

    return (
      <div className="payment">
        <div style={{ paddingLeft: "2px" }}>
          <Header title={configJSON.payment} />
        </div>
        <div className="content">
          <div className="payment_tabs" style={{ flex: 1 }}>
            <Tabs
              value={view}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              className="tabFlexContainer tabsroot"
            >
              <MuiTab
                className={
                  view === 0 ? "tabIconWrapperSelected tablabelIcon tabroot" : "tabIconWrapper tablabelIcon tabroot"
                }
                data-testid="list-tab"
                label={configJSON.paymentMethods}
              />
              <MuiTab
                className={
                  view === 1 ? "tabIconWrapperSelected tablabelIcon tabroot" : "tabIconWrapper tablabelIcon tabroot"
                }
                data-testid="card-tab"
                label={configJSON.paymentGateway}
              />
            </Tabs>
          </div>
          {this.renderShippingTabsCompontents(view)}
        </div>        
      </div>
    );
  }
}

export default withLayout(withDialog(withLoader(withHeaderBar(Payment))));
// Customizable Area End
