Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.notificationsHeader = "Notifications";
exports.deleteNotifications = "Delete notifications";
exports.createNewNotification = "Create new notification ";
exports.youHaveNoNotifications = "You have no notifications";
exports.youHaveNotSetUpNotificationMessages = "You have not set up notification messages yet.";
exports.notificationMessage = "Once you have created notification(s), you will be able to push them to all registered customers and it will appear in their ‘My account’ section.";

exports.thisFieldIsRequired = "This field is required.";
exports.titleMustBeMaximum_65Characters = "Title must be maximum 65 characters";
exports.messageMustBeMaximum_178Characters = "Message must be maximum 178 characters";
exports.saveLabel = "Save";
exports.createLabel = "Create";
exports.editLabel = "Edit";
exports.notificationLabel = "notification";
exports.titleLabel = "Title";
exports.titlePlaceholder = "Title";
exports.messageLabel = "Message";
exports.messagePlaceholder = "Message";
exports.errorOnPageInitialization = "Error on page initialization";
exports.somethingWentWrong = "Something went wrong";

exports.notificationAPiEndPoint = "/bx_block_notification/notification_settings"
exports.getNotificationApiMethodType = "GET";
exports.createNotificationApiMethodType = "POST";
exports.updateNotificationApiMethodType = "PUT";
exports.sendNotificationApiMethodType = "POST";

exports.bulkDeleteAPiEndPoint = "/bx_block_notification/notification_settings/"
exports.bulkDeleteAPiMethodType = "DELETE"

exports.areYouSure = "Are you sure?"
exports.areYouSureYouWantToDeleteThisNotifications = "Are you sure you want to delete this notifications."
exports.sendNotification = "send_notification";

exports.textTableHeaderId = "#";
exports.textTableHeaderTitle = "Title";
exports.textTableHeaderMessage = "Message";
exports.textNotificationSentSuccessfully = "Notification has been sent";
exports.textPaginationItemText = "notifications";
// Customizable Area End