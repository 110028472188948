// Customizable Area Start
import React from "react";
import {
    Box, Grid, Typography, withStyles, Table,
    TableCell,
    TableHead,
    TableRow,
    TableBody,
    MenuItem,
    Select,
    withWidth
} from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { backIcon, printIcon } from "./assets";
import SubmitButtonPopup from "../../../components/src/SubmitButtonPopup.web";
import moment from "moment";
import OrderDetailController, {
    Props,
    configJSON,
    OrderItem,
    OrderStatus
} from "./OrderDetailController.web";
import AlertMessage from "../../../components/src/AlertMessage.web";
import { Alert } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Customizable Area End

export class OrderDetail extends OrderDetailController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    orderDetailBlock = (classes: Record<string, string>) => {
        return (
            <>
                <Grid item container lg={12} xs={12} className={classes.dislpayFlex} >
                    <Grid item lg={6} xs={6} >
                        <Typography className={classes.orderTitle}>{configJSON.orderNumber}</Typography>
                        <Typography className={classes.orderText}>{this.state.orderDetailData?.attributes?.order_number}</Typography>
                    </Grid>
                    <Grid item lg={6} xs={6} >
                        <Typography className={classes.orderTitle}>{configJSON.orderType}</Typography>
                        <Typography className={classes.orderText}>{this.state.orderDetailData?.attributes?.order_type == configJSON.collection ? configJSON.collectionType : configJSON.deliveryType}</Typography>
                    </Grid>
                </Grid>
                <Grid item container lg={12} xs={12} className={classes.orderDateBox} >
                    <Grid item lg={6} xs={6} >
                        <Typography className={classes.orderTitle}>{configJSON.orderDate}</Typography>
                        <Typography className={classes.orderText}>{moment(this.state.orderDetailData?.attributes?.order_date).format("MMMM D YYYY, HH:mm")}</Typography>
                    </Grid>
                    <Grid item lg={6} xs={6} >
                        <Typography className={classes.orderTitle}>{configJSON.customer}</Typography>
                        <Typography className={classes.orderText}>{this.state.orderDetailData?.attributes?.account?.attributes?.full_name}</Typography>
                    </Grid>
                </Grid>
            </>
        )
    }
    orderDetailBlockResp = (classes: Record<string, string>) => {
        return (
            <>
                <Grid item container lg={12} xs={12} className={classes.dislpayFlex} >
                    <Grid item lg={7} xs={7}>
                        <Typography className={classes.orderTitle}>{configJSON.orderDate}</Typography>
                        <Typography className={classes.orderText}>{moment(this.state.orderDetailData?.attributes?.order_date).format("DD/MM/YYYY, HH:mm")}</Typography>
                    </Grid>
                    <Grid item lg={5} xs={5}>
                        <Typography className={classes.orderTitle}>{configJSON.order}</Typography>
                        <Typography className={classes.orderText}>{this.state.orderDetailData?.attributes?.order_number}</Typography>
                    </Grid>
                </Grid>
                <Grid item container lg={12} xs={12} className={classes.orderDateBox} >
                    <Grid item lg={7} xs={7}>
                        <Typography className={classes.orderTitle}>{configJSON.orderType}</Typography>
                        <Typography className={classes.orderText}>{this.state.orderDetailData?.attributes?.order_type == configJSON.collection ? configJSON.collectionType : configJSON.deliveryType}</Typography>
                    </Grid>
                    <Grid item lg={5} xs={5}>
                        <Typography className={classes.orderTitle}>{configJSON.customer}</Typography>
                        <Typography className={classes.orderText}>{this.state.orderDetailData?.attributes?.account?.attributes?.full_name}</Typography>
                    </Grid>
                </Grid>
            </>
        )
    }
    orderStatusSelect = (classes: Record<string, string>) => {
        return (
            <Grid item className={classes.mt10}>
                <Typography className={classes.orderStatusText}>{configJSON.orderStatus}</Typography>
                <Select
                    name="order_status"
                    fullWidth
                    variant="outlined"
                    data-test-id={`selectelement`}
                    value={this.state.orderStatusSelect}
                    className={classes.orderSelectStatus}
                    onChange={(e: React.ChangeEvent<{ name?: string; value: unknown }>) => { this.handleChange(e?.target?.value as string) }}
                    style={{
                        color: this.getStatusTextColor(this.state.orderStatusSelect),
                        backgroundColor: this.getStatusColor(this.state.orderStatusSelect),
                    }}
                    IconComponent={
                        () => (
                        <ExpandMoreIcon 
                            style={{ ...webStyles.iconSelect , ...{
                                color: this.getStatusTextColor(this.state.orderStatusSelect),
                            }}} 
                        />
                        )
                    }
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }}
                    data-testId="order_status"
                >
                    {this.state.orderStatus.map((item: OrderStatus) =>
                        <MenuItem
                            className={classes.dropdownitem}
                            style={{
                                backgroundColor: item.color,
                                color: item.textColor
                            }}
                            key={item.name}
                            value={item.value}
                            onClick={() => this.selectOption(item.key)}
                        >
                            {item.value}
                        </MenuItem>
                    )}
                </Select>
            </Grid>
        )
    }
    orderItemsTableBlock = (classes: Record<string, string>, currencyType: string) => {
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.headerText}>{configJSON.item}</TableCell>
                        <TableCell className={classes.headerText} align="center">{configJSON.quantity}</TableCell>
                        <TableCell className={classes.headerText} align="right">{configJSON.totalPrice}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={classes.backgroundWhite} >
                    {this.state.orderDetailData?.attributes?.order_items &&
                        this.state.orderDetailData?.attributes?.order_items?.length > 0 && (
                            this.state.orderDetailData?.attributes?.order_items.map((item: OrderItem,) => {
                                return (
                                    <TableRow
                                        key={item.id}
                                        data-test-id="tablerows"
                                    >
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            className={classes.dataText}
                                        >
                                            {item.attributes.catalogue.attributes.name}
                                            <Typography className={classes.fieldvalue}>
                                                {this.getVariants(item.attributes.catalogue_variants)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            className={classes.dataText}
                                            align="center"
                                        >
                                            {item.attributes.quantity}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            className={classes.dataText}
                                            align="right"
                                        >
                                            {currencyType}{item.attributes.total_price}
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        )}
                    <TableRow
                        key={this.state.orderDetailData?.id}
                        className={classes.tableFooterBG}
                        data-test-id="tablerows"
                    >
                        <TableCell
                            component="th"
                            scope="row"
                            className={classes.dataTextFooter}
                        >
                            {configJSON.total}
                        </TableCell>
                        <TableCell
                            component="th"
                            scope="row"
                            className={classes.dataTextFooter}
                            align="center"
                        >
                            {this.state.totalQuantity}
                        </TableCell>
                        <TableCell
                            component="th"
                            scope="row"
                            className={classes.dataTextFooter}
                            align="right"
                        >
                            {currencyType}{this.state.orderDetailData?.attributes?.sub_total}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        )
    }
    orderPaymentBlock = (classes: Record<string, string>, currencyType: string) => {
        return (
            <Grid item xs={12} >
                <Grid className={`${classes.smItems}`} >
                    <Typography className={classes.subTotalText}>{configJSON.subTotal}</Typography>
                    <Typography className={classes.subTotalText} data-test-id="subtotalValue">{currencyType}{this.state.orderDetailData?.attributes?.sub_total}</Typography>
                </Grid>
                {this.state.orderDetailData?.attributes?.promo_code &&
                    <Grid className={`${classes.smItems}`}>
                        <Typography ><span className={classes.couponText}>{this.state.orderDetailData?.attributes?.promo_code}</span><span className={classes.appliedText}>Applied</span></Typography>
                        <Typography className={classes.taxesText} data-test-id="subtotalValue">-{currencyType}{this.state.orderDetailData?.attributes?.applied_discount}</Typography>
                    </Grid>
                }
                <Grid className={`${classes.smItems}`} >
                    <Typography className={classes.taxesText}>{configJSON.taxes}</Typography>
                    <Typography className={classes.taxesText} data-test-id="subtotalValue">{currencyType}{this.state.orderDetailData?.attributes?.total_tax}</Typography>
                </Grid>
                <Grid className={`${classes.smItems}`} >
                    <Typography className={classes.taxesText}>{configJSON.deliveryCharges}</Typography>
                    <Typography className={classes.taxesText} data-test-id="subtotalValue">{configJSON.free}</Typography>
                </Grid>
                <Grid className={`${classes.smItems}`} >
                    <Typography className={classes.totalText}>{configJSON.total}</Typography>
                    <Typography className={classes.totalText} data-test-id="subtotalValue">{currencyType}{this.state.orderDetailData?.attributes?.total}</Typography>
                </Grid>
            </Grid>
        )
    }
    orderAddressBlock = (classes: Record<string, string>) => {
        return (
            <Grid item className={classes.mt10}>
                {this.state.orderDetailData?.attributes?.account?.attributes?.full_name && <Typography className={classes.deliveryAddressText}>{this.state.orderDetailData?.attributes?.account?.attributes?.full_name}</Typography>}
                {this.state?.orderDetailData?.attributes?.order_type == configJSON.collection ?
                    <>
                        {this.state.orderDetailData?.attributes?.delivery_address?.attributes?.address && <Typography className={classes.deliveryAddressText}>{this.state.orderDetailData?.attributes?.delivery_address?.attributes?.address}</Typography>}
                        {this.state.orderDetailData?.attributes?.delivery_address?.attributes?.address_line_2 && <Typography className={classes.deliveryAddressText}>{this.state.orderDetailData?.attributes?.delivery_address?.attributes?.address_line_2}</Typography>}
                        {this.state.orderDetailData?.attributes?.delivery_address?.attributes?.city && <Typography className={classes.deliveryAddressText}>{this.state.orderDetailData?.attributes?.delivery_address?.attributes?.city}</Typography>}
                        {this.state.orderDetailData?.attributes?.delivery_address?.attributes?.state && <Typography className={classes.deliveryAddressText}>{this.state.orderDetailData?.attributes?.delivery_address?.attributes?.state}</Typography>}
                        {this.state.orderDetailData?.attributes?.delivery_address?.attributes?.country && <Typography className={classes.deliveryAddressText}>{this.state.orderDetailData?.attributes?.delivery_address?.attributes?.country}</Typography>}
                        {this.state.orderDetailData?.attributes?.delivery_address?.attributes?.zip_code && <Typography className={classes.deliveryAddressText}>{this.state.orderDetailData?.attributes?.delivery_address?.attributes?.zip_code}</Typography>}
                    </>
                    :
                    <>
                        {this.state.orderDetailData?.attributes?.delivery_address?.attributes?.flat_no && <Typography className={classes.deliveryAddressText}>{this.state.orderDetailData?.attributes?.delivery_address?.attributes?.flat_no}</Typography>}
                        {this.state.orderDetailData?.attributes?.delivery_address?.attributes?.landmark && <Typography className={classes.deliveryAddressText}>{this.state.orderDetailData?.attributes?.delivery_address?.attributes?.landmark}</Typography>}
                        {this.state.orderDetailData?.attributes?.delivery_address?.attributes?.address_line_2 && <Typography className={classes.deliveryAddressText}>{this.state.orderDetailData?.attributes?.delivery_address?.attributes?.address_line_2}</Typography>}
                        {this.state.orderDetailData?.attributes?.delivery_address?.attributes?.address && <Typography className={classes.deliveryAddressText}>{this.state.orderDetailData?.attributes?.delivery_address?.attributes?.address}</Typography>}
                    </>
                }
                {this.state.orderDetailData?.attributes?.account?.attributes?.phone_number && <Typography className={classes.deliveryAddressText}>+{this.state.orderDetailData?.attributes?.account?.attributes?.country_code} {this.state.orderDetailData?.attributes?.account?.attributes?.phone_number}</Typography>}
            </Grid>
        )
    }

    renderShipRocketMessage() {
        const { classes } = this.props;
        if (this.state.shipRocketMessage !== "") {
            return (
                <Box className={classes.storeAvailabilityContainer}>
                    <Alert 
                        data-test-id="shipRocketMessage"
                        icon={false} className={this.state.shipRocketMessage === configJSON.shipRocketFailed ? `${classes.shipRocketFailed}` : `${classes.storeAvailabilityAlertBox}`}
                        onClose={() => this.setState({ shipRocketMessage: '' })}
                    >
                        <Typography className={"warningText"}>
                            {this.state.shipRocketMessage}
                        </Typography>
                    </Alert>
                </Box>
            )
        } else return <></>
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, width } = this.props;
        const isMobile = /s/.test(width);
        const currencyType = this.props.currencyType
        const isFirefox = navigator?.userAgent?.toLowerCase()?.indexOf('firefox') > -1;

        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <SubmitButtonPopup
                    data-test-id="customerSubmit"
                    open={this.state.openSubmit}
                    close={this.discardBtn}
                    submit={this.updateOrderStatus}
                />
                <AlertMessage
                    data-test-id="orderAlertMsg"
                    open={this.state.openOrderAlert}
                    success={this.state.alertOrderSuccess}
                    message={this.state.alertOrderMessage}
                    close={this.handleOrderAlertMessageClose}
                />
                {!isMobile ?
                    <>
                        <Box className={classes.mt40}>
                            <Box className={classes.backIconContainer} >
                                <img src={backIcon} alt={configJSON.backIcon} className={classes.backIcon} onClick={() => this.props.goToBack()} />
                                <p className={classes.backText}>{configJSON.back}</p>
                            </Box>
                            <Box width='100%' display='flex' justifyContent='space-between' alignItems='flex-end'>
                                <span
                                    data-test-id="title"
                                    className={`${classes.headerTitle}`}
                                >
                                    {configJSON.orderDetails}
                                </span>
                            </Box>
                        </Box>
                        {this.renderShipRocketMessage()}
                        {this.state.orderDetailData &&
                            <Box width='100%' marginTop='40px'>
                                <Grid item container xs={12} md={12} lg={12}>
                                    <Grid item xs={12} md={12} lg={12} className={classes.orderBox}>
                                        <Grid item lg={9} md={9} xs={12} className={classes.gridBox}>
                                            {this.orderDetailBlock(classes)}
                                        </Grid>
                                        <Grid item lg={3} md={3} xs={12} className={classes.gridBox}>
                                            <Typography className={classes.orderText}>{configJSON.status}</Typography>
                                            {this.orderStatusSelect(classes)}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} className={classes.orderBox}>
                                        <Grid item lg={9} md={9} xs={12} className={classes.gridBox}>
                                            <Grid item lg={12} className={classes.mb20}>
                                                {this.orderItemsTableBlock(classes, currencyType)}
                                            </Grid>
                                            <Grid item container className={classes.paymentBox}>
                                                <Grid item lg={6} className={classes.pl15}>
                                                    <Typography className={`${classes.orderText} ${classes.mb10}`} >Payment Method</Typography>
                                                    <Typography className={classes.deliveryAddressText}>{this.state.orderDetailData?.attributes?.payment_type == "online" ? "Online" : "Cash On Delivery"}</Typography>
                                                </Grid>
                                                <Grid item lg={5} className={classes.paddiingSubtotal} >
                                                    {this.orderPaymentBlock(classes, currencyType)}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={3} md={3} xs={12} className={`${classes.gridBox} ${classes.deliveryBoxHeight}`}>
                                            <Typography className={classes.orderText}> {this.state?.orderDetailData?.attributes?.order_type == configJSON.collection ? configJSON.collectionaddress : configJSON.deliveryaddress}</Typography>
                                            {this.orderAddressBlock(classes)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                    </> :
                    <Grid item container lg={12} md={12} xs={12}>
                        <Grid item xs={12} className={classes.mg20}>
                            <Box className={classes.dislayFlexAI}>
                                <Grid xs={1} className={classes.dislayFlexAI}>
                                    <img src={backIcon} alt={configJSON.backIcon} className={classes.backIcon} onClick={() => this.props.goToBack()} />
                                </Grid>
                                <Grid xs={11} item className={classes.dislpayFlexJC}>
                                    <Typography>{configJSON.orderDetails}</Typography>
                                </Grid>
                            </Box>
                            <Grid item xs={12} className={classes.printIconRes}>
                                <img src={printIcon} alt={configJSON.printIcon} className={classes.printIcon} />
                            </Grid>
                        </Grid>
                        {this.renderShipRocketMessage()}
                        <Grid item xs={12} className={`detail-scroll ${isFirefox && 'mozilaVariantMOdalScrollbar'} ${classes.orderDetailRes}`}>
                            <Grid item xs={12} className={classes.gridBoxResponsiveDetail}>
                                {this.orderDetailBlockResp(classes)}
                                {this.orderStatusSelect(classes)}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.headerTextRes}>{configJSON.items}</Typography>
                                <Grid item xs={12} className={classes.gridBoxResonsiveTable}>
                                    {this.orderItemsTableBlock(classes, currencyType)}
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.headerTextRes}>{configJSON.paymentMethod} <span className={classes.paymentTypeRes}>{this.state.orderDetailData?.attributes?.payment_type == "online" ? "Online" : "Cash On Delivery"}</span></Typography>
                                <Grid item xs={12} className={classes.gridBoxResponsive}>
                                    {this.orderPaymentBlock(classes, currencyType)}
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.headerTextRes}>{this.state?.orderDetailData?.attributes?.order_type == configJSON.collection ? configJSON.collectionAddress : configJSON.deliveryAddress}</Typography>
                                <Grid item xs={12} className={classes.gridBoxResponsive}>
                                    {this.orderAddressBlock(classes)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyles = {
  iconSelect: {
    position: 'absolute',
    right: "8px",
    pointerEvents: "none",
    width: "20px"
  } as React.CSSProperties,
}

export const useStyles: Record<string, CSSProperties> = {
    orderText: {
        fontFamily: "Rubik",
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "28px",
        letterSpacing: "-0.02em",
        color: "#3C3E49",
        "@media (max-width: 480px)": {
            fontSize: "14px",
        },
    },
    storeAvailabilityContainer:{
      marginBottom: "16px"
    },
    storeAvailabilityAlertBox: {
        borderRadius: "4px",
        padding: "4px 25px 4px 16px",
        margin: '0px 0px 10px',
        borderLeft: "4px solid #D97706",
        backgroundColor: "#FEF3C7",
        "& .warningText":{
          fontSize: 14,
          lineHeight: 1.3,
          fontWeight: 400,
          fontFamily: "Inter",
          color:'#D97706',
          "& .Link":{
              fontWeight: 700,
              textDecoration:'underline',
              cursor:'pointer'
          }
      }
    },
    shipRocketFailed: {
      borderRadius: "4px",
      padding: "4px 25px 4px 16px",
      borderLeft: "4px solid #dc2626",
      backgroundColor: "#fee2e2",
      "& .warningText":{
        fontSize: 14,
        lineHeight: 1.3,
        fontWeight: 400,
        fontFamily: "Inter",
        color:'#DC2626',
        "& .Link":{
            fontWeight: 700,
            textDecoration:'underline',
            cursor:'pointer'
        }
      }
    },
    customdropdown: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "18px",
        letterSpacing: "0em",
        '&::before, &::after': {
            borderBottom: "none !important"
        },
        '&:hover': {
            textDecoration: "none !important"
        }
    },
    appliedText: {
        fontFamily: "Inter",
        fontSize: "11px",
        fontStyle: "italic",
        fontWeight: 500,
        lineHeight: "13px",
        letterSpacing: "0px",
        color: "#64748B",
    },
    backIconContainer: {
        marginTop: '40px',
        width: 'auto',
        marginBottom: '4px',
        height: '24px',
        display: 'flex',
        alignItems: 'center',
        "@media (max-width: 480px)": {
            marginTop: '20px'
        }
    },
    orderTitle: {
        fontFamily: "Rubik",
        fontSize: "10px",
        fontWeight: 400,
        lineHeight: "16px",
        color: "#9B9FB1",
        marginBottom: "5px",
        textTransform: "uppercase",
        "@media (max-width: 480px)": {
            fontWeight: 500,
            color: "#94A3B8",
            textTransform: "none"
        },
    },
    deliveryAddressText: {
        fontFamily: "Rubik",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0px",
        color: "#000",
        wordWrap: "break-word"
    },
    orderStatusText: {
        fontFamily: "Rubik",
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0em",
        marginBottom: "5px",
    },
    backIcon: {
        cursor: 'pointer',
        width: '15px',
        height: '15px'
    },
    backText: {
        width: "100%",
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontWeight: 400,
        marginLeft: '4px',
        marginBottom: '0px !important',
        color: '#676B7E'
    },
    couponText: {
        padding: "6px 8px",
        marginRight: "5px",
        gap: "10px",
        borderRadius: "3px",
        fontFamily: "Rubik",
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "24px",
        letterSpacing: "0px",
        color: "#059669",
        background: "#D1FAE5"
    },
    headerText: {
        fontFamily: "Rubik",
        fontSize: "10px",
        fontWeight: 400,
        lineHeight: "16px",
        letterSpacing: "0.05em",
        color: "#9B9FB1",
        border: "none",
        textTransform: "uppercase",
        "@media (max-width: 480px)": {
            textTransform: "none",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: 700,
            lineHeight: "18px",
            letterSpacing: "0em",
            color: "#94A3B8"
        }
    },
    taxesText: {
        fontFamily: "Rubik",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0px",
        color: "#000",
        "@media (max-width: 480px)": {
            color: "#64748B",
        },
    },
    subTotalText: {
        fontFamily: "Rubik",
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0px",
        color: "#000",
        "@media (max-width: 480px)": {
            color: "#64748B",
        }
    },
    dataText: {
        fontFamily: "Rubik",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "21px",
        letterSpacing: "0px",
        color: "#000"
    },
    dataTextFooter: {
        fontFamily: "Rubik",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0px",
        color: "#5500CC"
    },
    headerTitle: {
        fontFamily: "Rubik",
        fontWeight: 500,
        color: "#3C3E49",
        fontSize: '28px',
        llineHeight: '32px',
        width: 'auto',
        "@media (max-width: 480px)": {
            fontSize: '16px',
            lineHeight: '24px',
            width: '100%',
        }
    },
    orderSelectStatus: {
        "& input, & textarea, & div": {
            padding: "14px 14px"
        },
        "& fieldset": {
            border: "none",
            top: "0px",
        },
    },
    totalText: {
        fontFamily: "Rubik",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0px",
        color: "#000",
        "@media (max-width: 480px)": {
            color: "#334155",
        }
    },

    gridBox: {
        borderRadius: '3px',
        border: '2px solid #ECEDF0',
        backgroundColor: '#FFFFFF',
        padding: '40px'
    },
    gridBoxResponsive: {
        borderRadius: '4px',
        border: '1px solid #ECEDF0',
        backgroundColor: '#FFFFFF',
        padding: '20px',
        margin: "10px 0px",
    },
    smItems: {
        display: "flex",
        justifyContent: 'space-between;',
        padding: "5px 0px",
    },
    mt40: {
        marginTop: "40px"
    },
    orderBox: {
        display: "flex", gap: "12px", marginBottom: "12px"
    },
    dislpayFlex: {
        display: "flex"
    },
    orderDateBox: {
        display: "flex", marginTop: "30px"
    },
    gridBoxResponsiveDetail: {
        borderRadius: '4px',
        border: '1px solid #ECEDF0',
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: "10px",
    },
    gridBoxResonsiveTable: {
        borderRadius: '4px',
        border: '1px solid #ECEDF0',
        backgroundColor: '#FFFFFF',
        margin: "10px 0px",
    },
    headerTextRes: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "15px",
        letterSpacing: "0em",
        color: "#000",
        marginTop: "20px",
    },
    paymentTypeRes: {
        padding: "5px 10px",
        borderRadius: "32px",
        border: "1px solid #BCB1F5",
        background: "#F7F5FF",
        fontFamily: "Inter",
        fontSize: "10px",
        fontWeight: 500,
        lineHeight: "16px",
        letterSpacing: "0em",
        color: "#4F31E4",
        marginLeft: "5px",
    },
    paymentBox: {
        display: "flex", justifyContent: "space-between", borderTop: "2px solid #ECEDF0", paddingTop: "20px"
    },
    pl15: {
        paddingLeft: "15px"
    },
    dislpayFlexJC: {
        display: "flex", justifyContent: "center"
    },
    dislayFlexAI: {
        display: "flex", alignItems: "center"
    },
    mt10: {
        marginTop: "10px"
    },
    backgroundWhite: {
        background: "#fff"
    },
    mb20: {
        marginBottom: "20px"
    },
    mb10: {
        marginBottom: "10px"
    },
    tableFooterBG: {
        background: "#F0E5FF"
    },
    paddiingSubtotal: {
        padding: "0px 15px"
    },
    deliveryBoxHeight: {
        height: "fit-content"
    },
    mg20: {
        margin: "20px 0px"
    },
    printIconRes: {
        display: "flex",
        justifyContent: "end"
    },
    orderDetailRes: {
        height: "74vh",
        overflowY: "auto",
        overflowX: "hidden",
        scrollbarColor: "#5500CC transparent",
        scrollbarWidth: "thin",
        paddingRight: "15px"
    },
    printIcon: {
        height: "24px",
        width: "24px",
        display:'none'
    },

}


export default withWidth()(withStyles(useStyles)(OrderDetail));



// Customizable Area End