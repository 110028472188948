//@ts-nocheck
import React from "react";
import {
  Box,
  Button,
  CardContent,
  Checkbox,
  Grid,
  Radio,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles, styled, withStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CloseIcon from '@material-ui/icons/Close';
import { foodLabelCheck } from "../../blocks/inventorymanagement/src/InventoryManagement.web";
import { Option } from "../../../packages/blocks/shoppingcart/src/OrderConfirmationSummaryController.web";
import { CartItem } from "../../../packages/blocks/shoppingcart/src/CartOrdersController.web";
import Alert from '@material-ui/lab/Alert';

const closeIcon = require("../assets/closeIcon.png");
type ItemRadio = { title: string; name: string; price: number }
const foolLabelColor = (name: string) => {
  switch (name) {
    case "Vegetarian":
      return "#dbfbcb";
    case "Non-Vegetarian":
      return "#fba3a3";
    case "Vegan":
      return "#c3e3bb";
    case "Gluten free":
      return "#ebe3b3";
    case "Halal":
      return "#d3ebcb";
    case "Kosher":
      return "#cbe3fb";
    case "Milk":
      return "#f3f3f3";
    case "Eggs":
      return "#fbfbd3";
    case "Nuts":
      return "#e3cbc3";
    case "Wheat":
      return "#e3d3a3";
    case "Soy":
      return "#fbebab";
    case "Peanuts":
      return "#fbd393";
    case "Celery":
      return "#9bcb9b";
    case "Fish":
      return "#bbd3f3";
    case "Crustaceans":
      return "#d3ebfb";
    case "Lupin":
      return "#e3d3f3";
    case "Mollusc":
      return "#d3ebfb";
    case "Mustard":
      return "#fbf3cb";
    case "Sesame Seeds":
      return "#fbf3cb";
    case "Sulphur Dioxide":
      return "#ebfbfb";
    case "Mild":
      return "#fbebeb";
    case "Spicy":
      return "#fbebeb";
    case "Extra Hot":
      return "#fbc3c3";
    default:
      return "#dbfbcb";
  }
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "42.75rem",
    borderRadius: "12px",
  },
  "& .MuiDialog-paperScrollPaper": {
    maxHeight: "75%",
    marginTop: "-95px",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "100%",
      marginTop: 0,
    }
  },
  "& .MuiDialog-paperWidthSm": {
   maxWidth:'unset !important'
  },
  "& .MuiDialogContent-root": {
    padding: "2px",
  },
  "& .MuiDialogActions-root": {
    padding: "1px",
  },
  typography: {
    fontFamily: "var(--website-builder-body-font)",
  },
}));

const TooltipCustom = withStyles((theme) => ({
  tooltip: {
    height:'42px',
    padding:'12px 16px',
    boxShadow: '0px 8px 32px 0px #0000000F',
    borderRadius:'6px',
    fontWeight:400,
    fontSize:'14px',
    lineHeight:'22px',
    backgroundColor: "var(--website-builder-secondary-light-accent-color)",
    color:"var(--website-builder-secondary-dark-accent-color)",
    fontFamily:"var(--website-builder-body-font)",
  },
}))(Tooltip);


const MyDialog = (props: any) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return <BootstrapDialog {...props} theme={theme} disablePortal={true} fullScreen={fullScreen} maxWidth={fullScreen ? false : 'sm'} />
}

export function ProductDetailDialogue(props: any) {
  const { open, setClose, currencyType, selectedProduct, variantSelect, variantRadio, currentPrice, isEdit } = props;
  const labels=selectedProduct?.attributes.lables ? selectedProduct?.attributes.lables : selectedProduct?.attributes.food_lables
  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  const [currentRadioName, setCurrentRadioName] = React.useState([])
  const [newItem, setNewItem] = React.useState({...selectedProduct})
  const chosenVariant: Array<{name: string; option: Array<string>}> = selectedProduct?.variants ?? []
  const [validoptions, setValidoptions] = React.useState([])


  React.useEffect(() => {
    if(chosenVariant.length > 0) {
      const newArray = []
      selectedProduct?.attributes?.variants.forEach(element => {
        if(element.variant_type === "single") {
          const currentChosenVariant = chosenVariant.find(v => v.name === element.title)
          if(currentChosenVariant) {
            const currentElement = element.options.find(e => e.data.attributes.option_name === currentChosenVariant.option[0])
            newArray.push({ title: element.title, name: currentElement.data.attributes.option_name, price: currentElement.data.attributes.price })
          }
        }
      });
      setCurrentRadioName(newArray)
    }else{
      setCurrentRadioName([])
    }
  }, [chosenVariant.length])

  const isDiscount =
    !(
      selectedProduct?.attributes.after_discount ===
      selectedProduct?.attributes.price
    ) && selectedProduct?.attributes.after_discount;
  const classes = useStyles();

  const onClose = () => {
    setCurrentRadioName([])
    setValidoptions([]);
    setClose()
  }

  const handleRadioClick = (currentRadio: ItemRadio, arrayRadio: Array<ItemRadio>, option: Option, title: string, variantIndex: number, optionIndex: number, parentName: string) => {
    const newRadioName = [...arrayRadio]
    const currentVariantIndex = arrayRadio.findIndex((item) => item.title === title)
    const newVariantsList = [...chosenVariant]
    const currentIndexVariant = newVariantsList.findIndex(variant => variant.name === parentName)
    let currentPrice=0;
    if(currentRadio && currentRadio.price){
      currentPrice=currentRadio.price;
    }
    if (currentRadio?.name === option.data.attributes.option_name) {
      newRadioName[currentVariantIndex].name = ""
      newRadioName[currentVariantIndex].price = 0
      newVariantsList[currentIndexVariant].option = []
      variantRadio({...selectedProduct,variants: [...newVariantsList]}, option, false, currentPrice, variantIndex, optionIndex, parentName)

    } else {
      if (currentVariantIndex >= 0) {
        newRadioName[currentVariantIndex].name = option.data.attributes.option_name
        newRadioName[currentVariantIndex].price = option.data.attributes.price
        newVariantsList[currentIndexVariant].option = [option.data.attributes.option_name]
        variantRadio({...selectedProduct,variants: [...newVariantsList]}, option, true, currentPrice, variantIndex, optionIndex, parentName)

      } else {
        newRadioName.push({ title: title, name: option.data.attributes.option_name, price: option.data.attributes.price })
        newVariantsList.push({name: parentName, option: [option.data.attributes.option_name]})
        variantRadio({...selectedProduct,variants: [...newVariantsList]}, option, true, 0, variantIndex, optionIndex, parentName)

      }
    }
    setNewItem({
      ...selectedProduct,
      variants: newVariantsList
    })
    setCurrentRadioName(newRadioName)
  }

  const handleVariantSelect = (selectedProduct: CartItem, option: Option, event, variantIndex: number, optionIndex: number, parentName: string) => {
    const newVariantsList = [...chosenVariant]
    const currentIndexVariant = newVariantsList.findIndex(variant => variant.name === parentName)
    if(event.target.checked) {
      if (currentIndexVariant >= 0) {
        newVariantsList[currentIndexVariant].option.push(option.data.attributes.option_name)
      } else {
        newVariantsList.push({name: parentName, option: [option.data.attributes.option_name]})
      }
    } else {
      const optionList = [...newVariantsList[currentIndexVariant].option]
      newVariantsList[currentIndexVariant].option = [...optionList.filter(o => o !== option.data.attributes.option_name)]
    }
    setNewItem({
      ...selectedProduct,
      variants: [...newVariantsList]
    })
    variantSelect({...selectedProduct,variants: [...newVariantsList]}, option, event.target.checked, variantIndex, optionIndex, parentName)
  }

  const validateUpdate=()=>{
    let inValidVariants=[]
    for(let variant of selectedProduct.attributes.variants){
      const currentChosenVariant =  selectedProduct.variants.find((chosen) => chosen.name === variant.title);
      if(((currentChosenVariant?.option.length<variant.min && variant.min> 0)  || (currentChosenVariant?.option.length>variant.max && variant.max > 0))){
        inValidVariants.push(variant.id)
      }
      if(!currentChosenVariant && variant.min> 0){
        inValidVariants.push(variant.id)
      }
    }
    if(inValidVariants.length>0){
      setValidoptions(inValidVariants);
      return false;
    }
    else{
      setValidoptions([]);
      props.close(selectedProduct);
    }
  }
 const closeWarningAlert=(id)=>{
    let ids = validoptions.filter(e => e != id);
    setValidoptions(ids)
  }

  const getMessage=(variant,optionLength)=>{
    if(variant.variant_type=='single'){
      return `Choose at least ${variant.min} item`
    }
    else{
      if(!optionLength || optionLength<variant.min){
       return `Choose at least ${variant.min} item`
      }
      else{
       return `Choose up to ${variant.max} items`
      }
    }
  }
  if(props.validationOptions){
    React.useEffect(() => {
        setValidoptions(props.validationOptions);
    },[props.validationOptions.length])
  }


  return (
    <MyDialog
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
    >
      <CloseIcon  className={classes.closeImg} onClick={() => onClose()} />
      <div
        className={`detail-scroll ${isFirefox && 'mozilaVariantMOdalScrollbar'}`}
        style={{ height: "100%", marginRight: "10px", overflowY: "auto", display: "flex", flexDirection: 'column', alignItems: "center", }}
      >
        <div style={cardStyle.cardContentStyles} className="upper-view-product-detail">
          <div className={classes.imgContainer}>
          <img
            src={
              selectedProduct?.attributes.image ||
              selectedProduct?.attributes.images.url
            }
            className={classes.cardImageStyles}
            alt="Card"
            />
            </div>
          <CardContent className={classes.cardContent}>
            <Box className={classes.contentBox}
            >
              <Box className={classes.contentNestedBox}>
                {labels?.map((attr: any, ind: number) => {
                  return (
                    <Grid key={`label_${ind}`} className={classes.foodLables} style={{ backgroundColor: foolLabelColor(attr) }}>
                      <img
                        className={classes.foodLableImg}
                        height={18}
                        width={18}
                        src={foodLabelCheck(attr, false)}
                        alt={attr}
                      />
                      <span className={classes.foodlabel}>{attr}</span>
                    </Grid>
                  );
                })}
              </Box>
              <Typography
                component="h5"
                gutterBottom
                variant="h5"
                style={cardStyle.titleStyles}
              >
                {selectedProduct?.attributes.name}
              </Typography>
            </Box>

            <Typography
              variant="body2"
              component="p"
              style={cardStyle.paraStyles}
            >
              {selectedProduct?.attributes.description}
            </Typography>
            <Box
              className={classes.currencyBox}
            >
              {/* currency that */}
              <Typography className={classes.currencyText}>
                {currencyType}
                {/* {selectedProduct?.attributes.after_discount} */}
                {currentPrice === 0 ? selectedProduct?.attributes.after_discount : currentPrice}
                {isDiscount && (
                  <strike
                    className={classes.strikeStyle}
                  >
                    {currencyType}
                    {selectedProduct.attributes.price}
                  </strike>
                )}
                <del className={classes.delStyle}></del>
              </Typography>
            </Box>
          </CardContent>
        </div>
        <hr className={`line-view-product-detail ${classes.bdTop}`} />
        <div className={`lower-view-product-detail ${classes.mrb150}`} >
          {
            !props.isEdit &&
            <>
             {selectedProduct?.attributes?.variants?.map((variant,variantIndex) => {
             const currentChosenVariant =  chosenVariant.find((chosen) => chosen.name === variant.title)
             const AlertFlag = ((currentChosenVariant?.option.length<variant.min && variant.min> 0 && validoptions.length>0 && validoptions.includes(variant.id))  || (currentChosenVariant?.option.length>variant.max && variant.max > 0 && validoptions.length>0 && validoptions.includes(variant.id)) || (!currentChosenVariant && validoptions.includes(variant.id) ))
              return(
                <div key={variant.id} className={classes.mr8}>
                  {variant.variant_type === "multiple" && variant.options.filter((option)=>{ return option.data.attributes.status === true }).length>0 &&
                    <p className={`${classes.variantTitle} ${classes.boldStyle}`}>
                      {variant.title}
                      <TooltipCustom
                       enterTouchDelay={0}
                       leaveDelay={2} className={classes.secondaryAccentColor}  title={(!currentChosenVariant || currentChosenVariant?.option.length<variant.min) ? `Choose at least ${variant.min} item` : `Choose up to ${variant.max} items`} placement="right">
                        <ErrorOutlineIcon style={AlertFlag ? cardStyle.errorOutred :cardStyle.errorOutgray}   />
                      </TooltipCustom>
                    </p>
                  }
                  {variant.variant_type === "single" && variant.options.filter((option)=>{ return option.data.attributes.status === true }).length>0 &&
                    <p className={`${classes.variantTitle} ${classes.boldStyle}`}>
                      {variant.title}
                      <TooltipCustom
                      enterTouchDelay={0} leaveDelay={2} className={classes.secondaryAccentColor} title={`Choose at least ${!variant.min ? 0 : 1} item`} placement="right" >
                        <ErrorOutlineIcon style={AlertFlag ? cardStyle.errorOutred :cardStyle.errorOutgray}  />
                      </TooltipCustom>
                    </p>
                  }
                
                {
                    AlertFlag &&
                    <Grid item xs={12} md={12}>
                    <Alert icon={false} className={`${classes.alertbox} ${classes.warningMsg}`} onClose={() => closeWarningAlert(variant.id)}>{getMessage(variant,currentChosenVariant?.option.length)}</Alert>
                    </Grid>
                  }

                  {variant.variant_type === "multiple" && variant.options.filter((option)=>{ return option.data.attributes.status === true }).map((option,optionIndex) => (
                    <Box
                      style={cardStyle.viewRadio}
                      key={option.data.id}
                    >
                      <Checkbox style={cardStyle.checkbox} onChange={(event) => variantSelect(selectedProduct, option, event.target.checked, variantIndex, optionIndex, variant.title)} />
                      <Typography style={cardStyle.additionals}>{option.data.attributes.option_name}</Typography>
                      <Typography style={cardStyle.detailPrice}>+{currencyType}{Number(option.data.attributes.price).toFixed(2)}</Typography>
                    </Box>
                  ))}
                  {variant.variant_type === "single" && variant.options.filter((option)=>{ return option.data.attributes.status === true }).map((option,optionIndex) => {
                    const currentVariant = currentRadioName.find((item) => item.title === variant.title)
                    return (
                      <Box
                        key={option.data.id}
                        style={cardStyle.viewRadio}
                        data-test-id="radioVariant"
                        
                      >
                        <Radio
                          style={cardStyle.checkbox}
                          checked={currentVariant?.name === option.data.attributes.option_name}
                          onClick={() => {
                          handleRadioClick(currentVariant, currentRadioName, option, variant.title, variantIndex, optionIndex, variant.title)
                        }}
                        />
                        <Typography style={cardStyle.additionals}>{option.data.attributes.option_name}</Typography>
                        <Typography style={cardStyle.detailPrice}>+{currencyType}{Number(option.data.attributes.price).toFixed(2)}</Typography>
                      </Box>
                    )
                  })}

                </div>
)})}
            </>
          }
          {
            props.isEdit &&
            <>
               {selectedProduct?.attributes?.variants?.map((variant,variantIndex) => {
                 const currentChosenVariant =  selectedProduct.variants.find((chosen) => chosen.name === variant.title)
                 const AlertFlag = ((currentChosenVariant?.option.length<variant.min && variant.min> 0 && validoptions.length>0 && validoptions.includes(variant.id))  || (currentChosenVariant?.option.length>variant.max && variant.max > 0 && validoptions.length>0 && validoptions.includes(variant.id)) || (!currentChosenVariant && validoptions.includes(variant.id) ))
                 return(
             <div key={variant.id} className={classes.mr8}>
                  {variant.variant_type === "multiple" && variant.options.filter((option)=>{ return option.data.attributes.status === true }).length>0 &&
                    <p className={`${classes.variantTitle} ${classes.boldStyle}`}>
                      {variant.title}
                      <TooltipCustom enterTouchDelay={0} leaveDelay={2} className={classes.secondaryAccentColor} title={(!currentChosenVariant || currentChosenVariant?.option.length<variant.min) ? `Choose at least ${variant.min} item` : `Choose up to ${variant.max} items`} placement="right-end">
                        <ErrorOutlineIcon style={AlertFlag ? cardStyle.errorOutred :cardStyle.errorOutgray}  />
                      </TooltipCustom>
                    </p>
                  }
                  {variant.variant_type === "single" && variant.options.filter((option)=>{ return option.data.attributes.status === true }).length>0 &&
                    <p className={`${classes.variantTitle} ${classes.boldStyle}`}>
                      {variant.title}
                      <TooltipCustom enterTouchDelay={0} leaveDelay={2} className={classes.secondaryAccentColor} title={`Choose at least ${!variant.min ? 0 : 1} item`} placement="right-end" >
                        <ErrorOutlineIcon style={AlertFlag ? cardStyle.errorOutred :cardStyle.errorOutgray}  />
                      </TooltipCustom>
                    </p>
                  }

                  {
                    ((currentChosenVariant?.option.length<variant.min && variant.min> 0 && validoptions.length>0 && validoptions.includes(variant.id))  || (currentChosenVariant?.option.length>variant.max && variant.max > 0 && validoptions.length>0 && validoptions.includes(variant.id)) || (!currentChosenVariant && validoptions.includes(variant.id) )) &&
                    <Grid item xs={12} md={12}>
                    <Alert icon={false} className={`${classes.alertbox} ${classes.warningMsg}`} onClose={() => closeWarningAlert(variant.id)}>{getMessage(variant,currentChosenVariant?.option.length)}</Alert>
                    </Grid>
                  }
               
                  {variant.variant_type === "multiple" && variant.options.filter((option)=>{ return option.data.attributes.status === true }).map((option,optionIndex) => {
                    const currentListChosenVariant =  chosenVariant.find((chosen) => chosen.name === variant.title)
                    return (
                      <Box
                        style={cardStyle.viewRadio}
                        key={option.data.id}
                      >
                        <Checkbox
                          style={cardStyle.checkbox}
                          onChange={(event) => handleVariantSelect(selectedProduct, option, event, variantIndex, optionIndex, variant.title)} 
                          defaultChecked={currentListChosenVariant?.option?.includes(option.data.attributes.option_name)}
                         />
                        <Typography style={cardStyle.additionals}>{option.data.attributes.option_name}</Typography>
                        <Typography style={cardStyle.detailPrice}>+{currencyType}{Number(option.data.attributes.price).toFixed(2)}</Typography>
                      </Box>
                    )
                  })}
                  {variant.variant_type === "single" && variant.options.filter((option)=>{ return option.data.attributes.status === true }).map((option,optionIndex) => {
                    const currentVariant = currentRadioName.find((item) => item.title === variant.title)
                    return (
                      <Box
                        key={option.data.id}
                        style={cardStyle.viewRadio}
                        data-test-id="radioVariant"
                        
                      >
                        <Radio
                          style={cardStyle.checkbox}
                          checked={currentVariant?.name === option.data.attributes.option_name}
                          onClick={() => {
                            handleRadioClick(currentVariant, currentRadioName, option, variant.title, variantIndex, optionIndex, variant.title)
                          }}
                        />
                        <Typography style={cardStyle.additionals}>{option.data.attributes.option_name}</Typography>
                        <Typography style={cardStyle.detailPrice}>+{currencyType}{Number(option.data.attributes.price).toFixed(2)}</Typography>
                      </Box>
                    )
                  })}

                </div>
             )}
             )}
            </>
          }
        </div>
      </div>
      {isEdit &&
        <div className={classes.dialogFooter}><Button variant="contained" className={`${props.isStoreClosed?classes.cartBtn:classes.btnDisable}`} onClick={() => validateUpdate()}>Update Cart</Button></div>
      }
    </MyDialog>
  );
}

const cardStyle = {
  viewRadio: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "8px",
  },
  checkbox: { color: "var(--website-builder-primary-color)" },

  additionals: {
    textAlign: "start",
    width: "400px",
    height: "35px",
    padding: "6px, 16px, 6px, 16px",
    lineHeight: "40px",
    fontFamily: "var(--website-builder-body-font)",
    fontWeight: 400,
    fontSize: "14px",
    color: "var(--website-builder-secondary-color)",

  },
  mainContainer: {
    display: "grid",
    gridTemplateColumns: "auto",
  },
  cardContainerStyles: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
    marginBottom: "10px",
  },
 
  cardContentStyles: {
    display: "flex",
    backgroundColor: "#ffffff",
    gap: "30px",
  },
  
  titleStyles: {
    fontFamily: "var(--website-builder-header-font)",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "26px",
    color: "var(--website-builder-secondary-color)",
    marginBottom: "12px"
  },
  paraStyles: {
    color: "var(--website-builder-secondary-dark-accent-color)",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    wordBreak: "break-all",
    fontFamily: "var(--website-builder-body-font)",
  },
  detailPrice: {
    color: "var(--website-builder-secondary-color)",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    fontFamily: "var(--website-builder-body-font)",
    width: "25%",
    justifyContent: "end",
  },
  saveBtn: {
    padding: "6px",
    gap: "10px",
    background: "#F6F0FF",
    borderRadius: "15px",
    color: "#5500CC",
    fontWeight: "bold",
    fontSize: "14px",
    borderStyle: "none",
    marginLeft: "2rem",
  },
  radiocolor: {
    color: "#6200EA"
  },
  errorOutred: {
    height: "18px",
    color: "#DC2626"
  },
  errorOutgray: {
    height: "18px",
    color: "grey"
  },
};
const useStyles = makeStyles((theme) => ({
  // new styles
  dialogFooter: {
    backgroundColor: "#fff",
    boxShadow: "0px -25px 50px 0px #00000017",
    textAlign: "center"
  },
  cartBtn: {
    borderRadius: "8px",
    padding: "16px",
    margin: "8px",
    width: "437px",
    background: "linear-gradient(99.09deg, var(--website-builder-primary-dark-accent-color) 2.64%, var(--website-builder-primary-color) 100%)",
    fontSize: "16px",
    fontFamily: "var(--website-builder-body-font)",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#fff",
    textTransform: "capitalize"
  },
  errorOutLine: {
    height: "18px",
    color: "grey"
  },
  variantRoot: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "8px",
  },

  mrb8: {
    marginBottom: "8px"
  },
  variantTitle: {
    color: "#646464",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    wordBreak: "break-all",
    fontFamily: "var(--website-builder-body-font)",
  },
  boldStyle: {
    color: "var(--website-builder-secondary-color)",
    fontWeight: "bold"
  },
  custTooltip: {
    borderRadius: "20px",
  },
  dflex: {
    display: "flex",
    alignItems: "center"
  },
  additionals: {
    textAlign: "start",
    width: "400px",
    padding: "6px, 16px, 6px, 16px",
    lineHeight: "40px",
    fontFamily: "var(--website-builder-body-font)",
    fontWeight: 400,
    fontSize: "14px",
    color: "#334155",
  },
  closeImg: {
    color: "var(--website-builder-secondary-color)",
    cursor: "pointer",
    width: "24px",
    height: "24px",
    marginTop: "5%",
    marginBottom: "5%",
    marginLeft: "90%"
  },
  detailStyle: {
    height: "100%",
    marginRight: "10px",
    overflowY: "auto",
    display: "flex",
    flexDirection: 'column',
    alignItems: "center",
  },
  cardContent: {
    width: "100%",
    padding: "0px",
  },
  imgContainer:{
    paddingTop:'19px',
    paddingBottom:'19px',
    display:'flex',
    alignItems:'center',
    "@media (max-width: 450px)": {
      paddingTop:'0px',
      paddingBottom:'0px',
    }
  },
  cardImageStyles: {
    width: "202px",
    height: "202px",
    "@media (max-width: 450px)": {
      width: "205px",
      height: "205px",
    }
  },
  contentBox: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    gap: "12px",
  },
  contentNestedBox: {
    display: "flex",
    marginBottom: "6px",
    maxWidth: '100%',
    flexWrap: 'wrap'
  },
  foodLableImg: {
    margin: "auto"
  },
  foodlabel: {
    margin: "auto",
    fontFamily: "var(--website-builder-body-font)",
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 400,
    color: "#334155"
  },
  currencyBox: {
    display: "flex",
    alignItems: "center",
    marginTop: "12px",
  },
  currencyText: {
    fontWeight: "bold",
    fontFamily: "var(--website-builder-body-font)",
    color:"var(--website-builder-secondary-color)"
  },
  strikeStyle: {
    fontWeight: 400,
    color: "var(--website-builder-secondary-dark-accent-color)",
    marginLeft: "4px",
  },
  delStyle: {
    color: "grey"
  },
  bdTop: {
    borderTop: "solid 1px #838b95"
  },
  mrb150: {
    marginBottom: "120px"
  },
  errorOutgray: {
    height: "18px",
    color: "grey"
  },

  mr8: {
    marginBottom: "8px"
  },
  alertbox: {
    borderRadius: "4px !important",
    padding: "4px 17px 4px 16px",
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 400,
    fontFamily: "var(--website-builder-body-font)",
    margin: '8px 0px',
    width:'100% !important'
},
warningMsg: {
  borderLeft: "4px solid #D97706",
  backgroundColor: "#FEF3C7",
  color: "#D97706",

},
secondaryAccentColor:{
  color:"var(--website-builder-secondary-dark-accent-color)",
},
btnDisable: {
  borderRadius: "8px",
  padding: "16px",
  margin: "8px",
  width: "437px",
  background:  "#F1F5F9",
  fontSize: "16px",
  fontFamily: "var(--website-builder-body-font)",
  fontWeight: 700,
  lineHeight: "24px",
  color: "#64748B",
  textTransform: "capitalize",
  pointerEvents: "none",
},
foodLables: {
  display: "flex",
  gap: "7px",
  margin: "2px",
  borderRadius: "2rem",
  height: "32px",
  padding: "7px 10px 7px 10px",
  borderRadius: "50px",
  "@media (max-width: 450px)": {
   padding: "7px 10px 7px 16px !important",
  }
},
  "@media (max-width: 480px)": {
    cartBtn: {
      width: "90%"
    },
    btnDisable:{
      width: "90%"
    }
  }
}))
