import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { DeliveryAddressType } from "./CartOrdersController.web";

const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: any;
    // Customizable Area Start
    classes: Record<string, string>;
    // Customizable Area End
}

// Customizable Area Start

export interface OrderItem {
    attributes: {
        quantity: number;
        catalogue: {
            attributes: {
                name: string;
                price: GLfloat;
                after_discount: number;
                image: {
                    url: string;
                };
                variants: Variant[]
            }
        };
        catalogue_variants: Variant[]
    },
}

export interface Variant {
    id: string;
    attributes: {
        catalogue_variant: {
            attributes: {
                title: string
            }
        };
        catalogue_options: Option[];
    }
}

export interface Option {
    data: {
        id: string;
        type: string;
        attributes: {
            id: number;
            option_name: string;
            price: number;
            status: boolean;
        };
    }
    id: string;
    type: string;
    attributes: {
        id: number;
        option_name: string;
        price: number;
        status: boolean;
    };
}

interface OrderSummary {
    data: {
        attributes: {
            order_number: string;
            order_type: string,
            status: string;
            total: string;
            order_date: string;
            created_at: string;
            delivered_at: string;
            delivery_address: {
                attributes: DeliveryAddressType
            };
            order_items: OrderItem[];
            account: {
                attributes: {
                    email: string;
                    full_phone_number: string;
                    phone_number: string;
                    full_name: string
                }
            }
        },
    },
    meta: {
        store_message: string,
        store_profile: {
            attributes: {
                currency_symbol: string;
                country_name: string;
                phone_code: string;
                address_line_1: string;
                address_line_2: string;
                city_name: string;
                state_name: string;
                zipcode: string;
            }
        }
    }
}

interface CanvasDataType {
    data : {
        attributes : {
            
        }
    }
    header: {
        data:{
            attributes:{
                logo_url: string,
                show_business_name: boolean
            }
        }
    }
  }
// Customizable Area End
interface S {
    // Customizable Area Start
    orderSummaryData: OrderSummary | null
    canvasData: CanvasDataType | null,
    storename: string,
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class OrderConfirmationSummaryController extends BlockComponent<Props, S, SS> {

    // Customizable Area Start
    apiCallIdOrderSummary: string = "";
    getCanvasDataCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            orderSummaryData: null,
            canvasData: null,
            storename: "",
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId != null) {
                if (apiRequestCallId === this.apiCallIdOrderSummary) {
                    this.setState({
                        orderSummaryData: responseJson,
                        storename: responseJson?.meta?.store_profile?.attributes?.name
                    })
                }
                if(apiRequestCallId === this.getCanvasDataCallId){
                    this.setState({canvasData: responseJson.data.attributes})
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        window.scrollTo(0,0);
        await removeStorageData("count")
        await removeStorageData("stipeInfo")
        this.apiFnOrderSummary();
        this.getCanvasData();
    }

    apiFnOrderSummary = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        const order_id = id ? id : await getStorageData("orderId");
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiCallIdOrderSummary = requestMessage.messageId ;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.apiCallEndpointOrderSummary + order_id
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiGetMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return this.apiCallIdOrderSummary;
    }

    getCanvasData = () => {
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getCanvasDataCallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.designApiEndPoint
        );
      
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getApiMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

    handleContinueShopping = async () => {
        const landingPage: Message = new Message(getName(MessageEnum.NavigationMessage));
        landingPage.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPageScreen");
        landingPage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(landingPage);
    }

    // Customizable Area End
}