// Customizable Area Start
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, withStyles } from "@material-ui/core";
import React from "react";
import { Skills } from "./Constants";
import CustomAutoCompletePickerController from "./CustomAutoCompletePickerController.web";

const configJSON = require("./config");

export class CustomAutoCompletePicker extends CustomAutoCompletePickerController {
  render() {
    const { isDisabled, isError, isHelperText } = this.props;

    return (
      <div>
        <Autocomplete
          id="static-page_type"
          options={Skills}
          getOptionLabel={(option) => option?.title || ""}
          size="small"
          fullWidth
          value={{ ...(this.props.value || this.state.optionValue || []) }}
          renderInput={(params) => (
            <TextField
              placeholder={configJSON.staticPageTypePlaceholder}
              error={isError}
              helperText={isHelperText}
              {...params}
              variant="outlined"
            />
          )}
          onChange={(_event, object) => {
            this.handleOptionChange(object);
          }}
          disabled={isDisabled}
          disableClearable
        />
      </div>
    );
  }
}

export const styles = () => ({
  textField: {
    "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
      visibility: "hidden",
    },
  },
});

export default withStyles(styles)(CustomAutoCompletePicker);
// Customizable Area End