// Customizable Area Start
import * as React from "react"
import { IBlock } from "../../../framework/src/IBlock"
import { Message } from "../../../framework/src/Message"
import { BlockComponent } from "../../../framework/src/BlockComponent"
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum"
import { runEngine } from "../../../framework/src/RunEngine"
import { RouterProps } from "react-router"
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/DialogContext"
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web"
import { withToastProps } from "../../ss-cms-common-components/src/HOC/withSnackBar.Web"
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web"
import { generateRequestMessage, isTokenExpired, clearStorageData } from "../../ss-cms-common-components/src/Utilities/Utilities"
import { Scrollbars } from "react-custom-scrollbars";
import { getResponseVariables } from "../../ss-cms-common-components/src/GetResponseVariables/GetResponseVariables";
import { emitter } from "../../../../packages/blocks/ss-cms-common-components/src/Layout/Layout"

const configJSON = require("./config")

export type Props = RouterProps &
  DialogProps &
  withHeadeActionBarProps &
  withLoaderProps &
  withToastProps & {
    navigation: {
      navigate: (to: string, params: object) => void;
      getParam: (param: string) => string;
      goBack: () => void;
    };
    id: string;
    setDialogState: (status: boolean, params: object) => void;
    classes: Record<string, string>
  }
interface taxTableObject {
  tax: number | string,
  updated_on: string,
  tax_title: string,
  active: boolean | string
}

export interface taxResponseTypes {
  id: string,
  type: string,
  attributes: {
    id: string,
    tax: string,
    tax_title: string,
    active: boolean,
    updated_at: string
  }
}

interface S {

  showCreatePage: boolean
  showTable: boolean
  taxTable: Array<taxResponseTypes>
  showEmptyPage: boolean
  isOpen: boolean
  page: number
  per_page: number
  totalCount: number
  taxTitle: string
  taxPercentage: string
  taxId: string
}

interface SS {
  id: number
}

export default class TaxesController extends BlockComponent<Props, S, SS> {
  getTaxApiCallId: string = ""
  statusTaxApiCallId: string = ""
  postBulkDeleteCallId: string = ""
  getOrderNotificationId: string = ""
  scrollRef: React.RefObject<Scrollbars>;

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)
    this.scrollRef = React.createRef()
    this.state = {
      showCreatePage: false,
      showTable: false,
      taxTable: [],
      showEmptyPage: false,
      isOpen: false,
      page: 1,
      per_page: 10,
      totalCount: 0,
      taxTitle: "",
      taxPercentage: "",
      taxId: ""
    }

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.ActionMessageFromToaster),
    ]

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }
  async receive(from: string, message: Message) {
    if (isTokenExpired(message)) {
      return this.logoutAndNavigateLogin();
    }
    this.props.hideLoader()
    this.initTaxData(message)
    this.handleErrorRespone(message)

    if (getName(MessageEnum.ActionMessageFromToaster)) {
      const type = message.getData(
        getName(MessageEnum.ActionMessageFromToasterMessage)
      )
    }
  }

  getOrderNotification = async () => {
    const requestMessage = await generateRequestMessage(
      `/bx_block_order_management/orders/new_order_count`,
      configJSON.getTaxDataApiMethodType
    );
    this.getOrderNotificationId = requestMessage.messageId
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  async componentDidMount() {
    window.scrollTo(0, 0)
    await this.getTaxDataApi()
    await this.getOrderNotification()
  }
  handleAddNewTax = () => {
    this.setState({
      isOpen: true,
      taxId: "",
      taxTitle: "",
      taxPercentage: "",
    })
  }
  onCancelClick = () => {
    this.setState({ isOpen: false })
  }

  handlePageChange = (value: number) => {
    this.setState(
      {
        page: value,
      },
      () => {
        this.getTaxDataApi()
      }
    )
  }

  initTaxData = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);

    if (apiRequestCallId === this.getTaxApiCallId && responseJson?.data) {
      if (responseJson?.data?.length > 0) {
        const totalCount = responseJson?.meta?.pagination?.total_count || 0
        this.setState({
          taxTable: responseJson.data,
          totalCount: totalCount,
          showEmptyPage: false
        })
      } else {
        this.setState({ showEmptyPage: true, totalCount: 0, taxTable: [] })
      }
    } else if (apiRequestCallId === this.statusTaxApiCallId && responseJson?.data) {
      this.getTaxDataApi();
    } else if (apiRequestCallId == this.getOrderNotificationId) {
       emitter.emit("changeNotiNumber", responseJson.new_order_count)
    }
  }
  handleErrorRespone = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);
    if (!responseJson || !responseJson.errors) {
      return
    }
    const errors: Array<string> | string = responseJson.errors

    let allerrors = ""
    if (typeof errors === "string") {
      allerrors = responseJson.errors
    } else {
      errors.forEach((errorItem: string) => {
        if (allerrors.length <= 0) {
          allerrors = errorItem;
        } else {
          allerrors = `${allerrors}{\n}${errorItem}`
        }
      })
    }

    return this.props.setDialogState(true, {
      title: "Error",
      message: allerrors,
      confirmBackground: "#FF1744",
      confirmHoverBackground: "rgb(240, 25, 73)",
      confirmTextColor: "#fff",
      hideCancel: true,
    })
  }
  getTaxDataApi = async () => {
    this.props.displaySpinner()
    const requestMessage = await generateRequestMessage(
      `${configJSON.getTaxDataApiUrl}?page=${this.state.page}&per_page=${this.state.per_page}`,
      configJSON.getTaxDataApiMethodType
    );
    this.getTaxApiCallId = requestMessage.messageId
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  statusTaxDataApi = async (taxID: string, status: boolean) => {
    this.props.displaySpinner()
    const requestMessage = await generateRequestMessage(
      `${configJSON.getTaxDataApiUrl}/${taxID}`,
      "PUT"
    );
    this.statusTaxApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ active: status })
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  editTax = (data: any) => {
    this.setState({
      isOpen: true,
      taxId: data.attributes.id,
      taxTitle: data.attributes.tax_title,
      taxPercentage: data.attributes.tax,
    })
  }
}
// Customizable Area End
