// Customizable Area Start
import React, { RefObject } from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/DialogContext";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { withToastProps } from "../../ss-cms-common-components/src/HOC/withSnackBar.Web";
export const configJSON = require("./config.js");
import { getResponseVariables } from "../../ss-cms-common-components/src/GetResponseVariables/GetResponseVariables";
import { generateRequestMessage } from "../../ss-cms-common-components/src/Utilities/Utilities";
import { FormikProps } from "formik";

export type Props = RouterProps & DialogProps & withHeadeActionBarProps & withToastProps & {
    id: string;
    isOpen: boolean;
    history: void;
    onCancel: () => void;
    title: string
    helperTextValue: string;
    textLabel: string;
    width: string;
    isBrand: boolean;
    handleErrorRespone: (response: string) => void;
    getTaxDataApi: () => void;
    classes: classTypes;
    taxId: string,
    taxTitle: string,
    taxPercentage: string
};

interface classTypes {
    dialogRoot: string,
    contentRoot: string,
    root: string,
    fullDiv: string,
    inputLabel: string,
    textField: string,
    footerAction: string,
    footerActionRight: string,
    saveButton: string,
    dialogTitle: string,
    svgRoot: string
}
interface S {
    message: string | null;
    open: boolean;
}
interface taxPayloadTypes {
    "tax_title": string,
    "tax": string,
}
interface SS {
    id: number;
}

export default class AddTaxDialogController extends BlockComponent<Props, S, SS> {
    addPercentageApiCallId: string = "";

    taxRef: RefObject<FormikProps<taxPayloadTypes>>;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.taxRef = React.createRef();
        this.state = {
            message: null,
            open: this.props.isOpen,
        };
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AlertMessage),
            getName(MessageEnum.ActionMessageFromToaster)
        ];

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    async receive(from: string, message: Message) {
        this.messageAddTaxApiCall(message);
    }

    messageAddTaxApiCall = (message: Message) => {

        const [responseJson, apiRequestCallId] = getResponseVariables(message);
        if (this.addPercentageApiCallId === apiRequestCallId) {
            if (responseJson && responseJson?.data) {
                this.closeDialog();
                this.props.showHeaderBar({
                    message: this.props.taxId ? configJSON.editTaxSuccessMessage : configJSON.addTaxSuccessMessage,
                    type: "success",
                })
                this.props.getTaxDataApi();
            } else {
                this.props.getTaxDataApi();
                this.props.handleErrorRespone(responseJson);
            }
        }
    }
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
        if (this.props?.isOpen !== prevProps?.isOpen) {
            this.setState({ open: this.props?.isOpen });
        }
    }

    handleSubmitDialog = () => {
        this.taxRef.current && this.taxRef.current.handleSubmit();
    }

    closeDialog = () => {
        this.setState({ open: false })
        this.props.onCancel();
    };
    handleSubmit = (formData: taxPayloadTypes) => {
        this.addTaxApi(formData)
    };
    addTaxApi = async (taxPayload: taxPayloadTypes) => {
        const requestMessage = await generateRequestMessage(
            this.props.taxId ? configJSON.addTaxApiUrl + "/" + this.props.taxId : configJSON.addTaxApiUrl,
            this.props.taxId ? configJSON.putMethodType : configJSON.addTaxApiMethodType
        );

        this.addPercentageApiCallId = requestMessage.messageId
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(taxPayload)
        )
        runEngine.sendMessage(requestMessage.id, requestMessage)
    }
}
// Customizable Area End
