
import React from "react";
// Customizable Area Start
import {
    Grid,
    withWidth,
    Typography
} from "@material-ui/core";
import withStyles, { CSSProperties } from "@material-ui/core/styles/withStyles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
export const configJSON = require("./config");
import AlertMessage from "../../../components/src/AlertMessage.web";
// Customizable Area End

import StripePaymentsHomeController, {
    Props
} from "./StripePaymentsHomeController.web";

import StripePayments from '../../stripepayments/src/StripePayments.web'


export class StripePaymentsHome extends StripePaymentsHomeController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        return (
            <>
                <Grid className={classes.container}   container alignItems="flex-start" justifyContent="space-around" spacing={2}>
                <AlertMessage
                        data-test-id="orderAlertMsg"
                        open={this.state.openOrderAlert}
                        success={this.state.alertOrderSuccess}
                        message={this.state.alertOrderMessage}
                        close={this.handleOrderAlertMessageClose}
                    />
                            <Grid item sm={4} xs={12} className={classes.backArrow}>
                           { this.state.showStripe && 
                           <>
                                <ArrowBackIcon fontSize="medium" data-test-id="pressBackButton" style={webStyles.backIcon}
                                    onClick={() => this.pressBackButton()}
                                />
                          
                                <Typography
                                    className={classes.backIconText} onClick={() => this.pressBackButton()}
                                    data-test-id="pressBackButtonText" >
                                    {configJSON.backText}
                                </Typography>
                                </>
                                 }
                            </Grid>
                            <Grid item sm={6} xs={12} data-test-id="sample">
                                {this.state.stripeInfo && this.state.stripeInfo.stripe_publishable_key && this.state.showStripe && 
                                    <StripePayments
                                        navigation={this.props.navigation}
                                        id={'stripePayment'}
                                        client_secret={this.state.stripeInfo.client_secret}
                                        payment_intent_id={this.state.stripeInfo.payment_intent_id}
                                        customer_id={this.state.stripeInfo.customer_id}
                                        orderId={this.state.stripeInfo.orderId}
                                        stripe_publishable_key={this.state.stripeInfo.stripe_publishable_key}
                                        amount={this.state.stripeInfo.amount}
                                        data-test-id="stripePayment"
                                        paymentSuccessCallBack={()=>this.paymentSuccessCallBack()}
                                    />
                                }
                            </Grid>
                </Grid>
            </>
            //Merge Engine End DefaultContainer
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const cardStyle = {
    plusIcon: {
        width: "12px",
        marginRight: "4px"
    },
    buttonAddAddress: {
        backgroundColor: "var(--website-builder-primary-light-accent-color)",
        border: "1px solid var(--website-builder-primary-color)",
        paddingTop: "16px",
        paddingBottom: "16px",
        fontFamily: "var(--website-builder-body-font)",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        letterSpacing: "0em",
        color: "var(--website-builder-secondary-color)",
        width: "100%",
        borderRadius: "6px",
        marginBottom: "20px",
        display: "flex",
        justifyContent: "center"
    },
    root: {
        flexGrow: 1,
    },
};
const useStyles: Record<string, CSSProperties> = {
    backArrow: {
        display: "flex",
        alignItems: "center",
        marginTop: "30px",
        marginBottom: "10px"
    },
    backIconText: {
        fontFamily: "var(--website-builder-body-font)",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "16px",
        color: "var(--website-builder-secondary-dark-accent-color)",
        cursor: "pointer"
    },
    container: { 
        backgroundColor: "#F1F5F9",
         padding:'40px 0px',
 },
 "@media (max-width: 480px)": {
    container:{
        padding:'0px',
        backgroundColor:'#fff',
    },
    backArrow: {
        marginTop: "10px",
        marginBottom: "0px"
    },
},
}

const webStyles = {
    pointer: { cursor: "pointer" },
    backIcon: {
        cursor: "pointer",
        marginRight: "5px",
        height: "14px",
        width: "14px",
    }
}

const StyledComponent = withStyles(useStyles)(StripePaymentsHome);
export default withWidth()(StyledComponent);

// Customizable Area End
