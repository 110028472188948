// Customizable Area Start
import React, { Component, Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";

import EmailAccountLogin from "../../emailaccountlogin/src/Login.web";
import EmailAccountForgotPassword from "../../emailaccountforgotpassword/src/ForgotPassword.web";
import AdminEmailTemplates from "../../adminemailtemplates/src/EmailTemplates.web";
import AdminEmailTemplatesCreate from "../../adminemailtemplates/src/EmailTemplatesCreate.web";
import AdminStaticPage from "../../adminstaticpage/src/StaticPageTable.web";
import AdminStaticPageCreate from "../../adminstaticpage/src/CreateStaticPage.web";
import AdminImageCatalogue from "../../adminimagecatalogue/src/ImageCatalogue.web";
import AdminImageCatalogueBulkUpload from "../../adminimagecataloguebulkupload/src/ImageCatalogueBulkUpload.web";
import AdminEditImage from "../../admineditimage/src/EditImage.web";
import AdminCustomerFeedback from "../../admincustomerfeedback/src/CustomerFeedback.web";
import AdminCustomerFeedbackCreate from "../../admincustomerfeedback/src/CustomerFeedbackCreate.web";
import AdminStoreDetails from "../../adminstoredetails/src/StoreDetails.web";
import AdminTaxes from "../../admintaxes/src/Taxes.web";
import AdminShipping from "../../adminshipping/src/Shipping.web";
import AdminPromoCode from "../../adminpromocode/src/PromoCode.web";
import AdminPromoCodeCreate from "../../adminpromocode/src/PromoCodeCreate.web";
import AdminPaymentIntegration from "../../adminpaymentintegration/src/Payment.web";
import AdminMobileApp from "../../adminmobileapp/src/MobileApp.web";
import AdminNotifications from "../../adminnotifications/src/Notifications.web";
import AdminOtherIntegrations from "../../adminotherintegrations/src/OtherIntegrations.web";
import AdminAccount from "../../adminaccount/src/AccountDetails.web";
import Variants from "../../inventorymanagement/src/Variants.web";
import InventoryManagement from "../../inventorymanagement/src/InventoryManagement.web";
import ItemsLibrary from "../../inventorymanagement/src/ItemsLibrary.web";
import Categories from "../../categoriessubcategories/src/Categories.web";
import OrderList from "../../ordermanagement1/src/OrderList.web";
import  CustomerList from "../../adminconsole2/src/CustomerList.web";
import ReviewItermsFromLibrary from "../../inventorymanagement/src/ReviewItemsFromLibrary.web";
import {ItemAvailabilityLayout} from "../../itemavailability/src/ItemAvailability.web";
import AdminBranding from "../../websitebuilder/src/Branding.web";

class WebRoutes extends Component {
  render() {
    return (
      <Switch>
        {/* <Route exact path="/" component={AdminEmailTemplates} /> */}
        <Route exact path="/Home" component={ItemAvailabilityLayout} />
        <Route exact path="/EmailAccountLogin" component={EmailAccountLogin} />
        <Route
          exact
          path="/EmailAccountForgotPassword"
          component={EmailAccountForgotPassword}
        />
        <Route
          exact
          path="/AdminEmailTemplates"
          component={AdminEmailTemplates}
        />
        <Route
          //exact
          path="/AdminEmailTemplatesCreate/:id"
          component={AdminEmailTemplatesCreate}
        />
        <Route exact path="/AdminStaticPage" component={AdminStaticPage} />
        <Route
          exact
          path="/AdminStaticPageCreate/:id?"
          component={AdminStaticPageCreate}
        />
        <Route
          exact
          path="/AdminImageCatalogue"
          component={AdminImageCatalogue}
        />
        <Route
          exact
          path="/AdminImageCatalogueBulkUpload"
          component={AdminImageCatalogueBulkUpload}
        />
        <Route exact path="/AdminEditImage/:id" component={AdminEditImage} />
        <Route
          exact
          path="/AdminCustomerFeedback"
          component={AdminCustomerFeedback}
        />
        <Route
          exact
          path="/AdminCustomerFeedbackCreate/:id?"
          component={AdminCustomerFeedbackCreate}
        />
        <Route exact path="/AdminStoreDetails" component={AdminStoreDetails} />
        <Route exact path="/AdminTaxes" component={AdminTaxes} />
        <Route exact path="/AdminShipping" component={AdminShipping} />
        <Route exact path="/AdminPromoCode" component={AdminPromoCode} />
        <Route
          exact
          path="/AdminPromoCodeCreate/:id?"
          component={AdminPromoCodeCreate}
        />
        <Route
          exact
          path="/AdminPaymentIntegration"
          component={AdminPaymentIntegration}
        />
        <Route exact path="/AdminMobileApp" component={AdminMobileApp} />
        <Route
          exact
          path="/AdminNotifications"
          component={AdminNotifications}
        />
        <Route
          exact
          path="/AdminOtherIntegrations"
          component={AdminOtherIntegrations}
        />
        <Route exact path="/AdminAccount" component={AdminAccount} />
        <Route exact path="/Variants" component={Variants} />
        <Route exact path="/InventoryManagement" component={InventoryManagement} />
        <Route exact path="/InventoryManagementLibrary" component={ItemsLibrary} />
        <Route exact path="/Categories" component={Categories} />
        <Route exact path="/Orders" component={OrderList} />
        <Route exact path="/Customers" component={CustomerList} />
        <Route exact path="/InventoryManagementLibraryReview" component={ReviewItermsFromLibrary} />
        <Route exact path="/AdminBranding" component={AdminBranding} />

      </Switch>
    );
  }
}

export default WebRoutes;
// Customizable Area End
