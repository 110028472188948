import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { dialogBoxProps } from "../../ss-cms-common-components/src/HOC/withDialog.web";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";
import { Scrollbars } from "react-custom-scrollbars";
import React, { createRef } from "react"
import { getResponseVariables } from "../../ss-cms-common-components/src/GetResponseVariables/GetResponseVariables";
import { generateRequestMessage } from "../../ss-cms-common-components/src/Utilities/Utilities";
import {  S as temp } from "./BrandingController.web"
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
type SelectedFileTypeAlias = string | null | ArrayBuffer;
type SectionDataAlias = { [key: string]: SelectedDataTypeAlias };
type SelectedDataTypeAlias = string | null | ArrayBuffer | boolean;
import { MetadataBanner } from "./ProductPreviewScreenController.web";

// Customizable Area End

export const configJSON = require("./config.js");

export type Props = DialogProps &
  dialogBoxProps &
  withHeadeActionBarProps &
  withLoaderProps & {
    navigation: {
      navigate: (to: string, params: object) => void;
      getParam: (param: string) => string;
      goBack: () => void;
    };
    id: string;
    // Customizable Area Start
    classes?: {};
    onTextChange?: (value: string) => void;
    selectedTab: string;
    parentStates: temp;
    selectedSection: string;
    updatedSection: string;
    updateSelectedSection: (sectionName: string) => void;
    updateExpandedSection: (sectionName: string) => void;
    createIdHandler: (createId: string, responseJson: ProductPreviewContentInterface) => void;
    realTimeHandler: (value: string | boolean, name: string, fieldType: string, fieldLinkType: string) => void;
    apiCalledHandler: () => void;
    realTimeSectionHandler: (value: any, fieldType: string, section_type: string, fieldName: string) => void;
    realTimeCategoryHandler: (fieldValue: any) => void;
    realTimeProductHandler: (fieldValue: any) => void;
    HandleStoreNameEnabled: (isStoreNameTrigged: boolean) => void;
    handleThemeColor:(primaryColor:string, secondaryColor:string) => void;
    handleFont:(font:string,type:string)=>void;
    HandleMobileMenyTrigger: (isMobileMenuOpenTrigger: boolean) => void;
    HandleLogoUpload: (isStoreNameTrigged: string) => void;
    handleSectionAdd: (type: string, name?: string) => void;
    updateSectionName: (id: string, sectionItemIndex: number, name?: string) => void;
    deleteSection: (sectionItemIndex: number) => void;
    updateBannerMobileData: (index: number, base64Image: string, fileName: string, isBannerType: string) => void;
    updateBannerUrl: (index: number, bannerUrl: string) => void;
    updateSectionType: (index: number, type: string) => void;
    handleSectionDropDownOpen: (index: number) => void;
    handleGetAPITrigger: () => void;
    handleCloseAllSections: () => void;
    handleSocialLinkToggle: (event: any) => void;
    updateBannerMobileMetadata: (index: number, metadata: MetadataBanner) => void;
    updateBannerDesktopMetadata: (index: number, metadata: MetadataBanner) => void;
    overrideCategoryOrProductSelectedValue: (sectionId: string, sectionType: string, index: number, selectedValue: string) => void;
    updateFooter: (eventName: string, value: boolean | string) => void;

    HandleCanvasDataGetAPIResponse: (APIResponseData: any) => void;
    HandleNavigationMenuOptions: (navigationOptions: any) => void;
    realTimeSectionCategoryName: (sectionId: string, sectionName: string, section_type: string) => void;       // Customizable Area End
  };

export interface CategoryData {
  category_image: string,
  category_name: string
}

export interface ProductData {
  product_name: string,
  product_image: string,
  new_rate: string,
  old_rate: string,
  currency: string,
  discount: string
}

export interface CatgeoryValidationInterface {
  section_name: string,
  Category1: boolean,
  Category2: boolean,
  Category3: boolean,
  Category4: boolean

}

export interface S {
  // Customizable Area Start
  activeRightSideBarTab: string,
  categoryOne: string,
  showStoreLinkInputBox: boolean,
  showContactInformation: boolean,
  showSocialLinks: boolean,
  selectedFonts: { headerText: string, bodyText: string },
  selectedPhoneNumber: string | unknown,
  isDisabledSwitch: boolean,
  themeFontsContent: boolean,
  footerContent: boolean,
  headerContent: boolean,
  facebookUrl: string,
  errorStatus: boolean,
  checkStatus: boolean,
  instagramUrl: string,
  errorInstagramStatus: boolean,
  checkInstagramStatus: boolean,
  twitterUrl: string,
  errorTwitterStatus: boolean,
  checkTwitterStatus: boolean,
  youTubeUrl: string,
  errorYouTubeStatus: boolean,
  checkYouTubeStatus: boolean,
  selectedColor: { primary: string, secondary: string, id: number },
  linkedinUrl: string,
  defaultColors: Array<{ primary: string, secondary: string, id: number }>,
  errorLinkedinStatus: boolean,
  checkLinkedinStatus: boolean,
  playStoreUrl: string,
  errorPlayStoreStatus: boolean,
  checkPlayStoreStatus: boolean,
  appStoreUrl: string,
  errorAppStoreStatus: boolean,
  checkAppStoreStatus: boolean,
  copyRight: string,
  errorCopyRightStatus: boolean,
  createId: string,
  copyRightError: string,
  showProductPreview: ProductPreviewContentInterface;
  sectionDataN: Array<SectionDataMetadata>
  contactTempInformation: boolean;
  appTempStore: boolean;
  socialTempMedia: boolean;
  facebookTempUrl: string,
  instagramTempUrl: string,
  twitterTempUrl: string,
  youTubeTempUrl: string,
  linkedinTempUrl: string,
  playStoreTempUrl: string,
  appStoreTempUrl: string,
  copyTempRight: string,
  sectionContent: boolean,
  selectTypeValue: string,
  cropperSectionOpen: boolean,
  selectedFile: SelectedFileTypeAlias,
  selectedCropedFile: SelectedFileTypeAlias,
  image: string;
  selectedFileType: string;
  uploadMobileBannerOpen: boolean;
  uploadHeaderLogoModalOpen: boolean;
  sectionImageSize: number;
  isBannerType: string;
  selectedModal: boolean;
  modalTabData: Array<string>;
  activeModalTabName: string;
  modalImages: Array<ModalImageData>;
  selectedImage: ModalImageData;
  sectionData: Array<SectionData>;
  addSectionBox: boolean;
  selectAddSectionType: string;
  selectAddSectionName: string;
  categoriesData: Array<string>;
  productsDisplayData: Array<string>;
  deleteSectionModal: boolean;
  deleteSectionItemIndex: number;
  selectMobileBanner: SelectedFileTypeAlias;
  selectDesktopBanner: SelectedFileTypeAlias;
  bannerUrl: string;
  checkBannerUrl: boolean;
  showStoreName: boolean,
  storeName: string;
  uploadModalType: string;
  HeaderCropSectionOpen: boolean;
  isLogoUploaded: boolean,
  isFaviconUploaded: boolean,
  SelectedLogoName: string | null,
  SelectedFaviconName: string | null,
  isMobileMenuOpen: boolean
  sectionMainData: Array<{ [key: string]: string | null | ArrayBuffer | boolean | undefined }>;
  sectionId: string;
  isColorSelectionOpen: boolean;
  isFontSelectionOpen: boolean;
  isDeleteSection: SectionDataAlias;
  allSelectValue: SectionDataAlias;
  tempBannerUrl: string;
  isPlayError: boolean;
  isAppError: boolean;
  isYoutubeError: boolean;
  isFacebookError: boolean
  isInstagramError: boolean;
  isTwitterError: boolean;
  isLinkedinError: boolean;
  selectedLogo: any,
  selectedFavicon: any,
  SelectedLogoFileType: string,
  SelectedFaviconFileType: string,
  SelectedLogoFileSize: number,
  SelectedFaviconFileSize: number,
  HeaderNavigation: HeaderNavigation,
  mainSideBarId: string,
  focusedIndex: any,
  isEditMode: boolean,
  selectedMobileFileName: string,
  selectedDesktopFileName: string
  isHeaderImageUploaded: boolean,
  isFieldSelected: boolean
  token: token
  isLoading: boolean
  isMobileMenuOpenChecker: boolean,
  showStoreNameChecker: boolean,
  sectionIndex: number,
  cropperHeight: number,
  cropperWidth: number,
  ratio: number,
  isAddTextToBannerDrawerOpen: boolean;
  selectedDropdownIndex: number;
  chosenBannerPlatform: 'desktop' | 'mobile';
  bannerTextProperties: {
    mobile: MetadataSection
    desktop: MetadataSection
  }
  // Customizable Area End
}

// Customizable Area Start
export interface MetadataSection {
  textAlignment: string,
  textPosition: string,
  headerText: string,
  fontColor: string,
  fontSize: string
}

interface ImageAttributes {
  name: string;
  created_at: string;
  updated_at: string;
  url: string;
}

interface ImageData {
  id: string;
  type: string;
  attributes: ImageAttributes;
}

interface ApiResponse {
  data: ImageData[];
}

interface ModalImage {
  type: string;
  name: string;
  url: string;
  isSelect: boolean;
}


export type SectionDataMetadata = {
  id: string;
  type: string;
  attributes: {
    section_type: string;
    section_name: string;
    banner_url: string;
    mobile_banner_url: string;
    desktop_banner_url: string;
    desktop_banner_metadata: string;
    mobile_banner_filename: string;
    mobile_banner_metadata: string;
    is_deleted: boolean
  }
};

export interface HeaderNavigation {
  menuData: {
    id: number;
    label: string;
    options: string[];
    selectedOption: string;
  }[];
}

export interface ProductPreviewContentInterface {

  footer: {

    phone_number: number;
    copyright: string;
    facebook_url: string;
    x_url: string;
    youtube_url: string;
    linkedin_url: string;
    appstore_url: string;
    playstore_url: string;
    instagram_url: string;
    design_id: string;
    is_social_media: boolean;
    is_app_link: boolean;
    is_contact: boolean;
  };
  metadata: {
    catagoryData: Array<{ [key: string]: string }>,
    sectionUpdateData: Array<{ [key: string]: string }>,
    selectedValue: { [key: string]: string },
    headerNavigationData?: any
  };
  sections: {
    data: Array<SectionDataMetadata>
  };
  header: {
    isLogoUploaded: boolean,
    isFaviconUploaded: boolean,
    is_mobile_menu: boolean,
    selectedLogo: null | string,
    selectedFavicon: null | string,
    show_business_name: boolean,
    logo_url?: string,
    favicon_url?: string,
    logo_filename?: string,
    favicon_filename?: string,
  },
  themes_and_font: {
    primary_colour: string;
    secondary_colour: string;
    header_text: string;
    body_text: string;
  }

}

type token = string | null
export interface SectionData { [key: string]: string | boolean }
export interface ModalImageData {
  type: string;
  name: string;
  url: string;
  isSelect: boolean;
}
export interface ValidResponseType {
  data: object;
}
export interface SS {
  id: string;
}
// Customizable Area End

class ContactInformationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  postCreateWebsiteBuilderCallId: string = "";
  putWebsiteBuilderCallId: string = "";
  getProductPreviewContentCallId: string = ""
  getDefaultColorsAPI: string = "";
  getImageCatalogueAPI: string = "";
  scrollRef: React.RefObject<Scrollbars>;
  inputRef: any;
  debounceTimeout: NodeJS.Timeout | undefined;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.scrollRef = createRef();
    this.inputRef = React.createRef();
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage),
    getName(MessageEnum.ActionMessageFromToaster),
    ];

    this.state = {
      activeRightSideBarTab: "Footer",
      selectedColor: { primary: "", secondary: "", id: 0 },
      categoryOne: "",
      isColorSelectionOpen: false,
      isFontSelectionOpen: false,
      defaultColors: [],
      selectedFonts: { headerText: "", bodyText: "" },
      showContactInformation: false,
      showStoreLinkInputBox: false,
      showSocialLinks: false,
      selectedPhoneNumber: "",
      isDisabledSwitch: true,
      themeFontsContent: false,
      footerContent: false,
      facebookUrl: "",
      errorStatus: false,
      checkStatus: false,
      instagramUrl: "",
      errorInstagramStatus: false,
      checkInstagramStatus: false,
      twitterUrl: "",
      errorTwitterStatus: false,
      checkTwitterStatus: false,
      youTubeUrl: "",
      errorYouTubeStatus: false,
      checkYouTubeStatus: false,
      linkedinUrl: "",
      errorLinkedinStatus: false,
      checkLinkedinStatus: false,
      playStoreUrl: "",
      errorPlayStoreStatus: false,
      checkPlayStoreStatus: false,
      appStoreUrl: "",
      errorAppStoreStatus: false,
      checkAppStoreStatus: false,
      copyRight: "",
      errorCopyRightStatus: false,
      createId: "",
      copyRightError: "",
      showProductPreview: {
        header: {
          isLogoUploaded: false,
          isFaviconUploaded: false,
          selectedLogo: null,
          selectedFavicon: null,
          is_mobile_menu: false,
          show_business_name: false,
        },
        metadata: {
          catagoryData: [],
          sectionUpdateData: [],
          selectedValue: {}
        },
        sections: {
          data: [
            {
              id: "",
              type: "",
              attributes: {
                section_type: "",
                section_name: "",
                banner_url: "",
                mobile_banner_url: "",
                desktop_banner_url: "",
                desktop_banner_metadata: "",
                mobile_banner_filename: "",
                mobile_banner_metadata: "",
                is_deleted: false,
              }
            },
            {
              id: "",
              type: "",
              attributes: {
                section_type: "",
                section_name: "",
                banner_url: "",
                mobile_banner_url: "",
                desktop_banner_url: "",
                desktop_banner_metadata: "",
                mobile_banner_filename: "",
                mobile_banner_metadata: "",
                is_deleted: false,
              }
            }
          ]
        },
        footer: {
          phone_number: 5656565656,
          copyright: "",
          facebook_url: "",
          x_url: "",
          youtube_url: "",
          linkedin_url: "",
          appstore_url: "",
          playstore_url: "",
          instagram_url: "",
          design_id: "",
          is_social_media: false,
          is_app_link: false,
          is_contact: false
        },
        themes_and_font: {
          primary_colour: "",
          secondary_colour: "",
          header_text: "",
          body_text: ""
        }
      },
      sectionDataN: [],
      contactTempInformation: false,
      appTempStore: false,
      socialTempMedia: false,
      facebookTempUrl: "",
      instagramTempUrl: "",
      twitterTempUrl: "",
      youTubeTempUrl: "",
      linkedinTempUrl: "",
      playStoreTempUrl: "",
      appStoreTempUrl: "",
      copyTempRight: "Copyright � Current Year Store Name. All rights reserved.",
      sectionContent: false,
      selectTypeValue: "",
      cropperSectionOpen: false,
      selectedFile: null,
      selectedCropedFile: null,
      image: "",
      selectedFileType: "image/jpeg",
      uploadMobileBannerOpen: false,
      sectionImageSize: 0,
      isBannerType: "",
      selectedModal: false,
      selectedImage: {
        type: "",
        name: "",
        url: "",
        isSelect: false,
      },
      modalTabData: ["Catalogue", "Upload image"],
      activeModalTabName: "Catalogue",
      modalImages: [
        {
          type: "",
          name: "",
          url: "",
          isSelect: false,
        },
      ],
      sectionData: [
        {
          name: "banner",
          type: "banner",
          isOpen: false,
          sectionId: "id_" + this.generateRandomString(11) + "_" + Date.now(),
        },
        {
          name: "category",
          type: "category",
          isOpen: false,
          sectionId: "id_" + this.generateRandomString(11) + "_" + Date.now()
        },
        {
          name: "Products Display",
          type: "product",
          isOpen: false,
          sectionId: "id_" + this.generateRandomString(11) + "_" + Date.now()
        }
      ],
      categoriesData: ["Formal", "Casual", "Apparel", "Shirt"],
      productsDisplayData: ["AIR JORDAN 1 MID", "AIR JORDAN 12", "AIR JORDAN 1 RTS", "AIR JORDAN 2D"],
      addSectionBox: false,
      selectAddSectionType: "",
      selectAddSectionName: "",
      deleteSectionModal: false,
      deleteSectionItemIndex: -1,
      selectMobileBanner: "",
      selectDesktopBanner: "",
      bannerUrl: "",
      uploadHeaderLogoModalOpen: false,
      headerContent: false,
      uploadModalType: "",
      HeaderCropSectionOpen: false,
      isLogoUploaded: false,
      isFaviconUploaded: false,
      SelectedLogoName: "",
      SelectedFaviconName: "",
      isMobileMenuOpen: false,
      selectedLogo: null,
      selectedFavicon: null,
      SelectedLogoFileType: "",
      SelectedLogoFileSize: 0,
      SelectedFaviconFileType: "",
      SelectedFaviconFileSize: 0,
      showStoreName: false,
      storeName: '',
      HeaderNavigation: {
        menuData: [
          {
            id: 1,
            label: '1st Menu Item ',
            options: ['Shoe', 'Blazers', 'Casual Shirts', 'Casual Shoe', 'Flip Flops', 'Foot wear', 'Forage cap', 'French hat'],
            selectedOption: 'Select',
          },
          {
            id: 2,
            label: '2nd Menu Item ',
            options: ['Shoe', 'Blazers', 'Casual Shirts', 'Casual Shoe', 'Flip Flops', 'Foot wear', 'Forage cap', 'French hat'],
            selectedOption: 'Select',
          },
          {
            id: 3,
            label: '3rd Menu Item ',
            options: ['Shoe', 'Blazers', 'Casual Shirts', 'Casual Shoe', 'Flip Flops', 'Foot wear', 'Forage cap', 'French hat'],
            selectedOption: 'Select',
          },
          {
            id: 4,
            label: '4th Menu Item ',
            options: ['Shoe', 'Blazers', 'Casual Shirts', 'Casual Shoe', 'Flip Flops', 'Foot wear', 'Forage cap', 'French hat'],
            selectedOption: 'Select',
          },
        ]
      },
      isHeaderImageUploaded: false,
      token: '',
      isLoading: false,
      isMobileMenuOpenChecker: false,
      showStoreNameChecker: false,

      checkBannerUrl: false,
      sectionMainData: [],
      sectionId: "",
      isDeleteSection: {},
      allSelectValue: {},
      tempBannerUrl: "",
      isPlayError: false,
      isAppError: false,
      isYoutubeError: false,
      isFacebookError: false,
      isInstagramError: false,
      isTwitterError: false,
      isLinkedinError: false,
      mainSideBarId: "",
      focusedIndex: null,
      isEditMode: false,
      selectedMobileFileName: "",
      selectedDesktopFileName: "",
      isFieldSelected: false,
      sectionIndex: 0,
      cropperHeight: 0,
      cropperWidth: 0,
      ratio: 0,
      isAddTextToBannerDrawerOpen: false,
      selectedDropdownIndex: 0,
      chosenBannerPlatform: 'desktop',
      bannerTextProperties: {
        mobile: {
          textAlignment: "",
          textPosition: "",
          headerText: "",
          fontColor: "",
          fontSize: "",
        },
        desktop: {
          textAlignment: "",
          textPosition: "",
          headerText: "",
          fontColor: "",
          fontSize: "",
        }
      },
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  receive = async (from: string, message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.handleAPIMessageCreateContent(message)
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.handleAPIMessageUpdateContent(message)
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.handleAPIMessageShowContent(message)
    }

    if (this.getImageCatalogueAPI === apiRequestCallId) {
      this.handleImageCatalogResponse(responseJson)
    }
    if (this.getDefaultColorsAPI === apiRequestCallId) {
      this.handleDefaultColorsResponse(responseJson)
    }
    if (message.id === getName(MessageEnum.ActionMessageFromToaster)) {
      const type = message.getData(
        getName(MessageEnum.ActionMessageFromToasterMessage)
      );

      if (type === "SAVECHANGES") {
      this.saveChangesConditions()
      }
      if (type === "DISCARDCHANGES") {
        this.resetSectionData()
        this.resetFooterData()
        this.resetHeaderData()
        this.getProductPreview()
        // this.props.handleGetAPITrigger()
      }
    }
  }

  saveChangesConditions(){
    let createId = "1"

    let { errorStatus, errorInstagramStatus,
      errorTwitterStatus, errorLinkedinStatus,
      errorYouTubeStatus, errorPlayStoreStatus, errorAppStoreStatus, copyRightError
      } = this.state
    if (!errorStatus && !errorInstagramStatus && !errorTwitterStatus && !errorLinkedinStatus &&
      !errorYouTubeStatus && !errorPlayStoreStatus && !errorAppStoreStatus && copyRightError !== "Copyright must be at maximum of 150 characters" && this.checkEmptyValidationsSocial()
      && this.checkEmptyValidationsLink()
    ) {
      createId ? this.updateWebsiteBuilderContent() : this.createWebsiteBuilderContent()
    }
    else {
    this.validationConditionsCheck()
    }
  }
  validationConditionsCheck(){
    let { playStoreUrl, appStoreUrl } = this.state
    if (!this.checkEmptyValidationsLink()) {
      this.setState({
        isPlayError: playStoreUrl ? false : true,
        isAppError: appStoreUrl ? false : true,
      })
    } if (!this.checkEmptyValidationsSocial()) {
      this.validationConditionCheck2()
    }
  }

  onChangeBannerTextProperty = (name: string, value: string, platform: 'desktop' | 'mobile') => {
    if (name === "headerText") {
      value = value.slice(0, 40)
      if (value.split('\n').length === 4) {
        value = value.slice(0, value.length - 1)
      }
    }
    
    this.setState({
      bannerTextProperties: {
        ...this.state.bannerTextProperties,
        [platform]: {
          ...this.state.bannerTextProperties[platform],
          [name]: value
        }
      }
    },()=>{
      if(platform=='desktop')
      this.handleDesktopBannerMetadataLiveUpdate();
      if(platform=='mobile')
      this.handleMobileBannerMetadataLiveUpdate();
    })
  }

  toggleAddTextToBannerDrawer = (open: boolean, platform: 'desktop' | 'mobile') => {
    this.setState({
      isAddTextToBannerDrawerOpen: open,
      chosenBannerPlatform: platform,
    })
    this.setDefaultBannerDataText(this.state.selectedDropdownIndex)
  }

  handleMobileBannerMetadata = () => {
    const { bannerTextProperties, selectedDropdownIndex } = this.state;
    if(Number(bannerTextProperties.mobile.fontSize) > 0) {
      const mobileBannerMetadata = {
        text_alignment: bannerTextProperties.mobile.textAlignment,
        text_position: bannerTextProperties.mobile.textPosition,
        header_text: bannerTextProperties.mobile.headerText,
        font_colour: bannerTextProperties.mobile.fontColor,
        font_size: bannerTextProperties.mobile.fontSize
      }
      this.props.updateBannerMobileMetadata(selectedDropdownIndex, mobileBannerMetadata)
      this.saveChangesConditions()
      this.toggleAddTextToBannerDrawerClose(false, 'mobile')
      this.closeToastChange()
    } else {
      alert("Font size must be bigger than 0")
    }
  }

  handleDesktopBannerMetadata = () => {
    const { bannerTextProperties, selectedDropdownIndex } = this.state;
    if (Number(bannerTextProperties.desktop.fontSize) > 0) {
      const desktopBannerMetadata = {
        text_alignment: bannerTextProperties.desktop.textAlignment,
        text_position: bannerTextProperties.desktop.textPosition,
        header_text: bannerTextProperties.desktop.headerText,
        font_colour: bannerTextProperties.desktop.fontColor,
        font_size: bannerTextProperties.desktop.fontSize
      }
      this.props.updateBannerDesktopMetadata(selectedDropdownIndex, desktopBannerMetadata)
      this.saveChangesConditions()
      this.toggleAddTextToBannerDrawerClose(false, 'desktop')
      this.closeToastChange()
    } else {
      alert("Font size must be bigger than 0")
    }
  }

  toggleAddTextToBannerDrawerClose = (open: boolean, platform: 'desktop' | 'mobile') => {
    this.setState({
      isAddTextToBannerDrawerOpen: open,
      chosenBannerPlatform: platform,
    })
  }

  handleDesktopBannerMetadataLiveUpdate = () => {
    const { bannerTextProperties, selectedDropdownIndex } = this.state;
    if (Number(bannerTextProperties.desktop.fontSize) > 0) {
      const desktopBannerMetadata = {
        text_alignment: bannerTextProperties.desktop.textAlignment,
        text_position: bannerTextProperties.desktop.textPosition,
        header_text: bannerTextProperties.desktop.headerText,
        font_colour: bannerTextProperties.desktop.fontColor,
        font_size: bannerTextProperties.desktop.fontSize
      }
      this.props.updateBannerDesktopMetadata(selectedDropdownIndex, desktopBannerMetadata)
    } else {
      alert("Font size must be bigger than 0")
    }
  }
  handleMobileBannerMetadataLiveUpdate = () => {
    const { bannerTextProperties, selectedDropdownIndex } = this.state;
    if(Number(bannerTextProperties.mobile.fontSize) > 0) {
      const mobileBannerMetadata = {
        text_alignment: bannerTextProperties.mobile.textAlignment,
        text_position: bannerTextProperties.mobile.textPosition,
        header_text: bannerTextProperties.mobile.headerText,
        font_colour: bannerTextProperties.mobile.fontColor,
        font_size: bannerTextProperties.mobile.fontSize
      }
      this.props.updateBannerMobileMetadata(selectedDropdownIndex, mobileBannerMetadata)
    } else {
      alert("Font size must be bigger than 0")
    }
  }

  validationConditionCheck2(){
    let {  youTubeUrl, linkedinUrl, facebookUrl, instagramUrl, twitterUrl } = this.state
    this.setState({
      isLinkedinError: linkedinUrl ? false : true,
      isYoutubeError: youTubeUrl ? false : true,
      isFacebookError: facebookUrl ? false : true,
      isInstagramError: instagramUrl ? false : true,
      isTwitterError: twitterUrl ? false : true,
    })
  }
  checkEmptyValidationsSocial() {
    if (this.state.showSocialLinks) {
      if (this.state.youTubeUrl || this.state.linkedinUrl || this.state.facebookUrl || this.state.instagramUrl || this.state.twitterUrl) {
        return true
      } else { return false }
    } else {
      return true
    }
  }

  checkEmptyValidationsLink() {
    if (this.state.showStoreLinkInputBox) {
      if ((this.state.playStoreUrl || this.state.appStoreUrl)) {
        return true
      } else {
        return false
      }
    }
    else {
      return true
    }
  }

  checkEmptyValidations() {
    if ((this.state.showStoreLinkInputBox)
    ) {
      if ((this.state.playStoreUrl || this.state.appStoreUrl)) {
        this.setState({
          isPlayError: false,
          isAppError: false,
        })
      }
    }
    if ((this.state.showSocialLinks)) {
    this.updateStateForMain()
    } else {

      if (!this.checkEmptyValidationsLink()) {
        this.updateState();
      } if (!this.checkEmptyValidationsSocial()) {
      this.updateStateForElse();
      }
    }
  }

  updateStateForMain(){
    if (this.state.youTubeUrl || this.state.linkedinUrl || this.state.facebookUrl || this.state.instagramUrl || this.state.twitterUrl) {
      this.setState({
        isLinkedinError: false,
        isYoutubeError: false,
        isFacebookError: false,
        isInstagramError: false,
        isTwitterError: false,
      })
    }
  }
  updateState(){
    this.setState({
      isPlayError: this.state.playStoreUrl ? false : true,
      isAppError: this.state.appStoreUrl ? false : true,
    })
  }

  updateStateForElse(){
    this.setState({
      isLinkedinError: this.state.linkedinUrl ? false : true,
      isYoutubeError: this.state.youTubeUrl ? false : true,
      isFacebookError: this.state.facebookUrl ? false : true,
      isInstagramError: this.state.instagramUrl ? false : true,
      isTwitterError: this.state.twitterUrl ? false : true,
    })
  }
  async componentDidMount() {
    let id = await getStorageData("id")
    const token = await getStorageData("admintoken")
    this.setState({
      mainSideBarId: id,
      token
    }, () => { this.getProductPreview() })
    if (token) {
      this.setState({
        isLoading: true
      })
      this.getDefaultColors()
      this.getImageCatalogueAPI = this.apiCallHandle(token, `${configJSON.getImageCatalougeAPIEndpoint}?per_page=999`, configJSON.getApiMethod)
    }
  };

  resetSectionData = () => {
    this.setState({
      sectionContent: false,
      selectTypeValue: "",
      cropperSectionOpen: false,
      selectedFile: null,
      selectedCropedFile: null,
      image: "",
      uploadMobileBannerOpen: false,
      sectionImageSize: 0,
      isBannerType: "",
      selectedModal: false,
      selectedImage: {
        type: "",
        name: "",
        url: "",
        isSelect: false,
      },
      addSectionBox: false,
      selectAddSectionType: "",
      selectAddSectionName: "banner",
      deleteSectionModal: false,
      selectMobileBanner: "",
      selectDesktopBanner: "",
      bannerUrl: "",
      checkBannerUrl: false,
      selectedMobileFileName: "",
      allSelectValue: {},
      sectionData: [
        {
          name: "banner",
          type: "banner",
          isOpen: false,
          sectionId: "id_" + this.generateRandomString(11) + "_" + Date.now() 
        },
        {
          name: "category",
          type: "category",
          isOpen: false,
          sectionId: "id_" + this.generateRandomString(11) + "_" + Date.now()
        },
        {
          name: "Products Display",
          type: "product",
          isOpen: false,
          sectionId: "id_" + this.generateRandomString(11) + "_" + Date.now()
        }
      ],
    })
  }

  resetFooterData = () => {
    this.setState({
      showStoreLinkInputBox: false,
      showSocialLinks: false,
      copyRight: "",
      facebookUrl: "",
      instagramUrl: "",
      twitterUrl: "",
      youTubeUrl: "",
      linkedinUrl: "",
      playStoreUrl: "",
      showContactInformation: false,
      checkPlayStoreStatus: false,
      copyRightError: "",
      checkInstagramStatus: false,
      checkTwitterStatus: false,
      checkYouTubeStatus: false,
      checkLinkedinStatus: false,
      checkAppStoreStatus: false,
      errorStatus: false,
      appStoreUrl: ""
    })
  };

  resetHeaderData = () => {
    this.setState({
      uploadHeaderLogoModalOpen: false,
      uploadModalType: "",
      HeaderCropSectionOpen: false,
      isLogoUploaded: false,
      isFaviconUploaded: false,
      SelectedLogoName: "",
      SelectedFaviconName: "",
      isMobileMenuOpen: false,
      selectedLogo: null,
      selectedFavicon: null,
      SelectedLogoFileType: "",
      SelectedLogoFileSize: 0,
      SelectedFaviconFileType: "",
      SelectedFaviconFileSize: 0,
      showStoreName: false,
      storeName: '',
      HeaderNavigation: {
        menuData: [
          {
            id: 1,
            label: '1st Menu Item ',
            options: ['Shoe', 'Blazers', 'Casual Shirts', 'Casual Shoe', 'Flip Flops', 'Foot wear', 'Forage cap', 'French hat'],
            selectedOption: 'Select'
          },
          {
            id: 2,
            label: '2nd Menu Item ',
            options: ['Shoe', 'Blazers', 'Casual Shirts', 'Casual Shoe', 'Flip Flops', 'Foot wear', 'Forage cap', 'French hat'],
            selectedOption: 'Select'
          },
          {
            id: 3,
            label: '3rd Menu Item ',
            options: ['Shoe', 'Blazers', 'Casual Shirts', 'Casual Shoe', 'Flip Flops', 'Foot wear', 'Forage cap', 'French hat'],
            selectedOption: 'Select'
          },
          {
            id: 4,
            label: '4th Menu Item ',
            options: ['Shoe', 'Blazers', 'Casual Shirts', 'Casual Shoe', 'Flip Flops', 'Foot wear', 'Forage cap', 'French hat'],
            selectedOption: 'Select'
          },
        ]
      },
    })
  }

  handleSectionMainData = (sectionId: string, sectionType: string, sectionName: string, fieldName: string, fieldValue: SelectedFileTypeAlias, fileName?: any, fileValue?: string) => {
    let sectionMainData = this.state.sectionMainData.slice();
    if (sectionMainData.length === 0) {
      sectionMainData[0] = {
        section_type: sectionType,
        section_name: sectionName,
        [fieldName]: fieldValue,
        [fileName]: fileValue,
        is_Deleted: false,
        sectionId
      };
    } else {
      let mainData = sectionMainData.find(data => data.sectionId === sectionId);
      if (mainData) {
        let matchedIndex = sectionMainData.findIndex(data => data.sectionId === sectionId);
        let newData = { ...mainData, [fieldName]: fieldValue, [fileName]: fileValue };
        sectionMainData[matchedIndex] = newData;
      } else {
        sectionMainData.push({
          section_type: sectionType,
          section_name: sectionName,
          [fieldName]: fieldValue,
          [fileName]: fileValue,
          is_Deleted: false,
          sectionId
        });
      }
    }
    this.setState({
      sectionMainData,
      allSelectValue: {
        ...this.state.allSelectValue,
        [sectionId + fieldName]: fieldValue
      }
    }, () => {
      this.props.realTimeCategoryHandler(this.state.sectionMainData);
      this.props.realTimeProductHandler(this.state.sectionMainData);
      typeof fieldValue === "string" && this.props.overrideCategoryOrProductSelectedValue(sectionId, sectionType, fileName, fieldValue);
      this.openToastChange();
    });
  };

  sectionDataModify = () => {
    let categoryData = this.state.sectionMainData.filter((item) => {
      return item.section_type === "category";
    })[0];

    let CategoryList = [];
    for (const key in categoryData) {
      if (key.startsWith("category")) {
        CategoryList.push({
          category_name: categoryData[key],
          category_image: "https://cdn.pixabay.com/photo/2016/06/03/17/35/shoes-1433925_1280.jpg"
        });
      }
    }
    return CategoryList;
  }

  sectionDataProductModify = () => {
    let productData = this.state.sectionMainData.filter((item: any) => {
      return item.section_type === "product";
    })[0];

    let ProductList = [];
    for (const key in productData) {
      if (key.startsWith("product")) {
        ProductList.push({
          product_name: productData[key],
          product_image: "https://cdn.pixabay.com/photo/2016/06/03/17/35/shoes-1433925_1280.jpg",
          new_rate: "129,95",
          old_rate: "149,00",
          currency: "�",
          discount: "20%"
        });
      }
    }
    return ProductList
  }

  handleBannerUrl = (event: React.ChangeEvent<HTMLInputElement>, sectionId: string, section_type: string, section_name: string, index: number) => {
    const { value, name } = event.target;
    const trimmedValue = value.trim();
    const bannerUrlRegex = /^(www.|http:\/\/|https:\/\/)/;
    ;
    this.setState({
      bannerUrl: trimmedValue,
    }, () => {
      if (this.state.bannerUrl === this.state.tempBannerUrl) {
        this.closeToastChange()
      } else {
        this.openToastChange()
      }
      if (bannerUrlRegex.test(this.state.bannerUrl)) {
        this.props.realTimeHandler(this.state.bannerUrl, name, "sections", "")
        this.handleSectionMainData(sectionId, section_type, section_name, name, value)
        this.setState({
          checkBannerUrl: false
        })
      } else {
        if (!this.state.bannerUrl) {
          this.setState({
            checkBannerUrl: false
          })
        } else {
          this.setState({
            checkBannerUrl: true
          })
        }
      }
      this.props.updateBannerUrl(index, this.state.bannerUrl)
    })
  }

  handleSectionCropperClose = () => {
    this.setState({
      cropperSectionOpen: false
    }, () => {
      this.setState({
        uploadMobileBannerOpen: true
      })
    })
  };

  handleSectionClose = () => {
    this.setState({
      uploadMobileBannerOpen: false
    })
  };

  addSection = () => {
    this.setState({ addSectionBox: true }, () => {
      this.openToastChange()
      this.props.handleCloseAllSections()
    });
  }

  removeAddSectionBox = () => {
    this.setState({
      addSectionBox: false
    });
  }

  handleSelectCatalogue = () => {
    const { modalImages, token } = this.state;
    const updatedImages = modalImages.map(image => ({
      ...image,
      isSelect: false,
    }));

    this.getImageCatalogueAPI = this.apiCallHandle(token, `${configJSON.getImageCatalougeAPIEndpoint}?per_page=999`, configJSON.getApiMethod)
    this.setState({
      isLoading: true,
      modalImages: updatedImages,
      selectedImage: {
        type: "",
        name: "",
        url: "",
        isSelect: false,
      },
      selectedModal: true,
    }, () => ({
      uploadMobileBannerOpen: false,
    }))
  }

  setDefaultBannerDataText = (index: number) => {
    let mobileBannerMetadata = this.state.showProductPreview.sections.data[index]?.attributes.mobile_banner_metadata;
    let desktopBannerMetadata = this.state.showProductPreview.sections.data[index]?.attributes.desktop_banner_metadata;
    const mobileMetadata = this.isJson(mobileBannerMetadata) ? JSON.parse(mobileBannerMetadata) : mobileBannerMetadata
    const desktopMetadata = this.isJson(desktopBannerMetadata) ? JSON.parse(desktopBannerMetadata) : desktopBannerMetadata
    this.setState({
      bannerTextProperties: {
        mobile: {
          textAlignment: mobileMetadata?.text_alignment || "",
          textPosition: mobileMetadata?.text_position || "",
          headerText: mobileMetadata?.header_text || "",
          fontColor: mobileMetadata?.font_colour || "",
          fontSize: mobileMetadata?.font_size || ""
        },
        desktop: {
          textAlignment: desktopMetadata?.text_alignment || "",
          textPosition: desktopMetadata?.text_position || "",
          headerText: desktopMetadata?.header_text || "",
          fontColor: desktopMetadata?.font_colour || "",
          fontSize: desktopMetadata?.font_size || ""
        }
      }
    },()=>{
      this.props.updateBannerDesktopMetadata(index, desktopMetadata)
      this.props.updateBannerMobileMetadata(index, mobileMetadata)
    })
  }

  toggleDropDown = (dropDownIndex: number, fromBanner: boolean = false) => {
    if (fromBanner) {
      this.setDefaultBannerDataText(dropDownIndex)
    }
    this.setState({ selectedDropdownIndex: dropDownIndex })
    this.props.handleSectionDropDownOpen(dropDownIndex)
  }

  typeSelectHandler = (index: number, type: string) => {
    let sectionNewData = this.state.sectionData.map((item: SectionData, itemIndex: number) => {
      if (index === itemIndex) {
        return {
          ...item,
          type
        }
      }
      return item
    })
    this.setState({
      sectionData: sectionNewData
    }, () => {
      this.openToastChange();
      this.props.updateSectionType(index, type)
    })
  };

  addNewSection = (event: any) => {
    const { selectAddSectionName, sectionData } = this.state;
    let name = selectAddSectionName;

    if (event.target.value === "banner") {
      name = "banner";
    }

    const newSection = {
      name: name,
      isOpen: true,
      type: event.target.value,
      sectionId: "id_" + this.generateRandomString(11) + "_" + Date.now()
    };

    const updatedSectionData = [...sectionData, newSection];

    this.setState({
      sectionData: updatedSectionData,
      addSectionBox: false,
      selectAddSectionType: "",
      selectAddSectionName: ""
    }, () => { this.props.handleSectionAdd(event.target.value, name) });
  }

  changeSectionName = (event: React.ChangeEvent<HTMLInputElement>, sectionId: string, sectionItemIndex: number, section_name: string,) => {
    let sectionName = event.target.value;
    this.props.realTimeSectionCategoryName(sectionId, sectionName, section_name)
    this.handleSectionMainData(sectionId, sectionName, section_name, "section_type", sectionName)
    const copySectionData = [...this.state.sectionData];
    let updatedSectionItem = copySectionData[sectionItemIndex];
    updatedSectionItem = {
      ...updatedSectionItem,
      name: sectionName
    }
    copySectionData[sectionItemIndex] = updatedSectionItem;
    this.setState({
      sectionData: copySectionData
    }, () => { this.props.updateSectionName(sectionId, sectionItemIndex, sectionName); })
  }

  handleCropperSubmit = (data: string) => {
    const { sectionIndex, isBannerType, selectedMobileFileName, selectedDesktopFileName } = this.state
    const bannerIndex = sectionIndex
    const fileName = isBannerType === "mobile" ? selectedMobileFileName : selectedDesktopFileName
    if (data) {
      this.setState(
        {
          selectedCropedFile: data,
          image: data,
        },
        () => {
          this.props.updateBannerMobileData(bannerIndex, data, fileName, isBannerType);
          this.setState({
            cropperSectionOpen: false,
            uploadMobileBannerOpen: false
          }, () => {
            this.openToastChange()
          });
        }
      );
    }
  };

  convertBase64ToImageFile = (baseFile: any) => {
    const base64ImageData = baseFile;
    const [contentType, base64Data] = base64ImageData.split(';base64,');
    const mimeType = contentType.split(':')[1];
    const binaryData = atob(base64Data);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const view = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      view[i] = binaryData.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: mimeType });
    const file = new File([blob], "image.png", { type: mimeType });
    return file

  }

  closeDeleteSectionModal = () => {
    this.setState({ deleteSectionModal: false })
  }

  deleteSection = () => {
    const { sectionData, isDeleteSection, sectionMainData, deleteSectionItemIndex } = this.state;

    const updatedSectionMainData = sectionMainData.map((item) => {
      if (item.sectionId === isDeleteSection.sectionId) {
        return {
          ...item,
          is_Deleted: true
        };
      }
      return item;
    });

    const updatedSectionData = [...sectionData];
    updatedSectionData.splice(deleteSectionItemIndex, 1);

    this.setState({
      sectionData: updatedSectionData,
      sectionMainData: updatedSectionMainData,
      deleteSectionItemIndex: -1,
      deleteSectionModal: false,
    }, () => {
      this.openToastChange();
      this.props.deleteSection(deleteSectionItemIndex)
    });

    this.setState({
      sectionMainData: updatedSectionMainData
    });
  }


  openDeleteSectionModal = (sectionItemIndex: number, sectionDelete: { [key: string]: string | boolean }) => {
    this.setState({
      deleteSectionItemIndex: sectionItemIndex,
      deleteSectionModal: true,
      isDeleteSection: sectionDelete
    })
  }

  handleChangeFacebook = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    const trimmedValue = value.trim();
    const facebookUrlRegex = /^(www\.|http:\/\/|https:\/\/)/;

    this.setState({
      facebookUrl: trimmedValue,
    }, () => {
      if (this.state.facebookUrl === this.state.facebookTempUrl) {
        this.closeToastChange()
      } else {
        this.openToastChange()
      }
      if (facebookUrlRegex.test(this.state.facebookUrl)) {
        this.props.updateFooter(name, value)
        this.setState({
          errorStatus: false,
          checkStatus: true,
          isFacebookError: false
        }, () => {
          this.checkEmptyValidations()
        })
      } else {
        if (!this.state.facebookUrl) {
          this.props.updateFooter(name, value)
          this.setState({
            errorStatus: false,
            checkStatus: false,
            isFacebookError: false
          })
        } else {
          this.setState({
            errorStatus: true,
            checkStatus: false,
            isFacebookError: false
          })
        }
      }
    })
  }

  handleChangeInstagram = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    const trimmedValue = value.trim();
    const instagramUrlRegex = /^(www\.|http:\/\/|https:\/\/)/;
    this.setState({
      instagramUrl: trimmedValue,
    }, () => {
      if (this.state.instagramUrl === this.state.instagramTempUrl) {
        this.closeToastChange()
      } else {
        this.openToastChange()
      }
      if (instagramUrlRegex.test(this.state.instagramUrl)) {
        this.props.updateFooter(name, value)
        this.setState({
          errorInstagramStatus: false,
          checkInstagramStatus: true,
          isInstagramError: false
        }, () => {
          this.checkEmptyValidations()
        })
      } else {
        if (!this.state.instagramUrl) {
          this.props.updateFooter(name, value)
          this.setState({
            errorInstagramStatus: false,
            checkInstagramStatus: false,
            isInstagramError: false
          })
        } else {
          this.setState({
            errorInstagramStatus: true,
            checkInstagramStatus: false,
            isInstagramError: false
          })
        }
      }
    })
  };

  handleChangeTwitter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    const trimmedValue = value.trim();
    const twitterUrlRegex = /^(www\.|http:\/\/|https:\/\/)/;
    this.setState({
      twitterUrl: trimmedValue,
    }, () => {
      if (this.state.twitterUrl === this.state.twitterTempUrl) {
        this.closeToastChange()
      } else {
        this.openToastChange()
      }
      if (twitterUrlRegex.test(this.state.twitterUrl)) {
        this.props.updateFooter(name, value)
        this.setState({
          errorTwitterStatus: false,
          checkTwitterStatus: true,
          isTwitterError: false
        }, () => {
          this.checkEmptyValidations()
        })
      } else {
        if (!this.state.twitterUrl) {
          this.props.updateFooter(name, value)

          this.setState({
            errorTwitterStatus: false,
            checkTwitterStatus: false,
            isTwitterError: false
          })
        } else {
          this.setState({
            errorTwitterStatus: true,
            checkTwitterStatus: false,
            isTwitterError: false
          })
        }
      }
    })
  };

  handleChangeYouTube = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    const trimmedValue = value.trim();
    const youTubeUrlRegex = /^(www\.|http:\/\/|https:\/\/)/;
    this.setState({
      youTubeUrl: trimmedValue,
    }, () => {
      if (this.state.youTubeUrl === this.state.youTubeTempUrl) {
        this.closeToastChange()
      } else {
        this.openToastChange()
      }
      if (youTubeUrlRegex.test(this.state.youTubeUrl)) {
        this.props.updateFooter(name, value)
        this.setState({
          errorYouTubeStatus: false,
          checkYouTubeStatus: true,
          isYoutubeError: false
        }, () => {
          this.checkEmptyValidations()
        })
      } else {
        if (!this.state.youTubeUrl) {
          this.props.updateFooter(name, value)

          this.setState({
            errorYouTubeStatus: false,
            checkYouTubeStatus: false,
            isYoutubeError: false
          })
        } else {
          this.setState({
            errorYouTubeStatus: true,
            checkYouTubeStatus: false,
            isYoutubeError: false
          })
        }
      }
    })
  }

  handleChangeLinkedin = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    const trimmedValue = value.trim();
    const linkedinUrlRegex = /^(www\.|http:\/\/|https:\/\/)/;
    this.setState({
      linkedinUrl: trimmedValue,
    }, () => {
      if (this.state.linkedinUrl === this.state.linkedinTempUrl) {
        this.closeToastChange()
      } else {
        this.openToastChange()
      }
      if (linkedinUrlRegex.test(this.state.linkedinUrl)) {
        this.props.updateFooter(name, value)
        this.setState({
          errorLinkedinStatus: false,
          checkLinkedinStatus: true,
          isLinkedinError: false
        }, () => {
          this.checkEmptyValidations()
        })
      } else {
        if (!this.state.linkedinUrl) {
          this.props.updateFooter(name, value)

          this.setState({
            errorLinkedinStatus: false,
            checkLinkedinStatus: false,
            isLinkedinError: false
          })
        } else {
          this.setState({
            errorLinkedinStatus: true,
            checkLinkedinStatus: false,
            isLinkedinError: false
          })
        }
      }
    })
  }


  handleChangePlayStore = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    const trimmedValue = value.trim();
    const playStoreUrlRegex = /^(www\.|http:\/\/|https:\/\/)/;
    this.setState({
      playStoreUrl: trimmedValue,
    }, () => {
      if (this.state.playStoreUrl === this.state.playStoreTempUrl) {
        this.closeToastChange()
      } else {
        this.openToastChange()
      }
      if (playStoreUrlRegex.test(this.state.playStoreUrl)) {
        this.props.updateFooter(name, value)
        this.setState({
          errorPlayStoreStatus: false,
          checkPlayStoreStatus: true,
          isPlayError: false
        }, () => {
          this.checkEmptyValidations()
        })
      } else {
        if (!this.state.playStoreUrl) {
          this.props.updateFooter(name, value)
          this.setState({
            errorPlayStoreStatus: false,
            checkPlayStoreStatus: false,
            isPlayError: false
          })
        } else {
          this.setState({
            errorPlayStoreStatus: true,
            checkPlayStoreStatus: false,
            isPlayError: false
          })
        }
      }
    })
  }

  handleChangeAppStore = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    const trimmedValue = value.trim();
    const appStoreUrlRegex = /^(www\.|http:\/\/|https:\/\/)/;

    this.setState({
      appStoreUrl: trimmedValue,
    }, () => {
      if (this.state.appStoreUrl === this.state.appStoreTempUrl) {
        this.closeToastChange()
      } else {
        this.openToastChange()
      }
      if (appStoreUrlRegex.test(this.state.appStoreUrl)) {
        this.props.updateFooter(name, value)
        this.setState({
          errorAppStoreStatus: false,
          checkAppStoreStatus: true,
          isAppError: false
        }, () => {
          this.checkEmptyValidations()
        })
      } else {
        if (!this.state.appStoreUrl) {
          this.props.updateFooter(name, value)

          this.setState({
            errorAppStoreStatus: false,
            checkAppStoreStatus: false,
            isAppError: false
          })
        } else {
          this.setState({
            errorAppStoreStatus: true,
            checkAppStoreStatus: false,
            isAppError: false
          })
        }
      }
    })
  };

  handleChangeCopyRight = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    this.setState({
      copyRight: value,
    }, () => {
      this.props.updateFooter(name, value)
      if (this.state.copyRight.length > 150) {
        this.setState({
          copyRightError: "Copyright must be at maximum of 150 characters"
        })
      }
      if (this.state.copyRight === this.state.copyTempRight) {
        this.closeToastChange()
      } else {
        this.openToastChange()
      }
    })
  };

  closeSelectedModal = () => {
    this.setState({
      selectedModal: false, selectedImage: {
        type: "",
        name: "",
        url: "",
        isSelect: false,
      },
    });
    this.getImageCatalogueAPI = this.apiCallHandle(this.state.token, `${configJSON.getImageCatalougeAPIEndpoint}?per_page=999&search=`, configJSON.getApiMethod)
  };

  selectActiveTab = (tabName: string) => {
    this.setState({ activeModalTabName: tabName });
  };

  selectImage = (imageIndex: number, name: string) => {
    const imageArray = [...this.state.modalImages];
    imageArray.forEach((imageItem, index) => {
      if (index == imageIndex) {
        imageItem = {
          ...imageItem,
          isSelect: true,
        }
      } else {
        imageItem = {
          ...imageItem,
          isSelect: false,
        }
      }
      imageArray[index] = imageItem;
      if (this.state.isBannerType === "mobile") {
        this.setState({
          selectedMobileFileName: name
        })
      } else {
        this.setState({
          selectedDesktopFileName: name
        })
      }
      this.setState({
        selectedImage: imageArray[imageIndex],
        modalImages: imageArray
      }, () => {
        this.imageUrlToBase64(this.state.selectedImage);
      })
    })
  };

  imageUrlToBase64 = (data: ModalImageData) => {
    if (data.url) {
      const secureUrl = data.url.replace(/^http:\/\//i, 'https://');
      const imgData = new Image();

      imgData.crossOrigin = 'Anonymous';

  imgData.onload = () => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = imgData.width;
    canvas.height = imgData.height;

    context?.drawImage(imgData, 0, 0, imgData.width, imgData.height);

    const base64Data = canvas.toDataURL('image/jpeg'); 
    this.setState({
      selectedFile: base64Data,
      selectedFileType: 'image/jpeg', 
    });
  };

  imgData.src = secureUrl;
}
};

  toggleAppStoreLinkInputBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { name } = event.target;
    this.setState({
      showStoreLinkInputBox: event.target.checked,
    }, () => {
      this.props.realTimeHandler(this.state.showStoreLinkInputBox, name, "footer", "")
      if (this.state.showStoreLinkInputBox === this.state.appTempStore) {
        this.closeToastChange()
      } else {
        this.openToastChange()
      }
    });
  };

  toggleContactInformation = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { name, checked } = event.target
    this.setState({
      showContactInformation: checked,
    }, () => {
      this.props.updateFooter(name, checked)
      if (this.state.showContactInformation === this.state.contactTempInformation) {
        this.closeToastChange()
      } else {
        this.openToastChange()
      }
    });
  };

  toggleStorenameForHeader = (event: React.ChangeEvent<HTMLInputElement>) => {
    const trigger = event.target.checked
    this.setState({
      showStoreName: trigger,
    }, () => {
      if (this.state.showStoreName === this.state.showStoreNameChecker) {
        this.closeToastChange()
      } else {
        this.openToastChange()
      }
    });
    this.props.HandleStoreNameEnabled(trigger)
  };

  toggleMobileMenuForHeader = (event: React.ChangeEvent<HTMLInputElement>) => {
    const trigger = event.target.checked
    this.setState({
      isMobileMenuOpen: trigger,
    }, () => {
      if (this.state.isMobileMenuOpen === this.state.isMobileMenuOpenChecker) {
        this.closeToastChange()
      } else {
        this.openToastChange()
      }
    });
    this.props.HandleMobileMenyTrigger(trigger)
  };

  closeToastChange = () => {
    this.props.hideHeaderBar()
  };

  selectImageFile = (files: any) => {
    this.setState({
      uploadMobileBannerOpen: false
    })
    const reader = new FileReader();
    reader.onload = () => {
      if (this.state.isBannerType === "mobile") {
        this.setState({
          selectedMobileFileName: files[0].name
        })
      } else {
        this.setState({
          selectedDesktopFileName: files[0].name
        })
      }
      this.setState({ selectedFile: reader.result, sectionImageSize: files[0].size, selectedFileType: files[0].type }, () => {
        this.handleSectionCropperOpen();
        // this.openToastChange()
      });
    };
    reader.readAsDataURL(files[0]);
  };


  handleNextCatalogue = () => {
    if (this.state.selectedImage.isSelect === true) {
      this.setState({
        cropperSectionOpen: true
      }, () => {
        this.setState({
          selectedModal: false
        })
      })
    }
  };

  handleUploadModal = (index: number, sectionId: string) => {
    this.setState({
      uploadMobileBannerOpen: true,
      isBannerType: "mobile",
      sectionId,
      sectionIndex: index,
    })
  };

  handleUploadDesktopModal = (index: number, sectionId: string) => {
    this.setState({
      uploadMobileBannerOpen: true,
      isBannerType: "Desktop",
      sectionId,
      sectionIndex: index,
    }, () => {
      // this.openToastChange()
    })
  };

  handleSectionCropperOpen = () => {
    this.setState({
      cropperSectionOpen: true
    }, () => {
      this.setState({
        uploadMobileBannerOpen: false
      })
    })
  };

  HandleLogoUploadModalOpen = (modalType: string) => {
    if (this.state.isLogoUploaded && modalType === "logo" || this.state.isFaviconUploaded && modalType === "favicon") {
      this.setState({
        HeaderCropSectionOpen: true,
        uploadModalType: modalType,
        isHeaderImageUploaded: false
      })
      if (this.state.isLogoUploaded && modalType == "logo") {
        this.setState({ ratio: 4.2 })
      }
      if (this.state.isFaviconUploaded && modalType === "favicon") {
        this.setState({ ratio: 1.1 })
      }
      return
    }
    this.setState({
      uploadHeaderLogoModalOpen: true,
      uploadModalType: modalType,
      isHeaderImageUploaded: false
    })
  };

  HandleLogoUploadModalClose = () => {
    this.setState({
      uploadHeaderLogoModalOpen: false,
      HeaderCropSectionOpen: false
    }, () => {
      this.getProductPreview()
    })
  };

  HandleNextForHeaderModal = () => {
    this.setState({
      HeaderCropSectionOpen: true
    }, () => {
      this.setState({
        uploadHeaderLogoModalOpen: false
      })
    })
  };

  selectLogoFile = (files: Blob[] & FileList) => {
    this.setState({
      uploadMobileBannerOpen: false,
      isHeaderImageUploaded: true
    })
    const reader = new FileReader();
    reader.onload = () => {
      if (this.state.uploadModalType === "logo") {
        this.setState({ selectedLogo: reader.result, SelectedLogoFileSize: files[0].size, SelectedLogoFileType: files[0].type, SelectedLogoName: files[0].name }, () => {
          this.HandleNextForHeaderModal();
        });
      } else {
        this.setState({ selectedFavicon: reader.result, SelectedFaviconFileSize: files[0].size, SelectedFaviconFileType: files[0].type, SelectedFaviconName: files[0].name }, () => {
          this.HandleNextForHeaderModal();
        });
      }
    };
    reader.readAsDataURL(files[0]);
  };

  handleCatagoryChange = (event: React.ChangeEvent<{ value: string }>, menuItemId: number) => {
    const selectedCategory: string = event.target.value;
    const isOptionSelected = this.state.HeaderNavigation.menuData.some(item => item.selectedOption === selectedCategory);

    if (!isOptionSelected) {
      const updatedMenuData = this.state.HeaderNavigation.menuData.map((menuItem) => {
        if (menuItem.id === menuItemId) {
          return { ...menuItem, selectedOption: selectedCategory };
        }
        return menuItem;
      });

      this.setState({
        HeaderNavigation: {
          ...this.state.HeaderNavigation,
          menuData: updatedMenuData,
        },
      }, () => {
        this.openToastChange();
      });
      this.props.HandleNavigationMenuOptions(updatedMenuData);
    }
  };

  handleEditIconClick = (index: number) => {
    if (this.inputRef) {
      this.inputRef.focus();

      this.setState({ focusedIndex: index })
    }
  }

  handleBlur = () => {
    this.setState({
      focusedIndex: null,
    });
  };

  handleLogoFaviconCropper = (data: string) => {
    if (data) {
      if (this.state.uploadModalType === "logo") {
  
          this.setState({
            isLogoUploaded: true,
            selectedLogo: data,
          }, () => {
            this.openToastChange()
          })
          this.props.HandleLogoUpload(data)
      
   
      }
      if (this.state.uploadModalType === "favicon") {
        this.setState({
          isFaviconUploaded: true,
          selectedFavicon: data,
        }, () => {
          this.openToastChange()
        })
      }
      this.setState({
        HeaderCropSectionOpen: false,
        uploadHeaderLogoModalOpen: false
      });
    }
  };

  HandleNextForHeaderModalClose = () => {
    this.setState({
      HeaderCropSectionOpen: false,
      isHeaderImageUploaded: false
    }, () => {
      this.setState({
        uploadHeaderLogoModalOpen: true,
      })
    })
  };

  openToastChange = () => {
    this.props.showHeaderBar({
      message: "",
      ignoreClickAway: true,
    });
  };

  toggleSocialLinksBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    this.setState({
      showSocialLinks: checked,
    }, () => {
      this.props.updateFooter(name, checked)
      if (this.state.showSocialLinks === this.state.socialTempMedia) {
        this.closeToastChange()
      } else {
        this.openToastChange()
      }
    });
  };

  toggleFooterContent = () => {
    this.setState({
      footerContent: !this.state.footerContent,
      sectionContent: false,
      headerContent: false,
      themeFontsContent: false,
    }, () => {
      if (this.state.footerContent) {
        this.updateExpandedSection('footer');
      } else {
        this.updateExpandedSection('blank');
      }

    });
    this.updateSectionState('footer');
    this.props.handleCloseAllSections()
  };

  toggleHeaderContent = () => {
    this.setState({
      headerContent: !this.state.headerContent,
      sectionContent: false,
      footerContent: false,
      themeFontsContent: false,
    }, () => {
      if (this.state.headerContent) {
        this.updateExpandedSection('header');
      } else {
        this.updateExpandedSection('blank');

      }

    });
    this.updateSectionState('header');
    this.props.handleCloseAllSections()
  }

  handleAPIMessageShowContentUpdateState = (responseJson:any,metadata:any)=>{
    this.setState({
      showProductPreview: {
        themes_and_font: responseJson.data.attributes?.themes_and_font.data?.attributes,
        footer: responseJson.data.attributes?.footer.data?.attributes,
        sections: responseJson.data.attributes?.sections,
        header: responseJson.data.attributes?.header?.data?.attributes,
        metadata: metadata && Object.keys(metadata).length !== 0 ? JSON.parse(metadata) : {},
      },
      sectionDataN: responseJson.data?.attributes?.sections?.data,
    }, () => {
      this.props.createIdHandler(responseJson.data.id, this.state.showProductPreview)
      
    this.updateStateData()
    
    })
  }

  headerNavigationUpdateData=(HeaderNavigationUpdatedData:any, responseJson:any,metadata:any)=>{
    if (HeaderNavigationUpdatedData !== null && HeaderNavigationUpdatedData.headerNavigationData !== undefined) {
      this.setState({
        HeaderNavigation: HeaderNavigationUpdatedData.headerNavigationData
      });
    } else {
      this.setState({
        HeaderNavigation: this.state.HeaderNavigation
      });
    }
    this.handleAPIMessageShowContentUpdateState(responseJson,metadata)
  
    this.props.HandleCanvasDataGetAPIResponse(responseJson.data?.attributes)
    if (HeaderNavigationUpdatedData === null || HeaderNavigationUpdatedData.sectionUpdateData === undefined) {
      this.setState({
        sectionData: this.state.sectionData
      });
    } else {
      this.setState({
        sectionData: HeaderNavigationUpdatedData.sectionUpdateData
      });
    }
  }
  handleAPIMessageShowContent = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);

    if (this.getProductPreviewContentCallId === apiRequestCallId) {
      if (responseJson && responseJson.data) {
        this.props.hideLoader()
        const metadata = responseJson.data.attributes?.metadata;
        const HeaderNavigationUpdatedData = metadata && Object.keys(metadata).length !== 0 ? JSON.parse(metadata) : null;
        this.headerNavigationUpdateData(HeaderNavigationUpdatedData,responseJson,metadata)
       
      } else {
        this.props.hideLoader()

        this.updateFooterCopyright()
        console.error(responseJson, "Error")
      }
    }
  };
updateStateData(){
  const selectedPrimaryColor = this.state.showProductPreview?.themes_and_font?.primary_colour ?? "";
  const selectedSecondaryColor = this.state.showProductPreview?.themes_and_font?.secondary_colour ?? "";
  const isDefaultColor = this.state.defaultColors.find((color: any) => 
    color.primary === selectedPrimaryColor && color.secondary === selectedSecondaryColor);
  this.setState({
    selectedColor: { 
      primary: selectedPrimaryColor,
      secondary: selectedSecondaryColor,
      id: isDefaultColor ? isDefaultColor.id : 0,
    },
    selectedFonts: {
      headerText: this.state.showProductPreview?.themes_and_font?.header_text ?? "",
      bodyText: this.state.showProductPreview?.themes_and_font?.body_text ?? "",
    },
    selectedMobileFileName: this.state.showProductPreview?.sections?.data[0]?.attributes?.mobile_banner_filename,
    showContactInformation: this.state.showProductPreview?.footer?.is_contact,
    showStoreLinkInputBox: this.state.showProductPreview?.footer?.is_app_link,
    showSocialLinks: this.state.showProductPreview?.footer?.is_social_media,
    facebookUrl: this.state.showProductPreview?.footer?.facebook_url,
    instagramUrl: this.state.showProductPreview?.footer?.instagram_url,
    twitterUrl: this.state.showProductPreview?.footer?.x_url,
    youTubeUrl: this.state.showProductPreview?.footer?.youtube_url,
    linkedinUrl: this.state.showProductPreview?.footer?.linkedin_url,
    playStoreUrl: this.state.showProductPreview?.footer?.playstore_url,
    appStoreUrl: this.state.showProductPreview?.footer?.appstore_url,
    copyRight: this.state.showProductPreview?.footer?.copyright,
    contactTempInformation: this.state.showProductPreview?.footer?.is_contact,
    appTempStore: this.state.showProductPreview?.footer?.is_app_link,
    socialTempMedia: this.state.showProductPreview?.footer?.is_social_media,
    facebookTempUrl: this.state.showProductPreview?.footer?.facebook_url,
    instagramTempUrl: this.state.showProductPreview?.footer?.instagram_url,
    twitterTempUrl: this.state.showProductPreview?.footer?.x_url,
    youTubeTempUrl: this.state.showProductPreview?.footer?.youtube_url,
    linkedinTempUrl: this.state.showProductPreview?.footer?.linkedin_url,
    playStoreTempUrl: this.state.showProductPreview?.footer?.playstore_url,
    appStoreTempUrl: this.state.showProductPreview?.footer?.appstore_url,
    copyTempRight: this.state.showProductPreview?.footer?.copyright,
    isMobileMenuOpen: this.state.showProductPreview?.header?.is_mobile_menu,
    showStoreName: this.state.showProductPreview?.header?.show_business_name,
    isMobileMenuOpenChecker: this.state.showProductPreview?.header?.is_mobile_menu,
    showStoreNameChecker: this.state.showProductPreview?.header?.show_business_name,
    allSelectValue: this.state.showProductPreview?.metadata?.selectedValue,
    isLogoUploaded: this.state.showProductPreview?.header?.logo_url?.length ? true : false,
    isFaviconUploaded: this.state.showProductPreview?.header?.favicon_url?.length ? true : false,
    selectedLogo: this.state.showProductPreview?.header?.logo_url,
    selectedFavicon: this.state.showProductPreview?.header?.favicon_url,
    SelectedLogoName: this.state.showProductPreview?.header?.logo_filename === undefined ? "" : this.state.showProductPreview?.header?.logo_filename,
    SelectedFaviconName: this.state.showProductPreview?.header?.favicon_filename === undefined ? "" : this.state.showProductPreview?.header?.favicon_filename,
  },()=>{
  }
  )

}
  resetAllErrors = () => {
    this.setState({
      checkPlayStoreStatus: false,
      checkInstagramStatus: false,
      checkTwitterStatus: false,
      checkYouTubeStatus: false,
      checkLinkedinStatus: false,
      checkAppStoreStatus: false,
      checkStatus: false,
      isPlayError: false,
      isAppError: false,
      isYoutubeError: false,
      isFacebookError: false,
      isInstagramError: false,
      isTwitterError: false,
      isLinkedinError: false,
    })
  };

  toggleSectionContent = () => {
    this.setState({
      sectionContent: !this.state.sectionContent,
      footerContent: false,
      headerContent: false,
      themeFontsContent: false,
    }, () => {
      if (this.state.sectionContent) {
        this.updateExpandedSection('section');
      } else {
        this.updateExpandedSection('blank');
      }

    });
    this.updateSectionState('section');
    this.props.handleCloseAllSections()
  };

  handleAPIMessageCreateContent = async (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);
    if (this.postCreateWebsiteBuilderCallId === apiRequestCallId) {
      if (responseJson) {
        this.props.createIdHandler(responseJson.data.id, responseJson)
        this.props.apiCalledHandler()
        this.props.handleGetAPITrigger()
        await setStorageData("id", responseJson.data.id);
        this.getProductPreview()
        this.props.HandleCanvasDataGetAPIResponse(responseJson.data.attributes)
        this.resetAllErrors()
      }
      this.props.hideLoader()
    }
  };

  handleAPIMessageUpdateContent = async (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);

    if (this.putWebsiteBuilderCallId === apiRequestCallId) {
      if (responseJson && responseJson.data) {
        this.props.createIdHandler(responseJson.data.id, responseJson);
        this.props.apiCalledHandler()
        this.props.handleGetAPITrigger()
        await setStorageData("id", responseJson.data.id);
        this.getProductPreview()
        this.resetAllErrors()
        this.props.HandleCanvasDataGetAPIResponse(responseJson.data.attributes)
      }
      this.props.hideLoader()
    }
  };

  getSectionDataId = (section: string | boolean | ArrayBuffer | null) => {
    let test: string = ""
    this.state.sectionDataN.filter((item) => {
      return item.attributes.section_type === section;
    }).forEach((item, index) => {
      test = item.id;
    });
    return test
  }

  handleImageSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    const searchValue = event.target.value;

    this.debounceTimeout = setTimeout(() => {
      this.setState({ isLoading: true })
      this.getImageCatalogueAPI = this.apiCallHandle(this.state.token, `${configJSON.getImageCatalougeAPIEndpoint}?per_page=999&search=${searchValue}`, configJSON.getApiMethod)
    }, 300);
  };

  handleImageCatalogResponse = (responseJson: ApiResponse) => {
    const newImages: ModalImage[] = responseJson.data.map((image) => ({
      type: image.type,
      name: image.attributes.name,
      url: image.attributes.url,
      isSelect: false
    }));

    this.setState({
      modalImages: newImages,
      isLoading: false
    });
  }

  checkLogoType = () => {
    const { uploadModalType, SelectedLogoName, SelectedFaviconName } = this.state
    if (uploadModalType === "logo") {
      if (SelectedLogoName && SelectedLogoName.trim() !== "") {
        return "Edit Logo";
      } else {
        return "Add Logo";
      }
    } else if (uploadModalType === "favicon") {
      if (SelectedFaviconName && SelectedFaviconName.trim() !== "") {
        return "Edit Favicon";
      } else {
        return "Add Favicon";
      }
    }
  }

  createWebsiteBuilderContent = async () => {
    this.props.showLoader();
    const headerNavigationData = this.state.HeaderNavigation;
    let formData = new FormData();
    const requestMessage = await generateRequestMessage(
      configJSON.createContentWebsiteBuilderEndPoint,
      configJSON.postAPiMethod,
      { "content-type": undefined }
    );

    if (this.props.selectedTab)
      formData.append("designs[design_platform]", this.props.selectedTab);
    formData.append("designs[footer_attributes][is_social_media]", this.state.showSocialLinks.toString());
    formData.append("designs[footer_attributes][facebook_url]", this.state.facebookUrl);
    formData.append("designs[footer_attributes][youtube_url]", this.state.youTubeUrl);
    formData.append("designs[footer_attributes][is_contact]", this.state.showContactInformation.toString());
    formData.append("designs[footer_attributes][is_app_link]", this.state.showStoreLinkInputBox.toString());
    formData.append("designs[footer_attributes][copyright]", this.state.copyRight);
    formData.append("designs[footer_attributes][playstore_url]", this.state.playStoreUrl);
    formData.append("designs[footer_attributes][appstore_url]", this.state.appStoreUrl);
    formData.append("designs[footer_attributes][linkedin_url]", this.state.linkedinUrl);
    formData.append("designs[footer_attributes][x_url]", this.state.twitterUrl);
    formData.append("designs[footer_attributes][instagram_url]", this.state.instagramUrl);
   

   this.checkValidInputForForfaviconAndIconForUpdate(formData)
    formData.append("designs[header_attributes][show_business_name]", this.state.showStoreName?.toString());
    formData.append("designs[header_attributes][is_mobile_menu]", this.state.isMobileMenuOpen?.toString());
    formData.append("designs[header_attributes][logo_filename]", this.state.SelectedLogoName === null ? "" : this.state.SelectedLogoName.toString());
    formData.append("designs[header_attributes][favicon_filename]", this.state.SelectedFaviconName === null ? "" : this.state.SelectedFaviconName.toString());
 
    if (headerNavigationData) {
      const metadata: any = {
        headerNavigationData,
      };
      formData.append("designs[metadata]", JSON.stringify(metadata));
    }

   this.manageformDataForUpdateData(formData)

    this.postCreateWebsiteBuilderCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getProductPreview = async () => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.apiContentType
    };

    const requestMessage = await generateRequestMessage(
      `${configJSON.createContentWebsiteBuilderEndPoint}`,
      configJSON.getApiMethod,
      headers
    );
    this.getProductPreviewContentCallId = requestMessage.messageId;
    this.send(requestMessage);
  };

  apiCallHandle = (token: token, endPoint: string, method: string, body?: object) => {
    const header = { token: token };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), header);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), body)

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
updateFormData = (formData:any)=>{
  
}
  updateWebsiteBuilderContent = async () => {
    let id = "1"
    this.props.showLoader();
    let formData = new FormData();
    const headerNavigationData = this.state.HeaderNavigation;
    const requestMessage = await generateRequestMessage(
      `${configJSON.createContentWebsiteBuilderEndPoint}/${id}`,
      configJSON.putAPiMethod,
      { "content-type": undefined }
    );
    if (this.props.selectedTab)
      formData.append("designs[design_platform]", this.props.selectedTab);
    if (id){
      formData.append("designs[footer_attributes][id]", id)
      formData.append("designs[themes_and_font_attributes][primary_colour]", this.state.selectedColor.primary);
      formData.append("designs[themes_and_font_attributes][secondary_colour]", this.state.selectedColor.secondary);
      formData.append("designs[themes_and_font_attributes][header_text]", this.state.selectedFonts.headerText);
      formData.append("designs[themes_and_font_attributes][body_text]", this.state.selectedFonts.bodyText);
    formData.append("designs[footer_attributes][is_social_media]", this.state.showSocialLinks.toString());
    formData.append("designs[footer_attributes][facebook_url]", this.state.facebookUrl);
    formData.append("designs[footer_attributes][youtube_url]", this.state.youTubeUrl);
    formData.append("designs[footer_attributes][is_contact]", this.state.showContactInformation.toString());
    formData.append("designs[footer_attributes][is_app_link]", this.state.showStoreLinkInputBox.toString());
    formData.append("designs[footer_attributes][copyright]", this.state.copyRight);
    formData.append("designs[footer_attributes][playstore_url]", this.state.playStoreUrl);
    formData.append("designs[footer_attributes][appstore_url]", this.state.appStoreUrl);
    formData.append("designs[footer_attributes][linkedin_url]", this.state.linkedinUrl);
    formData.append("designs[footer_attributes][x_url]", this.state.twitterUrl);
    formData.append("designs[footer_attributes][instagram_url]", this.state.instagramUrl);
    
this.checkValidInputForForfaviconAndIconForUpdate(formData);
   
    formData.append("designs[header_attributes][show_business_name]", this.state.showStoreName?.toString());
    formData.append("designs[header_attributes][is_mobile_menu]", this.state.isMobileMenuOpen?.toString());
    formData.append("designs[header_attributes][logo_filename]", this.state.SelectedLogoName === null ? "" : this.state.SelectedLogoName.toString());
    formData.append("designs[header_attributes][favicon_filename]", this.state.SelectedFaviconName === null ? "" : this.state.SelectedFaviconName.toString());
    }
    if (headerNavigationData) {
      const metadata: any = {
        headerNavigationData,
      };
      formData.append("designs[metadata]", JSON.stringify(metadata));
    }
    this.manageformDataForUpdateData(formData);
   

    this.putWebsiteBuilderCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  checkValidInputForForfaviconAndIconForUpdate=(formData:any)=>{
    const isValidInput = (input: string | null | undefined): boolean => {
      return input !== undefined && input !== null && (input.startsWith('data:image'));
    };
    if (isValidInput(this.state.selectedLogo)) {
      formData.append("designs[header_attributes][logo]", this.state.selectedLogo.toString());
    }

    if (isValidInput(this.state.selectedFavicon)) {
      formData.append("designs[header_attributes][favicon]", this.state.selectedFavicon.toString());
    }
  }
manageBannerPart=(item:any,index:any,formData:any)=>{
  if (item.mobileBannerUpload && !item.mobileBannerUpload.startsWith('https://')) {
    formData.append(`designs[sections_attributes][${index}][mobile_banner]`, item.mobileBannerUpload);
    formData.append(`designs[sections_attributes][${index}][mobile_banner_filename]`, item.mobileBannerName ?? '');
  }

  if (item.desktopBannerUpload && !item.desktopBannerUpload.startsWith('https://')) {
    formData.append(`designs[sections_attributes][${index}][desktop_banner]`, item.desktopBannerUpload);
    formData.append(`designs[sections_attributes][${index}][desktop_banner_filename]`, item.desktopBannerName ?? '');
  }
  if (item.mobileBannerMetadata ) {
    formData.append(`designs[sections_attributes][${index}][mobile_banner_metadata]`,this.isJson(item.mobileBannerMetadata)? item.mobileBannerMetadata:JSON.stringify(item.mobileBannerMetadata));
  }
  if (item.desktopBannerMetadata ) {
    formData.append(`designs[sections_attributes][${index}][desktop_banner_metadata]`,this.isJson(item.desktopBannerMetadata)? item.desktopBannerMetadata:JSON.stringify(item.desktopBannerMetadata));
  }
  formData.append(`designs[sections_attributes][${index}][banner_url]`, item.bannerUrl ?? '');
}
manageCategoryPart=(item:any,index:any,formData:any)=>{
  if (item.sectionName) {
    formData.append(`designs[sections_attributes][${index}][section_name]`, item.sectionName);
  }
  if (item.metadata) {
    item.metadata.isOpen = false;
    formData.append(`designs[sections_attributes][${index}][metadata]`, JSON.stringify(item.metadata));
  }
}
  manageformDataForUpdateData = (formData:any)=>{
    this.props.parentStates.SectionData.forEach((item, index) => {
      const sectionType = item.type?.toLowerCase();
      if (sectionType) {
        formData.append(`designs[sections_attributes][${index}][section_type]`, sectionType);
        formData.append(`designs[sections_attributes][${index}][is_deleted]`, item.is_deleted.toString());
        if (item.metadata && item.metadata.sectionIdToSend) {
          formData.append(`designs[sections_attributes][${index}][id]`, item.metadata.sectionIdToSend as string);
        }
        switch (sectionType) {
          case 'banner':
           this.manageBannerPart(item,index,formData)
            break;
          case 'product':
          case 'category':
           this.manageCategoryPart(item,index,formData)
            break;
          default:
            break;
        }
      }
    });
  }

  updateSectionState = (selectionName: string) => {
    this.props.updateSelectedSection(selectionName);
  }

  updateExpandedSection = (selectionName: string) => {
    this.props.updateExpandedSection(selectionName);
  }

  updateFooterCopyright = () => {
    if (this.state.copyRight == "" && this.props.parentStates.storeName != "") {
      let copyrithText = `Copyright @ ${new Date().getFullYear()} ${this.props.parentStates?.storeName}. All rights reserved`
      this.setState({ copyRight: copyrithText })
    }
  }

  generateRandomString(length:number) {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const randomValues = new Uint8Array(length);
    window.crypto.getRandomValues(randomValues);
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(randomValues[i] % characters.length);
    }
    return result;
}
toggleThemeFontsContent = () => {
  this.setState({
    themeFontsContent: !this.state.themeFontsContent,
    headerContent: false,
    sectionContent: false,
    footerContent: false,
  }, () => {
    if (this.state.themeFontsContent) {
      this.updateExpandedSection('themeFonts');
    } else {
      this.updateExpandedSection('blank');
    }
  });
  this.updateSectionState('themeFonts');
  this.props.handleCloseAllSections()
}

toggleColorSelectionOpen = () => {
  this.setState({
    isColorSelectionOpen: !this.state.isColorSelectionOpen
  })
}

toggleFontSelectionOpen = () => {
  this.setState({
    isFontSelectionOpen: !this.state.isFontSelectionOpen
  })
}

getDefaultColors = async () => {
  this.getDefaultColorsAPI = this.apiCallHandle(this.state.token, configJSON.defaultColorsApiEndPoint, configJSON.getApiMethod)
}

handleDefaultColorsResponse = (responseJson: any) => {
  if (responseJson?.data) {
    let defaultColors: any = []
    responseJson.data.map((item: any) => {
      defaultColors.push({
        primary: item.attributes.primary_colour,
        secondary: item.attributes.secondary_colour,
        id: item.id
      })
    })
    this.setState({
      defaultColors
    })
  }
}
callBackforColorSelection = (primary:string, secondary:string)=>{
  this.openToastChange()
  this.props.handleThemeColor(primary,secondary)
}
handleColorSelection = (color: { primary: string, secondary: string, id: number }) => {
  this.setState({
    selectedColor: color
  }, ()=>{this.callBackforColorSelection(color.primary,color.secondary)})
}
handlePrimaryColorChange = (color: string) => {
  this.setState({
    selectedColor: {
      ...this.state.selectedColor,
      primary: color
    }
  }, ()=>{this.callBackforColorSelection(color, this.state.selectedColor.secondary)})
}

handleSecondaryColorChange = (color: string) => {
  this.setState({
    selectedColor: {
      ...this.state.selectedColor,
      secondary: color
    }
  }, ()=>{this.callBackforColorSelection( this.state.selectedColor.primary, color)})
}

handleCustomColorSelection = () => {
  if (!this.state.selectedColor.id) {
    return;
  }
  this.setState({
    selectedColor: { primary: "", secondary: "", id: 0 }
  }, this.openToastChange)
}

handleHeaderTextFontChange = (font: string) => {
  this.setState({
    selectedFonts: {
      ...this.state.selectedFonts,
      headerText: font
    }
  }, this.openToastChange)
  this.props.handleFont(font,"header")
}

handleBodyTextFontChange = (font: string) => {
  this.setState({
    selectedFonts: {
      ...this.state.selectedFonts,
      bodyText: font
    }
  }, this.openToastChange)
  this.props.handleFont(font,"body")

}
  

  isJson = (str: string) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  // Customizable Area End
}

export default ContactInformationController; 