Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";

exports.contentTypeApplicationJson = "application/json";
exports.getStaticPagesEndPoint = "bx_block_static_pages/static_pages/static_pages_without_token";
exports.getMethod = "GET";
exports.designApiEndPoint = `bx_block_websitebuilder/designs`

exports.filterApiEndPoints = "bx_block_catalogue/catalogues/search_product?"
exports.getAllItemUrl = "bx_block_landingpage2/categories_and_catalogues"
exports.getCurrencyEndPoints = "bx_block_store_details/fetch_currency_symbol"

exports.apiEndPointsContactUs = "bx_block_static_pages/static_pages/mobile_drawer_api";
exports.mixPannelapiEndPoint = "bx_block_mixpanel_integration/mixpanel_integrations/track_event"

exports.textHome = "Home";
exports.textHelpCenter = "Help Center";
exports.textBack = "Back";
exports.textAboutUs = "About us";
exports.textFAQs = "FAQs";
exports.textPrivacyPolicy = "Privacy Policy";
exports.textTOS = "Terms of Service";
exports.searchText = "Search";
exports.searchSubText = "Explore using either menu item names or menu categories."
exports.noSearchHeading = "We could not find that in our menu";
exports.noSearchSubHeading = "Please check for typos or use more general terms. You can also try our menu categories to find what you are looking for.";
exports.storeCloseMsgTitle="We are currently closed for online orders";
exports.storeCloseMsg="Due to some unforeseen reasons, we are closed. We will be back tomorrow on our regular time."
// Customizable Area End