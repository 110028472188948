Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.emailSettingsEndpoint = "/bx_block_email_template/email_settings";
exports.defaultEmailSettingsEndpoint =  "/bx_block_email_template/default_email_settings"
exports.routeEmailTemplates = "/website-and-emails/email-templates";
exports.routeEmailCreate = "/website-and-emails/email-templates/create";

exports.textActive = "Active";
exports.textInactive = "Inactive";
exports.textHeaderEmailTemplates = "Email templates";
exports.textBack = "Back";
exports.textQuickEmailPreview = "Quick email preview";
exports.textPreviewEmail = "Preview Email";
exports.textEdit = "Edit";
exports.textTitle = "Title *";
exports.textEmailSubject = "Email Subject";
exports.textContent = "Content *";
exports.textBuilderAiAddress =
  "Builder AI, 3rd Floor, 5 Merchant Square, Paddington, London, W2 6LG";
exports.textUnsubscribe = "Unsubscribe";
exports.textEmailPreferences = "Email preferences"
exports.textDesktop = "Desktop";
exports.textMobile = "Mobile";
exports.textTablet = "Tablet";

exports.textThisFieldIsRequired = "This field is required.";
exports.textErrorOnPageInitialization = "Error on page initialization";
exports.textErrorOnSaveChanges = "Error on save changes";
exports.textErrorOnLoadingDefaultSettings = "Error on loading default settings";


exports.httpGetMethod = "GET";
exports.httpPatchMethod = "PATCH";
exports.httpPostMethod = "POST";

exports.deviceTypeDesktop = "Desktop";
exports.deviceTypeMobile = "Mobile";
exports.deviceTypeTablet = "tablet";
exports.routeEmailLogin = "EmailAccountLogin";

exports.infoText = "Do not edit content marked in purple. For example [Customer Name]";


exports.orderDetailsEmail = `<div class="orderContainer">
                            <div class="headerWrapper">
                              <div class="detailWrapper detailWidth-75">
                                <div class="detailContainer">
                                  <p class="detailLabel">Order Date</p>
                                  <p class="detailValue">19/01/2024, 11:37</p>
                                </div>

                                <div class="detailContainer detailWidth-25">
                                  <p class="detailLabel detailLabelMobile">Order #</p>
                                  <p class="detailLabel detailLabelDesktop">Order number</p>
                                  <p class="detailValue">OD00000107</p>
                                </div>
                              </div>

                              <div class="detailWrapper detailWidth-75">
                                <div class="detailContainer">
                                  <p class="detailLabel">Order Type</p>
                                  <p class="detailValue">Delivery</p>
                                </div>
                                <div class="detailContainer detailWidth-25">
                                  <p class="detailLabel">Customer</p>
                                  <p class="detailValue">Jason Wills</p>
                                </div>
                              
                              </div>
                            </div>


                            <div class="addressWrapper DesktopWrapper">
                            <h5 class="addressTitle detailLabel">Delivery Address</h5>
                            <div class="addressDetailContainer ContentDetailContainer">
                              <p>John Smith</p>
                              <p>Apartment 66</p>
                              <p>London</p>
                              <p>United Kingdom</p>
                              <p>SW11 3AA</p>
                              <p>07111111111</p>
                            </div>
                            </div>

                            <div class="itemsWrapper DesktopWrapper">
                            <h5 class="itemsTitle detailLabel">Items</h5>
                            <div class="itemsDetailContainer ContentDetailContainer">
                              <div class="tableHeaderWrapper">
                                <div class="itemRow tableTitle detailLabel">Item</div>
                                <div class="qualityRow tableTitle detailLabel">Quantity</div>
                                <div class="priceRow tableTitle detailLabel">Price</div>
                              </div>

                              <div class="tableBodyWrapper">
                                <div class="itemRow tableBodyText">
                                  <p>Veg peprico (M)</p>
                                  <p class="margintop-7">Tomato, Capsicum</p>
                                </div>
                                <div class="qualityRow tableBodyText">1</div>
                                <div class="priceRow tableBodyText">$34</div>
                              </div>

                              <div class="tableBodyWrapper">
                                <div class="itemRow tableBodyText">
                                  <p>Cheese Paradise</p>
                                  <p class="margintop-7">Extra cheese</p>
                                </div>
                                <div class="qualityRow tableBodyText">1</div>
                                <div class="priceRow tableBodyText ">$26</div>
                              </div>

                              <div class="tableBodyWrapper">
                                <div class="itemRow tableBodyText">
                                  <p>Cheese dip (M)</p>
                                </div>
                                <div class="qualityRow tableBodyText">2</div>
                                <div class="priceRow tableBodyText">$6</div>
                              </div>

                              <div class="tableBodyWrapper tableTotalWraper">
                                <div class="itemRow tableTotalText">Total :</div>
                                <div class="qualityRow tableTotalText">4</div>
                                <div class="priceRow tableTotalText">$64</div>
                              </div>

                            </div>
                            </div>

                            <div class="paymentWrapper">
                                <div class="DesktopPaymentTitle">
                                  <h5 class="paymentTitle">Payment Method</h5>
                                  <h5 class="paymentSubTitle">Cash On Delivery</h5>
                                </div>

                              <div class="paymentDetailContainer">
                              
                                <div class="paymentDetail">
                                  <div class="paymentLabel paymentLabelHeader">Sub Total</div>
                                  <div class="paymentValue paymentLabelHeader">$64.00</div>
                                </div>

                                <div class="paymentDetail paddingTop-8">
                                  <div class="paymentLabel coupenWrapper"><div class="coupenContainer">SOMELONGTESTCODE</div><i>Applied</i></div>
                                  <div class="paymentValue">-$2</div>
                                </div>

                                <div class="paymentDetail paddingTop-8">
                                  <div class="paymentLabel DesktopPaymentdetail">Taxes</div>
                                  <div class="paymentValue">$1</div>
                                </div>

                                <div class="paymentDetail paddingTop-8">
                                  <div class="paymentLabel DesktopPaymentdetail">Delivery charges</div>
                                  <div class="paymentValue">$1</div>
                                </div>

                                <div class="paymentDetail margintop-12">
                                  <div class="paymentTotalLabel">Total</div>
                                  <div class="paymentTotalLabel">$64.00</div>
                                </div>
                              
                              </div>
                            </div>
                            </div>  `
// Customizable Area End
