// Customizable Area Start
import React from "react";
import OrderListingFilterModalController, { configJSON } from "./OrderListingFilterModalController.web";
import { Box, Checkbox, IconButton, Typography, withStyles } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { checkboxCheck, checkboxUncheck } from "./assets";
import moment from "moment";


// Customizable Area Start
const Accordion = withStyles({
    root: {
      boxShadow: 'none',
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(MuiAccordion);
  
  const AccordionSummary = withStyles({
    root: {
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      height:40,
      minHeight: 40,
      padding: '0px ',
      '&$expanded': {
        height:40,
        minHeight: 40,
      },
    },
    content: {
      '&$expanded': {
        margin: '0px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);
  
  const AccordionDetails = withStyles((theme) => ({
    root: {
        padding:'0px',
        marginTop: '20px',
    },
  }))(MuiAccordionDetails);

  
// Customizable Area End



export class OrderListingFliterModal extends OrderListingFilterModalController {
// Customizable Area Start
// Customizable Area End
render() {
// Customizable Area Start
    const { classes } = this.props;

return (
    <Dialog
        onClose={this.props.hideModal}
        aria-labelledby="customized-dialog-title"
        open={true}
        fullWidth
        classes={{ paper: classes.paperProps }}
    >
        {/* dialog header */}
        <Box padding='24px' width='100%' display='flex' justifyContent='space-between' alignItems='center'>
            {/* title */}
            <span className={classes.dialogHeaderText}>Filter</span>
            {/* close icon */}
            <IconButton aria-label="delete" style={webStyles.closeIcon} onClick={this.props.hideModal}>
                <CloseIcon  />
            </IconButton>
        </Box>
        {/* dialog content */}
        <Box className={classes.middleContainer}>
        <form onSubmit={(e) => this.handleFormSubmit(e)} onReset={(e) => this.resetFilter(e)} id="myform">
            <Box width='100%'>
                {/* 1 */}
                <Accordion square defaultExpanded >
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandLessIcon />}>
                        <span className={classes.accordianHeaderTitle}>Order Date</span>
                    </AccordionSummary>
                    <AccordionDetails>
                       <Box width='100%' display='flex' marginBottom='30px' justifyContent='space-between'>
                            {/* From */}
                            <Box width='50%' display='flex' flexDirection='column' position='relative' className={classes.viewDateLeft}>
                                <span className={classes.dateFiledLabel}>{`${configJSON.from} *`}</span>
                                <button
                                    className={classes.dateShowButton}
                                    onClick={(e) => this.handleOpenStartDate(e)}
                                    data-test-id="startDateShow"
                                >
                                    {this.state.start_date ? moment(this.state.start_date, 'yyyy-MM-DD').format('DD-MM-yyyy') : 'dd-mm-yyyy'}
                                </button>
                                <input 
                                    type="date" 
                                    name="start_date"
                                    placeholder="dd/mm/yyyy"
                                    data-test-id="start_date"
                                    value={this.state.start_date ? this.state.start_date : ""} 
                                    max={moment().format('yyyy-MM-DD')}
                                    onChange={(e) => { this.setState({ start_date:e.target.value }) }}
                                    required={this.state.end_date ? true : false}
                                    ref={this.startDateRef}
                                    className={classes.dateField}
                                />
                            </Box>
                            {/* To */}
                            <Box width='50%' display='flex' flexDirection='column' position='relative'className={classes.viewDateRight}>
                                <span className={classes.dateFiledLabel}>To *</span>
                                <button 
                                    className={classes.dateShowButton}
                                    onClick={(e)=>this.handleOpenEndDate(e)}
                                    data-test-id="endDateShow"
                                >
                                    {this.state.end_date ? moment(this.state.end_date, 'yyyy-MM-DD').format('DD-MM-yyyy') : 'dd-mm-yyyy'}
                                </button>  
                                <input
                                    type="date"
                                    className={classes.dateField}
                                    name="end_date"
                                    data-test-id="end_date"
                                    value={this.state.end_date} 
                                    max={moment().format('yyyy-MM-DD')}
                                    min={this.state.start_date}
                                    onChange={(e) => { this.setState({ end_date:e.target.value }) }}
                                    ref={this.endDateRef}
                                    required={this.state.start_date ? true : false}
                                />
                            </Box>
                       </Box>
                    </AccordionDetails>
                </Accordion>

                {/* 2 */}
                <Accordion square>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={<ExpandLessIcon />}>
                        <Typography className={classes.accordianHeaderTitle}>Order Status</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box display='flex' width='100%' flexDirection='column'>
                        {
                            this.state.orderStatusList.map((order, index) => {
                                return (
                                    <Box display='flex' width='100%' alignItems="center" key={index}>
                                        <Checkbox
                                            data-test-id={`checkbox_status${index}`}
                                            color="default"
                                            icon={<img src={checkboxUncheck} alt="uncheck" />}
                                            checkedIcon={<img src={checkboxCheck} alt="uncheck" />}
                                            onChange={(e) => this.handleOrderStatus(e, order.key)}
                                            checked={Boolean(this.state.Orderfilterpayload.includes(order.key))}
                                        />
                                        <Typography className={classes.value}>{order.value}</Typography>
                                    </Box>
                                )
                            })
                        }
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </form>
        </Box>

        {/* dialog footer */}
        <Box width='100%' height='40px' display='flex' justifyContent='flex-end'>
            <button className={classes.resetBtn} form="myform" type="reset">{configJSON.reset}</button>
            <button className={classes.applyBtn} form="myform" type="submit" data-test-id="submitBtn">{configJSON.apply}</button>
        </Box>
    </Dialog>
)
// Customizable Area End
}

}

// Customizable Area Start
const webStyles = {
    closeIcon: {
        width:'35',
        height:'35px',
        transform:'translateX(10px)'
    },
}

export const useStyles : Record<string, CSSProperties> = {
    dialogHeaderText: {
        fontFamily: 'Inter',
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '26px',
        color: "#3C3E49"
    },
    closeIcon: {
        cursor: 'pointer'
    },
    accordianHeaderTitle: {
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '18px',
        color: "#3C3E49"
    },
    dateFiledLabel: {
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '20px',
        marginBottom:'4px',
        color: "#000000"
    },
    value: {
        letterSpacing: "0em",
        fontFamily: 'Inter',
        fontSize: '14px',
        lineHeight: '22px',
        color: "#334155"
    },
    viewDateLeft: {
        paddingRight: "12px",
        "@media (max-width: 480px)": {
            paddingRight: "3px",
        }
    },
    viewDateRight: {
        paddingLeft: "12px",
        "@media (max-width: 480px)": {
            paddingLeft: "3px",
        }
    },
    dateShowButton:{
        width: '100%',
        height: '48px',
        borderRadius: '3px',
        border: '1px solid #D0D2DA',
        fontFamily: 'Rubik',
        display:'flex',
        paddingLeft: "16px",
        alignItems:'center',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '24px',
        background:'none',
        color:'#3C3E49',
        cursor:'pointer',
        zIndex:100,
        "@media (max-width: 480px)": {
            width: '100%',
            height:'48px',
            zIndex:1,
            bottom:'0px',
        }
    },
    dateField: {
        width: '100%',
        position:'absolute',
        bottom:'7px',
        left:'0px',
        opacity:'0',
        "@media (max-width: 480px)": {
            width: '100%',
            height:'48px',
            zIndex:100,
            bottom:'0px',
        }
    },
    paperProps:{
        borderRadius: '3px',
        width: '309px',
        maxHeight: '80vh',
        border: '0px solid #D0D2DA',
        display:'flex',
        overflow:'hidden',
        "@media (max-width: 480px)": {
            width:'95%'
        }
    },
    applyBtn:{
        width: '101px',
        height: '40px',
        borderRadius: '3px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:'#00D659',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '18px',
        color:'#3C3E49',
        border:'none'
    },
    resetBtn:{
        color:'#3C3E49',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '18px',
        width:'auto',
        background:'none',
        border:'none',
        marginRight:'23px',
        "@media (max-width: 480px)": {
            marginRight:'20px',
        }
    },
    middleContainer:{
        padding: '0px 25px',
        height: '50%',
        overflowY:'auto'
    }

};
// Customizable Area End


export default withStyles(useStyles)(OrderListingFliterModal);
// Customizable Area End
