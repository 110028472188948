import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { EmailSettingsMap, Tab } from "./Types";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";
import { onMaximizeType } from "../../ss-cms-common-components/src/Editor-v4/Editor";
import { getResponseVariables } from "../../ss-cms-common-components/src/GetResponseVariables/GetResponseVariables";
import {
  generateRequestMessage,
  showError,
  isTokenExpired,
  clearStorageData
} from "../../ss-cms-common-components/src/Utilities/Utilities";

const configJSON = require("./config.js");

export type Props = DialogProps &
  withLoaderProps &
  withHeadeActionBarProps & {
    navigation: {
      navigate: (to: string, params: object) => void;
      getParam: (param: string) => string;
      goBack: () => void;
    };
    id: string;
    // Customizable Area Start
    classes:Record<string, string>
    // Customizable Area End
  };

export interface DefaultEmailSettingsType {
  [key: string]: string;
}

interface S {
  // Customizable Area Start
  redirected: boolean;
  selectedTab: number;
  headerTabs: Tab[];
  content: string;
  contentPlain?: string;
  title: string;
  titleInit: string;
  event_name: string;
  defaultEmailSettings: DefaultEmailSettingsType;
  editorMaximized: boolean;
  titleError: boolean;
  contentError: boolean;
  emailSubject: string;
  emailSubjectError: boolean;
  emailContentShow : string;
  editerRefresh: boolean;
  discardContent : string;
  discardTitle : string
  logoImage: string
  storeName : string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start

// Customizable Area End

export default class EmailTemplatesCreateController extends BlockComponent<
  Props,
  S,
  SS
> {
  defaultMessageId = "";
  detailMessageId: string = "";
  settingsMessageId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ActionMessageFromToaster),
    ];

    this.state = {
      redirected: false,
      selectedTab: 0,
      headerTabs: [],
      content: '',
      contentPlain: "",
      titleError: false,
      contentError: false,
      title: "",
      titleInit: "",
      event_name: "",
      defaultEmailSettings: {},
      editorMaximized: false,
      emailSubject: "",
      emailSubjectError: false,
      emailContentShow:'',
      editerRefresh: false,
      discardContent: '',
      discardTitle : '',
      logoImage: '',
      storeName: ''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getEmailDetails();
    // Customizable Area End
  }

  // Customizable Area Start
  goToEmailTemplates = () => {
    const NavigateToAdminEmailTem = new Message(getName(MessageEnum.NavigationMessage));
    NavigateToAdminEmailTem.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "AdminEmailTemplates"
    );
    NavigateToAdminEmailTem.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(NavigateToAdminEmailTem);
    this.setState({ redirected: true })
  };

  getDefaultEmailSettings = async () => {
    const message = await generateRequestMessage(
      configJSON.defaultEmailSettingsEndpoint,
      configJSON.httpGetMethod
    );
    this.defaultMessageId = message.messageId;
    this.send(message);
  };

  getCurrentId = () => this.props.navigation.getParam("id") || "";

  getEmailDetails = async () => {
    this.props.showLoader();
    const detailMessage = await generateRequestMessage(
      `${configJSON.emailSettingsEndpoint}/${this.getCurrentId()}`,
      configJSON.httpGetMethod
    );

    this.detailMessageId = detailMessage.messageId;
    this.send(detailMessage);
  };

  handleDeviceResolutionOriginal = () => {
    let {selectedTab} = this.state
    let selectedTabs: string;
    if (selectedTab === 0) {
      selectedTabs = configJSON.deviceTypeMobile;
    } else {
      selectedTabs = configJSON.deviceTypeDesktop;
    }

    return selectedTabs; 
  };

  handleDeviceResolution = () => {
    return configJSON.deviceTypeDesktop;
  };

  saveChanges = async () => {
    const requestMessage = await generateRequestMessage(
      `${configJSON.emailSettingsEndpoint}/${this.getCurrentId()}`,
      configJSON.httpPatchMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        edit_title: this.state.title,
        content: this.state.content,
        subject: this.state.emailSubject,
      })
    );

    this.settingsMessageId = requestMessage.messageId;
    this.props.showLoader();
    this.send(requestMessage);
  };

  receive = (from: string, message: Message) => {
    if (isTokenExpired(message)) {
      return this.logoutAndNavigateLogin();
    }
    if (message.id === getName(MessageEnum.ActionMessageFromToaster)) {
      const type = message.getData(
        getName(MessageEnum.ActionMessageFromToasterMessage)
      );
      if (type === "SAVECHANGES") {
        if (
          !this.state.contentPlain ||
          this.state.contentPlain === "\n" ||
          !this.state.title
        ) {
          const titleError = !this.state.title;
          const contentError =
            !this.state.contentPlain || this.state.contentPlain === "\n";
          return this.setState({
            titleError,
            contentError,
          });
        }
        this.saveChanges();
      }
      if (type === "DISCARDCHANGES") {
        this.setState({editerRefresh:true,contentError:false,titleError:false, content:this.state.discardContent, title:this.state.discardTitle})
      }
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.messageSettingsCall(message);
      this.messageDetailCall(message);
      this.messageDefaultCall(message);
    }
  };

  logoutAndNavigateLogin = () => {
    clearStorageData();
    const navigateToEmailLogin = new Message(getName(MessageEnum.NavigationMessage));
    navigateToEmailLogin.addData(
      getName(MessageEnum.NavigationTargetMessage),
      configJSON.routeEmailLogin
    );
    navigateToEmailLogin.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(navigateToEmailLogin.messageId, navigateToEmailLogin);
  };

  messageSettingsCall = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);

    if (apiRequestCallId === this.settingsMessageId) {
      if (responseJson.errors) {
        return showError(
          configJSON.textErrorOnSaveChanges,
          responseJson,
          this.props.hideLoader,
          this.props.setDialogState
        );
      }
      this.props.hideLoader();
      this.props.showHeaderBar({ type: "success" });
      this.goToEmailTemplates();
    }
  };

  messageDetailCall = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);

    if (apiRequestCallId === this.detailMessageId) {
      if (responseJson.errors) {
        return showError(
          configJSON.textErrorOnPageInitialization,
          responseJson,
          this.props.hideLoader,
          this.props.setDialogState
        );
      }
      this.setState({
        discardContent: responseJson.data.attributes.content, 
        discardTitle: responseJson.data.attributes.edit_title,
        content: responseJson.data.attributes.content,
        emailContentShow : this.highlightTextWithBrackets(this.orderContentAdd(responseJson.data.attributes.content)),
        contentPlain: responseJson.data.attributes.content,
        titleInit: responseJson.data.attributes.title,
        title: responseJson.data.attributes.edit_title,
        event_name: responseJson.data.attributes.event_name,
        emailSubject: responseJson.data.attributes.subject,
        logoImage : responseJson.meta?.logo_url,
        storeName : responseJson.meta?.store_name
      });
      this.props.hideLoader();
    }
  };

  messageDefaultCall = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);

    if (apiRequestCallId === this.defaultMessageId) {
      if (responseJson.errors) {
        return showError(
          configJSON.textErrorOnLoadingDefaultSettings,
          responseJson,
          this.props.hideLoader,
          this.props.setDialogState
        );
      }
      this.setState({
        defaultEmailSettings: responseJson?.data?.[0]?.attributes || {},
      });
    }
  };

  async componentWillUnmount() {
    super.componentWillUnmount();
  }

  handleTabChange = (event: React.ChangeEvent<{}>, selectedTab: number) => {
    this.setState({ selectedTab });
  };

  handleTitleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    this.setState({ title: event.target.value });
    this.setState({ titleError: false });
    this.props.showHeaderBar({ message: "" });
  };

  handleEmailSubjectChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    this.setState({ emailSubject: event.target.value, emailSubjectError: false });
    this.props.showHeaderBar({ message: "" });
  };

  handleBackButtonClick = () => {
    this.goToEmailTemplates();
  };

  handleEditorChange = (content: string, contentPlain: string) => {
    if(this.state.contentPlain === contentPlain){return}
    let changeContent = this.orderContentAdd(content)
    let highlightdtext = this.highlightTextWithBrackets(changeContent)
    if(this.state.editerRefresh){
      this.props.hideHeaderBar();  
    }else{
     this.props.showHeaderBar({ message: "" });
     }
    this.setState({ content, emailContentShow: highlightdtext,contentPlain:contentPlain.trim(), contentError: false,editerRefresh:false});
     
  };

  orderContentAdd = (content:string) => {
    return content.replace(/\[Order Details\]/g,configJSON.orderDetailsEmail)
  }

  highlightTextWithBrackets = (text:string)=> {
    var pattern = /\[([^\]]+)\]/g;
    var highlightedText = text.replace(pattern, '<span style="color:#400099;font-weight:600">[$1]</span>');
    return highlightedText;
}
  // Customizable Area End
}
