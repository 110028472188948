// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { createRequest } from "../../../../packages/blocks/adminpaymentintegration/src/Helpers";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import {
    OutputFormat,
    geocode,
    RequestType,
} from "react-geocode";
import { DeliveryAddressType } from "./CartOrdersController.web";
import { getStorageData } from "framework/src/Utilities";

const baseURL = require("../../../framework/src/config.js").baseURL;
export const configJSON = require("./config");

export type Props = {
    id: string;
    isDialogOpen: boolean
    onCloseDialog: (address?: DeliveryAddressType) => void
    classes?: Record<string, string>
    countryCode: string
    address?: DeliveryAddressType
}

export interface AreaType {
    id: string
    attributes: { name: string }
}

interface S {
    location: {
        latitude?: number;
        longitude?: number;
    }
    house: string;
    isHouseFocused: boolean;
    houseError: string;
    building: string
    buildingError: string;
    isBuildingFocused: boolean;
    landmark: string
    address: string
    scrollDisabled: boolean
    addressError: boolean
    areaList: Array<AreaType>
    openState: boolean
    areaState: AreaType | null
    inputValue: string
    areaError: boolean
}

interface SS {
}

class DialogAddressController extends BlockComponent<Props, S, SS> {
    getAreaZoneId: string = ""

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.ActionMessageFromToaster)
        ];
    
        this.state = {
            house: this.props.address?.flat_no ?? "",
            isHouseFocused: false,
            houseError: "",

            building: this.props.address?.address_line_2 ?? "",
            isBuildingFocused: false,
            buildingError: "",

            landmark: this.props.address?.landmark ?? "",
            location: {},
            address: this.props.address?.address ?? "",
            addressError: false,
            scrollDisabled: false,

            areaList: [],
            openState: false,
            areaState: {
                id: "0",
                attributes: {
                    name: this.props.address?.area || ""
                }
            },
            inputValue: this.props.address?.area || "",
            areaError: false
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    handleChangeState = (_event: React.ChangeEvent<{}>, value: AreaType | null) => {
        this.setState({ areaState: value })
    };

    handleStateOpen = () => {
        this.setState({ openState: true });
    };

    handleStateClose = () => {
        this.setState({ openState: false });
    };

    handleStateInverse = () => {
        this.setState({ openState: !this.state.openState });
    };

    onHandleProceed() {
        if (this.state.address.trim() === "") {
            this.setState({ addressError: true })
        }
        if (this.state.areaState?.attributes?.name === "" && this.props.countryCode === "ae") {
            this.setState({ areaError: true })
        }
        if (this.state.house.trim() === "") {
            this.setState({ houseError: configJSON.thisFieldNotEmpty, isHouseFocused: true })
        }
        if (this.state.building.trim() === "") {
            this.setState({ buildingError: configJSON.thisFieldNotEmpty, isBuildingFocused: true })
        }
        if (this.state.house.trim() === "" || this.state.building.trim() === "" || this.state.address.trim() === "") {
            return
        }
        if (this.state.areaState?.attributes?.name === "" && this.props.countryCode === "ae") {
            return
        }
        this.setState({
            houseError: "", isHouseFocused: false,
            buildingError: "", isBuildingFocused: false,
            addressError: false,
            areaError: false
        })
        this.props.onCloseDialog({
            address: this.state.address,
            flat_no: this.state.house,
            address_line_2: this.state.building,
            landmark: this.state.landmark,
            area: this.state.areaState?.attributes?.name ?? ""
        })
    }

    closeDialog() {
        this.props.onCloseDialog()
    }

    onChangeLocation = (latitude: number, longitude: number) => {
        this.setState({ location: { latitude, longitude } })
    }

    onFocusHouse() {
        this.setState({ isHouseFocused: true })
    }

    onBlurHouse() {
        if (this.state.house.length === 0) {
            this.setState({ houseError: configJSON.thisFieldNotEmpty })
        } else {
            this.setState({ isHouseFocused: false, houseError: "" })
        }
    }

    onFocusBuilding() {
        this.setState({ isBuildingFocused: true })
    }

    onBlurBuilding() {
        if (this.state.building.length === 0) {
            this.setState({ buildingError: configJSON.thisFieldNotEmpty })
        } else {
            this.setState({ isBuildingFocused: false, buildingError: "" })
        }
    }

    async getCurrentLocation() {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(this.getSuccessPosition, this.getErrorPosition)
        }
    }

    getSuccessPosition = (position: { coords: { latitude: number, longitude: number } }) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        this.handleCurrentLocation(latitude, longitude)
    }

    getErrorPosition() {
        alert("Unable to retrieve your location");
    }

    handleCurrentLocation(latitude: number, longitude: number) {
        this.onChangeLocation(latitude, longitude)
        geocode(RequestType.LATLNG, `${latitude},${longitude}`, {
            location_type: "ROOFTOP",
            enable_address_descriptor: true,
            outputFormat: OutputFormat.JSON,
            key: configJSON.keyGoogle,
            region: this.props.countryCode,
            language: "en"
        })
            .then(({ results }) => {
                this.setState({ address: results[0].formatted_address })

            })
            .catch((e) => {
                this.setState({ address: "" })
            });
    }

    onHandleInputChange(inputValue: string) {
        this.setState({ inputValue, areaError:false });
    }

    async getAreaZone() {
        const token = await getStorageData("admintoken");
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.getAreaZoneId = request.messageId;
        const splitAddress = this.state.address.split("-")
        const currentCity = splitAddress[splitAddress.length - 2]
        createRequest({
            token,
            requestMsg: request,
            method: "GET",
            endPoint: `${baseURL}/${configJSON.areaSearch}?region=${currentCity.trim()}&search_term=${this.state.inputValue}`
        });
    }

    receive = (from: string, message: Message) => {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson && !responseJson.errors) {
                switch (apiRequestCallId) {
                    case this.getAreaZoneId:
                        this.setState({ areaList: responseJson.data})
                        break;
                }
            }
        }
    };

    async componentDidMount(): Promise<void> {
        this.getCurrentLocation()
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        if (this.state.address !== prevState.address && this.props.countryCode === "ae") {
            this.getAreaZone()
        }
    }
}

export default DialogAddressController;
// Customizable Area End
