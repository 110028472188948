// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true,
});

const configJSON = require("../../../framework/src/config");

exports.customiseApp = "Customise App";
exports.chooseColor = "Choose Primary Colour";
exports.appSettingsHint = "In this section you can provide primary app color and deep linking configuration.";

exports.readAppRequirementsApiEndpoint = "bx_block_mobile_app/app_submission_requirement";
exports.appSettingApiEndpoint = "bx_block_mobile_app/app_settings";
exports.getApiMethodType = "GET";
exports.putApiMethodType = "PUT";
exports.deleteApiMethodType = "DELETE";
exports.postApiMethodType = "POST";
exports.qrCodesApiEndpoint = "bx_block_mobile_app/qr_codes";
exports.saveChangesApiEndpoint = "bx_block_mobile_app/app_submission_requirement";
exports.getLocationsAPI = "bx_block_location_management/countries";
exports.getCityAPI = "bx_block_location_management/address_states";
exports.qrCodeApiEndpoint = "bx_block_mobile_app/qr_codes";
exports.getCountriesApiEndpoint = "bx_block_location_management/countries";

exports.thisFieldIsRequired = "This field is required";
exports.maximum_30Characters = "Maximum 30 characters";
exports.maximum_50Characters = "Maximum 50 characters";
exports.maximum_170Characters = "Maximum 170 characters";
exports.maximum_4000Characters = "Maximum 4000 characters";
exports.emailMustBeInValidFormat = "Email must be in valid format.";
exports.errorMessage = "Oops! It seems like the provided URL is not valid";
exports.appIconIsRequired = "App icon is required";
exports.validFieldUrl = "Must be a valid url";
exports.commonFeatureBannerIsRequired = "Common feature banner is required";
exports.defaultPriceHasToBeMoreThanZero =
  "Default price has to be more than zero";
  exports.zip_invalid = "Zip code is not valid";


exports.maximum_75Characters = "Maximum 75 characters";
exports.phone_10Characters = "Phone must be 10 characters";
exports.phone_11Characters = "Phone must be 11 characters";
exports.appName = "App name";
exports.st_india = "india";
exports.st_cap_india = "India";
exports.st_uk = 'uk';
exports.st_us = "united states";
exports.promotionalText = "Promotional Text";
exports.description = "Description";
exports.appIcon = "App Icon";
exports.commonFeatureBanner = "Common feature banner";
exports.website = "Website";
exports.email = "Email";
exports.phone = "Phone";
exports.firstName = "First Name";
exports.lastName = "Last Name";
exports.country = "Country";
exports.state = "State";
exports.city = "City";
exports.cityState = "City / State";
exports.postcodeZipPin = "Postcode / ZIP / Pin";
exports.addressLine_1 = "Address line 1";
exports.addressLine_2 = "Address line 2";
exports.privacyPolicyUrl = "Privacy policy url";
exports.supportUrl = "Support url";
exports.marketingUrl = "Marketing url";
exports.termsAndConditionsUrl = "Terms and conditions url";
exports.targetAudienceAndContent = "Target audience and content";
exports.distributedCountries = "Distributed countries";
exports.autoPriceConversion = "Auto price conversion";
exports.androidWear = "Android wear";
exports.googlePlayForEducation = "Google play for education";
exports.usExportLaws = "US export laws";
exports.copyright = "Copyright";
exports.appType = "App Type";
exports.featureGraphic = "Feature Graphic";
exports.productTitle = "Product Title";
exports.appCategory = "App Category";
exports.reviewUsername = "Review Username";
exports.reviewPassword = "Review Password";
exports.reviewNotes = "Review Notes";
exports.screenshots = "Screenshots";
exports.defaultPrice = "Default Price";
exports.tags = "Tags";
exports.deleteAppType = "Delete App Type";
exports.remove = "Remove";

exports.enterTheCountryPlaceholder = "Enter the country";
exports.enterTheStatePlaceholder = "Enter the state";
exports.enterTheCityPlaceholder = "Enter the city";
exports.enterTheCityStatePlaceholder = "Enter the city / state";
exports.enterThePostcodePlaceholder = "Enter the postcode";
exports.enterTheAddressDetailsPlaceholder = "Enter the address details";
exports.enterExtraAddressDetailsPlaceholder = "Enter extra address details";
exports.selectAudiencePlaceholder = "Select audience";
exports.mobileAppTitle = "Details";

exports.contactDetails = "Contact details";
exports.pageUrLs = "Page URLs";
exports.pricingAndDistribution = "Pricing and distribution";
exports.appCategory = "App category";

exports.label5AndUnder = "5 and under";
exports.label6_8 = "6-8";
exports.label9_12 = "9-12";
exports.label13_15 = "13-15";
exports.label16_17 = "16-17";
exports.label18AndOver = "18 and over";
exports.labelFreeApp = "Free app";
exports.labelPaidApp = "Paid app";
exports.labelAndroid = "Android";
exports.labelIOs = "iOS";

exports.addAppType = "Add app type";
exports.urlGetValidations = "urlGetValidations";
exports.mobileApp = "Mobile app";

exports.maximumTotalImageSizeCannotBeGreaterThan_50Mb =
  "Maximum total image size cannot be greater than 50MB";

exports.sampleDocuments = "Sample documents";
exports.sampleDocumentsHint =
  "Download these samples documents to help you fill the form";
exports.sampleDocumentsLink =
  "https://intercom.help/engineerai/en/articles/6465764-ios-sample-document";
exports.iOsSampleDocument = "iOS sample document";

exports.sampleDocumentsLink2 =
  "https://intercom.help/engineerai/en/articles/6465768-google-play-sample-document";
exports.googlePlaySampleDocument = "Google play sample document";

exports.headerTabs = "iOS sample document";
exports.mobileAppTab1Hint =
  "Here's where you add the info needed to submit your app to Apple’s App Store and Google's Play Store.";
exports.mobileAppTab2Hint =
  "Generate a QR code here - this lets customers download your store’s app.";

exports.appType = "App type";

exports.errorOnInitialization = "Error on initialization";
exports.errorOnSave = "Error on save";
exports.errorOnDelete = "Error on delete";
exports.qrCodeGenerateError = "Qr Code Generate Error";
exports.qrCodeEditError = "Qr Code Edit Error";
exports.somethingWentWrong = "Something went wrong!";
exports.downloadJson = "Download JSON Settings";
exports.webJson = "Web JSON";
exports.mobileJson = "Mobile JSON";
exports.webJsonLink = `${configJSON.baseURL}/bx_block_mobile_app/app_submission_requirement/web_json`;
exports.mobileJsonLink = `${configJSON.baseURL}/bx_block_mobile_app/app_submission_requirement/mobile_json`;
exports.headerTabNames = [
  { tab_name: "App Settings" },
  { tab_name: "QR Code Generator" },
  { tab_name: "App Requirement" }
];
exports.updatedAt = "Updated at";
exports.pleaseAdd = "Please Add Store URL to App Settings";
exports.createQrCodeFirst = "To create a QR code that directs users to your app in the App or Play Store,",
exports.createQrCodeScondLine = "please enter your store URL in the app settings",
exports.goToAppSettingsButton = "Go to app settings",
exports.generalSettings = "General Settings";
exports.appNameLabel = "Application Name";
exports.googlePlayStoreURLLabel = "Google Play Store Link";
exports.appleStoreURLLabel = "Apple App Store Link";
exports.textSomethingWentWrong = " Something went wrong";


exports.QrCodeCreateMsg = "QR code generated successfully";
exports.QrCodeUpdateMsg = "QR code updated successfully";
exports.QrCodeDeleteMsg = "QR code deleted successfully";


exports.qrCode = "QR code";
exports.download = "Download";
exports.createQrCode = "Create QR code";
exports.qr_btn_update = "Update";
exports.qr_btn_save = "Save";
exports.qr_radion1 = "android";
exports.qr_radion2 = "ios";

exports.qr_labelName = "code_type";

exports.qr_urlLabel = "Url";
exports.qr_urlName = "url";

exports.qr_delete_dialog_title = "Delete Qr Code";
exports.qr_delete_dialog_msg = "Are you sure to delete Qr code?";
exports.qr_delete_dialog_confirm = "Yes, delete"
// Customizable Area End
