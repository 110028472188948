Object.defineProperty(exports, '__esModule', {
    value: true
  });
  // Customizable Area Start
  exports.postAPiMethod = 'POST';
  exports.putAPiMethod = 'PUT';
  exports.getApiMethod = 'GET';
  exports.patchApiMethod = 'PATCH';
  exports.deleteApiMethod = 'DELETE';
  exports.endPoint = '/bx_block_images_catalogue/images';
  exports.postBulkDeleteApi = 'bx_block_images_catalogue/images/bulk_destroy';
  
  exports.gridView = 'Grid view';
  exports.listView = 'List view';
  exports.imageCatalogue = 'Image catalogue';
  exports.deleteImages = 'Delete images';
  exports.bulkUpload = 'Bulk upload';
  exports.addImage = 'Add image';
  exports.emptyPageTitle = 'You have no product images';
  exports.errorOnInitialization = 'Error on page initialization';
  exports.productImages = 'Images';
  exports.imageDetails = "Image details";
  exports.imageName = "Image name *";
  exports.banners = "Banner";
  exports.productImage = 'Product image';
  exports.errorMessage = 'Something went wrong';
  exports.errorOnDelete = 'Error on delete';
  exports.errorOnBulkUpload = 'Error on bulk upload';
  exports.emptyPageMessage =
    'Start bulk uploading your product images here so you can easily select images in ‘Products’';
  exports.emptyPageButtonLabel = 'Start bulk upload';
  exports.dialogTitle = 'Are you sure?';
  exports.dialogMessage = 'Are you sure you want to delete this product image';
  exports.dialogOkay = 'Yes, delete';
  exports.deleteModalMessage = 'Are you sure you want to delete this images.';
  exports.deleteModaConfirmColor = 'white';
  exports.deleteModalConfirmBackground = '#FF1744';
  exports.deleteModalConfirmHoverBackground = 'rgb(240, 25, 73)';
  exports.imageDetails = 'Image details';
  exports.image = 'Image *';
  exports.imageSizeValidation =
    'Maximum total image size cannot be greater than 50MB';
  exports.addImage = 'Add Image';
  exports.maxMb = 'Max 20MB';
  exports.remove = 'Remove';
  exports.back = 'Back';
  exports.bulkUploadMessage =
    '*You can add multiple images using this option. Total file size should be less than 50MB per upload.';
  exports.bulkUploadHint =
    'This image will be part of the product catalogue images';
  exports.imageCatalogueUrl = '/website-and-emails/image-catalogue/bulk-upload';
  exports.imageCatalogueUrl2 = '/website-and-emails/image-catalogue';
  exports.imageDescription = "This image will be part of the product catalogue images";
  exports.requiredField = "This field is required";
  // Customizable Area End