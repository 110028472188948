import React, { useEffect, useState, useRef } from "react";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { useRunEngine } from "../../utilities/src/hooks/useRunEngine";
import { useBlockHelpers } from "../../utilities/src/hooks/useBlockHelpers";
import { loadStripe, Stripe, StripeElements } from "@stripe/stripe-js";
import { config } from "./config.web";

import StripePaymentsView from "./StripePaymentsView.web";

// Customizable Area Start
export const baseURL = require("../../../framework/src/config.js").baseURL;
// Customizable Area End

export interface ViewProps {
  // Customizable Area Start
  testID: string;
  stripePromise: Promise<Stripe | null>;
  stripeClientSecret: string | undefined;
  errorString: string | undefined;
  setOrderNumber: (_: number) => void;
  orderNumber: number | undefined;
  actionResult: string | undefined;
  stripeInitialised: boolean;
  isInStripeCallback: boolean;
  onHandleSubmit: (
    stripe: Stripe | null,
    stripeElements: StripeElements | null
  ) => void;
  submitOrderNumberButtonViewProps: { value: string };
  submitPaymentButtonViewProps: { value: string };
  loadingViewProps: { value: string };
  orderIdViewProps: { value: string };
  stripeMessageViewProps: { successValue: string; errorValue: string };
  amount:string
  // Customizable Area End
}

export interface ControllerProps {
  navigation: any;
  id: string;
  // Customizable Area Start
  client_secret:string
  payment_intent_id:string
  customer_id:string
  orderId:string
  stripe_publishable_key:string
  amount:string
  paymentSuccessCallBack: () => void
  // Customizable Area End
}

const subscribedMessages = [
  // Customizable Area Start
  MessageEnum.RestAPIResponceMessage,
  MessageEnum.SessionResponseMessage,
  MessageEnum.NavigationPropsMessage
  // Customizable Area End
];

const StripePayments = ({ 
  // Customizable Area Start
  navigation, id, client_secret,payment_intent_id, customer_id,orderId,stripe_publishable_key,amount,paymentSuccessCallBack
  // Customizable Area End
}: ControllerProps) => {
  // Customizable Area Start
  const getPaymentMethodsCallId = useRef<string>("");
  // Customizable Area End

  // Customizable Area Start
  const errorString:string | undefined=undefined;
  const params = new URLSearchParams(window.location.search);
  const returnedPaymentIntentClientSecret = params.get(
    "payment_intent_client_secret"
  );
  const isInStripeCallback = Boolean(returnedPaymentIntentClientSecret);
  const [orderNumber, setOrderNumber] = useState<number | undefined>(undefined);
  const [stripePaymentIntentId, setStripePaymentIntentId] = useState<
    string | undefined
  >(undefined);
  const [stripeClientSecret, setStripeClientSecret] = useState<
    string | undefined
  >(undefined);
  const [stripeCustomerId, setStripeCustomerId] = useState<string | undefined>(
    undefined
  );
  const [stripeInitialised, setStripeInitialised] = useState<boolean>(false);
  const [stripeActionResultMessage, setStripeActionResultMessage] = useState<
    string | undefined
  >(undefined);
  const [stripeAmount, setStripeAmount] = useState<
  string 
>('');
  // Customizable Area End

  // Customizable Area Start
  const {
    sendBlockMessage,
    setReceiveCallback,
    subscribe,
    unsubscribeFromMessage,
  } = useRunEngine();

  const { extractNetworkResponse } = useBlockHelpers();

  const [stripePromise] = useState(loadStripe(stripe_publishable_key));

  const getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    sendBlockMessage(message);
  };
  // Customizable Area End

  const receive = (from: string, message: Message) => {
    // Customizable Area Start
   if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } =
        extractNetworkResponse(message);
      if (
        apiRequestCallId === getPaymentMethodsCallId.current ||
        from === "UNIT_TEST"
      ) {
        setStripeClientSecret(responseJson.data.attributes.client_secret);
        setStripePaymentIntentId(
          responseJson.data.attributes.payment_intent_id
        );
        setStripeCustomerId(responseJson.data.attributes.customer_id);
      }
    }

    // Customizable Area End
  };

  // Customizable Area Start
  const checkPaymentResult = async () => {
    const stripe = await stripePromise;
    if (!stripe || !returnedPaymentIntentClientSecret) {
      return;
    }
    const { paymentIntent, error } = await stripe.confirmCardPayment(
      returnedPaymentIntentClientSecret
    );
    if (error) {
      return;
    } else if (paymentIntent) {
      const userNotification = `STRIPE OUTCOME: ${paymentIntent.status}`;
      setStripeActionResultMessage(userNotification);
    }
  };
  // Customizable Area End

  useEffect(() => {
    setReceiveCallback(receive);
    subscribedMessages.forEach((message) => subscribe(message));
    // Customizable Area Start
    getToken();
    // Customizable Area End
    return () => {
      subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
    };
  }, []);

  // Customizable Area Start
  useEffect(() => {
    checkPaymentResult();
  }, [stripe_publishable_key]);

  useEffect(() => {
    if (stripeClientSecret && stripePaymentIntentId && stripeCustomerId) {
      setStripeInitialised(true);
    }
  }, [stripeClientSecret, stripePaymentIntentId, stripeCustomerId]);

  useEffect(() => {
    if (client_secret && payment_intent_id && customer_id && orderId) {
      setStripeClientSecret(client_secret);
      setStripePaymentIntentId(payment_intent_id)
      setStripeCustomerId(customer_id)
      setOrderNumber(Number(orderId))
      setStripeAmount(amount)
    }
  }, [client_secret,payment_intent_id, customer_id,orderId]);
  // Customizable Area End

  // Customizable Area Start
  const onHandleSubmit = async (
    stripe: Stripe | null,
    stripeElements: StripeElements | null
  ) => {
    if (!stripe || !stripeElements) {
      return;
    }

    let return_url=`${window.location.origin}/orderconfirmation`


    const result = await stripe.confirmPayment({
      elements: stripeElements,
      confirmParams: {
      },
     redirect: 'if_required'
    });
    if (result.error) {
      if(result.error.type !== "validation_error"){
      setStripeActionResultMessage(result.error.message);
      }
    }
    if(result && result.paymentIntent && result.paymentIntent.status== "succeeded"){
      paymentSuccessCallBack()
    }
  };

  // Customizable Area End

  // Customizable Area Start
  const orderIdViewProps = {
    value: config.orderId,
  };

  const submitOrderNumberButtonProps = {
    value: config.submitText,
  };

  const submitPaymentButtonProps = {
    value: config.payText,
  };

  const loadingViewProps = {
    value: config.loading,
  };

  const stripeMessageViewProps = {
    successValue: config.stripeSuccessMessage,
    errorValue: config.stripeErrorMessage,
  };
  // Customizable Area End

  const viewProps: ViewProps = {
    testID: id,
    // Customizable Area Start
    errorString,
    stripePromise: stripePromise,
    stripeClientSecret: stripeClientSecret,
    setOrderNumber: setOrderNumber,
    orderNumber: orderNumber,
    actionResult: stripeActionResultMessage,
    stripeInitialised: stripeInitialised,
    isInStripeCallback: isInStripeCallback,
    onHandleSubmit: onHandleSubmit,
    submitOrderNumberButtonViewProps: submitOrderNumberButtonProps,
    submitPaymentButtonViewProps: submitPaymentButtonProps,
    loadingViewProps: loadingViewProps,
    orderIdViewProps: orderIdViewProps,
    stripeMessageViewProps: stripeMessageViewProps,
    amount:stripeAmount
    // Customizable Area End
  };

  return <StripePaymentsView {...viewProps} />;
};

export default StripePayments;
