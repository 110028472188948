import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { CartItem } from "../../../../packages/blocks/shoppingcart/src/CartOrdersController.web";
import { Option } from "../../../../packages/blocks/shoppingcart/src/OrderConfirmationSummaryController.web";
import { difference, groupBy, isEqual } from "lodash";
import { AppMixpanel } from "../../../components/src/Mixpanel";
import { uuid } from "uuidv4"
import { event } from "react-native-reanimated";
const images = require("./assets")


export type MetadataBanner = {
  text_alignment: string
  header_text: string
  text_position: string
  font_colour: string
  font_size: string
}

export type SectionDataAttribute = {
  type: string;
  section_type: string;
  section_name: string;
  banner_url: string;
  mobile_banner_url: string;
  desktop_banner_url: string,
  mobileBannerUpload: string;
  desktopBannerUpload: string;
  mobile_banner_metadata: string
  desktop_banner_metadata: string
  is_deleted: boolean
}

export type SectionType = {
  attributes : SectionDataAttribute
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  classes: any
  // Customizable Area End
}

export interface ItemMenuFilter {
  name: string
  value: string
  id: number
  img: string
}

export interface CategoryItem {
  id: string
  attributes: {
    id: number
    name: string
  }
}

interface S {
  // Customizable Area Start
  categories: Array<CategoryItem>;
  // selectItem:number;
  categoryId: number;
  categorieItems: Array<CartItem>;
  value: any;
  isReadMoreShown: boolean,
  isSearch: boolean,
  search: string,
  searchData: any,
  isOrderSuccess: boolean,
  lastScrollY: number,
  isShow: boolean,
  isDetail: boolean,
  selectedProduct: any
  showTag: number
  showCatergoriesNumber: number,
  socialMedias: any,
  staticPages: any,
  openDrawer: boolean,
  filterModal: boolean,
  foodLabelDropdown: boolean,
  dietaryItems: any,
  allergenItems: any,
  spicyItems: any,
  foodLabelData: any,
  expandedLables: string | boolean;
  selectedFoodLables: any;
  appliedFoodLables: any,
  filterDrawer: boolean,
  noSearch: boolean,
  orderedItem: any[],
  tempStore: number,
  checkDiscount: boolean,
  radioDiscount: boolean,
  addToCardButton: boolean,
  varianstName: string[],
  isStoreClosed:boolean,
  storeClosedMessage:string
  validationOptions:number[],
  isAddAction:boolean
  footerEmail: string,
  footerCountryCode: string,
  footerPhoneNumber: string,
  whatsappNumber: string,
  whatsappMessage: string,
  token:any,
  canvasData:any,
  storename:string,
  isIntersectionScrollPaused:boolean;
  isCategoryGapVisible:boolean;
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getLandingPageDataByIdApiCall: string = "";
  getFilterItemId: string = ""
  filterApiCallId: string = "";
  getAllItemId: string = "";
  getSearchApiCallId: string = "";
  currencyType: string = "";
  getCurrencyTypeApiCallId: string = "";
  getStaticPagesMessageId: string = "";
  contactUsApiCallId : string = "";
  getCanvasDataCallId:string="";
  mixPanelLandingPageCallId:string="";
  scrollPauseTimeout: NodeJS.Timeout | null; 
  categoryItemsObserver: IntersectionObserver | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.setCurrencyType()
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      categories: [],
      categoryId: 0,
      categorieItems: [],
      value: 0,
      search: '',
      searchData: [],
      isReadMoreShown: false,
      isSearch: false,
      isOrderSuccess: false,
      lastScrollY: 200,
      isShow: true,
      isDetail: false,
      selectedProduct: undefined,
      showTag: 6,
      showCatergoriesNumber: 4,
      socialMedias: {
        facebook: { url: "https://www.facebook.com/login/" },
        instagram: { url: "https://www.instagram.com/accounts/login/" },
        twitter: { url: "https://twitter.com/i/flow/login?lang=en" },
        youtube: { url: "https://www.youtube.com/premium" }
      },
      staticPages: [],
      openDrawer: false,
      filterModal: false,
      foodLabelDropdown: true,
      dietaryItems: [
        { id: 1, img: images.vegIcon, name: "Vegetarian", value: "Vegetarian", color: "#DDFFCF" },
        { id: 2, img: images.nonVegIcon, name: "Non-Vegetarian", value: "Non-Vegetarian", color: "#FFA6A6" },
        { id: 3, img: images.vegan, name: "Vegan", value: "Vegan", color: "#C4E1BD" },
        { id: 4, img: images.glutenFree, name: "Gluten free", value: "Gluten free", color: "#EFE1B0" },
        { id: 5, img: images.halal, name: "Halal", value: "Halal", color: "#D4EDCA" },
        { id: 6, img: images.kosher, name: "Kosher", value: "Kosher", color: "#CAE2FF" },
      ],
      allergenItems: [
        { id: 1, img: images.milk, name: "Milk", value: "Milk", color: "#F2F2F2" },
        { id: 2, img: images.eggsFilter, name: "Eggs", value: "Eggs", color: "#FFFDD6" },
        { id: 3, img: images.nutsFilter, name: "Nuts", value: "Nuts", color: "#E1C9C1" },
        { id: 4, img: images.wheatFilter, name: "Wheat", value: "Wheat", color: "#E4D7A6" },
        { id: 5, img: images.soy, name: "Soy", value: "Soy", color: "#FCEDAA" },
        { id: 6, img: images.peanuts, name: "Peanuts", value: "Peanuts", color: "#F8D494" },
        { id: 7, img: images.celery, name: "Celery", value: "Celery", color: "#9ECF9B" },
        { id: 8, img: images.fishFilter, name: "Fish", value: "Fish", color: "#BAD7F1" },
        { id: 9, img: images.crustaceans, name: "Crustaceans", value: "Crustaceans", color: "#D2EAFF" },
        { id: 10, img: images.lupin, name: "Lupin", value: "Lupin", color: "#E7D6F4" },
        { id: 11, img: images.molusc, name: "Mollusc", value: "Mollusc", color: "#D2EAFF" },
        { id: 12, img: images.mustard, name: "Mustard", value: "Mustard", color: "#FFF5CA" },
        { id: 13, img: images.seasameSeeds, name: "Sesame Seeds", value: "Sesame Seeds", color: "#F5E5C1" },
        { id: 14, img: images.sulphurDiaoxide, name: "Sulphur Dioxide", value: "Sulphur Dioxide", color: "#E8F8FF" }
      ],
      spicyItems: [
        { id: 1, img: images.mild, name: "Mild", value: "Mild" },
        { id: 2, img: images.spicy, name: "Spicy", value: "Spicy" },
        { id: 3, img: images.extraHot, name: "Extra Hot", value: "Extra Hot" },
      ],
      foodLabelData: [],
      expandedLables: "" || false,
      selectedFoodLables: [],
      appliedFoodLables: [],
      filterDrawer: false,
      noSearch: false,
      orderedItem: [],
      tempStore: 0,
      checkDiscount: false,
      radioDiscount: false,
      addToCardButton: false,
      varianstName: [],
      isStoreClosed:false,
      storeClosedMessage:'',
      validationOptions:[],
      isAddAction:false,
      footerEmail: "",
      footerCountryCode: "",
      footerPhoneNumber: "",
      whatsappMessage: "",
      whatsappNumber: "",
      token:'',
      canvasData:'',
      storename:'',
      isIntersectionScrollPaused:false,
      isCategoryGapVisible:true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.scrollPauseTimeout = null; 
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && !responseJson.error) {
        this.responseSucessCall(apiRequestCallId, responseJson)
      } else if (responseJson && responseJson.error) {
        this.responseErrorsCell(apiRequestCallId, responseJson)
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    const token = await getStorageData("admintoken")
    this.setState({ token })
    this.getStaticPages();
    this.getCurrencyType();
    window.addEventListener("resize", this.handleTagNumber)
    this.handleTagNumber()
    this.setState({
      foodLabelData: [
        { id: 1, name: "Dietary", children: [...this.state.dietaryItems] },
        { id: 2, name: "Allergens", children: [...this.state.allergenItems] },
        { id: 3, name: "Spice", children: [...this.state.spicyItems] }
      ]
    })
    this.getCartItems()
    this.apiCallFnContactInfo()
    this.getCanvasData()
    this.getAllItem()
   

  }

  getCartItems = async () => {
    let data = JSON.parse(await getStorageData('count'));
    if(data) this.setState({ orderedItem: data })
  }

  handleTagNumber = () => {
    if (window.document.body.clientWidth < 700) {
      this.setState({ showTag: 2, showCatergoriesNumber: 3 })
    } else {
      this.setState({ showTag: 6, showCatergoriesNumber: 4 })
    }
  }

  handleClearAll = () => {
    this.setState({ selectedFoodLables: [] });
  };

  handleFoodLabelsFilterDelete = (foodLabel: any) => {
    let newSelected = [...this.state.appliedFoodLables];
    newSelected = newSelected.filter((food) => food != foodLabel)
    this.setState({ 
      selectedFoodLables: newSelected, 
      appliedFoodLables: newSelected,
      categorieItems:[],
      categories:[] 
    }, () => this.categoriesFilterData())
  }

  handleApplyFilter = () => {
    this.handleCloseFilter();
    this.setState({ 
      appliedFoodLables: this.state.selectedFoodLables, 
      filterDrawer: false,
      categorieItems:[],
      categories:[]
    }, () => this.categoriesFilterData())
  };

  onPressOrder() {
    setStorageData("count", JSON.stringify(this.state.orderedItem))
    const navigateCartOrders: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigateCartOrders.addData(getName(MessageEnum.NavigationTargetMessage), "CartOrders");
    navigateCartOrders.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateCartOrders);
  }

  handleAddToCart = async() => {
    if(this.state.selectedProduct && this.state.selectedProduct.attributes.variants.length>0){
      this.setState({validationOptions:[]},()=>{
        let inValidVariants = this.checkValidation();
        if(inValidVariants.length>0){
          this.setState({validationOptions:inValidVariants})
          return false;
        }
        else{
          this.setDataIntoStore();
        }
      })
    }
    else{
    this.setDataIntoStore();
    }
  }

  setDataIntoStore=()=>{
    setStorageData("count", JSON.stringify(this.state.orderedItem));
    setStorageData("variant", JSON.stringify(this.state.varianstName));
    this.setState({ isDetail: false, selectedProduct: undefined, tempStore: 0,validationOptions:[],isAddAction:false,addToCardButton: false })
  }

  checkValidation=()=>{
    let inValidVariants=[]
    if(this.state.selectedProduct){
    for(let variant of this.state.selectedProduct.attributes.variants){
      const currentChosenVariant =  (this.state.selectedProduct.variants || []).find((chosen:any) => chosen.name === variant.title);
      if(((currentChosenVariant && currentChosenVariant.option.length<variant.min && variant.min> 0)  || (currentChosenVariant && currentChosenVariant.option.length>variant.max && variant.max > 0))){
        inValidVariants.push(variant.id)
      }
      if(!currentChosenVariant && variant.min> 0){
        inValidVariants.push(variant.id)
      }
    }
  }
    return inValidVariants
  }

  onCloseSuccessOrder() {
    this.setState({ isOrderSuccess: false })
  }

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let header = {
      "Content-Type": contentType,
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  apiCallFnContactInfo = async () => {
    const loginToken = await getStorageData("userToken")

    const header = {
        "Content-Type": "application/json",
        token: loginToken,
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactUsApiCallId = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiEndPointsContactUs
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.contactUsApiCallId;
  };

  handleOpenMail = () => {
    const email  = this.state.footerEmail;
    const mailToUrl = `mailto:${email}`;
    const mailWindow = window.open(mailToUrl, '_blank');
    let checkClose: NodeJS.Timeout | undefined;
    if (mailWindow) {
        checkClose = setInterval(() => {
            if (mailWindow.closed) {
                clearInterval(checkClose as unknown as number);
            }
        }, 1000);
    }
  }

  handleWhatsappNavigation = () => {
    const phoneNumber = this.state.footerCountryCode + this.state.whatsappNumber;
    const message = this.state.whatsappMessage;
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappURL, '_blank');
  }

  handleCloseDrawer = () => {
    this.setState({
      openDrawer: false,
      search: ""
    })
  }

  handleOpenDrawer = () => {
    this.setState({
      openDrawer: true
    })
  }

  handleOpenFilter = () => {
    this.mixPanelEventHit("Applied filters")
    this.setState({
      filterModal: true,
      selectedFoodLables: this.state.appliedFoodLables
    })
  }

  handleFoodLableClose = () => {
    this.setState({
      foodLabelDropdown: false
    })
  }
  handleCloseFilter = () => {
    this.setState({
      filterModal: false,
      selectedFoodLables: this.state.appliedFoodLables
    })
  }

  handleFoodLabelOpen = () => {
    this.setState({
      foodLabelDropdown: true
    })
  }

  handleChildChange = (isChecked: boolean, parent: { id: string; name: string; children: Array<ItemMenuFilter> }, child: ItemMenuFilter) => {
    let newSelected = [...this.state.selectedFoodLables];
    if (!isChecked) {
      newSelected = [...newSelected, child.value];
    } else {
      newSelected = newSelected.filter((category) => category !== child.value);
      if (!parent.children.some((c: any) => newSelected.includes(c.value))) {
        newSelected = newSelected.filter((category) => category !== parent.name);
      }
    }
    this.setState({ selectedFoodLables: newSelected });
  };

  categoriesFilterData = () => {
    let apiEndPoint = `${configJSON.getAllItemUrl}?`
    if (this.state.appliedFoodLables.length > 0) {
      this.state.appliedFoodLables.map((foodLabel: any) => {
        apiEndPoint += "labels[]=" + foodLabel + "&"
      })
    }

    const header = {
      "Content-Type": configJSON.categoryApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFilterItemId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  categoreiDataSuccess = (responseJson: any) => {
    if (responseJson.meta && !responseJson.meta.online_ordering) {
      this.setState({ isStoreClosed: true, storeClosedMessage: responseJson.meta.online_store_message })
    }
    else {
      this.setState({ isStoreClosed: false })
    }
  }

  handleParentChange = (isChecked: boolean, parent: { id: string; name: string; children: Array<ItemMenuFilter> }) => {
    let newSelected = [...this.state.selectedFoodLables];
    if (!isChecked) {
      const arrayItemAdd: Array<string> = []
      parent.children.forEach((item: ItemMenuFilter) => {
        const indexItem = this.state.selectedFoodLables.findIndex((label: string) => label === item.value)
        if (indexItem < 0) {
          arrayItemAdd.push(item.value)
        }
      })
      newSelected = [...newSelected, ...arrayItemAdd]
    } else {
      const arrayRemoveItem = parent.children.map((item: ItemMenuFilter) => item.value)
      newSelected = difference(newSelected, arrayRemoveItem)
    }
    this.setState({ selectedFoodLables: newSelected });
  }

  handlePrev = () => {
    const indexId = this.state.categories.findIndex((category: CategoryItem) => category.attributes.id === this.state.categoryId)
    const prevValue = (indexId - 1 + this.state.categories.length) % this.state.categories.length;
    const categoryId = this.state.categories[Number(prevValue)].id;
    this.manualCategoryScroll(Number(categoryId))
  };

  handleNext = () => {
    const indexId = this.state.categories.findIndex((category: CategoryItem) => category.attributes.id === this.state.categoryId)
    const nextValue = (indexId + 1) % this.state.categories.length;
    const categoryId = this.state.categories[Number(nextValue)].id;
    this.manualCategoryScroll(Number(categoryId))
  };

  itemSelect = (item: CartItem,addAction:boolean) => {
    this.setState({
      selectedProduct: item,
      isDetail: true,
      openDrawer: false,
      search: "",
      isSearch: false,
      searchData: [],
      addToCardButton:true, //new change
      tempStore: item.attributes?.after_discount ?? item.attributes?.price ?? 0,
      isAddAction:addAction
    })
  }

  setCloseDetail = () => {
    if(this.state.selectedProduct && this.state.isAddAction && !this.state.isStoreClosed){
      let listItem = [...this.state.orderedItem];
      let addItem = listItem.map((el:CartItem) => el.id).lastIndexOf(this.state.selectedProduct.id)
      if (listItem[addItem]?.count !== 1) {
        listItem[addItem] = { ...listItem[addItem], count: listItem[addItem]?.count - 1 }
        this.setState({ orderedItem: listItem, isDetail: false });
      } else {
        listItem.splice(addItem, 1)
        this.setState({ orderedItem: listItem, isDetail: false });
      }
    }
    this.setState({ isDetail: false, selectedProduct: undefined, tempStore: 0,validationOptions:[],isAddAction:false,addToCardButton:false })
  }

  toggleBtn = () => {
    this.setState((prevState) => ({ isReadMoreShown: !prevState.isReadMoreShown }));
  };

  getCurrencyType = async () => {
    this.getCurrencyTypeApiCallId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: "bx_block_store_details/fetch_currency_symbol",
    })
  }

  setCurrencyType = async () => {
    this.currencyType = await getStorageData("currency_type") || "$"
  }

  searchItem = (text: any) => {
    this.setState({ search: text })
    if (text.length > 2 && text.trim()) {
      this.setState({ isSearch: true })
      this.searchApi(text)
      return
    }
    this.setState({ searchData: [], isSearch: false })
  }

  searchApi = async (text: any) => {
    this.getSearchApiCallId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_catalogue/catalogues/search_product?search=${text}`,
    })
  }

  searchDataSuccess = (responseJson: any) => {
    this.setState({ searchData: responseJson.data })
  }

  responseSucessCall = async (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getLandingPageDataByIdApiCall) {
      this.categoreiDataSuccess(responseJson)
    }
    if (apiRequestCallId === this.getFilterItemId) {
      if (responseJson.catalogues.length === 0) {
        this.setState({ categorieItems: [], noSearch: true})
      } else {
        this.sortItem(responseJson)
        this.setState({ noSearch: false })
      }
    }
    if (apiRequestCallId === this.getSearchApiCallId) {
      this.searchDataSuccess(responseJson)
    }
    if (apiRequestCallId === this.getCurrencyTypeApiCallId) {
      if (responseJson.currency_symbol) {
        setStorageData("currency_type", responseJson.currency_symbol);
      } else {
        setStorageData("currency_type", "$");
      }
    }
    if (apiRequestCallId === this.filterApiCallId) {
      this.categoreiDataSuccess(responseJson)
    }
    if (apiRequestCallId === this.getStaticPagesMessageId) {
      this.setState({
        staticPages: responseJson.data.filter(
          (staticPage: any) => staticPage.attributes.active
        ),
      })
    }
    if (apiRequestCallId === this.getAllItemId) {
      this.sortItem(responseJson)
    }
    if (apiRequestCallId === this.contactUsApiCallId) {
      this.setState({
        footerCountryCode: responseJson.store_profile.country.phone_code,
        footerPhoneNumber: responseJson.store_profile.phone_number,
        footerEmail: responseJson.store_profile.email,
        whatsappMessage: responseJson.store_profile.whatsapp_message,
        whatsappNumber: responseJson.store_profile.whatsapp_number,
        storename: responseJson.store_profile.store_name

      })
    }
    if(apiRequestCallId === this.getCanvasDataCallId){
      this.setState({canvasData:responseJson.data.attributes}, () => {
        this.observeCategoryGap();
      })
    }
  }

  sortItem(data: { categories: Array<CategoryItem>, catalogues: Array<CartItem>,online_ordering:boolean,online_store_message:string }) {
    let groupsCategoryName = groupBy(data.catalogues, 'attributes.category_name')
    const nameList: Array<{name: string; id: number}> = data.categories.map((cate: { attributes: { name: string; id: number } }) => ({ name: cate.attributes.name, id: cate.attributes.id }))
    let resultSort: Array<CartItem> = [];
    nameList.forEach(name => {
      if (groupsCategoryName[name.name]) {
        groupsCategoryName[name.name].forEach((group, index) => {
          const item = { ...group, idGroup: index === 0 ? name.id : 0 }
          resultSort.push(item);
        })
      }
    })
    if (!data.online_ordering) {
      this.setState({ isStoreClosed: true, storeClosedMessage: data.online_store_message })
    }
    else {
      this.setState({ isStoreClosed: false })
    }
    
    
    this.setState({ categorieItems: resultSort, categories: data.categories, categoryId: data.categories[0].attributes.id })
    this.categoryScroll(data.categories[0].attributes.id)
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(!isEqual(this.state.categorieItems, prevState.categorieItems)) this.createObserver()
  }

  createObserver = () => {
    if(this.categoryItemsObserver){
      this.categoryItemsObserver.disconnect();
    }
    this.categoryItemsObserver = new IntersectionObserver((entries: IntersectionObserverEntry[], observer?: IntersectionObserver) => {
      entries.forEach(entry => {
       if (entry.isIntersecting) {
        if(!this.state.isIntersectionScrollPaused){
          this.categoryScroll(Number(entry.target.id.split("_")[2]))
        }
       }
      });
    }, { threshold: 1, rootMargin: "5%" });
    this.state.categorieItems.forEach((element: CartItem) => {
      if(element.idGroup !== 0 ) {
        let target = document.querySelector(`#itemDetailsContainer_${element.attributes.id}_${element.idGroup}`);
        if(target && this.categoryItemsObserver) this.categoryItemsObserver.observe(target);
      }
    });
  }
  observeCategoryGap = () => {
    const observer = new IntersectionObserver((entries: IntersectionObserverEntry[], observer?: IntersectionObserver) => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          this.setState({ isCategoryGapVisible: true });
        } else {
          this.setState({ isCategoryGapVisible: false });
        }
      });
    }, { rootMargin: '0px', threshold: 0 });

    let target = document.querySelector(`.gap-category`);
    if(target) observer.observe(target);
  }

  responseErrorsCell = async (apiRequestCallId: any, responseJson: any) => {
    this.showAlert("Error", responseJson.error);
  }

  getAllItem = async () => {
    this.getAllItemId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: configJSON.getAllItemUrl,
    })
  }

  getStaticPages = async () => {
    this.getStaticPagesMessageId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: configJSON.getStaticPagesEndPoint,
    })
  }

  handleOpenAccordion = (name: string) => {
    if (this.state.expandedLables == name) {
      this.setState({ expandedLables: "" })
    } else {
      this.setState({ expandedLables: name })
    }
  }

  componentWillUnmount(): any {
    window.removeEventListener("resize", this.handleTagNumber)
  }

  handleIncrement = (event: any, id: number, categorieItem: CartItem) => {
    this.mixPanelEventHit("Add to cart")
    let listItem = [...this.state.orderedItem]
    if (categorieItem?.attributes?.variants?.length !== 0) {
      this.itemSelect(categorieItem,true)
      listItem.push({ ...categorieItem, count: 1, total: categorieItem.attributes.after_discount ?? categorieItem.attributes.price, variants: [] })
    }
    else {
      if (event && typeof event.stopPropagation === 'function') {
        event.stopPropagation();
      }
      const currentItemIndex = listItem.findIndex((item) => item.id === categorieItem.id)
      if (currentItemIndex >= 0) {
        listItem[currentItemIndex].count += 1
      } else {
        listItem.push({ ...categorieItem, count: 1, total: categorieItem.attributes.after_discount ?? categorieItem.attributes.price, variants: [] })
      }
      setStorageData("count", JSON.stringify(listItem));
    }
    this.setState({ orderedItem: listItem });
  };

  handleDecrement = (event: {stopPropagation:() => void}, index: number, id: number) => {
    let item:Array<CartItem> = [...this.state.orderedItem]
    let addItem = item.map((el:CartItem) => el.id).lastIndexOf(id)
    if (item[addItem] && item[addItem].count && item[addItem].count !== 1) {
      item[addItem] = { ...item[addItem], count: item[addItem].count - 1 }
      this.setState({ orderedItem: item, isDetail: false });
    } else {
      item.splice(addItem, 1)
      this.setState({ orderedItem: item, isDetail: false });
    }
    setStorageData("count", JSON.stringify(item));
    if (event && typeof event.stopPropagation === 'function') {
      event.stopPropagation();
    }
  };

  countItem = () => {
    let count = 0
    this.state.orderedItem.forEach(element => {
      count += element.count
    });
    return count
  }

  manualCategoryScroll = (id: number) => {
    if (this.scrollPauseTimeout) {
      clearTimeout(this.scrollPauseTimeout);
    }
    this.setState({
      isIntersectionScrollPaused:true
    }, () => {
      this.categoryScroll(id);
    })
  }

  categoryScroll(id: number) {
    this.mixPanelEventHit("Category carousel click")
    const currentHeadItem = this.state.categorieItems.find((item: CartItem) => item.idGroup === id);
    const prevSelectedId = this.state.categoryId;
    const element = document.getElementById(`showCategoriesId_${id}`)
    element?.scrollIntoView({ block: "nearest", inline: "center", behavior: "smooth", })
    this.setState({ categoryId: id }, () => {
      this.cardScroll(`itemDetailsContainer_${currentHeadItem?.attributes.id}_${id}`, id, prevSelectedId)
    })
  }

  scrollHorizontally = () => {
    const parentElement = document.querySelector(".category-scroll") as HTMLElement;
    const childElement = document.getElementById(`showCategoriesId_${this.state.categoryId}`) as HTMLElement;
    if (parentElement && childElement) {
      const containerRect = parentElement.getBoundingClientRect();
      const childRect = childElement.getBoundingClientRect();
      let scrolltolocation = childRect.left - containerRect.left + parentElement.scrollLeft;
      parentElement.scrollTo({
        left: scrolltolocation-parentElement.offsetWidth/3,
        behavior: 'smooth'
      });
    }
  }

  scrollToVerticalChild(parentElement:HTMLElement, childElement:HTMLElement) {
    if (parentElement && childElement) {
      const containerRect = parentElement.getBoundingClientRect();
      const childRect = childElement.getBoundingClientRect();
      const scrollY = childRect.top - containerRect.top + parentElement.scrollTop;
      parentElement.scrollTo({
        top: scrollY-parentElement.offsetHeight/10,
        behavior: 'smooth'
      });
      
      let gapCategoryElement = document.querySelector(`.gap-category`) as HTMLElement;
      window.scrollTo({
        top: gapCategoryElement.offsetTop,
        behavior: 'smooth'
      });
    }
  }
  cardScroll(id: string, categoryId:number, prevSelectedId:number) {
    const prevCategoryIndex = this.state.categories.findIndex((item) => Number(item.id) == prevSelectedId);
    const targetCategoryIndex = this.state.categories.findIndex((item) => Number(item.id) == categoryId);
    const stepsToScroll = Math.abs(prevCategoryIndex-targetCategoryIndex);
    const element = document.getElementById(id);
    const parentContainer = document.getElementById("landing-scroll");
    if(this.state.isIntersectionScrollPaused){
      if(parentContainer && element){
        this.scrollToVerticalChild(parentContainer, element)
      }
    }else{
      element?.scrollIntoView({ block: "center", inline: "nearest", behavior: "smooth", });
    }
    this.scrollHorizontally();
    
    if(this.state.isIntersectionScrollPaused){
      this.scrollPauseTimeout = setTimeout(() => {
        this.setState({
          isIntersectionScrollPaused:false
        })
      },500 + (150*stepsToScroll))
    }
  }

  variantSelect = (selectedProduct: CartItem, option: Option, isChecked: boolean, variantIndex: number, optionIndex: number, parentName: string) => {
    const { price, option_name } = option.data.attributes;
    let newOrderedItem = [...this.state.orderedItem]
    let currectProductIndex = this.state.orderedItem.length - 1
    if(!this.state.isStoreClosed && selectedProduct && selectedProduct.attributes && selectedProduct.attributes.availability &&  selectedProduct.attributes.availability !== "out_of_stock"){
    let currentVariantList = this.state.orderedItem[currectProductIndex].variants
    if (isChecked) {
      newOrderedItem[currectProductIndex].total = Number(Number(this.state.tempStore + price).toFixed(2))
      const currentParentIndex = currentVariantList.findIndex((item: {name: string, option: Array<string>}) => item.name === parentName)
      if (currentParentIndex < 0) {
        currentVariantList.push({ name: parentName, option: [option_name] })
      } else {
        currentVariantList[currentParentIndex].option.push(option_name)
      }
      newOrderedItem[currectProductIndex].variants = currentVariantList
      this.setState((prevState) => ({
        tempStore: Number(Number(prevState.tempStore + price).toFixed(2)),
        addToCardButton: true,
        orderedItem: newOrderedItem,
        selectedProduct:newOrderedItem[currectProductIndex]
      }));
    } else {
      newOrderedItem[currectProductIndex].total = Number(Number(this.state.tempStore - price).toFixed(2))
      const currentParentIndex = currentVariantList.findIndex((item: {name: string, option: Array<string>}) => item.name === parentName)
      const newOption = [...currentVariantList[currentParentIndex].option]
      currentVariantList[currentParentIndex].option = newOption.filter(o => o !== option_name)
      newOrderedItem[currectProductIndex].variants = currentVariantList
      this.setState((prevState) => ({
        tempStore: Number(Number(prevState.tempStore - price).toFixed(2)),
        orderedItem: newOrderedItem,
        selectedProduct:newOrderedItem[currectProductIndex]
      }));
    }
  }
  }

  variantRadio = (selectedProduct: CartItem, option: Option, isAdd: boolean, currentPrice: number, variantIndex: number, optionIndex: number, parentName: string) => {
    const { price, option_name } = option.data.attributes
    let newOrderedItem = [...this.state.orderedItem]
    let currectProductIndex = this.state.orderedItem.length - 1
    if(!this.state.isStoreClosed && selectedProduct && selectedProduct.attributes && selectedProduct.attributes.availability &&  selectedProduct.attributes.availability !== "out_of_stock"){
    let currentVariantList = this.state.orderedItem[currectProductIndex].variants
    if (isAdd) {
      newOrderedItem[currectProductIndex].total = Number(Number(this.state.tempStore - currentPrice + price).toFixed(2))
      const currentParentIndex = currentVariantList.findIndex((item: {name: string, option: Array<string>}) => item.name === parentName)
      if (currentParentIndex < 0) {
        currentVariantList.push({ name: parentName, option: [option_name] })
      } else {
        currentVariantList[currentParentIndex].option = [option_name]
      }
      newOrderedItem[currectProductIndex].variants = currentVariantList
      this.setState((prevState) => ({
        tempStore: Number(Number(this.state.tempStore - currentPrice + price).toFixed(2)),
        addToCardButton: true,
        checkDiscount: true,
        orderedItem: newOrderedItem,
        selectedProduct: newOrderedItem[currectProductIndex]
      }));
    } else {
      newOrderedItem[currectProductIndex].total = Number(Number(this.state.tempStore - currentPrice + price).toFixed(2))
      const currentParentIndex = currentVariantList.findIndex((item: {name: string, option: Array<string>}) => item.name === parentName)
      currentVariantList[currentParentIndex].option = []
      newOrderedItem[currectProductIndex].variants = currentVariantList
      this.setState((prevState) => ({
        tempStore: Number(Number(this.state.tempStore - price).toFixed(2)),
        orderedItem: newOrderedItem,
        selectedProduct: newOrderedItem[currectProductIndex]
      }))
    }
  }
  }
  showCount=(id:number)=>{
     const items=this.state.orderedItem.filter(ele=>ele.id==id)
     if(items && items.length>1){
      return items.length
     }
     else{
      const item=this.state.orderedItem.find(ele=>ele.id==id)
      return item.count
     }
  }
  openItemDetails=(categorieItem: CartItem)=>{
    this.setState({
      selectedProduct: categorieItem,
      isDetail: true,
      openDrawer: false,
      search: "",
      isSearch: false,
      searchData: [],
      tempStore: categorieItem.attributes?.after_discount ?? categorieItem.attributes?.price ?? 0,
      isAddAction:true
    })
    if(!this.state.isStoreClosed && categorieItem.attributes && categorieItem.attributes.availability &&  categorieItem.attributes.availability !== "out_of_stock"){
    this.setState({addToCardButton:true})
      this.handleIncrement( {}, categorieItem.id, categorieItem )
    }
  }

  getCanvasData = () => {
    let apiEndPoint = `bx_block_websitebuilder/designs`
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCanvasDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
  

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getBannerTextPosition = (section: MetadataBanner) => {
    let positions = ["center", "center"];
    const textPosition = section.text_position;
    if (textPosition === "Top") positions = ["center", "flex-start"];
    else if (textPosition === "Top Left") positions = ["flex-start", "flex-start"];
    else if (textPosition === "Top Right") positions = ["flex-end", "flex-start"];
    else if (textPosition === "Center") positions = ["center", "center"];
    else if (textPosition === "Mid Left") positions = ["flex-start", "center"];
    else if (textPosition === "Mid Right") positions = ["flex-end", "center"];
    else if (textPosition === "Bottom") positions = ["center", "flex-end"];
    else if (textPosition === "Bottom Right") positions = ["flex-end", "flex-end"];
    else if (textPosition === "Bottom Left") positions = ["flex-start", "flex-end"];
    
    return { justifyContent: positions[0], alignItems: positions[1] }
  }
 
  mixPanelEventHit = async (event_name:string) => {
    if(event_name != ""){
    AppMixpanel.track(event_name);
    }
  }

isFirefox = () => navigator?.userAgent?.toLowerCase()?.indexOf("firefox") > -1;

  getLandingScrollHeight = () => {
    return this.state.noSearch ? "0px" : "100px";
  }

  getScrollClassForMozillaLanding = () => {
    return this.isFirefox() && "mozilaVariantMOdalScrollbar";
  }

  getBottomSpacing = () => {
    return this.countItem() > 0 && this.props.classes.bottomActionSpace;
  }

  getStoreVisibility = () => {
    return this.state.isStoreClosed ? 'flex' : 'none'
  }
  getNoSearchVisibility = () => {
    return this.state.noSearch ? 'flex' : 'none';
  }
  getFooterVisibility = () => {
    return this.state.noSearch ? 'none' : 'block';
  }
  getDisablePlusAddVisibility = () => {
    return this.state.isStoreClosed ? 'block' : 'none'
  }
  getEnablePlusAddVisibility = () => {
    return this.state.isStoreClosed ? 'none' : 'block'
  }
  onAccordianChange = (newExanpanded:boolean, name:string) => {
    this.setState({ expandedLables: newExanpanded ? name : false })
  }
}

// Customizable Area End



