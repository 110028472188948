Object.defineProperty(exports, "__esModule", {
  value: true
});
// Customizable Area Start
exports.getPaymentMethod = `/bx_block_payment_admin/payment_option_methods`;
exports.getPaymentGateway = `/bx_block_payment/payment_providers`;
exports.getMethod = "GET";
exports.putMethod = "PUT";
exports.activationStatusLinkBase = "https://staging.cloud-marketplace.builder.ai/api/accounts/";
exports.storeDetailsAPiEndPoint ="/bx_block_store_details/public/store_profile";
exports.errorPageInitialisation = "Error on page initialization";
exports.errorCardUpdate = "Error on card update";
exports.errorOnSave = "Error on save";
exports.basic = "Basic ";
exports.errorActivation = "Error on Activation Status Check";
exports.payment = "Payment";
exports.notActive = "Not active";
exports.razorpayTitle = "Razorpay";
exports.stripeTitle = "Stripe";
exports.paymentTitle = "Payment";
exports.dummyPassword = "*************";
exports.useToGetMessage = "Use to get online payment seamlessly into your bank account";
exports.viewDashboardLink = "https://dashboard.razorpay.com/signin?screen=sign_in";
exports.consoleErrorBase = "Async: Could not copy text: ";
exports.paymentGateway = "Payment Gateway";
exports.paymentMethods = "Payment Methods";
exports.fieldRequired = "This field is required";
exports.apiKey = "API Key";
exports.apiSecretKey = "API Secret Key";
exports.stripeIntegration = "Stripe Integration";
exports.paymentIntegration = "Payment Integration";
exports.active = "Active";
exports.inactive = "Inactive";
exports.razorPay = "RazorPay";
exports.stripe = "Stripe";
exports.user = "User:";
exports.password = "Password:";
exports.stars = "*************";
exports.paymentCardHint = "Use to get online payment seamlessly into your bank account";
exports.viewDashboard = "View Dashboard";
exports.activateAccount = "Activate Account";
exports.editAccount = "Edit Account";
exports.editPayment = "Edit Payment";
exports.enablePaymentMode = "Enable or Disable Payment Method";
exports.cashOnDelivery = "Cash On Delivery (COD)";
exports.onlinePayments = "Online Payments";
exports.emptyTextTitle = `Add your country in Store Detail Page to view\npayment gateway provider(s)`;
exports.emptyTextDetail = `Add your Store Info and choose a country of operation to activate list of\n payment gateway providers (s)`;
exports.addStore = "Add Store Details";
exports.descriptionPayment = `Use to get online payment\nseamlessly into your bank account`;
exports.messageTitle = "Message for customers";
exports.messagePlaceholder = "Add any important order related message to display to your customers on the cart and order confirmation screen.";
exports.cashSwitchTitle = "Cash On Delivery"
exports.cashSwitchDescription = "Cater to customers without easy access to online payment methods."
exports.onlineSwitchTitle = "Online Payments"
exports.onlineSwitchDescription = "Provide a speedy and secure checkout experience with online payments."
exports.keyIsRequired = "Key cannot be empty"
exports.secretKey = "Secret Key"

exports.dialog_title = "Error on save";
exports.dialog_msg = "At least one option must be selected";
exports.dialog_bg = "#FF1744";
exports.dialogCFHover = "rgb(240, 25, 73)";
// Customizable Area End
