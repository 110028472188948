import React from "react";
import OrderConfirmationSummaryController, {
    Props,
    // Customizable Area Start
    OrderItem,
    Variant,
    Option
    // Customizable Area End
} from "../src/OrderConfirmationSummaryController.web";

// Customizable Area Start
import {
    withStyles,
    createStyles,
    Theme
} from "@material-ui/core";
import moment from "moment";
const images = require("./assets");
const configJSON = require("./config");
// Customizable Area End
export class OrderConfirmationSummary extends OrderConfirmationSummaryController {
    constructor(props: Props) {
        super(props)
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const date = moment(this.state.orderSummaryData?.data.attributes.order_date)
        const formattedDate = date.format('D MMM, YYYY | hh:mm A')
        // Customizable Area End
        return (
            // Customizable Area Start
            <div className={classes.mainDiv} >
                <div className={classes.header} >
                    <div className={classes.logonDiv}>
                    {this.state.canvasData?.header?.data.attributes.logo_url != null &&
                    <img className={classes.imgDiv} src={this.state.canvasData?.header?.data.attributes.logo_url} alt="Logo" />
                    }
                    {this.state.canvasData?.header?.data.attributes.show_business_name && <span className={classes.restaurantNameForMobile}>{this.state?.storename} </span>}
                    </div>
                </div >
                
                <div className={classes.topSection}>
                    <img className={classes.successLogoImage} src={images.green} alt="green-tick"/>
                    <span className={classes.thankYouText}>
                        {configJSON.thankYouText}
                    </span>
                    <span className={classes.orderSuccess}>
                        {configJSON.orderSuccess}
                    </span>
                </div>

                <div className={classes.mainBoxDiv} >
                    <div className={classes.mainBox} >
                        <div className={classes.orderDate} >
                            {configJSON.orderDate} {formattedDate}
                        </div>
                        
                        <div className={classes.statusDetails}>
                            <div className={classes.mobStatusDetailWrapper}>
                                <div className={classes.headings}>
                                    {configJSON.status}
                                </div>
                                <div className={classes.subheadings}>
                                    {this.state.orderSummaryData?.data.attributes.status}
                                </div>
                            </div>
                            <div className={classes.mobStatusDetailWrapper}>
                                <div className={classes.headings}>
                                    {configJSON.orderNumber}
                                </div>
                                <div className={classes.subheadings}>
                                    {this.state.orderSummaryData?.data.attributes.order_number}
                                </div>
                            </div>
                            <div className={classes.mobStatusDetailWrapperHide}>
                                <div className={classes.headings}>
                                    {configJSON.total}
                                </div>
                                <div className={classes.subheadings}>
                                    {this.state.orderSummaryData?.meta.store_profile.attributes.currency_symbol}
                                    {this.state.orderSummaryData?.data.attributes.total}
                                </div>
                            </div>
                        </div>
                        <hr className={classes.divider}/>

                        <div className={classes.merchantNote}>
                            <div className={classes.headingsNote}>
                                {configJSON.noteFromMerchant}
                            </div>
                            <div className={classes.descriptionBox}>
                                <div className={classes.content}>
                                    <p>{this.state.orderSummaryData?.meta?.store_message}</p>
                                </div>
                            </div>
                        </div>
                        <hr className={classes.divider} />

                        <div className={classes.addressDetails}>
                            <div>
                                <div className={classes.headings}>
                                    {this.state.orderSummaryData?.data.attributes.order_type == configJSON.collectionString ? configJSON.collectionAddress  :configJSON.deliverAddress}
                                </div>
                                <div className={classes.subheadings}>
                                {this.state.orderSummaryData?.data.attributes.order_type == configJSON.collectionString ?
                                    <>
                                        {this.state.orderSummaryData?.data.attributes.account.attributes.full_name} <br />
                                        {this.state.orderSummaryData?.data.attributes.account.attributes.email} <br />
                                        {this.state.orderSummaryData?.meta?.store_profile?.attributes?.address_line_1} {this.state.orderSummaryData?.meta?.store_profile?.attributes?.address_line_2 && ","} {this.state.orderSummaryData?.meta?.store_profile?.attributes?.address_line_2} <br />
                                        {this.state.orderSummaryData?.meta?.store_profile?.attributes?.city_name} {this.state.orderSummaryData?.meta?.store_profile?.attributes?.city_name && ","} {this.state.orderSummaryData?.meta?.store_profile?.attributes?.state_name} <br />
                                        {this.state.orderSummaryData?.meta?.store_profile?.attributes?.zipcode}, {this.state.orderSummaryData?.meta?.store_profile?.attributes?.country_name}
                                    </> 
                                :
                                    <>
                                        {this.state.orderSummaryData?.data.attributes.account.attributes.full_name} <br />
                                        {this.state.orderSummaryData?.data.attributes.account.attributes.email} <br />
                                        {this.state.orderSummaryData?.data.attributes.delivery_address?.attributes.address} <br />
                                        {this.state.orderSummaryData?.data.attributes.delivery_address?.attributes.area ?? ""} {this.state.orderSummaryData?.data.attributes.delivery_address?.attributes.area && <br />} <br />
                                        {this.state.orderSummaryData?.data.attributes.delivery_address?.attributes.flat_no} <br />
                                        {this.state.orderSummaryData?.data.attributes.delivery_address?.attributes.address_line_2} <br />
                                        {this.state.orderSummaryData?.data.attributes.delivery_address?.attributes.landmark}
                                    </>
    }
                                </div>
                            </div>
                        </div>
                        <hr className={classes.divider} />

                        {this.state.orderSummaryData?.data.attributes.order_items.map((item: OrderItem, index: number,OrderItemArray) => {
                            return (
                                <div key={index}>
                                    <div className={classes.itemsContainer}>
                                        <div className={classes.itemSubContainer}>
                                            <div>
                                                <img className={classes.itemImage} src={item.attributes.catalogue.attributes.image.url} />
                                            </div>
                                            <div className={classes.itemDetailContainer}>
                                                <div className={classes.itemName}>
                                                    {item.attributes.catalogue.attributes.name}
                                                </div>

                                                {item.attributes.catalogue_variants.length > 0 && item.attributes.catalogue_variants.map((variants: Variant) => {
                                                    return (
                                                        <div className={classes.variantsContainer}>
                                                            <div className={classes.variantsTitle}>
                                                                {variants.attributes.catalogue_variant.attributes.title}: 
                                                            </div>
                                                            <div className={classes.variantsOptionsContainer}>
                                                                {variants.attributes.catalogue_options.map((option: Option,index:number,variantsarray) => { return (<div className={classes.optionsTitle} key={index}>{option.attributes.option_name}{index !== variantsarray.length-1 && ','}</div>) })}
                                                            </div>
                                                        </div>
                                                    )
                                                })}

                                                <div className={classes.quantity}>
                                                   Qty: <span>{item.attributes.quantity}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={classes.priceContainer}>
                                            <div className={classes.mainPrice}>
                                                {this.state.orderSummaryData?.meta.store_profile.attributes.currency_symbol}
                                                {item.attributes.catalogue.attributes.after_discount}
                                            </div>
                                            {item.attributes.catalogue.attributes.after_discount !== item.attributes.catalogue.attributes.price &&
                                            <span className={`new-price-text ${classes.discountPrice}`}>
                                                {this.state.orderSummaryData?.meta.store_profile.attributes.currency_symbol}{item.attributes.catalogue.attributes.price}
                                            </span>
                        }
                                        </div>
                                    </div>
                                    {index !== OrderItemArray.length - 1 &&
                                        <hr className={classes.divider} />
                                    }
                                </div>
                            )
                        })}

                        <div className={classes.totalWrapper}>
                            <hr className={classes.divider} />
                            <div className={classes.totalContainer}>
                                <p className={classes.removeMarginP}>{configJSON.total}</p>
                                <p className={classes.removeMarginP}>
                                {this.state.orderSummaryData?.meta.store_profile.attributes.currency_symbol}
                                    {this.state.orderSummaryData?.data.attributes.total}
                                </p>
                            </div>
                            <hr className={classes.divider} />

                        </div>

                        <div className={classes.buttonDiv}>
                            <button className={classes.button} onClick={this.handleContinueShopping} data-test-id="continueButton">
                                {configJSON.backTOMenuButton}
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            // Customizable Area End
        )
    }
}
// Customizable Area Start
const Styles = createStyles({
        mainDiv: {
            background: "#F1F5F9",
            "@media (max-width: 785px)":{
                background:'#FFFFFF'
            }
        },
        topSection: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            marginBottom: "30px",
            "@media (max-width: 550px)":{
                marginTop:48
            }
        },
        imgDiv: {
            height: '40px'
        },
        logonDiv: {
            display: "flex",
            alignItems: "center",
        },
        header: {
            background: "white",
            display: "flex",
            paddingTop: "20px",
            paddingLeft: "40px",
            gap: "70px",
            paddingBottom: "20px",
            marginBottom: "50px",
            "@media (max-width: 550px)": {
                display:'none'
            }
        },
        restaurantNameForMobile: {
            fontFamily: "var(--website-builder-body-font)",
            fontSize: '16px',
            fontWeight: 700,
            marginLeft: '10px',
            color: "var(--website-builder-secondary-color)",
          },
        resName: {
            fontFamily: "var(--website-builder-body-font)",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "21px",
        },
        successLogoImage:{
            width: '40px',
            height: '42.14px',
            marginBottom:'1.37px',
            "@media (max-width: 550px)": {
                marginBottom: '8px',
                width: '22px',
                height: '22px'
            }
        },
        thankYouText: {
            fontFamily: "var(--website-builder-header-font)",
            fontWeight: 700,
            fontSize: "30px",
            lineHeight: "40px",
            marginBottom:'8px',
            color:'var(--website-builder-secondary-color)',
            "@media (max-width: 550px)": {
                fontSize: "20px",
                lineHeight: "28px",
            }
        },
        orderSuccess: {
            fontFamily: "var(--website-builder-body-font)",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            color:'var(--website-builder-secondary-color)',
            "@media (max-width: 550px)": {
                fontSize: "14px",
                lineHeight: "22px",
            }
        },
        mainBoxDiv: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "70px",
        },
        mainBox: {
            background: "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            paddingTop: "30px",
            paddingLeft: "25px",
            paddingRight: "25px",
            width: "785px",
            maxWidth : '100%'
        },
        orderDate: {
            color:'var(--website-builder-primary-color)',
            fontFamily: "var(--website-builder-body-font)",
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "22px",
            marginBottom: "30px",
            "@media (max-width: 550px)": {
                marginBottom: "16px",
            }
        },
        statusDetails: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "20px",
            "@media (max-width: 550px)": {
                flexDirection:'column',
            }
        },
        mobStatusDetailWrapper:{
            "@media (max-width: 550px)": {
                width:'100%',
                display:'flex',
                alignItems: "center",
                justifyContent: "space-between",
            }
        },
        mobStatusDetailWrapperHide:{
            "@media (max-width: 550px)": {
                display:'none'
            }
        },
        headings: {
            color: "var(--website-builder-secondary-color)",
            fontFamily: "var(--website-builder-body-font)",
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "22px",
        },
        headingsNote: {
            color: "var(--website-builder-secondary-color)",
            fontFamily: "var(--website-builder-body-font)",
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "22px",
            marginBottom: "3px",
        },
        subheadings: {
            color: "var(--website-builder-secondary-color)",
            fontFamily: "var(--website-builder-body-font)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "22px",
        },
        divider: {
            borderTopColor: "var(--website-builder-secondary-dark-accent-color)",
            marginBottom: "16px",
            marginTop: '0px !important',
        },
      merchantNote: {
            margin: "8px 0px",
        },
        descriptionBox: {
            width: "100%",
            color:"var(--website-builder-secondary-color)"
        },
        addressDetails: {
            display: "flex",
            justifyContent: "space-between",
            margin: "8px 0px 16px",
        },
        itemsContainer: {
            display: "flex",
            justifyContent: "space-between",
            margin: "8px 0px 16px",
            width: '100%',
            gap: "15px",
        },
        itemSubContainer: {
            width:'100%',
            display: "flex",
            gap: "15px",
        },
        itemImage: {
            height: "90px",
            width: "70px",
            "@media (max-width: 550px)": {
                height: "76px",
              },
        },
        itemDetailContainer:{
            width:'100%',
            display:'flex',
            flexDirection:'column',
            gap: "4px",

        },
        itemName: {
            fontFamily: "var(--website-builder-header-font)",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "24px",
            color: "var(--website-builder-secondary-color)"
        },
        variantsContainer: {
            display: "flex",
            gap: "4px 5px",
            width: '100%',
            marginTop:'2px'
        },
        variantsTitle: {
            fontFamily: "var(--website-builder-body-font)",
            width : 'auto',
            color : 'var(--website-builder-secondary-dark-accent-color)',
            whiteSpace: 'nowrap',
            fontWeight: 400,
        },
        variantsOptionsContainer:{
            display: "flex",
            flexWrap:'wrap',
        },
        optionsTitle: {
            fontFamily: "var(--website-builder-body-font)",
            marginLeft: "5px",
            color:"var(--website-builder-secondary-color)"
        },
        buttonDiv: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "35px",
            marginTop:'16px',
            "@media (max-width: 550px)": {
                marginBottom: "16px",
            }
        },
        button: {
            borderRadius: "8px",
            padding: "16px",
            fontFamily: "var(--website-builder-body-font)",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "24px",
            background: "linear-gradient(to right, var(--website-builder-primary-dark-accent-color), var(--website-builder-primary-color))",
            color: "white",
            width: "390px",
            border:'none'
        },
        quantity: {
            fontWeight: 400,
            fontFamily: "var(--website-builder-body-font)",
            fontSize: "14px",
            lineHeight: "22px",
            color:'var(--website-builder-secondary-dark-accent-color)',
            "& span":{
                color:'var(--website-builder-secondary-color)',
            }
        },
        priceContainer:{
            display:'flex',
            width : 'auto',
            height : '100%',
            flexDirection:'column',
            alignItems:'flex-end',
            color : 'var(--website-builder-secondary-color)'
        },
        totalWrapper:{
            width:'100%',
            display:'none',
            "@media (max-width: 550px)": {
                display:'block'
            }
        },
        mainPrice:{
            fontFamily: "var(--website-builder-body-font)",
        },
        totalContainer:{
            width:'100%',
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center',
            fontFamily: "var(--website-builder-body-font)",
            fontWeight:700,
            fontSize:'14px',
            lineHeight:'22px',
            margin:'16px 0px',
            color:'var(--website-builder-secondary-color)'
        },
        removeMarginP:{
            margin : '0px !important'
        },
        discountPrice: {
            textDecoration: "line-through",
            fontWeight: 400,
            color: '#9A9A9A',
            marginLeft: "0px",
            fontSize: '12px'
        },
    })

export default withStyles(Styles)(OrderConfirmationSummary)
// Customizable Area End