// Customizable Area Start
//@ts-nocheck
import React from "react";
// Customizable Area Start
import {
  Box,
  Breadcrumbs,
  createStyles,
  Theme,
  withStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Drawer,
  withWidth,
  Button
} from "@material-ui/core";
import {
  ExpandMore,
  NavigateNext as NavigateNextIcon,
} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import StaticPageController, { StaticPage } from "./StaticPagesController.web";
import parser from "html-react-parser";
import {
  dummyLogo,
  searchIcon,
  backIcon,
  drawerButton,
} from "./assets";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { ProductDetailDialogue } from "../../../components/src/ProductDetailPopUp";
import CloseIcon from '@material-ui/icons/Close';
import LocalPhoneOutlinedIcon from '@material-ui/icons/LocalPhoneOutlined';
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';
import { CartItem } from "../../../../packages/blocks/shoppingcart/src/CartOrdersController.web";

const configJSON = require("./config.js");

export class StaticPages extends StaticPageController {

  helpCenterHandle = () => {
    this.setState({
      mobileOpen: true
    })
  }

  handleOpenSearchDrawer = () => {
    this.setState({
      openDrawer: true
    })
  }

  renderPlaceOrder() {
      if (this.countItem()) {
        return (
          <div className="place-order-container" style={webStyles.orderActionContainer}>
            {this.state.addToCardButton ?
              <div data-test-id='place-order' className="place-order-text" onClick={() => this.handleAddToCart()} style={this.state.isStoreClosed ? { ...webStyles.addToCartBtn, ...webStyles.btnDisable } : { ...webStyles.addToCartBtn, ...webStyles.btnEnable }}>
                {`Add to Cart`}
              </div>
              :
              <div data-test-id='place-order' className="place-order-text" onClick={() => this.onPressOrder()} style={this.state.isStoreClosed ? { ...webStyles.addToCartBtn, ...webStyles.btnDisable } : { ...webStyles.addToCartBtn, ...webStyles.btnEnable }}>
                {`Go to Cart (${this.countItem()})`}
              </div>}
              
          </div>
        )
      }
      if (this.countItem() == 0 && this.state.isStoreClosed && this.state.isDetail) {
        return (
          <div className="place-order-container" style={webStyles.orderActionContainer}>
            <div data-test-id='place-order-disable' className="place-order-text" style={{ ...webStyles.addToCartBtn, ...webStyles.btnDisable }}>
              {`Add to Cart`}
            </div>
  
          </div>
        )
      }
  }

  leftPanelItem = (classes, selected, staticData) => {
    const {
      staticAboutUs,
      staticFAQs,
      staticPrivacy,
      staticTOS,
      staticDR,
      staticHIW,
    } = staticData;

    return (
      <>
        {staticAboutUs && (
          <div
            data-testId="staticAboutUs"
            onClick={() => this.navigateToStatic(staticAboutUs)}
            className={`${classes.leftPanelItem} ${selected?.id === staticAboutUs.id
              ? classes.leftPanelItemSelected
              : ""
              } `}
          >
            {configJSON.textAboutUs}
          </div>
        )}
        {staticFAQs && (
          <div
            data-testId="staticFAQs"
            onClick={() => this.navigateToStatic(staticFAQs)}
            className={`${classes.leftPanelItem} ${selected?.id === staticFAQs.id
              ? classes.leftPanelItemSelected
              : ""
              } `}
          >
            {configJSON.textFAQs}
          </div>
        )}
        {staticPrivacy && (
          <div
            data-testId="staticPrivacy"
            onClick={() => this.navigateToStatic(staticPrivacy)}
            className={`${classes.leftPanelItem} ${selected?.id === staticPrivacy.id
              ? classes.leftPanelItemSelected
              : ""
              } `}
          >
            {configJSON.textPrivacyPolicy}
          </div>
        )}
        {staticTOS && (
          <div
            data-testId="staticTOS"
            onClick={() => this.navigateToStatic(staticTOS)}
            className={`${classes.leftPanelItem} ${selected?.id === staticTOS.id ? classes.leftPanelItemSelected : ""
              } `}
          >
            {configJSON.textTOS}
          </div>
        )}
        {staticHIW && (
          <div
            data-testId="staticHIW"
            onClick={() => this.navigateToStatic(staticHIW)}
            className={`${classes.leftPanelItem} ${selected?.id === staticHIW.id ? classes.leftPanelItemSelected : ""
              } `}
          >
            How it works
          </div>
        )}
        {staticDR && (
          <div
            data-testId="staticDR"
            onClick={() => this.navigateToStatic(staticDR)}
            className={`${classes.leftPanelItem} ${selected?.id === staticDR.id ? classes.leftPanelItemSelected : ""
              } `}
          >
            Delivery and returns
          </div>
        )}
      </>
    );
  };

  leftPanelItemMobile = (classes, selectedId, selected, staticData) => {
    const {
      staticAboutUs,
      staticFAQs,
      staticPrivacy,
      staticTOS,
      staticDR,
      staticHIW,
    } = staticData;
    return (
      <>
        {!selectedId && (
          <div className={classes.leftPanelMobile}>
            {staticAboutUs && (
              <div
                data-testId="staticAboutUs2"
                onClick={() => this.navigateToStatic(staticAboutUs)}
                className={`${classes.leftPanelItem} ${selected?.id === staticAboutUs.id
                  ? classes.leftPanelItemSelected
                  : ""
                  } `}
              >
                {configJSON.textAboutUs}
              </div>
            )}
            {staticFAQs && (
              <div
                data-testId="staticFAQs2"
                onClick={() => this.navigateToStatic(staticFAQs)}
                className={`${classes.leftPanelItem} ${selected?.id === staticFAQs.id
                  ? classes.leftPanelItemSelected
                  : ""
                  } `}
              >
                {configJSON.textFAQs}
              </div>
            )}
            {staticPrivacy && (
              <div
                data-testId="staticPrivacy2"
                onClick={() => this.navigateToStatic(staticPrivacy)}
                className={`${classes.leftPanelItem} ${selected?.id === staticPrivacy.id
                  ? classes.leftPanelItemSelected
                  : ""
                  } `}
              >
                {configJSON.textPrivacyPolicy}
              </div>
            )}
            {staticTOS && (
              <div
                data-testId="staticTOS2"
                onClick={() => this.navigateToStatic(staticTOS)}
                className={`${classes.leftPanelItem} ${selected?.id === staticTOS.id
                  ? classes.leftPanelItemSelected
                  : ""
                  } `}
              >
                {configJSON.textTOS}
              </div>
            )}
            {staticHIW && (
              <div
                data-testId="staticHIW2"
                onClick={() => this.navigateToStatic(staticHIW)}
                className={`${classes.leftPanelItem} ${selected?.id === staticHIW.id
                  ? classes.leftPanelItemSelected
                  : ""
                  } `}
              >
                How it works
              </div>
            )}
            {staticDR && (
              <div
                data-testId="staticDR2" onClick={() => this.navigateToStatic(staticDR)}
                className={`${classes.leftPanelItem} ${selected?.id === staticDR.id
                  ? classes.leftPanelItemSelected
                  : ""
                  } `}
              >
                Delivery and returns
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  getDisplayStyle(isMobile: any, selectedId: any) {
    if (isMobile) {
      if (selectedId === undefined) {
        return "block"
      } else return 'none'
    } else return 'block'
  }

  getColor(isMobile: any) {
    if (isMobile) return "#F1F5F9"
    else return "#FFF"
  }

  render() {
    const selectedId = this.props.navigation?.getParam("id");
    const { classes, width } = this.props;
    const { staticPages } = this.state;
    const isMobile = /s/.test(width);

    const selected = staticPages.find(
      (staticPage: StaticPage) => staticPage.id === selectedId
    );

    const staticAboutUs = staticPages.find(
      (staticPage: StaticPage) =>
        staticPage.attributes.page_type.name === "About us"
    );
    const staticTOS = staticPages.find(
      (staticPage: StaticPage) =>
        staticPage.attributes.page_type.name === "Terms of Service"
    );
    const staticPrivacy = staticPages.find(
      (staticPage: StaticPage) =>
        staticPage.attributes.page_type.name === "Privacy Policy"
    );
    const staticFAQs = staticPages.find(
      (staticPage: StaticPage) =>
        staticPage.attributes.page_type.name === "FAQs" // Privacy Policy
    );
    const staticHIW = staticPages.find(
      (staticPage: StaticPage) =>
        staticPage.attributes.page_type.name === "How it works"
    );
    const staticDR = staticPages.find(
      (staticPage: StaticPage) =>
        staticPage.attributes.page_type.name === "Delivery and returns"
    );
    const staticData = {
      staticAboutUs,
      staticFAQs,
      staticPrivacy,
      staticTOS,
      staticDR,
      staticHIW,
    };

    return (
      <>
        <Grid>
          <div className={classes.mainAppDiv} >
            <AppHeader
              Fname="Restaurant"
              logo={dummyLogo}
              itemName="Name"
              data-test-id="appHeaderComponent"
              Lname="Name"
              state={this.state}
              searchData={this.state.searchData}
              value={this.state.search}
              onBlur={() => { this.setState({ isSearch: false }); }}
              onChange={(text) => this.searchItem(text.target.value)}
              handleCloseDrawer={this.handleCloseDrawer}
              handleOpenDrawer={this.handleOpenDrawer}
              onFocus={() => { this.setState({ isSearch: true }); }}
              itemImage="image"
              itemSelect={(i: CartItem) => this.openItemDetails(i)}
              openDrawer={this.state.openDrawer}
              backgroundColor= {this.getColor(isMobile)}
              searchInputColor={true}
              mixPanelHit={this.mixPanelEventHit}
              isStaticPage= {true}
            />
            {isMobile && (
              <div style={{ position: "absolute", transform: "translateY(-180%)", paddingLeft: "10px" }}>
                <svg onClick={this.handleToggle} data-test-id="drawerButton" className={classes.cursorPointer}  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4 6H20C20.55 6 21 6.45 21 7V7.01C21 7.56 20.55 8.01 20 8.01H4C3.45 8.01 3 7.56 3 7.01V7C3 6.45 3.45 6 4 6ZM20 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13H20C20.55 13 21 12.55 21 12C21 11.45 20.55 11 20 11ZM4 18H14C14.55 18 15 17.55 15 17C15 16.45 14.55 16 14 16H4C3.45 16 3 16.45 3 17C3 17.55 3.45 18 4 18Z" fill="var(--website-builder-secondary-color)"/>
                </svg>
              </div>
            )}
            <div className={classes.breadcrumbsOnScreen}>
              <Box
                className={classes.breadcrumbsWrapper}
              >
                <Breadcrumbs separator={<NavigateNextIcon className={classes.secondaryColor} fontSize="small" />}>
                  <Link
                    to="/"
                    className={classes.breadcrumbLink}>
                    <Typography>
                      {configJSON.textHome}
                    </Typography>
                  </Link>
                  <Link
                    to={this.helpCenterHandle}
                    className={classes.breadcrumbLink} onClick={this.helpCenterHandle} data-test-id="breadcrumb">
                    <Typography>{configJSON.textHelpCenter}</Typography>
                  </Link>
                  {selectedId &&
                    (
                      <Typography
                        className=
                        {classes.breadcrumbNonLink}>
                        {selected?.attributes?.page_type.name}
                      </Typography>
                    )
                  }
                </Breadcrumbs>
              </Box>
            </div>
            <ProductDetailDialogue
              open={this.state.isDetail}
              setClose={() => { this.setCloseDetail() }}
              currencyType={this.currencyType}
              selectedProduct={this.state.selectedProduct}
              variantSelect={this.variantSelect}
              variantRadio={this.variantRadio}
              currentPrice={this.state.tempStore}
              validationOptions={this.state.validationOptions}
              data-test-id="productDetailTest"
            />
          </div>
        </Grid>
        <Grid item container>
          {/* Left Drawer */}
          <div>
            <Drawer
              open={this.state.mobileOpen}
              fullWidth
              style={{ width: "100vw" }}
              className={classes.drawer}
              anchor="left"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                className={classes.leftDrawerContent}
              >
                <svg onClick={this.handleClose} data-test-id="closeMenuDrawer" className={classes.cursorPointer}  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4 6H20C20.55 6 21 6.45 21 7V7.01C21 7.56 20.55 8.01 20 8.01H4C3.45 8.01 3 7.56 3 7.01V7C3 6.45 3.45 6 4 6ZM20 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13H20C20.55 13 21 12.55 21 12C21 11.45 20.55 11 20 11ZM4 18H14C14.55 18 15 17.55 15 17C15 16.45 14.55 16 14 16H4C3.45 16 3 16.45 3 17C3 17.55 3.45 18 4 18Z" fill="var(--website-builder-secondary-color)"/>
                </svg>
                <div>
                <img
                  style={{ marginRight: "28px", height: "40px" }}
                  src={this.state.canvasData?.header?.data.attributes.logo_url}
                  alt="Logo"
                />
                {this.state.canvasData?.header?.data.attributes.show_business_name && <span className={classes.restaurantNameForMobile}>{this.state?.storename} </span>}
                </div>
                <svg data-test-id="searchopendrawerTest" className={classes.cursorPointer} onClick={this.handleOpenSearchDrawer}  width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.5271 15.2322L20.4529 19.158L19.1566 20.4544L15.2308 16.5286C13.8192 17.6579 12.029 18.3335 10.082 18.3335C5.52803 18.3335 1.83203 14.6375 1.83203 10.0835C1.83203 5.5295 5.52803 1.8335 10.082 1.8335C14.636 1.8335 18.332 5.5295 18.332 10.0835C18.332 12.0305 17.6564 13.8207 16.5271 15.2322ZM14.688 14.5521C15.8089 13.3969 16.4987 11.8211 16.4987 10.0835C16.4987 6.53829 13.6272 3.66683 10.082 3.66683C6.53682 3.66683 3.66536 6.53829 3.66536 10.0835C3.66536 13.6287 6.53682 16.5002 10.082 16.5002C11.8197 16.5002 13.3954 15.8104 14.5506 14.6895L14.688 14.5521Z" fill="var(--website-builder-secondary-color)"/>
                </svg>
              </div>

              <div>
                <Box
                  className=
                  {classes.breadcrumbsWrapper}
                >
                  <Breadcrumbs
                    separator=
                    {<NavigateNextIcon className={classes.secondaryColor}
                      fontSize="small" />}
                  >
                    <Link to="/"
                      className={
                        classes.breadcrumbLink
                      }
                    >
                      <Typography>
                        {
                          configJSON.textHome
                        }
                      </Typography>
                    </Link>
                    <Link to={this.helpCenterHandle}
                      className={
                        classes.breadcrumbLink
                      }
                      onClick={this.helpCenterHandle}
                    >
                      <Typography>
                        {
                          configJSON.textHelpCenter
                        }
                      </Typography>
                    </Link>
                    {selectedId && (
                      <Typography className={classes.breadcrumbNonLink}>
                        {selected?.attributes?.page_type.name}
                      </Typography>
                    )}
                  </Breadcrumbs>
                </Box>
              </div>

              <div>
                <Typography className={classes.selectedSectionHeading}>
                  Help Center
                </Typography>
              </div>

              <Grid item lg={3} md={4} sm={12}>
                <div className={classes.staticPage}>
                  <div className={classes.contentDrawer}>
                    <div className={classes.leftPanelDrawer}>
                      {this.leftPanelItem(classes, selected, staticData)}
                    </div>
                  </div>
                </div>
              </Grid>
            </Drawer>
          </div>

          <Grid item container className={classes.mainPageDiv}>
            {/* Sidebar */}
            <Grid item lg={3} md={4} sm={12} className={classes.sidebarContainerDiv} style={{ display: this.getDisplayStyle(isMobile, selectedId) }}>
              <div className={classes.staticPage}>
                <Grid>
                  <Link to="/" className={classes.backButtonArea}>
                    <svg className={classes.marginRight5} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z" fill="var(--website-builder-secondary-dark-accent-color)"/>
                    </svg>
                    {configJSON.textBack}
                  </Link>
                </Grid>
                <Grid>
                  <Typography className={classes.helpCenterText}>
                    {configJSON.textHelpCenter}
                  </Typography>
                </Grid>
                <div className={classes.content}>
                  <div className={classes.leftPanel} style={{ display: this.getDisplayStyle(isMobile, selectedId) }}>
                    {this.leftPanelItem(classes, selected, staticData)}
                  </div>
                  {this.leftPanelItemMobile(
                    classes,
                    selectedId,
                    selected,
                    staticData
                  )}
                </div>
              </div>
            </Grid>

            <Grid item container lg={9} md={8} sm={12} className={classes.mainStaticPage}>
              <Grid item lg={10} md={10} sm={10} className={classes.faqDiv}>
                {selectedId ? (
                  selected?.attributes?.page_type?.name === "FAQs" ? (
                    <div className={classes.faqs}>
                      {selected?.attributes.description?.questions
                        ?.filter((question) => question.attributes.status)
                        .map((question) => (
                          <div className={classes.faqsWide}>
                            <div className={classes.rightPanel}>
                              <div className={classes.faqTitle}>
                                {question.attributes.title}
                              </div>
                              <div className={classes.faqContent}>
                                {parser(question.attributes.content)}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <div className={classes.rightPanel}>
                      <div className={classes.pageTitle}>
                        {selected?.attributes.title}
                      </div>
                      <div className={classes.pageContent}>
                        {parser(
                          selected?.attributes?.description?.content || ""
                        )}
                      </div>
                    </div>
                  )
                ) : (
                  <div className={classes.rightPanelEmpty} />
                )}
              </Grid>
            </Grid>
          </Grid>
            <div className={classes.placeOrderRender}>
              {!this.state.isOrderSuccess && this.renderPlaceOrder()}
            </div>
        </Grid>
      </>
    );
  }
}

export const Styles: any = (theme: Theme) =>
  createStyles({
    staticPage: {
      padding: "20px 30px",
    },
    selectedSectionHeading: {
      fontSize: "30px",
      fontWeight: 700,
      fontFamily: "var(--website-builder-header-font)",
      lineHeight: "40px",
      padding: "16px",
      color:"var(--website-builder-secondary-color)"
    },
    selectedSectionHeadingSearch: {
      fontSize: "30px",
      fontWeight: 700,
      fontFamily: "var(--website-builder-body-font)",
      lineHeight: "40px",
      padding: "10px 16px",
    },
    subHeadingSearch: {
      fontSize: "14px",
      fontWeight: 400,
      fontFamily: "var(--website-builder-body-font)",
      lineHeight: "22px",
      padding: "16px",
    },
    noSearchHeading: {
      fontSize: "20px",
      fontWeight: 700,
      fontFamily: "var(--website-builder-body-font)",
      lineHeight: "28px",
      marginBottom: "8px",
    },
    noSearchSubHeading: {
      fontSize: "14px",
      fontWeight: 400,
      fontFamily: "var(--website-builder-body-font)",
      lineHeight: "22px",
    },
    drawer: {
      "& .MuiDrawer-paper": {
        width: "100%",
      },
    },
    drawerSearch: {
      "& .MuiDrawer-paper": {
        width: "100%",
        backgroundColor: "#F1F5F9",
      },
    },
    leftDrawerContent: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 16px",
    },
    mainStaticPage: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "25px",
      backgroundColor: "#F1F5F9"
    },
    mainAppDiv: {
      padding: "10px",
      backgroundColor: "#FFF",
      [theme.breakpoints?.down("sm")]: {
        backgroundColor: "#F1F5F9",
      },
    },
    mainPageDiv:{
      display: "flex",
      height: "100vh",
      backgroundColor: "#F1F5F9"
    },
    faqDiv: {
      width: "100%",
    },
    restaurantNameForMobile: {
      fontSize: '16px',
      fontWeight: 700,
      marginLeft: '-20px',
      color: "var(--website-builder-secondary-color)",
    },
    marginRight5:{
      marginRight: "5px"
    },
    cursorPointer: {
      cursor: "pointer"
    },
    backButtonArea: {
      display: "flex",
      alignItems:"center",
      marginBottom: 20,
      fontSize: "14px",
      fontWeight: 700,
      fontFamily: "var(--website-builder-body-font)",
      lineHeight: "22px",
      letterSpacing: "0px",
      color: "var(--website-builder-secondary-color)",
      cursor: "pointer",
      textDecoration: "none",
      [theme.breakpoints?.down("sm")]: {
        display: "none",
      },
    },
    content: {
      display: "flex",
      gap: 20,
      [theme.breakpoints?.down("sm")]: {
        flexDirection: "column",
      },
    },
    contentDrawer: {
      display: "flex",
      gap: 20,
    },
    select: {
      border: "1px solid #364F6B33",
      padding: "18.5px 14px",
    },
    leftPanel: {
      display: "flex",
      flex: 1,
      backgroundColor: "#FFFFFF",
      flexDirection: "column",
    },
    leftPanelDrawer: {
      display: "flex",
      flex: 1,
      backgroundColor: "#FFFFFF",
      flexDirection: "column",
    },
    leftPanelMobile: {
      display: "none",
      flex: 1,
      backgroundColor: "#FFFFFF",
      flexDirection: "column",
      [theme.breakpoints?.down("sm")]: {
        display: "none",
      },
    },
    leftPanelItem: {
      padding: "10px",
      cursor: "pointer",
      fontFamily: "var(--website-builder-body-font)",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      color: "var(--website-builder-secondary-dark-accent-color)",
    },
    leftPanelItemSelected: {
      padding: "10px",
      cursor: "pointer",
      fontFamily: "var(--website-builder-body-font)",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      color: "var(--website-builder-primary-color)",
      backgroundColor: "var(--website-builder-primary-light-accent-color)",
      borderRadius: "8px",
    },
    rightPanel: {
      padding: "15px 30px",
      borderRadius: 8,
      backgroundColor: "#FFFFFF",
      "@media (max-width: 480px)": {
        margin: "0px 15px",
        padding: "15px 15px",
      },
    },
    pageTitle: {
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      color: "var(--website-builder-secondary-color)",
      marginBottom: 40,
      fontFamily: "var(--website-builder-header-font)",
    },
    pageContent: {
      fontFamily: "var(--website-builder-body-font)",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#334155",
      marginBottom: 20,
    },
    rightPanelEmpty: {
      flex: 3,
    },
    faqs: {
      display: "flex",
      flexDirection: "column",
      gap: 25,
      [theme.breakpoints?.down("sm")]: {
        gap: 15,
      },
    },
    faqsWide: {
      display: "block",
    },
    faqsSmall: {
      display: "none",
      [theme.breakpoints?.down("sm")]: {
        display: "block",
      },
    },
    faqTitle: {
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      color: "var(--website-builder-secondary-color)",
      fontFamily: "var(--website-builder-header-font)",
    },
    faqContent: {
      fontFamily: "var(--website-builder-body-font)",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#334155",
    },
    secondaryColor: {
      color:"var(--website-builder-secondary-color)"
    },
    breadcrumbsWrapper: {
      padding: "16px",
    },
    breadcrumbLink: {
      color: "var(--website-builder-secondary-dark-accent-color)",
      textDecoration: "none",
      fontFamily: "var(--website-builder-body-font)",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      "& .MuiTypography-body1": {
        fontFamily: "var(--website-builder-body-font)",
      },
      
    },
    breadcrumbNonLink: {
      color: "var(--website-builder-secondary-color)",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      "& .MuiTypography-body1": {
        fontFamily: "var(--website-builder-body-font)",
      },
      "@media (max-width: 900px)": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontFamily: "var(--website-builder-body-font)",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
      },
      "@media (max-width: 480px)": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
      },
      "@media (max-width: 320px)": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
      },
    },
    helpCenterText: {
      fontSize: "30px",
      fontWeight: 700,
      lineHeight: "40px",
      marginBottom: "20px",
      color:"var(--website-builder-secondary-color)",
      [theme.breakpoints?.down("sm")]: {
        display: "none",
      },
      "& .MuiTypography-body1": {
        fontFamily: "var(--website-builder-body-font)",
      },
    },
    mainDivCon: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
      backgroundColor: "#F1F5F9",
    },
    logoDiv: {
      alignItems: "center",
      display: "flex",
    },
    restaurantName: {
      fontSize: "16px",
      fontWeight: 700,
      width: "200px",
    },
    searchInput: {
      borderStyle: "none",
      padding: "10px",
      paddingLeft: "40px",
      borderRadius: "3px",
      font: "Inter",
      height: "2.75rem",
    },
    searchInputDrawer: {
      borderStyle: "none",
      padding: "10px",
      paddingLeft: "40px",
      borderRadius: "3px",
      font: "Inter",
      height: "2.75rem",
      width: "100%",
      marginBottom: "50px",
    },
    searchIcon: {
      position: "absolute",
      left: "25px",
      top: "24px",
    },
    noSearchImage: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "16px",
    },
    sidebarContainerDiv: {
      background: "#FFF",
      borderRadius: "0px 0px 40px 0px",
    },
    breadcrumbsOnScreen: {
      display: "none",
      "@media (max-width: 900px)": {
        marginTop: "10px",
        display: "block",
      },
      "@media (max-width: 480px)": {
        marginTop: "10px",
        display: "block",
      },
      "@media (max-width: 320px)": {
        marginTop: "10px",
        display: "block",
      },
    },
    placeOrderRender: {
      display: "block",
      width: "100%",
      "@media (max-width: 900px)": {
        display: "block",
        width: "100%"
      },
      "@media (max-width: 480px)": {
        display: "block",
        width: "100%"
      },
      "@media (max-width: 320px)": {
        display: "block",
        width: "100%"
      },
    },

  });

const webStyles = {
  orderActionContainer: {
    borderRadius: "16px 16px 0px 0px",
    display: 'flex',
    zIndex: 100000,
    position: "fixed",
    left: 0, bottom: 0,
    justifyContent: 'center',
    background: '#FFFFFF',
    width: '100%', 
    boxShadow: '0px -25px 50px 0px rgba(0, 0, 0, 0.09)'
  },
  addToCartBtn: {
    textAlign: 'center',
    cursor: "pointer",
    width: "100%",
    fontFamily: "var(--website-builder-body-font)",
    fontWeight: 700,
    textTransform: 'capitalize',
    borderRadius: '8px',
  },
  btnEnable: {
    color: 'white',
    background: "linear-gradient(99.09deg, var(--website-builder-primary-dark-accent-color) 2.64%, var(--website-builder-primary-color) 100%)"
  },
  btnDisable: {
    pointerEvents: "none",
    background: "#F1F5F9",
    color: "#64748B"
  },
  contentSuccess: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: "100%"
  },
  containerSuccess: {
    display: 'flex',
    position: "fixed",
    zIndex: 10000,
    left: 0,
    bottom: 0,
    justifyContent: 'center',
    padding: '25px',
    background: '#FFFFFF',
    width: '100%',
    boxShadow: '0px -25px 50px 0px #00000017'
  },
  closeIcon: {
    width: '24px',
    height: '24px',
    color: 'gray',
    position: 'absolute',
    cursor: 'pointer'
  },
  iconPhone: {
    color: '#5500CC',
    marginRight: "8px",
    width: "22px",
    height: "22px",
  },
  placeOrderText: {
    fontFamily: "var(--website-builder-body-font)",
    fontWeight: 700,
    color: '#5500CC',
    alignSelf: 'center'
  },
  buttonPlaceOrder: {
    textAlign: 'center',
    width: "100%",
    fontFamily: "var(--website-builder-body-font)",
    fontWeight: 700,
    textTransform: 'capitalize',
    borderRadius: '8px',
    color: 'white',
    background: "linear-gradient(99.09deg, #C399FF 2.64%, #6200EA 100%)"
  },
  containerPlaceOrder: {
    borderRadius: "16px 16px 0px 0px",
    display: 'flex',
    zIndex: 100000,
    position: "fixed",
    left: 0,
    bottom: 0,
    justifyContent: 'center',
    background: '#FFFFFF',
    width: '100%',
    boxShadow: '0px -25px 50px 0px rgba(0, 0, 0, 0.09)'
  },
  bodyText:{
    fontFamily: "var(--website-builder-body-font)",
  }
}

const StyledComponent = withStyles(Styles)(StaticPages);
export default withWidth()(StyledComponent);
// Customizable Area End

// Customizable Area End
