// Customizable Area Start
//@ts-nocheck
import React from "react";
import {
  Box,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import VariantsController, { Props } from "./VariantsController.web";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { noVariants, editIcon, deleteIcon, searchIcon } from "./assets";
import withStyles, { CSSProperties } from "@material-ui/core/styles/withStyles";
import CustomizedDialogs from "../../../components/src/ConfirmModal.web";
import AlertMessage from "../../../components/src/AlertMessage.web";
import SubmitButtonPopup from "../../../components/src/SubmitButtonPopup.web";
import AddEditVariant from "./AddNewVariant.web";
import AddVariantSearch from "./AddNewVariantSearch.web";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout";
import { Scrollbars } from "react-custom-scrollbars";
import SectionLoader from "./SectionLoader.web";
import Pagination from "../../ss-cms-common-components/src/Pagination/Pagination";

export class Variants extends VariantsController {
  constructor(props: Props) {
    super(props);
  }

  variantTable = (classes) => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableTitle} style={{}}>
              {" "}
              Title
            </TableCell>
            <TableCell className={classes.tableTitle} style={{}}>
              {" "}
              Options
            </TableCell>
            <TableCell className={classes.tableTitle} style={{}}>
              {" "}
              Price
            </TableCell>
            <TableCell
              className={classes.tableTitle}
              style={{
                paddingRight: "45px",
              }}
              align="right"
            >
              Edit
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody style={{ border: "2px", background: "#fff" }}>
          {this.state.variants &&
          this.state.variants.length > 0 &&
          !this.state.noSearchVariants ? (
            this.state.variants.map((data: any, index: any) => {
              return (
                <>
                  <TableRow
                    key={data.variant.id}
                    style={{ border: "2px solid #ECEDF0" }}
                  >
                    <TableCell
                      style={{ width: "20%" }}
                      rowSpan={
                        data?.options?.length == 0 ? 1 : data?.options?.length
                      }
                      className={classes.tableData}
                    >
                      {data.variant.title}
                    </TableCell>
                    <TableCell
                      className={classes.tableData}
                      style={{ borderLeft: "2px solid #ECEDF0" }}
                    >
                      {data?.options.length == 0
                        ? ""
                        : data?.options[0]?.option_name}
                    </TableCell>
                    <TableCell
                      className={classes.tableData}
                      style={{ borderRight: "2px solid #ECEDF0" }}
                    >
                      {data?.options.length == 0 ? "" : data?.options[0]?.price}
                    </TableCell>
                    <TableCell
                      style={{ width: "10%" }}
                      rowSpan={
                        data?.options?.length == 0 ? 1 : data?.options?.length
                      }
                      align="center"
                    >
                      <img
                        src={editIcon}
                        style={{ cursor: "pointer" }}
                        data-testId={`editVariantBtn-${index}`}
                        alt="edit icon"
                        onClick={() => {
                          this.setState({
                            searchSelectedVariant: data,
                            isOpen: true,
                            isEdit: true,
                          });
                        }}
                      />
                      <img
                        src={deleteIcon}
                        style={{
                          cursor: "pointer",
                          marginLeft: "15px",
                          height: "18.33px",
                          width: "18.33px",
                        }}
                        data-testId={`deleteVariantBtn-${index}`}
                        onClick={() => {
                          this.setState({
                            openModal: true,
                            variantId: data.variant.id,
                          });
                        }}
                        alt="delete icon"
                      />
                    </TableCell>
                  </TableRow>
                  {data.options.slice(1).map((option: any, index: any) => {
                    return (
                      <TableRow style={{ border: "2px solid #ECEDF0" }}>
                        <TableCell
                          className={classes.tableData}
                          style={{ borderLeft: "2px solid #ECEDF0" }}
                        >
                          {option.option_name}
                        </TableCell>
                        <TableCell
                          className={classes.tableData}
                          style={{ borderRight: "2px solid #ECEDF0" }}
                        >
                          {option.price}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              );
            })
          ) : (
            <TableRow style={{ border: "2px solid #ECEDF0" }}>
              <TableCell component="th" scope="row" colSpan={4}>
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                  style={{ background: "#fff", margin: "10px 0px" }}
                >
                  <Grid item className={classes.variantsContent}>
                    <Typography style={{ marginBottom: "10px" }}>
                      <img src={noVariants} alt="no variants" />
                    </Typography>
                    <Typography
                      variant="h5"
                      component="h2"
                      style={{
                        fontFamily: "Rubik",
                        fontSize: "20px",
                        fontWeight: "500",
                        lineHeight: "26px",
                        letterSpacing: "-0.02em",
                        marginBottom: "5px",
                      }}
                    >
                      No results found
                    </Typography>
                    <Typography
                      color="textSecondary"
                      style={{
                        fontFamily: "Rubik",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "22px",
                        marginBottom: "10px",
                        color: "#83889E",
                      }}
                    >
                      Revise search text and try again
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
  };

  variantsBlock = (classes: any) => {
    return this.state.variants && this.state.variants.length > 0 ? (
      <Grid item>
        <Grid
          item
          lg={12}
          md={12}
          xs={12}
          style={{ display: "flex", marginBottom: "20px" }}
        >
          <Grid item lg={6} md={6} xs={6}>
            <Typography
              variant="h5"
              style={{
                fontFamily: "Rubik",
                fontSize: "26px",
                fontWeight: "500",
                lineHeight: "31px",
                letterSpacing: "0em",
              }}
            >
              Variants
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} xs={6} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              data-test-id="variantButtonTest"
              className={classes.variantButton}
              style={{ background: "#00D659" }}
              onClick={() => {
                this.setState({ isOpen: true });
              }}
              data-testId="addVariant"
            >
              Add Variants
            </Button>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} xs={12} style={{ margin: "30px 0px" }}>
          <form style={{ width: "100%", background: "#fff" }} noValidate>
            <TextField
              id="searchVariant"
              fullWidth
              variant="outlined"
              size="small"
              placeholder="Search Variants"
              data-testId="searchVariantInput"
              value={this.state.searchVariant}
              className={classes.InputStyle}
              onKeyDown={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              onChange={(e) => {
                this.searchVariantWithText(e.target.value);
              }}
              autoComplete="off"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      style={{ height: "16.13px", width: "16.13px" }}
                      src={searchIcon}
                      alt="searchIcon"
                    />
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          {this.state.loadingSearch ? (
            <SectionLoader loading={true} />
          ) : (
            this.variantTable(classes)
          )}
        </Grid>
      </Grid>
    ) : (
      <Grid
        item
        lg={12}
        md={12}
        xs={12}
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box sx={useStyles.noVariantsBox}>
          <Grid item className={classes.variantsContent}>
            <Typography style={{ marginBottom: "10px" }}>
              <img src={noVariants} alt="no variants " />
            </Typography>
            <Typography
              variant="h5"
              component="h2"
              style={{
                fontFamily: "Rubik",
                fontSize: "26px",
                fontWeight: "500",
                lineHeight: "31px",
                marginBottom: "5px",
                color: "#363636",
              }}
            >
              You have no variants
            </Typography>
            <Typography
              style={{
                fontFamily: "Rubik",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "22px",
                color: "#676B7E",
                marginBottom: "10px",
              }}
            >
              Easily set up variants and allow your customers to <br />{" "}
              personalize their order.
            </Typography>
            <Typography
              style={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontStyle: "italic",
                fontWeight: "400",
                lineHeight: "18px",
                marginBottom: "10px",
              }}
            >
              For example- Crust types, Toppings, Size, Ingredients etc.
            </Typography>
            <Grid>
              <Button
                variant="contained"
                data-testId="addVariants"
                className={classes.variantButton}
                style={{ background: "#00D659" }}
                onClick={() => {
                  this.setState({ isOpen: true });
                }}
              >
                Add Variants
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        {this.state.isOpen && (
          <AddEditVariant
            navigation={this.props.navigation}
            handleClose={this.CreateVariantModalClose}
            isEdit={this.state.isEdit}
            isEditStatus={false}
            searchSelectedVariant={this.state.searchSelectedVariant}
            showCreateSuccessPopup={this.showCreateSuccessPopup}
            showEditeSuccessPopup={this.showEditeSuccessPopup}
          />
        )}
        <AddVariantSearch
          data-test-id="addVariantTest"
          navigation={this.props.navigation}
          classes={useStyles}
          parentState={this.state}
          closeSearch={() => {
            this.setState({ searchVariantModalOpen: false });
          }}
          openAdd={() => {
            this.setState({ isOpen: true });
          }}
          closeAdd={() => {
            this.setState({ isOpen: false });
          }}
        />
        <AlertMessage
          data-testId="alertMessage"
          open={this.state.openAlert}
          success={this.state.alertSuccess}
          message={this.state.alertMessage}
          close={this.handleAlertMessageClose}
        />
        <SubmitButtonPopup
          data-testId="variantSubmit"
          open={this.state.openSubmit}
          close={this.discardBtn}
          submit={this.submitBtn}
        />
        <Scrollbars>
          <Grid container item lg={12} md={12} xs={12} className={classes.root}>
            <Grid item lg={11} md={11} xs={11} style={{ marginTop: "40px" }}>
              {this.state.loading ? (
                <SectionLoader loading={true} />
              ) : (
                !this.state.isAddEdit && this.variantsBlock(classes)
              )}
              {!!this.state.pagination?.total_count &&
                !this.state.noSearchVariants &&
                !this.state.loading &&
                !this.state.loadingSearch && (
                  <Box marginBottom={4}>
                    <Pagination
                      data-testId="pagination"
                      count={this.state.pagination?.total_count}
                      onChange={this.handlePageChange}
                      pageSize={this.state.pagination?.per_page}
                      itemText="Variants"
                      pageNo={this.state.pagination?.current_page || 1}
                    />
                  </Box>
                )}
            </Grid>
          </Grid>
        </Scrollbars>
        <CustomizedDialogs
          data-testId="deleteVariant"
          open={this.state.openModal}
          name="variant"
          handleClose={this.handleClose}
          deleteCategory={this.deleteVariant}
        />
      </>
    );
  }
}

const useStyles: Record<string, CSSProperties> = {
  root: {
    justifyContent: "center",
    backgroundColor: "#FAFAFA",
  },
  tableTitle: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    letterSpacing: "0em",
    color: "#3C3E49",
  },
  tableData: {
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "16px",
    letterSpacing: "0em",
    color: "#3C3E49",
  },
  InputStyle: {
    color: "red",
    "& input": {
      fontFamily: "Rubik",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "22px",
      color: "#AFB2C0",
    },
    "& fieldset": {
      border: "1px solid #D0D2DA",
      top: "0px",
    },
  },
  noVariantsBox: {
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "50px 50px 0px 50px",
    width: "648px",
    height: "357px",
    marginTop: "60px",
  },
  variantsContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  variantButton: {
    background: "#00D659",
    textTransform: "none",
    boxShadow: "none",
    borderRadius: "3px",
    fontFamily: "Rubik",
    fontSize: "16px",
    fontweight: "500",
    lineHeight: "24px",
    padding: "10px 26px 10px 26px",
    letterSspacing: "0em",
    color: " #3C3E49",
  },
};

export default withLayout(withStyles(useStyles)(Variants));

// Customizable Area End
