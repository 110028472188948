// Customizable Area Start
import PromoCodeCreateController from "./PromoCodeCreateController.web";
import * as React from "react";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import withSnackBar from "../../ss-cms-common-components/src/HOC/withSnackBar.Web";
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { Scrollbars } from "react-custom-scrollbars";
import { withStyles } from "@material-ui/core/styles";
import "date-fns";
import Toolbar from "../../ss-cms-common-components/src/Toolbar/Toolbar";
import PromoCodeComponent from "./PromoCodeComponent.web";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout"
export const configJSON = require("./config.js");
import "../assets/promoCode.css";

export class PromoCodeCreate extends PromoCodeCreateController {
  promocodeComp = () => {
    if (this.state.promoCodeId != 0) {
      if (this.state.filledData) {
        return (
          <PromoCodeComponent
            onSubmit={this.handleSubmitPromocode}
            openToast={this.openToastOnChange}
            formRef={this.formRef}
            promoCodeData={this.state.filledData}
          />
        )
      }
    } else {
      return (
        <PromoCodeComponent
          onSubmit={this.handleSubmitPromocode}
          openToast={this.openToastOnChange}
          formRef={this.formRef}
          promoCodeData={{}}
        />
      )
    }
  }
  render() {
  
    return (
      <Scrollbars>
        <div className="promo-code-create-container">
          <Toolbar
            title={
              this.state.promoCodeId ? configJSON.editPromoCode : configJSON.createPromoCode
            }
            handleBackClick={this.handleBackClick}
          />
          {this.promocodeComp()}
        </div>
      </Scrollbars>
    );
  }
}
export const styles = () => ({
  input: {
    height: 48,
  },
});

export default withLayout(withStyles(styles)(
  withSnackBar(withLoader(withHeaderBar(PromoCodeCreate)))
));
// Customizable Area End
