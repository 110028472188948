// Customizable Area Start
import React from "react";
import QrCodeController from "./QrCodeController.web";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import withHeaderActionBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import "../assets/styles.css";
import { Button } from "@material-ui/core";
import { QRCodeSVG } from "qrcode.react";
import moment from "moment";

const configJSON = require("./config");

const handleQR = (codeType: string) => {
  if(codeType === "ios") {
    return "App Store"
  }
  if(codeType === "android") {
    return "Play Store"
  }
}

export class QrCode extends QrCodeController {
  render() {
    const qrCodes  = this.state.qrCodes;
      return (
        <div className="qr-codes">
          {!qrCodes.length ? (
            <div style={webStyles.mainDiv}>
            <div style={webStyles.noQrDiv}>
              <span style={webStyles.storeUrlHeading}>
                {configJSON.pleaseAdd}
              </span>
              <span style={webStyles.noQrDescription}>
                {configJSON.createQrCodeFirst} <br/> {configJSON.createQrCodeScondLine}
              </span>
              <div style={webStyles.buttonDiv}>
              <button style={webStyles.noQrButton} onClick={this.props.handleSetting}>
                {configJSON.goToAppSettingsButton}
              </button>
              </div>
            </div>
            </div>
          ) : (
            <>
              {qrCodes.map((code, index) => (
                <div className="qr-code-card-wrapper" key={index}>
                <div className="qr-code-card">
                  <div className="qr-code-image" ref={this.state.qrCodeRef[index]}>
                    <QRCodeSVG value={code.url} />
                  </div>
                  <span className="qr-code-title">
                    {handleQR(code.code_type)} {configJSON.qrCode}
                  </span>
                  <div className="qr-code-updated-at">
                    {configJSON.updatedAt} {moment(code.updated_at).format("DD/MM/YY")}
                  </div>
                  <Button
                    className="edit-qr-code-button"
                    variant="outlined"
                    onClick={() =>
                      this.downloadQRCode(
                        this.state.qrCodeRef[index]?.current?.querySelector("svg")
                      )
                    }
                  >
                    {configJSON.download}
                  </Button>
                </div>
              </div>
              ))}
            </>
          )}
        </div>
      );
    };
}

const webStyles = {
  mainDiv: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  } as React.CSSProperties,
  noQrDiv: {
    backgroundColor: "#FFFFFF",
    width: "auto",
    height: "auto",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "50px",
  } as React.CSSProperties,
  storeUrlHeading: {
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: "28px",
    lineHeight: "32px",
    color: "#3C3E49",
    marginBottom: "20px"
  } as React.CSSProperties,
  noQrDescription: {
    textAlign: "center",
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#676B7E",
    marginBottom: "30px"
  } as React.CSSProperties,
  noQrButton: {
    padding: "12px, 40px, 12px, 40px",
    color: "#3C3E49",
    backgroundColor: "#00D659",
    borderRadius: "3px",
    height: "48px",
    width: "230px",
    border: "none",
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
  } as React.CSSProperties,
  buttonDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  } as React.CSSProperties,
}

export default withDialog(withHeaderActionBar(withLoader(QrCode)));
// Customizable Area End