// Customizable Area Start
//@ts-nocheck
import React from "react";
import {
    Box,
    Button,
    Grid,
    TextField,
    Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AdminLoginController, { Props } from "./AdminLoginController.web";
import withStyles, { CSSProperties } from "@material-ui/core/styles/withStyles";
import { Formik } from "formik";
import * as Yup from "yup";
import { backIcon } from "./assets";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
    typography: {
        fontFamily: "Rubik",
    }

});

const validationSchema = Yup.object().shape({
    forget_email: Yup.string().email('Invalid email address')
        .required("Email is required"),
})

export class ForgetPassword extends AdminLoginController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const { classes } = this.props;
        return (
            <ThemeProvider theme={theme}>
                <Grid container item lg={12} md={12} xs={12} className={classes.root}>
                    <Box>
                        <Grid className={classes.backArrow}>
                            <img
                                src={backIcon}
                                data-testId="backBtn"
                                onClick={() => { this.redirectNavigation("AdminLogin") }}
                                alt="backIcon"
                                style={{
                                    cursor: "pointer",
                                    marginRight: "5px",
                                    height: "12px",
                                    width: "12px",
                                }}
                            />
                            <Typography className={classes.storeName}>Back to sign in </Typography>
                        </Grid>
                        <Box className={classes.adminLoginBox}>
                            <Formik
                                initialValues={{
                                    forget_email: this.state.forget_email,
                                }}
                                data-testId="formik"
                                onSubmit={(values) => {
                                    console.log("ForgetPassword", values)
                                }}
                                validationSchema={validationSchema}
                            >
                                {({
                                    errors,
                                    touched,
                                    values,
                                    handleBlur,
                                    setFieldValue,
                                    handleSubmit,
                                }) => (
                                    <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                        <Typography
                                            component="h5"
                                            style={{
                                                fontWeight: "500",
                                                fontSize: "20px",
                                                lineHeight: "28px",
                                                paddingBottom: "20px",
                                                color: "#3C3E49"
                                            }}
                                        >
                                            Forgotten password
                                        </Typography>
                                        <Grid
                                            item
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            className={classes.bottomSpace}
                                        >
                                            <Typography
                                                variant="h6"
                                                component="h6"
                                                className={classes.titleText}
                                            >
                                                Email
                                            </Typography>
                                            <TextField
                                                value={values.forget_email}
                                                type="text"
                                                name="forget_email"
                                                data-testId="forget_email"
                                                className={classes.placeholderText}
                                                onChange={(e) => {
                                                    this.setState({
                                                        forget_email: e.target.value,
                                                    });
                                                    setFieldValue("forget_email", e.target.value);
                                                }}
                                                error={Boolean(touched.forget_email && errors.forget_email)}
                                                onBlur={handleBlur}
                                                helperText={touched.forget_email && errors.forget_email}
                                                fullWidth
                                                variant="outlined"
                                                placeholder="Enter email"
                                            />
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}> <Button variant="contained" type="submit" className={classes.loginBtn}><Typography style={{
                                            fontSize: "16px",
                                            fontWeight: 500,
                                            lineHeight: "24px",
                                            color: "#3C3E49"
                                        }}>Reset password</Typography></Button></Grid>
                                    </form>
                                )}
                            </Formik>
                        </Box>
                    </Box>
                </Grid>
            </ThemeProvider>
        );
    }
}

const useStyles: Record<string, CSSProperties> = {
    root: {
        display: "flex",
        background: "#FAFAFA",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    },
    storeName: {
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "22px",
        color: "#676B7E"
    },
    adminLoginBox: {
        width: "448px",
        background: "#FFF",
        border: "2px solid #ECEDF0",
        borderRadius: "3px",
        padding: "40px 20px"
    },
    titleText: {
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "20px",
    },
    bottomSpace: {
        marginBottom: "20px"
    },
    loginBtn: {
        background: "#00D659",
        borderRadius: "3px",
        width: "100%",
        border: "none",
        padding: "12px",
        cursor: "pointer",
        boxShadow: "none",
        textTransform: "none",
        "&:hover": {
            boxShadow: "none",
            background: "#00D659",
        }
    },
    placeholderText: {
        "& input, & textarea, & div": {
            fontFamily: "Rubik",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "22px",
            color: "#64748B",
        },
    },
    backArrow: {
        display: "flex",
        alignItems: "center",
        marginBottom: "20px",
    },
}

export default withStyles(useStyles)(ForgetPassword);

// Customizable Area End