// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";
import { ChangeEvent } from "react";
import { generateRequestMessage, showError, isTokenExpired, clearStorageData } from "../../ss-cms-common-components/src/Utilities/Utilities";
export const configJSON = require("./config.js");

export type Props = DialogProps &
  withLoaderProps &
  withHeadeActionBarProps & {
    navigation: {
      navigate: (to: string, params: object) => void;
      getParam: (param: string) => string;
      goBack: () => void;
    };
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  selectedImages: string[];
  selectedRawImages: File[];
  formValues: string[];
  categoryImageError: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export const Endpoint = configJSON.endPoint;

export default class ImageCatalogueBulkUploadController extends BlockComponent<Props, S, SS> {
  uploadMessageId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ActionMessageFromToaster),
    ];

    this.state = {
      selectedImages: [],
      selectedRawImages: [],
      formValues: [],
      categoryImageError:''
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  setImagesSpecificName = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) => {
    let formValues = [...this.state.formValues];
    formValues[index] = e.target.value;
    this.setState({ formValues });
    const emptyname = formValues.filter(el => el.trim() === '');
    if(emptyname.length>=1){
      this.props.showHeaderBar({ message: "",enable:true });
    }
    else{
      this.props.showHeaderBar({ message: "",enable:false });
    }
  
  }

  bulkUpload = async () => {
    const requestMessage = await generateRequestMessage(
      Endpoint,
      configJSON.postAPiMethod,
      {
        "content-type": undefined,
      }
    );

    let formData = new FormData();
    this.state.selectedRawImages.forEach((i, index) => {
      formData.append("images[]", i);
      formData.append("names[]", this.state.formValues[index]);
    });

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    this.uploadMessageId = requestMessage.messageId;
    this.props.showLoader();
    this.send(requestMessage);
  };

  receive = (from: string, message: Message) => {
    if (message.id === getName(MessageEnum.ActionMessageFromToaster)) {
      const type = message.getData(
        getName(MessageEnum.ActionMessageFromToasterMessage)
      );
      if (type === "SAVECHANGES") {
        this.bulkUpload();
      }
      if (type === "DISCARDCHANGES") {
        window.location.reload();
      }
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.uploadMessageId === apiRequestCallId) {
        this.props.hideLoader();
        if (responseJson.errors) {
          return this.handleError(configJSON.errorOnBulkUpload, responseJson);
        }
        const to = new Message(getName(MessageEnum.NavigationMessage));
        to.addData(getName(MessageEnum.NavigationTargetMessage), "AdminImageCatalogue");
        to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        runEngine.sendMessage(to.messageId, to);
        this.props.showHeaderBar({ type: "success" });
      }
    }
  };

  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  async componentWillUnmount(): Promise<void> {
    super.componentWillUnmount();
    this.props.hideHeaderBar();
  }

  handleError = (title: string, responseJson: {}) => {
    showError(
      title,
      responseJson,
      this.props.hideLoader,
      this.props.setDialogState
    );
  };

  handleBackButtonClick = () => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(getName(MessageEnum.NavigationTargetMessage), "AdminImageCatalogue");
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  handleSelectImages = (files: FileList) => {
    if (files.length) {
      const oldListLength = this.state.selectedRawImages.length;
      const fileArray = Array.from(files);
      const totalSize = [...this.state.selectedRawImages, ...fileArray].reduce(
        (acc: number, curr) => acc + Number((curr as File)?.size || 0),
        0
      );
      if (totalSize > 50 * 1024 * 1024) {
        return this.props.showHeaderBar({
          message: configJSON.imageSizeValidation,
          type: "error",
        });
      }
      const newImageArray = Array.from({
        length: oldListLength + files.length,
      }).map(() => "");
      this.state.selectedImages.forEach((i, index) => {
        newImageArray[index] = i;
      });

      let values = [...this.state.formValues];

      fileArray.forEach((i: File, index: number) => {
        const reader = new FileReader();
        reader.onload = () => {
          this.setState((prevState) => {
            const selectedImages = prevState.selectedImages;
            selectedImages[oldListLength + index] = reader.result as string;
            return { selectedImages };
          });
        };
        reader.readAsDataURL(i);

        values.push(i.name);
      });

      this.setState({
        selectedImages: newImageArray,
        selectedRawImages: [...this.state.selectedRawImages, ...fileArray] as File[],
        formValues: values,
        categoryImageError:'',
      });
      this.props.showHeaderBar({ message: "",enable:false});
    }
  };

  handleDeleteSelectedImage = (index: number) => () => {
    const selectedImages = [...this.state.selectedImages];
    const selectedRawImages = [...this.state.selectedRawImages];
    selectedImages.splice(index, 1);
    selectedRawImages.splice(index, 1);
    this.setState({ selectedImages, selectedRawImages }, () => {
      if(selectedImages.length>=1){
        this.props.showHeaderBar({ message: "",enable:false });
        this.setState({ categoryImageError: '' })
      }
      else{
        this.props.showHeaderBar({ message: "",enable:true  });
        this.setState({ categoryImageError: configJSON.requiredField })
      }
    
    });
  };
}
// Customizable Area End