// Customizable Area Start
import React from "react";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { getStorageData } from "../../../framework/src/Utilities";
import * as Yup from "yup";
import { Box } from "@material-ui/core";
export const configJSON = require("./config");

interface Ss {
  variant_type: string;
  title: string;
  options: any[];
  min : number | null
  max: number | null,
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleClose: (apicall: boolean) => {};
  classes: any;
  isEdit: boolean;
  isEditStatus: boolean;
  searchSelectedVariant: any;
  variantName: string;
  showEditeSuccessPopup: () => {};
  showCreateSuccessPopup: () => {};
  modifyDatapass: (obj: any) => {};
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isEditStatus: boolean;
  formInitialValues: Ss;
  isEdit: boolean;
  optionsLength: number;
  minMaxErroFlag: boolean
  variantApiResponseStore: any;
  variantOptionsResponseStore: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
Yup.addMethod(Yup.array, "unique", function (message) {
  return this.test("unique", message, function (list) {
    const mapper = (x: any) => x.option_name;
    const set = [...new Set(list.map(mapper))];
    const isUnique = list.length === set.length;
    if (isUnique) {
      return true;
    }
    const idx = list.findIndex((l: any, i: number) => mapper(l) !== set[i]);
    return this.createError({
      path: `options[${idx}].option_name`,
      message: message,
    });
  });
});

// Customizable Area End

export default class AddNewVariantController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  formSchema: any;
  formRef: any;
  addOPtionBtnRef: any;
  optionsRef: any;
  scrollContainerRef: any;
  // api Id
  createVariantApiCall: any;
  createVariantOptionApiCall: any;
  updateVariantApiCall: any;
  updateVariantOptionApiCall: any;
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.AccoutLoginSuccess),
    ];

    this.formSchema = Yup.object().shape({
      variant_type: Yup.string()
        .required("Type is required")
        .max(300, "Must be less than 300 characters"),
        title: Yup.string()
        .max(36, "Must be less than 36 characters")
        .required("Title is required"),
        min : Yup.number().typeError('Minimum is required')
        .required("Minimum is required"),
        max : Yup.number().nullable(true).typeError('Maximum is required').when('variant_type', {
          is: 'multiple',
          then: Yup.number().typeError('Maximum is required').required('Maximum is required')
        }),
      options: Yup.array()
        .of(
          Yup.object().shape({
            price: Yup.number().required("Price is required"),
            status: Yup.boolean().required("status is required"),
            option_name: Yup.string().required("Option Name is required"),
          })
        )
        .required("Must have Options")
        // @ts-ignore
        .unique("OptionName Must be unique")
        .min(1, "At least one option required"),
    });

    this.formRef = React.createRef();
    this.addOPtionBtnRef = React.createRef();
    this.optionsRef = React.createRef();
    this.scrollContainerRef = React.createRef();
    this.optionsRef.current = [];

    // Customizable Area Start

    this.state = {
      // Customizable Area Start
      isEdit: false,
      isEditStatus: false,
      optionsLength: 0,
      formInitialValues: {
        title: "",
        variant_type: "multiple",
        max : null,
        min : null,
        options: [{ option_name: "", price: null, status: true }],
      },
      variantOptionsResponseStore: null,
      variantApiResponseStore: null,
      minMaxErroFlag: false,
      // Customizable Area Start
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      // check api response
      if ((responseJson && responseJson.data) || responseJson.variant) {
        this.checkResponseApi(apiRequestCallId, responseJson);
      } else {
        this.parseApiErrorResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
        this.props.handleClose(true);
      }
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    let { isEdit, isEditStatus, searchSelectedVariant } = this.props;
    this.setState({ isEdit, isEditStatus });
    if (isEditStatus) {
      this.setFlagInventory();
    }
    if (searchSelectedVariant) {
      this.setInitialValues();
    }
  }

  // set form initalvalue
  setInitialValues = () => {
    let { searchSelectedVariant } = this.props;
    let { variant, options } = searchSelectedVariant;
    this.setState({
      formInitialValues: {
        ...this.state.formInitialValues,
        title: variant.title,
        variant_type: variant.variant_type,
        max: variant.max,
        min: variant.min,
        options: options.length
          ? options
          : [{ option_name: "", price: null, status: true }],
      },
    });
  };

   // set Inventory state
   setFlagInventory = () => {
    let { isEdit, searchSelectedVariant, variantName } = this.props;
    if (!isEdit && variantName) {
      this.setState({
        formInitialValues: {
          ...this.state.formInitialValues,
          title: variantName,
          
          options: [{ option_name: "", price: null, status: true }],
        },
      });
    }
    if (searchSelectedVariant) {
      let { options } = searchSelectedVariant;
      this.setState({ optionsLength: options.length });
    }
  };

  // api response check
  checkResponseApi = (apiRequestCallId: any, responseJson: any) => {
     // create variant option api call
     if (apiRequestCallId === this.createVariantOptionApiCall) {
      this.setState({ variantOptionsResponseStore: responseJson.data }, () => {
        this.handleAfterFormSave();
      });
    }
    
    // create variant api call
    if (apiRequestCallId === this.createVariantApiCall) {
      this.checkResponseErrorVariant(responseJson);
    }
   
    // update variant
    if (apiRequestCallId === this.updateVariantApiCall) {
      if (
        responseJson.data?.attributes?.errors?.title[0] ==
        "has already been taken"
      ) {
        this.formRef.current.setFieldError(
          "title",
          "This field name is already in use. Please enter a unique name."
        );
      } else {
        this.setState(
          { variantApiResponseStore: { attributes: responseJson.variant } },
          () => {
            this.UpdateVariantoption();
          }
        );
      }
      // update variant option after update variant
    }
    // update variant option
    if (apiRequestCallId === this.updateVariantOptionApiCall) {
      this.setState({ variantOptionsResponseStore: responseJson.data }, () => {
        this.handleAfterFormSave();
      });
    }
    //
  };

  // add option btn click and sroll manage
  handleAddOption = () => {
    this.addOPtionBtnRef.current?.click();
    setTimeout(() => {
      this.scrollContainerRef.current.scrollTo({
        left: 0,
        top: this.scrollContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }, 100);
  };

  handleAfterFormSave = () => {
    if (this.state.isEditStatus) {
      let {
        variantApiResponseStore: { attributes },
        variantOptionsResponseStore: { data },
      } = this.state;
      this.props.modifyDatapass({
        id: attributes.id,
        title: attributes.title,
        variant_type: attributes.variant_type,
        min : attributes.min,
        max : attributes.max,
        options: data.map((opt: any) => ({
          data: {
            attributes: {
              id: opt.attributes.id,
              price: opt.attributes.price,
              option_name: opt.attributes.option_name,
              status: opt.attributes.status,
            },
          },
        })),
      });
      this.props.handleClose(false);
    } else {
      if (this.state.isEdit) {
        this.props.showEditeSuccessPopup();
      } else {
        this.props.showCreateSuccessPopup();
      }
      this.props.handleClose(true);
    }
  };

  // manage error response

  checkResponseErrorVariant = (responseJson: any) => {
    if (responseJson.data.id) {
      this.setState({ variantApiResponseStore: responseJson.data }, () => {
        this.CreateVariantoption(responseJson.data.id);
      });
    } else {
      this.formRef.current.setFieldError(
        "title",
        "This field name is already in use. Please enter a unique name."
      );
    }
  };

  // create variant option
  CreateVariantoption = async (variantId: string) => {
    let token = await getStorageData("admintoken");

    let formValue = this.formRef.current.values;
    let data = {
      variant_id: +variantId,
      options: formValue.options,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": "application/json",
      token,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/catalogues_options`
    );

    this.createVariantOptionApiCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  //  update variant option
  UpdateVariantoption = async () => {
    let token = await getStorageData("admintoken");

    let formValue = this.formRef.current.values;
    let { variant } = this.props.searchSelectedVariant;
    let data = {
      // variant_id: variant.id,
      options: formValue.options,
    };

    const header = {
      "Content-Type": "application/json",
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateVariantOptionApiCall = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/catalogues_options/${variant.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Edit variant
  VariantFormEdit = async (values: any) => {
    let { variant } = this.props.searchSelectedVariant;
    let token = await getStorageData("admintoken");

    const header = {
      "Content-Type": "application/json",
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateVariantApiCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_catalogue/catalogues_variants/${variant.id}?title=${values.title}&variant_type=${values.variant_type}&min=${values.min}&max=${values.max}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // create variant
  VariantFormCreate = async (values: any) => {
    let token = await getStorageData("admintoken");

    const header = {
      token,
    };

    let formData = new FormData();
    formData.append("title", values.title);
    formData.append("variant_type", values.variant_type);
    formData.append("min", values.min);
    formData.append("max", values.max);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createVariantApiCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_catalogue/catalogues_variants`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // form submit handle
  variantFormSubmit = (values: any) => {
    if(values.max < values.min && values.variant_type==='multiple') {
      this.updateMinMaxFlag(true)
      return
    }
    if (this.state.isEdit) {
      this.VariantFormEdit(values);
    } else {
      this.VariantFormCreate(values);
    }
  };

  // option push formik field
  AddOptionForm = (e: any, arrayHelpers: any[]) => {
    e.preventDefault();
    arrayHelpers.push({ option_name: "", price: null, status: true });
  };
  // error show
  RenderOptionsMessage = (error: any) => {
    if (error.options) {
      let fidError = error.options.find(
        (options: any) => options?.option_name === "OptionName Must be unique"
      );

      if (fidError) {
        return (
          <Box width="100%" mt="0.88rem" p="0rem 3rem ">
            <p style={{ color: "#DC2626" }}>{fidError.option_name}*</p>
          </Box>
        );
      }
    }
  };

  updateMinMaxFlag = (value: boolean) =>{
    this.setState({minMaxErroFlag:value})
  }

  updateValueMinMax = (values:Ss)=>{
    if(values.max && values.max > values.options.length - 1){
      this.formRef.current.setFieldValue('max',null)
    }
    if(values.min && values.min > values.options.length - 1){
      this.formRef.current.setFieldValue('min',null)
    }
    
  }
  // Customizable Area End
}
