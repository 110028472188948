// Customizable Area Start
import React from "react";
import HeaderController from "./HeaderController.web";
const configJSON = require("./config.js");

export class Header extends HeaderController {
  render() {
    return (
      <div className="header">
        <div className="header-title-wrapper">
          <span className="header-title">
            {configJSON.otherIntegrationsTitle}
          </span>
        </div>
        {this.props?.children}
      </div>
    );
  }
}
// Customizable Area End