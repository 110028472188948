Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.urlGetValidations = 'profile/validations';
exports.validationApiContentType = 'application/json';
exports.validationApiMethodType = 'GET';
exports.loginAPiEndPoint = '/admin/v1/login/';
exports.forgotAPiEndPoint = '/admin/v1/forgot_password';
exports.otpValidateAPIEndPoint = '/admin/v1/forgot_password/otp_validate';
exports.loginAPiMethod = 'POST';
exports.loginApiContentType = 'application/json';
exports.dashboardOnbordingAPI = '/admin/v1/onboarding';
// account detail
exports.accountDetailsContentType = 'application/json';
exports.accountDetailsMethodType = 'GET';
exports.accountDetailsAPI = 'bx_block_admin_profile/admin_profile';
// updateAccountAPI
exports.updateAccountAPIContentType = 'application/json';
exports.updateAccountAPIMethodType = 'PUT';
exports.updateAccountAPI = 'bx_block_admin_profile/admin_profile';
// updateAccountAPI
exports.adminUsersMethodType = 'GET';
exports.adminUsersAPI = 'admin/v1/admin_user/sub_admin_users';
// subAdminCountAPI
exports.subAdminCountContentType = 'application/json';
exports.subAdminCountMethodType = 'GET';
exports.subAdminCountAPI = 'admin/v1/admin_user/sub_admin_count';
// updateSubAdminAPI
exports.updateSubAdminAPIContentType = 'application/json';
exports.updateSubAdminAPIMethodType = 'PUT';
exports.updateSubAdminAPI = 'admin/v1/admin_user/update_sub_admin';
// createSubAdminAPI
exports.createSubAdminAPIContentType = 'application/json';
exports.createSubAdminAPIMethodType = 'POST';
exports.createSubAdminAPI = 'admin/v1/admin_user/create_sub_admin';
// getSubAdminDetailAPI
exports.subAdminUserDetailMethodType = 'GET';
exports.subAdminUserDetailAPI = 'admin/v1/admin_user/show_sub_admin';
// getSubAdminDetailAPI
exports.subAdminPermissionsMethodType = 'GET';
exports.subAdminPermissionsAPI = 'admin/v1/admin_user/permissions';
// getSubAdminDetailAPI
exports.subAdminBulkDeleteAPI = '/admin/v1/batch_requests';
exports.subAdminBulkDeleteMethodType = 'POST';
// getSubAdminDetailAPI
exports.getCurrentPlanMethodType = 'GET';
exports.getCurrentPlanAppToken = 'iCxBWGyfgL89NPCwkc-i';

exports.pleaseEnterAValidEmail = 'Please enter a valid email';
exports.nameIsRequired = 'Name is required';
exports.emailIsRequired = 'Email is required';
exports.invalidEmailAddress = 'Invalid email address';

exports.phoneNumberIsNotValid = 'Phone number is not valid';
exports.phoneNumberIsRequired = 'Phone number is required';
exports.phoneMustBeAtLeast10Characters = 'Phone must be at least 10 characters';
exports.phoneMustBeAtMax11Characters = 'Phone must be maximum 11 characters';
exports.nameMustBeMaximum50Characters = 'Name must be maximum 50 characters';
exports.passwordIsRequired = 'Password is required';
exports.pleaseEnterAPassword = 'Please enter a password';
exports.mustContain8Char1Uppercase1Digit1Symbol =
  'Must contain 8 characters including 1 Uppercase letter, 1 digit and 1 Symbol';
exports.pleaseConfirmYourPassword = 'Please confirm your password';
exports.passwordsMustMatch = 'Password does not match';
exports.passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>'])(?=.{8,})/;
exports.emailRegex = !/^\S+@\S+\.\S+$/;
exports.invalidPassword = 'Invalid password';

exports.errorEmailNotValid = 'Email not valid.';
exports.errorPasswordNotValid = 'Password not valid.';
exports.placeHolderEmail = 'Email';
exports.placeHolderPassword = 'Password';

exports.imgPasswordInVisiblePath = 'assets/ic_password_invisible.png';
exports.imgPasswordVisiblePath = 'assets/ic_password_visible.png';

exports.extractPermissions = ['tag', 'bulk upload', 'variant'];
exports.allPermissions = [
  'product',
  'category',
  'order',
  'brand',
  'coupon',
  'user',
  'brand setting',
  'tax',
  'email setting'
];
exports.productPermissionArray = [
  'product',
  'tax',
  'category',
  'variant',
  'brand',
  'bulk upload'
];

exports.generalFetchErrorMessage = "Sorry account detail data couldn't fetch";
exports.generalSaveErrorMessage = "Error: data can't save";

exports.getUserPlanIframeURL =
  'https://staging.engineer.ai/upgrade-plan?project_id';

exports.createNewAdminUserLabel = 'Create new admin user';
exports.backButtonLabel = 'Back';
exports.planButtonLabel = 'Plan';
exports.adminUserHeader = 'Admin Users';
exports.deleteAdminUsers = "Delete admin user's";

exports.thereIsNoAdminUsers = 'There is no admin users';
exports.areYouSure = 'Are you sure?';
exports.areYouSureYouWantToDeleteThisAdminUser =
  'Are you sure you want to delete this admin user.';
exports.accountAdminUsersNewURL = '/account/admin-users/new';
exports.accountAdminUsersURL = '/account/admin-users';
exports.accountAdminUsersURL = '/account/admin-users';

exports.sorryAdminUsersDataCouldntFetch =
  "Sorry admin users data couldn't fetch";
exports.somethingWentWrong = 'Something went wrong';

exports.permissionsLabel = 'Permissions';
exports.createNewSubAdmin = 'Create new sub admin';
exports.editSubAdmin = 'Edit sub admin';
exports.subAdminNotFound = 'Sub admin not found';
exports.createNewSubAdmin = 'Create new sub admin';
exports.couldntFindSubAdminWithId = "Couldn't find sub admin with id";
exports.emailLabel = 'Email';
exports.emailPlaceholder = 'Email address';
exports.nameLabel = 'Name';
exports.namePlaceholder = 'Name';
exports.phoneLabel = 'Phone';
exports.phonePlaceholder = 'Enter phone number';
exports.passwordLabel = 'Password';
exports.passwordConfirmation = 'Password confirmation';
exports.changePassword = 'Change password';

exports.sorryYourAdminDataCouldnTFetch = "Sorry your admin data couldn't fetch";
exports.addNewAdminUserURL = '/account/admin-users/new';
exports.welcomeToYourAccount = 'Welcome to your account';
exports.logout = 'Logout';
exports.profileTitle = 'Profile';
exports.adminUsersTitle = 'admin users';
exports.planTitle = 'Plan';
exports.accountDetailLink = '/account/detail';
exports.planLink = '/account/plan';
exports.adminUsersLink = '/account/admin-users';
exports.homeLink = "/AdminStoreDetails";

exports.flagValueUK = 'uk';
exports.flagValueIndia = 'uk';
exports.flagValueUS = 'us';
exports.flagValueUE = 'au';
exports.phonoCodeUK = '+44';
exports.phonoCodeIndia = '+91';
exports.phonoCodeUS = '+1';
exports.phonoCodeUE = '+971';
// Customizable Area End
