Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "websitebuilder";
exports.labelBodyText = "websitebuilder Body";

exports.btnExampleTitle = "CLICK ME";

exports.postAPiMethod = 'POST';
exports.putAPiMethod = 'PUT';
exports.getApiMethod = 'GET';
exports.patchApiMethod = 'PATCH';
exports.deleteApiMethod = 'DELETE';
exports.apiContentType = "application/json"; 
exports.storeProfileEndPointAPI = "/bx_block_store_details/store_profile"; 

exports.createContentWebsiteBuilderEndPoint = "bx_block_websitebuilder/designs";
exports.textNewDesktop = "Desktop";
exports.textNewMobile = "Mobile";
exports.texttFooterSection = "Copyright © 2021 Studio Store. All rights reserved Powered by Builder ai.";
exports.textAreaWillDisplay = "This area will display an email link for your customers along with the Phone number and WhatsApp number added on Store Details."
exports.textFooterCopyRight = "Copyright © 2021 Studio Store. All rights reserved";
exports.textFooterBuilderAi = "Builder.ai";
exports.textFooterPowered = "Powered by"
exports.textUrlNotValid = "Oops! It seems like the provided URL is not valid."
exports.textUrlNotValid = "Oops! It seems like the provided URL is not valid.";
exports.textHeaderBranding ="Branding";
exports.textPreview ="Quick homepage preview";
exports.textNotValid = "Copyright must be at maximum of 150 characters";
exports.textRequired = "Copyright field is required";
exports.desktopBanner = "Desktop Banner";
exports.mobileBanner = "Mobile Banner";
exports.mobileCropperText = "Crop your image to appear for";
exports.textMobileDevice = "mobile devices";
exports.textDesktopDevice = "Desktop";
exports.selectionMobileText = "Upload images from your device or from Builder Image Catalogue";
exports.textBannerImageSelection = "Banner Image Selection";
exports.textSelectionPixel = "Upload a banner image that is at least 2880X960 pixels for optimal results. File Size Max 20MB";
exports.textSelectionPixelMobile = "Upload a banner image that is at least 780*800 pixels for optimal results. File Size Max 20MB";
exports.defaultColorsApiEndPoint = "/bx_block_websitebuilder/colour_selections";
exports.fontsList = [
  "Roboto",
  "Inter",
  "Open Sans",
  "Montserrat",
  "Source Sans Pro",
  "Noto Sans",
  "Poppins",
  "Raleway",
  "Oxygen",
  "Lato",
]
exports.textAlignmentList = [
  "Left",
  "Center",
  "Right",
]

exports.textPositionList = [
  "Top",
  "Center",
  "Bottom",
]

exports.desktopTextPositionList = [
  "Top Left",
  "Top Right",
  "Bottom Left",
  "Bottom Right",
  "Center",
  "Mid Left",
  "Mid Right",
]
exports.textUploadButton = "Upload from device";
exports.textChooseCatalogue = "Choose from Image Catalogue";
exports.textModalRemoved = "The section selected will be removed from this Home page on your site."
exports.textUploadLogo = "Upload Logo";
exports.textUploadFavicon = "Upload Favicon";
exports.textUploadLogoModalText = "Click to Upload";
exports.textUploadLogoModalMessage = "Upload a Logo image that is at least 400X160 pixels for optimal results. File Size Max 20MB. Supported Formats PNG, JPG, JPEG.";
exports.textUploadFaviconModalMessage = "Upload a Favicon image that is at least 32 X 32 pixels for optimal results. File Size Max 20MB. Supported Formats PNG, JPG, JPEG.";
exports.addLogoText = "Add Logo";
exports.addFaviconText = "Add Favicon";
exports.HeaderNavigationSettingsMessage = "Choose the categories you want to promote to top of the menu";
exports.FaviconTooltipText = "Favicon is the image that appears on the tab of the browser window for the website";
exports.textUrlRequired = "URL is required";
exports.SocialTooltipText = "Leave this field blank if you prefer not to display this information";
exports.getImageCatalougeAPIEndpoint = "bx_block_images_catalogue/images";
// Customizable Area End