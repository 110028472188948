// Customizable Area Start
import React from "react";
import ImageCatalogueBulkUploadController from "./ImageCatalogueBulkUploadController";
import "../assets/styles.css";
import withHeaderActionBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import UploadBox from "../../ss-cms-common-components/src/UploadBox/UploadBox.Web";
import Button from "@material-ui/core/Button";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import { Scrollbars } from "react-custom-scrollbars";
import { TextField } from "@material-ui/core";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout"
import { AddIcon } from "./assets"

export const configJSON = require("./config.js");

export class ImageCatalogueBulkUpload extends ImageCatalogueBulkUploadController {
  render() {
    return (
      <Scrollbars>
        <div className="image-catalogue image-catalogue-bulk-upload">
          <Header onBackButtonClick={this.handleBackButtonClick} />
          <div className="content">
            <div className="image-upload-card">
              <span className="image-upload-header">
                {configJSON.imageDetails}
              </span>
              <span className="image-upload-image-list-header">
                {configJSON.image}
              </span>
              <span className="image-upload-image-list-hint">
                {configJSON.bulkUploadHint}
              </span>
              {this.state.selectedImages.length ? (
                <div className="image-upload-selected-images">
                  {this.state.selectedImages.map((i, index) => (
                    <div className="image-upload-selected-image-wrapper">
                      <img
                        className="image-upload-selected-image"
                        alt="selected image"
                        src={i}
                      />
                      <TextField
                        name="imageSpecificName"
                        size="small"
                        variant="outlined"
                        fullWidth
                        onChange={(e) => this.setImagesSpecificName(e, index)}
                        value={this.state.formValues[index]}
                        {...this.props}
                        id="standard-size-small"
                        style={{
                          marginTop: 20,
                        }}
                      />
                      <Button
                        className="image-upload-selected-image-remove-basic"
                        onClick={this.handleDeleteSelectedImage(index)}
                      >
                        {configJSON.remove}
                      </Button>
                    </div>
                  ))}
                  <div style={{ paddingBottom: 32, display: "flex" }}>
                    <UploadBox
                      uploadIcon={AddIcon}
                      height="155px"
                      minHeight="120px"
                      width="155px"
                      margin="0"
                      multiple
                      uploadMsg={
                        <div className="image-upload-message">
                          {configJSON.addImage}
                        </div>
                      }
                      sizeMsg={
                        <div className="image-upload-size-hint">
                          {configJSON.maxMb}
                        </div>
                      }
                      messageMargin="0"
                      onSelectFile={this.handleSelectImages.bind(this)}
                      error={this.state.categoryImageError ? true : false}
                      helperText={
                        <p  style={{color: '#D50000',fontSize: '0.75rem'}} id="standard-size-small-helper-text">{this.state?.categoryImageError}</p>
                        
                      }
                    />
                  </div>
                </div>
              ) : (
                <UploadBox
                  uploadIcon={AddIcon}
                  height="180px"
                  width="100%"
                  margin="8px 0 20px 0"
                  multiple
                  uploadMsg={
                    <div className="image-upload-message">
                      {configJSON.addImage}
                    </div>
                  }
                  sizeMsg={
                    <div className="image-upload-size-hint">
                      {configJSON.maxMb}
                    </div>
                  }
                  messageMargin="0"
                  onSelectFile={this.handleSelectImages.bind(this)}
                  error={this.state.categoryImageError ? true : false}
                  helperText={
                    <p style={{color: '#D50000',fontSize: '0.75rem'}} id="standard-size-small-helper-text">{this.state?.categoryImageError}</p>
                    
                  }
                />
              )}
            </div>
          </div>
        </div>
      </Scrollbars>
    );
  }
}

interface HeaderProps {
  onBackButtonClick: () => void;
}

export const Header = ({ onBackButtonClick }: HeaderProps) => {
  return (
    <div className="header">
      <div className="header-title-wrapper">
        <div>
          <div
            className="icon-back-wrapper"
            onClick={onBackButtonClick}
            data-testid="bulk-upload-back-button"
          >
            <i className="icon-arrow-right" onClick={onBackButtonClick} />
            <span>{configJSON.back}</span>
          </div>
          <div className="header-title">{configJSON.productImage}</div>
        </div>
      </div>
    </div>
  );
};

export default withLayout(withDialog(
  withHeaderActionBar(withLoader(ImageCatalogueBulkUpload))
));
// Customizable Area End