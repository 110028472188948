// Customizable Area Start
import React, { RefObject } from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";
import { FormikProps } from "formik";
import { getResponseVariables } from "../../ss-cms-common-components/src/GetResponseVariables/GetResponseVariables";
import {generateRequestMessage, checkResponseError, showError} from "../../ss-cms-common-components/src/Utilities/Utilities";

const configJSON = require("./config");

interface errorsTypes{
  errors: string
}
interface QrCodeResponse {
  id: number,
  type: string,
  attributes: {
    id: number;
    code_type: string;
    url: string;
    updated_at: string;
  }
}
interface QrCodeView {
  id: number;
  code_type: string;
  url: string;
  updated_at: string;
}

export type Props = DialogProps &
  withLoaderProps &
  withHeadeActionBarProps & {
    id: string;
    closeDialog: () => void;
    isDialogOpen?: boolean;
    handleSetting? : () => void
  };

interface S {
  qrCodes: QrCodeView[];
  showQrCodeDialog: boolean;
  selectedQrCodeId: number;
  selectedCode: QrCodeView;
  qrCodeRef: RefObject<HTMLDivElement>[];
}

interface SS {
  id: string;
}

class QrCodeController extends BlockComponent<Props, S, SS> {
  qrCodesMessageId: string = "";
  generateQrCodeMessageId: string = "";
  editQrCodeMessageId: string = "";
  deleteQrCodeItemId: number = 0;
  deleteQrCodeMessageId: string = "";
  
  formRef: RefObject<FormikProps<QrCodeView>>;
  qrCodeRef: RefObject<HTMLDivElement>[];

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.formRef = React.createRef();
    this.qrCodeRef = [];
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ActionMessageFromToaster),
    ];

    this.state = {
      qrCodes: [],
      showQrCodeDialog: false,
      selectedQrCodeId: 0,
      selectedCode: {
        code_type: "android",
        url: "",
        id: 0,
        updated_at: ""
      },
      qrCodeRef: Array.from({ length: 2 }, () => React.createRef())
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.initPage();
  }

  async componentDidUpdate(prevProps: {}) {
    if(prevProps !== this.props){
      if(this.props.isDialogOpen){
        this.showGenerateQrCodeDialog();
      }
    }
  }

  initPage = () => {
    this.readQrCodes();
  };

  readQrCodes = async () => {
    const qrCodesMessage = await generateRequestMessage(
      configJSON.qrCodesApiEndpoint,
      configJSON.getApiMethodType
    );
    this.qrCodesMessageId = qrCodesMessage.messageId;
    this.send(qrCodesMessage);
  };

  receive = (from: string, message: Message) => {
    if (message.id === getName(MessageEnum.ActionMessageFromToaster)) {
      const type = message.getData(
        getName(MessageEnum.ActionMessageFromToasterMessage)
      );
      if (type === "SAVECHANGES") {
        this.formRef.current && this.formRef.current.handleSubmit();
      }
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.messageQrCodesCall(message)
      this.messageGenerateQrCodeCall(message)
      this.messageEditQrCodeMessageId(message)
      this.messageDeleteQrCodeMessageId(message)

    }
  };

  messageQrCodesCall = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);
    if (this.qrCodesMessageId === apiRequestCallId) {
      if (checkResponseError(responseJson)) {
        this.props.hideLoader();
        return this.handleError(configJSON.qrCodeGenerateError, responseJson);
      }
      const qrCodes = (responseJson?.data || []).map((item: QrCodeResponse) => ({
        id: item.attributes.id,
        code_type: item.attributes.code_type,
        url: item.attributes.url,
        updated_at: item.attributes.updated_at,
      }));
      this.setState({ qrCodes, showQrCodeDialog: false });
    }
  };

  downloadQRCode = (svgNode: SVGSVGElement | null | undefined) => {
    console.log("SVG Node:", svgNode);
    if (!svgNode) {
      console.error("SVG node not found");
      return;
    }
    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(svgNode);
    const blob = new Blob([svgString], { type: "image/svg+xml" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "qrcode.svg";
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  messageGenerateQrCodeCall = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);
    if (this.generateQrCodeMessageId === apiRequestCallId) {
      if (checkResponseError(responseJson)) {
        this.props.hideLoader();
        return this.handleError(configJSON.qrCodeGenerateError, responseJson);
      }
      const qrCodes = [...this.state.qrCodes, responseJson.data.attributes];
      this.setState({ qrCodes, showQrCodeDialog: false });
      this.props.hideLoader();
      this.props.showHeaderBar({ type: "success", message: configJSON.QrCodeCreateMsg });
    }
  };

  messageEditQrCodeMessageId = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);
    if (this.editQrCodeMessageId === apiRequestCallId) {
      if (checkResponseError(responseJson)) {
        this.props.hideLoader();
        return this.handleError(configJSON.qrCodeEditError, responseJson);
      }
      const qrCodes = [...this.state.qrCodes];
      const item: QrCodeView =
        qrCodes.find((item) => item.id === this.state.selectedQrCodeId) || {} as QrCodeView;
      
      item.code_type = responseJson.data.attributes.code_type;
      item.url = responseJson.data.attributes.url;
      item.updated_at = responseJson.data.attributes.updated_at;

      this.setState({ qrCodes, showQrCodeDialog: false });
      this.props.hideLoader();
      this.props.showHeaderBar({ type: "success", message: configJSON.QrCodeUpdateMsg });
    }
  };

  messageDeleteQrCodeMessageId = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);
    if (this.deleteQrCodeMessageId === apiRequestCallId) {
      this.props.hideLoader();
      if (checkResponseError(responseJson)) {
        return this.handleError(configJSON.errorOnDelete, responseJson);
      }
      const qrCodes = [...this.state.qrCodes];
      const codeIndex = qrCodes.findIndex(
        (item) => item.id === this.deleteQrCodeItemId
      );
      qrCodes.splice(codeIndex, 1);
      this.setState({ qrCodes });
      this.props.showHeaderBar({ type: "success", message: configJSON.QrCodeDeleteMsg });
    }
  };

  handleError = (title: string, responseJson: errorsTypes) => {
    showError(
      title,
      responseJson,
      this.props.hideLoader,
      this.props.setDialogState
    );
  };

  hideLoader = () => {
    this.props.hideLoader();
  };
  

  showGenerateQrCodeDialog = () => {
    const InitialValues = {
      code_type: "android",
      url: "",
      id: 0,
      updated_at: ""
    };
    this.setState({
      showQrCodeDialog: true,
      selectedQrCodeId: 0,
      selectedCode: InitialValues
    });
  };
  
}
export default QrCodeController;
// Customizable Area End
