// Customizable Area Start
import React from "react";
import { ErrorMessage, Form, Formik, FormikProps } from "formik";
import { CheckboxInput } from "../../ss-cms-common-components/src/Inputs/Inputs.web";
import { Dialog } from "../../ss-cms-common-components/src/Dialog/Dialog";
import IntegrationActivateDialogController from "./IntegrationActivateDialogController.web";
import TextField from "@material-ui/core/TextField";
import lodashGet from "lodash/get";
import { useStyles } from "./styles";
import {
  IntegrationResponseTypes,
  IntegrationResponseWithTypeTypes,
} from "./OtherIntegrations.types";
import { QuestionMarkIcon } from "./assets"

const configJSON = require("./config.js");

export class IntegrationActivateDialog extends IntegrationActivateDialogController {
  renderMessage = (integrationConfig: { [key: string]: string }) => {
    return (
      <div>
        <div>
          {integrationConfig?.dialogMessage || configJSON.dialogMessage}
        </div>
        <div className="other-integration-how-to-use-container">
          <img
            src={QuestionMarkIcon}
            alt="question-circle"
            className="other-integration-question-circle-image"
          />
          <a
            className="other-integration-question-title"
            href={integrationConfig.howToUseLink}
            target="_blank"
          >
            {configJSON.howToUse}
          </a>
        </div>
      </div>
    );
  };

  render() {
    const { open, setOpen, selectedIntegration } = this.props;

    if (!selectedIntegration?.integrationName) {
      return null;
    }
    const configField = selectedIntegration?.integrationName;
    const integrationConfig = configJSON[configField];
    const initialValues = integrationConfig?.fields.reduce(
      (
        acc: { [key: string]: string | boolean | undefined },
        field: { key: keyof IntegrationResponseTypes; value: string }
      ) => {
        if (field.key) acc[field.key] = selectedIntegration[field.key];
        return acc;
      },
      {
        integrationType: selectedIntegration?.integrationName,
      }
    );

    return (
      <Dialog
        open={open}
        setOpen={setOpen}
        onSubmit={this.handleSubmitDialog}
        title={integrationConfig.dialogTitle}
        message={this.renderMessage(integrationConfig)}
        okay="Save"
        containerClassName="other-integrations-activate-dialog"
        messageClassName="other-integration-message-container"
        titleClassName="title"
      >
        <Formik<IntegrationResponseWithTypeTypes>
          innerRef={this.formRef}
          initialValues={initialValues}
          validationSchema={
            configField === "tawk"
              ? this.TawkToSchema
              : configField === "firebase"
              ? this.FirebaseSchema
              : this.KaleyraSchema
          }
          onSubmit={(values) =>
            this.handleSubmit(values, integrationConfig, selectedIntegration)
          }
          enableReinitialize
        >
          {(formikProps) => (
            <Form autoComplete="off" noValidate className="form" translate="no">
              {integrationConfig?.fields
                ?.filter((field: { hideAtForm: boolean }) => !field?.hideAtForm)
                ?.map(
                  (field: {
                    type: string;
                    key: string;
                    label: string;
                    required: boolean;
                  }) => {
                    if (field.type === "checkbox") {
                      return (
                        <CheckboxInput
                          key={field.key}
                          {...formikProps}
                          name={field.key}
                          label={field.label}
                        />
                      );
                    }

                    return (
                      <>
                        <TextInput
                          key={field.key}
                          label={field.label}
                          name={field.key}
                          required={field?.required}
                          {...formikProps}
                        />
                        <ErrorMessage name={field.key}>
                          {(message: string) => (
                            <div className="other-integration-error-helper">
                              {message?.replaceAll(field.key, field.label)}
                            </div>
                          )}
                        </ErrorMessage>
                      </>
                    );
                  }
                )}
            </Form>
          )}
        </Formik>
      </Dialog>
    );
  }
}

type DefaultProps = {
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  onTextChange?: () => void;
};

type TextInputProps = DefaultProps & {
  InputProps?: {
    classes: { [key: string]: string };
  };
  disabled?: boolean;
} & FormikProps<IntegrationResponseWithTypeTypes>;

export const TextInput = ({
  name,
  label,
  placeholder = label,
  required,
  values,
  handleChange,
  InputProps,
  disabled,
  ...props
}: TextInputProps) => {
  const classes = useStyles();

  return (
    <div>
      {label && (
        <span className={classes.inputLabelWrapper}>
          <span
            className={
              disabled ? classes.disabledInputLabel : classes.inputLabel
            }
          >
            {label + (required ? " *" : "")}
          </span>
        </span>
      )}
      <TextField
        disabled={disabled}
        variant="outlined"
        placeholder={placeholder}
        className={classes.fullWidth}
        InputProps={{
          classes: {
            input: classes.resizeable,
            ...(InputProps?.classes || {}),
          },
          ...(InputProps || {}),
        }}
        onChange={handleChange}
        name={name}
        value={lodashGet(values, name)}
        {...props}
      />
    </div>
  );
};
// Customizable Area End