// Customizable Area Start
import React from "react"
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import {generateRequestMessage} from "../../ss-cms-common-components/src/Utilities/Utilities";

const configJSON = require("./config");
export type Props = DialogProps &
  withLoaderProps &
  withHeadeActionBarProps & {
    id: string;
  };

interface S {
  primaryColor: string;
  brandSettingId: string;
  appName: string;
  androidAppLink: string;
  iosAppLink: string;
  errorMessageGoogle: string;
  errorMessageIos: string
}

interface SS {
  id: number;
}

export class AppSettingsFormController extends BlockComponent<Props, S, SS> {
  brandSettingsGetMessageId: string = "";
  brandSettingsPostMessageId: string = "";

  constructor(props: Props) {
    super(props);
    this.state = {
      primaryColor: "#000",
      brandSettingId: "",
      appName: "",
      androidAppLink: "",
      iosAppLink: "",
      errorMessageGoogle: "",
      errorMessageIos: ""
    };
    
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ActionMessageFromToaster),
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.getBrandSettings();
  }

  getBrandSettings = async () => {
    this.props.displaySpinner();
    const getBrandSettingsData = await generateRequestMessage(configJSON.appSettingApiEndpoint, configJSON.getApiMethodType);
    this.brandSettingsGetMessageId = getBrandSettingsData.messageId;
    runEngine.sendMessage(getBrandSettingsData.id, getBrandSettingsData);
  }

  postBrandSettings = async () => {
    this.props.displaySpinner();
    const brandData = {
      primary_app_color: this.state.primaryColor,
      deeplink_name: this.state.appName,
      android_app_url: this.state.androidAppLink,
      ios_app_url: this.state.iosAppLink,
    }
    const postBrandSettingsData = await generateRequestMessage(configJSON.appSettingApiEndpoint, configJSON.putApiMethodType);
    postBrandSettingsData.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(brandData)
    );
    this.brandSettingsPostMessageId = postBrandSettingsData.messageId;
    runEngine.sendMessage(postBrandSettingsData.id, postBrandSettingsData);
  }

  receive(from: string, message: Message): void {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.brandSettingsGetMessageId === apiRequestCallId) {
        this.props.hideLoader();
        this.setState({
          brandSettingId: responseJson.data.id,
          primaryColor: responseJson.data.attributes.primary_app_color,
          appName: responseJson.data.attributes.deeplink_name,
          androidAppLink: responseJson.data.attributes.android_app_url,
          iosAppLink: responseJson.data.attributes.ios_app_url,
        })
      }

      if (this.brandSettingsPostMessageId === apiRequestCallId) {
        this.props.hideLoader();
        this.props.showHeaderBar({ type: "success" });
      }
    } else if (getName(MessageEnum.ActionMessageFromToaster)) {
      const type = message.getData(
        getName(MessageEnum.ActionMessageFromToasterMessage)
      );
      if (type === "SAVECHANGES") {
        this.postBrandSettings();
      } else if (type === "DISCARDCHANGES") {
        this.getBrandSettings();
      }
    }
  }

  openToastOnChange = () => {
    this.props.showHeaderBar({ message: "" });
    return true;
  };

  handleCustomPrimaryChange = (color: string) => {
    this.setState({
      primaryColor: color
    });
    if(this.state.primaryColor !== color){
      this.openToastOnChange();
    }
  };

  handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    variableName: string,
    limit: number = 51
  ) => {
    if (event.target.value.length < limit) {
      this.setState({ [variableName]: event.target.value } as Pick<S, keyof S>);
      this.openToastOnChange();
    } else {
      return false;
    }
  };
  handleTextFieldChangeEmails = (
    event: React.ChangeEvent<HTMLInputElement>,
    variableName: string,
    limit: number = 51
  ) => {
    const { name } = event.target
    const emailRegex = /^(https?:\/\/)?([a-z0-9-]+\.)+[a-z]{2,}(\/\S*)?$/i;
  
    if (event.target.value.length < limit) {
      this.setState({ [variableName]: event.target.value } as Pick<S, keyof S>);
      if (emailRegex.test(event.target.value)) {
        if(name === "androidAppLink") {
          this.setState({errorMessageGoogle: ""})
        }
        if (name === "iosAppLink") {
          this.setState({errorMessageIos: ""})
        }
        this.openToastOnChange();
      } else if(name === "androidAppLink") {
        this.setState({ errorMessageGoogle: configJSON.errorMessage });
      } 
      else if (name === "iosAppLink") {
        this.setState({ errorMessageIos: configJSON.errorMessage })
      }
    } else {
      return false;
    }
  };
  
}
// Customizable Area End