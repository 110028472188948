// Customizable Area Start

// Customizable Area Start

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { CartItem } from "../../../../packages/blocks/shoppingcart/src/CartOrdersController.web";
import { Option } from "../../../../packages/blocks/shoppingcart/src/OrderConfirmationSummaryController.web";
import { AppMixpanel } from "../../../components/src/Mixpanel";

const configJSON = require("./config.js");

export type Props = {
  navigation: any;
  width: any;
};

interface CanvasDataType {
  data: {
    attributes: {}
  }
}

interface S {
  staticPages: StaticPage[];
  mobileOpen: boolean;
  searchDrawer: boolean;
  search: string;
  isSearch: boolean;
  searchData: any;
  isDetail: boolean;
  selectedProduct: any;
  openDrawer: boolean;
  categorieItems: any;
  isOrderSuccess: boolean;
  tempStore: number;
  orderedItem: CartItem[];
  addToCardButton: boolean;
  validationOptions:number[];
  isAddAction: boolean;
  isStoreClosed: boolean;
  canvasData: CanvasDataType | null,
  storename: string,
}

interface SS { }

export type StaticPage = {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    description: {
      content: string;
      questions?: {
        attributes: {
          title: string;
          content: string;
          status: boolean;
        };
      }[];
    };
    active: boolean;
    position: number;
    page_type: {
      id: number;
      name: string;
    };
    page_slug: string;
    created_at: string;
    updated_at: string;
  };
};

export default class StaticPageController extends BlockComponent<Props, S, SS> {
  getStaticPagesMessageId: string | undefined;
  getCurrencyTypeApiCallId: string | undefined;
  mixPanelLandingPageCallId: string | undefined;
  getCanvasDataCallId: string = ""
  getSearchApiCallId: any
  currencyType: string = ""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.setCurrencyType()
    this.state = {
      staticPages: [],
      mobileOpen: false,
      searchDrawer: false,
      search: '',
      isSearch: false,
      searchData: [],
      isDetail: false,
      selectedProduct: undefined,
      openDrawer: false,
      categorieItems: [],
      isOrderSuccess: false,
      tempStore: 0,
      orderedItem: [],
      addToCardButton: false,
      validationOptions: [],
      isAddAction: false,
      isStoreClosed: false,
      canvasData: null,
      storename: '',
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getStaticPages();
    this.getCurrencyType();
    this.getCartItems();
    this.getCanvasData();
  }

  getCanvasData = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCanvasDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.designApiEndPoint
    );
  

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCartItems = async () => {
    let data = JSON.parse(await getStorageData('count'));
    if(data) this.setState({ orderedItem: data })
  }

  countItem = () => {
    let count = 0
    this.state.orderedItem.forEach(element => {
      count += element.count
    });
    return count
  }

  navigateToStatic = (staticPage: StaticPage) => {
    this.props.navigation.navigate("StaticPageDetail", { id: staticPage.id });
    this.setState({
      mobileOpen: false
    })
    window.scrollTo(0, 0)
  };

  handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.props.navigation.navigate("StaticPageDetail", {
      id: event.target.value,
    });
  };

  onPressOrder() {
    setStorageData("count", JSON.stringify(this.state.orderedItem))
    const navigateCartOrders: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigateCartOrders.addData(getName(MessageEnum.NavigationTargetMessage), "CartOrders");
    navigateCartOrders.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateCartOrders);
    let body: any = document.querySelector(".hide-scroll");
    if (body) body.style.overflow = "hidden";
  }

  variantSelect = ( selectedProduct: CartItem,option: Option, isChecked:boolean,variantIndex: number, optionIndex:number,parentName: string) =>{
    const { price,option_name } = option.data.attributes;
    let currectProductIndex = this.state.orderedItem?.length - 1
    let newItemOrdered =[...this.state.orderedItem]
    if( !this.state.isStoreClosed && selectedProduct && selectedProduct?.attributes && selectedProduct?.attributes?.availability && selectedProduct?.attributes?.availability !== "out_of_stock"){
    let currentVariantList = this.state.orderedItem[currectProductIndex].variants
    if(isChecked){
      newItemOrdered[currectProductIndex].total =Number(Number(this.state.tempStore + price).toFixed(2))
      const currentParentIndex = currentVariantList.findIndex((item: { name: string, option: Array<string> }) => item.name === parentName)
      if(currentParentIndex < 0){
        currentVariantList.push({ name: parentName,option: [option_name] })
      } else{
        currentVariantList[currentParentIndex].option.push(option_name)
      }
      newItemOrdered[currectProductIndex].variants = currentVariantList
      this.setState((prevState) => ({ 
        tempStore: Number(Number(prevState.tempStore + price).toFixed(2)),
        orderedItem: newItemOrdered,
        selectedProduct:newItemOrdered[currectProductIndex],
        addToCardButton: true,
      }));
    } else{
      newItemOrdered[currectProductIndex].total = Number(Number(this.state.tempStore - price).toFixed(2))
      const currentParentIndex = currentVariantList.findIndex((item: {name: string, option: Array<string>}) => item.name === parentName)
      const newOption = [...currentVariantList[currentParentIndex].option]
      currentVariantList[currentParentIndex].option = newOption.filter(o => o !== option_name)
      newItemOrdered[currectProductIndex].variants =currentVariantList
      this.setState((prevState) => ({
        tempStore: Number(Number(prevState.tempStore - price).toFixed(2)),
        selectedProduct:newItemOrdered[currectProductIndex],
        orderedItem: newItemOrdered,
      }));
    }
  }
  }

  mixPanelEventHit = async (event_name:string) => {
    if(event_name != ""){
      AppMixpanel.track(event_name);
      }
   }

  handleAddToCart = async() =>{
    if(this.state.selectedProduct && this.state.selectedProduct.attributes.variants.length > 0) {
      this.setState({ validationOptions:[]},() => {
        let inValidVariants = this.checkValidation();
        if(inValidVariants.length> 0 ) {
          this.setState({validationOptions:inValidVariants})
          return false;
        } else {
          this.setDataIntoStore();
        }
      })
    } else{
      this.setDataIntoStore();
    }
  }

  openItemDetails = (categorieItem: CartItem) => {

   this.setState({
    selectedProduct: categorieItem,
    openDrawer: false,
    isDetail: true,
    search: "",
    isSearch: false,
    searchData: [],
    tempStore: categorieItem.attributes?.after_discount ?? categorieItem.attributes?.price ?? 0,
    isAddAction:true,
  })
    if( !this.state.isStoreClosed && categorieItem.attributes && categorieItem.attributes.availability &&  categorieItem.attributes.availability !== "out_of_stock"){
      this.setState({addToCardButton:true})
      this.handleIncrement(categorieItem)
    }
  }

  setCloseDetail = () => {
    if(this.state.selectedProduct && this.state.isAddAction && !this.state.isStoreClosed) {
      let listItem = [...this.state.orderedItem]
      let addItem = listItem.map((el:CartItem) => el.id).lastIndexOf(this.state.selectedProduct.id)
      if (listItem[addItem]?.count !== 1){
        listItem[addItem] = { ...listItem[addItem], count: listItem[addItem]?.count - 1 }
        this.setState({orderedItem: listItem, isDetail: false})
      } else {
        listItem.splice(addItem, 1)
        this.setState({ orderedItem: listItem, isDetail:false })
      }
    }
    this.setState({ isDetail:false, selectedProduct: undefined, tempStore: 0, validationOptions: [], isAddAction: false,addToCardButton:false })
  }
  setDataIntoStore = () => {
    setStorageData("count", JSON.stringify(this.state.orderedItem));
    this.setState({ isDetail: false, selectedProduct: undefined, tempStore: 0, validationOptions:[], isAddAction:false,addToCardButton: false })
  }

  handleIncrement = (categorieItem: CartItem) =>{
    let listItem = [...this.state.orderedItem]
    if (categorieItem?.attributes?.variants?.length !== 0){
      listItem.push({ ...categorieItem, count: 1, total:categorieItem.attributes.after_discount ?? categorieItem.attributes.price, variants: [] })
    } else {
      const currentItemIndex = listItem.findIndex((item) => item.id === categorieItem.id)
      if (currentItemIndex >= 0){
        listItem[currentItemIndex].count += 1
      } else {
        listItem.push({ ...categorieItem, count: 1, total: categorieItem.attributes.after_discount ?? categorieItem.attributes.price, variants: [] })
      }
      setStorageData("count", JSON.stringify(listItem))
    }
    this.setState({ orderedItem: listItem })
  };

  checkValidation=()=>{
    let inValidVariants=[]
    if(this.state.selectedProduct){ 
    for(let variant of this.state.selectedProduct.attributes.variants) {
      const currentChosenVariant =  (this.state.selectedProduct.variants || []).find((chosen: {name: string}) => chosen.name === variant.title);
      if(((currentChosenVariant && currentChosenVariant.option.length<variant.min && variant.min> 0)  || (currentChosenVariant && currentChosenVariant.option.length>variant.max && variant.max > 0))){
        inValidVariants.push(variant.id);
      }
      if(!currentChosenVariant && variant.min> 0) {
        inValidVariants.push(variant.id);
      }
    }
  }
    return inValidVariants
  }

  variantRadio = (selectedProduct:CartItem, option: Option,isAdd:boolean, currentPrice:number, variantIndex: number, optionIndex: number, parentName: string) => {
    const {price, option_name} = option.data.attributes
    let currectProductIndex =this.state.orderedItem?.length - 1
    let newItemOrdered =[...this.state.orderedItem]
    if(!this.state.isStoreClosed && selectedProduct && selectedProduct.attributes && selectedProduct.attributes.availability &&  selectedProduct.attributes.availability !== "out_of_stock"){
    let currentVariantList = this.state.orderedItem[currectProductIndex].variants
    if (isAdd){
      newItemOrdered[currectProductIndex].total =Number(Number(this.state.tempStore - currentPrice + price).toFixed(2))
      const currentParentIndex = currentVariantList.findIndex((item: {name: string, option: Array<string>}) => item.name === parentName)
      if (currentParentIndex < 0){
        currentVariantList.push({ name: parentName, option: [option_name] })
      } else{
        currentVariantList[currentParentIndex].option = [option_name]
      }
      newItemOrdered[currectProductIndex].variants = currentVariantList
      this.setState((prevState) => ({ 
        tempStore: Number(Number(this.state.tempStore - currentPrice + price).toFixed(2)),
        orderedItem: newItemOrdered,
        addToCardButton: true,
        selectedProduct: newItemOrdered[currectProductIndex]
      }));
    } else { 
      newItemOrdered[currectProductIndex].total = Number(Number(this.state.tempStore - currentPrice + price).toFixed(2))
      const currentParentIndex = currentVariantList.findIndex((item: {name: string, option: Array<string>}) => item.name === parentName)
      currentVariantList[currentParentIndex].option =[]
      newItemOrdered[currectProductIndex].variants = currentVariantList
      this.setState((prevState) =>({ 
        tempStore: Number(Number(this.state.tempStore - price).toFixed(2)),
        selectedProduct: newItemOrdered[currectProductIndex],
        orderedItem: newItemOrdered,
      }))
    }
  }
  }

  setCurrencyType = async () => {
    this.currencyType = await getStorageData("currency_type") || "$"
  }

  onCloseSuccessOrder() {
    this.setState({ isOrderSuccess: false })
    let body: any = document.querySelector(".hide-scroll");
    if (body) body.style.overflow = "auto";
  }

  receive = async (from: string, message: Message) => {
    const apiRequestCallID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getStaticPagesMessageId === apiRequestCallID
    ) {
      if (responseJSON?.data) {
        this.setState({
          staticPages: responseJSON.data.filter(
            (staticPage: StaticPage) => staticPage.attributes.active
          ),
          isStoreClosed: !responseJSON?.meta?.online_ordering,
          storename: responseJSON?.meta?.store_name
        });
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getSearchApiCallId === apiRequestCallID
    ) {
      if (responseJSON) {
        this.searchDataSuccess(responseJSON)
      }
    }
    else if (apiRequestCallID === this.getCurrencyTypeApiCallId) {
      if (responseJSON.currency_symbol) {
        setStorageData("currency_type", responseJSON.currency_symbol);
      } else {
        setStorageData("currency_type", "$");
      }
    }
    if(apiRequestCallID === this.getCanvasDataCallId){
      this.setState({canvasData: responseJSON.data.attributes})
    }
  }; 

  getCurrencyType = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCurrencyTypeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCurrencyEndPoints
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getStaticPages = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getStaticPagesMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStaticPagesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  searchDataSuccess = (responseJSON: any) => {
    this.setState({ searchData: responseJSON.data })
  };

  handleToggle = () => {
    this.setState({
      mobileOpen: !this.state.mobileOpen,
    });
  };

  handleCloseDrawer = () => {
    this.setState({
      openDrawer: false,
      search: ""
    })
  };

  handleOpenDrawer = () => {
    this.setState({
      openDrawer: true
    })
  };

  handleClose = () => {
    this.setState({
      mobileOpen: false,
    });
  };

  searchApi = async (text: any) => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSearchApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/catalogues/search_product?search=${text}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.getSearchApiCallId;
  }

  searchItem = (text: any) => {
    this.setState({ search: text })
    if (text && text.trim()) {
      this.setState({ isSearch: true })
      this.searchApi(text)
      return
    }
    this.setState({ searchData: [], isSearch: false })
  }
}
// Customizable Area End

// Customizable Area End
