// Customizable Area Start
// @ts-nocheck
import * as React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom"; 
import { EventEmitter } from 'eventemitter3';
import Nav from "../Nav";
import FadeInDropdown from "../Dropdown/FadeInDropdown/FadeInDropdown";
import { RoundIconBadge } from "../Badge/RoundIconBadge/RoundIconBadge";
import { Scrollbars } from "react-custom-scrollbars";
import { Button } from "@material-ui/core";
import RefreshIcon from '@material-ui/icons/Refresh';
import CircularProgressBar from "../Charts/CircularProgressBar";
import "./assets/css/style.css";
const Logo: any = require("./assets/images/temp/logo.png");
import { getStorageData, setStorageData } from "../../../../framework/src/Utilities";
import { Message } from "../../../../framework/src/Message"
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
export const configJSON = require("./config");
export const emitter = new EventEmitter();

interface LayoutProps {
  children: any;
  hideLayout: boolean
}

export const Layout: React.FunctionComponent<LayoutProps> = (props) => {
  const [hideNav, setHideNav] = useState(false);
  const [adminUser, setAdminUser] = useState({});
  const [progress, setProgress] = useState(0);
  const [noti, setNoti] = useState(0);
  const [storeName, setStoreName] = useState("Store Name");
  const [lastRefresh, setLastRefresh] = useState(null);

  const getCachedValues = async () => {
    setStoreName(await getStorageData("store_name") || "Store Name")
    setAdminUser(JSON.parse((await getStorageData("adminuser") || "{}")));
    const progress = await getStorageData("progress");
    setProgress(progress ? JSON.parse(progress) : 0);
  };

  const handleRefresh = () => {
    const currentTime = new Date();
    const options = { day: '2-digit', month: 'long' };
    const formattedDate = currentTime.toLocaleDateString('en-US', options);
    const formattedTime = currentTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
    const lastRefreshString = `Last updated on ${formattedDate.split(' ')[1]} ${formattedDate.split(' ')[0]} at ${formattedTime}`;
    setLastRefresh(lastRefreshString); 
    setStorageData("lastRefresh", lastRefreshString);
    window.location.reload(true);
  };

  const getLastRefreshDateTime = async() => {
    const storedDateTime = await getStorageData('lastRefresh');
    if (storedDateTime) {
      setLastRefresh(storedDateTime);
    }
  };

  useEffect(() => {
    getCachedValues();
    handleOpenDrawer();
    getLastRefreshDateTime();
    async function checkStorageData() {
      const admin = JSON.parse(await getStorageData("adminuser"));
      const progressData = await getStorageData("progress");

      if (admin) {
        setAdminUser(admin);
      }
      if (progressData) {
        setProgress(progressData);
      }
    }
    window.addEventListener("progressInserted", checkStorageData);
    window.addEventListener("resize", handleOpenDrawer)
    
    return () => {
      window.removeEventListener("resize", handleOpenDrawer)
      window.removeEventListener("progressInserted", checkStorageData);
    };

    
  }, []);

  const handleOpenDrawer = () => {
    if (window.document.body.clientWidth < 700) {
      setHideNav(true)
    } else {
      setHideNav(false)
    }
  }

  useSub('changeNotiNumber', (data) => {
    setNoti(data)
  });

  const newOrderNotification = (isVisible: boolean, className: string) => {
    if(isVisible) {
      return (
        <Link to="/Orders">
          <div
            style={webStyle.flex}
            className={className}
          >
            <span style={webStyle.count}>{noti}</span>
            <div style={webStyle.lastRefreshDiv}>
              <span style={webStyle.notiText}>{configJSON.notiText}</span>
              <div>
                  {lastRefresh && <span style={webStyle.lastRefresh}>{lastRefresh}</span>}
              </div>
            </div>
            <Button onClick={handleRefresh}
            style={webStyle.refreshButton}
            >
              <RefreshIcon style={webStyle.refreshIcon}/>
            </Button>
          </div>
        </Link>
      )
    } else return <></>
  }

  const { children, hideLayout } = props;
  if (hideLayout) {
    return <div className="admin-layout__content">{children}</div>;
  }
  let menuList = [
    {
      label: "Managing Orders",
      iconEl: <RoundIconBadge iconName="orders" />,
      externalUrl:
        "https://intercom.help/engineerai/en/articles/6475072-managing-orders",
    },
    {
      label: "Setting and Managing inventory",
      iconEl: <RoundIconBadge iconName="list" />,
      externalUrl:
        "https://intercom.help/engineerai/en/articles/6475081-setting-and-managing-inventory",
    },
    {
      label: "Setting up store and emails",
      iconEl: <RoundIconBadge iconName="wallet" />,
      externalUrl:
        "https://intercom.help/engineerai/en/articles/6475108-setting-up-store-and-emails",
    },
    {
      label: "Configuring business settings",
      iconEl: <RoundIconBadge iconName="settings" />,
      externalUrl:
        "https://intercom.help/engineerai/en/articles/6475115-configuring-business-settings",
    },
    {
      label: "Payments and Shipping",
      iconEl: <RoundIconBadge iconName="products" />,
      externalUrl:
        "https://intercom.help/engineerai/en/articles/6475121-payments-and-shipping",
    },
  ];
  let profileList = [
    {
      label: "Account Overview",
      iconName: "user",
      internalUrl: "/AdminAccount",
    },
    {
      label: "Logout",
      showRightArrow: false,
      iconName: "logout",
    },
  ];
  return (
    <div className={`admin-layout ${hideNav ? "admin-layout--hide-nav" : ""}`}>
      <div className="admin-layout__sidebar">
        <div className="admin-layout__brand-logo">
          <img className="img-fluid" src={Logo} alt="logo" />
        </div>
        <Scrollbars>
          <div className="admin-layout__link-container">
            <Nav />
          </div>
        </Scrollbars>
      </div>
      <div className="admin-layout__main">
        <div className="admin-layout__nav">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <a
                href="#"
                className="admin-layout__hamburger"
                onClick={(e) => {
                  e.preventDefault();
                  setHideNav(!hideNav);
                }}
              >
                <i className="icon-menu f-18"></i>
              </a>
              <span className="f-14 fw-normal text-capitalize" style={{ marginLeft: "4px"}}>{storeName}</span>
            </div>
            <div className="d-flex align-items-center" >
              {newOrderNotification(noti > 0, "order-notification-view-desktop")}
              <FadeInDropdown
                className="me-10"
                menuClassName="setup-guide-menu"
                menuLists={menuList}
                label="Setup guide"
                badgeEl={<CircularProgressBar percentage={progress} />}
                menuLabel={"Setup guide"}
              />
              <FadeInDropdown
                menuLists={profileList}
                label={`Hi, ${adminUser?.data?.attributes?.name || "Admin User"}`}
                menuHeader={
                  <div className="">
                    <h6 className="f-14 mb-0 text-blue-grey-10">{`${adminUser
                      ?.data?.attributes?.name || "Admin User"}`}</h6>
                    <p className="f-10 mb-0 text-blue-grey-8">{`${adminUser?.data
                      ?.attributes?.email || "Admin@User.com"}`}</p>
                  </div> 
                }
                badgeName="sj"
                menuLabel={"Setup guide"}
              />
            </div>
          </div>
          {newOrderNotification(noti > 0, "order-notification-view-mobile")}
        </div>
        <div className="admin-layout__content">{children}</div>
      </div>
    </div>
  );
  // Customizable Area End
};

export const useSub = (event: string, callback: (value: number) => void) => {
  const unsubscribe = () => {
    emitter.off(event, callback);
  };

  useEffect(() => {
    emitter.on(event, callback);
    return unsubscribe;
  }, []);

  return unsubscribe;
};

const webStyle = {
  flex: {
    backgroundColor: "#FEF3C7",
    gap: "8px",
    borderRadius: "100px",
    marginRight: "16px",
    alignItems: "center",
  } as React.CSSProperties,
  lastRefreshDiv: {
    display: "flex",
    flexDirection: "column"
  },
  lastRefresh: {
    fontSize: "10px",
    color: "#000000",
    fontFamily: "Rubik",
  },
  count: {
    padding: "7px 16px",
    color: "#fff",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "18.96px",
    borderRadius: "16px",
    backgroundColor: "#F59E0B",
  } as React.CSSProperties,
  notiText: {
    fontFamily: "Rubik",
    color: "#334155",
    fontSize: "14px",
    fontWeight: 500,
  } as React.CSSProperties,
  refreshButton: {
    display: "flex",
    alignItems: "center",
    borderRadius: "50px"
  } as React.CSSProperties,
  refreshIcon: {
    color: "#0F172A",
    marginRight: "5px"
  } as React.CSSProperties,
}
// Customizable Area End
