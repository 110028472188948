// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouteComponentProps } from "react-router-dom";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withLoader.Web";
import { withToastProps } from "../../ss-cms-common-components/src/HOC/withSnackBar.Web";
import { styles } from "./Login.web";
import { WithStyles, WithTheme } from "@material-ui/core";
import { getResponseVariables } from "../../ss-cms-common-components/src/GetResponseVariables/GetResponseVariables";
import { FormikErrors, FormikTouched } from "formik";
import {
  generateRequestMessage,
  getError,
} from "../../ss-cms-common-components/src/Utilities/Utilities";
import {
  setStorageData,
  getStorageData,
  removeStorageData
} from "../../../framework/src/Utilities";
import * as Yup from "yup";

// Customizable Area End

// Customizable Area Start
interface LoginResponseSuccessType {
  token: string;
  user: {
    data: {
      id: string;
      type: string;
      attributes: {
        id: number;
        email: string;
        account_type: string;
        permissions?: string[];
      };
    };
  };
  permissions?: string[];
  permission?: string[];
  role: string;
}

// TODO LINKS RENEW TO NEW FORMAT
const links = [
  {
    id: "home",
    link: "Home",
  },
  {
    id: "image",
    link: "AdminImageCatalogue",
  },
  {
    id: "email setting",
    link: "AdminEmailTemplates",
  },
  {
    id: "static",
    link: "AdminStaticPage",
  },
  {
    id: "coustomer",
    link: "AdminCustomerFeedback",
  },
  {
    id: "store",
    link: "AdminStoreDetails",
  },
  {
    id: "tax",
    link: "AdminTaxes",
  },
  {
    id: "shipping",
    link: "AdminShipping",
  },
  {
    id: "payment",
    link: "AdminPaymentIntegration",
  },
  {
    id: "coupon",
    link: "AdminPromoCode",
  },
  {
    id: "notifications",
    link: "AdminNotifications",
  },
  {
    id: "mobile",
    link: "AdminMobileApp",
  },
  {
    id: "accountoverview",
    link: "AdminAccount",
  },
  {
    id: "profile",
    link: "AdminAccount",
  },
  {
    id: "adminUsers",
    link: "AdminAccount",
  },
];

export const configJSON = require("./config");

export const Schema = Yup.object().shape({
  email: Yup.string()
    .email(configJSON.textInvalidEmailFormat)
    .required(configJSON.textThisFieldIsRequired),
  password: Yup.string().required(configJSON.textThisFieldIsRequired),
});

export type Props = RouteComponentProps<{}, {}, { currentPathState: unknown }> &
  withToastProps &
  withLoaderProps &
  WithStyles<typeof styles> &
  WithTheme & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  token?: string;
  storeName: string;
  isRemember: boolean
  email: string;
  password: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export type LoginDataType = {
  email: string;
  password: string;
  isRemember: boolean
};
// Customizable Area End

export default class LoginController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  loginAPICallID: string = "";
  invalidPasswordError: string = "";
  invalidEmailError: string = "";
  getStoreDetailsMessageId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];

    this.state = {
      storeName: "",
      isRemember: false,
      email: "",
      password: ""
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getStoreName();
    this.getStoreDetails();
  }

  getStoreName = async () => {
    const isRemember = await getStorageData("isRemember")
    const store_name = await getStorageData("store_name")
    const email = await getStorageData("email")
    const password = await getStorageData("password")
    this.setState({
      storeName: store_name || "",
      email: email || "",
      password: password || "",
      isRemember: isRemember === "true",
    })
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId != null) {
        this.messageLoginAPICall(apiRequestCallId, responseJson);
        this.handleGetStoreDetailsAPICall(message);
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      if (AlertBodyMessage === configJSON.responseErrorUserNotFound) {
        this.invalidEmailError = AlertBodyMessage;
        this.forceUpdate();
        return;
      }
      if (AlertBodyMessage === configJSON.responseErrorInvalidPassword) {
        this.invalidPasswordError = AlertBodyMessage;
        this.forceUpdate();
        return;
      }
      this.props.showToast({
        type: "error",
        message: AlertBodyMessage,
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleInvalidPasswordError = (
    errors: FormikErrors<LoginDataType>,
    touched: FormikTouched<LoginDataType>
  ) => {
    let passwordError: string;
    if (this.invalidPasswordError) {
      passwordError = configJSON.responseErrorInvalidPassword;
    } else if (errors?.password && touched?.password) {
      passwordError = errors?.password;
    } else {
      passwordError = "";
    }
    return passwordError;
  };

  handleInvalidEmailError = (
    errors: FormikErrors<LoginDataType>,
    touched: FormikTouched<LoginDataType>
  ) => {
    let emailError: string;
    if (this.invalidEmailError) {
      emailError = configJSON.textErrorInvalidEmail;
    } else if (errors?.email && touched?.email) {
      emailError = errors?.email;
    } else {
      emailError = "";
    }
    return emailError;
  };

  messageLoginAPICall = async (
    apiRequestCallId: string,
    responseJson: LoginResponseSuccessType
  ) => {
    if (apiRequestCallId === this.loginAPICallID) {
      this.props.hideLoader();
      if (responseJson && responseJson.token) {
        const token = responseJson?.token;
        const adminUser = responseJson?.user;
        adminUser.data.attributes.permissions = responseJson.permission ||
          responseJson.permissions || ["all"];
        if (token) {
          await setStorageData("admintoken", token);
          await setStorageData("adminuser", JSON.stringify(adminUser));
          await setStorageData(
            "account_type",
            JSON.stringify(adminUser.data?.attributes?.account_type) || ""
          );
          if (this.state.isRemember) {
            setStorageData("email", this.state.email);
            setStorageData("password", this.state.password);
            setStorageData("isRemember", JSON.stringify(this.state.isRemember));
          } else {
            removeStorageData("email");
            removeStorageData("password");
            setStorageData("isRemember", "false");
          }
          const adminuser = await getStorageData("adminuser");
          const permissions = this.getPermissions(adminuser);
          const filteredLinks = this.getFilteredLinks(permissions);

          this.pushRelatedPage(permissions, filteredLinks);
        }
      } else {
        this.props.showToast({
          type: "error",
          message: "Error! Invalid password",
        });
      }
    }
  };

  //Login Form
  handleSubmitLogin = (loginData: LoginDataType) => {
    this.props.showLoader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.loginAPICallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );
    const headers = {
      "Content-Type": "application/json",
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      // Login property used on backend instead only email
      JSON.stringify({ login: loginData.email, password: loginData.password })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getStoreDetails = async () => {
    const message = await generateRequestMessage(
      configJSON.storeDetailsAPiEndPoint,
      "GET"
    );
    this.getStoreDetailsMessageId = message.messageId;
    this.send(message);
  };

  handleGetStoreDetailsAPICall = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);

    if (this.getStoreDetailsMessageId === apiRequestCallId) {
      this.setStorageValues(message);
    }
  };

  goToForgotPassword = () => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountForgotPassword"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(to);
  };

  private pushRelatedPage(
    permissions: string[],
    filteredLinks: { id: string; link: string }[]
  ) {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      permissions?.includes("all") || permissions?.includes("order")
        ? "Home"
        : filteredLinks[0]?.link || "Home"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(to);
  }

  private getFilteredLinks(permissions: string[]) {
    return links.filter((link) => {
      return permissions?.includes(link.id);
    });
  }

  private getPermissions(adminuser: string | null) {
    return adminuser
      ? JSON.parse(adminuser)?.data?.attributes?.permissions
      : [];
  }

  private setStorageValues(message: Message) {
    const [responseJson] = getResponseVariables(message);
    const storeName = responseJson.data?.attributes?.name;
    this.setState({ storeName });
    setStorageData(
      "store_name",
      responseJson.data?.attributes?.name || configJSON.textDefaultStoreName
    );

    setStorageData(
      "currency_type",
      responseJson.data?.attributes?.currency_symbol
    );

    setStorageData(
      "store_details",
      JSON.stringify(responseJson.data?.attributes)
    );
  }
  // Customizable Area End
}
