// Customizable Area Start
import React, { useRef } from "react";
import NotificationsController from "./NotificationsController.web";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import TableComponent from "../../ss-cms-common-components/src/TableComponent/TableComponent";
import { Dialog } from "../../ss-cms-common-components/src/Dialog/Dialog";
import "./styles.css";
import TextField from "@material-ui/core/TextField";
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import Pagination from "../../ss-cms-common-components/src/Pagination/Pagination";
import { Scrollbars } from "react-custom-scrollbars";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import {
  NoProductCardProps,
  HeaderProps,
  NotificationType,
} from "./Types";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout"
import CreateDialog from "./CreateDialog.web";

const configJSON = require("./config");
const titles = [configJSON.textTableHeaderId, configJSON.textTableHeaderTitle, configJSON.textTableHeaderMessage];

export class Notifications extends NotificationsController {
  render() {
    const {
      allNotifications,
      showNotificationCreate,
      totalCount,
      pageInitialized,
      checkedNotificationList,
    } = this.state;

    if (!pageInitialized) {
      return null;
    }

    return (
      <Scrollbars ref={this.scrollRef}>
        <div className="business-notifications">
          {!allNotifications.length ? (
            <NoProductCard onClick={this.handleCreateClick} />
          ) : (
            <>
              <Header
                onClick={this.handleCreateClick}
                checkedNotificationList={checkedNotificationList}
                handleDeleteModal={this.handleDeleteModal}
              />

              <div className="notifications">
                <TableComponent<NotificationType>
                  titles={titles}
                  data={allNotifications}
                  edit
                  push
                  handleEditClick={(item) => this.handleEditClick(item)}
                  handlePushClick={(item) => this.handlePushClick(item)}
                  getCheckedItems={(item) => this.checkedItems(item)}
                  checkbox
                />
                <div className="pagination-wrapper">
                  <Pagination
                    count={totalCount}
                    onChange={this.handlePageChange}
                    itemText={configJSON.textPaginationItemText}
                    pageSize={this.state.per_page}
                  />
                </div>
              </div>
            </>
          )}
          {showNotificationCreate && (
            <CreateDialog
              setOpen={this.setOpen}
              onCreate={
                this.state.isEditClicked
                  ? this.handleEditNotification
                  : this.handleCreateNotification
              }
              editClicked={this.state.isEditClicked}
              notificationItem={this.state.notificationItem}
            />
          )}
        </div>
      </Scrollbars>
    );
  }
}

const Header = ({
  children,
  onClick,
  checkedNotificationList,
  handleDeleteModal,
}: HeaderProps) => {
  return (
    <div className="header">
      <div className="header-title-wrapper">
        <section className="d-flex align-items-center">
          <span className="header-title">{configJSON.notificationsHeader}</span>
          {checkedNotificationList?.length > 0 && (
            <p className="delete-items" data-testid="notifications-bulk-delete-button" onClick={handleDeleteModal}>
              {configJSON.deleteNotifications}
            </p>
          )}
        </section>

        <div className="header-buttons">
          <button className="create-button" onClick={onClick}>
            {configJSON.createNewNotification}
          </button>
        </div>
      </div>
      {children}
    </div>
  );
};

const NoProductCard = ({ onClick }: NoProductCardProps) => {
  return (
    <div className="no-product-card">
      <div className="no-product-card-content">
        <span className="no-product-card-title">
          {configJSON.youHaveNoNotifications}
        </span>
        <span className="no-product-card-hint">
          {configJSON.youHaveNotSetUpNotificationMessages}
        </span>
        <span className="no-product-card-hint">
          {configJSON.notificationMessage}
        </span>
        <button onClick={onClick} className="no-product-button">
          {configJSON.createNewNotification}
        </button>
      </div>
    </div>
  );
};

export default withLayout(withLoader(withDialog(withHeaderBar(Notifications))));
// Customizable Area End