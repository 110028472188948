import React from "react";

import {
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import WebsiteBuilderThemeController, {
    Props,
  } from "./WebsiteBuilderThemeController"
// Customizable Area End


export class WebsiteBuilderTheme extends WebsiteBuilderThemeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <></>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default WebsiteBuilderTheme;
// Customizable Area End
