// Customizable Area Start
import React from "react";
import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  withWidth,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import CustomerListWebController, {
    Props, customerObject,
  } from "./CustomerListWebController";
import withStyles, { CSSProperties } from "@material-ui/core/styles/withStyles";
import Scrollbars from "react-custom-scrollbars";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout";
import SectionLoader from "../../inventorymanagement/src/SectionLoader.web";
import { editIcon, noCustomerIcon, nocategories, searchIcon } from "./assets";
import PaginationComponent from '../../ss-cms-common-components/src/Pagination/Pagination'
import AlertMessage from "../../../components/src/AlertMessage.web";
import  CustomerEdit  from "./CustomerEdit.web";


// Customizable Area End

export class CustomerList extends CustomerListWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderScreen(classes: Record<string, string>) {
    if(this.state.custormerList === null){
      return this.noCustomerBlock(classes)
    }
    return this.CustomerBlock(classes);
  }

  noCustomerBlock = (classes:Record<string, string>) =>{
    return (
      <Grid
        item
        lg={12}
        md={12}
        xs={12}
        style={{ display: "flex", justifyContent: "center", marginTop: "143px" }}
      >
        <Box className={classes.noCustomerBox} >
          <Grid item className={classes.noCustomerContent}>
            <Typography style={{ marginBottom: "20px" }}>
              <img src={noCustomerIcon} alt="no products" />
            </Typography>
            <Typography
              data-test-id="noorders"
              variant="h5"
              component="h2"
              className={classes.noCustomerTextTitle}
            >
              You have no customers
            </Typography>
            <Typography  className={classes.noCustomerText}>
            You don’t have any customers at the moment. They’ll start appearing here once you get orders in, but you can also manually add them below.
            </Typography>
          </Grid>
        </Box>
      </Grid>
    );
  }

  CustomerBlock = (classes: Record<string, string>) => {
    if(this.state.isEditCustomer){
      return <CustomerEdit data-test-id="customerEditBlock" id="editcustomer" goToBack={this.goToBack} customerData={this.state.customerData} />
    }
    return (
      <>
        {this.renderCustomerList(classes)}
      </>
    )

  };

  renderCustomerList = (classes: Record<string, string>) => {
    return (
      <Grid item style={{ marginTop: "40px" }}>
        {this.renderHeaderTitle(classes)}
        <Grid item lg={12} md={12} xs={12} className={classes.tableContainer}>
          {this.renderDesktopCustomersList(classes)}
        </Grid>
        {this.state.custormerList?.length  ? this.renderPagination() : ''}
        <div style={{ height: "30px" }} />
      </Grid>
    )
  }

  renderHeaderTitle = (classes: Record<string, string>) => {
    return (
      <div style={{ display: 'flex', flexDirection: "column" }}>
        <Grid
          item
          lg={12}
          md={12}
          xs={12}
          style={{ display: "flex" }}
        >
          <Grid item lg={12} md={12} xs={12}>
            <Box width='100%' display='flex' justifyContent='space-between' alignItems='flex-end'>
              <span
                data-test-id="title"
                className={`${classes.headerTitle}`}
              >
                Customers
              </span>
              {this.renderCSVButton(`${classes.csvDownloadBtn}`, 'pc')}
            </Box>
          </Grid>
        </Grid>
        <Grid lg={12} md={12} xs={12} className="order-listing-search">
          {/* filter  */}
          <Box width='100%' display='flex'>

            {/* search Input */}
            <Box className={classes.searchContainer}>
              <TextField
                data-test-id="searchOrderInput"
                className={`${classes.placeholderText} ${classes.SearchInputStyle} `}
                placeholder="Search customers"
                variant="standard"
                fullWidth
                value={this.state.searchText}
                onChange={this.updateSearchText}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={searchIcon}
                        alt="searchIcon"
                        style={{ paddingLeft: "15px" }}
                      />
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
              />
            </Box>
          </Box>
        </Grid>
      </div>
    )
  }

  renderCSVButton = (classes: string, testId: string) => {
    return (
      <button data-test-id={`download_csv_button_${testId}`} className={classes} onClick={this.downloadCsvCustomerFile}>Download CSV</button>
    )
  }

  renderDesktopCustomersList = (classes: Record<string, string>) => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.headerText} style={{ width: '22%' }}>Full name</TableCell>
            <TableCell className={classes.headerText} style={{ width: '22%' }}>Email</TableCell>
            <TableCell className={classes.headerText} style={{ width: '20%' }}>Number</TableCell>
            <TableCell className={classes.headerText} style={{ width: '20%' }}>Opt-in</TableCell>
            <TableCell className={`${classes.headerText}`} style={{ width: '6%' }} > Edit </TableCell>
          </TableRow>
        </TableHead>

        <TableBody style={{ background: "#fff" }}>
          {this.state.custormerList?.length && !this.state.tableInnerLoading ?
            <>
              {this.state.custormerList.map((customer: customerObject, index) => {
                return (
                  <TableRow style={{ border: "2px solid #ECEDF0" }} data-test-id="tablerows" key={index}>
                    <TableCell component="th" scope="row" className={classes.dataText} style={{ width: '22%' }}> {customer.attributes.full_name}</TableCell>
                    <TableCell component="th" scope="row" className={classes.dataText} style={{ width: '22%' }}> {customer.attributes.email}</TableCell>
                    <TableCell component="th" scope="row" className={classes.dataText} style={{ width: '20%' }}> {customer.attributes.phone_number} </TableCell>
                    <TableCell component="th" scope="row" style={{ width: '20%' }}>
                      {customer.attributes.recive_update ?
                        <div className={`${classes.chipContainerPositiv} ${classes.chipContainer}`}>
                          Yes
                        </div>
                        :
                        <div className={`${classes.chipContainerNagitiv} ${classes.chipContainer}`}>
                          No
                        </div>
                      }
                    </TableCell>
                    <TableCell component="th" scope="row" className={classes.dataText} style={{ width: '6%' }}  > <img src={editIcon} alt="editIcon" className={classes.editIcon} data-test-id={`editIcon${index}`} onClick={()=>this.editCustomer(customer)} /> </TableCell>
                  </TableRow>
                )
              })}
            </>
            :
            <>
              {this.state.tableInnerLoading ? (
                <TableRow style={{ background: '#F1F5F9', border: 'none' }}>
                  <TableCell colSpan={7} style={{ textAlign: 'center', border: 'none' }} >
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow style={{ border: "2px solid #ECEDF0" }}>
                  <TableCell component="th" scope="row" colSpan={8}>
                    {this.renderNoCustomerSearch(classes)}
                  </TableCell>
                </TableRow>
              )}
            </>
          }
        </TableBody>
      </Table>
    )
  }

  renderPagination = () => {
    let {totalCount,activePage,perPage} = this.state.paginationObj
    return (
      <Grid item lg={12} md={12} xs={12}>
        <PaginationComponent
          count={totalCount}
          pageSize={perPage}
          itemText={'customers'}
          paginationContainerStyle={{ padding: "0px" }}
          onChange={(page) => this.changePage(page) }
          pageNo={activePage}
          data-test-id="paginationEle"
        />
      </Grid>
    )
  }

  renderNoCustomerSearch = (classes:Record<string, string>)=>{
      return (
        <Grid
        item
        lg={12}
        md={12}
        xs={12}
        style={{ background: '#fff', margin: "10px 0px" }}
      >
        <Grid item className={classes.noCustomerContent}>
          <Typography style={{ marginBottom: "10px" }}>
            <img src={nocategories} alt="no orders" />
          </Typography>
          <span
            data-test-id="noResultsfound"
            style={{
              fontFamily: "Rubik",
              fontSize: "20px",
              fontWeight: "500",
              lineHeight: "26px",
              letterSpacing: "-0.02em",
              marginBottom: "5px",
              color: "#000000"
            }}
          >
            No results found
          </span>
          <span
            style={{
              fontFamily: "Rubik",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "22px",
              marginBottom: "10px",
              color: "#83889E",
            }}
          >
            Revise search text and try again
          </span>
        </Grid>
      </Grid>
      )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start

      <Scrollbars>
        <AlertMessage
          data-test-id="orderAlertMsg"
          open={this.state.openOrderAlert}
          success={this.state.alertOrderSuccess}
          message={this.state.alertOrderMessage}
          close={this.handleOrderAlertMessageClose}
        />
        <Grid container item lg={12} md={12} xs={12} className={classes.root}>
          <Grid item lg={11} md={11} xs={11}>
            {this.state.ScreenLoading ? (
              <SectionLoader loading={true} />
            ) : (
              this.renderScreen(classes)
            )}
          </Grid>
        </Grid>
      </Scrollbars>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const useStyles: Record<string, CSSProperties> = {
  root: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: " #f8f9fa",
    height: "100%",
  },
  headerTitle: {
    fontFamily: "Rubik",
    fontWeight: 500,
    color: "#3C3E49",
    fontSize: '28px',
    lineHeight: '32px',
    textAlign: 'center',
    width: 'auto',
    "@media (max-width: 550px)": {
      fontSize: '16px',
    }
  },
  placeholderText: {
    "& input, & textarea, & div": {
      fontFamily: "Rubik",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "22px",
      color: "#64748B",
    },
    "& fieldset": {
      top: "0px",
    },
  },
  searchContainer: {
    width: '100%',
    height: '41px',
    "@media (max-width: 550px)": {
      height: '42px',
    },
  },
  SearchInputStyle: {
    "& > div": {
      background: "#FFFFFF",
      height: '41px',
      padding: '0px',
      border: "1px solid #D0D2DA",
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
      "@media (max-width: 550px)": {
        height: '42px',
        borderRadius: '4px'
      },
    },
    "& input": {
      fontFamily: "Rubik",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "22px",
      "&::placeholder": {
        color: "#AFB2C0"
      },
    },
    "& fieldset": {
      top: "0px",
    },
  },
  csvDownloadBtn: {
    width: 'auto',
    height: '21px',
    fontFamily: 'Rubik',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#3C3E49',
    border: 'none',
    background: 'none',
    textDecoration: 'underline',
    marginBottom:'3px'
  },
 
  tableContainer: {
    minHeight: '50vh'
  },
  dataText: {
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    color: "#3C3E49",
  },
  chipContainer: {
    width: '46px',
    height: '20px',
    borderRadius: '32px',
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  chipContainerPositiv: {
    border: '1px solid #B3DAC7',
    background: '#EBF5F0',
    color: '#008243'
  },
  chipContainerNagitiv: {
    border: '1px solid #FFD7D7',
    background: '#FFECEC',
    color: '#E00000'
  },
  editIcon: {
    marginLeft: '4px',
    cursor: 'pointer'
  },
  noCustomerBox: {
    width : '528px',
    height:'288px',
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    background:'#fff',
  },
  noCustomerContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  noCustomerTextTitle: {
    marginBottom: "5px",
    fontFamily: "Rubik",
    fontSize: "26px",
    fontWeight: 500,
    lineHeight: "31px",
    color: "#363636",
  },
  noCustomerText: {
    fontSize: "14px",
    color: "#676B7E",
    maxWidth: "395px",
    width: "100%",
    fontWeight: 400
  },
};

const StyledComponent = withLayout(withStyles(useStyles)(CustomerList));
export default withWidth()(StyledComponent);

// Customizable Area End