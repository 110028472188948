import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");
import {
  isTokenExpired,
  clearStorageData,
} from "../../ss-cms-common-components/src/Utilities/Utilities";

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  category: string;
  categories: any;
  isEdit: boolean;
  isAddEdit: boolean;
  categoryId: any;
  categoryName: string;
  setTouched: boolean;
  loading: boolean;
  loadingSearch: boolean;
  errorText: string;
  searchCategory: string;
  noSearchCategories: boolean;
  openModal: boolean;
  openAlert: boolean;
  alertSuccess: boolean;
  alertMessage: any;
  openSubmit: boolean;
  pagination: Pagination;
  // Customizable Area End
}
// Customizable Area Start
import { emitter } from "../../../../packages/blocks/ss-cms-common-components/src/Layout/Layout";

export interface Pagination {
  current_page: number;
  next_page: number;
  prev_page: number;
  total_count: number;
  total_pages: number;
}

export interface Categories {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
  };
}
// Customizable Area End
interface SS {
  id: any;
}

export default class CategoriesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  addCategoryApiCallId: any;
  editCategoryApiCallId: any;
  searchCategoryApiCallId: any;
  deleteCategoryApiCallId: any;
  getOrderNotificationId: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      category: "",
      categories: [],
      isEdit: false,
      isAddEdit: false,
      categoryId: 0,
      categoryName: "",
      setTouched: false,
      loading: false,
      loadingSearch: false,
      errorText: "",
      searchCategory: "",
      noSearchCategories: false,
      openModal: false,
      openAlert: false,
      alertSuccess: false,
      alertMessage: "",
      openSubmit: false,
      pagination: {
        current_page: 0,
        next_page: 0,
        prev_page: 0,
        total_count: 0,
        total_pages: 0,
      },
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    window.addEventListener("popstate", () => this.pressBackEditAdd());
    this.searchCategory();
    this.getOrderNotification();
    this.setState({ loading: true });
    this.handleBackBrowser();
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson?.data && !responseJson.error && !responseJson.errors) {
        this.apiRequestCall(apiRequestCallId, responseJson);
      } else if (apiRequestCallId == this.getOrderNotificationId) {
         emitter.emit("changeNotiNumber", responseJson.new_order_count)
      } else if (
        responseJson.message == "This category has already been taken." ||
        responseJson.message == "No Category Found"
      ) {
        this.handleCategoryMsg(responseJson);
      } else if (apiRequestCallId === this.deleteCategoryApiCallId) {
        this.handleDleteCategoryResp(responseJson);
      } else if (responseJson?.errors) {
        this.setState({ loading: false, loadingSearch: false });
        this.parseApiErrorResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      } else {
        this.setState({ loading: false, loadingSearch: false });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getOrderNotification = async () => {
    const token = await getStorageData("admintoken");
    const header = {
      "Content-Type": "application/json",
      token
    };
    const requestSAMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getOrderNotificationId = requestSAMessage.messageId;
    requestSAMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "/bx_block_order_management/orders/new_order_count");
    requestSAMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestSAMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),"GET");
    runEngine.sendMessage(requestSAMessage.id, requestSAMessage);
  }

  componentWillUnmount(): any {
    window.removeEventListener("popstate", () => this.pressBackEditAdd());
  }

  pressBackEditAdd = () => {
    if (this.state.isAddEdit) {
      this.setState({
        isAddEdit: false,
        openSubmit: false,
        searchCategory: "",
        noSearchCategories: false,
      });
    } else {
      window.onpopstate = null;
    }
  };

  /* istanbul ignore next */
  handleBackBrowser() {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, "", window.location.href);
    };
  }

  submitBtn = () => {
    if (this.state.category != "") {
      this.setState({ openSubmit: false });
      if (this.state.isEdit) {
        this.editCategory();
      } else {
        this.addCategory();
      }
    }
    if (this.state.category == "") {
      this.setState({
        setTouched: true,
        errorText: "Please add category name",
      });
    }
  };
  discardBtn = (even: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ isAddEdit: false, openSubmit: false });
  };

  apiRequestCall = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.addCategoryApiCallId) {
      this.searchCategory();
      this.setState({
        isAddEdit: false,
        loading: false,
        loadingSearch: false,
        searchCategory: "",
        noSearchCategories: false,
        openAlert: true,
        alertSuccess: true,
        alertMessage: "Category added successfully.",
      });
    } else if (apiRequestCallId === this.editCategoryApiCallId) {
      this.editCategoryApiCall(responseJson);
    } else if (apiRequestCallId === this.searchCategoryApiCallId) {
      this.setState({
        loading: false,
        loadingSearch: false,
        categories: responseJson.data,
        pagination: responseJson?.metadata?.meta?.pagination,
      });
      if (responseJson.data.lenth > 0) {
        this.setState({ noSearchCategories: false });
      }
    }
  };
  editCategoryApiCall = (responseJson: any) => {
    if (
      responseJson?.data?.attributes?.errors?.name[0] ==
      "This category has already been taken."
    ) {
      this.setState({
        setTouched: true,
        loading: false,
        loadingSearch: false,
        errorText: "This category has already been taken",
      });
    } else {
      this.searchCategory();
      this.setState({
        loading: false,
        loadingSearch: false,
        searchCategory: "",
        isAddEdit: false,
        noSearchCategories: false,
        categoryId: 0,
        openAlert: true,
        alertSuccess: true,
        alertMessage: "Category edited successfully.",
      });
    }
  };
  handleClose = () => {
    this.setState({ openModal: false });
  };
  handleInputChange = (e: any) => {
    if (e.target.value.trim() == "") {
      this.setState({
        category: "",
        errorText: "Please add category name",
        setTouched: true,
      });
    } else {
      this.setState({
        category: e.target.value.replace(/\s+/g, " "),
        setTouched: false,
      });
    }
  };

  addCategory = async () => {
    const token = await getStorageData("admintoken");
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addCategoryAPIEndPoint + this.state.category.trim()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  editCateoryText = (data: any) => {
    this.setState({
      category: data.attributes.name,
      categoryName: data.attributes.name,
      setTouched: false,
      categoryId: data.attributes.id,
      isAddEdit: true,
      isEdit: true,
      searchCategory: "",
      noSearchCategories: false,
    });
  };
  editCategory = async () => {
    this.setState({ loading: true });
    const token = await getStorageData("admintoken");

    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.editCategoryAPIEndPoint +
        this.state.categoryId +
        "?[categories][name]=" +
        this.state.category.trim()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteCategory = async () => {
    this.setState({ loading: true });
    const token = await getStorageData("admintoken");

    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.editCategoryAPIEndPoint + this.state.categoryId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  searchCategoryWithText = (val: any) => {
    if (val == "") {
      this.setState({ searchCategory: "", noSearchCategories: false });
      this.searchCategory();
    } else {
      this.setState({ searchCategory: val });
      this.searchCategory();
    }
  };

  searchCategory = async (page = 1) => {
    this.setState({ loadingSearch: true });
    const token = await getStorageData("admintoken");

    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchCategoryAPIEndPoint +
        this.state.searchCategory +
        "&page=" +
        page +
        "&per_page=10"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleAlertMessageClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ openAlert: false });
  };
  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };
  handleDleteCategoryResp = (responseJson: any) => {
    if (responseJson.success) {
      this.setState({
        loading: false,
        openModal: false,
        openAlert: true,
        alertSuccess: true,
        alertMessage: "Category deleted successfully.",
      });
      this.searchCategory();
    } else if (
      responseJson.errors == "category have products so we can't delete"
    ) {
      this.searchCategory();
      this.setState({
        loading: false,
        openModal: false,
        openAlert: true,
        alertSuccess: false,
        alertMessage:
          "Category could not be deleted as there is a live menu item associated to it.",
      });
    }
  };
  handleCategoryMsg = (responseJson: any) => {
    if (responseJson.message == "This category has already been taken.") {
      this.setState({
        loading: false,
        setTouched: true,
        errorText: "This category has already been taken",
      });
    } else if (responseJson.message == "No Category Found") {
      this.setState({ loadingSearch: false, noSearchCategories: true });
    }
  };
  handlePageChange = (page: number) => {
    this.searchCategory(page);
  };
  // Customizable Area End
}
