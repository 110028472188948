// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {getName} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { clearStorageData, isTokenExpired } from "../../ss-cms-common-components/src/Utilities/Utilities";
import { emitter } from "../../../../packages/blocks/ss-cms-common-components/src/Layout/Layout";

const baseURL = require("../../../framework/src/config.js").baseURL;


export interface customerObject {
  id : string,
  type : string,
  attributes : {
    full_name : string,
    email: string,
    phone_number : string,
    recive_update: boolean,
    contact_number : number,
    country_name : {
      code : string,
      name: string,
      phone_code : string
    }

  }
}

export interface SSS {
  data: customerObject[],
  metadata: {
    meta: {
      pagination: {
        current_page: number,
        next_page: number,
        prev_page: null | number,
        total_pages: number,
        total_count: number,
        per_page: number
      }
    }
  }

}

interface paginationObject {
  totalPage : number,
  activePage : number,
  totalCount : number,
  perPage : number
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:Record<string, string>
  // Customizable Area End
}


interface S {
  // Customizable Area Start
  ScreenLoading: boolean
  tableInnerLoading : boolean
  custormerList : null | customerObject[]
  paginationObj: paginationObject
  searchText : string
  alertOrderMessage : string
  openOrderAlert : boolean
  alertOrderSuccess : boolean
  isEditCustomer: boolean,
  customerData:customerObject | null
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomerListWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCustomerListApiCallId:string="";
  getOrderNotificationId: string = ""

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      ScreenLoading: true,
      tableInnerLoading: false,
      custormerList: null,
      paginationObj: {
        totalPage: 0,
        activePage: 1,
        totalCount: 0,
        perPage: 10
      },
      searchText: '',
      alertOrderMessage: '',
      openOrderAlert: false,
      alertOrderSuccess: false,
      isEditCustomer: false,
      customerData:null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.data) {
        this.handleAllApiResponse(apiRequestCallId, responseJson);
      } else if (apiRequestCallId == this.getOrderNotificationId) {
        emitter.emit("changeNotiNumber", responseJson.new_order_count)
      }
    }
    // Customizable Area End
  }


  // Customizable Area Start
  async componentDidMount() {
    // this.setState({tableInnerLoading:false, ScreenLoading:false, isEditCustomer:true,custormerList:[]})
    this.getCustomerList();
    this.getOrderNotification()
  }

  getOrderNotification = async () => {
    const token = await getStorageData("admintoken");
    const header = {
      token,
      "Content-Type": configJSON.customerListApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getOrderNotificationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `/bx_block_order_management/orders/new_order_count`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCustomerList = async() => {
    this.setState({tableInnerLoading:true})
    let {activePage, perPage} = this.state.paginationObj
    const token = await getStorageData("admintoken");
    const header = {
      "Content-Type": configJSON.customerListApiContentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCustomerListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.customerListApiEndpoint}?page=${activePage}&per_page=${perPage}&search=${this.state.searchText}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  handleAllApiResponse = (apiRequestCallId:string, responseJson:SSS)=>{
    if(apiRequestCallId==this.getCustomerListApiCallId){
      this.handleCustomerListResp(responseJson);
    }
  }

  handleCustomerListResp = (responseJson:SSS)=>{
    let {data,metadata:{meta:{pagination:{total_count, total_pages}}}} = responseJson
    this.setState({
      ScreenLoading:false, tableInnerLoading : false, 
      paginationObj: {...this.state.paginationObj, totalCount: total_count, totalPage: total_pages},
      custormerList:[...data]
      // custormerList:[]
    })
  }

  changePage= (page:number)=>{
    this.setState({paginationObj:{...this.state.paginationObj,activePage:page}},()=>this.getCustomerList())
  }

  updateSearchText = (e:React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({searchText:e.target.value, paginationObj:{...this.state.paginationObj,activePage:1}},()=>this.handleSearchOnChange())
  }

  SearchDebounce = (call: ()=>void, delay: number) => {
    let timer: ReturnType<typeof setTimeout>;
    return function() {
      clearTimeout(timer);
      timer = setTimeout(() => {
        call();
      }, delay);
    };
  };

  handleSearchOnChange = this.SearchDebounce(this.getCustomerList, 500);

  downloadCsvCustomerFile =async()=>{
    let { searchText}  = this.state
   const token = await getStorageData("admintoken");
    let payloadString = `search=${searchText}`
    
    const url = `${baseURL}/${configJSON.customersListCsvDownloadEndpoint}?${payloadString}&token=${token}`
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "data.csv");
    link.setAttribute("rel", "noreferrer");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    this.setState({alertOrderMessage:'CSV downloaded successfully.',openOrderAlert: true, alertOrderSuccess:true})
  }

  handleOrderAlertMessageClose = (event: unknown, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openOrderAlert: false });
  };

  editCustomer = (data:customerObject)=>{
    this.setState({customerData:{...data},isEditCustomer:true})
  }

  goToBack = (apicall:boolean)=>{
    if(apicall){
      this.getCustomerList()

    }
    this.setState({isEditCustomer:false, customerData:null})
  }


  // Customizable Area End
}
