  // Customizable Area Start

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
import { FilterPayloadType, SSS, orderStatusObjKey } from "./OrderListController.web";
import React from "react";


// Customizable Area Start
export const configJSON = require("./config");

// Customizable Area End

export interface Props {
    // Customizable Area Start
    classes: Record<string, string>
    hideModal: () => void
    updateFilterPayload: (obj: FilterPayloadType) => void
    filterPayload: SSS
    // Customizable Area End
  }
  
  interface S {
    // Customizable Area Start
    orderStatusList: Array<{ value: string; key: orderStatusObjKey }>;
    Orderfilterpayload: orderStatusObjKey[];
    start_date: string;
    end_date: string;

    // Customizable Area End
  }
  
  interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
  }


export default class OrderListingFilterModalController extends BlockComponent<
  Props,
  S,
  SS
> {
    // Customizable Area Start
    startDateRef;
    endDateRef;

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
    
        // Customizable Area Start
        this.subScribedMessages = [
          // Customizable Area Start
          // Customizable Area End
        ];
    
        this.state = {
          // Customizable Area Start
          orderStatusList:[
            { value: 'Order placed', key: orderStatusObjKey.PLACED },
            { value: 'Preparing', key: orderStatusObjKey.PREPARING },
            { value: 'Ready for pick up', key: orderStatusObjKey.READY },
            { value: 'Out for delivery', key: orderStatusObjKey.OUT_FOR_DELIVERY },
            { value: 'Delivered',key: orderStatusObjKey.DELIVERED },
            { value: 'Cancelled', key: orderStatusObjKey.CANCEL }
          ],
          Orderfilterpayload: [],
          start_date: moment().format('yyyy-MM-DD'),
          end_date: moment().format('yyyy-MM-DD')
          // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    
        // Customizable Area Start
        this.startDateRef = React.createRef<Omit<HTMLInputElement, 'showPicker'> & { showPicker: () => void }>();
        this.endDateRef = React.createRef<Omit<HTMLInputElement, 'showPicker'> & { showPicker: () => void }>();
        // Customizable Area End
      }

      async componentDidMount() {
        let { filterPayload: { start_date, end_date, orderStatus }} = this.props
        this.setState({ start_date, end_date, Orderfilterpayload: orderStatus })
      }

      async receive(from: string, message: Message) {
          // Customizable Area Start
        // Customizable Area End
      }

      handleOrderStatus(e: React.ChangeEvent<HTMLInputElement>, orderStatus: orderStatusObjKey) {
        if (e.target.checked) {
          this.setState({ Orderfilterpayload: [...this.state.Orderfilterpayload, orderStatus] })
        } else {
          let copyOrderfilterpayload = [...this.state.Orderfilterpayload]
          let findIndex = copyOrderfilterpayload.findIndex(string => string === orderStatus)
          copyOrderfilterpayload.splice(findIndex, 1)
          this.setState({ Orderfilterpayload:[...copyOrderfilterpayload] })
        }
      }

      handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        this.props.updateFilterPayload({ start_date:this.state.start_date,end_date: this.state.end_date, orderStatus: this.state.Orderfilterpayload })
      }

      resetFilter(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        this.setState({ start_date: moment().format('yyyy-MM-DD'), end_date: moment().format('yyyy-MM-DD'), Orderfilterpayload: [] })
      }

      handleOpenStartDate(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        if (this.startDateRef.current) this.startDateRef.current.showPicker();
      }

      handleOpenEndDate(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        if (this.endDateRef.current) this.endDateRef.current.showPicker();
      }
}


  // Customizable Area End
