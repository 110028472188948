export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");
export const noCategories = require("../assets/nocategories.png");
export const editIcon = require("../assets/editIcon.svg");
export const deleteIcon = require("../assets/deleteIcon.svg");
export const backIcon = require("../assets/backIcon.png");
export const searchIcon = require("../assets/searchIcon.svg");
