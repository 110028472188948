Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "adminconsole2";
exports.labelBodyText = "adminconsole2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.customerListApiContentType = "application/json";
exports.customerListApiEndpoint = "bx_block_admin/customers";

exports.editCustomerListApiContentType = "application/json";
exports.editCustomerListApiMethodType = "PUT";
exports.editCustomerListApiEndpoint = "bx_block_admin/customers";

exports.customersListCsvDownloadEndpoint = "bx_block_admin/export_customers/download_csv";
exports.mobileRegExp = /^(\+91[\-\s]?)?(0|91)?[789]\d{9}$/

exports.mobileRegex={
  in : /^\d{10}$/,
  ae: /^[1-9]\d{8}$/ 
}

// Customizable Area End