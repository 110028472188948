import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { setStorageData, getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    admin_email: string,
    admin_password: string,
    forget_email: string,
    remember_me: boolean,
    new_password: string,
    confirm_password: string
    loading: boolean,
    openAlert: boolean;
    alertSuccess: boolean;
    alertMessage: any;
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class AdminLoginController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    adminLoginApiCallId: any;
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            admin_email: "",
            admin_password: "",
            forget_email: "",
            remember_me: false,
            new_password: "",
            confirm_password: "",
            loading: false,
            openAlert: false,
            alertSuccess: false,
            alertMessage: "",
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    async componentDidMount() {
        // Customizable Area Start
        let token = await getStorageData("userToken")
        if (token) {
            this.redirectNavigation("Categories")
        }
        // Customizable Area End
    }
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson && !responseJson.error && !responseJson.errors) {
                if (apiRequestCallId == this.adminLoginApiCallId && responseJson.meta.token) {
                    this.setState({ loading: false, openAlert: true, alertSuccess: true, alertMessage: "Successfully Login!" });
                    this.saveLoggedInUserData(responseJson);
                    setTimeout(() => {
                        this.redirectNavigation("Categories")
                        this.setState({ loading: false })
                    }, 2000)
                }
            } else if (apiRequestCallId == this.adminLoginApiCallId && responseJson.errors && responseJson.errors[0].failed_login) {
                this.setState({ loading: false, openAlert: true, alertSuccess: false, alertMessage: "Login failed, Invalid credential." })
                setTimeout(() => {
                    this.redirectNavigation("AdminLogin")
                })
            }
            else if (responseJson.errors) {
                this.parseApiErrorResponse(responseJson);
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
    }

    // Customizable Area Start
    redirectNavigation = (navigate: string) => {
        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), navigate);
        msg.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(msg);
    }

    adminLogin = () => {
        this.setState({ loading: true });
        const body = {
            "data": {
                "attributes": {
                    "email": this.state.admin_email,
                    "password": this.state.admin_password
                },
                "type": "email_account"
            }
        }
        const header = {
            "Content-Type": configJSON.validationApiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.adminLoginApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.adminLoginAPIEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    saveLoggedInUserData(responseJson: any) {
        if (responseJson.meta.token) {
            setStorageData("userData", JSON.stringify(responseJson));
            setStorageData("userToken", responseJson.meta.token);
        }
    }

    handleAlertMessageClose = (event: any, reason: any) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ openAlert: false });
    };

    // Customizable Area End
}