import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { isTokenExpired, clearStorageData } from "../../ss-cms-common-components/src/Utilities/Utilities";
import { emitter } from '../../ss-cms-common-components/src/Layout/Layout'
import moment from "moment";
import { CatalogueVariantType, OrderDetailDataType } from "./OrderDetailController.web";
import { debounce } from "lodash";
import { SnackbarCloseReason } from "@material-ui/core";
const baseURL = require("../../../framework/src/config.js").baseURL;

export const orderStatusObj = {
  "placed": 'Order Placed',
  "preparing": 'Preparing',
  "ready_for_pick_up": 'Ready for pick up',
  "out_for_delivery": 'Out for delivery',
  "delivered": 'Delivered',
  "cancelled": 'Cancelled'
}
export enum orderStatusObjKey  {
  PLACED = "placed",
  PREPARING = "preparing",
  READY = "ready_for_pick_up",
  OUT_FOR_DELIVERY = "out_for_delivery",
  DELIVERED = "delivered",
  CANCEL = "cancelled"
}
export interface FilterPayloadType{
  orderStatus: orderStatusObjKey[],
  start_date: string
  end_date: string
}

export interface SSS {
  start_date: string
  end_date: string
  orderStatus: orderStatusObjKey[]
  searchText: string
}

export type OrderStatusObjectType = {
  id: number
  name: string
  value: string
  color: string
  textColor: string
  key: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: {
    navigate: (to: string, params: object) => void;
    getParam: (param: string) => string;
    goBack: () => void;
  };
  id: string;
  // Customizable Area Start
  classes: Record<string, string>
  width: number
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  ordersData: OrderDetailDataType[];
  noSearchOrders: boolean;
  loading: boolean;
  filterOrderLoading: boolean
  orderStatus: OrderStatusObjectType[];
  currencyType: string;
  filterModal: boolean
  paginationData: {
    total_count: number;
    per_page?: number;
    current_page?: number;
  };
  filterPayload: SSS
  statusKey: string;
  alertOrderMessage: string;
  openOrderAlert: boolean;
  alertOrderSuccess: boolean;
  showfilterNodataMessage: boolean;
  orderId: number;
  isOrderDetail: boolean;
  isStoreAvailable: boolean;
  numberNotiOrder: number
  shipRocketMessage: string
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class OrderListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  currencyType: string = "";
  getOrdersApiCallId: string = "";
  getOrderNotificationId: string = ""
  csvDownloadApiCallID:string="";
  updateOrdersStatusApiCallId:string="";
  getStoreStatusApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      ordersData: [],
      orderStatus: [
        { id: 1, name: "orderplaced", value: "Order placed", color: "#FEF3C7", textColor: '#D97706', key: "placed" },
        { id: 2, name: "preparing", value: "Preparing", color: "#FEF3C7", textColor: '#D97706', key: "preparing" },
        { id: 3, name: "readyforpickup", value: "Ready for Pick Up", color: "#FEF3C7", textColor: '#D97706', key: "ready_for_pick_up" },
        { id: 4, name: "outfordelivery", value: "Out for delivery", color: "#FEF3C7", textColor: '#D97706', key: "out_for_delivery" },
        { id: 5, name: "delivered", value: "Delivered", color: "#D1FAE5", textColor: '#059669', key: "delivered" },
        { id: 6, name: "cancelled", value: "Cancelled", color: "#FEE2E2", textColor: '#DC2626', key: "cancelled" }
      ],
      noSearchOrders: false,
      loading: true,
      filterOrderLoading: false,
      filterModal: false,
      filterPayload: {
        start_date: moment().format('yyyy-MM-DD'),
        end_date: moment().format('yyyy-MM-DD'),
        orderStatus: [],
        searchText: ''
      },
      currencyType: '',
      paginationData: {
        total_count: 0
      },
      statusKey: '',
      alertOrderMessage: '',
      openOrderAlert: false,
      alertOrderSuccess: false,
      showfilterNodataMessage: true,
      orderId: 0,
      isOrderDetail: false,
      isStoreAvailable: true,
      numberNotiOrder: 0,
      shipRocketMessage: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.setCurrencyType();
    // Customizable Area End
  }

  // Customizable Area Start
  /* istanbul ignore next */
  async componentDidMount() {
    this.getOrders(0);
    this.getStoreStatus();
    this.getOrderNotification();
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.data && !responseJson.errors && !responseJson.error) {
        if (apiRequestCallId == this.getOrdersApiCallId) {
          this.setState({ loading: false, filterOrderLoading: false })
          if (responseJson.data && responseJson.data.data && responseJson.data.data.length > 0) {
            this.setState({ ordersData: responseJson.data.data });
          }
          else {
            this.setState({ ordersData: [], showfilterNodataMessage: this.filterNoDataFlag() });
          }
          if (responseJson.data && responseJson.metadata && responseJson.metadata.meta && responseJson.metadata.meta.pagination) {
            this.setState({ paginationData: responseJson.metadata.meta.pagination })
          }
          else {
            this.setState({ paginationData: {
              total_count: 0
            } })
          }
        }

        if (apiRequestCallId == this.updateOrdersStatusApiCallId) {
          this.getOrders(this.state.paginationData.current_page || 0)
          if (responseJson.meta.shiprocket_status === true) {
            if (responseJson.meta.ship_msg) {
              this.setState({ shipRocketMessage: responseJson.meta.ship_msg })
            } else {
              this.setState({ shipRocketMessage: '' })
            }
          } else {
            this.setState({ shipRocketMessage: configJSON.shipRocketFailed })
          }
        }
      }
      else if (responseJson?.errors) {
        this.setState({ loading: false, filterOrderLoading: false });
        this.parseApiErrorResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (responseJson && responseJson.message) {
        if (apiRequestCallId === this.getOrdersApiCallId) {
          this.setState({ ordersData: [], paginationData: { total_count: 0 }, loading: false, filterOrderLoading: false, showfilterNodataMessage: this.filterNoDataFlag() });
        } else {
          this.setState({ alertOrderMessage: responseJson.message, openOrderAlert: true })
        }
      }else if(apiRequestCallId === this.getStoreStatusApiCallId){
        this.setState({
          isStoreAvailable:responseJson.payments[0].online_ordering
        })
      } else if (apiRequestCallId == this.getOrderNotificationId) {
         emitter.emit("changeNotiNumber", responseJson.new_order_count)
      }
    }
    // Customizable Area End
  }
  // web events
  // Customizable Area Start
  getOrders = async (pageNo: number) => {
    this.setState({ filterOrderLoading: true })
    const token = await getStorageData("admintoken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    let { filterPayload: { start_date, end_date, orderStatus, searchText } } = this.state

    let payloadString = `&search=${searchText}`
    if (start_date && end_date) {
      payloadString += `&start_date=${start_date}&end_date=${end_date}`
    }
    if (orderStatus.length) {
      orderStatus.forEach(status => {
        payloadString += `&status[]=${status}`
      })
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getOrdersApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.ordersListEndpoint}?page=${pageNo}${payloadString}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStoreStatus = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token:await getStorageData("admintoken")
    };
    const requestSAMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getStoreStatusApiCallId = requestSAMessage.messageId;
    requestSAMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.getStoreAvailabilityEndPoint);
    requestSAMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestSAMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.validationApiMethodType);
    runEngine.sendMessage(requestSAMessage.id, requestSAMessage);
  }

  goToHome = () => {
    const gotoHomeFromOLMessage: Message = new Message(getName(MessageEnum.NavigationMessage));
    gotoHomeFromOLMessage.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
    gotoHomeFromOLMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(gotoHomeFromOLMessage);
  }
  
  getOrderNotification = async () => {
    const token = await getStorageData("admintoken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const requestSAMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getOrderNotificationId = requestSAMessage.messageId;
    requestSAMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.ordersNotificationEndpoint);
    requestSAMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestSAMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.validationApiMethodType);
    runEngine.sendMessage(requestSAMessage.id, requestSAMessage);
  }

  
  handleChange = (id: string) => {
    this.updateOrderStatus(id);
  }

  selectOption = (status: string) => {
    this.setState({ statusKey: status });
  }

  pageChange = (page: number) => {
    this.getOrders(page)
  }
  setCurrencyType = async () => {
    const currencyType = await getStorageData("currency_type") || "$"
    this.setState({ currencyType: currencyType })
  }
  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  getStatusColor = (value: string) => {
    let color = ""
    for (let item of this.state.orderStatus) {
      if (item.value === this.handleValueSelect(value)) {
        color = item.color
      }
    }
    return color;
  }

  getStatusTextColor = (value: string) => {
    let color = ""
    for (let item of this.state.orderStatus) {
      if (item.value === this.handleValueSelect(value)) {
        color = item.textColor
      }
    }
    return color;
  }

  getBackgroundColorNoOrder(isMobile: boolean) {
    if (isMobile) return "#transparent"
    else return "#fff"
  }

  handleValueSelect = (value: string) => {
    switch (value) {
      case "Placed":
        return "Order placed"
      case "Ready for pick up":
        return "Ready for Pick Up"
      case "Cancelled":
        return "Cancelled"
      case "Delivered":
        return "Delivered"
      case "Preparing":
        return "Preparing"
      default:
        return "Out for delivery"
    }
  }
  updateOrderStatus = async (orderId: string) => {
    const token = await getStorageData("admintoken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    let data = {
      "status": this.state.statusKey
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateOrdersStatusApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.ordersListEndpoint}/${orderId}/update_order_status`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.examplePutAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getVariants = (values: CatalogueVariantType[]) => {
    let names = values.map((a: CatalogueVariantType) => a.attributes.catalogue_variant.attributes.title);
    if (names.length > 0) {
      let variant = "";
      names.forEach((element: string, index: number) => {
        if (index === 0) {
          variant += element
        } else {
          variant += `, ${element}`
        }
      });
      return variant
    }
    else {
      return "";
    }
  }

  showHideFilterModal = () => {
    this.setState({ filterModal: !this.state.filterModal })
  }

  renderDatePayloadChip = () => {
    let { filterPayload: { end_date, start_date } } = this.state
    let chipArray = []
    if (end_date && start_date) {
      if (start_date === moment().format('yyyy-MM-DD') && end_date === moment().format('yyyy-MM-DD')) {
        chipArray.push('Today')
      } else {
        chipArray.push(`${moment(start_date, 'yyyy-MM-DD').format('DD/MM/yyyy')} - ${moment(end_date, 'yyyy-MM-DD').format('DD/MM/yyyy')}`)
      }
    }
    return chipArray
  }

  removeFilterOrder = (label: string) => () => {
    let { filterPayload: { orderStatus } } = this.state
    let orderStatusCopy = [...orderStatus]
    let findIndex = orderStatusCopy.findIndex(l => l === label)
    orderStatusCopy.splice(findIndex, 1)
    this.setState({ filterPayload: { ...this.state.filterPayload, orderStatus: [...orderStatusCopy] } }, () => this.getOrders(0))
  }

  removeDateFilter = () => () => {
    this.setState({ filterPayload: { ...this.state.filterPayload, start_date: '', end_date: '' } }, () => this.getOrders(0))
  }

  updateSearchText = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ filterPayload: { ...this.state.filterPayload, searchText: event.target.value } }, () => this.handleSearchOnChange())
  }

  handleOrderAlertMessageClose = (event: string, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openOrderAlert: false });
  };

  searchOrder = () => {
    this.getOrders(0)
  }

  updateFilterPayload = (payload: FilterPayloadType) => {
    let { orderStatus, start_date, end_date } = payload
    this.setState({ filterPayload: { ...this.state.filterPayload, orderStatus, start_date, end_date } }, () => {
      this.showHideFilterModal()
      this.searchOrder()
    })
  }

  filterNoDataFlag = () => {
    let { filterPayload: { end_date, orderStatus, searchText, start_date } } = this.state
    if (start_date || end_date || searchText.length || orderStatus.length) {
      return true
    } else {
      return false
    }
  }

  filterNoDataMessage = () => {
    let { start_date, end_date, orderStatus } = this.state.filterPayload
    if (start_date || end_date || orderStatus.length) {
      return true
    } else {
      return false
    }
  }

  handleSearchOnChange = debounce(this.searchOrder, 500);

  downloadCsvFile = async () => {
    let { filterPayload: { start_date, end_date, orderStatus, searchText } } = this.state
    const token = await getStorageData("admintoken");
    let payloadString = `search_term=${searchText}`
    if (start_date && end_date) {
      payloadString += `&filter[order_date][from]=${start_date}&filter[order_date][to]=${end_date}`
    }
    if (orderStatus.length) {
      orderStatus.forEach(status => {
        payloadString += `&filter[status][]=${status}`
      })
    }
    const url = `${baseURL}/${configJSON.ordersListCsvDownloadEndpoint}?${payloadString}&token=${token}`
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "data.csv");
    link.setAttribute("rel", "noreferrer");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    this.setState({ alertOrderMessage: 'CSV downloaded successfully.', openOrderAlert: true, alertOrderSuccess: true })
  }

  orderDetail = (Id: number) => {
    this.setState({ orderId: Id, isOrderDetail: true })
  }

  goToBack = () => {
    this.setState({ isOrderDetail: false, orderId: 0 })
    this.getOrders(0)
  }
  // Customizable Area End
}
