// @ts-nocheck
import React from 'react'
import { useEffect, useRef } from 'react';
import { Box, Typography, createStyles, Drawer } from '@material-ui/core';
import SearchEmpty from './SearchEmpty';
import { Theme, makeStyles } from "@material-ui/core";
import {Helmet} from "react-helmet";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchItemContainer: {
      display: 'flex',
      padding: '3px',
      backgroundColor: '#ffffff',
    },
    searchContainerName: {
      marginLeft: '10px',
      marginTop: '6px',
      fontWeight: 400,
      color: '#334155',
      lineHeight: "22px",
      fontSize: '14px',
      fontFamily: "var(--website-builder-body-font)",
      color:'var(--website-builder-secondary-color)'
    },
    mainDivCon: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      backgroundColor: "#F1F5F9",
      justifyContent: "space-between",
      padding: "12px 0",
      "@media (max-width: 900px)": {
        justifyContent: "center",
      },
    },
    logoDiv: {
      alignItems: 'center',
      display: 'flex',
    },
    restaurantName: {
      fontSize: '16px',
      fontWeight: 700,
      width: '200px',
    },
    restaurantNameForMobile: {
      fontFamily: "var(--website-builder-body-font)",
      fontSize: '16px',
      fontWeight: 700,
      marginLeft: '10px',
      color: "var(--website-builder-secondary-color)",
    },
    searchDiv: {
      position: 'relative',
      width: "23.75rem",
      display: 'flex',
      alignItems: "center",
      "@media (max-width: 900px)": {
        marginLeft: "0",
        display: "none",
      },
    },
    searchDivDrawer: {
      position: 'relative',
      padding: '10px 16px',
      "@media (max-width: 900px)": {
        display: "block",
      },
      "@media (max-width: 480px)": {
        display: "block",
      },
      "@media (max-width: 320px)": {
        display: "block",
      },
    },
    searchIcon: {
      position: 'absolute',
      left: '12px',
      "@media (max-width: 900px)": {
        display: "none"
      },
    },
    searchIconDrawer: {
      display: "none",
      "@media (max-width: 900px)": {
        display: "block",
        position: 'absolute',
        left: '25px',
        top: '24px',
      },
      "@media (max-width: 480px)": {
        display: "block",
        position: 'absolute',
        left: '25px',
        top: '24px',
      },
      "@media (max-width: 320px)": {
        display: "block",
        position: 'absolute',
        left: '25px',
        top: '24px',
      },
    },
    searchIconSmall: {
      position: 'absolute',
      cursor: "pointer",
      display: "none",
      "@media (max-width: 900px)": {
        display: "block",
        right: "10%",
        transform: "transformY(55%)"
      },
      "@media (max-width: 480px)": {
        display: "block",
        right: "6%",
        transform: "transformY(55%)"
      },
      "@media (max-width: 320px)": {
        display: "block",
        right: "6%",
        transform: "transformY(55%)"
      },
    },
    marginTopLeft: {
      marginLeft: "16px", marginTop: "47px"
    },
    drawerSearch: {
      width: "100vw",
      "& .MuiDrawer-paper": {
        width: "100%",
        backgroundColor: "#F1F5F9",
      },
    },
    selectedSectionHeadingSearch: {
      fontSize: "30px",
      fontWeight: 700,
      fontFamily: "var(--website-builder-header-font)",
      lineHeight: "40px",
      padding: "10px 16px",
      color:'var(--website-builder-secondary-color)'
    },
    subHeadingSearch: {
      fontSize: "14px",
      fontWeight: 400,
      fontFamily: "var(--website-builder-body-font)",
      lineHeight: "22px",
      padding: "16px",
      color:'var(--website-builder-secondary-dark-accent-color)'
    },
    searchInputDefault: {
      backgroundColor: "#fff"
    },
    searchInputCart: {
      backgroundColor: "#F1F5F9"
    },
    mainDivConCart: {
      justifyContent: 'space-between;',
      padding: "12px 25px",
    },
    searchDivCart: {
      marginLeft: "0px"
    },
    hideforDesktop: {
      display: "none"
    },
    cursorPointer: {
      cursor: "pointer"
    }
  })
)

export function AppHeader(props: any) {
  const classes = useStyles()
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        props.onBlur && props.onBlur();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [props.onBlur]);

  const renderContent = () => {
    if (props.state.isSearch) {
      if (props.searchData?.length || !props.state.search?.length) {
        return props.searchData?.map((item: any, index) => {
          const border = index ? '1px' : '0px'
          return (
            <div
              key={index}
              style={{ borderTop: `solid ${border} #E2E8F0`, cursor: 'pointer' }}
              onClick={() => props.itemSelect(item)}>
              <div className={classes.searchItemContainer}>
                <img src={item.attributes.images.url} width={36} height={36} style={{ borderRadius: '5rem' }} alt='' />
                <Typography className={classes.searchContainerName}>{item.attributes.name}</Typography>
              </div>
            </div>
          )
        })
      }
      else if (props.state.search?.length) {
        return <SearchEmpty />
      }
    }
  }
  return (     
   <>
    <Helmet>
      <title>{props.state?.storename}</title>
    </Helmet>
    <Box className={classes.mainDivCon} style={{ backgroundColor: props.backgroundColor, paddingLeft: props.searchInputDefaultColor || props.isStaticPage ? "2%" : "15%", paddingRight: props.searchInputDefaultColor || props.isStaticPage ? "2%" : "15%" }}>
      <Box className={classes.logoDiv}>
        {props.state.canvasData?.header?.data.attributes.logo_url != null &&
          <img style={{ height: '40px' }} src={props.state.canvasData?.header?.data.attributes.logo_url} alt="Logo" />
        }
        {props.state.canvasData?.header?.data.attributes.show_business_name && <span className={classes.restaurantNameForMobile}>{props.state?.storename} </span>}
      </Box>
      <div ref={ref} className={`${classes.searchDiv} ${!props.searchInputDefaultColor?'': classes.hideforDesktop}`}>
      <svg className={classes.searchIcon} width="18" height="18" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.5271 15.2322L20.4529 19.158L19.1566 20.4544L15.2308 16.5286C13.8192 17.6579 12.029 18.3335 10.082 18.3335C5.52803 18.3335 1.83203 14.6375 1.83203 10.0835C1.83203 5.5295 5.52803 1.8335 10.082 1.8335C14.636 1.8335 18.332 5.5295 18.332 10.0835C18.332 12.0305 17.6564 13.8207 16.5271 15.2322ZM14.688 14.5521C15.8089 13.3969 16.4987 11.8211 16.4987 10.0835C16.4987 6.53829 13.6272 3.66683 10.082 3.66683C6.53682 3.66683 3.66536 6.53829 3.66536 10.0835C3.66536 13.6287 6.53682 16.5002 10.082 16.5002C11.8197 16.5002 13.3954 15.8104 14.5506 14.6895L14.688 14.5521Z" fill="var(--website-builder-secondary-color)"/>
      </svg>
        <input test-id='search' onClick={()=>props.mixPanelHit('Header search')} type='search' placeholder='Search menu item...' onFocus={props.onFocus} value={props.value} onChange={(text) => props.onChange(text)} className={ !props.searchInputColor?`search-input ${classes.searchInputDefault}` :`search-input ${classes.searchInputCart}`} />
        <Box sx={{ position: 'absolute', width: "23.75rem" }} style={{ marginTop: "20px" }}>
          <div style={{ position: "absolute", width: "23.75rem", top: "16px" }}>{renderContent(true)}</div>
        </Box>
      </div>
      <svg className={`${classes.searchIconSmall} ${!props.searchInputDefaultColor?'': classes.hideforDesktop}`} onClick={props.handleOpenDrawer} width="18" height="18" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.5271 15.2322L20.4529 19.158L19.1566 20.4544L15.2308 16.5286C13.8192 17.6579 12.029 18.3335 10.082 18.3335C5.52803 18.3335 1.83203 14.6375 1.83203 10.0835C1.83203 5.5295 5.52803 1.8335 10.082 1.8335C14.636 1.8335 18.332 5.5295 18.332 10.0835C18.332 12.0305 17.6564 13.8207 16.5271 15.2322ZM14.688 14.5521C15.8089 13.3969 16.4987 11.8211 16.4987 10.0835C16.4987 6.53829 13.6272 3.66683 10.082 3.66683C6.53682 3.66683 3.66536 6.53829 3.66536 10.0835C3.66536 13.6287 6.53682 16.5002 10.082 16.5002C11.8197 16.5002 13.3954 15.8104 14.5506 14.6895L14.688 14.5521Z" fill="var(--website-builder-secondary-color)"/>
      </svg>
        <Drawer
          open={props.openDrawer}
          fullWidth
          className={classes.drawerSearch}
          anchor="right"
        >
          <div>
            <div className={classes.marginTopLeft}>
              <svg onClick={props.handleCloseDrawer} className={classes.cursorPointer} data-test-id="searchDrawerClose" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.82843 6.9999H16V8.9999H3.82843L9.1924 14.3638L7.7782 15.778L0 7.9999L7.7782 0.22168L9.1924 1.63589L3.82843 6.9999Z" fill="var(--website-builder-secondary-dark-accent-color)"/>
              </svg>
            </div>

          <div>
            <Typography className={classes.selectedSectionHeadingSearch}>
              Search
            </Typography>
          </div>

          <div>
            <Typography className={classes.subHeadingSearch}>
              Explore using either menu item names or menu categories.
            </Typography>
          </div>

            <div ref={ref} className={classes.searchDivDrawer}>
              <svg className={classes.searchIconDrawer} width="18" height="18" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.5271 15.2322L20.4529 19.158L19.1566 20.4544L15.2308 16.5286C13.8192 17.6579 12.029 18.3335 10.082 18.3335C5.52803 18.3335 1.83203 14.6375 1.83203 10.0835C1.83203 5.5295 5.52803 1.8335 10.082 1.8335C14.636 1.8335 18.332 5.5295 18.332 10.0835C18.332 12.0305 17.6564 13.8207 16.5271 15.2322ZM14.688 14.5521C15.8089 13.3969 16.4987 11.8211 16.4987 10.0835C16.4987 6.53829 13.6272 3.66683 10.082 3.66683C6.53682 3.66683 3.66536 6.53829 3.66536 10.0835C3.66536 13.6287 6.53682 16.5002 10.082 16.5002C11.8197 16.5002 13.3954 15.8104 14.5506 14.6895L14.688 14.5521Z" fill="var(--website-builder-secondary-color)"/>
              </svg>
              <input className="search-drawer" onClick={()=>props.mixPanelHit('Header search')} test-id='search' type='search' placeholder='Search menu item...' onFocus={props.onFocus} value={props.value} onChange={(text) => props.onChange(text)} />
              <Box
                sx={{
                  position: 'absolute', width: "calc(100% - 32px)", marginTop: "3px"
                }}
              >
                {renderContent(true)}
              </Box>
            </div>
          </div>
      </Drawer>
    </Box>
    </>
  )
}