import React from "react";
// Customizable Area Start
import { Box, Typography, styled, Select, MenuItem, Modal, Button, Grid, Tooltip, Backdrop, CircularProgress, Drawer, TextField, InputAdornment } from "@material-ui/core";
import ContactInformationController, { Props, SectionData, configJSON } from "./ContactInformationController.web";
import IOSSwitch from "../../ss-cms-common-components/src/IOSSwitch/IOSSwitch";
import { Add, InfoOutlined } from "@material-ui/icons";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import "./styles.css";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import AddCropper from "../../ss-cms-common-components/src/AddCropper/AddCropper.web";
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import UploadBox from "../../ss-cms-common-components/src/UploadBox/UploadBox.Web";
import { dropUpArrowIcon, checkIcon, ModalSearchIcon, AddSectionIcon, CloseIcon, InfoIcon, minusIcon, plusIcon, checkedImg, SectionIcon, deleteIcon, dropDownArrowIcon, uploadIcon, EditIcon, TooltipIcon } from "./assets";
import ColorPicker from "material-ui-color-picker";
// Customizable Area End


class ContactInformation extends ContactInformationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    render() {
        return (
            // Customizable Area Start
            <>
                <ContentBar>
                <ContentButtonBar data-test-id={"ThemeFontsSectionId"} onClick={this.toggleThemeFontsContent}>
                        <ContentHeadinText style={{color: this.state.themeFontsContent ? `rgba(98, 0, 234, 1)` : `rgba(71, 85, 105, 1)`}}>
                            Theme & Fonts
                        </ContentHeadinText>
                        <img src={this.state.themeFontsContent ? minusIcon : plusIcon} />
                    </ContentButtonBar>
                    {this.state.themeFontsContent && ( 
                        <> 
                            <SectionContentDropDownBox>
                                <SectionDropDownBox onClick={this.toggleColorSelectionOpen}>
                                    <SectionDropDownText style={{ fontWeight:500 }}>Colour Selection</SectionDropDownText>
                                    <SectionDropDownIcon src={this.state.isColorSelectionOpen ? dropUpArrowIcon : dropDownArrowIcon} />
                                </SectionDropDownBox>
                                {this.state.isColorSelectionOpen &&
                                    <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: '16px', padding: '20px 10px 30px 20px', justifyContent: 'flex-end' }}>
                                    {this.state.defaultColors?.map((color) => {
                                        const borderProperties = this.state.selectedColor.id === color.id ? '1px solid #6200EA' : '1px solid #D0D2DA'
                                        return (
                                            <div style={{ width: 69, height: 48, border: borderProperties, borderRadius: 4, display: 'flex', alignItems: 'center', justifyContent: 'center' }} key={color.id} onClick={() => this.handleColorSelection(color)}>
                                                <div style={{ backgroundColor: color.primary, width: 36, height: 36, borderRadius: '50%', transform: 'translateX(7px)' }} />
                                                <div style={{ backgroundColor: color.secondary, width: 36, height: 36, borderRadius: '50%', transform: 'translateX(-7px)' }} />
                                            </div>
                                        )
                                    })}
                                    <div style={{ width: 69, height: 48, border: !this.state.selectedColor.id ? '1px solid #6200EA' : '1px solid #D0D2DA', borderRadius: 4, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} onClick={this.handleCustomColorSelection}>
                                        <Add style={{ color: '#000', fontSize: 28, marginBottom: -6 }} />
                                        <span style={{ fontSize: 10, color: '#676B7E' }}>Custom</span>
                                    </div>
                                    {!this.state.selectedColor.id && (
                                    <>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '94%' }} >
                                            <label style={{ marginBottom: 6, display: 'flex', alignItems: 'center', color: '#3C3E49', fontWeight: 500, fontSize: 14 }}>Primary <InfoOutlined style={{ fontSize: 18, color: '#3C3E49', marginLeft: 3 }} /></label>
                                            <ColorPicker
                                                variant="outlined"
                                                onChange={this.handlePrimaryColorChange}
                                                hintText={this.state.selectedColor.primary}
                                                value={this.state.selectedColor.primary}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <section
                                                                style={{ 
                                                                    background: this.state.selectedColor.primary,
                                                                    cursor: "pointer",
                                                                    height: "36px",
                                                                    width: "36px",
                                                                    borderRadius: "50%"
                                                                }}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                    inputProps: {
                                                        style: {
                                                            color: '#475569',
                                                            fontSize: 14,
                                                            textTransform: 'uppercase',
                                                            opacity: 1,
                                                            fontWeight: 500
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '94%' }} >
                                            <label style={{ marginBottom: 6, display: 'flex', alignItems: 'center', color: '#3C3E49', fontWeight: 500, fontSize: 14 }}>Secondary <InfoOutlined style={{ fontSize: 18, color: '#3C3E49', marginLeft: 3 }} /></label>
                                            <ColorPicker
                                                variant="outlined"
                                                onChange={this.handleSecondaryColorChange}
                                                hintText={this.state.selectedColor.secondary}
                                                value={this.state.selectedColor.secondary}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <section
                                                                style={{ 
                                                                    background: this.state.selectedColor.secondary,
                                                                    cursor: "pointer",
                                                                    height: "36px",
                                                                    width: "36px",
                                                                    borderRadius: "50%"
                                                                }}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                    inputProps: {
                                                        style: {
                                                            color: '#475569',
                                                            fontSize: 14,
                                                            textTransform: 'uppercase',
                                                            opacity: 1,
                                                            fontWeight: 500
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    </>)}
                                </div>
                                }
                            </SectionContentDropDownBox>
                            <SectionContentDropDownBox>
                                <SectionDropDownBox onClick={this.toggleFontSelectionOpen}>
                                    <SectionDropDownText style={{ fontWeight:500 }}>Fonts</SectionDropDownText>
                                    <SectionDropDownIcon src={this.state.isFontSelectionOpen ? dropUpArrowIcon : dropDownArrowIcon} />
                                </SectionDropDownBox>
                            </SectionContentDropDownBox>

                              {this.state.isFontSelectionOpen &&
                                <div style={{ padding: '20px 0'}}>
                                    <SectionContentDropDown>
                                        <SectionDropDownHeading>Header Text</SectionDropDownHeading>
                                        <SectionSelectSubBox
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left"
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left"
                                                },
                                                getContentAnchorEl: null,
                                                keepMounted: true
                                            }}
                                            displayEmpty
                                            value={this.state.selectedFonts.headerText}
                                            onChange={(event) => this.handleHeaderTextFontChange(event.target.value as string)}
                                            style={{ marginBottom: 10 }}
                                        >
                                            <DisabledMenuItem value="" disabled>Select Font</DisabledMenuItem>
                                            {configJSON.fontsList.map((font: string, index: number) => {
                                                return (
                                                    <CustomMenuItem value={font} key={index}>{font}</CustomMenuItem>
                                                )
                                            })}
                                        </SectionSelectSubBox>
                                        <SectionDropDownHeading>Body Text</SectionDropDownHeading>
                                        <SectionSelectSubBox
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left"
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left"
                                                },
                                                getContentAnchorEl: null,
                                                keepMounted: true
                                            }}
                                            displayEmpty
                                            value={this.state.selectedFonts.bodyText}
                                            onChange={(event) => this.handleBodyTextFontChange(event.target.value as string)}
                                        >
                                            <DisabledMenuItem value="" disabled>Select Font</DisabledMenuItem>
                                            {configJSON.fontsList.map((font: string, index: number) => {
                                                return (
                                                    <CustomMenuItem value={font} key={index}>{font}</CustomMenuItem>
                                                )
                                            })}
                                        </SectionSelectSubBox>
                                    </SectionContentDropDown>
                                </div>
                            }
                           
                          
                        </>
                    )}
                    <ContentButtonBar data-test-id="HeaderToggle" onClick={this.toggleHeaderContent}>
                        <ContentHeadinText style={{ color: this.state.headerContent ? `rgba(98, 0, 234, 1)` : `rgba(71, 85, 105, 1)` }}>
                            Header
                        </ContentHeadinText>
                        <img src={this.state.headerContent ? minusIcon : plusIcon} />
                    </ContentButtonBar>
                    {this.state.headerContent && (
                        <FooterContentOuterBox>
                            <HeaderBoxContent>
                                <SocialMediaHeaderBox>
                                    <FooterContentHeadingText>
                                        Show Store Name
                                    </FooterContentHeadingText>
                                    <IOSSwitch
                                        name="checkedB"
                                        onChange={this.toggleStorenameForHeader}
                                        checked={this.state.showStoreName}
                                    />
                                </SocialMediaHeaderBox>
                                <HeaderDropDownSubHeading>Logo</HeaderDropDownSubHeading>
                                <UploadButtonBox style={{ margin: '0 10px 0 20px', width: 'auto' }} data-test-id="ToggleLogoUploadModal" onClick={() => this.HandleLogoUploadModalOpen("logo")}>
                                    <InputFieldText>{this.state.isLogoUploaded ? this.state.SelectedLogoName : "Upload Logo"}</InputFieldText>
                                    <UploadIcon src={!this.state.isLogoUploaded ? uploadIcon : EditIcon} />
                                </UploadButtonBox>
                                <HeaderDropDownSubHeading>
                                    Favicon
                                    <Tooltip TransitionProps={{ timeout: 600 }} title={configJSON.FaviconTooltipText} arrow placement="top">
                                        <img src={TooltipIcon} alt="" style={{ height: '18px', width: '18px' }} />
                                    </Tooltip>
                                </HeaderDropDownSubHeading>
                                <UploadButtonBox style={{ margin: '0 10px 0 20px', width: 'auto' }} data-test-id="ToggleFaviconUploadModal" onClick={() => this.HandleLogoUploadModalOpen("favicon")}>
                                    <InputFieldText>{this.state.isFaviconUploaded ? this.state.SelectedFaviconName : "Upload Favicon"}</InputFieldText>
                                    <UploadIcon src={!this.state.isFaviconUploaded ? uploadIcon : EditIcon} />
                                </UploadButtonBox>
                            </HeaderBoxContent>
                            {/* <Box sx={{ padding: '10px 0 10px 30px' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', padding: '10px 0px' }} style={{ gap: '5px' }}>
                                    <FooterContentHeadingText>
                                        Navigation Settings
                                    </FooterContentHeadingText>
                                    <Box sx={{ fontSize: '12px', fontWeight:400 }}>
                                        <span style={{ fontFamily:'Rubik'}}>{configJSON.HeaderNavigationSettingsMessage}</span>
                                    </Box>
                                </Box>
                                <SocialMediaHeaderBox>
                                    <FooterContentHeadingText>
                                        Open Mobile Menu
                                    </FooterContentHeadingText>
                                    <IOSSwitch
                                        name="checkedB"
                                        onChange={this.toggleMobileMenuForHeader}
                                        checked={this.state.isMobileMenuOpen}
                                    />
                                </SocialMediaHeaderBox>
                                <Box sx={{ paddingLeft: '20px' }}>
                                    {this.state.HeaderNavigation.menuData.map((menuItem, mapIndex, arr) => {
                                        const selectedItems = arr?.map(data => data.selectedOption)
                                        const filteredData = menuItem?.options?.filter(item => !selectedItems.includes(item));

                                        return (
                                            <React.Fragment key={menuItem.id}>
                                                <SectionDropDownSubHeading>{menuItem.label}</SectionDropDownSubHeading>
                                                <SectionSelectSubBox
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left"
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "left"
                                                        },
                                                        getContentAnchorEl: null,
                                                        keepMounted: true
                                                    }}
                                                    displayEmpty
                                                    value={menuItem.selectedOption}
                                                    onChange={(event: any) => this.handleCatagoryChange(event, menuItem.id)}
                                                    style={{ width: 'auto', margin: '0 10px 0 0' }}
                                                    renderValue={(value: unknown) => { return (value === undefined ? <StyledPlaceholder>{menuItem.selectedOption}</StyledPlaceholder> : value as string) }}
                                                >

                                                    <DisabledMenuItem value="" disabled>{menuItem.selectedOption}</DisabledMenuItem>
                                                    {filteredData?.map((categoryItem, index) => {
                                                        return (
                                                            <CustomMenuItem value={categoryItem} key={menuItem.id}>{categoryItem}  </CustomMenuItem>
                                                        )
                                                    })}
                                                </SectionSelectSubBox>
                                            </React.Fragment>
                                        )
                                    })}
                                </Box>
                            </Box> */}
                        </FooterContentOuterBox>
                    )}
                    <ContentButtonBar data-test-id={"SectionButtonId"} onClick={this.toggleSectionContent}>
                        <ContentHeadinText style={{ color: this.state.sectionContent ? `rgba(98, 0, 234, 1)` : `rgba(71, 85, 105, 1)` }}>
                            Sections
                        </ContentHeadinText>
                        <img src={this.state.sectionContent ? minusIcon : plusIcon} />
                    </ContentButtonBar>
                    {this.state.sectionContent && (
                        <>
                            {
                                this.props.parentStates.SectionData.map((sectionItem, index: number, arr) => {
                                    const { sectionName, type, id, metadata, is_deleted } = sectionItem
                                    const indexx = arr?.filter(a => !a.is_deleted)?.map((item, indexVal) => ({ id: item.id, index: indexVal }))
                                    return (
                                        <div key={index}>
                                            {
                                                !is_deleted && type?.toLowerCase() === "banner" &&
                                                <SectionContentDropDownBox key={index}>
                                                    <SectionSelectIcon src={SectionIcon} />
                                                    <SectionDropDownBox data-test-id={"BannerDropDownTestId"} onClick={() => this.toggleDropDown(index, true)}>
                                                        <SectionDropDownText>{`Section ${indexx?.findIndex(obj => obj?.id == id) + 1} - Banner`}</SectionDropDownText>
                                                        <SectionDropDownIcon src={metadata?.isOpen ? dropUpArrowIcon : dropDownArrowIcon} />
                                                    </SectionDropDownBox>
                                                    {
                                                        metadata?.isOpen &&
                                                        <SectionContentDropDown key={index}>
                                                            <DeleteButtonSectionBox>
                                                                <DeleteSectionButton onClick={() => this.openDeleteSectionModal(index, { section_name: `section${index + 1}`, section_type: "banner", is_Deleted: true, id })}>
                                                                    <DeleteButtonIcon src={deleteIcon} />
                                                                </DeleteSectionButton>
                                                            </DeleteButtonSectionBox>
                                                            <SectionDropDownHeading data-test-id={"BannerTextButtonId"}> Type <RedStar component={"span"}>*</RedStar></SectionDropDownHeading>
                                                            <SectionSelectSubBox
                                                                MenuProps={{
                                                                    anchorOrigin: {
                                                                        vertical: "bottom",
                                                                        horizontal: "left"
                                                                    },
                                                                    transformOrigin: {
                                                                        vertical: "top",
                                                                        horizontal: "left"
                                                                    },
                                                                    getContentAnchorEl: null,
                                                                    keepMounted: true
                                                                }}
                                                                onChange={(event) => this.typeSelectHandler(index, event.target.value as string)}
                                                                displayEmpty
                                                                value={type}
                                                            >
                                                                <DisabledMenuItem value="" disabled>Select Section Type</DisabledMenuItem>
                                                                <CustomMenuItem value="banner">Banner</CustomMenuItem>
                                                                {/* <CustomMenuItem value="category">Category Display</CustomMenuItem>
                                                                <CustomMenuItem value="product">Product Display</CustomMenuItem> */}
                                                            </SectionSelectSubBox>
                                                            <SectionDropDownSubHeading>Mobile Banner Image <RedStar component={"span"}>*</RedStar></SectionDropDownSubHeading>
                                                            <UploadButtonBox data-test-id={"BannerMobileTestId"} onClick={() => this.handleUploadModal(index, id)}>
                                                                <InputFieldText data-test-id={"uploadText"}>
                                                                    {sectionItem.mobileBannerUpload && sectionItem.mobileBannerName ?
                                                                        sectionItem.mobileBannerName : "Upload Banner"}
                                                                </InputFieldText>
                                                                <UploadIcon src={
                                                                    sectionItem.mobileBannerUpload && sectionItem.mobileBannerName ?
                                                                        EditIcon : uploadIcon} />
                                                            </UploadButtonBox>

                                                            <AddSectionButtonBox data-test-id={"addSectionButtonId"} onClick={() => this.toggleAddTextToBannerDrawer(true, 'mobile')} style={webStyles.addTextArea} >
                                                                <AddSectionButtonIcon src={!this.state.bannerTextProperties.mobile.textAlignment ? AddSectionIcon : EditIcon} />
                                                                <AddSectionButtonText data-test-id="addSectionText">
                                                                    {this.state.bannerTextProperties.mobile.textAlignment ? 'Edit' : 'Add'} Banner Text - Mobile
                                                                </AddSectionButtonText>
                                                            </AddSectionButtonBox>

                                                            <SectionDropDownSubHeading data-test-id={"uploadDesktopText"}>Desktop Banner Image <RedStar component={"span"}>*</RedStar></SectionDropDownSubHeading>
                                                            <UploadButtonBox onClick={() => this.handleUploadDesktopModal(index, id)}>
                                                                <InputFieldText>
                                                                    {sectionItem.desktopBannerUpload && sectionItem.desktopBannerName ?
                                                                        sectionItem.desktopBannerName : "Upload Banner"}
                                                                </InputFieldText>
                                                                <UploadIcon data-test-id={"BannerDesktopTestId"} src={
                                                                    sectionItem.desktopBannerUpload && sectionItem.desktopBannerName ?
                                                                        EditIcon : uploadIcon} />
                                                            </UploadButtonBox>

                                                            <AddSectionButtonBox data-test-id={"addSectionButtonId"} onClick={() => this.toggleAddTextToBannerDrawer(true, 'desktop')} style={webStyles.addTextArea} >
                                                                <AddSectionButtonIcon src={!this.state.bannerTextProperties.desktop.textAlignment ? AddSectionIcon : EditIcon} />
                                                                <AddSectionButtonText data-test-id="addSectionText">
                                                                    {this.state.bannerTextProperties.desktop.textAlignment ? 'Edit' : 'Add'} Banner Text - Desktop
                                                                </AddSectionButtonText>
                                                            </AddSectionButtonBox>

                                                            <SectionDropDownSubHeading> Banner URL <RedStar component={"span"}>*</RedStar></SectionDropDownSubHeading>
                                                            <SectionInputSubBox name="banner_url" data-test-id={"BannerChangeTestId"} type="text" value={sectionItem.bannerUrl} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleBannerUrl(e, id, "banner", `section${index + 1}`, index)} placeholder="www.testurl.com" />
                                                            {this.state.checkBannerUrl && (<ErrorText>{configJSON.textUrlNotValid}</ErrorText>)}
                                                        </SectionContentDropDown>
                                                    }
                                                </SectionContentDropDownBox>
                                            }
                                            {
                                                !is_deleted && (type?.toLowerCase() === "category") &&
                                                <SectionContentDropDownBox key={index}>
                                                    <SectionSelectIcon src={SectionIcon} />
                                                    <SectionDropDownBox onClick={() => this.toggleDropDown(index, false)}>
                                                        <SectionDropDownText>{`Section ${indexx?.findIndex(obj => obj.id == id) + 1} - ${sectionName}`}</SectionDropDownText>
                                                        <SectionDropDownIcon src={metadata?.isOpen ? dropUpArrowIcon : dropDownArrowIcon} />
                                                    </SectionDropDownBox>
                                                    {
                                                        metadata?.isOpen &&
                                                        <SectionContentDropDown key={index}>
                                                            <DeleteButtonSectionBox>
                                                                <DeleteSectionButton onClick={() => this.openDeleteSectionModal(index, { section_name: `section${index + 1}`, section_type: "catagory", is_Deleted: true, id })}>
                                                                    <DeleteButtonIcon src={deleteIcon} />
                                                                </DeleteSectionButton>
                                                            </DeleteButtonSectionBox>
                                                            <SectionDropDownHeading data-test-id="CategoryTextButtonId"> Type <RedStar component={"span"}>*</RedStar></SectionDropDownHeading>
                                                            <SectionSelectSubBox
                                                                MenuProps={{
                                                                    anchorOrigin: {
                                                                        vertical: "bottom",
                                                                        horizontal: "left"
                                                                    },
                                                                    transformOrigin: {
                                                                        vertical: "top",
                                                                        horizontal: "left"
                                                                    },
                                                                    getContentAnchorEl: null,
                                                                    keepMounted: true
                                                                }}
                                                                onChange={(event) => this.typeSelectHandler(index, event.target.value as string)}
                                                                displayEmpty
                                                                value={type}
                                                                data-test-id={`Selectid_{$index}`}
                                                            >
                                                                <DisabledMenuItem value="" disabled>Select Section Type</DisabledMenuItem>
                                                                <CustomMenuItem value="banner">Banner</CustomMenuItem>
                                                                <CustomMenuItem value="category">Category Display</CustomMenuItem>
                                                                <CustomMenuItem value="product">Product Display</CustomMenuItem>
                                                            </SectionSelectSubBox>
                                                            <SectionDropDownSubHeading> Section Name  <RedStar component={"span"}>*</RedStar></SectionDropDownSubHeading>
                                                            <UploadButtonBox>
                                                                <SectionInputNameBox name="section_type" placeholder="Section Name" value={sectionName} onChange={(event) => this.changeSectionName(event, id, index, `section${index + 1}`)} />
                                                                <UploadIcon src={EditIcon} />
                                                            </UploadButtonBox>
                                                            {metadata?.categories?.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        <SectionDropDownSubHeading> Category {index + 1} <RedStar component={"span"}>*</RedStar></SectionDropDownSubHeading>
                                                                        <SectionSelectSubBox
                                                                            MenuProps={{
                                                                                anchorOrigin: {
                                                                                    vertical: "bottom",
                                                                                    horizontal: "left"
                                                                                },
                                                                                transformOrigin: {
                                                                                    vertical: "top",
                                                                                    horizontal: "left"
                                                                                },
                                                                                getContentAnchorEl: null,
                                                                                keepMounted: true
                                                                            }}
                                                                            onChange={(event) => this.handleSectionMainData(id, type, `section${index + 1}`, "Category1", event.target.value as string, index)}
                                                                            name="catagory1"
                                                                            renderValue={(value: unknown) => { return (value === undefined ? <StyledPlaceholder>Select</StyledPlaceholder> : value as string) }}
                                                                            displayEmpty
                                                                            value={item.selected}
                                                                        >
                                                                            <DisabledMenuItem value="" disabled>Select Category</DisabledMenuItem>
                                                                            {
                                                                                metadata?.categories?.map((categoryItem, index) => {
                                                                                    return (
                                                                                        <CustomMenuItem value={categoryItem.category_name} key={index}>{categoryItem.category_name}</CustomMenuItem>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </SectionSelectSubBox>
                                                                    </>
                                                                )
                                                            })}
                                                        </SectionContentDropDown>
                                                    }
                                                </SectionContentDropDownBox >
                                            }
                                            {
                                                !is_deleted && (type?.toLowerCase() === "product") &&
                                                <SectionContentDropDownBox key={index}>
                                                    <SectionSelectIcon src={SectionIcon} />
                                                    <SectionDropDownBox onClick={() => this.toggleDropDown(index, false)}>
                                                        <SectionDropDownText>{`Section ${indexx?.findIndex(obj => obj.id == id) + 1} - ${sectionName}`}</SectionDropDownText>
                                                        <SectionDropDownIcon src={metadata?.isOpen ? dropUpArrowIcon : dropDownArrowIcon} />
                                                    </SectionDropDownBox>
                                                    {
                                                        metadata?.isOpen &&
                                                        <SectionContentDropDown key={index}>
                                                            <DeleteButtonSectionBox>
                                                                <DeleteSectionButton onClick={() => this.openDeleteSectionModal(index, { section_name: `section${index + 1}`, section_type: "product", is_Deleted: true, id })}>
                                                                    <DeleteButtonIcon src={deleteIcon} />
                                                                </DeleteSectionButton>
                                                            </DeleteButtonSectionBox>
                                                            <SectionDropDownHeading> Type <RedStar component={"span"}>*</RedStar></SectionDropDownHeading>
                                                            <SectionSelectSubBox
                                                                MenuProps={{
                                                                    anchorOrigin: {
                                                                        vertical: "bottom",
                                                                        horizontal: "left"
                                                                    },
                                                                    transformOrigin: {
                                                                        vertical: "top",
                                                                        horizontal: "left"
                                                                    },
                                                                    getContentAnchorEl: null,
                                                                    keepMounted: true
                                                                }}
                                                                onChange={(event) => this.typeSelectHandler(index, event.target.value as string)}
                                                                displayEmpty
                                                                value={type}
                                                            >
                                                                <DisabledMenuItem value="" disabled>Select Section Type</DisabledMenuItem>
                                                                <CustomMenuItem value="banner">Banner</CustomMenuItem>
                                                                <CustomMenuItem value="category">Category Display</CustomMenuItem>
                                                                <CustomMenuItem value="product">Product Display</CustomMenuItem>
                                                            </SectionSelectSubBox>
                                                            <SectionDropDownSubHeading> Section Name <RedStar component={"span"}>*</RedStar></SectionDropDownSubHeading>
                                                            <UploadButtonBox>
                                                                <SectionInputNameBox placeholder="Section Name" value={sectionName} onChange={(event) => this.changeSectionName(event, id.toString(), index, `section${index + 1}`)} />
                                                                {<UploadIcon src={EditIcon} />}
                                                            </UploadButtonBox>
                                                            {metadata?.products?.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        <SectionDropDownSubHeading> Product {index + 1} <RedStar component={"span"}>*</RedStar></SectionDropDownSubHeading>
                                                                        <SectionSelectSubBox
                                                                            MenuProps={{
                                                                                anchorOrigin: {
                                                                                    vertical: "bottom",
                                                                                    horizontal: "left"
                                                                                },
                                                                                transformOrigin: {
                                                                                    vertical: "top",
                                                                                    horizontal: "left"
                                                                                },
                                                                                getContentAnchorEl: null,
                                                                                keepMounted: true
                                                                            }}
                                                                            onChange={(event) => this.handleSectionMainData(id, type, `section${index + 1}`, "Category1", event.target.value as string, index)}
                                                                            name="catagory1"
                                                                            renderValue={(value: unknown) => { return (value === undefined ? <StyledPlaceholder>Select</StyledPlaceholder> : value as string) }}
                                                                            displayEmpty
                                                                            value={item.selected}
                                                                        >
                                                                            <DisabledMenuItem value="" disabled>Select Category</DisabledMenuItem>
                                                                            {
                                                                                item?.products?.map((productItem, index) => {
                                                                                    return (
                                                                                        <CustomMenuItem value={productItem.product_name} key={index}>{productItem.product_name}</CustomMenuItem>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </SectionSelectSubBox>
                                                                    </>
                                                                )
                                                            })}
                                                        </SectionContentDropDown>
                                                    }
                                                </SectionContentDropDownBox>
                                            }
                                        </div>
                                    )
                                })
                            }
                            {
                                this.state.addSectionBox &&
                                <SectionContentDropDownBox>
                                    <SectionSelectIcon src={SectionIcon} />
                                    <SectionDropDownBox>
                                        <SectionDropDownText>Section {this.props.parentStates.SectionData.length > 0
                                            ? this.props.parentStates.SectionData.filter(section => !section.is_deleted).length + 1
                                            : 1}</SectionDropDownText>
                                        <SectionDropDownIcon src={dropDownArrowIcon} />
                                    </SectionDropDownBox>
                                    <SectionContentDropDown>
                                        <DeleteButtonSectionBox>
                                            <DeleteSectionButton>
                                                <DeleteButtonIcon data-test-id={"removeSectionButtonId"} src={deleteIcon} onClick={this.removeAddSectionBox} />
                                            </DeleteSectionButton>
                                        </DeleteButtonSectionBox>
                                        <SectionDropDownHeading> Type <RedStar component={"span"}>*</RedStar></SectionDropDownHeading>
                                        <SectionSelectSubBox
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left"
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left"
                                                },
                                                getContentAnchorEl: null,
                                                keepMounted: true
                                            }}
                                            displayEmpty
                                            value={this.state.selectAddSectionType}
                                            onChange={this.addNewSection}
                                            data-test-id={"selectAddSectionTypeId"}
                                        >
                                            <DisabledMenuItem value="" disabled>Select Section Type</DisabledMenuItem>
                                            <CustomMenuItem data-test-id="SectionText" value="banner">Banner</CustomMenuItem>
                                            {/* <CustomMenuItem value="category">Category Display</CustomMenuItem>
                                            <CustomMenuItem value="product">Product Display</CustomMenuItem> */}
                                        </SectionSelectSubBox>
                                    </SectionContentDropDown>
                                </SectionContentDropDownBox>
                            }
                             
                                {this.props.parentStates.SectionData.filter(item => !item.is_deleted && item.type == "banner").length > 0 ? 
                                <AddSectionButtonBox data-test-id={"addSectionButtonId"} >
                                {/* <AddSectionButtonIcon src={AddSectionIcon} />
                                <AddSectionButtonText data-test-id="addSectionText">Add Section</AddSectionButtonText> */}
                                </AddSectionButtonBox>
                                : 
                                <AddSectionButtonBox data-test-id={"addSectionButtonId"} onClick={() => this.addSection()}>
                                    <AddSectionButtonIcon src={AddSectionIcon} />
                                    <AddSectionButtonText data-test-id="addSectionText">Add Section</AddSectionButtonText>
                                </AddSectionButtonBox>
                                }
                            
                        </>
                    )}
                    <ContentButtonBar onClick={this.toggleFooterContent} data-test-id={"ContactInformationButtonId"}>
                        <ContentHeadinText style={{ color: this.state.footerContent ? `rgba(98, 0, 234, 1)` : `rgba(71, 85, 105, 1)` }}>
                            Footer
                        </ContentHeadinText>
                        <img src={this.state.footerContent ? minusIcon : plusIcon} />
                    </ContentButtonBar>
                    {this.state.footerContent && (
                        <FooterContentOuterBox style={{ marginLeft: '20px' }}>
                            <FooterBox>
                                <SocialMediaHeaderBox>
                                    <FooterContentHeadingText data-test-id={"DisplayTextButtonId"} >
                                        Contact Information
                                    </FooterContentHeadingText>
                                    <IOSSwitch
                                        name="is_contact"
                                        onChange={this.toggleContactInformation}
                                        checked={this.state.showContactInformation}
                                        data-test-id={"ContactInformationId"}
                                    />
                                </SocialMediaHeaderBox>
                                {this.state.showContactInformation && (
                                    <ContactDetailsText data-test-id={"DisplayAreaTextId"}>
                                        {configJSON.textAreaWillDisplay}
                                    </ContactDetailsText>
                                )}
                            </FooterBox>
                            <FooterBox>
                                <SocialMediaHeaderBox>
                                    <FooterContentHeadingText>Social Media
                                        <Tooltip TransitionProps={{ timeout: 600 }} title={configJSON.SocialTooltipText} arrow placement="top">
                                            <ImageInfo src={InfoIcon} />
                                        </Tooltip>
                                    </FooterContentHeadingText>

                                    <IOSSwitch
                                        name="is_social_media"
                                        checked={this.state.showSocialLinks}
                                        onChange={this.toggleSocialLinksBox}
                                        data-test-id={"SocialMediaId"}
                                    />
                                </SocialMediaHeaderBox>
                                {
                                    this.state.showSocialLinks &&
                                    <BoxWithMarginLeft>
                                        <SocialMediaLinksBox>
                                            <SocialMediaLinksHeading data-test-id={"SocialMediaId"}>
                                                Facebook
                                            </SocialMediaLinksHeading>
                                            <SocialInputBox>
                                                <SocialMediaLinksInputBox name="facebook_url" type="text" value={this.state.facebookUrl} data-test-id={"facebookChangeTestId"} onChange={this.handleChangeFacebook} placeholder="www.facebook.com/builder.ai" />
                                                {this.state.checkStatus && (<ValidUrl src={checkedImg} />)}
                                            </SocialInputBox>
                                            {this.state.errorStatus && (<ErrorText>{configJSON.textUrlNotValid}</ErrorText>)}
                                            {this.state.isFacebookError && <ErrorText>{configJSON.textUrlRequired}</ErrorText>}
                                        </SocialMediaLinksBox>
                                        <SocialMediaLinksBox>
                                            <SocialMediaLinksHeading>
                                                Instagram
                                            </SocialMediaLinksHeading>
                                            <SocialInputBox>
                                                <SocialMediaLinksInputBox name="instagram_url" type="text" value={this.state.instagramUrl} data-test-id={"InstagramChangeTestId"} onChange={this.handleChangeInstagram} placeholder="www.instagram.com/builder.ai" />
                                                {this.state.checkInstagramStatus && (<ValidUrl src={checkedImg} />)}
                                            </SocialInputBox>
                                            {this.state.errorInstagramStatus && (<ErrorText>{configJSON.textUrlNotValid}</ErrorText>)}
                                            {this.state.isInstagramError && <ErrorText>{configJSON.textUrlRequired}</ErrorText>}
                                        </SocialMediaLinksBox>
                                        <SocialMediaLinksBox>
                                            <SocialMediaLinksHeading>Twitter</SocialMediaLinksHeading>
                                            <SocialInputBox>
                                                <SocialMediaLinksInputBox name="x_url" type="text" value={this.state.twitterUrl} data-test-id={"TwitterChangeTestId"} onChange={this.handleChangeTwitter} placeholder="www.twitter.com/builder.ai" />
                                                {this.state.checkTwitterStatus && (<ValidUrl src={checkedImg} />)}
                                            </SocialInputBox>
                                            {this.state.errorTwitterStatus && (<ErrorText>{configJSON.textUrlNotValid}</ErrorText>)}
                                            {this.state.isTwitterError && <ErrorText>{configJSON.textUrlRequired}</ErrorText>}
                                        </SocialMediaLinksBox>
                                        <SocialMediaLinksBox>
                                            <SocialMediaLinksHeading>
                                                Linkedin
                                            </SocialMediaLinksHeading>
                                            <SocialInputBox>
                                                <SocialMediaLinksInputBox name="linkedin_url" type="text" value={this.state.linkedinUrl} onChange={this.handleChangeLinkedin} data-test-id={"LinkedinChangeTestId"} placeholder="www.linkedin.com/builder.ai" />
                                                {this.state.checkLinkedinStatus && (<ValidUrl src={checkedImg} />)}
                                            </SocialInputBox>
                                            {this.state.errorLinkedinStatus && (<ErrorText>{configJSON.textUrlNotValid}</ErrorText>)}
                                            {this.state.isLinkedinError && <ErrorText>{configJSON.textUrlRequired}</ErrorText>}
                                        </SocialMediaLinksBox>
                                        <SocialMediaLinksBox>
                                            <SocialMediaLinksHeading>
                                                Youtube
                                            </SocialMediaLinksHeading>
                                            <SocialInputBox>
                                                <SocialMediaLinksInputBox name="youtube_url" type="text" value={this.state.youTubeUrl} data-test-id={"YouTubeChangeTestId"} onChange={this.handleChangeYouTube} placeholder="www.youtube.com/builder.ai" />
                                                {this.state.checkYouTubeStatus && (<ValidUrl src={checkedImg} />)}
                                            </SocialInputBox>
                                            {this.state.errorYouTubeStatus && (<ErrorText>{configJSON.textUrlNotValid}</ErrorText>)}
                                            {this.state.isYoutubeError && <ErrorText>{configJSON.textUrlRequired}</ErrorText>}
                                        </SocialMediaLinksBox>
                                    </BoxWithMarginLeft>
                                }
                            </FooterBox>
                            <FooterBox>
                                <SocialMediaHeaderBox>
                                    <FooterContentHeadingText>App Links </FooterContentHeadingText>
                                    <IOSSwitch
                                        name="is_app_link"
                                        checked={this.state.showStoreLinkInputBox}
                                        onChange={this.toggleAppStoreLinkInputBox}
                                        data-test-id={"StoreId"}
                                    />
                                </SocialMediaHeaderBox>
                                {this.state.showStoreLinkInputBox && (
                                    <BoxWithMarginLeft>
                                        <SocialMediaLinksBox>
                                            <SocialMediaLinksHeading data-test-id={"DisplayStoreTextId"}>
                                                Play Store URL
                                            </SocialMediaLinksHeading>
                                            <SocialInputBox>
                                                <SocialMediaLinksInputBox name="playstore_url" type="text" value={this.state.playStoreUrl} onChange={this.handleChangePlayStore} data-test-id={"PlayStoreChangeTestId"} placeholder="www.play.google.com/store/apps/" />
                                                {this.state.checkPlayStoreStatus && (<ValidUrl src={checkedImg} />)}
                                            </SocialInputBox>
                                            {this.state.isPlayError && <ErrorText>{configJSON.textUrlRequired}</ErrorText>}
                                            {this.state.errorPlayStoreStatus && (<ErrorText>{configJSON.textUrlNotValid}</ErrorText>)}
                                        </SocialMediaLinksBox>
                                        <SocialMediaLinksBox>
                                            <SocialMediaLinksHeading>
                                                App Store URL
                                            </SocialMediaLinksHeading>
                                            <SocialInputBox>
                                                <SocialMediaLinksInputBox name="appstore_url" type="text" value={this.state.appStoreUrl} onChange={this.handleChangeAppStore} data-test-id={"AppStoreChangeTestId"} placeholder="www.apps.apple.com/in/app/micro..." />
                                                {this.state.checkAppStoreStatus && (<ValidUrl src={checkedImg} />)}
                                            </SocialInputBox>
                                            {this.state.errorAppStoreStatus && (<ErrorText>{configJSON.textUrlNotValid}</ErrorText>)}
                                            {this.state.isAppError && <ErrorText>{configJSON.textUrlRequired}</ErrorText>}
                                        </SocialMediaLinksBox>
                                    </BoxWithMarginLeft>
                                )}
                            </FooterBox>
                            <CopyRightBox>
                                <FooterContentHeadingText>Copyright</FooterContentHeadingText>
                                <CopyrightInputBox name="copyright" type="text" value={this.state.copyRight} onChange={this.handleChangeCopyRight} data-test-id={"CopyRightChangeTestId"} />
                                <ErrorText>{this.state.copyRightError}</ErrorText>
                            </CopyRightBox>
                        </FooterContentOuterBox>
                    )}

                </ContentBar>
                <Modal open={this.state.cropperSectionOpen}>
                    <CropperBox data-testid="cropper-dialog">
                        <TitleBlock style={{ padding: "35px 40px 0px" }}>
                            <ModalTitle>
                                {this.state.isBannerType === "mobile" ? configJSON.mobileBanner : configJSON.desktopBanner}
                                <Typography style={{ fontSize: "14px", color: "rgba(112, 112, 112, 1)", lineHeight: "32px" }}>{configJSON.mobileCropperText} {this.state.isBannerType === "mobile" ? configJSON.textMobileDevice : configJSON.textDesktopDevice}</Typography>
                            </ModalTitle>
                            <Close
                                src={CloseIcon}
                                data-testid="closeButton"
                                onClick={this.handleSectionCropperClose}
                                style={{ width: 24, height: 24 }}
                            />
                        </TitleBlock>
                        <AddCropper
                            onCancel={this.handleSectionCropperClose}
                            dataToPass={{
                                selectedFile: this.state.selectedFile,
                                type: this.state.selectedFileType,
                                ratio: 3.1
                            }}
                            isBanner={this.state.isBannerType}
                            onSubmit={(data: string) => this.handleCropperSubmit(data)}
                        />
                    </CropperBox>
                </Modal>

                <Modal open={this.state.uploadMobileBannerOpen}>
                    <SelectionBox>
                        <TitleBlock>
                            <ModalTitle data-test-id="BannerTestId">
                                {configJSON.textBannerImageSelection}
                                <ModalInnerText>{configJSON.selectionMobileText}</ModalInnerText>
                            </ModalTitle>
                            <Close
                                src={CloseIcon}
                                onClick={this.handleSectionClose}
                                style={{ width: 24, height: 24 }}
                            />
                        </TitleBlock>
                        <TextSelectionPixel>
                            {this.state.isBannerType === "mobile" ? configJSON.textSelectionPixelMobile : configJSON.textSelectionPixel}

                        </TextSelectionPixel>
                        <GridTextCenter item xs={12}>
                            <UploadButton
                                color="secondary"
                                variant="contained"
                            >
                                <UploadBox
                                    data-testid={"UploadBanner"}
                                    onSelectFile={this.selectImageFile.bind(this)}
                                    uploadText={configJSON.textUploadButton}
                                />
                            </UploadButton>
                        </GridTextCenter>
                        <TextChooseCatalogue onClick={this.handleSelectCatalogue}>{configJSON.textChooseCatalogue}</TextChooseCatalogue>
                    </SelectionBox>
                </Modal>

                <Modal open={this.state.uploadHeaderLogoModalOpen}>
                    <HeaderModalBox>
                        <TitleBlock style={{ padding: "40px 40px 0px" }}>
                            <ModalTitle>
                                {this.state.uploadModalType === "logo" ? configJSON.textUploadLogo : this.state.uploadModalType === "favicon" ? configJSON.textUploadFavicon : ""}
                            </ModalTitle>
                            <Close
                                src={CloseIcon}
                                onClick={this.HandleLogoUploadModalClose}
                                style={{ width: 24, height: 24 }}
                            />
                        </TitleBlock>
                        <UploadBox data-test-id="LogoUploadModal" height="379px" width="617px" uploadMsg={configJSON.textUploadLogoModalText} onSelectFile={this.selectLogoFile.bind(this)} uploadIcon={plusIcon} sizeMsg={this.state.uploadModalType === "favicon" ? configJSON.textUploadFaviconModalMessage : configJSON.textUploadLogoModalMessage} acceptedFormat="image/png, image/jpeg, image/jpg" uploadIconStyles={{ height: '14px', width: '14px' }} />
                        <ModalContentFooter>
                            <CancelButton data-test-id="TriggerLogoModalClose" onClick={this.HandleLogoUploadModalClose}>Cancel</CancelButton>
                            <ModalEditImage data-test-id="TriggerLogoModalNext" style={{ background: "rgba(217, 217, 217, 1)" }} onClick={this.HandleNextForHeaderModal} disabled={!this.state.isHeaderImageUploaded}>Next</ModalEditImage>
                        </ModalContentFooter>
                    </HeaderModalBox>
                </Modal>

                <Modal open={this.state.HeaderCropSectionOpen}>
                    <HeaderModalBox >
                        <HeaderLogoTitleBox>
                            <ModalTitle>
                                {this.state.uploadModalType === "logo" ? (this.state.isLogoUploaded ? "Edit Logo" : configJSON.addLogoText) : configJSON.addFaviconText}
                            </ModalTitle>
                            <Close
                                src={CloseIcon}
                                onClick={this.HandleLogoUploadModalClose}
                                style={{ width: 24, height: 24 }}
                            />
                        </HeaderLogoTitleBox>
                        <AddCropper
                            onCancel={this.HandleNextForHeaderModalClose}
                            dataToPass={{
                                selectedFile: this.state.uploadModalType === "logo" ? this.state.selectedLogo : this.state.selectedFavicon,
                                type: this.state.uploadModalType === "logo" ? this.state.SelectedLogoFileType : this.state.SelectedFaviconFileType,
                                ratio: this.state.uploadModalType === "logo" ? "2.5:1" : "1"
                            }}
                            isBanner={this.state.isBannerType}
                            onSubmit={(data: string) => this.handleLogoFaviconCropper(data)}
                            data-test-id="CropModalSuccessSubmit"

                        />
                    </HeaderModalBox>
                </Modal>

                <CustomModal
                    open={this.state.selectedModal}
                    onClose={this.closeSelectedModal}
                >
                    <ModalContent>
                        <Box className="hiClass">
                            <Box style={{ padding: '20px 45px 10px 30px', display: 'flex', flexDirection: 'column', gap: '15px' }}>
                                <ModalHeaderBox>
                                    <ModalTitleText>Select image </ModalTitleText>
                                    <CloseModlaIcon src={CloseIcon} onClick={() => this.closeSelectedModal()} />
                                </ModalHeaderBox>
                                <ModalSearchImageBox>
                                    <SearchIconBox>
                                        <SearchIcon src={ModalSearchIcon} />
                                    </SearchIconBox>
                                    <SearchBoxInput placeholder="Search images" onChange={this.handleImageSearch} />
                                </ModalSearchImageBox>
                            </Box>
                            <Box style={{ padding: '7px 30px 10px', }}>
                                <MuiCustomImageCatalougeHolder container>
                                    {this.state.isLoading ?
                                        <Backdrop style={{ zIndex: 9999 }} open={this.state.isLoading}>
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    left: "50%",
                                                    top: "50%",
                                                    transform: "translate(-50%, -50%)",
                                                }}
                                            >
                                                <CircularProgress
                                                    size="5rem"
                                                    color="primary"
                                                    data-testid="page-loader"
                                                />
                                            </div>
                                        </Backdrop> :
                                        this.state.modalImages.length === 0 ? (
                                            <Grid item xs={12}>
                                                No results found
                                            </Grid>
                                        ) : (
                                            this.state.modalImages.map((imageData, index: number) => {
                                                const { name, url, isSelect, type } = imageData;
                                                return (
                                                    <Grid item xs={4} key={index}>
                                                        {
                                                            isSelect === true ?
                                                                <Box style={{ fontSize: '12px', lineHeight: '24px', fontWeight: 400 }}>
                                                                    <SelectedImage>
                                                                        <SelectedImageIconBox>
                                                                            <SelectedImageIcon src={checkIcon} />
                                                                        </SelectedImageIconBox>
                                                                        <CatalogueImage src={url} alt={name} />
                                                                    </SelectedImage>
                                                                    {name}
                                                                </Box>
                                                                :
                                                                <Box style={{ fontSize: '12px', lineHeight: '24px', fontWeight: 400 }}>
                                                                    <ImageBox onClick={() => this.selectImage(index, name)}>
                                                                        <CatalogueImage src={url} alt={name} />
                                                                    </ImageBox>
                                                                    {name}
                                                                </Box>
                                                        }
                                                    </Grid>
                                                );
                                            })
                                        )
                                    }
                                </MuiCustomImageCatalougeHolder>
                            </Box>
                        </Box>
                        <ModalContentFooter>
                            <CancelButton onClick={this.closeSelectedModal}>Cancel</CancelButton>
                            <ModalEditImage onClick={this.handleNextCatalogue} disabled={!this.state.selectedImage.isSelect} style={{ height: '64px', width: '128px', background: this.state.selectedImage.isSelect ? "rgba(0, 214, 89, 1)" : "rgba(217, 217, 217, 1)", color: this.state.selectedImage.isSelect ? "rgba(60, 62, 73, 1)" : "rgba(255, 255, 255, 1)" }}>Next</ModalEditImage>
                        </ModalContentFooter>
                    </ModalContent>
                </CustomModal>

                <DeleteCustomModal
                    open={this.state.deleteSectionModal}
                    onClose={this.closeDeleteSectionModal}
                >
                    <DeleteModalContentBox>
                        <ModalHeadingBox>
                            <ModalHeadingFlexBox>
                                <DeleteModalTitle>Are you sure?</DeleteModalTitle>
                                <CloseModalButton onClick={this.closeDeleteSectionModal}>
                                    <DeleteCloseIcon src={CloseIcon} />
                                </CloseModalButton>
                            </ModalHeadingFlexBox>
                            <ModalInformationText>{configJSON.textModalRemoved}</ModalInformationText>
                        </ModalHeadingBox>
                        <ModalFooterBox>
                            <CancelDeleteButton onClick={this.closeDeleteSectionModal}>Cancel</CancelDeleteButton>
                            <YesDeleteButton onClick={this.deleteSection}>Yes, delete</YesDeleteButton>
                        </ModalFooterBox>
                    </DeleteModalContentBox>
                </DeleteCustomModal>

                <Drawer
                    anchor="bottom"
                    open={this.state.isAddTextToBannerDrawerOpen}
                    onClose={() => this.toggleAddTextToBannerDrawer(false, this.state.chosenBannerPlatform)}
                    hideBackdrop
                    PaperProps={{
                        style: webStyles.drawerStyle
                    }}
                >
                    <div style={webStyles.containerDrawer}>
                        <div style={webStyles.topDrawer}>
                            <div style={webStyles.maxWidth}>
                                <SectionDropDownHeading>Text Alignment</SectionDropDownHeading>
                                <SectionSelectSubBox
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null,
                                        keepMounted: true
                                    }}
                                    displayEmpty
                                    value={this.state.bannerTextProperties[this.state.chosenBannerPlatform].textAlignment}
                                    style={webStyles.maxWidth}
                                >
                                    <DisabledMenuItem value="" disabled>Select Alignment</DisabledMenuItem>
                                    {configJSON.textAlignmentList.map((font: string, index: number) => {
                                        return (
                                            <CustomMenuItem 
                                                onClick={() => this.onChangeBannerTextProperty("textAlignment", font, this.state.chosenBannerPlatform)}
                                                value={font} 
                                                key={index}
                                            >{font}</CustomMenuItem>
                                        )
                                    })}
                                </SectionSelectSubBox>
                            </div>
                            <div style={webStyles.maxWidth}>
                                <SectionDropDownHeading>Text Position</SectionDropDownHeading>
                                <SectionSelectSubBox
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null,
                                        keepMounted: true
                                    }}
                                    displayEmpty
                                    value={this.state.bannerTextProperties[this.state.chosenBannerPlatform].textPosition}
                                    style={webStyles.maxWidth}
                                >
                                    <DisabledMenuItem value="" disabled>Select Position</DisabledMenuItem>
                                    {(this.state.chosenBannerPlatform === 'desktop' ? configJSON.desktopTextPositionList : configJSON.textPositionList).map((font: string, index: number) => {
                                        return (
                                            <CustomMenuItem 
                                                onClick={() => this.onChangeBannerTextProperty("textPosition", font, this.state.chosenBannerPlatform)}
                                                value={font} 
                                                key={index}
                                            >{font}</CustomMenuItem>
                                        )
                                    })}
                                </SectionSelectSubBox>
                            </div>
                        </div>
                        <div style={webStyles.relative}>
                            <SectionDropDownHeading>Header Text</SectionDropDownHeading>
                            <TextField
                                multiline
                                minRows={3}
                                maxRows={3}
                                placeholder="Enter your text here"
                                style={webStyles.maxWidth}
                                variant="outlined"
                                InputProps={{style: { padding: 12 }}}
                                value={this.state.bannerTextProperties[this.state.chosenBannerPlatform].headerText}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeBannerTextProperty("headerText", e.target.value, this.state.chosenBannerPlatform)}
                            /> 
                            <span style={webStyles.headerTextPlatform}>{this.state.bannerTextProperties[this.state.chosenBannerPlatform].headerText.length}/40</span>
                        </div>
                        <div style={webStyles.bottomDrawer}>
                            <div style={webStyles.maxWidth} className="color-picker-on-drawer" >
                                <SectionDropDownHeading>Font Colour</SectionDropDownHeading>
                                <ColorPicker
                                    value={this.state.bannerTextProperties[this.state.chosenBannerPlatform].fontColor}
                                    hintText={this.state.bannerTextProperties[this.state.chosenBannerPlatform].fontColor}
                                    onChange={(color: string) => this.onChangeBannerTextProperty("fontColor", color, this.state.chosenBannerPlatform)}
                                    style={webStyles.maxWidth}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <section
                                                    style={{ ...webStyles.adornment , ...{ 
                                                        background: this.state.bannerTextProperties[this.state.chosenBannerPlatform].fontColor,
                                                    }}}
                                                />
                                            </InputAdornment>
                                        ),
                                        inputProps: {
                                            style: webStyles.inputColorPicker
                                        },
                                    }}
                                />
                            </div>
                            <div style={webStyles.maxWidth} className="show_always">
                                <SectionDropDownHeading>Font Size</SectionDropDownHeading>
                                <SectionInputSubBox
                                    className="show_always"
                                    type="number" 
                                    placeholder="Enter font size" 
                                    style={webStyles.maxWidth} 
                                    value={this.state.bannerTextProperties[this.state.chosenBannerPlatform].fontSize} 
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
                                        this.onChangeBannerTextProperty("fontSize", e.target.value, this.state.chosenBannerPlatform)} 
                                data-test-id="fontsizeId"
                                />
                            </div>
                        </div>
                    </div>
                    <div style={webStyles.viewButtonDrawer}>
                        <button
                            onClick={() => this.toggleAddTextToBannerDrawer(false, this.state.chosenBannerPlatform)}
                            style={webStyles.cancelButtonDrawer}
                        >Cancel</button>
                        <button
                            onClick={() => this.state.chosenBannerPlatform === "mobile" ? this.handleMobileBannerMetadata() : this.handleDesktopBannerMetadata()}
                            style={webStyles.applyButtonDrawer}
                        >Apply</button>
                    </div>
                </Drawer>
            </>
            // Customizable Area End
        );

    }
};

// Customizable Area Start
export default withDialog(withLoader(withHeaderBar(ContactInformation)));
export { ContactInformation }
// Customizable Area End

const webStyles = {
    applyButtonDrawer: {
        backgroundColor: '#00D659',
        color: '#3C3E49',
        fontSize: 16,
        fontWeight: 500,
        textAlign: 'center',
        cursor: 'pointer',
        width: 132,
        height: '100%',
        borderRadius: 3,
        border: 'none'
    } as React.CSSProperties,
    cancelButtonDrawer: {
        backgroundColor: '#fff',
        color: '#3C3E49',
        fontSize: 12,
        fontWeight: 400,
        textAlign: 'center',
        cursor: 'pointer',
        height: '100%',
        borderRadius: 3,
        border: 'none',
        textDecoration: 'underline'
    } as React.CSSProperties,
    viewButtonDrawer: {
        borderTop: "1px solid #E8E8E8",
        marginTop: 'auto',
        display: "flex",
        justifyContent: "flex-end",
        gap: 20,
        height: 64,
        alignItems: "center"
    } as React.CSSProperties,
    inputColorPicker: {
        color: '#000',
        fontSize: 14,
        textTransform: 'uppercase',
        opacity: 1,
        fontWeight: 500,
        padding: '15.7px 10px',
    } as React.CSSProperties,
    adornment: {
        cursor: "pointer",
        height: "24px",
        width: "24px",
        borderRadius: "50%"
    } as React.CSSProperties,
    headerTextPlatform: {
        position: 'absolute',
        bottom: 10,
        right: 10,
        color: "#64748B",
        fontSize: 10
    } as React.CSSProperties,
    bottomDrawer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: '20px'
    } as React.CSSProperties,
    relative: {
        position: 'relative'
    } as React.CSSProperties,
    addTextArea: {
        padding: "10px"
    },
    maxWidth: {
        width: "100%"
    },
    drawerStyle: {
        left: 'auto',
        right: '20px',
        width: "400px", 
        height: "420px",
        backgroundColor: "rgba(255, 255, 255, 1)",
        boxShadow: "-4px -9px 20px 0px #00000033",
        borderTop: '9px solid #6200EA',
    },
    containerDrawer: {
        display: "flex",
        flexDirection: "column",
        gap: 10,
        padding: "30px 24px 0 24px"
    } as React.CSSProperties,
    topDrawer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: '20px'
    } as React.CSSProperties,
}

// Customizable Area Start
const CustomMenuItem = styled(MenuItem)({
    "&.MuiListItem-root.Mui-selected": {
        backgroundColor: "#fff",
    },
    "&.MuiListItem-root.Mui-selected:hover": {
        backgroundColor: "#00000014",
    },
    "&.MuiSelect-select": {
        backgroundColor: "#fff !important",
    },
    "&.Mui-focused .MuiSelect-select": {
        backgroundColor: "#fff !important",
    },
});

const MuiCustomImageCatalougeHolder = styled(Grid)({
    position: 'relative',
    maxHeight: '350px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
        width: '4px',
        height: '91px',
    },
    '&::-webkit-scrollbar-thumb': {
        background: 'rgba(0, 214, 89, 1)',
        borderRadius: '4px',
        marginRight: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
        background: 'rgba(0, 214, 89, 0.8)',
    },
    '&::-webkit-scrollbar-track': {
        borderRadius: '4px',
    },
});

const SectionSelectIcon = styled("img")({
    position: "absolute",
    top: "15px",
    left: "10px"
});

const SectionDropDownText = styled(Typography)({
    color: "#3C3E49",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
});

const SectionDropDownIcon = styled("img")({
    height: "10.5px",
    width: "10.5px",
    objectFit: "contain"
});

const AddSectionButtonBox = styled(Box)({
    padding: "15px 45px",
    display: "flex",
    columnGap: "10px",
    alignItems: "center",
    cursor: "pointer"
});

const AddSectionButtonIcon = styled("img")({
    height: "15px",
    width: "15px",
    objectFit: "contain"
});

const AddSectionButtonText = styled(Typography)({
    color: "#6200EA",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    textDecorationLine: "underline"
});

const SectionDropDownHeading = styled(Typography)({
    color: "#000",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    margin: "0px 0px",
    marginBottom: "4px"
});

const SectionDropDownSubHeading = styled(Typography)({
    color: "#000",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    margin: "10px 0px",
    marginBottom: "4px"
});

const SectionSelectSubBox = styled(Select)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "320px",
    height: "48px",
    borderRadius: "3px",
    border: "1px solid #D0D2DA",
    background: "#fff",
    padding: "10px",
    boxSizing: "border-box",
    cursor: "pointer",
    "&:focus": {
        outline: "none",
    },
    "&&::before": {
        content: "none",
    },
    "&&::after": {
        content: "none",
    },
    "& .MuiSelect-select:focus": {
        backgroundColor: "white !important",
    },
});

const SectionInputSubBox = styled("input")({
    width: "320px",
    height: "48px",
    borderRadius: "3px",
    border: "1px solid #D0D2DA",
    backgroundColor: "#fff",
    padding: "10px",
    boxSizing: "border-box",
    color: "#3C3E49",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    outline: "none"
});

const SectionInputNameBox = styled("input")({
    border: "none !important",
    outline: "none !important",
    width: "100%",
});

const DisabledMenuItem = styled(MenuItem)({
    display: "none"
})

const SectionContentDropDownBox = styled(Box)({
    width: "96%",
    borderBottom: "1px solid #E2E2E2",
    boxSizing: "border-box",
    cursor: "pointer",
    position: "relative",
    marginLeft: '15px'
});

const SectionDropDownBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "10px",
    paddingLeft: "30px",
    boxSizing: "border-box"
});

const DeleteButtonSectionBox = styled(Box)({
    width: "320px",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
});

const DeleteSectionButton = styled("button")({
    display: "flex",
    height: "24px",
    width: "24px",
    borderRadius: "100%",
    border: "1px solid red",
    backgroundColor: "transparent",
    justifyContent: "center",
    alignItems: "center",
});

const StyledPlaceholder = styled("span")({

})

const DeleteButtonIcon = styled("img")({
    display: "flex",
    width: "12px !important",
    height: "12px !important",
    justifyContent: "center",
    alignItems: "center"
});

const SectionContentDropDown = styled(Box)({
    width: "100%",
    padding: "0px",
    paddingLeft: "50px",
    marginBottom: "10px"
});

const RedStar = styled(Box)({
    color: "red",
    fontSize: "14px",
    fontWeight: 500,
});

const InputFieldText = styled(Typography)({
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#3C3E49",
    maxWidth: "200px",
    textOverflow: "ellipsis",
    textWrap: "nowrap",
    overflow: "hidden"
});

const UploadIcon = styled("img")({
    height: "24px",
    width: "24px",
    transition: "all 1s ease-in-out",
});

const UploadButtonBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "320px",
    height: "48px",
    borderRadius: "3px",
    border: "1px solid #D0D2DA",
    background: "#fff",
    padding: "10px",
    boxSizing: "border-box",
    cursor: "pointer",
});

const CropperBox = styled(Box)({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    borderRadius: "3px",
    width: "684px",
    height: "599px",
    flexShrink: 0,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    "& .MuiGrid-container": {
        "& .cropperClass": {
            maxWidth: "100%",
            "& .cropper-main-class": {
                "& .cropper-container": {
                    width: "100% !important"
                }
            }
        },

    },
});

const SelectionBox = styled(Box)({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    borderRadius: "3",
    width: "684px",
    height: "352px",
    flexShrink: 0
})

const HeaderModalBox = styled(Box)({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    borderRadius: "3",
    width: "684px",
    height: "599px",
    flexShrink: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& .extraClass": {
        margin: 'auto',
        "& .ExtraClassDropContainer": {
            "& .ExtraClassdropMessage": {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '15px',
                "& .ExtraClassdropMessage": {
                    fontSize: '20px !important',
                    fontWeight: "500 !important",
                    color: 'rgba(103, 107, 126, 1) !important',
                    lineHeight: '24px !important',
                    fontFamily: "Rubik !important"
                },
                "& .ExtraClasssizeMsg": {
                    fontSize: '12px !important',
                    color: 'rgba(103, 107, 126, 1) !important',
                    padding: '0 85px',
                    lineHeight: '24px !important',

                },
            }
        }
    },
})

const Close = styled("img")({
    "&:hover": {
        cursor: "pointer",
    },
});

const TitleBlock = styled("div")({
    display: "flex",
    padding: 40,
    color: "#3C3E49",
    width: "100%",
    justifyContent: "space-between"
});

const HeaderLogoTitleBox = styled("div")({
    display: "flex",
    padding: '40px 55px',
    color: "#3C3E49",
    width: "100%",
    justifyContent: "space-between"
});

const ModalTitle = styled(Typography)({
    color: "#3C3E49",
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "32px",
    fontStretch: "normal",
    fontFamily: "Rubik"
});

const ModalInnerText = styled(Typography)({
    fontFamily: "Rubik",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "48px",
    color: "#9B9FB1"
});

const TextSelectionPixel = styled(Typography)({
    color: "#676B7E",
    textAlign: "center",
    fontFamily: "Rubik",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    padding: "10px 125px",
});

const UploadButton = styled(Button)({
    padding: "0",
    background: "#00D659",
    borderRadius: 3,
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#3C3E49",
    textTransform: "none",
    width: "259px",
    alignItems: "center",
    "& div": {
        width: "100%",
        margin: "0px"
    },
    "& div div": {
        border: "none",
        display: "flex",
        padding: "0"
    },
    "& .Component-dropMessage-130": {
        margin: "0"
    }
});

const GridTextCenter = styled(Grid)({
    textAlign: "center"
});

const TextChooseCatalogue = styled(Typography)({
    color: "#6200EA",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "18px",
    textDecorationLine: "underline",
    marginTop: "23px",
    cursor: "pointer"
});

const CustomModal = styled(Modal)({
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

const ModalContent = styled(Box)({
    width: "684px",
    minHeight: "541px",
    backgroundColor: "#FFFFFF",
    borderRadius: "3px",
    outline: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
});

const ModalSearchImageBox = styled(Box)({
    borderRadius: "0px 3px 3px 0px",
    border: "1px solid #D0D2DA",
    backgroundColor: "#FFF",
    width: "100%",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    padding: "11.5px 0px",
    gap: "5px"
});

const SearchIconBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
});

const SearchIcon = styled("img")({
    width: "16px",
    height: "16px",
    marginLeft: "10px"
});

const SearchBoxInput = styled("input")({
    width: "100%",
    height: "100%",
    border: "none",
    outline: "none",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    maxHeight: "16px",
    "&::placeholder": {
        color: "#AFB2C0",
    }
});

const ModalHeaderBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
});

const ModalBannerInnerText = styled(Typography)({
    color: "#707070",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "32px",
    letterSpacing: "-0.28px"
});

const ModalTitleText = styled(Typography)({
    color: "#3C3E49",
    fontSize: "28px",
    fontWeight: 500,
    lineHeight: "32px",
    letterSpacing: "-0.56px",
});

const CloseModlaIcon = styled("img")({
    width: "22.5px",
    height: "22.5px",
    cursor: "pointer"
});

const ModlaContentBox = styled(Box)({
    margin: "11px 30px",
    display: "flex",
    flexWrap: "wrap",
    height: "412px",
    overflowY: "scroll",
    gap: "8px",
    "&::-webkit-scrollbar": {
        width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#00D659",
    },
    "&::-webkit-scrollbar-track": {
        backgroundColor: "#F8F9FA",
    },
});

const ModalContentFooter = styled(Box)({
    display: "flex",
    justifyContent: "flex-end",
    borderTop: "1px solid #E8E8E8",
});

const ModalEditImage = styled("button")({
    width: "120px",
    borderRadius: "3px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 214, 89, 1)",
    border: "none",
    minHeight: "100%",
    cursor: "pointer",
    color: "rgba(60, 62, 73, 1)",
    fontFamily: "Rubik",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    boxSizing: "border-box",
    transition: "background-color 0.3s ease",
    // "&:hover": {
    //     backgroundColor: "rgba(0, 214, 89, 1)",
    //     color: "rgba(60, 62, 73, 1)",
    // }
});


const ImageBox = styled(Box)({
    width: "196px",
    height: "110px",
    cursor: "pointer"
});

const SelectedImage = styled(Box)({
    width: "196px",
    height: "110px",
    border: "3px solid #00D659",
    position: "relative",
    boxSizing: "border-box"
});

const SelectedImageIconBox = styled(Box)({
    width: "24px",
    height: "24px",
    borderRadius: "3px",
    background: "#00D659",
    position: "absolute",
    top: "45px",
    left: "88px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
});

const SelectedImageIcon = styled("img")({
    height: "24px",
    width: "24px"
});

const CatalogueImage = styled("img")({
    height: "100%",
    width: "100%"
});

const CancelButton = styled(Box)({
    padding: "20px 48px",
    borderRadius: "3px",
    border: "none",
    height: "100%",
    cursor: "pointer",
    textAlign: "center",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    textDecorationLine: "underline",
    color: "#6200EA"
});

//======Footer css========//
const ContentBar = styled(Box)({
    width: "442px",
    padding: "20px",
    boxSizing: "border-box",
    backgroundColor: "#ffffff"
})

const ContentButtonBar = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 10px 10px 20px",
    backgroundColor: "#f6f7f9",
    width: "390px",
    height: "43px",
    margin: "10px 0",
    boxSizing: "border-box",
    cursor: "pointer"
})

const ContentHeadinText = styled(Typography)({
    color: "#475569",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
})

const FooterContentOuterBox = styled(Box)({
    paddingLeft: "0"
});

const SocialInputBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    marginBottom: "0px"
});

const FooterContentHeadingText = styled(Typography)({
    color: "#3C3E49",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    margin: "10px 0",
});

const FooterBox = styled(Box)({
    borderBottom: "1px solid #e2e2e2",
    // padding: "20px",
    marginLeft: '20px'
});

const HeaderBoxContent = styled(Box)({
    borderBottom: "1px solid #e2e2e2",
    padding: "0 0 20px 30px",
});

const SocialMediaLinksBox = styled(Box)({
    margin: "0px 0",
});

const ImageInfo = styled("img")({
    marginLeft: "10px"
});

const ValidUrl = styled("img")({
    position: "absolute",
    right: "3%",
    background: "#FFF"
});

const SocialMediaLinksHeading = styled(Typography)({
    color: "#3C3E49",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    marginTop: "10px !important"
});

const SocialMediaLinksInputBox = styled("input")({
    width: "100%",
    height: "48px",
    borderRadius: "3px",
    border: "1px solid  #D0D2DA",
    backgroundColor: "#FFF",
    margin: "3px 0",
    color: "#1E293B",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    padding: "10px",
    boxSizing: "border-box",
    outline: "none",
    "&::placeholder": {
        color: "#94A3B8",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "24px",
    }
});

const SocialMediaHeaderBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    margin: "10px 0px",
    justifyContent: "space-between",
    "& .MuiSwitch-track": {
        backgroundColor: "#94A3B8 !important"
    },
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#00D659 !important"
    }
});

const CopyrightInputBox = styled("input")({
    width: "100%",
    height: "48px",
    borderRadius: "3px",
    border: "1px solid  #D0D2DA",
    backgroundColor: "#FFF",
    margin: "10px 0",
    color: "#000",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    padding: "10px",
    boxSizing: "border-box",
    outline: "none",
});

const CopyRightBox = styled(Box)({
    padding: "20px",
});

const BoxWithMarginLeft = styled(Box)({
    padding: "0px 0",
    paddingLeft: "20px",
    position: "relative"
});

const ContactDetailsText = styled(Box)({
    color: "#1E293B",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    margin: "10px 0px",
    width: "346px"
});

const ErrorText = styled(Typography)({
    color: "#DC2626",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px"
});

const DeleteCustomModal = styled(Modal)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%"
});

const DeleteModalContentBox = styled(Box)({
    width: "684px",
    height: "277px",
    borderRadius: "3px",
    backgroundColor: "#fff",
    outline: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
});

const ModalFooterBox = styled(Box)({
    borderTop: "1px solid #E8E8E8",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
});

const ModalHeadingBox = styled(Box)({
    padding: "40px",
    boxSizing: "border-box"
});

const YesDeleteButton = styled("button")({
    padding: "20px 48px",
    alignItems: "center",
    borderRadius: "3px",
    backgroundColor: "#FF1744",
    color: "#FFF",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
    border: "none",
    cursor: "pointer"
});
const CancelDeleteButton = styled("button")({
    padding: "20px 48px",
    alignItems: "center",
    borderRadius: "3px",
    backgroundColor: "#fff",
    color: "#3C3E49",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    border: "none",
    cursor: "pointer",
    textDecoration: "underline",
});

const ModalHeadingFlexBox = styled(Box)({
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between"
});

const DeleteModalTitle = styled(Typography)({
    color: "#3C3E49",
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "32px",
    letterSpacing: "-0.56px",
});

const CloseModalButton = styled("button")({
    display: "flex",
    width: "24px",
    height: "24px",
    padding: "0.749px 0.75px 0.751px 0.75px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    border: "none"
})
const DeleteCloseIcon = styled("img")({
    width: "22.5px",
    height: "22.5px",
})
const ModalInformationText = styled(Typography)({
    color: "#3C3E49",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px"
})

const HeaderDropDownSubHeading = styled(Typography)({
    fontSize: "14px",
    fontWeight: 500,
    margin: "10px 0px",
    color: 'rgba(71, 85, 105, 1) !important',
    display: 'flex',
    alignItems: "center",
    gap: '10px',
    paddingLeft: '20px'
});

// Customizable Area End 