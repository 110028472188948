// Customizable Area Start
import * as React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import * as Yup from "yup";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { PayloadTypes } from "./PromoCodeCreateController.web";
export const configJSON = require("./config.js");
import { FormikProps, FormikHelpers, FormikErrors, FormikHandlers, FormikTouched } from "formik";
import { clearStorageData } from "../../ss-cms-common-components/src/Utilities/Utilities";
export type Props = RouterProps & withLoaderProps & {
    onSubmit: (data: PayloadTypes) => {},
    openToast: () => void,
    promoCodeData: InitialValuesTypes,
    formRef: React.RefObject<FormikProps<InitialValuesTypes>>
    classes: any
  };

export interface UserLimitTypes {
  userLimit: number
}
interface DiscountTypes {
    value: string,
    label: string
}
export interface InputProps {
  values: InitialValuesTypes;
  errors:  FormikErrors<InitialValuesTypes>;
  touched: FormikTouched<InitialValuesTypes>;
  setFieldValue:  FormikHelpers<InitialValuesTypes>["setFieldValue"];
  handleBlur: FormikHandlers["handleBlur"];
}

export interface InitialValuesTypes{
    promoCodeName: string,
    description: string,
    code: string,
    discountType: string,
    discount: string,
    minCartValue: string,
    validFrom: Date,
    validTo: Date,
    userLimit: number | null,
    userLimitDisabled: boolean
}

interface S {
  discountTypes: Array<DiscountTypes>;
  initialValues?: InitialValuesTypes
}

interface SS {
  id: number;
}

export default class PromoCodeComponentController extends BlockComponent<
  Props,
  S,
  SS
> {
    
  formRef: React.RefObject<FormikProps<InitialValuesTypes>>;
  constructor(props: Props) {
    super(props);
    this.formRef = React.createRef();
    this.receive = this.receive.bind(this);
    this.state = {
        discountTypes: configJSON.discountDataType
    };
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.ActionMessageFromToaster)
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  resetLogin = () => {
    clearStorageData();
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),"EmailAccountLogin");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(message.messageId, message);
  };

  getErrorStatus = (error: FormikErrors<InitialValuesTypes>, touched: FormikTouched<InitialValuesTypes>, fieldKey: keyof InitialValuesTypes) => {
    if (error?.[fieldKey] && touched?.[fieldKey]) {
      return true;
    }
    return false;
  };
  
  getHelperText = (error: FormikErrors<InitialValuesTypes>, touched: FormikTouched<InitialValuesTypes>, fieldKey: keyof InitialValuesTypes) => {
    if (error?.[fieldKey] && touched?.[fieldKey]) {
      return error?.[fieldKey];
    }
    return "";
  };

  formatDate = (date: Date) => {
    var dateObj = new Date(date),
      month = '' + (dateObj.getMonth() + 1),
      days = '' + dateObj.getDate(),
      year = dateObj.getFullYear();

    if (month.length < 2) 
      month = '0' + month;
    if (days.length < 2) 
      days = '0' + days;

    return [year, month, days].join('-');
  }

  isValidDate = (date: MaterialUiPickersDate) => {
    return date instanceof Date;
  };

  handleOnChangeValidFrom = (
    date: MaterialUiPickersDate,
    setFieldValue: FormikHelpers<InitialValuesTypes>["setFieldValue"]
  ) => {
    this.props.openToast();
    if (this.isValidDate(date)) {
      setFieldValue(configJSON.validFrom, date);
    } else {
      setFieldValue(configJSON.validFrom, "");
    }
  };

  handleOnChangeValidTo = (
    date: MaterialUiPickersDate,
    setFieldValue: FormikHelpers<InitialValuesTypes>["setFieldValue"]
  ) => {
    this.props.openToast();
    if (this.isValidDate(date)) {
      setFieldValue(configJSON.validTo, date);
    } else {
      setFieldValue(configJSON.validTo, "");
    }
  };

  userLimit = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, setFieldValue: FormikHelpers<InitialValuesTypes>["setFieldValue"]) => {
    this.props.openToast();
    setFieldValue("userLimit", event.target.value.replace(/\D/g, ""));
  }
  
  Schema = Yup.object().shape({
    promoCodeName: Yup.string()
      .max(30, configJSON.promoCodeNameValidation)
      .required(configJSON.requiredField),
    description: Yup.string()
      .max(50, configJSON.promoCodeDescriptionValidation)
      .required(configJSON.requiredField),
    code: Yup.string()
      .max(10, configJSON.promoCodeValidation)
      .required(configJSON.requiredField),
    discountType: Yup.string().required(configJSON.requiredField),
    discount: Yup.number()
      .required(configJSON.requiredField)
      .positive()
      .min(0, configJSON.promoCodeDiscountValidation1)
      .max(100000, configJSON.promoCodeDiscountValidation2),
    userLimit: Yup.number()
      .typeError(configJSON.promoCodeUserLimitValidation1)
      .min(1, configJSON.promoCodeUserLimitValidation2)
      .nullable(true),
  
    minCartValue: Yup.number()
      .required(configJSON.requiredField)
      .positive()
      .min(0, configJSON.promoCodeMinCartValueValidation1)
      .max(99999, configJSON.promoCodeMinCartValueValidation2),
  
    validFrom: Yup.date()
      .transform((curr, orig) =>  curr.toString() === configJSON.inValidDateTxt ? new Date() : (orig === "" ? null : (typeof curr ==="string" ? new Date(curr) : curr)))
      .test(configJSON.valid_checkDateInPast, configJSON.pastDate, 
        (dateObj) => {
            return !!(dateObj && dateObj > new Date(Date.now() -86400000));
        }) 
      .required(configJSON.promoCodeValidFromValidation),
    validTo: Yup.date()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .required(configJSON.promoCodeValidToValidation)
      .min(Yup.ref(configJSON.validFrom), configJSON.promoCodeValidToValidation2),
  });

  handleChangeUserLimitDisabled = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: FormikHelpers<InitialValuesTypes>["setFieldValue"]) => {
    this.props.openToast();
    setFieldValue(configJSON.field_userLimitDisabled, event.target.checked);

    if (!event.target.checked) {
      setFieldValue(configJSON.field_userLimit, null);
    }
  };

  handleSubmit = (data: InitialValuesTypes) => {
    const { onSubmit } = this.props;
    if(data === undefined){
      return
    }
    const promoCode = {
      title: data.promoCodeName,
      description: data.description,
      code: data.code,
      discount_type: data.discountType,
      discount: data.discount,
      valid_from: this.formatDate(data.validFrom),
      valid_to: this.formatDate(data.validTo),
      min_cart_value: data.minCartValue,
      limit: data.userLimit || null,
    };
    onSubmit(promoCode);
  };

  onTagsChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setFieldValue: FormikHelpers<InitialValuesTypes>["setFieldValue"]
  ) => {
    this.props.openToast();
    setFieldValue(event.target.name, event.target.value);
  };

}
// Customizable Area End
