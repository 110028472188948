import React, { FunctionComponent, useEffect } from "react";
// Customizable Area Start
import {
    Container,
    Box,
    Button,
    Grid,
    Typography,
    withWidth,
    Breadcrumbs,
    Link,
    Input,
    Card,
    CardContent,
    ButtonGroup,
    FormControlLabel,
    Checkbox,
    RadioGroup,
    InputAdornment,
    Dialog,
    DialogContent
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import withStyles, { CSSProperties } from "@material-ui/core/styles/withStyles";
import ClearIcon from '@material-ui/icons/Clear';
import Radio from '@material-ui/core/Radio';
import Alert from '@material-ui/lab/Alert';
import { AppHeader } from '../../../components/src/AppHeader.web'
import {
    logo, cartLogo, promoApplied, promocodeError, successTick, errorTick
} from './assets'
import {
    NavigateNext as NavigateNextIcon,
} from "@material-ui/icons";
import { ProductDetailDialogue } from "../../../components/src/ProductDetailPopUp";
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import SectionLoader from "../../inventorymanagement/src/SectionLoader.web";
import FormHelperText from '@material-ui/core/FormHelperText';
import CloseIcon from "@material-ui/icons/Close";
import AlertMessage from "../../../components/src/AlertMessage.web";
import { withHeadeActionBarProps } from "../../../../packages/blocks/ss-cms-common-components/src/HOC/withHeadeActionBar.Web";

// Customizable Area End

import CartOrdersController, {
    CartItem,
    Props, configJSON, Variants, TaxesApplied, IRazorProps, DeliveryAddressType
} from "./CartOrdersController.web";
import { DialogAddress } from "./DialogAddress.web";


export class CartOrders extends CartOrdersController {
    constructor(props: Props & withHeadeActionBarProps) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    mixPanelHitEvent = () => {
        this.mixPanelEventHit('Added delivery address')
        this.setState({ isDialogOpen: true })
    }

    noCartOrdersBlock = () => {
        const { classes } = this.props
        return (
            <Grid
                item
                lg={12}
                md={12}
                xs={12}
                className={classes.noCartRoot}
            >
                <Box className={classes.noOrdersBox} >
                    <Grid item className={classes.ordersContent}>
                        <Typography className={classes.mrb10}>
                            <img src={cartLogo} alt="no products" />
                        </Typography>
                        <Typography
                            data-test-id="noorders"
                            variant="h5"
                            component="h2"
                            className={classes.cartEmptyText}
                        >
                            {configJSON.cartEmpty}
                        </Typography>
                        <Typography className={`${classes.noordersText} ${classes.font400}`}>
                            {configJSON.noDataAddedToCart}
                        </Typography>
                    </Grid>
                </Box>
            </Grid>
        );
    };

    breadGroom = () => {
        const { classes } = this.props
        return (
            <div className={classes.breadcrumbsOnScreen} >
                <Box className={classes.breadcrumbsWrapper}>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" className={classes.secondaryColor} />}>
                        <Link
                            data-test-id="backtomenu"
                            onClick={() => this.backToMenu()}
                            className={classes.breadcrumbLink}
                        >
                            <Typography className={classes.breadcrumbLinkText}>{configJSON.home}</Typography>
                        </Link>
                        <Typography className={classes.breadcrumbNonLink}>
                            {configJSON.cart}
                        </Typography>
                    </Breadcrumbs>
                </Box>
            </div>
        )
    }

    StyledRadio = () => {
        const { classes } = this.props
        return (
            <Radio
                className={classes.radioBtnRoot}
                disableRipple
                color="default"
                checkedIcon={<span className={`${classes.radioBtnIcon}  ${classes.radioBtnCheckedIcon}`} />}
                icon={<span className={classes.radioBtnIcon} />}
            />
        );
    }

    orderTypes = () => {
        const { classes } = this.props
        return (
            <Grid container spacing={3} direction="row">
                <Grid item xs={12} md={5} xl={5} className={classes.mrb10}>
                    <RadioGroup row aria-label="Order Type" value={this.state.orderType} name="customized-radios" className={classes.radioGroup}>
                        <FormControlLabel data-test-id="delivery" onClick={() => this.changeOrderType(configJSON.delivery)} className={`${classes.radioBtn} ${this.state.orderType == configJSON.delivery ? classes.radioBtnChecked : classes.radioBtnUnhecked}`} value={configJSON.delivery} control={this.StyledRadio()} label={configJSON.delivery} />
                        <FormControlLabel data-test-id="collection" onClick={() => this.changeOrderType(configJSON.collection)} className={`${classes.radioBtn} ${this.state.orderType == configJSON.collection ? classes.radioBtnChecked : classes.radioBtnUnhecked}`} value={configJSON.collection} control={this.StyledRadio()} label={configJSON.collection} />
                    </RadioGroup>
                </Grid>
                {
                    this.state.isOrderTypeSelected &&
                    <Grid item xs={12} md={8}>
                        <Alert icon={false} data-test-id="orderTypeReq" className={`${classes.alertbox} ${classes.warningMsg}`} onClose={() => this.closeAlert()}>{configJSON.orderTypeWarningMsg}</Alert>
                    </Grid>
                }
                {
                    this.state.isAddressEmpty &&
                    <Grid item xs={12} md={8}>
                        <Alert icon={false} data-test-id="addressEmpty" className={`${classes.alertbox} ${classes.warningMsg}`} onClose={() => this.closeAlertEmpty()}>{configJSON.chooseAddress}</Alert>
                    </Grid>
                }
                {
                    this.state.isitemUpdated &&
                    <Grid item xs={12} md={8}>
                        <Alert icon={false}
                            data-test-id="itemUpdateMsg"
                            className={`${classes.alertbox} 
                         ${classes.updateMsg}`}
                            onClose={() => this.closeAlert()}>
                            {`${this.state.selectedProduct?.attributes.name}${configJSON.itemUpdateMsg}`}
                        </Alert>
                    </Grid>
                }
            </Grid>
        )
    }
    orderTypeMessage = () => {
        const { classes } = this.props
        return (
            <Grid container className={classes.orderTypeRoot}>
                {this.renderCollectionView(this.state.orderType === configJSON.collection)}
                {this.renderDeliveryView(this.state.orderType === configJSON.delivery, this.state.deliveryAddress)}
                {
                    this.state.paymentStatus &&
                    <Grid item xs={12} md={12}>
                        <Alert icon={false} data-test-id="itemFailMsg" className={`${classes.alertbox} ${classes.failMsg}`} onClose={() => this.closeAlert()}>{configJSON.itemFailMsg}</Alert>
                    </Grid>
                }
            </Grid>
        )
    }

    renderCollectionView(isCollection: boolean) {
        const { classes } = this.props
        if (isCollection) {
            return (
                <Grid item xs={12} md={12} xl={12} className={`${classes.cAddress} ${classes.mrb10}`}>
                    <div className={classes.cAddressBox}>
                        <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.773 12.023L7 16.7959L2.22703 12.023C-0.40901 9.3869 -0.40901 5.11307 2.22703 2.47703C4.86307 -0.15901 9.1369 -0.15901 11.773 2.47703C14.409 5.11307 14.409 9.3869 11.773 12.023ZM7 8.75C7.82845 8.75 8.5 8.07845 8.5 7.25C8.5 6.42157 7.82845 5.75 7 5.75C6.17155 5.75 5.5 6.42157 5.5 7.25C5.5 8.07845 6.17155 8.75 7 8.75Z" fill="var(--website-builder-primary-color)" />
                        </svg>
                        <Typography data-test-id="collectionAddressMsg" component={'span'} className={`${classes.cAddressTitle}`}>
                            {configJSON.collectionAddress}
                        </Typography>
                    </div>
                    <Typography className={classes.collectionAddress}>
                        {this.state.metaInfo?.address_line_1}{this.state.metaInfo?.address_line_2 && ","} {this.state.metaInfo?.address_line_2},
                        {" "}{this.state.metaInfo?.city_name}{this.state.metaInfo?.city_name && ","} {this.state.metaInfo?.state_name}.
                        {" "}{this.state.metaInfo?.zipcode}, {this.state.metaInfo?.country_name}
                    </Typography>
                </Grid>
            )
        }
    }

    renderDeliveryView(isDelivery: boolean, address: DeliveryAddressType) {
        const { classes } = this.props
        if (isDelivery) {
            if (address.address !== "" && address.flat_no !== "" && address.address_line_2 !== "") {
                const landmark = address.landmark ?? ""
                const area = address.area ?? ""
                return (
                    <Grid item xs={12} md={12} xl={12} className={`${classes.cAddress} ${classes.mrb10}`}>
                        <div className={classes.cAddressBox}>
                            <svg className={classes.dLocationIcon} width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.773 12.023L7 16.7959L2.22703 12.023C-0.40901 9.3869 -0.40901 5.11307 2.22703 2.47703C4.86307 -0.15901 9.1369 -0.15901 11.773 2.47703C14.409 5.11307 14.409 9.3869 11.773 12.023ZM7 8.75C7.82845 8.75 8.5 8.07845 8.5 7.25C8.5 6.42157 7.82845 5.75 7 5.75C6.17155 5.75 5.5 6.42157 5.5 7.25C5.5 8.07845 6.17155 8.75 7 8.75Z" fill="var(--website-builder-primary-color)" />
                            </svg>
                            <Typography data-test-id="collectionAddressMsg" component={'span'} className={`${classes.cAddressTitle} ${classes.dAddressTitle}`}>
                                {configJSON.deliverAddress}<span className={classes.enableColon}>:</span>
                            </Typography>
                        </div>
                        <Typography className={`${classes.collectionAddress} ${classes.deliveryAddress}`}>
                            <>
                                {address.address} <br />
                                {area} {area && <br />}
                                {address.flat_no} <br />
                                {address.address_line_2}  <br />
                                {landmark}
                            </>
                        </Typography>
                        <div className="edit-icon-delivery-address-cart" data-test-id="editAddress" onClick={() => this.setState({ isDialogOpen: true })} style={cardStyle.editAddressIcon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="var(--website-builder-secondary-dark-accent-color)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z" /><path d="m15 5 4 4" /></svg>
                        </div>
                    </Grid>
                )
            } else {
                return (
                    <div
                        style={cardStyle.buttonAddAddress}
                        data-test-id="addAddress"
                        onClick={() => this.mixPanelHitEvent()}
                    >
                        {configJSON.addAddress}
                    </div>
                )
            }
        }
    }

    cartItems = () => {
        const { classes } = this.props
        return (
            <Grid item xs={12} md={8}>
                {this.orderTypeMessage()}
                <div className={`landing-scroll-cart ${classes.landingScrollStyle} ${classes.cartList}`}>
                    {this.state.categorieItems && this.state.categorieItems.map((categorieItem: CartItem, index: number) => (
                            <Card
                                key={`card_${index}`}
                                data-test-id={`itemDetailsContainer_${index}`}
                                className={classes.landingPageCard}
                            >
                                <div className={classes.cardContentStyles}>
                                    <div className="landing-page-image-card">
                                        <img className="landing-page-image-card" src={categorieItem.attributes.images.url} style={cardStyle.cardImageStyles} alt="Card" />
                                    </div>
                                    <CardContent className={classes.cardContent}>
                                        <Box className={classes.itemIncrease} 
                                            style={{ marginBottom: categorieItem.attributes.after_discount !== categorieItem.attributes.price ? "40px" : "8px" }}
                                        >
                                            <span className={`title-card ${classes.titleCard2}`} style={cardStyle.titleStyles}>
                                                {categorieItem.attributes.name}
                                            </span>
                                        </Box>
                                        {categorieItem.attributes.variants.length > 0 &&
                                            categorieItem.attributes.variants.map((item: Variants) => {
                                                const currentVariants = categorieItem.variants.find((variant) => variant.name === item.title)
                                                return (
                                                    <Box key={`variant_${item.id}`} className={classes.variantInfo} >
                                                        <div className={classes.mrR10}>
                                                            {currentVariants?.option && currentVariants?.option.length > 0 &&
                                                                <>
                                                                    <Typography component={'span'}
                                                                        className=
                                                                        {`${classes.ctSize} ${classes.font400} label`}>
                                                                        {item.title}{': '}
                                                                    </Typography>
                                                                    <Typography component={'span'}
                                                                        className=
                                                                        {`${classes.ctSize} ${classes.font700}`}>
                                                                        {this.getSelectedOtions(currentVariants?.option ?? [])}
                                                                    </Typography>
                                                                </>
                                                            }
                                                        </div>
                                                    </Box>
                                                )
                                            }
                                            )
                                        }                                 
                                    {this.priceBlock(categorieItem)}
                                    <Box className={classes.itemIncrease}>
                                        <ButtonGroup color="primary" aria-label="primary button group">
                                            <Button variant='text' data-test-id={`removeBth_${index}`} className={`${classes.removeBtn} ${!this.state.isStoreClosed ? classes.btnDisable : ''}`} onClick={() => this.decreaseItem(categorieItem.id, index)}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="24" height="24" rx="4" fill="var(--website-builder-primary-light-accent-color)" />
                                                    <path d="M7 11H17C17.55 11 18 11.45 18 12C18 12.55 17.55 13 17 13H7C6.45 13 6 12.55 6 12C6 11.45 6.45 11 7 11Z" fill="#0F172A" />
                                                </svg>
                                            </Button>
                                            <Button variant='text' data-test-id={`orderQuantity_${index}`} className={classes.countText}>{this.getCount(categorieItem.id, index)}</Button>
                                            <Button variant='text' data-test-id={`addBth_${index}`} className={`${!this.state.isStoreClosed ? classes.btnDisable : ''}`} onClick={() => this.increaseItem(categorieItem.id, index)}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="24" height="24" rx="4" fill="var(--website-builder-primary-light-accent-color)" />
                                                    <path d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z" fill="#0F172A" />
                                                </svg>
                                            </Button>
                                        </ButtonGroup>
                                        <Button className={`${classes.editBtn} ${classes.fW500}`} data-test-id={`editBtn_${index}`} onClick={() => this.openCartItemDetails(categorieItem, index)}>Edit</Button>
                                    </Box>
                                </CardContent>
                            </div>
                        </Card>
                    ))}
                </div>
            </Grid>
        )
    }
    priceBlock = (item: CartItem) => {
        const { classes } = this.props;
        const isDiscount = item.attributes.after_discount !== item.attributes.price;
        return (
            <Box className={classes.priceRoot}>
                <div className={classes.priceInfo}>
                    <div className={classes.priceblock}>
                        {isDiscount &&
                            <span className={`new-price-text ${classes.discountPrice}`}>
                                {this.state.currency}{item.attributes.price}
                            </span>
                        }
                        <span className={`price-text ${classes.price}`}>
                            {this.state.currency}{item.total ? item.total : (item.attributes.after_discount || item.attributes.price)}
                        </span>
                    </div>
                </div>
                {item?.attributes?.availability === "out_of_stock" && <span className={`available-text ${classes.notAvailableText}`} >{configJSON.notAvailable}</span>}
            </Box>
        )
    }

    summarySection = () => {
        const { classes } = this.props
        return (
            <>
                <Grid item xs={12} md={4} className={classes.summary}>
                    <div className={classes.smRoot}>
                        <Typography className={classes.smTitle}> {configJSON.summary}</Typography>
                        <Grid className={`${classes.smItems} ${classes.bdTop}`}>
                            <Typography className={classes.smItemLabel}>{configJSON.subtotal}</Typography>
                            <Typography className={classes.smItemValue} data-test-id="subtotalValue">{this.state.currency}{Number(this.state.subtotal).toFixed(2)}</Typography>
                        </Grid>
                        {this.state.taxesApplied && this.state.taxesApplied.length > 0 &&
                            this.state.taxesApplied.map((tax: TaxesApplied, index: number) => {
                                return (
                                    <Grid className={classes.smItems} key={'tax_' + index}>
                                        <Typography className={classes.smItemLabel}>{tax.tax_title}</Typography>
                                        <Typography className={classes.smItemValue}> {this.state.currency}{this.round((((this.state.subtotal - this.state.promoCodeDiscount) * Number(tax.tax)) / 100), 2)}</Typography>
                                    </Grid>
                                )
                            })
                        }
                        <Grid className={`${classes.smItems} ${classes.bdBottom}`}>
                            <Typography className={classes.smItemLabel}>{configJSON.delivery}</Typography>
                            <Typography className={classes.smItemValue}>{this.state.distance_rate > 0 ? this.state.currency + this.state.distance_rate : configJSON.free}</Typography>
                        </Grid>
                        {
                            this.state.isPromoCodeValid &&
                            <Grid className={`${classes.promoDiscountbox} ${classes.bdBottom}`}>
                                <div className={classes.dicountCodeBox}>
                                    <Typography className={classes.discountCodeMsg}>{this.state.promocodeApplied}</Typography>
                                    <Typography className={classes.discountAppliedMsg}>{configJSON.applied}</Typography>
                                </div>
                                <Typography className={classes.diccountValue} data-test-id="discountPrice">{`-${this.state.currency}${Number(this.state.promoCodeDiscount).toFixed(2)}`}</Typography>
                            </Grid>
                        }
                        <Grid className={`${classes.smItems} ${classes.bdBottom}`}>
                            <Typography className={`${classes.smItemLabel} ${classes.smTotal}`}>{configJSON.total}</Typography>
                            <Typography className={classes.smItemTotalValue}>{this.state.currency}{Number(this.state.distance_rate + this.state.subtotal + this.state.totalTax - this.state.promoCodeDiscount).toFixed(2)}</Typography>
                        </Grid>
                        <Grid className={`${classes.pdtb}  ${classes.bdBottom}`}>
                            <div className={` ${classes.promobox}`}>
                                <Input placeholder="Enter promo code" value={this.state.promocode} className={`${classes.smpromo} ${this.state.isPromoApplied ? classes.smpromoClear : ''}`}
                                    inputProps={{ 'aria-label': 'description' }}
                                    onChange={(e) => this.handlePromoCode(e.target.value)}
                                    data-test-id="promocode"
                                />
                                {
                                    !this.state.isPromoApplied && <Button variant="contained" disabled={this.state.promocode?.length > 0 ? false : true}  className={classes.smApplyBtn} onClick={() => this.validatePromo()} data-test-id="promocodeBtn">
                                        {configJSON.apply}
                                    </Button>
                                }
                                {
                                    this.state.isPromoApplied && <ClearIcon className={classes.clearIcon} onClick={() => this.clearPromoCode()} data-test-id="clearBtn" />
                                }
                            </div>
                            {this.state.showPromocodeMessage &&
                                <Grid className={`${classes.promoMsgBox} `}>
                                    <img src={this.state.isPromoCodeValid ? promoApplied : promocodeError} className={classes.promoImg} alt="promo success" />
                                    <Typography className={classes.promoCodeMsg} data-test-id="promocodeMsg">{this.state.isPromoCodeValid ? configJSON.promocodeApplied : configJSON.promocodeValidateMsg}</Typography>
                                </Grid>
                            }
                            {this.customerInfo()}

                        </Grid>
                        <Grid className={`${classes.pdtb24} ${classes.bdBottom}`}>
                            <Typography className={classes.smNoteLabel}>{configJSON.merchantNote}</Typography>
                            <Typography className={classes.smNoteValue} data-test-id="merchantnote">{this.state.merchantNote}</Typography>
                        </Grid>
                        <Grid className={`${classes.pdtb24} ${classes.bdBottom}`}>
                            <Typography className={`${classes.smNoteLabel} ${classes.paymentType}`}>{configJSON.paymentType}</Typography>
                            <RadioGroup aria-label="payment Type" value={this.state.paymentOption} name="customized-radios" >
                                <FormControlLabel data-test-id="online" onClick={() => this.changePaymentType(configJSON.onlineType)} className={`${classes.paymentOption} ${classes.paymentRadio}`} value={configJSON.onlineType} control={<Radio disableRipple style={cardStyle.radioBtnStyle} />} label={configJSON.online} />
                                <FormControlLabel data-test-id="cashondelivery" onClick={() => this.changePaymentType(configJSON.cod)} className={`${classes.paymentOption} ${classes.paymentRadio}`} value={configJSON.cod} control={<Radio disableRipple style={cardStyle.radioBtnStyle} />} label={configJSON.cashOnDelivery} />
                            </RadioGroup>
                        </Grid>
                        <Grid className={classes.smSecurebox}>
                            <svg className={classes.smLockimg} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="var(--website-builder-secondary-dark-accent-color)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect width="18" height="11" x="3" y="11" rx="2" ry="2" /><path d="M7 11V7a5 5 0 0 1 10 0v4" /></svg>
                            <Typography className={classes.smLocktext}>{configJSON.secureMsg}</Typography>
                        </Grid>
                        <Grid className={classes.pdtb}>
                            <Button variant="contained" data-test-id="checkoutBtn" className={classes.smBtn} onClick={() => this.placeOrder()}>
                                {this.state.deliveryAddress.address !== "" ? configJSON.continueToPayment : configJSON.placeOrderBtn}
                            </Button>
                        </Grid>
                    </div>
                </Grid>
            </>
        )
    }

    storeAvilability = () => {
        const { classes } = this.props
        return (
            <>
                {!this.state.isStoreClosed &&
                    <Grid container justifyContent="space-around">
                        <Grid item xs={12} className={`${classes.storeMsgBox}`}>
                            <Typography variant="h6" className={classes.storeMsgTitle} data-test-id="storeClosedMsgTitle">{configJSON.storeCloseMsgTitle}</Typography>
                            <Typography variant="h6" className={classes.storeMsg}>{this.state.storeClosedMessage}</Typography>
                        </Grid>
                    </Grid>
                }
            </>
        )
    }
    customerInfo = () => {
        const { classes } = this.props
        return (
            <>
                {(this.state.orderType === configJSON.delivery || this.state.orderType === configJSON.collection) &&
                    <>
                        <Typography data-test-id="personalInfo" className={classes.personalDetailTitle}>{configJSON.personalDetails}</Typography>
                        <Grid className={`${classes.pdtb24} `}>
                            <Typography className={classes.personalDetailLabel}>{configJSON.name}</Typography>
                            <Input placeholder={configJSON.namePlaceholder} value={this.state.customerName} className={`${classes.smpromo} ${classes.br8}`}
                                inputProps={{ 'aria-label': 'description' }}
                                onChange={(e: { target: { value: string } }) => this.handleCustomerInfo(e.target.value, 'customerName')}
                                data-test-id="customerName"
                                error={!this.state.customerName && this.state.isNameValid}
                                aria-describedby="component-name-error-text"
                            />
                            {!this.state.customerName && this.state.isNameValid &&
                                <FormHelperText className={classes.errorMsg} id="component-name-error-text">{configJSON.fieldRequired}</FormHelperText>
                            }
                        </Grid>
                        <Grid className={`${classes.pdtb24} `}>
                            <Typography className={classes.personalDetailLabel}>{configJSON.contactNumber}</Typography>
                            <Input placeholder={configJSON.contactNumberPlaceHolder} value={this.state.customerContact} className={`${classes.smpromo} ${classes.br8}`}
                                inputProps={{ 'aria-label': 'description' }}
                                onChange={(e) => this.handleCustomerInfo(e.target.value, 'customerContact')}
                                data-test-id="customerContact"
                                startAdornment={<InputAdornment position="start"><Typography className={classes.flag}>{this.state.metaInfo?.country_flag}</Typography>
                                    <Typography className={`${classes.inputText} ${classes.mrlr8}`}>{this.state.metaInfo?.phone_code}</Typography></InputAdornment>}
                                error={!this.state.customerContact && this.state.isMobileValid}
                                aria-describedby="component-contact-error-text"
                            />
                            {!this.state.customerContact && this.state.isMobileValid &&
                                <FormHelperText className={classes.errorMsg} id="component-contact-error-text">{configJSON.fieldRequired}</FormHelperText>
                            }
                            {this.state.customerContact && this.state.isMobileValid && this.state.isValidcontactNo &&
                                <FormHelperText className={classes.errorMsg} data-test-id="invalidContact" id="component-contact-error-text">{configJSON.validContactMsg}</FormHelperText>
                            }
                        </Grid>
                        <Grid className={`${classes.pdtb24} `}>
                            <Typography className={classes.personalDetailLabel}>{configJSON.emailAddress}</Typography>
                            <Input placeholder={configJSON.emailAddressPlaceHolder} value={this.state.customerEmail} className={`${classes.smpromo} ${classes.br8}`}
                                inputProps={{ 'aria-label': 'description' }}
                                onChange={(e) => this.handleCustomerInfo(e.target.value, 'customerEmail')}
                                data-test-id="customerEmail"
                                error={!this.state.customerEmail && this.state.isEmailValid}
                                aria-describedby="component-email-error-text"
                            />
                            {!this.state.customerEmail && this.state.isEmailValid &&
                                <FormHelperText className={classes.errorMsg} id="component-email-error-text">{configJSON.fieldRequired}</FormHelperText>
                            }
                            {this.state.customerEmail && this.state.isValidEmailId &&
                                <FormHelperText className={classes.errorMsg} data-test-id="notvalidemailId" id="component-email-error-text">{configJSON.validEmailIdMsg}</FormHelperText>
                            }
                        </Grid>
                        <Grid className={`${classes.pdtb24} `}>
                            <div className={classes.priceInfo}>
                                <Checkbox
                                    className={classes.checkboxroot}
                                    disableRipple
                                    checkedIcon={<span className={`${classes.icon} ${classes.checkedIcon}`} />}
                                    icon={<span className={classes.icon} />}
                                    inputProps={{ 'aria-label': 'decorative checkbox' }}
                                    onChange={(e) => this.handlePromotionStatusChange(e.target.checked)}
                                    value={this.state.promotionEnabled}
                                    defaultChecked={this.state.promotionEnabled}
                                    data-test-id="pramtionEnabled"
                                />
                                <Typography className={classes.pramotionText}>{configJSON.promotionEnableText}</Typography>
                            </div>

                        </Grid>
                    </>
                }
            </>
        )
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { width } = this.props;
        const isMobile = /s/.test(width);
        console.log('markup',this.state.categorieItems)
        return (
            <>
                <Grid style={webStyles.container} >
                    <AlertMessage
                        data-test-id="orderAlertMsg"
                        open={this.state.openOrderAlert}
                        success={this.state.alertOrderSuccess}
                        message={this.state.alertOrderMessage}
                        close={this.handleOrderAlertMessageClose}
                    />
                    <div style={{ backgroundColor: this.getColor(isMobile) }} >
                        {!isMobile && <AppHeader
                            Fname="Restaurant"
                            logo={logo}
                            itemName="Name"
                            data-test-id="appHeaderComponent"
                            Lname="Name"
                            state={this.state}
                            searchData={this.state.searchData}
                            value={this.state.search}
                            onBlur={() => this.setSearchInfo()}
                            itemImage="image"
                            openDrawer={this.state.openDrawer}
                            backgroundColor={'#fff'}
                            searchInputDefaultColor={true}
                        />}
                        {this.breadGroom()}
                        {this.state.razerpayOPtion && <RazorpayCheckoutComponent data-test-id="razerpayCheckout"  {...this.state.razerpayOPtion} handleSuccessResponse={this.handleRazerResponse} onClose={() => { this.setState({ razerpayOPtion: null }) }} />}
                        {this.state.ScreenLoading ? (
                            <SectionLoader loading={true} />
                        ) : (
                            <Container maxWidth={false}  >
                                {
                                    this.state.categorieItems.length > 0 ? (
                                        <Grid item xs={12}  data-test-id="sample" >
                                            {this.orderTypes()}
                                            <Grid container spacing={3} direction="row" >
                                                {this.storeAvilability()}
                                                {this.cartItems()}
                                                {this.summarySection()}
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <div style={webStyles.viewEmpty}>
                                            <div style={{ ...webStyles.flexColumn, ...{ backgroundColor: this.getColor(!isMobile) } }}>
                                                {this.noCartOrdersBlock()}
                                                <Button variant="contained" color="primary" className={this.props.classes.backBtn} onClick={() => this.backToMenu()}>
                                                    {configJSON.backToMenu}
                                                </Button>
                                            </div>
                                        </div>
                                    )
                                }
                            </Container>
                        )}
                    </div>
                    <ProductDetailDialogue
                        open={this.state.isDetail}
                        setClose={() => this.setCloseDetail()}
                        currencyType={this.state.currency}
                        selectedProduct={this.state.selectedProduct}
                        variantSelect={this.variantSelect}
                        variantRadio={this.variantRadio}
                        currentPrice={this.state.tempStore}
                        data-test-id="productDetailTest"
                        close={this.updateCart}
                        isEdit={true}
                        isStoreClosed={this.state.isStoreClosed}
                    />
                    <DialogAddress
                        id="DialogAddress"
                        isDialogOpen={this.state.isDialogOpen}
                        onCloseDialog={(add) => this.onCloseDialog(add)}
                        classes={this.props.classes}
                        countryCode={this.state.countryCode}
                        address={this.state.deliveryAddress}
                    />
            
                </Grid>

                <Dialog
                    onClose={() => this.closeModal()}
                    data-test-id="dialog-box-msg"
                    aria-labelledby="customized-dialog-title"
                    open={this.state.msg_popup}
                    fullWidth
                    PaperProps={{
                        style: webStyles.viewDialog
                    }}
                >
                    <DialogContent className="dialog-address-content" >
                        <div style={webStyles.textTitle}>
                            <img src={this.state.msg_status == 'success' ? successTick : errorTick} alt="" />
                            <CloseIcon
                                onClick={() => this.closeModal()}
                                style={webStyles.closeIcon}
                                data-test-id="close-icon"
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <span style={{
                                ...webStyles.msgText, fontWeight: 700,
                                fontSize: '16px',
                                lineHeight: '24px',
                                color: '#1E293B',
                                textAlign: 'center'
                            }}>{this.state.msg_text}</span>
                        </div>
                    </DialogContent>
                </Dialog>
            </>
            //Merge Engine End DefaultContainer
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const cardStyle = {
    plusIcon: {
        width: "12px",
        marginRight: "4px"
    },
    buttonAddAddress: {
        backgroundColor: "var(--website-builder-primary-light-accent-color)",
        border: "1px solid var(--website-builder-primary-color)",
        paddingTop: "16px",
        paddingBottom: "16px",
        fontFamily: "var(--website-builder-body-font)",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        letterSpacing: "0em",
        color: "var(--website-builder-secondary-color)",
        width: "100%",
        borderRadius: "6px",
        marginBottom: "20px",
        display: "flex",
        justifyContent: "center"
    },
    cardImageStyles: {
        aspectRatio: "1/1",
        display: 'flex',
        padding: "8px"
    },
    editAddressIcon: {
        width: "44px",
        height: "44px",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#FFF",
        borderRadius: "33px",
    } as React.CSSProperties,
    card: {
        boxShadow: "none",
        marginBottom: '15px',
        padding: '20px',
        borderRadius: '8px',
        position: 'relative'
    },
    titleStyles: {
        fontFamily: "var(--website-builder-header-font)",
        fontWeight: 700,
        color: 'var(--website-builder-secondary-color)',
        alignSelf: 'center',
        width: "50%",
    },
    notAvailableText: {
        color: "var(--website-builder-secondary-color)",
        fontFamily: "var(--website-builder-body-font)",
        fontStyle: "italic",
        fontWeight: 600,
        backgroundColor: "#E2E8F0",
        textAlign: "center",
        marginLeft: "6px",
    },
    "& .MuiTypography-body2": {
        fontFamily: "var(--website-builder-body-font)",
    },
    root: {
        flexGrow: 1,
    },
    landingPageCard: {
        maxHeight: "195px",
        boxShadow: "none",
        marginBottom: '15px',
        padding: '20px',
        borderRadius: '8px',
        position: 'relative'
    },

    "@media (max-width: 480px)": {
        card: {
            borderBottom: '1px solid var(--website-builder-secondary-light-accent-color)'
        },
        titleStyles: {
            fontSize: '16px'
        },
        editAddressIcon: {
            left: '85% !important'
        }
    },
    radioBtnStyle: {
        color: "var(--website-builder-primary-color)"
    }


};
const useStyles: Record<string, CSSProperties> = {
    inputTextLable: {
        'label + &': {
            marginTop: 36,
        },
        "& .MuiOutlinedInput-notchedOutline":{
            border:'1px solid var(--website-builder-secondary-light-accent-color)',
            "&:hover":{
                border:'1px solid var(--website-builder-secondary-color)',
            }
        },
        "&.MuiOutlinedInput-root":{
            "&.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline":{
                    border:'1px solid var(--website-builder-secondary-color)',
                    "&:hover":{
                        border:'1px solid var(--website-builder-secondary-color)',
                    }
                },
            }
        }
    },
    noOption: {
        color: 'var(--website-builder-secondary-dark-accent-color)'
    },
    countryAutocomplete: {
        padding: "0px",
        paddingLeft: "12px",
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        height: "50px",
        paddingRight: "4px",
        paddingBottom: "4px",
        "&.MuiOutlinedInput-root": {
            "&.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline": {
                    border: '1px solid var(--website-builder-secondary-color)',
                    "&:hover": {
                        border: '1px solid var(--website-builder-secondary-color)',
                    }
                },
            }
        },
        "& .MuiInputAdornment-root > svg": {
            color: "var(--website-builder-secondary-color)"
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: '1px solid var(--website-builder-secondary-light-accent-color)',
            "&:hover": {
                border: '1px solid var(--website-builder-secondary-color)',
            }
        },
    },
    inputDialog: {
        fontFamily: "var(--website-builder-body-font)",
        lineHeight: "24px",
        borderRadius: '4px',
        position: 'relative',
        backgroundColor: '#FFF',
        color: 'var(--website-builder-secondary-color)',
        fontSize: '14px',
    },
    noordersText: {
        fontSize: "14px",
        color: "var(--website-builder-secondary-dark-accent-color)",
        maxWidth: "395px",
        width: "100%",
        lineHeight: '22px',
        fontFamily: "var(--website-builder-body-font)",
    },
    breadcrumbLinkText: {
        fontFamily: "var(--website-builder-body-font)",
    },
    breadcrumbLink: {
        color: "var(--website-builder-secondary-dark-accent-color)",
        textDecoration: "none",
        fontFamily: "var(--website-builder-body-font)",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        '&:hover': {
            textDecoration: "none",
            cursor: "pointer"
        }
    },
    bdTop: {
        borderTop: " 1px solid var(--website-builder-secondary-light-accent-color)"
    },
    bdBottom: {
        borderBottom: " 1px solid var(--website-builder-secondary-light-accent-color)"
    },
    smItemValue: {
        fontSize: '16px',
        fontFamily: "var(--website-builder-body-font)",
        fontWeight: 400,
        color: 'var(--website-builder-secondary-color)',
        lineHeight: '24px'
    },
    smItemTotalValue: {
        fontSize: '24px',
        fontFamily: "var(--website-builder-body-font)",
        fontWeight: 700,
        color: 'var(--website-builder-secondary-color)',
        lineHeight: '32px'
    },
    smItemLabel: {
        fontSize: '16px',
        fontFamily: "var(--website-builder-body-font)",
        fontWeight: 400,
        color: 'var(--website-builder-secondary-dark-accent-color)',
        lineHeight: '24px'
    },
    smApplyBtn: {
        borderRadius: '0 8px 8px 0',
        backgroundColor: 'var(--website-builder-primary-color)',
        fontSize: '12px',
        fontFamily: "var(--website-builder-body-font)",
        fontWeight: 600,
        color: '#fff',
        lineHeight: '14.52px',
        width: "100%",
        height: "44px",
        flex: 1,
        minWidth: 'auto',
        '&:hover': {
            backgroundColor: 'var(--website-builder-primary-color)',
        }
    },
    smRoot: {
        padding: '20px 24px',
        borderRadius: '12px 12px 32px 12px',
        backgroundColor: '#fff'
    },
    smNoteLabel: {
        fontSize: '12px',
        fontFamily: "var(--website-builder-body-font)",
        fontWeight: 700,
        color: 'var(--website-builder-secondary-color)',
        lineHeight: '18px',
        marginBottom:'8px',
        marginTop:'14px',
        "@media (max-width: 550px)": {
            marginTop:'10px',
        }
        
    },
    smLockimg: {
        width: '16px',
        height: '16px'
    },
    smLocktext: {
        fontSize: '14px',
        fontFamily: "var(--website-builder-body-font)",
        fontWeight: 400,
        color: 'var(--website-builder-secondary-dark-accent-color)',
        lineHeight: '22px',
        marginLeft: "10px"
    },
    smNoteValue: {
        fontFamily: "var(--website-builder-body-font)",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        letterSpacing: "0em",
        color: "var(--website-builder-secondary-dark-accent-color)",
    },
    smBtn: {
        borderRadius: '8px',
        backgroundImage: 'linear-gradient(99.09deg, var(--website-builder-primary-dark-accent-color) 2.64%, var(--website-builder-primary-color) 100%)',
        fontSize: '16px',
        fontFamily: "var(--website-builder-body-font)",
        fontWeight: 700,
        color: '#fff',
        lineHeight: '24px',
        width: "100%",
        height: "56px",
        textTransform: "capitalize"
    },
    smTitle: {
        fontSize: "24px",
        fontFamily: "var(--website-builder-header-font)",
        lineHeight: "32px",
        fontWeight: 700,
        color: "var(--website-builder-secondary-color)",
        padding: "0px 0px 17px 0px"
    },
    noOrdersBox: {
        width: "100%",
        maxWidth: "528px",
        borderRadius: "10px",
        height: "100%",
        maxHeight: "267px",
        display: "flex",
        justifyContent: "center",
        backgroundColor: '#FFFFFF',
        "@media (max-width: 550px)": {
            backgroundColor: 'transparent'
        }
    },
    ordersContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
    },
    backBtn: {
        padding: "16px 26px 16px 26px",
        borderRadius: "3px",
        background: 'linear-gradient(180deg, var(--website-builder-primary-dark-accent-color) 0%, var(--website-builder-primary-color) 100%)',
        fontSize: '16px',
        fontWeight: 700,
        fontFamily: "var(--website-builder-body-font)",
        marginTop: '10px',
        textTransform: 'none',
        "&:hover": {
            background: 'linear-gradient(180deg, var(--website-builder-primary-dark-accent-color) 0%, var(--website-builder-primary-color) 100%)',
        }
    },
    cartTitle: {
        fontSize: '30px',
        fontFamily: "var(--website-builder-header-font)",
        fontWeight: 700,
        lineHeight: '40px',
        color: 'var(--website-builder-secondary-color)',
        paddingBottom: '16px'
    },
    secondaryColor: {
        color: "var(--website-builder-secondary-color)"
    },
    breadcrumbsWrapper: {
        padding: "16px 8px",
    },
    breadcrumbsOnScreen: {
        marginTop: "30px",
        display: "block",
        padding: "0 24px",
        backgroundColor: "#F1f5f9",
        "@media (max-width: 550px)": {
            padding: "0 16px",
            marginTop: "0px",
            paddingTop: "30px",
            backgroundColor: "#FFF"
        },
    },
    breadcrumbNonLink: {
        fontFamily: "var(--website-builder-body-font)",
        color: "var(--website-builder-secondary-color)",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        "@media (max-width: 480px)": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontFamily: "var(--website-builder-body-font)",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "22px",
            variantInfo: {
                flexDirection: 'column'
            },
            priceRoot: {
                position: 'absolute',
                top: '0px',
                right: '0px'
            },
        },
        "@media (max-width: 900px)": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontFamily: "var(--website-builder-body-font)",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "22px",
        },
        "@media (max-width: 320px)": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontFamily: "var(--website-builder-body-font)",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "22px",
        },
    },
    smItems: {
        display: "flex",
        justifyContent: 'space-between;',
        padding: "10px 0px",

    },
    pdtb: {
        padding: '10px 0px',
    },
    pdtb24: {
        padding: '10px 0px',

    },
    pd10: {
        padding: '10px'
    },
    promobox: {
        display: 'flex',
        position: 'relative'
    },
    smSecurebox: {
        display: 'flex',
        alignItems: "center",
        marginTop: "10px"
    },
    smpromo: {
        width: "100%",
        borderRadius: "8px 0 0 8px",
        border: "1px solid var(--website-builder-secondary-light-accent-color)",
        padding: '10px 8px 10px 8px',
        height: '44px',
        position: 'relative',
        '&::before, &::after': {
            borderBottom: "none !important"
        },
        '& .MuiInputBase-input': {
            fontFamily: "var(--website-builder-body-font)",
        },
        "& input": {
            color: 'var(--website-builder-secondary-color);',
        }
    },
    ctSize: {
        color: 'var(--website-builder-secondary-color);',
        fontFamily: "var(--website-builder-body-font)",
        lineHeight: '22px',
        fontSize: '14px',
        "&.label": {
            color: 'var(--website-builder-secondary-dark-accent-color);',
        }
    },
    font400: {
        fontWeight: 400
    },
    font700: {
        fontWeight: 700
    },
    cartEmptyText: {
        marginBottom: "13px",
        fontFamily: "var(--website-builder-header-font)",
        fontSize: "26px",
        fontWeight: 500,
        lineHeight: "31.8px",
        color: "var(--website-builder-secondary-color)",
    },
    landingScrollStyle: {
        minHeight: "100px",
        maxHeight: '1600px',
        overflowY: 'auto'
    },
    cardDiver: {
        backgroundColor: "#E2E8F0",
        marginTop: "-16px"
    },
    cardContent: {
        cursor: 'pointer',
        width: '100%',
        padding: "6px 16px",
        marginLeft: '16px'
    },
    variantInfo: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginBottom: '8px'
    },
    itemIncrease: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '8px',
    },
    priceRoot: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '8px',
        justifyContent: 'space-between',
        width: "100%"
    },
    priceInfo: {
        display: 'flex',
        alignItems: "center"
    },
    priceblock: {
        display: 'flex',
        flexDirection: 'column'
    },
    discountPrice: {
        textDecoration: "line-through",
        fontWeight: 400,
        color: 'var(--website-builder-secondary-dark-accent-color)',
        marginLeft: "0px",
        fontSize: '12px',
        fontFamily: "var(--website-builder-body-font)",
    },
    mrR10: {
        marginRight: "10%"
    },
    editBtn: {
        color: 'var(--website-builder-secondary-color)',
        fontFamily: "var(--website-builder-body-font)",
        lineHeight: '24px',
        fontSize: '12px',
        border: "1px solid var(--website-builder-secondary-dark-accent-color)",
        padding: '4px 16px 4px 16px',
        borderRadius: '100px'
    },
    smpromoClear: {
        borderRadius: "8px",
    },
    fW500: {
        fontWeight: 500
    },
    removeBtn: {
        paddingLeft: "1px",
        marginLeft: "-3px"
    },
    diInputLabel: {
        color: '#64748B',
        fontFamily: "var(--website-builder-body-font)",
        fontSize: '14px',
        lineHeight: '22px',
        paddingLeft: "10px"
    },
    noCartRoot: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: 'rgb(241, 245, 249)',
        minWidth: '428px',
        alignItems: 'center',
    },
    mrb10: {
        marginBottom: '10px'
    },
    mr8: {
        margin: "8px"
    },
    price: {
        fontFamily: "var(--website-builder-body-font)",
        fontWeight: 700,
        fontSize: '18px',
        color: 'var(--website-builder-secondary-color)'
    },
    countText: {
        fontFamily: "var(--website-builder-body-font)",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "var(--website-builder-secondary-color);",
    },
    cartList: {
        cartItem: {

        }
    },
    landingPageCard: {
        boxShadow: "none",
        marginBottom: '15px',
        padding: '20px',
        borderRadius: '8px',
        position: 'relative'
    },
    cardContentStyles: {
        display: 'flex',
        borderRadius: '0px, 4px, 4px, 0px',
        border: '1px',
        borderColor: 'var(--website-builder-secondary-light-accent-color)',
        alignItems: 'center',
        backgroundColor: '#ffffff',
    },
    clearIcon: {
        position: "absolute",
        right: "10px",
        top: "9px",
        color: "var(--website-builder-secondary-color)",
    },
    promoCodeMsg: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "var(--website-builder-secondary-color)",
        fontFamily: "var(--website-builder-body-font)",
        marginLeft: "8px"
    },
    alertbox: {
        borderRadius: "4px",
        padding: "4px 25px 4px 16px",
        fontSize: "14px",
        lineHeight: "22px",
        fontWeight: 400,
        fontFamily: "var(--website-builder-body-font)",
        margin: '0px 0px 10px'
    },
    warningMsg: {
        borderLeft: "4px solid #D97706",
        backgroundColor: "#FEF3C7",
        color: "#D97706",
    },
    updateMsg: {
        borderLeft: "4px solid var(--website-builder-primary-color)",
        background: "#F8FAFC",
        color: 'var(--website-builder-secondary-color)'
    },
    failMsg: {
        borderLeft: "4px solid #DC2626",
        background: "#FEE2E2",
        color: '#DC2626'
    },
    promoImg: {
        width: "20.8px",
        height: "20.8px",
    },
    promoMsgBox: {
        display: "flex",
        padding: "10px 0px",
        alignItems: "center"
    },
    promoDiscountbox: {
        display: "flex",
        padding: "10px 0px",
        justifyContent: "space-between",
        alignItems: "center"
    },
    dicountCodeBox: {
        display: "flex",
        alignItems: "center"
    },
    discountCodeMsg: {
        fontSize: "14px",
        lineHeight: "24px",
        fontWeight: 600,
        color: "#059669",
        borderRadius: "3px",
        padding: "5px 8px 5px 8px",
        backgroundColor: "#D1FAE5",
        fontFamily: "var(--website-builder-body-font)",
    },
    discountAppliedMsg: {
        fontFamily: "var(--website-builder-body-font)",
        fontStyle: "italic",
        fontSize: "13px",
        lineHeight: "15.73px",
        fontWeight: 500,
        color: "var(--website-builder-secondary-dark-accent-color)",
        marginLeft: "8px"
    },
    diccountValue: {
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 400,
        color: "var(--website-builder-secondary-color)",
        fontFamily: "var(--website-builder-body-font)",
    },
    radioBtnRoot: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    radioBtnIcon: {
        borderRadius: '50%',
        width: 20,
        height: 20,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    radioBtnCheckedIcon: {
        backgroundColor: 'var(--website-builder-primary-color)',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 20,
            height: 20,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: 'var(--website-builder-primary-color)',
        },
    },
    collectionAddress: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "var(--website-builder-body-font)",
        color: "var(--website-builder-secondary-color)",
        paddingLeft: " 23px",
        paddingRight: " 23px"
    },
    deliveryAddress: {
        fontFamily: "var(--website-builder-body-font)",
        color: "var(--website-builder-secondary-color)",
        marginTop: "8px"
    },
    cAddressTitle: {
        fontSize: "14px",
        lineHeight: "22px",
        fontWeight: 400,
        fontFamily: "var(--website-builder-body-font)",
        color: "var(--website-builder-secondary-color)",
        marginLeft: "8px"
    },
    dAddressTitle: {
        fontSize: "12px",
        lineHeight: "18px",
        marginLeft: "10px"
    },
    cAddressImg: {
        width: "13.5px",
        height: "16.3px"
    },
    cAddressBox: {
        display: "flex",
        alignItems: "center"
    },
    cAddress: {
        backgroundColor: "var(--website-builder-primary-light-accent-color)",
        borderRadius: "10px",
        padding: "11px 20px 11px 20px"
    },
    radioBtn: {
        backgroundColor: "#fff",
        borderRadius: "30px",
        border: "1px solid var(--website-builder-secondary-light-accent-color)",
        padding: "10px 12px 10px 12px",
        height: "44px",
        '& .MuiTypography-body1': {
            fontWeight: 700,
            fontFamily: 'var(--website-builder-body-font)',
            fontSize: '16px',
            lineHeight: '24px',
            color: 'var(--website-builder-secondary-color)'
        }
    },
    radioBtnChecked: {
        backgroundColor: "var(--website-builder-primary-light-accent-color)",
        color: "var(--website-builder-secondary-color)",
    },
    radioBtnUnhecked: {
        backgroundColor: "#fff",
        color: "var(--website-builder-secondary-color)"
    },
    orderTypeTitle: {
        fontSize: "14px",
        lineHeight: "22px",
        color: "#1E293B",
        fontFamily: "var(--website-builder-body-font)",
        margin: '0px 0px 7px 10px'
    },
    radioGroup: {
        marginLeft: "10px"
    },
    mrl13: {
        marginLeft: "13px"
    },
    paymentType: {
        fontSize: '14px',
        padding: '10px 0px'
    },
    paymentRadio: {
        border: "none",
        padding: "0px"
    },
    paymentOption: {
        '& .MuiTypography-body1': {
            fontFamily: "var(--website-builder-body-font)",
        },
        fontFamily: "var(--website-builder-body-font)",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "18px",
        letterSpacing: "0em",
        color: "var(--website-builder-secondary-color)"
    },
    btnDisable: {
        pointerEvents: "none"
    },
    storeMsgBox: {
        backgroundColor: "#FFF",
        padding: "24px 20px 24px 20px",
        borderRadius: "12px",
        margin: "8px",
        boxShadow: " 0px 4px 8px 0px #00000008"
    },
    storeMsgTitle: {
        fontFamily: "var(--website-builder-header-font)",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "28px",
        letterSpacing: "0em",
        textAlign: "center",
        color: "#DC2626",
    },
    storeMsg: {
        fontFamily: "var(--website-builder-body-font)",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        letterSpacing: "0em",
        textAlign: "center",
        color: "#64748B",
    },
    inputText: {
        fontFamily: "var(--website-builder-body-font)",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "24px",
        textAlign: "left",
        color: "var(--website-builder-secondary-dark-accent-color)"
    },
    mrlr8: { margin: '0px 8px' },
    flag: {
        fontSize: '24px'
    },
    personalDetailLabel: {
        fontFamily: "var(--website-builder-body-font)",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "22px",
        textAlign: "left",
        color: "var(--website-builder-secondary-dark-accent-color)"
    },
    br8: {
        borderRadius: '8px',
        '& .MuiInputBase-input': {
            fontFamily: "var(--website-builder-body-font)",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "24px",
            textAlign: "left",
            color: "var(--website-builder-secondary-dark-accent-color)"
        },
        '&.Mui-error': {
            border: "1px solid #F87171"
        }
    },
    personalDetailTitle: {
        fontFamily: "var(--website-builder-body-font)",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        textAlign: "left",
        padding: "25px 0px 10px",
        color: 'var(--website-builder-secondary-color)'
    },
    pramotionText: {
        fontFamily: "var(--website-builder-body-font)",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        textAlign: "left",
        color: "var(--website-builder-secondary-color)",
        width: "290px"
    },
    checkboxroot: {
        padding: '0px !important',
        marginRight: '9px'
    },
    icon: {
        borderRadius: 6,
        width: 20,
        height: 20,
        borderWidth: 25,
        boxShadow: 'inset 0 0 0 2px var(--website-builder-primary-color), inset 0 -1px 0 rgba(136,51,255,.1)',
        backgroundColor: '#ffffff',
    },
    checkedIcon: {
        borderWidth: 20,
        backgroundColor: 'var(--website-builder-primary-color)',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 20,
            height: 20,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: 'var(--website-builder-primary-color)',
            borderWidth: 20,
        },
    },
    errorMsg: {
        fontFamily: "var(--website-builder-body-font)",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
        textAlign: "left",
        color: "#DC2626"
    },
    enableColon: {
        display: 'none'
    },
    editAddressIcon: {
        width: "44px",
        position: "relative",
        left: "96%",
        bottom: "75%",
    },
    "@media (max-width: 900px)": {
        mainDivCon: {
            justifyContent: "space-between !important"
        },
        cAddress: {
            backgroundColor: "var(--website-builder-primary-light-accent-color)",
            padding: "16px 8px 16px 6px"
        },
        editAddressIcon: {
            left: "93%"
        }
    },
    '@media (max-width: 768px)': {
        storeMsgTitle: {
            lineHeight: "22px",
            fontSize: "14px",
        },
        storeMsg: {
            fontSize: "12px",
            lineHeight: "18px",
        },
        cAddress: {
            backgroundColor: "var(--website-builder-primary-light-accent-color)",
            padding: "16px 8px 16px 6px"
        },
    },
    "@media (max-width: 480px)": {
        variantInfo: {
            flexDirection: 'column'
        },
        priceRoot: {
            position: 'absolute',
            top: '26px',
            right: '0px',
            width: 'auto'
        },
        price: {
            order: -1,
            paddingBottom: '8px',
            fontSize: '16px'
        },
        landingPageCard: {
            borderBottom: 0,
            marginBottom: 0,
            paddingLeft: 0,
            paddingRight: 0
        },
        titleCard2: {
            fontSize: '16px',
            width: '80% !important',
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            "-webkit-line-clamp": 2,
            "-webkit-box-orient": "vertical",
            wordBreak: "break-word"
        },
        cardContent: {
            paddingBottom: "0px !important",
            paddingRight: 0,
            marginLeft: "0px"
        },
        ctSize: {
            fontSize: '12px'
        },
        cardContentStyles: {
            alignItems: 'flex-start'
        },
        smRoot: {
            padding: 0,
            marginTop: 0
        },
        smItemLabel: {
            fontSize: '14px',
            lineHeight: '22px'
        },
        smItemValue: {
            fontSize: '14px',
            color: 'var(--website-builder-secondary-color)',
            lineHeight: '22px'
        },
        smTotal: {
            color: "var(--website-builder-secondary-dark-accent-color)"
        },
        smItemTotalValue: {
            fontSize: '18px',
            lineHeight: '26px'
        },
        smLockimg: {
            width: '16px',
            height: '16px'
        },
        smApplyBtn: {
            fontSize: '12px',
            fontWeight: 600,
            color: '#fff',
            lineHeight: '14.52px',
        },
        smpromo: {
            fontSize: "16px",
            fontWeight: 400,
            fontFamily: "var(--website-builder-body-font)",
            lineHeight: " 24px",
            color: "#0F172A",
            height: '44px',
        },
        discountAppliedMsg: {
            fontSize: "12px",
            lineHeight: "14.52px",
        },
        diccountValue: {
            fontSize: "14px",
            lineHeight: "22px",
        },
        cAddress: {
            backgroundColor: "#F6F0FF",
            padding: "16px 8px 16px 6px"
        },
        cAddressTitle: {
            fontSize: "12px",
            lineHeight: "18px",
            fontWeight: 400,
            color: "var(--website-builder-secondary-color)",
        },
        dAddressTitle: {
            fontSize: "14px",
            lineHeight: "22px",
            fontWeight: 700,
            color: "#0F172A",
        },
        enableColon: {
            display: 'inline-block'
        },
        collectionAddress: {
            fontWeight: 500,
            color: "var(--website-builder-secondary-color)",
        },
        deliveryAddress: {
            fontWeight: 500,
            color: "var(--website-builder-secondary-color)",
            paddingLeft: '10px',
            paddingRight: '14%'
        },
        dLocationIcon: {
            display: 'none'
        },
        alertbox: {
            fontSize: "12px",
            lineHeight: "28px",
        },
        orderTypeRoot: {
            borderBottom: "1px solid var(--website-builder-secondary-light-accent-color) "
        },
        cartEmptyText: {
            fontFamily: "var(--website-builder-body-font)",
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "28px",
            color: "var(--website-builder-secondary-color)",
        },
        noordersText: {
            fontFamily: "var(--website-builder-body-font)",
        },
        backBtn: {
            padding: "16px",
            borderRadius: "8px",
            fontSize: '14px',
            position: "absolute",
            bottom: '8px',
            width: '91%',
            margin: "8px 0px"
        },
        noCartRoot: {
            position: "relative"
        },
        smTitle: {
            visibility: "hidden"
        },
        editAddressIcon: {
            left: "85%",
        },
    }
}

const webStyles = {
    viewEmpty: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: "72%"
    } as React.CSSProperties,
    flexColumn: {
        display: 'inline-flex',
        flexDirection: "column",
        alignItems: "center",
        padding: "44px 0"
    } as React.CSSProperties,
    container: { backgroundColor: "#F1F5F9", flex: 1 },
    pointer: { cursor: "pointer" },
    error: {
        color: "#f44336",
        fontSize: "13px",
        fontFamily: "var(--website-builder-body-font)",
        lineHeight: "18px",
        marginLeft: "13px",
        marginTop: "2px"
    },
    msgText: {
        fontFamily: "var(--website-builder-header-font)",

    },
    proceedButton: {
        background: "linear-gradient(99.09deg, var(--website-builder-primary-dark-accent-color) 2.64%, var(--website-builder-primary-color) 100%)",
        width: "100%",
        fontFamily: "var(--website-builder-body-font)",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        letterSpacing: "0em",
        color: "#fff",
        textAlign: "center",
        paddingTop: "11px",
        paddingBottom: "11px",
        marginTop: "20px",
        borderRadius: "8px"
    } as React.CSSProperties,
    inputPlaces: {
        padding: "2px 8px",
        fontSize: "14px",
        lineHeight: "24px",
        fontWeight: 600,
        color: "#000"
    },
    viewDialog: {
        borderRadius: '6px',
        border: '0px solid #D0D2DA',
        // height: "85%"
    },
    containerInput: {
        paddingLeft: "28px",
        paddingRight: "28px",
    },
    areaGap: {
        height: "6px"
    },
    textTitle: {
        width: "100%",
        textAlign: "center",
        color: "var(--website-builder-secondary-color)",
        fontFamily: "var(--website-builder-header-font)",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "26px",
        letterSpacing: "0em",
        position: "sticky",
        top: 0,
        paddingBottom: "20px",
        paddingTop: "30px",
        zIndex: 2,
        backgroundColor: "#fff"
    } as React.CSSProperties,
    closeIcon: {
        width: "24px",
        position: "absolute",
        zIndex: 1,
        right: "20px",
        cursor: 'pointer',
        color: "var(--website-builder-secondary-color)"
    } as React.CSSProperties,
    gap: {
        marginBottom: "12px"
    },
    viewMap: {
        marginBottom: "30px"
    },
    title: {
        color: "var(--website-builder-secondary-color)",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "var(--website-builder-body-font)",
        marginLeft: "4px"
    },
    viewAddress: {
        display: 'flex',
        flexDirection: "column"
    } as React.CSSProperties,
    expandIcon: {
        color: '#64748B'
    },
    zoneScrollBar: {
        scrollbarColor: "var(--website-builder-primary-color) #E2E8F0",
        scrollbarWidth: "auto"
    } as React.CSSProperties
}


export const RazorpayCheckoutComponent: FunctionComponent<IRazorProps> = (props) => {
    const { razorpay, account, total } = props
    const [Razorpay] = useRazorpay();
    const options: RazorpayOptions = {
        order_id: razorpay.attributes.razorpay_order_id,
        description: razorpay.attributes.order_description,
        currency: razorpay.attributes.currency,
        key: razorpay.attributes.key,
        amount: total, //will come as props
        name: configJSON.projectNameText,
        handler: (resp) => {
            props.handleSuccessResponse(resp);
        },
        modal: {
            ondismiss: () => {
                props.onClose()
            }
        },
        prefill: {
            email: account.attributes.email, //will come in props
            contact: account.attributes.phone_number,
            name: account.attributes.full_name
        },
        theme: { color: configJSON.themeColor },
    };

    useEffect(() => {
        new Razorpay(options).open();
    }, [Razorpay])

    return null
};

const StyledComponent = withStyles(useStyles)(CartOrders);
export default withWidth()(StyledComponent);

// Customizable Area End
