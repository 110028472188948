export const bannerImage = require("../assets/bannerImage.jpeg");
export const bannerMobileImage = require("../assets/bannerMobileImage.jpg");
export const pizzaCard = require("../assets/pizzaCard.png");
export const arrowRight = require("../assets/arrowRight.png");
export const arrowLeft = require("../assets/arrowLeft.png");

export const carrot = require("../assets/carrot.png");
export const fish = require("../assets/fish.png");
export const greenVeg = require("../assets/greenVeg.png");

export const nuts = require("../assets/nuts.png");
export const singleNuts = require("../assets/singleNuts.png");
export const egg = require("../assets/egg.png");

export const sandwich = require("../assets/sandwich.png");
export const wheat = require("../assets/wheat.png");

export const carrotIcon = require("../assets/carrotIcon.png");
export const dummyLogo = require("../assets/dummy-logo.png");
export const backIcon = require("../assets/backIcon.svg");
export const searchIcon = require("../assets/search.svg");
export const drawerButton = require("../assets/drawerButton.png");
export const backSearch = require("../assets/backSearch.png");
export const noSearchImage = require("../assets/noSearchImage.png");
export const filterIcon = require("../assets/filterImage.png");
export const arrowUp = require("../assets/arrowUp.png");
export const arrowDown = require("../assets/arrowDown.png");
export const closeIcon = require("../assets/close.png");

export const vegIcon = require("../assets/veg.png");
export const vegan = require("../assets/vegan.png");
export const nonVegIcon = require("../assets/nonVeg.png");
export const halal = require("../assets/halal.png");
export const kosher = require("../assets/kosher.png");
export const glutenFree = require("../assets/glutenFree.png");

export const milk = require("../assets/milk.png");
export const soy = require("../assets/soy.png");
export const peanuts = require("../assets/peanuts.png");
export const nutsFilter = require("../assets/nutsFilter.png");
export const wheatFilter = require("../assets/wheatFilter.png");
export const eggsFilter = require("../assets/eggsFilter.png");
export const celery = require("../assets/celery.png");
export const fishFilter = require("../assets/fishFilter.png");
export const lupin = require("../assets/lupin.png");
export const crustaceans = require("../assets/crustaceans.png");
export const molusc = require("../assets/molusc.png");
export const mustard = require("../assets/mustard.png");
export const seasameSeeds = require("../assets/seasameSeeds.png");
export const sulphurDiaoxide = require("../assets/sulphurDiaoxide.png");

export const mild = require("../assets/mildSpicy.png");
export const spicy = require("../assets/spicy.png");
export const extraHot = require("../assets/extraHot.png");

export const filter = require("../assets/filterDrawer.png");

export const plusAdd = require("../assets/plusAdd.png");
export const minusRemove = require("../assets/minusRemove.png");
export const disablePlusAdd = require("../assets/disablePlusAdd.png");
