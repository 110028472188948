// @ts-nocheck
import React from "react";
import { Box, Typography } from "@material-ui/core";

const styles = {
  mainDivCon: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    height: "20rem",
    borderRadius: "3px",
    "@media (max-width: 480px)": {
    backgroundColor: "#F1F5F9",
    },
  },
  image: {
    marginTop: "50px",
    width: "64px",
    height: "64px"
  },
  title: {
    fontFamily: "var(--website-builder-body-font)",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "28px",
    textAlign: "center",
    marginTop: "1rem",
    paddingLeft: "4rem",
    paddingRight: "4rem",
    color:"var(--website-builder-secondary-color)",
  },
  body: {
    fontFamily: "var(--website-builder-body-font)",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    textAlign: "center",
    color: "#646464",
    paddingTop: "0.5rem",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    color:"var(--website-builder-secondary-dark-accent-color)",
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
};

const SearchEmpty = (props) => {
  return (
    <Box sx={styles.mainDivCon}>
      <img
        style={styles.image}
        src={require("./assets/food.svg")}
        alt=""
        loading="eagle"
      />
      <Box sx={styles.container}>
        <Typography style={styles.title}>
          We could not find that in our menu.
        </Typography>
        <Typography style={styles.body}>
          Please check for typos or use more general terms. You can also try our
          menu categories to find what you are looking for.
        </Typography>
      </Box>
    </Box>
  );
};

export default SearchEmpty;
