import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class WebsiteBuilderThemeController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getWebsiteThemeSettingApiCallId: string;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.getWebsiteThemeSettingApiCallId = "";
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        const apiMessageType = getName(MessageEnum.RestAPIResponceMessage);
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        let errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        if (message.id === apiMessageType) {
            if (!responseJson.errors && !responseJson.error) {
                if (apiRequestCallId === this.getWebsiteThemeSettingApiCallId) {
                    this.setTheme(responseJson.data.attributes.themes_and_font.data.attributes);
                    await setStorageData("color_and_font_setting", JSON.stringify(responseJson.data.attributes.themes_and_font.data.attributes))
                }
            } else {
                this.parseApiErrorResponse(responseJson.errors && responseJson);
            }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.getWebsiteThemeSetting();
        const cachedSetting = await getStorageData("color_and_font_setting", true);
        if(cachedSetting){
            this.setTheme(cachedSetting);
        }
    }
    setTheme = (setting: { body_text: string | null; header_text: string | null; primary_colour: string | null; secondary_colour: string | null }) => {
        const primaryColor: string = setting.primary_colour ?? "#8833FF";
        const primaryDarkAccentColor: string = this.generateAccentColor(primaryColor, 0.40) ?? "rgba(136, 51, 255, 0.4)";
        const primaryLightAccentColor: string = this.generateAccentColor(primaryColor, 0.13) ?? "rgba(136, 51, 255, 0.13)";
        const secondaryColor: string = setting.secondary_colour ?? "#0F172A";
        const secondaryDarkAccentColor: string = this.generateAccentColor(secondaryColor, 0.60) ?? "rgba(15, 23, 42, 0.6)";
        const secondaryLightAccentColor: string = this.generateAccentColor(secondaryColor, 0.10) ?? "rgba(15, 23, 42, 0.1)";
        this.changeCSSVariable('--website-builder-primary-color', primaryColor);
        this.changeCSSVariable('--website-builder-primary-dark-accent-color', primaryDarkAccentColor)
        this.changeCSSVariable('--website-builder-primary-light-accent-color', primaryLightAccentColor)
        this.changeCSSVariable('--website-builder-secondary-color', secondaryColor)
        this.changeCSSVariable('--website-builder-secondary-dark-accent-color', secondaryDarkAccentColor)
        this.changeCSSVariable('--website-builder-secondary-light-accent-color', secondaryLightAccentColor)
        this.changeCSSVariable('--website-builder-header-font', setting.header_text ?? "Inter")
        this.changeCSSVariable('--website-builder-body-font', setting.body_text ?? "Rubik")
    }
    getWebsiteThemeSetting = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
        };
        const getThemeSettingMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getWebsiteThemeSettingApiCallId = getThemeSettingMessage.messageId;
        getThemeSettingMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getThemeSettingEndPoint);
        getThemeSettingMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        getThemeSettingMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
        runEngine.sendMessage(getThemeSettingMessage.id, getThemeSettingMessage);
    }
    generateAccentColor = (color: string, opacityPercentage:number) => {
        let redColor, green, blue, transparent;

        // RGBA format
        if (color.startsWith('rgba(')) {
            const rgbaValues = color.slice(5, -1).split(',');
            redColor = parseInt(rgbaValues[0]);
            green = parseInt(rgbaValues[1]);
            blue = parseInt(rgbaValues[2]);
            transparent = parseFloat(rgbaValues[3]);
        }
        // HEX format
        else if (color.startsWith('#') && (color.length === 4 || color.length === 7)) {
            redColor = parseInt(color.slice(1, 3), 16);
            green = parseInt(color.slice(3, 5), 16);
            blue = parseInt(color.slice(5, 7), 16);
            transparent = 1;
        }
        // RGB format
        else if (color.startsWith('rgb(')) {
            const rgbValues = color.slice(4, -1).split(',');
            redColor = parseInt(rgbValues[0]);
            green = parseInt(rgbValues[1]);
            blue = parseInt(rgbValues[2]);
            transparent = 1;
        }
        else {
            return null;
        }



        return `rgba(${redColor}, ${green}, ${blue}, ${transparent * opacityPercentage})`;
    }
    changeCSSVariable(variableName: string, value: string) {
        document.documentElement.style.setProperty(variableName, value);
    }
    // Customizable Area End
}
