export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const searchIcon = require("../assets/searchIcon.svg");
export const eyeIcon = require("../assets/image_eye.svg");
export const printIcon = require("../assets/image_print_24px.svg");
export const noOrdersIcon = require("../assets/image_no_orders.svg");
export const checkboxUncheck = require("../assets/checkbox_uncheck.svg");
export const checkboxCheck = require("../assets/checkbox_check.svg");
export const menuitem = require("../assets/menuitem.svg");
export const nocategories = require("../assets/nocategories.svg");
export const backIcon = require("../assets/back_icon.svg");

