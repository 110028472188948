// Customizable Area Start
import React, { useEffect } from 'react';

type Props = {
    id: string
    countryCode: string
    onChangeLocation: (latitude: number, longitude: number, address: string) => void
    initData: string
    onFocusInput: () => void
    onBlurInput: () => void
}

const AutoCompleteInputPlaces = ({ initData, countryCode, onChangeLocation,onFocusInput, onBlurInput }: Props) => {
    const [value, setValue] = React.useState(initData)
    const inputRef = React.useRef<HTMLInputElement | null>()

    const options = {
        componentRestrictions: { country: countryCode },
        types: ['establishment'],
    };

    const inputStyle = {
        border: '1px solid var(--website-builder-secondary-light-accent-color)',
        width: '100%',
        borderRadius: '4px',
        fontWeight: '600',
        padding: '12px 8px',
        marginTop: "4px",
        fontSize: "14px",
        lineHeight: "24px",
        fontFamily: "var(--website-builder-body-font)",
        color:"var(--website-builder-secondary-color)",
    }

    const autoComplete = new window.google.maps.places.Autocomplete(inputRef.current as HTMLInputElement, options)

    autoComplete.addListener('place_changed', () => {
        const place = autoComplete.getPlace()
        if (place?.geometry?.location) {
            const address = place.name + " " + place.formatted_address
            setValue(address)
            onChangeLocation(place.geometry.location.lat(),  place.geometry.location.lng(), address)
        } else {
            alert("this location not available")
        }
    })

    useEffect(() => {
        setValue(initData)
    }, [initData])

    return (
        <input
            data-test-id="input"
            placeholder=''
            ref={(ref) => inputRef.current = ref}
            style={inputStyle}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onFocus={onFocusInput}
            onBlur={onBlurInput}
        />
    )
}

export default AutoCompleteInputPlaces
// Customizable Area End
