import { BlockComponent } from "../../../framework/src/BlockComponent";
import {
  IntegrationResponseTypes,
  IntegrationTypes,
} from "./OtherIntegrations.types";

// Customizable Area Start
const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  integrationData: IntegrationResponseTypes;
  integrationType?: IntegrationTypes;
  onClick: (
    integration: IntegrationResponseTypes,
    isActivated: boolean
  ) => void;
  onActivationChange: (
    integration: IntegrationResponseTypes,
    isActivated: boolean
  ) => void;
  integrationStatus?: string;
  dialogStatus: boolean;
  dialogStatusOnChange: () => void;
  cardShowStatus: { [key: string]: boolean };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class IntegrationCardKaleyraController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleChange = (active: boolean, integrationData: IntegrationResponseTypes) => {
    this.props.onActivationChange(integrationData, active);
  }

  handleClipboard = (text: string | boolean | undefined) => () => {
    if (!navigator.clipboard) {
      return;
    }
    navigator.clipboard.writeText(String(text));
  };
  // Customizable Area End
}
