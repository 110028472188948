export const logo = require("../assets/dummy-logo.png");
export const green = require("../assets/greenTick.png");
export const cartLogo = require("../assets/cart.png");
export const lockimage = require("../assets/vector.png");
export const add = require("../assets/add.png");
export const remove = require("../assets/remove.png");
export const promoApplied = require("../assets/promo-applied.png");
export const promocodeError = require("../assets/promocode-error.png");
export const location = require("../assets/location.png");
export const backIcon = require("../assets/backArro_icon.svg");
export const plusIcon = require("../assets/plusIcon.svg");
export const marker = require("../assets/marker.svg");
export const gpsIcon = require("../assets/gpsIcon.svg");
export const editAddressIcon = require("../assets/editAddressIcon.svg");
export const successTick = require("../assets/successTick.png");
export const errorTick = require("../assets/error.png");



