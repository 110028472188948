// Customizable Area Start
import React from "react";
import { IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IntegrationCardKaleyraController from "./IntegrationCardKaleyraController.web";
import {
  FieldTypes,
  IntegrationResponseTypes,
} from "./OtherIntegrations.types";

import IOSSwitch from "../../ss-cms-common-components/src/IOSSwitch/IOSSwitch";
import { Dialog } from "../../ss-cms-common-components/src/Dialog/Dialog";
import {clipboardIcon, kaleyraIcon} from "./assets"

const configJSON = require("./config.js");

export class IntegrationCardKaleyra extends IntegrationCardKaleyraController {
  renderCardFields = (
    integrationData: IntegrationResponseTypes,
    integrationName: string
  ) => {
    const integrationTypeConfig = configJSON?.[integrationName];
    const active = Boolean(integrationData?.is_active);

    const showAtCardFields = integrationTypeConfig?.fields?.filter(
      (field: FieldTypes) => field?.showAtCard
    );

    return active
      ? showAtCardFields?.map((field: FieldTypes) =>
          renderActiveField(integrationData, field, this.handleClipboard)
        )
      : renderInactiveField(integrationTypeConfig);
  };

  render() {
    const { integrationData, onClick } = this.props;
    const { integrationName, loading, isFetched } = integrationData;
    const integrationTypeConfig = configJSON?.[integrationName];
    const active = Boolean(integrationData?.is_active);

    const showCard =
      configJSON.fieldShowCard in integrationTypeConfig
        ? this.props?.cardShowStatus?.[integrationName]
        : true;

    const requiredFields = integrationTypeConfig?.fields?.filter(
      (field: FieldTypes) => field.required
    );

    const isRequiredFieldsFilled = requiredFields?.every(
      (field: FieldTypes) =>
        integrationData[field.key as keyof IntegrationResponseTypes]
    );

    const isActivateDisabled = loading || !isFetched || !isRequiredFieldsFilled;

    if (!isFetched) {
      return null;
    }

    if (!showCard) {
      return null;
    }

    return (
      <div className="other-integrations-card-vertical">
        <div className="card-image">
          {integrationName && (
            <img
              src={kaleyraIcon}
              className="other-integrations-card-type"
            />
          )}
        </div>
        <div className="other-integrations-card-details">
          <div className="other-integrations-card-information">
            <div className="other-integration-activate-box">
              <IOSSwitch
                checked={integrationData?.is_active}
                onChange={this.handleChange}
                disabled={isActivateDisabled}
              />
              <label className="other-integration-details-text">
                {active ? configJSON.activated : configJSON.notActivated}
              </label>
            </div>
            <div className="other-integrations-card-configuration-type">
              {integrationTypeConfig?.cartTitle}
            </div>
            {this.renderCardFields(integrationData, integrationName)}
          </div>
          <Button
            className="card-button"
            onClick={() => onClick(integrationData, active)}
            disabled={loading}
          >
            {configJSON.editIntegrationButton}
          </Button>
        </div>
        <Dialog
          open={this.props.dialogStatus}
          setOpen={this.props.dialogStatusOnChange}
          titleClassName="other-integrations-card-dialog-title"
          cancelClassName="other-integrations-card-dialog-cancel"
          title={configJSON.firebaseActivationErrorTitle}
          message={configJSON.firebaseActivationErrorMessage}
          okay={configJSON.firebaseActivationErrorConfirm}
        />
      </div>
    );
  }
}

export const renderActiveField = (
  responseData: IntegrationResponseTypes,
  field: FieldTypes,
  handleClipboard: (text: string | boolean | undefined) => () => void
) => {
  if (!responseData?.[field.key as keyof IntegrationResponseTypes]) {
    return null;
  }
  return (
    <div key={field.label} className="other-integrations-card-card-information">
      <span className="title">{field.label}</span>
      <span className="information">
        {field?.isHiddenFieldAtCard
          ? configJSON.dummyPassword
          :
          responseData?.[field.key as keyof IntegrationResponseTypes]}
      </span>
      <IconButton className="clipboard">
        <img
          src={clipboardIcon}
          onClick={handleClipboard(responseData?.[field.key as keyof IntegrationResponseTypes])}
        />
      </IconButton>
    </div>
  );
};

export const renderInactiveField = (config: { description?: string }) => (
  <div className="other-integrations-card-card-not-active">
    {config?.description}
  </div>
);
// Customizable Area End