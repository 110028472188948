import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
// import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import Websitebuilder from "../../blocks/websitebuilder/src/Websitebuilder";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import DeliveryEstimator from "../../blocks/deliveryestimator/src/DeliveryEstimator";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import OfflineBrowsing from "../../blocks/offlinebrowsing/src/OfflineBrowsing";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Storecontentmanagement2 from "../../blocks/storecontentmanagement2/src/Storecontentmanagement2";
import HyperLocalDelivery from "../../blocks/storecontentmanagement2/src/HyperLocalDelivery";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import AutomaticCheckoutCalculation from "../../blocks/automaticcheckoutcalculation/src/AutomaticCheckoutCalculation";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import LandingPageScreen from "../../blocks/landingpage/src/LandingPage.web";
import Contentmanagement1 from "../../blocks/contentmanagement1/src/Contentmanagement1";
import Adminconsole from "../../blocks/adminconsole/src/Adminconsole";
import Signuplogin from "../../blocks/signuplogin/src/Signuplogin";
import Termsandconditions from "../../blocks/termsandconditions/src/Termsandconditions";
import Ordermanagement1 from "../../blocks/ordermanagement1/src/Ordermanagement1";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Dashboard4 from "../../blocks/dashboard4/src/Dashboard4";
import Paymentadmin from "../../blocks/paymentadmin/src/Paymentadmin";
import Payments from '../../blocks/payments/src/Payments';

import MenuItemDetailsPage from "../../blocks/landingpage/src/MenuItemDetailsPage.web"
import AdminLogin from '../../blocks/adminconsole/src/AdminLogin.web';
import ChangePassword from '../../blocks/adminconsole/src/ChangePassword.web';
import ForgetPassword from '../../blocks/adminconsole/src/ForgetPassword.web';
import StaticPages from "../../blocks/landingpage/src/StaticPages.web";
import OrderConfirmationSummary from "../../blocks/shoppingcart/src/OrderConfirmationSummary.web";
import CartOrders from '../../blocks/shoppingcart/src/CartOrders.web';
import WebsiteBuilderTheme from "../../blocks/storecontentmanagement2/src/WebsiteBuilderTheme";
import StripePaymentsHome from "../../blocks/stripepayments/src/StripePaymentsHome.web";

const routeMap = {
  
  Contentmanagement1: {
    component: Contentmanagement1,
    path: "/Contentmanagement1"
  },
  OrderConfirmationSummary: {
    component: OrderConfirmationSummary,
    path: "/orderconfirmation"
  },
  Adminconsole: {
    component: Adminconsole,
    path: "/Adminconsole"
  },
  Signuplogin: {
    component: Signuplogin,
    path: "/Signuplogin"
  },
  Termsandconditions: {
    component: Termsandconditions,
    path: "/Termsandconditions"
  },
  Ordermanagement1: {
    component: Ordermanagement1,
    path: "/Ordermanagement1"
  },
  Rolesandpermissions: {
    component: Rolesandpermissions,
    path: "/Rolesandpermissions"
  },
  Dashboard4: {
    component: Dashboard4,
    path: "/Dashboard4"
  },
  Paymentadmin: {
    component: Paymentadmin,
    path: "/Paymentadmin"
  },
  Payments: {
    component: Payments,
    path: "/Payments"
  },

  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading"
  },
  StripePaymentsHome: {
    component: StripePaymentsHome,
    path: "/StripePayments",
    exact: true
  },
  SocialMediaAccountLogin: {
    component: SocialMediaAccountLogin,
    path: "/SocialMediaAccountLogin"
  },
  SocialMediaAccountRegistration: {
    component: SocialMediaAccountRegistration,
    path: "/SocialMediaAccountRegistration"
  },
  Filteritems: {
    component: Filteritems,
    path: "/Filteritems"
  },
  Filteroptions: {
    component: Filteroptions,
    path: "/Filteroptions"
  },
  ImportExportData: {
    component: ImportExportData,
    path: "/ImportExportData"
  },
  Websitebuilder: {
    component: Websitebuilder,
    path: "/Websitebuilder"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  DeliveryEstimator: {
    component: DeliveryEstimator,
    path: "/DeliveryEstimator"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: "/Emailnotifications2"
  },
  OfflineBrowsing: {
    component: OfflineBrowsing,
    path: "/OfflineBrowsing"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  Storecontentmanagement2: {
    component: Storecontentmanagement2,
    path: "/Storecontentmanagement2"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  Contactus: {
    component: Contactus,
    path: "/Contactus"
  },
  AddContactus: {
    component: AddContactus,
    path: "/AddContactus"
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: "/ApiIntegration"
  },
  Paymentadmin2: {
    component: Paymentadmin2,
    path: '/Paymentadmin2',
  },
  AutomaticCheckoutCalculation: {
    component: AutomaticCheckoutCalculation,
    path: "/AutomaticCheckoutCalculation"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  ShoppingCartOrders: {
    component: ShoppingCartOrders,
    path: "/ShoppingCartOrders"
  },
  AddShoppingCartOrderItem: {
    component: AddShoppingCartOrderItem,
    path: "/AddShoppingCartOrderItem"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: "/NavigationMenu"
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: "/AdvancedSearch"
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile"
  },
  RequestManagement: {
    component: RequestManagement,
    path: "/RequestManagement"
  },
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: "/Rolesandpermissions2"
  },
  OrderManagement: {
    component: OrderManagement,
    path: "/OrderManagement"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },

  HomeScreen: {
    component: HomeScreen,
    path: '/HomeScreen',
    exact: true
  },
  Home: {
    component: require('../../blocks/ss-cms-common-router/src/WebRoutes').default,
    path: '*',
    exact: true
  },

  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  LandingPageScreen: {
    component: LandingPageScreen,
    path: "/",
    exact: true
  },
  StaticPages: {
    component: StaticPages,
    path: '/StaticPages',
    exact: true
  },
  CartOrders: {
    component: CartOrders,
    path: '/CartOrders',
    exact: true
  },
  StaticPageDetail: {
    component: StaticPages,
    path: '/StaticPages/:id',
    exact: true
  },

  MenuItemDetailsPage: {
    component: MenuItemDetailsPage,
    path: "/MenuItemDetails"
  },
  AdminLogin: {
    component: AdminLogin,
    path: "/AdminLogin"
  },

  ChangePassword: {
    component: ChangePassword,
    path: "/ChangePassword"
  },
  ForgetPassword: {
    component: ForgetPassword,
    path: "/ForgetPassword"
  },
   AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  handleNavigationToOrderConfirm = (path) => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
        getName(MessageEnum.NavigationTargetMessage),
        path
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
}

 render() {

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

const url = window.location.href;
if (url.includes("bx_block_stripe_integration/payment_methods/success")) {
  this.handleNavigationToOrderConfirm("OrderConfirmationSummary")
}
if (url.includes("bx_block_stripe_integration/payment_methods/cancel")) {
  this.handleNavigationToOrderConfirm("CartOrders")
}

return (

  <View style={{ height: '100vh', width: '100vw' }}>
    {/* <TopNav /> */}
    <WebsiteBuilderTheme />
    {WebRoutesGenerator({ routeMap })}
    <ModalContainer />

  </View>

);
  }
}

export default App;