// Customizable Area Start
import React from 'react';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import { gpsIcon, marker } from './assets';
import { configJSON } from './DialogAddressController.web';

type Props = {
    countryCode: string;
    latitude: number;
    longitude: number;
    address: string;
    getCurrentPosition: () => void
}

const MapContainer = ({ countryCode, latitude, longitude, address, getCurrentPosition }: Props) => {
    const [currentAddress, setCurrentAddress] = React.useState(address)
    const mapStyles = {
        height: "305px",
        width: "100%"
    };

    const defaultCenter = {
        lat: latitude,
        lng: longitude
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: configJSON.keyGoogle,
        region: countryCode,
    })

    React.useEffect(() => {
        setCurrentAddress(address)
    }, [address])

    if (isLoaded) {
        return (
            <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={15}
                center={defaultCenter}
            >
                <Marker
                    position={{
                        lat: defaultCenter.lat,
                        lng: defaultCenter.lng
                    }}
                    icon={{ url: marker }}
                    onClick={() => setCurrentAddress(address)}
                    cursor="pointer"
                />
                {
                    currentAddress !== "" && (
                        <InfoWindow
                            zIndex={100}
                            onCloseClick={() => {
                                setCurrentAddress("");
                            }}
                            position={{ lat: latitude, lng: longitude }}
                            options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
                        >
                            <div style={styles.info}>
                                <span style={styles.title}>{configJSON.yourPosition}</span>
                                <span style={styles.detail}>{address}</span>
                            </div>
                        </InfoWindow>
                    )
                }
                <div style={styles.buttonPosition} data-test-id="position" onClick={() => getCurrentPosition()}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.1273 9.09091C17.7091 5.3 14.7 2.29091 10.9091 1.87273V0.909091C10.9091 0.409091 10.5 0 10 0C9.5 0 9.09091 0.409091 9.09091 0.909091V1.87273C5.3 2.29091 2.29091 5.3 1.87273 9.09091H0.909091C0.409091 9.09091 0 9.5 0 10C0 10.5 0.409091 10.9091 0.909091 10.9091H1.87273C2.29091 14.7 5.3 17.7091 9.09091 18.1273V19.0909C9.09091 19.5909 9.5 20 10 20C10.5 20 10.9091 19.5909 10.9091 19.0909V18.1273C14.7 17.7091 17.7091 14.7 18.1273 10.9091H19.0909C19.5909 10.9091 20 10.5 20 10C20 9.5 19.5909 9.09091 19.0909 9.09091H18.1273ZM10 6.36364C7.99091 6.36364 6.36364 7.99091 6.36364 10C6.36364 12.0091 7.99091 13.6364 10 13.6364C12.0091 13.6364 13.6364 12.0091 13.6364 10C13.6364 7.99091 12.0091 6.36364 10 6.36364ZM3.63636 10C3.63636 13.5182 6.48182 16.3636 10 16.3636C13.5182 16.3636 16.3636 13.5182 16.3636 10C16.3636 6.48182 13.5182 3.63636 10 3.63636C6.48182 3.63636 3.63636 6.48182 3.63636 10Z" fill="var(--website-builder-primary-color)"/>
                    </svg>
                    {configJSON.usePosition}
                </div>
            </GoogleMap>
        );
    } else return <></>
};

const styles = {
    info: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    } as React.CSSProperties,
    title: {
        fontFamily: "var(--website-builder-body-font)",
        color: "var(--website-builder-secondary-color)",
        fontSize: "12px",
        lineHeight: "16px",
        fontWeight: 600,
        marginBottom: "4px",
        textAlign: "center"
    } as React.CSSProperties,
    detail: {
        fontFamily: "var(--website-builder-body-font)",
        color: "var(--website-builder-secondary-dark-accent-color)",
        fontSize: "12px",
        lineHeight: "16px",
        fontWeight: 400,
    },
    buttonPosition: {
        position: "absolute",
        bottom: "20px",
        backgroundColor: "#fff",
        display: "inline-flex",
        justifyContent: "center",
        border: "1px solid var(--website-builder-primary-color)",
        padding: "10px",
        borderRadius: "100px",
        gap: "8px",
        left: "50%",
        transform: "translateX(-50%)",
        color: "var(--website-builder-secondary-color)",
        fontFamily: "var(--website-builder-body-font)",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        letterSpacing: "0em",
        cursor: "pointer",
        alignItems: 'center'
    } as React.CSSProperties,
}

export default MapContainer;
// Customizable Area End
