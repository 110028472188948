import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { clearStorageData, isTokenExpired } from "../../ss-cms-common-components/src/Utilities/Utilities";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export type LibraryItem = {
  id: number | string;
  name: string;
  categories: string[];
  created_at: string;
  description: string;
  price: string | number;
  old_price: string | null;
  on_sale: string;
  image_url: string;
  store_name: string;
  checked: boolean;
  selected_price: number | string;
  updatedCategories: {
    categoryList: string[];
    selectedCategory: string;
    searchedText: string;
  };
}
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedLibraryItems: LibraryItem[],
  currencySymbol: string;
  importingItems: boolean;
  uploadedItems: number;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ReviewItemsFromLibraryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCurrencySymbolApiCallId: string = "";
  importLibraryItemsApiCallId: string = "";
  BATCH_SIZE:number = 10;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedLibraryItems: [],
      currencySymbol: '',
      importingItems: false,
      uploadedItems: 0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    await this.getSelectedItems();
    const savedCurrencySymbol = await getStorageData('currency_type');
    this.handleCurrencySymbol(savedCurrencySymbol);
    this.addZIndexToSideBar();
  }

  getSelectedItems = async () => {
    const storedItems:LibraryItem[] = await getStorageData(configJSON.STORED_SEACHDATA_KEY, true);
    if(storedItems && storedItems.length > 0){
      this.setState({
        selectedLibraryItems: storedItems.filter((item:LibraryItem) => item.checked)
      })
    }else{
      this.goBack()
    }
  }
  handleCurrencySymbol = (symbol: string) => {
    if (symbol.trim() !== '') {
      this.setState({
        currencySymbol: symbol
      })
    } else {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
      };
      const requestMessageCurrencySymbol = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getCurrencySymbolApiCallId = requestMessageCurrencySymbol.messageId;

      requestMessageCurrencySymbol.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getCurrencySymbolApiEndPoint
      );
      requestMessageCurrencySymbol.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessageCurrencySymbol.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetType
      );

      runEngine.sendMessage(requestMessageCurrencySymbol.id, requestMessageCurrencySymbol);
    }
  }
  navigateToLogin = () => {
    clearStorageData();
    const toLogin = new Message(getName(MessageEnum.NavigationMessage));
    toLogin.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    toLogin.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(toLogin.messageId, toLogin);
  };
  addZIndexToSideBar = () => {
    const adminSidebar = document.querySelector('.admin-layout__sidebar');
    if (adminSidebar) {
      adminSidebar.classList.add(this.props.classes.ZIndexOnSidebar);
    }
  }
  goBack = () => {
    const goBackFromViewItems: Message = new Message(getName(MessageEnum.NavigationMessage));
    goBackFromViewItems.addData(getName(MessageEnum.NavigationTargetMessage), "InventoryManagementLibrary");
    goBackFromViewItems.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(goBackFromViewItems);
  }
  navigateToInventoryManagement = () => {
    const goToInventoryManagment: Message = new Message(getName(MessageEnum.NavigationMessage));
    goToInventoryManagment.addData(getName(MessageEnum.NavigationTargetMessage), "InventoryManagement");
    goToInventoryManagment.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const alertMessage = new Message(getName(MessageEnum.PostEntityMessage));
    alertMessage.addData(getName(MessageEnum.PostDetailDataMessage), { success: true, message: configJSON.importSuccessMessage });
    goToInventoryManagment.addData(getName(MessageEnum.NavigationRaiseMessage), alertMessage);
    this.send(goToInventoryManagment);
  }

  importNextBatch = () => {
    const { selectedLibraryItems, uploadedItems } = this.state;
    const remainingItems = selectedLibraryItems.length - uploadedItems;

    if (remainingItems > 0) {
      const batch = selectedLibraryItems.slice(uploadedItems, uploadedItems+this.BATCH_SIZE);
      this.importBatch(batch);
    }else{
      removeStorageData(configJSON.STORED_SEACHDATA_KEY);
      this.navigateToInventoryManagement();
    }
  }
  importBatch = async (batch:LibraryItem[]) => {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: await getStorageData('admintoken')
      };
      const bodyData = {
        items:batch.map(category => ({
          name:category.name,
          description:category.description,
          price:category.selected_price,
          image_url:category.image_url,
          categories:category.updatedCategories.selectedCategory
        }))
      }
      const uploadItemApiMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.importLibraryItemsApiCallId = uploadItemApiMessage.messageId;
      uploadItemApiMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.uploadLibraryItemsApiEndPoint);
      uploadItemApiMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
      uploadItemApiMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(bodyData));
      uploadItemApiMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostType);
      runEngine.sendMessage(uploadItemApiMessage.id, uploadItemApiMessage);
  }
  importItems = async () => {
    if (this.state.selectedLibraryItems.length > 0) {
      this.setState({
        importingItems:true
      })
      this.importNextBatch();
    } else {
      this.goBack();
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (isTokenExpired(message)) {
      return this.navigateToLogin();
    }
    const apiMessageType = getName(MessageEnum.RestAPIResponceMessage);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    
    if (message.id === apiMessageType) {
      if (!responseJson.errors && !responseJson.error) {
        if (apiRequestCallId === this.getCurrencySymbolApiCallId) {
          const currencySymbol = responseJson.currency_symbol ?? "$";
          await setStorageData("currency_type", currencySymbol);
          this.setState({
            currencySymbol
          })
        } else if (apiRequestCallId === this.importLibraryItemsApiCallId) {
          this.setState(prevState => ({
            uploadedItems: prevState.uploadedItems + responseJson.items.length
          }), () => {
            this.importNextBatch();
          });
        }
      } else {
        if (apiRequestCallId == this.importLibraryItemsApiCallId){
          this.setState({
            importingItems:false
          })
          alert(responseJson.error)
        }
        this.parseApiErrorResponse(responseJson.errors && responseJson);
      }
    }
    this.parseApiCatchErrorResponse(errorReponse);
    // Customizable Area End
  }
}
