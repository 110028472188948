// Customizable Area Start
import React from "react";
import OtherIntegrationsController from "./OtherIntegrationsController.web";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { Header } from "./Header.web";
import { IntegrationActivateDialog } from "./IntegrationActivateDialog.web";
import "../assets/styles.css";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import withSnackBar from "../../ss-cms-common-components/src/HOC/withSnackBar.Web";
import { IntegrationCardFirebase } from "../../adminotherintegrationsfirebase/src/IntegrationCardFirebase.web";
import { IntegrationResponseTypes, IntegrationTypes } from "../../adminotherintegrationsfirebase/src/OtherIntegrations.types";
import { IntegrationCardTawk } from "../../adminotherintegrationstawk/src/IntegrationCardTawk.web";
import { IntegrationCardKaleyra } from "../../adminotherintegrationskaleyra/src/IntegrationCardKaleyra.web";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout"

const configJSON = require("./config");

export class OtherIntegrations extends OtherIntegrationsController {
  render() {
    const { integrationData, showActivateDialog, selectedIntegrationData } =
      this.state;

    return (
      <div className="other-integrations">
        <Header />
        <div className={"other-integrations-card-container"}>
          <div className="content">
            <IntegrationCardFirebase
              onClick={this.selectIntegration}
              onActivationChange={this.updateIntegrationActivation}
              integrationData={integrationData?.[configJSON.typeFirebase as IntegrationTypes] as IntegrationResponseTypes}
              dialogStatus={this.state.dialogStatus}
              dialogStatusOnChange={this.handleChangeDialogStatus}
              cardShowStatus={this.state.cardShowStatus}
            />
          </div>
          <div className="content">
            <IntegrationCardTawk
              onClick={this.selectIntegration}
              onActivationChange={this.updateIntegrationActivation}
              integrationData={integrationData?.[configJSON.typeTawk as IntegrationTypes] as IntegrationResponseTypes}
              dialogStatus={this.state.dialogStatus}
              dialogStatusOnChange={this.handleChangeDialogStatus}
              cardShowStatus={this.state.cardShowStatus}
            />
          </div>
          <div className="content">
            <IntegrationCardKaleyra
              onClick={this.selectIntegration}
              onActivationChange={this.updateIntegrationActivation}
              integrationData={integrationData?.[configJSON.typeKaleyra as IntegrationTypes] as IntegrationResponseTypes}
              dialogStatus={this.state.dialogStatus}
              dialogStatusOnChange={this.handleChangeDialogStatus}
              cardShowStatus={this.state.cardShowStatus}
            />
          </div>
        </div>

        <IntegrationActivateDialog
          open={!!showActivateDialog}
          setOpen={this.setActivateDialog}
          selectedIntegration={selectedIntegrationData}
          onSubmit={this.updateOtherIntegrations}
        />
      </div>
    );
  }
}

export default withLayout(withSnackBar(withDialog(withLoader(OtherIntegrations))));
// Customizable Area End