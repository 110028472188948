Object.defineProperty(exports, "__esModule", {
  value: true,
});
// Customizable Area Start
exports.displayCardVertical = true;

exports.otherIntegrationsTitle = "Other integrations";

exports.brandSettingsAPI = "/admin/v1/brand_settings";
exports.getApiMethodType = "GET";
exports.updateApiMethodType = "POST";
exports.createApiMethodType = "POST";
exports.deleteApiMethodType = "DELETE";
exports.putApiMethodType = "PUT";

exports.dialogMessage =
  "Please fill in the following fields to activate this integration.";
exports.dialogTitle = "Integration ";

exports.consoleErrorBase = "Async: Could not copy text: ";

exports.notActive = "Not active";
exports.active = "Active";

exports.activateButton = "Activate";
exports.editIntegrationButton = "Edit";
exports.dummyPassword = "*************";
exports.thisFieldIsRequired = "This field is required";
exports.updateIntegrationSuccess = "Integration updated successfully";
exports.firebaseActivationErrorTitle = "You can not activate firebase";
exports.firebaseActivationErrorMessage = "You need to select at least one of the login methods";
exports.firebaseActivationErrorConfirm = "Okay";
exports.activated = "Activated";
exports.notActivated = "Not Activated";
exports.howToUse = "How to use";

exports.errors = {
  errorPageInitialisation: "Error on page initialization",
  errorCardUpdate: "Error on card update",
  googleFirebase: "Google Firebase Login Integration data couldn't be fetched",
  integration: "Integration data couldn't be fetched",
  updateIntegration: "Integration data couldn't be saved",
  default: "Something went wrong",
  brandSettings: "Country data couldn't be fetched",
};

exports.firebase = {
  order: 0,
  name: "Google Firebase Login",
  apiEndpoint: "bx_block_social_media_integration/firebase_setting",
  integrationName: "firebase",
  cartTitle: "Google Firebase Login",
  dialogTitle: "Google Firebase Login",
  description: "Use to authenticate users with Google Firebase Login",
  dialogMessage: "Use to authenticate users with Google Firebase Login",
  howToUseLink:
    "https://intercom.help/engineerai/en/articles/6961907-setting-up-your-firebase-account",
  fields: [
    {
      key: "api_key",
      label: "API Key",
      placeholder: "API Key",
      type: "text",
      showAtCard: true,
      isHiddenFieldAtCard: false,
      integrationActivityCheck: true,
      required: true,
      validations: ["string", "required", "nullable"],
      params: {
        required: exports.thisFieldIsRequired,
      },
    },
    {
      key: "auth_domain",
      label: "Auth Domain",
      placeholder: "Auth Domain",
      type: "text",
      showAtCard: true,
      isHiddenFieldAtCard: true,
      required: true,
      validations: ["string", "required", "nullable"],
      params: {
        required: exports.thisFieldIsRequired,
      },
    },
    {
      key: "project_id",
      label: "Project ID",
      placeholder: "Project ID",
      type: "text",
      required: true,
      validations: ["string", "required", "nullable"],
      params: {
        required: exports.thisFieldIsRequired,
      },
    },
    {
      key: "storage_bucket",
      label: "Storage Bucket",
      placeholder: "Storage Bucket",
      type: "text",
      required: true,
      validations: ["string", "required", "nullable"],
      params: {
        required: exports.thisFieldIsRequired,
      },
    },
    {
      key: "messaging_sender_id",
      label: "Messaging Sender ID",
      placeholder: "Messaging Sender ID",
      type: "text",
      required: true,
      validations: ["string", "required", "nullable"],
      params: {
        required: exports.thisFieldIsRequired,
      },
    },
    {
      key: "app_id",
      label: "App ID",
      placeholder: "App ID",

      type: "text",
      required: true,
      validations: ["string", "required", "nullable"],
      params: {
        required: exports.thisFieldIsRequired,
      },
    },
    {
      key: "measurement_id",
      label: "Measurement ID",
      placeholder: "Measurement ID",
      type: "text",
      required: true,
      validations: ["string", "required", "nullable"],
      params: {
        required: exports.thisFieldIsRequired,
      },
    },
    {
      key: "is_google_login",
      label: "Google Login",
      placeholder: "",
      type: "checkbox",
      required: false,
    },
    {
      key: "is_facebook_login",
      label: "Facebook Login",
      placeholder: "",
      type: "checkbox",
      required: false,
    },
  ],
};

exports.integrations = [
  exports.firebase,
];

exports.typeFirebase = "firebase"
exports.fieldShowCard = "checkStateToShowCard";
// Customizable Area End