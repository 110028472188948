Object.defineProperty(exports, "__esModule", {
  value: true,
});
// Customizable Area Start
exports.displayCardVertical = true;

exports.otherIntegrationsTitle = "Other integrations";

exports.brandSettingsAPI = "/admin/v1/brand_settings";
exports.getApiMethodType = "GET";
exports.updateApiMethodType = "POST";
exports.createApiMethodType = "POST";
exports.deleteApiMethodType = "DELETE";
exports.putApiMethodType = "PUT";

exports.dialogMessage =
  "Please fill in the following fields to activate this integration.";
exports.dialogTitle = "Integration ";

exports.consoleErrorBase = "Async: Could not copy text: ";
exports.notActive = "Not active";

exports.active = "Active";

exports.activateButton = "Activate";
exports.editIntegrationButton = "Edit";
exports.dummyPassword = "*************";
exports.thisFieldIsRequired = "This field is required";
exports.updateIntegrationSuccess = "Integration updated successfully";
exports.firebaseActivationErrorTitle = "You can not activate firebase";
exports.firebaseActivationErrorMessage = "You need to select at least one of the login methods";
exports.firebaseActivationErrorConfirm = "Okay";
exports.activated = "Activated";
exports.notActivated = "Not Activated";
exports.howToUse = "How to use";

exports.errors = {
  errorPageInitialisation: "Error on page initialization",
  errorCardUpdate: "Error on card update",
  googleFirebase: "Google Firebase Login Integration data couldn't be fetched",
  integration: "Integration data couldn't be fetched",
  updateIntegration: "Integration data couldn't be saved",
  default: "Something went wrong",
  brandSettings: "Country data couldn't be fetched",
};

exports.integrations = [
  exports.tawk,
];

exports.tawk = {
  order: 1,
  apiEndpoint: "bx_block_live_chat/tawk_setting",
  integrationName: "tawk",
  cartTitle: "Live Chat Tawk.to",
  dialogTitle: "Live Chat Tawk.to",
  description: "Use to add Live Chat Tawk.to",
  dialogMessage: "Use to add Live Chat Tawk.to",
  howToUseLink:
    "https://intercom.help/engineerai/en/articles/6961910-setting-up-your-tawk-to-account",
  fields: [
    {
      key: "property_id",
      label: "Property Id",
      placeholder: "Tawk to Property Id",
      type: "text",
      showAtCard: true,
      isHiddenFieldAtCard: false,
      integrationActivityCheck: true,
      required: true,
      validations: ["string", "required", "nullable"],
      params: {
        required: exports.thisFieldIsRequired,
      },
    },
    {
      key: "widget_id",
      label: "Widget Id",
      placeholder: "Widget Id",
      type: "text",
      showAtCard: true,
      isHiddenFieldAtCard: true,
      required: true,
      validations: ["string", "required", "nullable"],
      params: {
        required: exports.thisFieldIsRequired,
      },
    },
  ],
};;
exports.typeTawk = "tawk"
exports.fieldShowCard = "checkStateToShowCard";
// Customizable Area End