Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "storecontentmanagement2";
exports.labelBodyText = "storecontentmanagement2 Body";
exports.delivery= "Delivery";
exports.deliveryArea= "Delivery Area";
exports.deliveryIntegrate= "Delivery Integration";
exports.maxDeliveryRadius= "Maximum Delivery Radius";
exports.deliveryDistanceText= "Add delivery distance radius from your store location";
exports.Km= "Km";
exports.toolTipTitle= "Set up distance between 0.1 km and 100 km";
exports.deliveryRates= "Delivery Rates";
exports.minimumOderText= "Minimum Order Amount for Delivery";
exports.distanceCalculatedText= "Select how is delivery calculated";
exports.checkBoxText= "Offer free delivery when a customer buys over a certain amount";
exports.toolTipOfferTitle= "Set up amount for offer free delivery";
exports.remove= "Remove";
exports.rate= "Rate";
exports.distance= "Distance";
exports.getDistanceAreas= "bx_block_hyper_local/delivery_areas";
exports.getAddressDetails = "bx_block_store_details/store_profile";
exports.updateDistanceAreas= "bx_block_hyper_local/delivery_areas";

exports.mapAPIKey= "AIzaSyAAdnoL9ZLaHUAZQ0z1QDjgQWXJCkFlCuE";

exports.httpPutType = "PUT";
exports.httpDeleteType = "DELETE";
exports.addYourStoreDetailsTitle = `Add your country in Store Detail Page to Setup
Delivery Integrations`;
exports.addYourStoreDetailsDescription = ` Add your Store Info and choose a country
 of operation to activate available Delivery Integrations`;
exports.addStoreDetailsButtonTitle='Add Store Details';
exports.shiprocketIntegrationText = ' Integration';
exports.textFeildsData = [
  {
    name:"apiKey",
    label:"Api key *",
  },
  {
    name:"secretApiKey",
    label:"Secret Api key *",
  }
];
exports.cancelButtonText = "Cancel";
exports.addButtonLabel = "Add";
exports.deleteButtonText = "Delete";
exports.emptyText = '';
exports.requiredText = "*required";
exports.spipRocketDescription = "India's most-used eCommerce logistics and delivery solution";
exports.spipRocketTitle = "Shiprocket";
exports.editButton = "Edit";
exports.saveButtonLabel = "Save";
exports.adminToken = 'admintoken';
exports.getShiprocketListEndpoint = 'bx_block_shiprocket/delivery_partners';
exports.getAPIMethodType = "GET";
exports.postAPIMethodType = "POST";
exports.deleteAPIMethodType = "DELETE";
exports.patchAPIMethodType = 'PATCH';
exports.getSelectedCountry = 'bx_block_store_details/store_profile';
exports.connectButton = "Connect";
exports.netWorkErrorMessage = "Opps! Network error";
exports.createEndPoint = "bx_block_shiprocket/delivery_partner_configs/create_manually"
exports.addOrUpdateEndPoint = "bx_block_shiprocket/delivery_partner_configs";
exports.deleteSuccessMessage = "Record is deleted successfully";
exports.deleteFailureMessage = "Failed to delete the record";
exports.updateSuccessMessage = "Record is stored successfully";
exports.updateFailureMessage = "Failed to store the record";
exports.putAPIMethodType = "PUT";

exports.btnExampleTitle = "CLICK ME";
exports.distanceErrorText = "Distance range cannot be more than maximum radius";
exports.invalidRangeError = "Range is already exists";
exports.fieldRequired = "Field is required";
exports.outOFRange = "Invalid Range";
exports.getThemeSettingEndPoint = "bx_block_websitebuilder/designs";
// Customizable Area End