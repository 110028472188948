// Customizable Area Start
import React from "react";
import "../assets/styles.css";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import { Dialog } from "../../ss-cms-common-components/src/Dialog/Dialog";
import { TextField, styled } from "@material-ui/core";
import withLayout from "../../../../packages/blocks/ss-cms-common-components/src/withLayout/withLayout";
import { PaymentGatewayType } from "./PaymentGatewayActivisionController.web";
import { isEqual } from "lodash";
const configJSON = require("./config");

const TextInput = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-input": {
        padding: "12px 17px",
        paddingTop: "7px",
        borderColor: "#9B9FB1",
        fontSize: "14px",
        lineHeight: "24px",
        fontWeight: 400,
        color: "#000000"
    },
}));

type Props = {
    isShowModal: boolean
    setShowModal: (v: boolean) => void
    handleSubmit: ({ secretKey, otherKey, webhookKey }: { secretKey: string; otherKey: string; webhookKey: string }) => void
    title: string
    titleSecondField: string
    currentGateway?: PaymentGatewayType
};

type StateProps = {
    secretKey: string
    otherKey: string
    webhookKey: string
    keyError: KeyErrorsTypes
};

interface KeyErrorsTypes {
    secretKey: string;
    otherKey: string;
    webhookKey: string
}

export class ActivateAccountDialog extends React.Component<Props, StateProps> {
    constructor(props: Props) {
        super(props);
        this.state = {
            secretKey: "",
            otherKey: "",
            webhookKey: "",
            keyError: {
                secretKey: "",
                otherKey: "",
                webhookKey: ""
            },
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if(!isEqual(prevProps.currentGateway, this.props.currentGateway)) {
            this.initState(this.props.currentGateway)
        }
    }

    initState(gateway?: PaymentGatewayType) {
        if(gateway) {
            if(gateway.attributes.name === "Stripe") {
                this.setState({
                    secretKey: gateway.attributes.config.secret_key || "",
                    otherKey: gateway.attributes.config.publishable_key || "",
                    webhookKey: gateway.attributes.config.webhook_secret || "",
                    keyError: {
                        secretKey: "",
                        otherKey: "",
                        webhookKey: ""
                    },

                })
            } else if(gateway.attributes.name === "Razorpay") {
                this.setState({
                    secretKey: gateway.attributes.config.key_id || "",
                    otherKey: gateway.attributes.config.key_secret || "",
                    keyError: {
                        secretKey: "",
                        otherKey: "",
                        webhookKey: ""
                    },
                })
            } else {
                this.setState({
                    secretKey: gateway.attributes.config.secret_key || "",
                    otherKey: gateway.attributes.config.publishable_key || "",
                    keyError: {
                        secretKey: "",
                        otherKey: "",
                        webhookKey: ""
                    },
                })
            } 
        }
    }

    handleChangeKey = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName = event.target.name
        const fieldValue = event.target.value
        const keyError = this.state.keyError

        if (fieldName === "secretKey") {
            this.setState({ secretKey: fieldValue })
            keyError.secretKey = ""
            if (!fieldValue.length) {
                keyError.secretKey = configJSON.keyIsRequired
            }
            this.setState({ keyError })
        }
        if (fieldName === "otherKey") {
            this.setState({ otherKey: fieldValue })
            keyError.otherKey = ""
            if (!fieldValue.length) {
                keyError.otherKey = configJSON.keyIsRequired
            }
            this.setState({ keyError })
        }
        if (fieldName === "webhookKey") {
            this.setState({ webhookKey: fieldValue })
            keyError.webhookKey = ""
            if (!fieldValue.length) {
                keyError.webhookKey = configJSON.keyIsRequired
            }
            this.setState({ keyError })
        }
    }

    checkDisable = (secretKeyError: string, otherKeyError: string, webhookKey: string) => {
        if (this.props.title === "Stripe") {
            return !(secretKeyError === "" && otherKeyError === "" && webhookKey === "")
        } else {
            return !(secretKeyError === "" && otherKeyError === "")
        }
    }

    render() {
        const { currentGateway, isShowModal, setShowModal, handleSubmit, title, titleSecondField } = this.props
        return (
            <Dialog
                open={isShowModal}
                setOpen={setShowModal}
                onSubmit={() => handleSubmit({ secretKey: this.state.secretKey, otherKey: this.state.otherKey, webhookKey: this.state.webhookKey })}
                title={`${title} Integration`}
                onClose={() => this.initState(currentGateway)}
                customContent
                okay="Add"
                ignoreClickAway
                titleClassName="header-payment-dialog"
                confirmDisabled={this.checkDisable(this.state.keyError.secretKey, this.state.keyError.otherKey, this.state.keyError.webhookKey)}
            >
                <div className="change-password-dialog" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div className="d-flex flex-column" style={styles.viewInput}>
                        <label style={styles.title}>
                            {configJSON.secretKey} *
                        </label>
                        <TextInput
                            autoComplete="off"
                            name="secretKey"
                            value={this.state.secretKey}
                            type={"text"}
                            error={this.state.keyError.secretKey !== ""}
                            helperText={this.state.keyError.secretKey}
                            onChange={this.handleChangeKey}
                            variant="outlined"
                            data-test-id="secretKey"
                        />
                    </div>

                    <div className="d-flex flex-column" style={styles.viewInput}>
                        <label style={styles.title}>
                            {titleSecondField} *
                        </label>
                        <TextInput
                            data-test-id="otherKey"
                            autoComplete="off"
                            name="otherKey"
                            value={this.state.otherKey}
                            type={"text"}
                            error={this.state.keyError.otherKey !== ""}
                            helperText={this.state.keyError.otherKey}
                            variant="outlined"
                            onChange={this.handleChangeKey}
                        />
                    </div>
                    {
                        title === "Stripe" && (
                            <div className="d-flex flex-column" style={styles.viewInput}>
                                <label style={styles.title}>
                                    Webhook Secret *
                                </label>
                                <TextInput
                                    autoComplete="off"
                                    name="webhookKey"
                                    data-test-id="webhookKey"
                                    value={this.state.webhookKey}
                                    type={"text"}
                                    error={this.state.keyError.webhookKey !== ""}
                                    helperText={this.state.keyError.webhookKey}
                                    variant="outlined"
                                    onChange={this.handleChangeKey}
                                />
                            </div>
                        )
                    }
                    <div style={{ height: "16px"}} />
                </div>
            </Dialog>
        );
    }
}
const styles = {
    title: {
        fontSize: "12px",
        lineHeight: "20px",
        fontFamily: "Rubik",
        fontWeight: 500,
        color: "#000",
        marginBottom: "4px"
    },
    viewInput: {
        width: "calc(100% - 80px)",
        marginBottom: "24px",
    }
};

export default withLayout((withDialog(ActivateAccountDialog)));

// Customizable Area End