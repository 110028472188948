import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { uuid } from "uuidv4"
import { AppMixpanel } from "../../../components/src/Mixpanel";

// Customizable Area End
export const configJSON = require("./config");

export interface Props extends WithStyles {
    navigation: any;
    id: string;
    // Customizable Area Start
    width: string
    // Customizable Area End
}

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { WithStyles } from "@material-ui/core";
import { withHeadeActionBarProps } from "../../../../packages/blocks/ss-cms-common-components/src/HOC/withHeadeActionBar.Web";

export interface Variants {
    "id": string | number;
    "title": string;
    "price": string;
    "variant_type": string;
    "options": Array<Options>
}

export interface Options {
    "data": {
        "id": string | number;
        "type": string;
        "attributes": {
            "id": number;
            "option_name": string;
            "price": number;
            "status": boolean;
            "selected"?: boolean
        }
    }
}
export interface CartItem {
    idGroup?: number;
    "id": number;
    "type": string;
    "count": number;
    "total"?: number;
    "variants": Array<{ name: string; option: Array<string> }>
    "attributes": {
        "id": number;
        "sub_category": string | null;
        "brand": number | null;
        "tags": Array<string>;
        "reviews": Array<string>;
        "name": string;
        "sku": string | null;
        "description": string;
        "manufacture_date": string | null;
        "length": string | null;
        "breadth": string | null;
        "height": string | null;
        "stock_qty": number | null;
        "availability": string;
        "weight": string | null;
        "price": number;
        "recommended": string | null;
        "on_sale": string | null;
        "sale_price": string | null;
        "discount": string;
        "after_discount": number;
        "status": true,
        "food_lables": Array<string>;
        "prefix_id"?: string
        "images": {
            "id": number;
            "url": string
        };
        "variants": Array<Variants>;
        "categories": Array<Array<Array<string | number>>>;
    }
}

export interface ItemsCount {
    id: string | number;
    count: number,
    price: number,
    discountPrice: number,
    variants: Array<Variants>,
    selectedVariants: Array<SelectedVariants>
}
export interface SelectedVariants {
    name: string,
    option: Array<string>
}
export interface RespJson {
    data?: CartItem | Array<CartItem>,
    message?: string,
    valid?: boolean,
    currency_symbol?: string,
    country?: CountryInfo,
    payments?: Array<Payment>,
    discount?: string,
    errors?: Array<string>
}

export interface ResponseJson {
    data?: Array<CartItem>,
    message?: string,
    errors?: Array<string>
}

export interface TaxesApplied {
    id: string | number,
    tax: number | string,
    tax_title: string
}
export interface TexesRespJson {
    meta: Array<TaxesApplied>
}

export interface MetaInfoType {
    address_line_1: string,
    address_line_2: string,
    city_name: string,
    state_name: string,
    country_name: string,
    zipcode: string,
    country_flag: string
    phone_code: string
    country_code: string,
    name: string,
    currency_name: string,
}

export interface CountryInfo {
    id?: number,
    name?: string,
    code?: string,
    phone_code?: string,
    created_at?: string,
    updated_at?: string,
}
export interface Payment {
    id: number,
    cod: boolean,
    online_payment: boolean,
    message: string,
    created_at: string,
    updated_at: string,
    online_store_message: string,
    online_ordering: boolean
}
interface VariantsReq {
    id: string | number,
    catalogue_option_ids: Array<number | string>
}

export interface PlaceOrderResp {
    data: Data;
    meta?: PlaceOrderMetaInfo
}
export interface RazorpayApi {
    id: string;
    type: string;
    attributes: {
        key: string;
        amount: number;
        status: string;
        razorpay_order_id: string;
        receipt: string | null;
        account_id: number;
        order_id: number;
        entity: string;
        amount_paid: string;
        amount_due: string;
        currency: string;
        notes: {
            order_id: string;
        };
        business_name: string;
        order_description: string;
    };
}
export interface PlaceOrderMetaInfo {
    stripe?: {
        session_url: string
        id: string,
        amount: number,
        client_secret: string,
        currency: string,
        customer: string,
        payable_reference: number,
        ephemeral_key: string,
        stripe_publishable_key: string
    },
    razorpay: RazorpayApi
    tap_payments?: {
        id: string
        transaction: {
            url: string
        }
    },
    taxes: Array<TaxesApplied>,
    delivery_area: any
}
export interface Data {
    id: string;
    type: string;
    attributes: PurpleAttributes;
}

export interface PurpleAttributes {
    id: number;
    order_number: string;
    amount: null;
    account_id: number;
    coupon_code_id: null;
    delivery_address_id: null;
    sub_total: string;
    total: string;
    status: string;
    custom_label: null;
    applied_discount: string;
    cancellation_reason: null;
    order_date: Date;
    is_gift: boolean;
    placed_at: null;
    confirmed_at: null;
    in_transit_at: null;
    delivered_at: null;
    cancelled_at: null;
    refunded_at: null;
    source: null;
    shipment_id: null;
    delivery_charges: null;
    tracking_url: null;
    schedule_time: null;
    payment_failed_at: null;
    payment_pending_at: null;
    returned_at: null;
    tax_charges: string;
    deliver_by: null;
    tracking_number: null;
    is_error: boolean;
    delivery_error_message: null;
    order_status_id: number;
    is_group: boolean;
    is_availability_checked: boolean;
    shipping_charge: string;
    shipping_discount: string;
    shipping_net_amt: string;
    shipping_total: string;
    total_tax: number;
    created_at: Date;
    updated_at: Date;
    delivery_addresses: string[];
    razorpay_order_id: null;
    charged: null;
    invoice_id: null;
    invoiced: null;
    payment_type: string;
    prefix_id: string;
    order_items: OrderItem[];
    account: Account;
    address_line_1: string,
    address_line_2: string,
    city_name: string,
    state_name: string,
    country_name: string,
    zipcode: string,
    country_flag: string
    phone_code: string
    country_code: string,
    currency_name: string,
    name: string,
    data: {
        attributes: {}
    }
}

export interface Account {
    id: string;
    type: string;
    attributes: AccountAttributes;
}

export interface AccountAttributes {
    activated: boolean;
    country_code: number;
    email: string;
    first_name: null;
    full_phone_number: null;
    last_name: null;
    phone_number: string;
    type: null;
    created_at: Date;
    updated_at: Date;
    device_id: null;
    unique_auth_id: string;
    full_name: string;
}

export interface OrderItem {
    id: string;
    type: string;
    attributes: OrderItemAttributes;
}

export interface OrderItemAttributes {
    id: number;
    order_management_order_id: number;
    quantity: number;
    unit_price: string;
    total_price: string;
    old_unit_price: null;
    status: string;
    total_price_without_discount: string;
    catalogue_id: number;
    catalogue_variant_id: null;
    order_status_id: number;
    placed_at: null;
    confirmed_at: null;
    in_transit_at: null;
    delivered_at: null;
    cancelled_at: null;
    refunded_at: null;
    manage_placed_status: boolean;
    manage_cancelled_status: boolean;
    created_at: Date;
    updated_at: Date;
    order_statuses: OrderStatuses;
    catalogue: Catalogue;
    catalogue_variants: CatalogueVariantElement[];
}

export interface Catalogue {
    id: string;
    type: string;
    attributes: CatalogueAttributes;
}

export interface CatalogueAttributes {
    id: number;
    name: string;
    price: number;
    discount: string;
    after_discount: number;
    availability: string;
    status: boolean;
    image: Image;
    variants: Variant[];
    category_name: string;
}

export interface Image {
    id: number;
    url: string;
}

export interface Variant {
    id: number;
    title: string;
    variant_type: string;
    options: Option[];
}

export interface Option {
    data: CatalogueOption;
}

export interface CatalogueOption {
    id: string;
    type: Type;
    attributes: CatalogueOptionAttributes;
}

export interface CatalogueOptionAttributes {
    id: number;
    option_name: OptionName;
    price: number;
    status: boolean;
}

export enum OptionName {
    Option1 = "Option 1",
    Option2 = "Option 2",
    Option3 = "Option 3",
}

export enum Type {
    CatalogueOption = "catalogue_option",
}

export interface CatalogueVariantElement {
    id: string;
    type: string;
    attributes: FluffyAttributes;
}

export interface DeliveryAddressType {
    address: string
    flat_no: string
    address_line_2: string
    landmark?: string
    area?: string
}

export interface FluffyAttributes {
    catalogue_variant: PurpleCatalogueVariant;
    catalogue_options: CatalogueOption[];
}

export interface PurpleCatalogueVariant {
    id: string;
    type: string;
    attributes: TentacledAttributes;
}

export interface TentacledAttributes {
    title: string;
}

export interface OrderStatuses {
    order_number: string;
    placed_at: null;
    confirmed_at: null;
    in_transit_at: null;
    delivered_at: null;
    cancelled_at: null;
    refunded_at: null;
}
export interface ApiRequestPayload {
    contentType: string,
    method: string,
    endPoint: string,
    body?: Object
}

export interface Reqbody {
    customer: Customer,
    order: Order

}

export interface ReqbodyVerifySignature {
    razorpay_order_id: string,
    razorpay_payment_id: string
    razorpay_signature: string
}

export interface Customer {
    full_name: string,
    phone_number: string,
    email: string,
    receive_updates: boolean
}
export interface Order {
    payment_type: string,
    promo_code?: string,
    order_items?: Array<OrderItems>
    order_type: string
    delivery_address?: DeliveryAddressType
}

export interface OrderItems {
    catalogue_id: string | number,
    quantity: number,
    catalogue_variants?: Array<VariantsReq>
}

interface ICustomerData {
    data: {
        id: string;
        type: string;
        attributes: {
            activated: boolean;
            country_code: null | number;
            email: string;
            first_name: string;
            full_phone_number: string;
            last_name: string;
            phone_number: null | number;
            type: string;
            created_at: string;
            updated_at: string;
            device_id: null;
            unique_auth_id: string;
        };
    };
}

export interface IRazorResp {
    razorpay_payment_id: string;
    razorpay_order_id: string;
    razorpay_signature: string;
}
export interface IRazorProps {
    razorpay: RazorpayApi,
    account: Account
    total: string,
    handleSuccessResponse: (rzp_response: IRazorResp) => void;
    onClose: () => void
}

interface RazerpayOptionState {
    razorpay: RazorpayApi,
    account: Account
    total: string,
}

interface DataCanvasState {
    data: {
        attributes: {}
    }
}


// Customizable Area End

interface S {
    // Customizable Area Start
    token: string;
    searchData: Array<CartItem>;
    currency: string;
    countryCode: string;
    isSearch: boolean;
    search: string;
    openDrawer: boolean;
    selectedProduct: CartItem | undefined;
    isDetail: boolean;
    mobileOpen: boolean;
    categorieItems: Array<CartItem>
    cartItemsCount: Array<ItemsCount>;
    subtotal: number;
    isPromoApplied: boolean;
    promocode: string;
    promocodeApplied: string;
    isPromoCodeValid: boolean;
    orderType: string;
    isOrderTypeSelected: boolean;
    isAddressEmpty: boolean
    promoCodeDiscount: number;
    merchantNote: string;
    variantOptionIndex: number;
    radioValue: string;
    showPromocodeMessage: boolean;
    optionIds: Array<number>;
    checkDiscount: boolean;
    tempStore: number;
    isitemUpdated: boolean;
    paymentOption: string
    paymentStatus: boolean
    varianstName: string[]
    itemIndex: number;
    taxesApplied: Array<TaxesApplied> | undefined;
    totalTax: number;
    distance_rate: number;
    oldoptions: CartItem | undefined;
    isDialogOpen: boolean
    deliveryAddress: DeliveryAddressType,
    razerpayOPtion: RazerpayOptionState | null
    ScreenLoading: boolean
    metaInfo: MetaInfoType | null
    isStoreClosed: boolean;
    storeClosedMessage: string;
    customerEmail: string;
    customerContact: string;
    customerName: string;
    promotionEnabled: boolean
    isValidcontactNo: boolean
    isValidEmailId: boolean
    isNameValid: boolean
    isMobileValid: boolean
    isEmailValid: boolean
    store_profile: any
    msg_popup: boolean
    msg_text: string
    msg_status: string
    distance_data: any
    canvasData: DataCanvasState | null,
    storename: string,
    alertOrderMessage: string;
    openOrderAlert: boolean;
    alertOrderSuccess: boolean;
    orderResp: PlaceOrderResp | null
    isUptodateVariants:boolean
    // Customizable Area End
}


interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CartOrdersController extends BlockComponent<
    Props & withHeadeActionBarProps,
    S,
    SS
> {
    // Customizable Area Start
    validatePromocodeApiCallId?: string = '';
    getCurrencyApiCallId?: string = '';
    currencyType: string = '';
    placeOrderApiCallId: string = '';
    getPaymentTypesApiCallId: string = '';
    getTaxesApiCallId: string = '';
    razerpayVerifyApiCallId: string = '';
    getTapPaymentStatusId: string = "";
    mixPanelCallId: string = "";
    getDistanceCallId: string = "";
    getCanvasDataCallId: string = "";
    getCategoryItemsApiCallId: string = "";

    // Customizable Area End
    constructor(props: Props & withHeadeActionBarProps) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            currency: "",
            countryCode: "",
            searchData: [],
            isSearch: false,
            search: '',
            openDrawer: false,
            selectedProduct: undefined,
            isDetail: false,
            mobileOpen: false,
            categorieItems: [],
            cartItemsCount: [],
            subtotal: 0,
            isPromoApplied: false,
            promocode: '',
            promocodeApplied: '',
            isPromoCodeValid: false,
            orderType: 'Delivery',
            isOrderTypeSelected: false,
            isAddressEmpty: false,
            promoCodeDiscount: 0,
            merchantNote: '',
            variantOptionIndex: 0,
            radioValue: '',
            showPromocodeMessage: false,
            optionIds: [],
            checkDiscount: false,
            tempStore: 0,
            isitemUpdated: false,
            paymentOption: configJSON.cod,
            paymentStatus: false,
            varianstName: [],
            itemIndex: 0,
            totalTax: 0,
            distance_rate: 0,
            taxesApplied: [],
            oldoptions: undefined,
            isDialogOpen: false,
            deliveryAddress: {
                address: "",
                flat_no: "",
                address_line_2: "",
            },
            razerpayOPtion: null,
            ScreenLoading: false,
            metaInfo: null,
            isStoreClosed: true,
            storeClosedMessage: '',
            customerEmail: '',
            customerContact: '',
            customerName: '',
            promotionEnabled: false,
            isValidcontactNo: false,
            isValidEmailId: false,
            isNameValid: false,
            isMobileValid: false,
            isEmailValid: false,
            store_profile: '',
            msg_popup: false,
            msg_text: "",
            msg_status: "success",
            distance_data: '',
            canvasData: null,
            storename: "",
            alertOrderMessage: '',
            openOrderAlert: false,
            alertOrderSuccess: false,
            orderResp: null,
            isUptodateVariants:false
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();

        // Customizable Area Start 
        window.scrollTo(0, 0);
        this.getCurrency()
        this.getTaxes();
        this.getCartItems()
        this.getPaymentTypes()
        this.updatePaymentStatus();
        this.checkCurrentLink();
        this.getCanvasData();
        // Customizable Area End
    }

    checkCurrentLink() {
        const currentLink = new URLSearchParams(window.location.search)
        const id = currentLink.get("tap_id")
        if (id) { this.getTapPaymentStatus(id) }
    }

    async getTapPaymentStatus(id: string) {
        this.getTapPaymentStatusId = await this.apiCalls({
            contentType: configJSON.apiContentType,
            method: configJSON.getApiMethod,
            endPoint: `${configJSON.getTapPaymentStatus}?charge_id=${id}`,
        })
    }

    receive = async (from: string, message: Message) => {
        // Customizable Area Start
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id
        ) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson && !responseJson.errors && responseJson.data) {
                this.handleSucessResponse(apiRequestCallId, responseJson)
                this.handleCategorySucessResponse(apiRequestCallId, responseJson)
            }
            else if (responseJson && responseJson.error) {
                this.handleErrorResponse(apiRequestCallId, responseJson)
            }
            else if (responseJson && responseJson.message) {
                this.handleErrorResponseMsg(apiRequestCallId, responseJson)
            }
            else if (responseJson && !responseJson.errors) {
                this.handleTapPaymentStatus(responseJson.status);
                this.handleRespSuccess(apiRequestCallId, responseJson)
            } else if (responseJson) {
                this.managecheckDeliveryStatus(apiRequestCallId, responseJson)
            }
            else {
                this.parseApiErrorResponse(responseJson);
            }
            if (errorReponse) {
                this.parseApiCatchErrorResponse(errorReponse);
            }
            this.getAPIResponse(apiRequestCallId, responseJson)
            
        }
        // Customizable Area End
    };

    // Customizable Area Start

    managecheckDeliveryStatus = (apiRequestCallId: any, responseJson: any) => {
        if (responseJson && responseJson.errors) {
            this.handleRespSuccess(apiRequestCallId, responseJson)
        }

    }

    getAPIResponse = (apiRequestCallId:any, responseJson:any)=>{
        if(apiRequestCallId == this.getDistanceCallId){
            if(responseJson.rows[0].elements[0].distance){
            this.setState({distance_data:responseJson.rows[0].elements[0].distance.text},()=>{
                this.checkInDeliveryRadius()
            })
        }else{
            this.setState({distance_data:'1000000000000000',msg_popup:true,msg_status:'error',msg_text:`Oops, we only deliver within ${this.state.store_profile.data[0].attributes.max_radius} kms from the Store. Please try a different location or choose to collect.`})
        }
        }
    }
    getCanvasData = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCanvasDataCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.designApiEndPoint
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    apiCalls = async (data: ApiRequestPayload) => {
        const { contentType, method, endPoint, body } = data;
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        let header = {
            "Content-Type": contentType,
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };


    getColor(isMobile: boolean) {
        if (isMobile) return "#fff"
        else return "#F1F5F9"
    }
    validatePromoCodeApi = async (text: string) => {
        let body = {
            "promo_code": text,
            "cart_total": this.state.subtotal
        }
        this.validatePromocodeApiCallId = await this.apiCalls({
            contentType: configJSON.apiContentType,
            method: configJSON.postApiMethod,
            body: body,
            endPoint: `${configJSON.promocodeValidate}`,
        })
    }
    getCurrency = async () => {
        this.getCurrencyApiCallId = await this.apiCalls({
            contentType: configJSON.apiContentType,
            method: configJSON.getApiMethod,
            endPoint: `${configJSON.getCurrency}`,
        })
    }

    backToMenu = () => {
        const to = new Message(getName(MessageEnum.NavigationMessage));
        to.addData(
            getName(MessageEnum.NavigationTargetMessage),
            "LandingPageScreen"
        );
        to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        runEngine.sendMessage(to.messageId, to);
    }
    getCartItems = async () => {
        let data = JSON.parse(await getStorageData('count'));
        if(data){
            let cartItemIds=data.map((cartItem:CartItem)=>cartItem.id)
            this.cartItemsInfo(cartItemIds.join(','))
        } 
    }

    setCartPrices = async (data: Array<CartItem>) => {
        let cartItems: Array<ItemsCount> = []
        for (let item of data) {
            let price = 0;
            if (item.variants.length > 0) {
                price = this.getPrice(item);
                item['total'] = item.attributes.after_discount + price
            }
            else {
                item['total'] = (item.attributes.after_discount || item.attributes.price)
            }

            cartItems.push(
                {
                    id: item.id,
                    count: item.count ? item.count : 1,
                    price: item.attributes.price,
                    discountPrice: item.attributes.after_discount ? item.attributes.after_discount + price : item.attributes.price,
                    variants: item.attributes.variants,
                    selectedVariants: item.variants
                }
            )
        }
        this.setState({ categorieItems: data, cartItemsCount: cartItems }, () => {
            this.updatePrice(cartItems);
        })
    }
    getPrice = (item: CartItem) => {
        let price = 0
        for (let vari of item.attributes.variants) {
            let selectedVar = item.variants.find(variant =>  variant.name == vari.title);
            for (let option of vari.options) {
                if (selectedVar && selectedVar.option.findIndex((optionItem: string) => optionItem == option.data.attributes.option_name) != -1) {
                    price += option.data.attributes.price
                }
            }

        }
        return price;
    }

    getCount = (id: number, index: number) => {
        if (this.state.cartItemsCount[index].count) {
            return this.state.cartItemsCount[index].count
        }
        else {
            return 1
        }
    }
    increaseItem = (id: number, index: number) => {
        let data: Array<ItemsCount> = [...this.state.cartItemsCount]
        data[index].count = data[index].count + 1
        let items: Array<CartItem> = [...this.state.categorieItems]
        if (items[index] && items[index].count) {
            items[index].count = items[index].count + 1
        }
        this.updatePrice(data);
        this.setState({ cartItemsCount: data, categorieItems: items });
        setStorageData('count', JSON.stringify(items))
        this.clearPromoCode();
    }

    decreaseItem = (id: number, index: number) => {
        let data: Array<ItemsCount> = [...this.state.cartItemsCount]
        data[index].count = data[index].count - 1
        this.setState({ cartItemsCount: data })
        if (data[index].count == 0) {
            let finalItems = this.state.categorieItems.filter((ele: CartItem, i: number) => i != index)
            let itemcounts: Array<ItemsCount> = data.filter((el, indx) => indx != index)
            this.setState({ categorieItems: finalItems, cartItemsCount: itemcounts })
            setStorageData('count', JSON.stringify(finalItems))
        }
        else {
            let items: Array<CartItem> = [...this.state.categorieItems]
            if (items[index] && items[index].count) {
                items[index].count = items[index].count - 1

            }
            this.setState({ categorieItems: items })
            setStorageData('count', JSON.stringify(items))
        }
        this.updatePrice(data)
        this.clearPromoCode();
    }

    updatePrice = (items: Array<ItemsCount>) => {
        let total = 0
        items.forEach((ele: ItemsCount) => {
            total += ele.discountPrice * ele.count
        })
        this.setState({ subtotal: total }, () => {
            this.getTaxes();
        })
    }
    setCloseDetail = () => {
        let data: Array<CartItem> = [...this.state.categorieItems];
        let itemIndex = data.findIndex((ele: CartItem) => ele.id == this.state.selectedProduct?.id)
        if (this.state.oldoptions) {
            data[itemIndex] = this.state.oldoptions
        }
        this.setState({ isDetail: false, categorieItems: data, selectedProduct: undefined })
        this.setCartPrices(data)
    }
    openCartItemDetails = (cartItem: CartItem, index: number) => {
        this.mixPanelEventHit('Edit Item in Cart')
        let data: CartItem = JSON.parse(JSON.stringify(cartItem))
        this.setState({ isDetail: true, selectedProduct: cartItem, tempStore: cartItem.total ? cartItem.total : 0, itemIndex: index, isitemUpdated: false, oldoptions: data })
    }
    round = (value:number, decimals:number) =>{
        return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals);
    }

    handleSucessResponse = async (apiRequestCallId: string, responseJson: PlaceOrderResp) => {
        if (apiRequestCallId === this.placeOrderApiCallId) {
            this.orderSuccess(responseJson);
        }
        if (apiRequestCallId === this.getTaxesApiCallId) {
            let totalTax = 0
            if (responseJson?.meta) {
                this.setState({ taxesApplied: responseJson.meta.taxes, store_profile: responseJson.meta.delivery_area }, () => {
                    this.state.taxesApplied?.forEach((tax: TaxesApplied) => {
                        totalTax += Number(this.round((( (this.state.subtotal - this.state.promoCodeDiscount) * Number(tax.tax)) / 100), 2))
                    })
                    this.setState({ totalTax: Number((totalTax).toFixed(2)) })
                })
            }
            this.setState({ metaInfo: responseJson?.data?.attributes, storename: responseJson?.data?.attributes?.name })
        }
        if (apiRequestCallId === this.razerpayVerifyApiCallId) {
            this.setState({ ScreenLoading: false }, () => {
                this.handleNavigationToOrderConfirm()
            })

        }
        if (apiRequestCallId === this.getCanvasDataCallId) {
            this.setState({ canvasData: responseJson.data.attributes })
        }
    }

    handleTapPaymentStatus(status: string) {
        if (status === "CAPTURED") {
            this.handleNavigationToOrderConfirm()
        }
    }

    handleRespSuccess = (apiRequestCallId: string, responseJson: RespJson) => {
        if (apiRequestCallId === this.getCurrencyApiCallId) {
            this.currencySuccess(responseJson);
        }
        if (apiRequestCallId === this.getPaymentTypesApiCallId) {
            this.paymentTypesSuccess(responseJson);
        }
        if (apiRequestCallId === this.placeOrderApiCallId && responseJson.errors) {
            if(responseJson.errors[0] == "Store is currently closed"){
                this.setState({ alertOrderMessage: "Store is currently closed", openOrderAlert: true })
            }
            else if(responseJson.errors[0].includes('Invalid option selected for')){
                this.setState({ alertOrderMessage: responseJson.errors[0], openOrderAlert: true, isUptodateVariants: true })
            } else if(responseJson.errors[0].includes('Enter a Valid Promo Code')){
                this.setState({ alertOrderMessage: responseJson.errors[0], openOrderAlert: true, isUptodateVariants: true })
            } else {
                this.parseApiErrorResponse(responseJson);
            }
        }
    }

    handleErrorResponse = async (apiRequestCallId: string, responseJson: RespJson) => {
        this.parseApiErrorResponse(responseJson);
    }
    handleErrorResponseMsg = (apiRequestCallId: string, responseJson: RespJson) => {
        if (apiRequestCallId === this.validatePromocodeApiCallId) {
            this.promoCodeSuccess(responseJson);
        }
    }
    validatePromo = () => {
        this.mixPanelEventHit('Promo Code Applied')
        if (this.state.promocode && this.state.promocode.length > 0) {
            this.setState({ isPromoApplied: true });
            this.validatePromoCodeApi(this.state.promocode)
        }
    }
    clearPromoCode = () => {
        this.mixPanelEventHit('Promo Code Removed')
        this.setState({ isPromoApplied: false, promoCodeDiscount: 0, promocodeApplied: '', promocode: '', isPromoCodeValid: false, showPromocodeMessage: false });
    }
    handlePromoCode = (value: string) => {
        this.setState({ promocode: value.trim() });
        if (value.trim().length == 0) {
            this.setState({ isPromoCodeValid: false, promoCodeDiscount: 0, showPromocodeMessage: false, isPromoApplied: false })
        }

    }
    handleOrderAlertMessageClose = (reason: string) => {
        if (reason === 'clickaway') {
            return;
        }
        if(this.state.isUptodateVariants){
            this.getCartItems()
        }
        this.setState({ openOrderAlert: false });
    };

    currencySuccess = async (responseJson: RespJson) => {
        setStorageData('currency_type', responseJson.currency_symbol)
        let currency = responseJson.currency_symbol ? responseJson.currency_symbol : "$"
        this.setState({ currency: currency, countryCode: responseJson.country?.code || "in" });
    }
    promoCodeSuccess = (responseJson: RespJson) => {
        if (responseJson.valid) {
            this.setState({ isPromoCodeValid: true, promocodeApplied: this.state.promocode, promoCodeDiscount: Number(responseJson.discount), showPromocodeMessage: true })
            this.getTaxes();
        }
        else {
            this.setState({ isPromoCodeValid: false, promocodeApplied: '', promoCodeDiscount: 0, showPromocodeMessage: true })
        }
    }
 
    closeModal=()=>{
        this.setState({msg_popup:false})
    }
    convertToKm = (miles: string) => {
        let km = parseFloat(miles) * 1.60934;
        return km.toFixed(2);
    };

    getDistance = async () => {
        this.getDistanceCallId = await this.apiCalls({
            contentType: configJSON.apiContentType,
            method: configJSON.getApiMethod,
            endPoint: `bx_block_location_management/address_states/distance_matrix?origins=${this.state.metaInfo?.address_line_1}&destinations=${this.state.deliveryAddress.address}`,
        })
    }

    checkInDeliveryRadius = ()=>{
        if(parseFloat(this.state.distance_data) > Number(this.state.store_profile.data[0].attributes.max_radius)){
            this.setState({msg_popup:true,msg_status:'error',msg_text:`Oops, we only deliver within ${this.state.store_profile.data[0].attributes.max_radius} kms from the Store. Please try a different location or choose to collect.`})
        }
        else {
            this.setState({ isDialogOpen: false })
            if (this.state.orderType == "Delivery") {
                this.freeDeliveryCheck()
            }
            this.updateDistanceDeliveryCharge()
        }
    }
    updateDistanceDeliveryCharge = () => {
        this.state.store_profile.data[0].attributes.distance_rates.map((value: any) => {
            if (parseFloat(this.state.distance_data) > parseFloat(value.min_distance) && parseFloat(this.state.distance_data) < parseFloat(value.max_distance)) {
                if (value.rate != null) {
                    this.setState({ distance_rate: parseFloat(value.rate) })
                }
            }
        })
    }
    placeOrder = async () => {
        // this.checkInDeliveryRadius()
        this.mixPanelEventHit('Place Order Click')
        if (!this.state.orderType) {
            this.setState({ isOrderTypeSelected: true });
            return false;
        }
        if (this.state.orderType === configJSON.delivery && (this.state.deliveryAddress.address === "" || this.state.deliveryAddress.flat_no === "" || this.state.deliveryAddress.address_line_2 === "")) {
            this.setState({ isAddressEmpty: true });
            return false;
        }
        let currentPrice = Number(this.state.distance_rate + this.state.subtotal + this.state.totalTax - this.state.promoCodeDiscount).toFixed(2)
        if(this.state.orderType != 'Collection'){

            console.log("ello",currentPrice,"new",this.state.store_profile.data[0].attributes.amount)
            if (parseFloat(currentPrice) < Number(this.state.store_profile.data[0].attributes.amount)) {
                this.setState({ msg_popup: true, msg_status: 'error', msg_text: `Oops. We only deliver for orders over ${this.state.metaInfo?.currency_name} ${this.state.store_profile.data[0].attributes.amount}.` })
                return false;

            }
            // alert(this.state.distance_data)
            // alert(this.state.store_profile.data[0].attributes.max_radius)
            if (this.state.distance_data != '' && parseFloat(this.state.distance_data) > Number(this.state.store_profile.data[0].attributes.max_radius)) {
               
                this.setState({ msg_popup: true, msg_status: 'error', msg_text: `Oops, we only deliver within ${this.state.store_profile.data[0].attributes.max_radius} kms from the Store. Please try a different location or choose to collect.` })
                return false;
            }
    }

        let value = await this.handlecustomerInfoValidations();
        if (!value) {
            return false
        }
        if (this.state.cartItemsCount && this.state.cartItemsCount.length > 0) {
            this.setState({ isOrderTypeSelected: false, isAddressEmpty: false });
            let orderItems = this.orderDetailsSetup(this.state.cartItemsCount);
            let body: Reqbody = {
                "customer": {
                    "full_name": this.state.customerName.trim(),
                    "phone_number": this.state.customerContact,
                    "email": this.state.customerEmail,
                    "receive_updates": this.state.promotionEnabled
                },
                "order": {
                    "payment_type": this.state.paymentOption,
                    "order_items": orderItems,
                    "order_type": this.state.orderType.toLowerCase()
                }
            }
            if (this.state.promocode && this.state.isPromoApplied) {
                body.order.promo_code = this.state.promocode
            }
            if (this.state.orderType === configJSON.delivery) {
                body.order.delivery_address = this.state.deliveryAddress
            }
            this.placeOrderApiCallId = await this.apiCalls({
                contentType: configJSON.apiContentType,
                method: configJSON.postApiMethod,
                body: body,
                endPoint: `${configJSON.placeOrder}`
            })
        }
    }
    handlecustomerInfoValidations = () => {
        if (this.state.customerContact == '' || this.state.customerEmail == '' || this.state.customerName == '') {
            this.setState({
                isNameValid: true,
                isMobileValid: true,
                isEmailValid: true
            });
            return false;
        }
        else {
            this.setState({
                isNameValid: false,
                isMobileValid: false,
                isEmailValid: false
            });
        }
        if (this.state.customerEmail && this.validateEmail(this.state.customerEmail)) {
            this.setState({ isValidEmailId: false, isEmailValid: false })
        }
        else {
            this.setState({ isValidEmailId: true, isEmailValid: true })
            return false;
        }
        let mobileRegex = configJSON.mobileRegex.find((ele: { country: string, regex: RegExp }) => ele.country == this.state.metaInfo?.country_code)
        if (mobileRegex.regex.test(this.state.metaInfo?.phone_code + this.state.customerContact)) {
            this.setState({ isValidcontactNo: false, isMobileValid: false })
        }
        else {
            this.setState({ isValidcontactNo: true, isMobileValid: true })
            return false
        }

        return true
    }

    orderDetailsSetup = (cartItemsCount: Array<ItemsCount>) => {
        let orderItems: Array<OrderItems> = []
        for (let item of cartItemsCount) {
            let variantsItems: Array<VariantsReq> = []
            if (item.variants && item.variants.length > 0) {
                variantsItems = this.setupVariant(item)
                if (variantsItems.length > 0) {
                    orderItems.push({
                        "catalogue_id": item.id,
                        "quantity": item.count,
                        "catalogue_variants": variantsItems
                    })
                }
                else {
                    orderItems.push({
                        "catalogue_id": item.id,
                        "quantity": item.count
                    })
                }
            }
            else {
                orderItems.push({
                    "catalogue_id": item.id,
                    "quantity": item.count
                })
            }
        }
        return orderItems
    }
    setupVariant = (item: ItemsCount) => {
        let variantsItems: Array<VariantsReq> = []
        for (let variant of item.variants) {
            let options: Array<number | string> = []
            let selectedVar = item.selectedVariants.find(e => e.name == variant.title);
            if (variant.options.length > 0 && selectedVar && selectedVar?.option.length > 0) {
                for (let opt of variant.options) {
                    if (selectedVar?.option.findIndex((e: string) => e == opt.data.attributes.option_name) != -1) {
                        options.push(opt.data.id)
                    }
                }
                variantsItems.push({
                    "id": variant.id,
                    "catalogue_option_ids": options
                })
            }
        }
        return variantsItems;
    }

    orderSuccess = (responseJson: PlaceOrderResp) => {

        if (responseJson && responseJson.data && responseJson.data.attributes && responseJson.data.attributes.prefix_id) {
            setStorageData('orderId', responseJson.data.attributes.prefix_id);
        }
        if (responseJson.meta && responseJson.meta.stripe) {
            let stripeData = {
                client_secret: responseJson.meta.stripe.client_secret,
                payment_intent_id: responseJson.meta.stripe.id,
                customer_id: responseJson.meta.stripe.customer,
                orderId: responseJson.data.id,
                stripe_publishable_key: responseJson.meta.stripe.stripe_publishable_key,
                amount: `${this.state.currency}${Number(this.state.distance_rate + this.state.subtotal + this.state.totalTax - this.state.promoCodeDiscount).toFixed(2)}`
            }
            this.goToStripePayment(stripeData)
        } else if (responseJson.meta && responseJson.meta.tap_payments && responseJson.meta.tap_payments.transaction.url) {
            window.location.href = responseJson.meta.tap_payments.transaction.url;
        } else if (responseJson.meta && responseJson.meta.razorpay && responseJson.meta.razorpay.attributes) {
            this.setState({ razerpayOPtion: { razorpay: responseJson.meta.razorpay, account: responseJson.data.attributes.account, total: responseJson.data.attributes.total } })
        } else {
            this.handleNavigationToOrderConfirm();
        }
    }
    handleNavigationToOrderConfirm = () => {
        this.mixPanelEventHit('Successful online payment order')

        const to = new Message(getName(MessageEnum.NavigationMessage));
        to.addData(
            getName(MessageEnum.NavigationTargetMessage),
            "OrderConfirmationSummary"
        );
        to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        runEngine.sendMessage(to.messageId, to);
    }

    changeOrderType = (value: string) => {
        this.setState({ orderType: value, isOrderTypeSelected: false });

    }

    freeDeliveryCheck = () => {
        let currentPrice = Number(this.state.distance_rate + this.state.subtotal + this.state.totalTax - this.state.promoCodeDiscount).toFixed(2)
        if (Number(currentPrice) > Number(this.state.store_profile.data[0].attributes.free_delivery_offer_amount)) {
            this.setState({ msg_popup: true, msg_text: 'Congratulations! You are eligible for free delivery.', msg_status: 'success' })
        }
    }

    closeAlert = () => {
        this.setState({ isOrderTypeSelected: false, isitemUpdated: false, paymentStatus: false })
    }

    closeAlertEmpty = () => {
        this.setState({ isAddressEmpty: false, })
    }
    getPaymentTypes = async () => {
        this.getPaymentTypesApiCallId = await this.apiCalls({
            contentType: configJSON.apiContentType,
            method: configJSON.getApiMethod,
            endPoint: `${configJSON.paymentTypes}`,
        })
    }

    onCloseDialog(add?: DeliveryAddressType) {
        if(add) {
            this.setState({ deliveryAddress: add,isDialogOpen: false},()=>{
            this.getDistance()
            })
        } else {
            this.setState({ isDialogOpen: false })
            if (this.state.orderType == "Delivery") {
                this.freeDeliveryCheck()
            }
        }
    }

    variantSelect = (selectedProduct: CartItem, option: Option, isChecked: boolean, variantIndex: number, optionIndex: number) => {
        const { price } = option.data.attributes;
        const name = option.data.attributes.option_name;
        if (isChecked) {
            this.setState((prevState) => ({
                tempStore: Number(Number(prevState.tempStore + price).toFixed(2)),
                selectedProduct: { ...selectedProduct, total: Number(Number(prevState.tempStore + price).toFixed(2)) },
                varianstName: [...prevState.varianstName, name],
            }));
        } else {
            this.setState((prevState) => ({
                tempStore: Number(Number(prevState.tempStore - price).toFixed(2)),
                selectedProduct: { ...selectedProduct, total: Number(Number(prevState.tempStore - price).toFixed(2)) },
                varianstName: prevState.varianstName.filter((variantName) => variantName !== name),
            }));
        }
    }

    variantRadio = (selectedProduct: CartItem, option: Option, isAdd: boolean, currentPrice: number, variantIndex: number, optionIndex: number) => {
        const { price } = option.data.attributes
        const name = option.data.attributes.option_name;
        if (isAdd) {
            this.setState((prevState) => ({
                tempStore: Number(Number(this.state.tempStore - currentPrice + price).toFixed(2)),
                selectedProduct: { ...selectedProduct, total: Number(Number(this.state.tempStore - currentPrice + price).toFixed(2)) },
                varianstName: [...prevState.varianstName, name],
            }));
        } else {
            this.setState((prevState) => ({
                tempStore: Number(Number(this.state.tempStore - price).toFixed(2)),
                selectedProduct: { ...selectedProduct, total: Number(Number(this.state.tempStore - price).toFixed(2)) },
                varianstName: prevState.varianstName.filter((variantName) => variantName !== name),
            }))
        }
    }
    paymentTypesSuccess = (responseJson: RespJson) => {
        if (responseJson && responseJson.payments && responseJson && responseJson.payments.length > 0)
            this.setState({ merchantNote: responseJson.payments[0].message, isStoreClosed: responseJson.payments[0].online_ordering, storeClosedMessage: responseJson.payments[0].online_store_message });
    }

    updateCart = (newProduct: CartItem) => {
        let data = [...this.state.categorieItems]
        if (newProduct) {
            data[this.state.itemIndex] = { ...newProduct }
        }
        this.setCartPrices(data)
        this.setState({ isDetail: false, isitemUpdated: true, categorieItems: data });
        setStorageData('count', JSON.stringify(data))
        this.clearPromoCode();
    }

    getSelectedOtions = (selectedArray: Array<string>) => {
        if (selectedArray.length > 0) return selectedArray.join(", ")
        else return ""
    }

    changePaymentType = (value: string) => {
        this.setState({ paymentOption: value })
    }

    updatePaymentStatus = () => {
        if (window.location.search.includes('cancel')) {
            this.setState({ paymentStatus: true })
        }
    }

    getTaxes = async () => {
        this.getTaxesApiCallId = await this.apiCalls({
            contentType: configJSON.apiContentType,
            method: configJSON.getApiMethod,
            endPoint: `${configJSON.getTaxes}`,
        })
    }

    handleRazerResponse = async (paymentData: ReqbodyVerifySignature) => {
        this.setState({ ScreenLoading: true })
        let body: ReqbodyVerifySignature = {
            razorpay_order_id: paymentData.razorpay_order_id,
            razorpay_payment_id: paymentData.razorpay_payment_id,
            razorpay_signature: paymentData.razorpay_signature,
        }
        this.razerpayVerifyApiCallId = await this.apiCalls({
            contentType: configJSON.apiContentType,
            method: configJSON.postApiMethod,
            endPoint: `${configJSON.verifySignature}`,
            body: body
        })

    }
    
  
    setSearchInfo=()=>{
     this.setState({ isSearch: false });
    }
    mixPanelEventHit = async (event: string) => {
        if (event != "") {
            AppMixpanel.track(event);
        }
    }
    handleCustomerInfo = (data: string, name: string) => {
        let value: string = data.trim()
        if (name == 'customerName') {
            this.setState({ customerName: data, isNameValid: false })
            if (!value) {
                this.setState({ isNameValid: true, customerName: value })
            }
        }
        if (name == 'customerContact') {
            let mobileRegex = configJSON.mobileRegex.find((ele: { country: string, regex: RegExp }) => ele.country == this.state.metaInfo?.country_code)
            if (mobileRegex.regex.test(this.state.metaInfo?.phone_code + value)) {
                this.setState({ isValidcontactNo: false, isMobileValid: false, customerContact: value })
            }
            else {
                this.setState({ isValidcontactNo: true, isMobileValid: true, customerContact: value })
                return false
            }
        }
        if (name == 'customerEmail') {
            if (this.validateEmail(value)) {
                this.setState({ isValidEmailId: false, customerEmail: value, isEmailValid: false })
            }
            else {
                this.setState({ isValidEmailId: true, customerEmail: value, isEmailValid: true })
                return false
            }
        }
    }
    validateEmail = (email: string) => {
        const emailregex = new RegExp(configJSON.emailRegex)
        return emailregex.test(email);
    };
    handlePromotionStatusChange = (value: boolean) => {
        this.setState({ promotionEnabled: value })
    }
    cartItemsInfo = async (id: string) => {
        this.setState({ScreenLoading : true})
        this.getCategoryItemsApiCallId = await this.apiCalls({
            contentType: configJSON.apiContentType,
            method: configJSON.getApiMethod,
            endPoint: `${configJSON.getCartItems}${id}`,
        })
    }
    handleCategorySucessResponse = (apiRequestCallId: string, responseJson: ResponseJson) => {
        if (apiRequestCallId === this.getCategoryItemsApiCallId) {
            this.setState({ScreenLoading: false})
            this.handleCategoryItemInfoSuccess(responseJson)
        }
    }
    handleCategoryItemInfoSuccess = async (responseJson: ResponseJson) => {
        let data = JSON.parse(await getStorageData('count'));
        if(responseJson && responseJson.data && responseJson.data.length>0){
          for(let item of data){
               let items=responseJson.data.find((cartItem:CartItem)=>item.id==cartItem.id);
               let variants:Array<{name:string,option:Array<string>}> = []
               if(items)
                variants = this.setVariants(item,items)
               if(items && items.attributes){
                item.attributes=items.attributes
                item.variants=variants
               } 
          }
          this.setCartPrices(data)
          setTimeout(() => {
            this.setState({ isUptodateVariants: false, ScreenLoading: false })
        }, 2000);
      }
    }
    setVariants=(item:CartItem,items:CartItem)=>{
        let variants = item.variants.filter((element:{name:string,option:Array<string>})=>{
            let existVariants= items?.attributes.variants.find(item=>item.title==element.name)
            let options:Array<string>=[]
             existVariants?.options.filter((option:Options)=>{
                 if(element.option.indexOf(option.data.attributes.option_name)!=-1 && option.data.attributes.status){
                     options.push(option.data.attributes.option_name)
                 }
             })
             if(options.length>0){
                element.option=options
                 return element
             }
          }) 
          return variants
    }
    goToStripePayment = (data: {
        client_secret: string,
        payment_intent_id: string,
        customer_id: string,
        orderId: string,
        stripe_publishable_key: string,
        amount: string
    }) => {
        setStorageData('stipeInfo', JSON.stringify(data))
        const to = new Message(getName(MessageEnum.NavigationMessage));
        to.addData(
            getName(MessageEnum.NavigationTargetMessage),
            "StripePaymentsHome"
        );
        to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(to)
    }
    // Customizable Area End
}
