// Customizable Area Start
import React from "react";
import {
  withStyles,
  Grid,
  createStyles,
  Theme,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import * as YupValidate from "yup";
import DialogContent from "@material-ui/core/DialogContent";
import AddTaxDialogController from "./AddTaxDialogController.web";
import TextField from '@material-ui/core/TextField';
export const configJSON = require("./config.js");
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { Dialog } from "../../ss-cms-common-components/src/Dialog/Dialog";


const SchemaPercentage = YupValidate.object().shape({
  tax: YupValidate.number()
    .typeError(configJSON.taxValidation1)
    .moreThan(0, configJSON.taxValidation2)
    .lessThan(100, configJSON.taxValidation3)
    .required(configJSON.requiredField),
  tax_title: YupValidate.string().required(configJSON.taxTitleRequired)

});

export class AddTaxDialog extends AddTaxDialogController {

  render() {
    const {
      classes,
      title,
      id,
      textLabel,
      helperTextValue,
      taxId,
      taxTitle,
      taxPercentage,
    } = this.props;
    const { open } = this.state;
    return (

      <Dialog
        containerClassName="create-qr-code-dialog"
        title={taxId ? configJSON.editTax : title}
        titleClassName={classes.dialogTitle}
        customContent
        open={open}
        onClose={this.closeDialog}
        okay={taxId ? configJSON.save : configJSON.add}
        data-testid="dialog-container"
        setOpen={() => open}
        onSubmit={this.handleSubmitDialog}
      >

        <DialogContent classes={{ root: classes.contentRoot }}>
          <Formik
            initialValues={{
              tax: taxId ? taxPercentage : "",
              tax_title: taxId ? taxTitle : ""
            }}
            validationSchema={SchemaPercentage}
            innerRef={this.taxRef}
            onSubmit={(values) => {
              values.tax = values.tax.split('.')[1]?.length === 0 ? values.tax.split('.')[0] : values.tax
              this.handleSubmit(values);
            }}
          >
            {(formikProps) => {
              const {
                values,
                errors,
                touched,
                handleChange,
              } = formikProps;
              return (
                <Form autoComplete="off" noValidate style={{ margin: 0 }} translate={undefined}>
                  <Grid
                    container
                    justifyContent="center"
                  >
                    <div className={classes.fullDiv + " d-flex flex-column pb-24"}>
                      <label className={classes.inputLabel}>{configJSON.taxTitle}</label>
                      <TextField
                        InputProps={{
                          className: classes.textField,
                        }}
                        name="tax_title"
                        value={values.tax_title}
                        error={errors?.tax_title && touched?.tax_title ? true : false}
                        placeholder={configJSON.taxTitlePlaceholder}
                        onChange={handleChange}
                        variant="outlined"
                        helperText={
                          errors?.tax_title && touched?.tax_title
                            ? errors?.tax_title
                            : ""
                        }
                      />
                    </div>
                    <div className={classes.fullDiv + " d-flex flex-column pb-24"}>
                      <label className={classes.inputLabel}>{configJSON.taxPercentage}</label>
                      <TextField
                        InputProps={{
                          className: classes.textField,
                        }}
                        name="tax"
                        value={values.tax}
                        error={errors?.tax && touched?.tax ? true : false}
                        placeholder={textLabel}
                        onChange={handleChange}
                        variant="outlined"
                        helperText={
                          errors?.tax && touched?.tax
                            ? errors?.tax
                            : helperTextValue
                        }
                      />
                    </div>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    )

  };
};

const styles = (theme: Theme) =>
  createStyles({
    footerAction: {
      display: "flex",
      alignItems: "center",
      flex: "0 0 1",
      gap: "16px",
      borderTop: "1px solid #E8E8E8",
    },
    footerActionRight: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      flex: 1,
      gap: "16px",
    },
    root: {
      marginBottom: "65px",
    },
    dialogRoot: {
      margin: 0,
      padding: "40px 40px 30px 40px",
      alignItems: "center",
      display: "flex",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(2),
    },
    closeImage: {
      height: "1.75rem",
      width: "1.75rem",
    },
    dialogTitle: {
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "28px",
      lineHeight: "32px",
      letterSpacing: "-0.02em",
      color: "#3C3E49",
      flex: 1,
    },
    svgRoot: {
      width: "24px",
      height: "24px",
    },
    contentRoot: {
      padding: 0,
    },
    inputLabel: {
      marginBottom: "8px",
      color: "#000000",
      fontSize: "12px"
    },
    fullDiv: {
      width: "88%"
    },
    textfield: {
      border: "1px solid #c0c3ce",
      borderRadius: "3px",
      fontFamily: 'Rubik , sans-serif',
      lineHeight: "20px",
      width: "100%",
      height: 48,
      color: "#3C3E49",
      fontWeight: 400,
      fontSize: 14,
      backgroundColor: "#fff",
      "&  .MuiFormHelperText-root": {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "10px",
        lineHeight: "16px",
        paddingLeft: "3px",
        alignItems: "center",
        color: "#83889E",
      },

      "& .MuiFormHelperText-contained": {
        marginLeft: 0,
        marginRight: 0,
      },

      "& .MuiFormHelperText-root.Mui-error": {
        color: "#D50000",
      },
      "& .MuiFormHelperText-root.Mui-error:first-letter": {
        textTransform: "uppercase"
      },
      "& .MuiOutlinedInput-root": {
        position: "relative",
        bordeRadius: 4,
      },

      "& .MuiInputBase-fullWidth": {
        width: "98%",
        marginLeft: "3px",
      },
    },
  });
export default withStyles(styles)(((withHeaderBar((AddTaxDialog)))));
// Customizable Area End
