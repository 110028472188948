Object.defineProperty(exports, "__esModule", {
  value: true,
});
// Customizable Area Start
exports.displayCardVertical = true;

exports.otherIntegrationsTitle = "Other integrations";

exports.brandSettingsAPI = "/admin/v1/brand_settings";
exports.getApiMethodType = "GET";
exports.updateApiMethodType = "POST";
exports.createApiMethodType = "POST";
exports.deleteApiMethodType = "DELETE";
exports.putApiMethodType = "PUT";

exports.dialogMessage =
  "Please fill in the following fields to activate this integration.";
exports.dialogTitle = "Integration ";

exports.consoleErrorBase = "Async: Could not copy text: ";

exports.notActive = "Not active";
exports.active = "Active";

exports.activateButton = "Activate";
exports.editIntegrationButton = "Edit";
exports.dummyPassword = "*************";
exports.thisFieldIsRequired = "This field is required";
exports.updateIntegrationSuccess = "Integration updated successfully";
exports.firebaseActivationErrorTitle = "You can not activate firebase";
exports.firebaseActivationErrorMessage = "You need to select at least one of the login methods";
exports.firebaseActivationErrorConfirm = "Okay";
exports.activated = "Activated";
exports.notActivated = "Not Activated";
exports.howToUse = "How to use";

exports.errors = {
  errorPageInitialisation: "Error on page initialization",
  errorCardUpdate: "Error on card update",
  googleFirebase: "Google Firebase Login Integration data couldn't be fetched",
  integration: "Integration data couldn't be fetched",
  updateIntegration: "Integration data couldn't be saved",
  default: "Something went wrong",
  brandSettings: "Country data couldn't be fetched",
};

exports.kaleyra = {
  order: 2,
  name: "Kaleyra SMS",
  apiEndpoint: "bx_block_sms_gateways/kaleyra_setting",
  fetchEndpoint: "bx_block_sms_gateways/kaleyra_setting",
  integrationName: "kaleyra",
  cartTitle: "Kaleyra SMS",
  dialogTitle: "Kaleyra SMS",
  description: "Use to send SMS using Kaleyra SMS",
  dialogMessage: "Use to send SMS using Kaleyra SMS",
  howToUseLink:
    "https://intercom.help/engineerai/en/articles/6961909-setting-up-your-kaleyra-account",
  updateWithPut: true,
  checkStateToShowCard: true,
  fields: [
    {
      key: "api_key",
      label: "API Key",
      placeholder: "API Key",
      type: "text",
      showAtCard: true,
      isHiddenFieldAtCard: false,
      integrationActivityCheck: true,
      required: true,
      validations: ["string", "required", "nullable"],
      params: {
        required: exports.thisFieldIsRequired,
      },
    },
    {
      key: "sid",
      label: "SId",
      placeholder: "Auth SId",
      type: "text",
      showAtCard: true,
      isHiddenFieldAtCard: true,
      required: true,
      validations: ["string", "required", "nullable"],
      params: {
        required: exports.thisFieldIsRequired,
      },
    },
    {
      key: "flow_id",
      label: "Flow id",
      placeholder: "Flow id",
      type: "text",
      required: true,
      validations: ["string", "required", "nullable"],
      params: {
        required: exports.thisFieldIsRequired,
      },
    },
  ],
};

exports.integrations = [
  exports.kaleyra,
];

exports.typeKaleyra = "kaleyra"
exports.fieldShowCard = "checkStateToShowCard";
// Customizable Area End