import * as React from "react";
// Customizable Area Start
import { StyleSheet, View,Pressable,Text } from "react-native"
import { ViewProps } from "./StripePayments.web";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import {
  Typography,
} from "@material-ui/core";



// Customizable Area End

const StripePaymentsView: React.FC<ViewProps> = ({
  // Customizable Area Start
  testID,
  stripePromise,
  stripeClientSecret,
  errorString,
  setOrderNumber,
  orderNumber,
  actionResult,
  stripeInitialised,
  isInStripeCallback,
  onHandleSubmit,
  submitOrderNumberButtonViewProps,
  submitPaymentButtonViewProps,
  loadingViewProps,
  orderIdViewProps,
  stripeMessageViewProps,
  amount
  // Customizable Area End
}) => {
  const Render: React.FC<ViewProps> = ({
    testID,
    errorString,
    actionResult,
    stripeInitialised,
    onHandleSubmit,
    submitPaymentButtonViewProps,
    stripeMessageViewProps,
    amount
  }) => {
    // Customizable Area Start
    const stripe = useStripe();
    const stripeElements = useElements();

    if (errorString) {
      return (
        <View data-test-id="errorText" style={webStyle.mainWrapper}>
          <Typography>{stripeMessageViewProps.errorValue}</Typography>
          <Typography>{errorString}</Typography>
        </View>
      );
    }
    if (actionResult) {
      return (
        <View data-test-id={testID} style={webStyle.mainWrapper}>
          <Typography>{stripeMessageViewProps.successValue}</Typography>
          <Typography>Payment Status Message</Typography>
          <Typography>{actionResult}</Typography>
        </View>
      );
    } 
    if (stripeInitialised) {
      return (
        <View style={webStyle.mainWrapper}>
          <View style={webStyle.mainWrapper}>
            <PaymentElement />
               <Pressable style={webStyle.submitButton} testID="submit-payment" onPress={() => onHandleSubmit(stripe, stripeElements)}>
      <Text style={webStyle.text}>{`${submitPaymentButtonViewProps.value} (${amount})`}</Text>
    </Pressable>
          </View>
        </View>
      );
    }
    // Customizable Area End
    // Customizable Area Start
    return (
      <View style={webStyle.mainWrapper}>
        <Typography>{loadingViewProps.value}</Typography>
      </View>
    );
    // Customizable Area End
  };

  const viewProps: ViewProps = {
    testID: testID,
    errorString,
    stripePromise: stripePromise,
    stripeClientSecret: stripeClientSecret,
    setOrderNumber: setOrderNumber,
    orderNumber: orderNumber,
    actionResult: actionResult,
    stripeInitialised: stripeInitialised,
    isInStripeCallback: isInStripeCallback,
    onHandleSubmit: onHandleSubmit,
    submitOrderNumberButtonViewProps,
    submitPaymentButtonViewProps,
    loadingViewProps,
    orderIdViewProps,
    stripeMessageViewProps,
    amount
  };

  return (
    <Elements
      stripe={stripePromise}
      options={{ clientSecret: stripeClientSecret }}
      key={stripeClientSecret}
    >
      <Render {...viewProps} />
    </Elements>
  );
};

const webStyle = StyleSheet.create({
  // Customizable Area Start
  mainWrapper: {
    display: "flex",
    fontFamily: "var(--website-builder-body-font)",
    flexDirection: "column",
    paddingTop: "32px",
    paddingBottom: "32px",
    paddingLeft: "32px",
    paddingRight: "32px",
    backgroundColor: "#fff",
  },
  flexWrapper: {
    flex: 1,
  },
  horizontalWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  submitButton: {
    backgroundColor:"#8833FF",
    color: "#fff",
    textTransform:'capitalize',
    marginTop:'30px',
    fontFamily:"var(--website-builder-body-font)",
    fontWeight: "700",
    textAlign:"center",
    paddingTop:'10px',
    paddingBottom:'10px'

  },
  cancelButton: {
    backgroundColor: "#FF0000",
  },
  text:{
    color: "#fff",
    padding:'6px 8px'
  }
  // Customizable Area End
});

export default StripePaymentsView;
