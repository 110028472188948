// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true,
})

exports.addTaxLabel = "Add tax"
exports.addTaxTitle = "Add tax"
exports.addDialogTaxHelperText = "This tax will be applied for all the menu items for billing."
exports.addDialogTaxLabel = "Tax percentage"
exports.emptyPageTitle = "You have no tax configured"
exports.emptyPageButton = "Add tax"
exports.emptyPageMassage = `Add taxes to apply to your menu items`
exports.paginationItemText = "Taxes"
exports.addTaxSuccessMessage = "Tax added successfully"
exports.generalErrorMessage = "Something went wrong"
exports.deleteModelTitle = "Are you sure?"
exports.deleteModelMessage = "Are you sure you want to delete this taxes."

exports.getTaxDataApiUrl = "bx_block_taxes/taxes"
exports.getTaxDataApiMethodType = "GET"
exports.addTaxApiUrl = "bx_block_taxes/taxes"
exports.addTaxApiMethodType = "POST"
exports.taxValidation1= "Please enter number only"
exports.taxValidation2= "Tax should be greater then 0";
exports.taxValidation3= "Tax should be less then 100";
exports.updateTaxes = "Update tax"
exports.cancel = "Cancel"
exports.taxes = "Taxes"
exports.addTaxURL = "/business-settings/taxes/add-tax"

exports.taxTitleRequired= "tax title is a required field"
exports.editTax = "Edit tax"
exports.add = "Add"
exports.save = "Save"
exports.taxTitle = "Tax title *"
exports.taxTitlePlaceholder = "Tax title"
exports.taxPercentage = "Tax percentage *"
exports.editTaxSuccessMessage = "Tax edited successfully"
exports.putMethodType = "PUT"
exports.taxTableTitle = "Tax title"
exports.percentageTable = "Percentage"
exports.lastupdate = "Last update"
exports.active = "Active"
exports.edit = "Edit"
exports.percentageSign = "%"

exports.dateFormat = "MMM DD YYYY, HH:mm";
// Customizable Area End
