// Customizable Area Start
import * as React from "react"
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog"
import { Scrollbars } from "react-custom-scrollbars"
import EmptyPage from "../../ss-cms-common-components/src/EmptyPage/EmptyPage"

import { createStyles, withStyles } from "@material-ui/core"

import TableComponent from "../../ss-cms-common-components/src/TableComponent/TableComponent"
import "../assets/staticpagetable.css"
import CustomizedButton from "../../ss-cms-common-components/src/Button/CustomButton"

import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web"
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web"

import StaticPageTableController from "./StaticPageTableController.web"
import { withRouter } from "react-router-dom"
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout"

const configJSON = require("./config")
const titles = configJSON.staticPageTableTitles

export const styles = () =>
  createStyles({
    buttonContainer: {
      alignItems: "flex-end",
    },
  })

export class StaticPageTable extends StaticPageTableController {
  handleStaticPageTable = () => {
    const { spTable, checkedStaticPageList, showEmptyPage } = this.state
    if (spTable?.length > 0) {
      return (
        <div className="table-container">
          <div className="header-section-spt">
            <div className="static_page_title">{configJSON.staticPages}</div>
            <div style={{ display: "flex", alignItems: "center", gap: "16px", marginTop: "16px" }}>
              {checkedStaticPageList?.length > 0 && (
                <p className="delete-items" onClick={this.handleDeleteModal}>
                  {configJSON.deleteStaticPages}
                </p>
              )}
              <CustomizedButton
                label={configJSON.createNewStaticPage}
                handleClick={this.handleAddSPClick}
              />
            </div>
          </div>
          <TableComponent
            titles={titles}
            data={spTable}
            edit={true}
            showIds={false}
            handleEditClick={this.handleUpdateSPClick}
            activatedCellStyleTrue={webStyles.activatedCellStyleTrue}
            activatedCellStyleFalse={webStyles.activatedCellStyleFalse}
            getCheckedItems={(item) => this.checkedItems(item)}
            checkbox
          />
        </div>
      )
    } else if (showEmptyPage) {
      return (
        <div className="empty-page">
          <EmptyPage
            title={configJSON.youHaveNoStaticPages}
            message={""}
            button_label={configJSON.createStaticPage}
            handleClick={this.createStaticPageHandler}
            Component={() => (
              <div style={webStyles.message}>{configJSON.emptyPageMessage}</div>
            )}
            isCustomComponent={true}
          />
        </div>
      )
    }
  }

  render() {
    return (
      <Scrollbars>
        <div className="mainContainer-spt">
          {this.handleStaticPageTable()}
        </div>
      </Scrollbars>
    )
  }
}

const webStyles = {
  activatedCellStyleTrue: {
    width: "min-content",
    paddingRight: 20,
    paddingLeft: 20,
    backgroundColor: "#EBF5F0",
    borderColor: "#B3DAC7",
    color: "#008243",
  },
  activatedCellStyleFalse: {
    width: "min-content",
    paddingRight: 20,
    paddingLeft: 20,
    backgroundColor: "#FFECEC",
    borderColor: "#FFD7D7",
    color: "#E00000",
  } as any,
  message: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#676B7E",
    textAlign: "center",
    fontFamily: "Rubik",
    fontSize: "14px",
    lineHeight: "22px",
    whiteSpace: "pre-line"
  } as any
}

export default withLayout(withStyles(styles)(
  withLoader(withHeaderBar(withRouter(withDialog(StaticPageTable))))
))
// Customizable Area End