import { createRef } from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { FormikProps } from "formik";
import * as Yup from "yup";

const configJSON = require("./config.js");

// Customizable Area Start
import { IntegrationResponseTypes, IntegrationResponseWithTypeTypes } from "./OtherIntegrations.types";
// Customizable Area End

export type Props = {
  // Customizable Area Start
  open: boolean;
  setOpen: (newState: boolean) => void;
  onSubmit: (integrationData: IntegrationResponseWithTypeTypes) => void;
  selectedIntegration: IntegrationResponseTypes;
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export type SubmitDataType = {};

export default class IntegrationActivateDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  formRef = createRef<FormikProps<IntegrationResponseWithTypeTypes>>();

  TawkToSchema = Yup.object().shape({
    property_id: Yup.string().required(configJSON.thisFieldIsRequired),
    widget_id: Yup.string().required(configJSON.thisFieldIsRequired),
  });

  FirebaseSchema = Yup.object().shape({
    api_key: Yup.string().required(configJSON.thisFieldIsRequired),
    auth_domain: Yup.string().required(configJSON.thisFieldIsRequired),
    project_id: Yup.string().required(configJSON.thisFieldIsRequired),
    storage_bucket: Yup.string().required(configJSON.thisFieldIsRequired),
    messaging_sender_id: Yup.string().required(configJSON.thisFieldIsRequired),
    app_id: Yup.string().required(configJSON.thisFieldIsRequired),
    measurement_id: Yup.string().required(configJSON.thisFieldIsRequired),
    is_google_login: Yup.boolean(),
    is_facebook_login: Yup.boolean(),
  });

  KaleyraSchema = Yup.object().shape({
    api_key: Yup.string().required(configJSON.thisFieldIsRequired),
    sid: Yup.string().required(configJSON.thisFieldIsRequired),
    flow_id: Yup.string().required(configJSON.thisFieldIsRequired),
  });

  constructor(props: Props) {
    super(props);
  }

  handleSubmit = (
    submitData: IntegrationResponseWithTypeTypes,
    integrationConfig: { [key: string]: string },
    selectedIntegration: IntegrationResponseTypes
  ) => {
    const updateData = submitData;
    if (integrationConfig?.updateWithPut) {
      updateData.id = selectedIntegration?.id || "";
    }
    updateData.is_active = Boolean(selectedIntegration?.is_active);
    this.props.onSubmit(updateData);
  };

  handleSubmitDialog = () => {
    this.formRef.current?.handleSubmit();
    return false;
  };
}
