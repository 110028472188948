// Customizable Area Start
import React from "react";
import { ButtonWithDialog } from "../../ss-cms-common-components/src/ButtonWithDialog/ButtonWithDialog";
export const configJSON = require("./config.js");
import CatalogueItemController from "./CatalogueItemController.web";
import { DeleteBinIcon } from "./assets";

export default class CatalogueItem extends CatalogueItemController {
  render() {
    const { data, onEdit } = this.props;

    return (
      <div className="catalogue-item">
        <div className="image-wrapper">
          <img className="catalogue-image" src={data.image} />
        </div>
        <div className="file-name">{`${data.name}` || "File Name"}</div>
        <div className="catalogue-item-footer">
          <div onClick={() => onEdit(data)} className="edit-icon">
            {configJSON.textEdit} 
          </div>
          <div className="catalogue-item-buttons">
            <ButtonWithDialog
              classes={{
                root: "catalogue-item-delete-button",
                label: "catalogue-item-button-label",
                outlined: "catalogue-item-button-outline",
              }}
              variant="outlined"
              onSubmit={this.handleDelete}
              containerClassName="delete-dialog"
              titleClassName="delete-dialog-title"
              messageClassName="delete-dialog-message"
              cancelClassName="delete-dialog-cancel"
              okClassName="delete-dialog-ok"
              title={configJSON.dialogTitle}
              message={configJSON.dialogMessage}
              okay={configJSON.dialogOkay}
            >
              <img
                src={DeleteBinIcon}
                alt="delete-item"
                className="delete-icon"
              />
            </ButtonWithDialog>
          </div>
        </div>
      </div>
    );
  }
}
// Customizable Area End