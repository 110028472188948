
import React from "react";
// Customizable Area Start
import {
  Box,
  Card,
  CardContent,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
  FormGroup,
  createStyles,
  Theme,
  withStyles,
  DialogActions,
  Chip,
  Drawer,
  Button,
  Typography
} from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
import {
  dummyLogo,
  filter,
  arrowLeft,
  arrowRight,
  plusAdd,
  minusRemove,
  disablePlusAdd
} from './assets';
import { foodLabelCheck } from '../../inventorymanagement/src/InventoryManagement.web'
import { AppHeader } from "../../../components/src/AppHeader.web";
import { ProductDetailDialogue } from "../../../components/src/ProductDetailPopUp";
import CloseIcon from '@material-ui/icons/Close';
import LocalPhoneOutlinedIcon from '@material-ui/icons/LocalPhoneOutlined';
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';
import { AppFooter } from "../../../components/src/AppFooter.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NoSearchFound from "../../../components/src/NoSearchFound.web";
import { CartItem } from "../../../../packages/blocks/shoppingcart/src/CartOrdersController.web";
const images = require("./assets")
import RemoveIcon from '@material-ui/icons/Remove';
import {Helmet} from "react-helmet";
// Customizable Area End

import LandingPageControllerWeb, {
  ItemMenuFilter,
  Props,
  SectionType,
  configJSON,
} from "./LandingPageController.web";

export const PurpleCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "#6200EA",
    },
  },
  checked: {},
})((props: any) => <Checkbox color="default" {...props} />);


export class LandingPageWeb extends LandingPageControllerWeb {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  handleOpenFilterDrawer = () => {
    this.mixPanelEventHit("Applied filters")
    this.setState({
      filterDrawer: true,
      selectedFoodLables: this.state.appliedFoodLables
    })
  }

  handleCloseFilterDrawer = () => {
    this.setState({
      filterDrawer: false
    })
  }

  foodLabelBlock = (item: any) => {
    const labels = item?.attributes?.food_lables?.filter((lab: any) => !!lab.trim())
    const labelArray = labels?.slice(0, this.state.showTag)
    return (
      <Grid style={{ display: "flex" }}>
        {labelArray?.map((attr: any, index_num: any) => {
          return (
            <Grid style={cardStyle.foodLables} key={`block_${index_num}`} className="food-label-image">
              <img src={foodLabelCheck(attr)} alt="veg" />
            </Grid>
          );
        })
        }
        {labels?.length > this.state.showTag && (
          <Grid style={cardStyle.foodLablesCount} className="food-label-image">
            <span
              style={cardStyle.labelLength}
              className="food-label-text"
            >
              +{labels.length - this.state.showTag}
            </span>
          </Grid>
        )}
      </Grid>
    );
  };

  priceBlock = (item: any) => {
    const isDiscount = !(item.attributes.after_discount === item.attributes.price);
    const discontPercent = 100 - (item.attributes.after_discount * 100) / item.attributes.price;
    return (
      <Box style={{ display: 'flex', alignItems: 'center', marginTop: '8px', justifyContent: 'space-between', width: "100%" }}>
        <div style={{ display: 'flex', alignItems: "center" }}>
          <div style={{ display: 'flex', alignItems: "center" }}>
            <span style={cardStyle.currecyText}  className="price-text">
              {this.currencyType}{item.attributes.after_discount || item.attributes.price}
            </span>
            {isDiscount &&
              <span style={cardStyle.currecyType} className='new-price-text'>
                {this.currencyType}{item.attributes.price}
              </span>
            }
          </div>
          {isDiscount && <span style={cardStyle.saveBtn} className="save-price-text">{`Save ${discontPercent.toFixed(0)}%`}</span>}
        </div>
        {item?.attributes?.availability === "out_of_stock" && <span style={styles.notAvailableText} className="available-text">Out of Stock</span>}
      </Box>
    )
  }

  renderCategories = (classes: any) => {
    return (
      <div className={classes.renderCatgoriesNew}>
        <div>
          <div className={classes.filterButtonDiv} style={{top:`${this.state.noSearch ? '0px' : '24px'}`}}>
            <Button data-test-id="filterOpentest" onClick={this.handleOpenFilter} style={cardStyle.filterButton}>
              Filter      
              <svg className={classes.marginLeft10} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.66667 12H9.33333V10.6667H6.66667V12ZM2 4V5.33333H14V4H2ZM4 8.66667H12V7.33333H4V8.66667Z" fill="var(--website-builder-secondary-color)"/>
              </svg>
            </Button>
          </div>
          <div style={styles.renderCategoriesStyle}>
            <div data-test-id="handlePrev"  onClick={this.handlePrev} style={styles.btnBack} className="landing-page-category-arrow-left">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z" fill="var(--website-builder-secondary-color)"/>
              </svg>
            </div>
            <div style={styles.menubar} className="menu-bar">
              <div style={{ display: 'flex', overflow: 'auto', width: "100%" }} id="category-scroll" className="category-scroll">
                {this.state.categories.map((item: any, index: number) => {
                  return (
                    <div
                      key={index}
                      style={{
                        ...styles.tab,
                        borderBottom:
                          this.state.categoryId === item.attributes.id ? '2px solid var(--website-builder-primary-color)' : '2px solid var(--website-builder-secondary-light-accent-color)',
                        color: this.state.categoryId === item.attributes.id ? 'var(--website-builder-primary-color)' : 'var(--website-builder-secondary-dark-accent-color)',
                        fontWeight: this.state.categoryId === item.attributes.id ? 700 : 400,
                      }}
                      data-test-id={`showCategoriesId_${index}`}
                      id={`showCategoriesId_${item.attributes.id}`}
                      onClick={() => {
                        this.manualCategoryScroll(item.attributes.id)
                      }}
                      className="landing-page-category"
                    >
                      {item.attributes.name}
                    </div>
                  )
                })}
              </div>
            </div>
            <div data-test-id="handleNext" onClick={this.handleNext} style={styles.btnForwar} className="landing-page-category-arrow-right">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.1716 12.0007L8.2218 16.9504L9.636 18.3646L16 12.0007L9.636 5.63672L8.2218 7.05093L13.1716 12.0007Z" fill="var(--website-builder-secondary-color)"/>
            </svg>
            </div>
          </div>
        </div>
        {
          this.state.appliedFoodLables.length > 0 &&
          <div className="filter-landing-page-view">
            <Typography variant="h6"
            
            style={styles.filterTextChip}>Filters:</Typography>
            {this.state.appliedFoodLables.map((foodLabel: any) => {
              return (
                <Chip
                  key={foodLabel}
                  label={foodLabel}
                  data-testId="chipTest"
                  deleteIcon={<CloseIcon style={{ color: "var(--website-builder-primary-color)", height: "16px", width: "16px" }} />}
                  onDelete={() => { this.handleFoodLabelsFilterDelete(foodLabel) }}
                  style={styles.chipStyles}
                />
              )
            })}
          </div>
        }
      </div>
    )
  }


  renderPlaceOrder() {
    if (this.countItem()) {
      return (
        <div className="place-order-container" style={styles.orderActionContainer}>
          {this.state.addToCardButton ?
            <div data-test-id='place-order' className="place-order-text" onClick={() => this.handleAddToCart()} style={this.state.isStoreClosed ? { ...styles.addToCartBtn, ...styles.btnDisable } : { ...styles.addToCartBtn, ...styles.btnEnable }}>
              {`Add to Cart`}
            </div>
            :
            <div data-test-id='place-order' className="place-order-text" onClick={() => this.onPressOrder()} style={this.state.isStoreClosed ? { ...styles.addToCartBtn, ...styles.btnDisable } : { ...styles.addToCartBtn, ...styles.btnEnable }}>
              {`Go to Cart (${this.countItem()})`}
            </div>}
        </div>
      )
    }
    if (this.countItem() == 0 && this.state.isStoreClosed) {
      return (
        <div className="place-order-container" style={styles.orderActionContainer}>
          <div data-test-id='place-order-disable' className="place-order-text" style={{ ...styles.addToCartBtn, ...styles.btnDisable }}>
            {`Add to Cart`}
          </div>

        </div>
      )
    }
  }

  foodLabelDropdownfunction(){
    return(
      <div>
                  {this.state.foodLabelDropdown ? (
                      <svg className={cardStyle.cursorPointer} data-test-id="arrowUpTest" onClick={this.handleFoodLableClose} width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.625 14.3241L10.5583 5.39162C10.6754 5.27438 10.8343 5.2085 11 5.2085C11.1657 5.2085 11.3246 5.27438 11.4417 5.39162L20.375 14.3241" stroke="var(--website-builder-secondary-color)" stroke-linejoin="round" stroke-width="1.5" stroke-linecap="round" />
                    </svg>
                  ) : (
                  <svg onClick={this.handleFoodLabelOpen} className={cardStyle.cursorPointer}  data-test-id="arrowDownTest" width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path  d="M1.625 5.67588L10.5583 14.6084C10.6754 14.7256 10.8343 14.7915 11 14.7915C11.1657 14.7915 11.3246 14.7256 11.4417 14.6084L20.375 5.67588" stroke="var(--website-builder-secondary-color)" stroke-linecap="round" stroke-width="1.5"  stroke-linejoin="round"/>
                  </svg>
                  )}
                </div>
    )
  }
  canvasData (){
    const { classes } = this.props;

return(
  (this.state.canvasData && this.state.canvasData.sections.data) &&
    <>
    { this.state.canvasData.sections.data.map((value:SectionType)=>{
      if(value.attributes.desktop_banner_url != "" && !value.attributes.is_deleted){
        let desktopBannerMetaData
        if(typeof value.attributes.desktop_banner_metadata  === 'string'){
          desktopBannerMetaData = JSON.parse(value.attributes.desktop_banner_metadata)
        }
        return(
          <div className={classes.DesktopBannerWrapper} onClick={()=>this.mixPanelEventHit('Banner Click')}>
            {typeof value.attributes.desktop_banner_metadata  === 'string' && 
            <div className={classes.desktopTextWrapper} style={{
              textAlign : desktopBannerMetaData.text_alignment.toLowerCase(),
              justifyContent: this.getBannerTextPosition(desktopBannerMetaData).justifyContent,
              alignItems: this.getBannerTextPosition(desktopBannerMetaData).alignItems,
              color: desktopBannerMetaData.font_colour,
              fontSize: Number(desktopBannerMetaData.font_size)
            }}>{desktopBannerMetaData.header_text}</div>
          }
            <img src={value.attributes.desktop_banner_url} className={classes.desktopBannerImage}  />
          </div>
        )
      }
    })}
     {this.state.canvasData.sections.data.map((value:SectionType)=>{
      if(value.attributes.mobile_banner_url != "" && !value.attributes.is_deleted){
        let mobileBannerMetaData 
        if(typeof value.attributes.mobile_banner_metadata  === 'string'){
          mobileBannerMetaData = JSON.parse(value.attributes.mobile_banner_metadata)
        }
        return(
          <div className={classes.MobileBannerWrapper} onClick={()=>this.mixPanelEventHit('Banner Click')}>
            {typeof value.attributes.mobile_banner_metadata  === 'string' && 
            <div className={classes.mobileTextWrapper} style={{
              textAlign : mobileBannerMetaData.text_alignment.toLowerCase(),
              justifyContent: this.getBannerTextPosition(mobileBannerMetaData).justifyContent,
              alignItems: this.getBannerTextPosition(mobileBannerMetaData).alignItems,
              color: mobileBannerMetaData.font_colour,
              fontSize: Number(mobileBannerMetaData.font_size)
            }}>{mobileBannerMetaData.header_text}</div>
          }
            <img src={value.attributes.mobile_banner_url} className={classes.mobileBannerImage} />
          </div>
        )
      }
    })}
    </>
  
)
  }
faviconData(){
  return(
    this.state.canvasData?.header?.data.attributes.favicon_url !="" &&
    <Helmet>
    <link rel="icon" type="image/x-icon" href={this.state.canvasData?.header?.data.attributes.favicon_url} />
    </Helmet>
  )
}
  // Customizable Area End
  render() {
    const { classes } = this.props;
    return (
      // Customizable Area Start
      <div style={{ backgroundColor: '#F1F5F9' }} className="hide-scroll">
        {this.faviconData()}
        <div className={classes.appHeaderStyle}>
          <AppHeader
            logo={dummyLogo}
            Fname="Restaurant"
            Lname="Name"
            value={this.state.search}
            searchData={this.state.searchData}
            onChange={(text: any) => this.searchItem(text.target.value)}
            onBlur={() => { this.setState({ isSearch: false }); }}
            onFocus={() => { this.setState({ isSearch: true }); }}
            state={this.state}
            itemSelect={(i: CartItem) => this.openItemDetails(i)}
            data-test-id="appHeaderComponent"
            itemImage="image" itemName="Name"
            openDrawer={this.state.openDrawer}
            handleOpenDrawer={this.handleOpenDrawer}
            handleCloseDrawer={this.handleCloseDrawer}
            mixPanelHit={this.mixPanelEventHit}
          />
          <div className={classes.filterDrawer} >
            <svg className={classes.cursorPointer} data-test-id="openDrawer" onClick={this.handleOpenFilterDrawer}  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 18H14V16H10V18ZM3 6V8H21V6H3ZM6 13H18V11H6V13Z" fill="var(--website-builder-secondary-color)"/>
            </svg>
          </div>
        </div>

        {/* Filter Drawer */}
        <div>
          <Drawer
            open={this.state.filterDrawer}
            className={classes.drawer}
            anchor="left"
          >
            <div
              style={{ display: "flex", justifyContent: "space-between", padding: "30px", alignItems: "center" }}>
              <div
                className=
                {classes.filterHeadingDrawer}
              >
                Filter
              </div>
              <svg data-test-id="closeFilterDrawer" onClick={this.handleCloseFilterDrawer} className={classes.cursorPointer} width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.375 11.198L12.625 0.80127" stroke="var(--website-builder-secondary-color)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.625 11.198L1.375 0.80127" stroke="var(--website-builder-secondary-color)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div
              style={{ padding: "0px 30px 0px 30px" }}
            >
              <div
                style={cardStyle.foodLableContainer}
              >
                <span
                  style={cardStyle.foodLabelHeading}
                >
                  Food Label
                </span>
                {this.foodLabelDropdownfunction()}
              </div>
              <hr
                style={cardStyle.divider} />
            </div>
            <div
              style=
              {{ padding: "0px 30px 0px 30px" }}
            >
              <FormGroup>
                {this.state.foodLabelDropdown && this.state.foodLabelData.map((parent: any) => (
                  <Grid container key={parent.id}>
                    <Accordion
                      style={styles.viewAccordion}
                      data-test-id="accordionTest"
                      expanded={this.state.expandedLables == parent.name}
                      onChange={(event, newExanpanded) => this.onAccordianChange(newExanpanded, parent.name)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={styles.secondaryColor}/>}
                        className={classes.foodFilterStyle}
                        style={styles.viewAccordionSummary}
                      >
                        <Grid
                          item xs={12}>
                          <MenuItem
                            key={parent.id}
                            value={parent.value}
                            style={{ marginLeft: "-25px" }}
                            onClick={() => {
                              this.handleParentChange(parent.children.every((c: {value : string}) => this.state.selectedFoodLables.includes(c.value)), parent)
                            }}
                          >
                            <PurpleCheckbox
                              className={classes.menuItemCheckboxStyles}
                              checked={parent.children.every((c : {value : string}) => this.state.selectedFoodLables.includes(c.value))}
                              icon={
                                <span className={classes.iconStyles} />
                              }
                              checkedIcon={<span
                                className={classes.checkedIconStyles}
                              />}
                            />
                            <Grid
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Grid
                                className={classes.menuItemNameStyles}
                                style={{ fontSize: "14px" }}
                              >{parent.name}</Grid>
                            </Grid>
                          </MenuItem>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails
                        style={{ padding: "0px" }}
                      >
                        <Grid item xs={12} >
                          {parent.children.map((child: any) => (
                            <MenuItem
                              key={child.id}
                              value={child.value} className={classes.menuListStyles}
                              data-test-id="checkboxTest"
                              onClick={() => this.handleChildChange(this.state.selectedFoodLables.includes(child.value), parent, child)}
                            >
                              <PurpleCheckbox
                                className={classes.menuItemCheckboxStyles}
                                checked={this.state.selectedFoodLables.includes(child.value)}
                                icon={<span
                                  className={classes.iconStyles}
                                />}
                                checkedIcon={<span
                                  className={classes.checkedIconStyles}
                                />}
                              />
                              <Grid
                                style={
                                  {
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                              >
                                <Grid
                                  style={{
                                    marginRight: "5px",
                                  }}
                                >
                                  <img
                                    src={child.img} alt={child.name}
                                    style=
                                    {{
                                      height: "28px", width: "28px"
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  className={classes.menuItemNameStyles}
                                  style={{ fontSize: "14px" }}
                                >{child.name}</Grid>
                              </Grid>
                            </MenuItem>
                          ))
                          }
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}
              </FormGroup>
            </div>
            <div style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right", padding: "20px"
            }}>
              <Button
                style={styles.resetBtn}
                data-test-id="clearAllTest" onClick={this.handleClearAll}
              >
                Reset all
              </Button>
              <Button
                style={styles.applyBtn}
                onClick={this.handleApplyFilter} data-test-id="applyFiltersTest"
              >
                Apply
              </Button>
            </div>
          </Drawer>
        </div>
       {this.canvasData()}

        {/* Filter Dialog */}
        <div>
          <Dialog
            aria-labelledby="customized-dialog-title"
            open={this.state.filterModal}
            onClose={this.handleCloseFilter}
            closeAfterTransition
            data-test-id="filterDialogTest"
            fullWidth
            PaperProps={{
              style: {
                borderRadius: "10px",
                padding: "25px",
                width: "400px"
              }
            }}
          >
            <DialogTitle>
              <div style={cardStyle.filterHeadingContainer}>
                <span style={cardStyle.filterHeading}>
                  Filter
                </span>
                <div>
                <svg onClick={this.handleCloseFilter} style={cardStyle.cursorPointer} width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.375 11.198L12.625 0.80127" stroke="var(--website-builder-secondary-color)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.625 11.198L1.375 0.80127" stroke="var(--website-builder-secondary-color)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                </div>
              </div>

              <div style={cardStyle.foodLableContainer}>
                <span style={cardStyle.foodLabelHeading}>
                  Food Label
                </span>
                <div>
                  {this.state.foodLabelDropdown ? (
                    <svg className={cardStyle.cursorPointer} data-test-id="arrowUpTest" onClick={this.handleFoodLableClose} width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path  d="M1.625 14.3241L10.5583 5.39162C10.6754 5.27438 10.8343 5.2085 11 5.2085C11.1657 5.2085 11.3246 5.27438 11.4417 5.39162L20.375 14.3241" stroke="var(--website-builder-secondary-color)"  stroke-linecap="round" stroke-width="1.5" stroke-linejoin="round"/>
                    </svg>
                  ) : (
                    <svg className={cardStyle.cursorPointer} onClick={this.handleFoodLabelOpen} data-test-id="arrowDownTest" width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.625 5.67588L10.5583 14.6084C10.6754 14.7256 10.8343 14.7915 11 14.7915C11.1657 14.7915 11.3246 14.7256 11.4417 14.6084L20.375 5.67588" stroke="var(--website-builder-secondary-color)" stroke-width="1.5" stroke-linejoin="round" stroke-linecap="round"/>
                  </svg>
                  )}
                </div>
              </div>
              <hr style={cardStyle.divider} />
            </DialogTitle>
            <DialogContent style={{ padding: "0px 24px" }}>
              <FormGroup>
                {this.state.foodLabelDropdown && this.state.foodLabelData.map((parent: any) => (
                  <Grid container key={parent.id}>
                    <Accordion
                      style={styles.viewAccordion}
                      expanded={this.state.expandedLables == parent.name}
                      data-test-id="accordionTestDialog"
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon style={styles.secondaryColor} data-test-id={`expand-menu_${parent.name}`} onClick={() => this.handleOpenAccordion(parent.name)} />} className={classes.foodFilterStyle} style={styles.viewAccordionSummary}>
                        <Grid item xs={12}>
                          <MenuItem
                            key={parent.id}
                            value={parent.value}
                            style={{ marginLeft: "-25px" }}
                            onClick={() => {
                              this.handleParentChange(parent.children.every((c: any) => this.state.selectedFoodLables.includes(c.value)), parent)
                            }}
                            data-test-id={`check_${parent.name}`}
                          >
                            <PurpleCheckbox
                              className={classes.menuItemCheckboxStyles}
                              checked={parent.children.every((c: any) => this.state.selectedFoodLables.includes(c.value))}
                              icon={<span className={classes.iconStyles} />}
                              checkedIcon={<span className={classes.checkedIconStyles} />}
                            />
                            <Grid
                              className={classes.menuItemNameStyles}
                              style={styles.accordionMenuName}
                            >
                              {parent.name}
                            </Grid>
                          </MenuItem>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails style={{ padding: "0px" }}>
                        <Grid item xs={12}>
                          {parent.children.map((child: ItemMenuFilter) => {
                            return (
                              <MenuItem
                                key={child.id}
                                value={child.value}
                                className={classes.menuListStyles}
                                data-test-id={`checkboxTest`}
                                onClick={() => this.handleChildChange(this.state.selectedFoodLables.includes(child.value), parent, child)}
                              >
                                <PurpleCheckbox
                                  className={classes.menuItemCheckboxStyles}
                                  checked={(this.state.selectedFoodLables.includes(child.value))}
                                  icon={<span className={classes.iconStyles} />}
                                  checkedIcon={<span className={classes.checkedIconStyles} />}
                                />
                                <Grid style={styles.flexColumn}>
                                  <img
                                    src={child.img}
                                    alt={child.name}
                                    style={styles.imgFilter}
                                  />
                                  <Grid className={classes.menuItemNameStyles} style={styles.nameFilter}>{child.name}</Grid>
                                </Grid>
                              </MenuItem>
                            )
                          })}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}


              </FormGroup>
            </DialogContent>
            <DialogActions style={{ padding: "0px", marginTop: "10px" }}>
              <Button
                 style={styles.resetBtn}
                data-test-id="clearAllTest"
                onClick={this.handleClearAll}
              >
                Reset all
              </Button>
              <Button 
              style={styles.applyBtn}
                onClick={this.handleApplyFilter}
                data-test-id="applyFiltersTest"
              >
                Apply
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div className="gap-category" />
        {this.renderCategories(classes)}


          <Grid container justifyContent="space-around" style={{display:`${this.getStoreVisibility()}`}}>
            <Grid item className={`landing-page-card ${classes.storeMsgBox}`}>
              <Typography variant="h6" className={classes.storeMsgTitle}>{configJSON.storeCloseMsgTitle}</Typography>
              <Typography variant="h6" className={classes.storeMsg}>{this.state.storeClosedMessage}</Typography>
            </Grid>
          </Grid>
        

        <div id="landing-scroll" className={`landing-scroll ${this.getScrollClassForMozillaLanding()}`} style={{ minHeight: this.getLandingScrollHeight() }} onClick={()=>this.mixPanelEventHit("Item Card Click")}>
                {this.state.categorieItems.map((categorieItem: CartItem, index: number) => (
                  <Card
                    key={`card_${categorieItem.id}`}
                    className="landing-page-card"
                    style={styles.card}
                    data-test-id={`itemDetailsContainer_${index}`}
                    id={`itemDetailsContainer_${categorieItem.id}_${categorieItem.idGroup}`}
                    onClick={() => this.openItemDetails(categorieItem)}
                  >
                    <div style={cardStyle.cardContentStyles}>
                      <div className="landing-page-image-card">
                        <img className="landing-page-image-card" src={categorieItem.attributes.images.url} style={cardStyle.cardImageStyles} alt="Card" />
                      </div>
                      <div style={{ backgroundColor: "#E2E8F0", marginTop: "-16px" }} className="card-divider" />
                      <CardContent style={{ cursor: 'pointer', width: '100%', padding: "6px 16px" }}>
                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <span className="title-card" style={cardStyle.titleStyles}>
                            {categorieItem.attributes.name} 
                          </span>
                          <Box style={{ display: 'flex', marginLeft: "8px", width: "50%", justifyContent: "flex-end" }}>
                            {this.foodLabelBlock(categorieItem)}
                          </Box>
                        </Box>
                        <span style={cardStyle.paraStyles} className="landing-page-description-card">
                          {categorieItem.attributes.description}
                        </span>

                        <Grid style={styles.dflexCenter} >
                          {this.priceBlock(categorieItem)}
                          {categorieItem && categorieItem.attributes && categorieItem.attributes.availability && categorieItem.attributes.availability !== "out_of_stock" && <>
                            {(this.state.orderedItem.find((item: { id: number; }) => item && item.id === categorieItem.id)?.count && this.state.orderedItem.find((item: { id: number; }) => item && item.id === categorieItem.id)?.count !== 0) && !this.state.isStoreClosed && <>
                            <svg data-test-id="decrement" onClick={(event) => this.handleDecrement(event, index, categorieItem.id)} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="24" height="24" rx="4" fill="var(--website-builder-primary-color)"/>
                            <path d="M7.83333 11H16.1667C16.625 11 17 11.375 17 11.8333C17 12.2917 16.625 12.6667 16.1667 12.6667H7.83333C7.375 12.6667 7 12.2917 7 11.8333C7 11.375 7.375 11 7.83333 11Z" fill="white"/>
                            </svg>
                            &nbsp;</>}
                            {(this.state.orderedItem.find((item: { id: number; }) => item && item.id === categorieItem.id)?.count && this.state.orderedItem.find((item: { id: number; }) => item && item.id === categorieItem.id)?.count !== 0) && this.state.isStoreClosed && <><RemoveIcon data-test-id="staticRemoveBtn" fontSize="small" style={styles.incrementIcon} /></>}
                            {(this.state.orderedItem.find((item: { id: number; }) => item && item.id === categorieItem.id)) && <><Typography style={cardStyle.addItemCount} data-test-id="addedItemCount">{this.showCount(categorieItem.id)}</Typography>&nbsp;</>}
                            <img src={disablePlusAdd} style={{display:this.getDisablePlusAddVisibility(),...styles.btnDisable}} data-test-id="increment" />
                            
                            <svg style={{display:this.getEnablePlusAddVisibility(),...styles.btnEnables}}  data-test-id="increment" onClick={(event) => this.handleIncrement(event, categorieItem.id, categorieItem)} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="0.5" width="24" height="24" rx="4" fill="var(--website-builder-primary-color)"/>
                            <path d="M16.2857 13.2143H12.7143V16.7857C12.7143 17.1786 12.3929 17.5 12 17.5C11.6071 17.5 11.2857 17.1786 11.2857 16.7857V13.2143H7.71429C7.32143 13.2143 7 12.8929 7 12.5C7 12.1071 7.32143 11.7857 7.71429 11.7857H11.2857V8.21429C11.2857 7.82143 11.6071 7.5 12 7.5C12.3929 7.5 12.7143 7.82143 12.7143 8.21429V11.7857H16.2857C16.6786 11.7857 17 12.1071 17 12.5C17 12.8929 16.6786 13.2143 16.2857 13.2143Z" fill="white"/>
                            </svg>
                          </>}
                        </Grid>

                      </CardContent>
                    </div>
                  </Card>
                ))}
        </div>
        <div style={{display:this.getNoSearchVisibility(),...styles.noSearchBox}}>
              <NoSearchFound />
          <div style={styles.h300} />
        </div>

        <div style={{display:this.getFooterVisibility()}}>
          <AppFooter
            data-test-id="appFooter"
            socialMedias={this.state.socialMedias}
            staticPages={this.state.staticPages}
            handleEmailClick={this.handleOpenMail}
            countryCode={this.state.footerCountryCode}
            phoneNumber={this.state.footerPhoneNumber}
            handleWhatsApp={this.handleWhatsappNavigation}
            footerData={this.state.canvasData.footer}
            mixPanelHit={this.mixPanelEventHit}
          />
          <div className={this.getBottomSpacing()}/>
          {this.renderPlaceOrder()}
        </div>
        
        <ProductDetailDialogue
          open={this.state.isDetail}
          setClose={() => this.setCloseDetail()}
          currencyType={this.currencyType}
          selectedProduct={this.state.selectedProduct}
          variantSelect={this.variantSelect}
          variantRadio={this.variantRadio}
          currentPrice={this.state.tempStore}
          validationOptions={this.state.validationOptions}
          data-test-id="productDetailTest"
        />
        
      </div>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const styles: any = {
  cursorPointer: {
    cursor: "pointer"
  },
  imgFilter: { height: "28px", width: "28px", marginRight: "5px" },
  flexColumn: {
    display: "flex",
    alignItems: "center",
  },
  nameFilter: { fontSize: "14px" },
  arrowStyle: { width: "24px" },
  viewAccordion: { width: "100%", boxShadow: "none" },
  viewAccordionSummary: { padding: "0px" },
  secondaryColor: {
    color:"var(--website-builder-secondary-color)"
  },
  renderCategoriesStyle: {
    backgroundColor: '#F1F5F9',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: "8px 16px"
  },
  chipStyles: {
    marginRight: "5px",
    fontFamily: "var(--website-builder-body-font)",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    color: "var(--website-builder-primary-color)",
    background: "var(--website-builder-primary-light-accent-color)",
    padding: "4px 5px",
    marginTop: "4px"
  },
  card: {
    backgroundColor: '#D8DCE0',
    border: "1px solid var(--website-builder-secondary-light-accent-color)",
    boxShadow: "none",
    marginInline: "auto"
  },
  placeOrderText: {
    fontFamily: "var(--website-builder-body-font)",
    fontWeight: 700,
    color: '#5500CC',
    alignSelf: 'center'
  },
  iconPhone: {
    color: '#5500CC',
    marginRight: "8px",
    width: "22px",
    height: "22px",
  },
  notAvailableText: {
    color: "var(--website-builder-secondary-dark-accent-color)",
    fontFamily: "var(--website-builder-body-font)",
    fontStyle: "italic",
    fontWeight: 600,
    backgroundColor: "var(--website-builder-secondary-light-accent-color)",
    textAlign: "center",
    marginLeft: "6px",
  },
  menubar: {
    display: 'flex',
    justifyContent: 'center',
  },
  tab: {
    cursor: 'pointer',
    textAlign: 'center',
    fontFamily: "var(--website-builder-header-font)",
    borderBottom: '2px solid transparent',
    flexShrink: 0,
    padding: "16px 10px",
  },
  btnBack: {
    backgroundColor: '#ffff',
    borderRadius: '33px',
    cursor: 'pointer',
    color: '#64748B'
  },
  btnForwar: {
    backgroundColor: '#ffff',
    borderRadius: '33px',
    cursor: 'pointer',
    color: '#64748B'
  },
  resetBtn:{
    fontFamily: "var(--website-builder-body-font)",
    color: "var(--website-builder-secondary-color)",
    fontWeight: 400,
    lineHeight: "18px",
    fontSize: "12px",
    textTransform: "none"
  },
  
  applyBtn:{
    fontFamily: "var(--website-builder-body-font)",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "none",
    color: "#3C3E49",
    fontSize: "12px",
    padding: "10px 32px",
    background: "#00D659",
    borderRadius: "3px",
  },

  filterTextChip:{
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "var(--website-builder-primary-color)",
    marginRight: "8px",
    fontFamily: "var(--website-builder-body-font)",
  },
  accordionMenuName: {
    fontSize: "14px",
    width: "100%"
  },
  '@media (max-width: 768px)': {
    menubar: {
      width: '100%',
      overflowX: 'auto',
      WebkitOverflowScrolling: 'touch',
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    btnBack: {
      display: 'block',
    },
    btnForwar: {
      display: 'block',
    },
  },
  addToCartBtn: {
    textAlign: 'center',
    cursor: "pointer",
    width: "100%",
    fontFamily: "var(--website-builder-body-font)",
    fontWeight: 700,
    textTransform: 'capitalize',
    borderRadius: '8px',
  },
  btnEnable: {
    color: 'white',
    background: "linear-gradient(99.09deg, var(--website-builder-primary-dark-accent-color) 2.64%, var(--website-builder-primary-color) 100%)"
  },
  btnDisable: {
    pointerEvents: "none",
    background: "#F1F5F9",
    color: "#64748B"
  },
  btnEnables: {
    color: "#fff"
  },
  incrementIcon: {
    width: "24px",
    height: "24px",
    backgroundColor: "#CBD5E1",
    borderRadius: "4px",
    color: "#475569",
    marginRight: "4px",
    padding: "4px"
  },
  dflexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  orderActionContainer: {
    borderRadius: "16px 16px 0px 0px",
    display: 'flex',
    zIndex: 100000,
    position: "fixed",
    left: 0, bottom: 0,
    justifyContent: 'center',
    background: '#FFFFFF',
    width: '100%', 
    boxShadow: '0px -25px 50px 0px rgba(0, 0, 0, 0.09)'
  },
  noSearchBox:{ flexDirection: "column", alignItems: "center" },
  h300:{ height: "300px" }
};
const cardStyle: any = {
  cursorPointer: {
    cursor: "pointer"
  },
  "& .MuiTypography-body2": {
    fontFamily: "var(--website-builder-header-font)",
  },
  // Filter

  filterButton: {
    display: 'inline-flex',
    backgroundColor: 'white',
    borderRadius: '3px',
    textTransform: "capitalize",
    padding: "10px 20px 10px 20px",
    color: "var(--website-builder-secondary-color)",
    fontFamily: "var(--website-builder-body-font)",
  },
  filterHeading: {
    fontFamily: "var(--website-builder-body-font)",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "26px",
    color: "var(--website-builder-secondary-color)",
  },
  filterHeadingContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px"
  },
  foodLabelHeading: {
    fontFamily: "var(--website-builder-body-font)",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "26px",
    color:"var(--website-builder-secondary-color)"
  },
  foodLableContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  divider: {
    borderBottom: "1px solid #ECEDF0"
  },
  ///////////////////////
  mainContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto',
  },
  cardContainerStyles: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
    marginBottom: '10px'
  },
  cardImageStyles: {
    aspectRatio: "1/1",
    display: 'flex',
    padding: "8px",
    borderRight: '1px solid var(--website-builder-secondary-light-accent-color)',
  },
  cardContentStyles: {
    display: 'flex',
    borderRadius: '0px, 4px, 4px, 0px',
    alignItems: 'center',
    backgroundColor: '#ffffff',
  },
  titleStyles: {
    fontFamily: "var(--website-builder-header-font)",
    fontWeight: '700',
    color: 'var(--website-builder-secondary-color)',
    alignSelf: 'center',
    width: "50%"
  },
  paraStyles: {
    color: 'var(--website-builder-secondary-dark-accent-color)',
    fontWeight: 400,
    wordBreak: 'break-all',
    fontFamily: "var(--website-builder-body-font)",
  },
  addItemCount:{
    fontFamily: "var(--website-builder-body-font)"
  },
  foodLables: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0 2px",
    borderRadius: '2rem',
  },
  labelLength:{
    fontFamily: "var(--website-builder-body-font)",
    fontWeight: 500,
    color: "var(--website-builder-secondary-dark-accent-color)",
  },
  currecyText:{
    fontFamily: "var(--website-builder-body-font)",
    fontWeight: 700,
    color:"var(--website-builder-secondary-color)"
  },
  currecyType:{
    fontFamily: "var(--website-builder-body-font)",
    textDecoration: "line-through",
    fontWeight: 400,
    color: 'var(--website-builder-secondary-dark-accent-color)',
    marginLeft: '4px'
  },
  foodLablesCount: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 2px",
    border: "1px solid var(--website-builder-secondary-light-accent-color)",
    borderRadius: "50%",
  },
  saveBtn: {
    fontFamily: "var(--website-builder-body-font)",
    background: 'var(--website-builder-primary-light-accent-color)',
    borderRadius: '15px',
    color: 'var(--website-builder-primary-color)',
    fontWeight: '600',
    textAlign: 'center',
    minWidth: "65px"
  }
};

export const Styles = (theme: Theme) =>
  createStyles({
    cursorPointer: {
      cursor: "pointer"
    },
    appHeaderStyle:{
      top: 0, 
      position: "sticky",
      zIndex: 99
    },
    filterDrawer: {
      display: "none",
      "@media (max-width: 768px)": {
        display: "block",
        position: "absolute",
        top: "25%",
        left: "5%"
      }
    },
    renderCatgoriesNew: {
      position: "sticky",
      top: "55px",
    },
    filterHeadingDrawer: {
      fontFamily: "var(--website-builder-body-font)",
      fontSize: "18px",
      lineHeight: "18px",
      fontWeight: 700,
      color:'var(--website-builder-secondary-color)'
    },
    drawer: {
      "& .MuiDrawer-paper": {
        width: "100%",
      },
    },
    foodFilterStyle: {
      "& > div": {
        margin: "0px !important"
      },
      "& .MuiAccordionSummary-expandIcon": {
        padding: "0px"
      }
    },
    menuItemCheckboxStyles: {
      "&:hover": {
        background: "rgba(0, 0, 0, 0.08)",
      },
      "&.Mui-checked": {
        color: "#6200EA",
      },
    },
    marginLeft10:{
      marginLeft: "10px"
    },
    filterButtonDiv: {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      position: "absolute",
      left: "15%",
      '@media (max-width: 900px)': {
        left: "7.5%",
      },
      '@media (max-width: 768px)': {
        display: "none"
      }
    },
    iconStyles: {
      border: "1px solid #64748B",
      borderRadius: "3px",
      height: "20px",
      width: "20px",
    },
    checkedIconStyles: {
      backgroundColor: "var(--website-builder-primary-color)",
      borderRadius: "3px",
      "&:before": {
        display: "block",
        borderRadius: "3px",
        width: 20,
        height: 20,
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
      },
    },
    menuItemNameStyles: {
      fontFamily: "var(--website-builder-body-font)",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "19px",
      color: "var(--website-builder-secondary-color)",
    },
    menuListStyles: {
      "&:hover": {
        background: "#F6F0FF",
      },
      "&.Mui-selected": {
        background: "#F6F0FF",
      },
    },
    storeMsgBox: {
      backgroundColor: "#FFF",
      padding: "24px 20px 24px 20px",
      borderRadius: "12px",
      margin: "12px 8px 16px",
      boxShadow: " 0px 4px 8px 0px #00000008"
    },
    storeMsgTitle: {
      fontFamily: "var(--website-builder-header-font)",
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "28px",
      letterSpacing: "0em",
      textAlign: "center",
      color: "#DC2626",
    },
    storeMsg: {
      fontFamily: "var(--website-builder-body-font)",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      letterSpacing: "0em",
      textAlign: "center",
      color: "#64748B",
    },
    '@media (max-width: 900px)': {
      // left: "7.5%",
    },
    '@media (max-width: 768px)': {
      storeMsgTitle: {
        lineHeight: "22px",
        fontSize: "14px",
      },
      storeMsg: {
        fontSize: "12px",
        lineHeight: "18px",
      }
    },
    DesktopBannerWrapper:{
      border:'1px solid black',
      position:'relative',
      "@media (max-width: 450px)": {
        display:'none'
      }
    },
    desktopTextWrapper:{
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      fontWeight: 600,
      padding: 30,
      display: 'flex',
      whiteSpace: 'pre-line',
      overflow: 'hidden',
    },
    desktopBannerImage:{
      width: "100%",
      maxHeight: "480px"
    },
    MobileBannerWrapper:{
      display:'none',
      position:'relative',
      "@media (max-width: 450px)": {
        display:'block'
      }
    },
    mobileTextWrapper:{
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      fontWeight: 600,
      padding: 30,
      display: 'flex',
      whiteSpace: 'pre-line',
      overflow: 'hidden',
    },
    mobileBannerImage:{
      width: "100%",
      maxHeight: "344px"
    },
    bottomActionSpace:{
      marginBottom:120
    }

  })

export default withStyles(Styles)(LandingPageWeb)
// Customizable Area End
