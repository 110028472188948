import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { dialogBoxProps } from "../../ss-cms-common-components/src/HOC/withDialog.web";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";
// Customizable Area Start
import { Scrollbars } from "react-custom-scrollbars";
import { createRef } from "react"
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config.js");

export type Props = DialogProps &
  dialogBoxProps &
  withHeadeActionBarProps &
  withLoaderProps & {
    navigation: {
      navigate: (to: string, params: object) => void;
      getParam: (param: string) => string;
      goBack: () => void;
    };
    id: string;
    // Customizable Area Start
    classes?: {};
    onTextChange?: (value: string) => void;
    createId: string;
    updateData: ProductPreviewContentInterface;
    isApiCalled: boolean;
    categoryData: Array<CategoryData>;
    selectedSection:string;
    productData: Array<ProductData>;
    statesData?: any;
    sectionCategoryTitle: { [key: string]: string }
    // Customizable Area End
  };

export interface S {
  // Customizable Area Start
  showProductPreview: ProductPreviewContentInterface;
  createId: string;
  sectionDataN: Array<SectionDataMetadataObject>
  categoryData: Array<CategoryData>
  productData: Array<ProductData>
  mainId: string;
  CatagoryName: string;
  ProductName: string;
  //metaDataSection: Arra
  // Customizable Area End
}

// Customizable Area Start
export type MetadataBanner = {
  text_alignment: string
  header_text: string
  text_position: string
  font_colour: string
  font_size: string
}

export type SectionObject = {
  section_type: string;
  section_name: string;
  banner_url: string;
  mobile_banner_url: string;
  desktop_banner_url: string,
  is_deleted: boolean
}

export type SectionDataAttribute = {
  type: string;
  section_type: string;
  section_name: string;
  banner_url: string;
  mobile_banner_url: string;
  desktop_banner_url: string,
  mobileBannerUpload: string;
  desktopBannerUpload: string;
  mobileBannerMetadata: MetadataBanner
  desktopBannerMetadata: MetadataBanner
  is_deleted: boolean
}

export type SectionDataMetadataObject = {
  id: string;
  type: string;
  attributes: SectionDataAttribute
};
export interface CategoryData {
  category_image: string,
  category_name: string
}
export interface ProductData {
  product_name: string,
  product_image: string,
  new_rate: string,
  old_rate: string,
  currency: string,
  discount: string
}
export interface ProductPreviewContentInterface {
  [x: string]: any;

  footer: { [key: string]: number | boolean | string }
  sections: {
    data: Array<SectionDataMetadataObject>
  };
  header: {
    storeName: string,
    isStoreNameEnabled: boolean,
    isLogoUploaded?: boolean,
    selectedLogo?: any,
    isMobileMenuOpen?: boolean,
    navigationMenuOptions?: any,
    selectedFavicon?:any,
    selectedLogoName?:string,
    selectedFaviconName?:string,
  }
}



export interface SS {
  id: string;
}
// Customizable Area End

class ProductPreviewController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  scrollRef: React.RefObject<Scrollbars>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.scrollRef = createRef();
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      showProductPreview: {
        header: {
          storeName: "",
          isStoreNameEnabled: false,
        },

        sections: {
          data: [

          ]
        },
        footer: {
          phone_number: 5656565656,
          copyright: "",
          facebook_url: "",
          x_url: "",
          youtube_url: "",
          linkedin_url: "",
          appstore_url: "",
          playstore_url: "",
          instagram_url: "",
          design_id: "",
          is_social_media: false,
          is_app_link: false,
          is_contact: false
        }

      },
      createId: "",
      sectionDataN: [],
      categoryData: [],
      productData: [],
      mainId: "",
      CatagoryName: "Categories",
      ProductName: "Products Display"
    }


    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    let id = await getStorageData("id")
    this.setState({
      mainId: id
    })
  }
  scrollToTarget = () =>{
    let section;

    if (this.props.selectedSection === 'header') {
        section = "headerUnique";
    } else if (this.props.selectedSection === 'footer') {
        section = "footerUnique";
    } else if (this.props.selectedSection === 'section') {
        section = "headerUnique";
    } else {
        section = "";
    }
    let targetElement = document.getElementById(section);

    if (targetElement) {
      // Scroll to the target element
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }

  getBannerTextPosition = (section: SectionDataAttribute, platform: 'desktop' | 'mobile') => {
    let positions = ["center", "center"];
    const textPosition = section[platform === 'desktop' ? 'desktopBannerMetadata' : 'mobileBannerMetadata'].text_position;
    
    if (textPosition === "Top") positions = ["center", "flex-start"];
    else if (textPosition === "Top Left") positions = ["flex-start", "flex-start"];
    else if (textPosition === "Top Right") positions = ["flex-end", "flex-start"];
    else if (textPosition === "Center") positions = ["center", "center"];
    else if (textPosition === "Mid Left") positions = ["flex-start", "center"];
    else if (textPosition === "Mid Right") positions = ["flex-end", "center"];
    else if (textPosition === "Bottom") positions = ["center", "flex-end"];
    else if (textPosition === "Bottom Right") positions = ["flex-end", "flex-end"];
    else if (textPosition === "Bottom Left") positions = ["flex-start", "flex-end"];
    
    return { justifyContent: positions[0], alignItems: positions[1] }
  }

  // Customizable Area End
}

export default ProductPreviewController;
