// Customizable Area Start
import React from "react";
import ImageCatalogueController from "./ImageCatalogueController";
import { CatalogueFormatted, Tab } from "./Types";
import MuiTabs from "@material-ui/core/Tabs";
import MuiTab from "@material-ui/core/Tab";
import CatalogueItem from "./CatalogueItem.web";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import "./styles.css";
import Pagination from "../../ss-cms-common-components/src/Pagination/Pagination";
import TableComponent from "../../ss-cms-common-components/src/TableComponent/TableComponent";
import { Scrollbars } from "react-custom-scrollbars";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout"
export const configJSON = require("./config.js");

const titles = [configJSON.textTableHeaderImage, configJSON.textTableHeaderName, configJSON.textTableHeaderLastUpdate];

export class ImageCatalogue extends ImageCatalogueController {

  render() {
    const {
      headerTabs,
      selectedTab,
      selectedViewType,
      catalogues,
      allCatalogues,
      meta,
      pageInitialized,
      checkedImageCatalogueList,
    } = this.state;

    if (!pageInitialized) {
      return <div />;
    }
    
    return (
      <Scrollbars ref={this.scrollRef}>
        <div className="image-catalogue">
          <Header
            showButtons={true}
            onNavigate={this.handleNavigate}
            checkedImageCatalogueList={checkedImageCatalogueList}
            handleDeleteModal={this.handleDeleteModal}
            selectedViewType={selectedViewType}
            totalCount={!!meta.pagination?.total_count}
          >
            <div className="tabs-wrapper">
              <Tabs value={selectedTab} headerTabs={headerTabs} />
            </div>
          </Header>
          <div className="content">
            {!meta.pagination?.total_count ? (
              <NoProductCard onClick={this.handleNavigate} />
            ) : (
              <>
                <div className="content-header">
                  <div className="view-wrapper">
                    <MuiTabs
                      value={selectedViewType}
                      classes={{
                        flexContainer: "view-type-wrapper",
                        indicator: "view-type-indicator",
                      }}
                      onChange={this.handleSelectedViewTypeChange}
                    >
                      <ViewTypeTab data-testid="image-catalogue-grid-view">
                        <i className="icon-grid" />
                        <span>{configJSON.gridView}</span>
                      </ViewTypeTab>
                      <ViewTypeTab data-testid="image-catalogue-list-view">
                        <i className="icon-list" />
                        <span>{configJSON.listView}</span>
                      </ViewTypeTab>
                    </MuiTabs>
                  </div>
                </div>

                <div
                  className={`catalogues ${selectedViewType === 0 ? "grid-view" : "list-view"
                    }`}
                >
                  {selectedViewType === 0 ? (
                    allCatalogues.map((catalogue: CatalogueFormatted) => (
                      <CatalogueItem
                        key={catalogue.id}
                        data={catalogues[catalogue.id]}
                        onDelete={this.handleDelete}
                        onEdit={this.goToEdit}
                      />
                    ))
                  ) : (
                    <TableComponent
                      titles={titles}
                      data={allCatalogues}
                      getCheckedItems={(item) => this.checkedItems(item)}
                      checkbox
                      imageBorderRadius={0}
                      bodyCellPadding="10px"
                      edit
                      handleEditClick={this.goToEdit}
                      showIds={false}
                    />
                  )}
                </div>
                <div className="pagination-wrapper">
                  <Pagination
                    count={meta.pagination.total_count}
                    onChange={this.handlePageChange}
                    pageSize={this.state.rowPerPage}
                    itemText="images"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </Scrollbars>
    );
  }
}

const ViewTypeTab = ({ children, ...props }: { [key: string]: React.ReactNode }) => {
  return (
    <MuiTab
      {...props}
      classes={{
        selected: "view-type-selected",
        wrapper: "view-type-text-wrapper",
        root: "view-type-root",
      }}
      label={children}
    />
  );
};

interface HeaderProps {
  children?: React.ReactNode;
  showButtons: boolean;
  onNavigate: (routeTo: string) => void;
  handleDeleteModal: () => void;
  selectedViewType: number;
  checkedImageCatalogueList: CatalogueFormatted[];
  totalCount: boolean;
}

export const Header = ({
  children,
  showButtons,
  selectedViewType,
  checkedImageCatalogueList,
  handleDeleteModal,
  onNavigate,
  totalCount,
}: HeaderProps) => {
  const handleButton = (routeTo: string) => () => {
    onNavigate(routeTo);
  };
  return (
    <div className="header">
      <div className="header-title-wrapper">
        <span className="header-title">{configJSON.imageCatalogue}</span>
        {selectedViewType === 0
          ? ""
          : checkedImageCatalogueList?.length > 0 && (
            <p
              className="delete-items"
              onClick={handleDeleteModal}
              data-testid="delete-selected-images-button"
            >
              {configJSON.deleteImages}
            </p>
          )}
        {showButtons && totalCount && (
          <div className="header-buttons">
            <button
              className="add-image-button"
              onClick={handleButton(configJSON.imageCatalogueUrl)}
            >
              {configJSON.addImage}
            </button>
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

interface TabProps {
  onTabChange?: (event: React.ChangeEvent<{}>, value: {}) => void;
  value: number;
  headerTabs: Tab[];
}

const Tabs = ({ onTabChange, value, headerTabs }: TabProps) => {
  return (
    <MuiTabs
      classes={{
        root: "tabs-root",
        indicator: "tabs-indicator",
        flexContainer: "tabs-flex-container",
      }}
      value={value}
      onChange={onTabChange}
    >
      {headerTabs.map((headerTab) => (
        <MuiTab
          key={headerTab.tab_name}
          classes={{
            root: "tabs-root",
            labelIcon: "tab-label-icon",
            selected: "tab-label-selected",
          }}
          label={`${headerTab.tab_name} (${headerTab.count})`}
        />
      ))}
    </MuiTabs>
  );
};

export const NoProductCard = ({ onClick }: { onClick: (routeTo?: string | undefined) => void }) => {
  return (
    <div className="no-product-card">
      <div className="no-product-card-content">
        <span className="no-product-card-title">
          {configJSON.emptyPageTitle}
        </span>
        <span className="no-product-card-hint">
          {configJSON.emptyPageMessage}
        </span>
        <button
          onClick={() => onClick()}
          className="no-product-card-bulk-button"
        >
          {configJSON.emptyPageButtonLabel}
        </button>
      </div>
    </div>
  );
};

export default withLayout(withDialog(withLoader(withHeaderBar(ImageCatalogue))));
// Customizable Area End