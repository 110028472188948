import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {getStorageData} from '../../../framework/src/Utilities';

import { 
  isTokenExpired, 
  logoutAndRedirectToLoginPage,
  showError 
} from "../../ss-cms-common-components/src/Utilities/Utilities";
import { DialogProps } from '../../ss-cms-common-components/src/Dialog/Dialog'
type TName = "apiKey" | "secretApiKey" | "status";
interface IShipRocketAPICard {
  id: string;
  type: string;
  attributes: {
      id: number;
      name: string;
      description: string;
      active_status: number;
      image: {
        image:string;
      };
      credentials:{
        id:number;
        api_key:string;
        secret_key:string;
      }[]
  };
}

interface ICard {
  id: number;
  image: string;
  apiKey: string;
  secretApiKey: string;
  status: boolean;
  secretId: number;
  description: string;
  name: string;
}
interface IKeyData {
  apiKey: string,
  secretApiKey: string,
  status: boolean | string,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  showLoader:() => void;
  displaySpinner:() => void;
  hideLoader:() => void;
  setDialogState: (newState: boolean, dialogProps: DialogProps) => void;
  showToast:(obje:{type:"success"|"error",message:string}) => void;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  openModal: boolean;
  keysData: IKeyData;
  errorData: IKeyData;
  isAddPressedOnce: boolean;
  isShipRocketChecked:boolean;
  isAPIKeyAlreadyAdded:boolean;
  shipRocketList:ICard[];
  editCard: null | ICard;
  selectedCountryInStore: {
    id:number | null
  } | null;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Storecontentmanagement2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  shipRocketListApiCallId:string = '';
  shipRocketDeleteApiCallId:string = '';
  shipRocketUpdateApiCallId:string = '';
  shipRocketAddApiCallId:string = '';
  shipRocketSelectedCountryApiCallId:string = "";
  shipRocketUpdateStatusApiCallId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      openModal: false,
      keysData: {
        secretApiKey: "",
        apiKey: "",
        status:true,
      },
      errorData: {
        secretApiKey: "",
        apiKey: "",
        status:"",
      },
      isAddPressedOnce: false,
      isShipRocketChecked: true,
      isAPIKeyAlreadyAdded: false,
      shipRocketList: [],
      editCard: null,
      selectedCountryInStore:null,
     
  
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if(message.id === getName(MessageEnum.RestAPIResponceMessage)){
      if (isTokenExpired(message)) {
        return logoutAndRedirectToLoginPage(this.props);
      }
      this.props.hideLoader();
      const { apiResponseData } = this.getRosponseDataFromMessage(message);
      (apiResponseData === this.shipRocketListApiCallId) && this.handleShipRocketListMsg(message);
      (apiResponseData === this.shipRocketDeleteApiCallId) && this.handleShipRocketDeleteMsg(message);
      (apiResponseData === this.shipRocketUpdateApiCallId) && this.handleShipRocketUpdateMsg(message);
      (apiResponseData === this.shipRocketSelectedCountryApiCallId) && this.handleSelectedCoutry(message);
      (apiResponseData === this.shipRocketUpdateStatusApiCallId) && this.handleStatusUpdateMsg(message);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  toggleModal = () => {
    this.setState(prev => ({ openModal: false, editCard: null, isAddPressedOnce: false }));
  }

  getRosponseDataFromMessage = (message:Message) => {
    const apiResponseData = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const apiSuccessMessageData = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiErrorMessageData = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    return ({ apiResponseData, apiSuccessMessageData, apiErrorMessageData });
  }

  handleShipRocketListMsg = (message:Message) => {   
    const { apiErrorMessageData, apiSuccessMessageData } = this.getRosponseDataFromMessage(message);
    this.props.hideLoader();
      if (apiSuccessMessageData.data && !apiErrorMessageData) {
        this.updateShipRocketList(apiSuccessMessageData.data);
      } else {
        showError(configJSON.netWorkErrorMessage,apiSuccessMessageData,this.props.hideLoader,this.props.setDialogState);
      }
      
  }

  handleSelectedCoutry = (message:Message) => {
    const { apiErrorMessageData, apiSuccessMessageData } = this.getRosponseDataFromMessage(message);
    this.props.hideLoader();
      if (apiSuccessMessageData.data && !apiErrorMessageData) {

        const id = apiSuccessMessageData.data.attributes?.country_id ? apiSuccessMessageData.data.attributes.country_id : null;
        this.setState({ selectedCountryInStore: { id } });
      }
      else {
        showError(configJSON.netWorkErrorMessage,apiSuccessMessageData,this.props.hideLoader,this.props.setDialogState);
      }
  }

  handleShipRocketUpdateMsg = (message: Message) => {
    const { apiErrorMessageData,  apiSuccessMessageData } = this.getRosponseDataFromMessage(message);
    this.props.hideLoader();
    if (apiSuccessMessageData?.data && !apiErrorMessageData) {
      this.props.showToast({ type: "success", message:  configJSON.updateSuccessMessage});
      this.fetchShipRocketListFromServer();
    } else {
      this.props.showToast({ type: "error", message:  configJSON.updateFailureMessage});
    }
  }

  handleStatusUpdateMsg = (message:Message) => {
    const { apiErrorMessageData,  apiSuccessMessageData } = this.getRosponseDataFromMessage(message);
    this.props.hideLoader();
    if (apiSuccessMessageData?.data && !apiErrorMessageData) {
      this.props.showToast({ type: "success", message:  configJSON.updateSuccessMessage});
      this.fetchShipRocketListFromServer();
    } else {
      this.props.showToast({ type: "error", message:  configJSON.updateFailureMessage});
    }
  }

  navigateToStoreDetailsPage = () => {
    const navigateMessage = new Message(getName(MessageEnum.NavigationMessage));
    navigateMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    navigateMessage.addData(getName(MessageEnum.NavigationTargetMessage), `AdminStoreDetails`);

    this.send(navigateMessage);
  }

  handleShipRocketDeleteMsg = (message: Message) => {
    const { apiErrorMessageData,  apiSuccessMessageData } = this.getRosponseDataFromMessage(message);
    this.props.hideLoader();
    if (apiSuccessMessageData.message && !apiErrorMessageData) {
      this.fetchShipRocketListFromServer();
      this.props.showToast({ type: "success", message: configJSON.deleteSuccessMessage })
    } else {
      this.props.showToast({ type: "error", message:  configJSON.deleteFailureMessage})
    }
   
  }

 
  handleCheckboxChange = async (event:React.ChangeEvent<HTMLInputElement>,card:ICard) => {
    const shipRocketMessage = await this.makeMessage(
      `${configJSON.getShiprocketListEndpoint}/${card.id}?active_status=${!card.status}`,
      configJSON.putAPIMethodType);
    this.shipRocketUpdateStatusApiCallId = shipRocketMessage.messageId;
    runEngine.sendMessage(shipRocketMessage.id, shipRocketMessage);
  }


  handleAdd = async () => {
    const { keysData, editCard } = this.state
    this.setState({ isAddPressedOnce: true });
    if (this.checkErrorData() && editCard) {
      const endPoint = (editCard.apiKey && editCard.secretApiKey) ?
        `${configJSON.addOrUpdateEndPoint}/${editCard.secretId}`
        : configJSON.createEndPoint;
      const method = (editCard.apiKey && editCard.secretApiKey) ? configJSON.putAPIMethodType : configJSON.postAPIMethodType;
      const formdata = new FormData();
      formdata.append("api_key", keysData.apiKey);
      formdata.append("secret_key", keysData.secretApiKey);
      if(!editCard.apiKey && !editCard.secretApiKey){
        formdata.append("delivery_partner_id", (editCard.id).toString());
      }
      const shipRocketMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      const token = await getStorageData(configJSON.adminToken);
      const headers = {
        token: token,
      };
      shipRocketMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
      shipRocketMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
      shipRocketMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
      shipRocketMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);
      this.shipRocketUpdateApiCallId = shipRocketMessage.messageId;

      runEngine.sendMessage(shipRocketMessage.id, shipRocketMessage);
      this.toggleModal();
      this.props.displaySpinner();
    }
  }

  handleEdit = (card:ICard) => {
    this.setState({
      keysData: { apiKey: card.apiKey, secretApiKey: card.secretApiKey, status: card.status },
      editCard: card,
      openModal: true,
    }, this.intialCheckOfErrors)
  }

  handleDelete = async () => {
    const {editCard} = this.state
    const endPoint = `${configJSON.addOrUpdateEndPoint}/${editCard?.secretId}`;
    const shipRocketMessage: Message = await this.makeMessage(endPoint, configJSON.deleteAPIMethodType);
    this.shipRocketDeleteApiCallId = shipRocketMessage.messageId;
    runEngine.sendMessage(shipRocketMessage.id, shipRocketMessage);
    this.toggleModal();
    this.props.displaySpinner();
  }

  handleErrorCheck = (name:TName,value:string) => {
    if (name === "status") return;
    this.setState(prev => ({ errorData: { ...prev.errorData, [name]: value.length > 0 ? "" : "*required" } }))
  }

  checkErrorData = () => {
    return Object.values(this.state.errorData).every(value => value === "");
  }

  handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const {name,value} = event.target;
    this.setState(prev =>({keysData:{...prev.keysData,[name]:value}}),()=>{
      this.handleErrorCheck(name as TName,value);
    })
  }

  intialCheckOfErrors = () => {
    Object.entries(this.state.keysData).forEach(([name, value]) => {
      this.handleErrorCheck(name as TName, value)
    })
  }

  updateShipRocketList = (data: IShipRocketAPICard[]) => {
      const newList: ICard[] = data.map((each, index: number) => {
      let apiKey: string = "";
      let id: number = index;
      let image: string = "";
      let secretApiKey: string = "";
      let secretId: number = index;
      let status: boolean = false;
      let name: string = '';
      let description: string = "";
      if (each.attributes) {
        (each.attributes.active_status) && (status = !!each.attributes.active_status);
        (each.attributes.description) && (description = each.attributes.description);
        (each.attributes.id) && (id = each.attributes.id);
        (each.attributes.image && each.attributes.image.image) && (image = each.attributes.image.image);
        (each.attributes.name) && (name = each.attributes.name);
        const credentials = each.attributes.credentials[each.attributes.credentials.length-1];
        if (credentials) {
          (credentials.api_key) && (apiKey = credentials.api_key);
          (credentials.secret_key) && (secretApiKey = credentials.secret_key);
          (credentials.id) && (secretId = credentials.id);
        }
      }

      return { apiKey, id, image, secretApiKey, secretId, status, name, description }
    })
    this.setState(({ shipRocketList: [...newList] }));
  }

  makeMessage = async (endPoint:string,method:string,) => {
    const shipRocketMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const token = await getStorageData(configJSON.adminToken);
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    shipRocketMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    shipRocketMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    shipRocketMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    return shipRocketMessage;
  }

  fetchShipRocketListFromServer = async () => {
    const shipRocketMessage = await this.makeMessage(
      configJSON.getShiprocketListEndpoint,
      configJSON.getAPIMethodType);

    this.shipRocketListApiCallId = shipRocketMessage.messageId;
    runEngine.sendMessage(shipRocketMessage.id, shipRocketMessage);
  }

  fetchIsCountrySelectedInTheStore = async () => {
    const shipRocketMessage = await this.makeMessage(
      configJSON.getSelectedCountry,
      configJSON.getAPIMethodType);

    this.shipRocketSelectedCountryApiCallId = shipRocketMessage.messageId;
    runEngine.sendMessage(shipRocketMessage.id, shipRocketMessage);
  }

  async componentDidMount(): Promise<void> {
    this.fetchShipRocketListFromServer();
    this.fetchIsCountrySelectedInTheStore();
    this.intialCheckOfErrors();
  }
  // Customizable Area End
}
