// Customizable Area Start
import React from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import ColorPicker from "material-ui-color-picker";
import { AppSettingsFormController } from "./AppSettingsFormController.web";
import withHeaderActionBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";

const configJSON = require("./config.js");
const colorPickerIcon = require("../assets/colorpicker.svg");

export class AppSettingsForm extends AppSettingsFormController {
  render() {
    const { primaryColor, appName, androidAppLink, iosAppLink } = this.state;
    return (
      <>
        <div className="store-detail-container justify-content-between">
          <label className="label-store-details">
            {configJSON.customiseApp}
          </label>
          <div>
            <ColorPicker
              disabled
              variant="standard"
              value={primaryColor}
              onChange={this.handleCustomPrimaryChange}
              name="primaryColor"
              InputProps={{
                className: "width0",
                startAdornment: (
                  <InputAdornment position="start">
                    <section className="colorBox"
                      style={{ background: primaryColor }}
                    />
                    <span style={webStyles.chooseColor}>
                      {configJSON.chooseColor}
                    </span>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <div className="store-detail-container address-container justify-content-between">
          <label className="label-store-details">
            {configJSON.generalSettings}
          </label>

          <div className="d-flex flex-column pb-24">
            <label className="input-label">{configJSON.appNameLabel}</label>
            <TextField
              className="inputASF"
              data-testid={"text-field-appName"}
              name="appName"
              value={appName || ""}
              placeholder={configJSON.appNameLabel}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleTextFieldChange(event, "appName")}
              variant="outlined"
            />
          </div>

          <div className="d-flex flex-column pb-24">
            <label className="input-label">{configJSON.googlePlayStoreURLLabel}</label>
            <TextField
              className="inputASF"
              data-testid={"text-field-androidAppLink"}
              name="androidAppLink"
              value={androidAppLink || ""}
              placeholder={configJSON.googlePlayStoreURLLabel}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleTextFieldChangeEmails(event, "androidAppLink", 150)}
              variant="outlined"
            />
            {this.state.errorMessageGoogle && <div style={webStyles.error}>{this.state.errorMessageGoogle}</div>}
          </div>

          <div className="d-flex flex-column pb-24">
            <label className="input-label">{configJSON.appleStoreURLLabel}</label>
            <TextField
              className="inputASF"
              data-testid={"text-field-iosAppLink"}
              name="iosAppLink"
              value={iosAppLink || ""}
              placeholder={configJSON.appleStoreURLLabel}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleTextFieldChangeEmails(event, "iosAppLink", 150)}
              variant="outlined"
            />
            {this.state.errorMessageIos && <div style={webStyles.error}>{this.state.errorMessageIos}</div>}
          </div>
        </div>
      </>
    );
  }
}

const webStyles = {
  chooseColor: {
    fontWeight: 700,
    fontFamily: "Inter",
    fontSize: "14px",
    color: "#3C3E49",
    marginLeft: "10px"
  },
  error: {
    marginTop: "7px",
    color: "red"
  }
}

export default withDialog(withHeaderActionBar(withLoader(AppSettingsForm)));
// Customizable Area End