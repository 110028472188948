import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export function createRequest(request: {
    requestMsg: Message;
    endPoint: string;
    header?: object;
    method: string;
    token?: string;
    body?: string | FormData;
    isFormDataRequest?: boolean;
}) {
    const {
        requestMsg: apiMessage,
        endPoint: apiEndPoint,
        header: initHeader,
        method: apiMethod,
        token: apiToken,
        body: bodyRequest,
    } = request;
 
    const convertHeader = {
        Token: apiToken ?? undefined,
        "Content-Type": "application/json",
    }
 
    apiMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        apiEndPoint,
    );
 
    apiMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(convertHeader),
    );
 
    apiMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        apiMethod,
    );
 
    bodyRequest &&
    apiMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        bodyRequest,
    );
 
    runEngine.sendMessage(apiMessage.id, apiMessage);
}