import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { withToastProps } from "../../ss-cms-common-components/src/HOC/withSnackBar.Web";
import { generateRequestMessage, isTokenExpired, clearStorageData,showError } from "../../ss-cms-common-components/src/Utilities/Utilities";

export const configJSON = require("./config.js");

export type Props =
  DialogProps &
  withHeadeActionBarProps &
  withLoaderProps &
  withToastProps & {
    navigation: {
      navigate: (to: string, params: object) => void;
      getParam: (param: string) => string;
      goBack: () => void;
    };
    id: string;
  };

interface S {
  redirected: boolean;
  imageName: string;
  selectedFile: File | null;
  selectedImage: string;
  product: Attributes;
  value: string;
  categoryError: string;
  categoryImageError: string;
}

interface SS {
  id: string;
}

export interface Attributes {
  id: number;
  created_at: string;
  updated_at: string;
  url: string;
  name: string;
}


export default class EditImageController extends BlockComponent<Props, S, SS> {
  updateImageApiCallId: string = "";
  fetchImageApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
      categoryError: "",
      redirected: false,
      imageName: "",
      selectedFile: null,
      selectedImage: "",
      product: {} as Attributes,
      value: "",
      categoryImageError:""
    };
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.ActionMessageFromToaster),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchImage();
  };

  async componentWillUnmount() {
    super.componentWillUnmount();
    this.props?.hideHeaderBar?.();
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.updateImageApiCallId === apiRequestCallId) {
        if (responseJson.errors) {
          return this.handleError(configJSON.errorOnBulkUpload, responseJson);
        }
        else{
          this.handleAddUpdateResponse();
        }
       
      }
      if (this.fetchImageApiCallId === apiRequestCallId) {
        let product = responseJson.data.attributes;
        this.setState({
          product,
          imageName: product.name,
          selectedImage: product.url,
        });
        this.props.hideLoader();
      }
    } else if (getName(MessageEnum.ActionMessageFromToaster)) {
      this.handleToasterMessageResponse(message);
    }
  }

  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  handleAddUpdateResponse = () => {
    this.props.showHeaderBar({
      message: configJSON.categoryUpdateMessage,
      type: "success",
    });
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "AdminImageCatalogue"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  handleToasterMessageResponse = (message: Message) => {
    const type = message?.getData(
      getName(MessageEnum.ActionMessageFromToasterMessage)
    );
    if (type === "SAVECHANGES") {
      this.updateImage();
    } else if (type === "DISCARDCHANGES") {
      this.fetchImage();
    }
  };

  fetchImage = async () => {
    let productId = this.props.navigation?.getParam("id");
    this.props?.showLoader?.();
    const requestMessage = await generateRequestMessage(
      `${configJSON.endPoint}/${productId}`,
      configJSON.getApiMethod
    );
    this.fetchImageApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  selectImageFile = (files: File[]) => {
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({
        imageName: files[0].name,
        selectedImage: reader.result as string,
        selectedFile: files[0],
        categoryImageError:'',
        categoryError:'',
      },()=> this.openToastOnChange(false));
    };
    reader.readAsDataURL(files[0]);
  };

  updateImage = async () => {
    const requestMessage = await generateRequestMessage(
      `${configJSON.endPoint}/${this.state.product.id}`,
      configJSON.putAPiMethod,
      {
        "content-type": undefined,
      }
    );
    const formData = new FormData();
    this.state.selectedFile &&
      formData.append("image[file]", this.state.selectedFile);
    !!this.state.imageName &&
      formData.append("image[name]", this.state.imageName);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    this.updateImageApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onCategoryChange = (value: string) => {
    this.setState({ imageName: value });
    if (!value.trim()) {
      this.setState({ categoryError: configJSON.requiredField },()=>this.openToastOnChange(true));
    } else if (value.trim().length > 50) {
      this.setState({
        categoryError: configJSON.imageNameTooLong,
      },()=>this.openToastOnChange(true));
    } else {
      this.setState({ categoryError: "" },()=>this.openToastOnChange(false));
    }
    
  };

  openToastOnChange = (value:boolean) => {
    this.props.showHeaderBar({
      message: "",
      type: "",
      enable:value
    });
  };

  handleBackButtonClick = () => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "AdminImageCatalogue"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
    this.setState({ redirected: true })
  };

  removeLogoImg = () => {
    this.setState(
      {
        selectedFile: null,
        selectedImage: "",
        categoryImageError:configJSON.requiredField
      },
      () => {
        this.openToastOnChange(true);
      }
    );
  };
  handleError = (title: string, responseJson: {}) => {
    showError(
      title,
      responseJson,
      this.props.hideLoader,
      this.props.setDialogState
    );
  };
}
