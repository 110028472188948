// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";
import { RouterProps } from "react-router";
import { clearStorageData, generateRequestMessage, isTokenExpired, showError } from "../../ss-cms-common-components/src/Utilities/Utilities";
import { getStorageData } from "../../../framework/src/Utilities";
import { emitter } from "../../../../packages/blocks/ss-cms-common-components/src/Layout/Layout";
const configJSON = require("./config.js");

export type Props = RouterProps &
  DialogProps &
  withLoaderProps &
  withHeadeActionBarProps & {
    // Customizable Area Start
    navigation: {
      navigate: (to: string, params: object) => void;
      getParam: (param: string) => string;
      goBack: () => void;
    };
    id: string;
    showToast:(obj:{type:"success"|"error",message:string})=>void;
    // Customizable Area End
  };

const countries = {
  in: "India",
  ae: "United Arab Emirates",
  gb: "United Kingdom",
  us: "United States",
};

export type BrandCountryType = {
  country: string;
  country_id: number;
  country_code: string;
};

interface S {
  // Customizable Area Start
  view: number;
  brandCountryData: BrandCountryType;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

class ShippingController extends BlockComponent<Props, S, SS> {
  countryDataApiCallId: string = "";
  getOrderNotificationId: string = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ActionMessageFromToaster),
    ];

    this.state = {
      view: 0,
      brandCountryData: {} as BrandCountryType,
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.countryDataApiCallId) {
        if (responseJson?.data) {
          const { country_code, country_id } = responseJson?.data?.attributes;
          const brandCountryData = {
            country_code,
            country_id,
            country: countries[country_code as "gb" | "in" | "ae" | "us"],
          };
          this.setState({
            ...this.state,
            brandCountryData: brandCountryData,
          });
        } else {
          showError(configJSON.textErrorOnInitialize,responseJson, this.props.hideLoader, this.props.setDialogState)
        }
        this.props.hideLoader();
      } else if (apiRequestCallId == this.getOrderNotificationId) {
         emitter.emit("changeNotiNumber", responseJson.new_order_count)
      }
    }
  }

  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  getOrderNotification = async () => {
    const token = await getStorageData("admintoken");
    const header = {
      "Content-Type": 'application/json',
      token
    };
    const requestSAMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getOrderNotificationId = requestSAMessage.messageId;
    requestSAMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "/bx_block_order_management/orders/new_order_count");
    requestSAMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestSAMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestSAMessage.id, requestSAMessage);
  }

  async componentDidMount() {
    this.getCountryData();
    this.getOrderNotification();
  }
  
  async componentWillUnmount(): Promise<void> {
    super.componentWillUnmount();
  }

  setViewState(view: number) {
    this.setState({ view });
  }

  async getCountryData() {
    const getCountryData = await generateRequestMessage(
      configJSON.countryDataApi,
      configJSON.getMethod
    );
    this.countryDataApiCallId = getCountryData.messageId;
    runEngine.sendMessage(getCountryData.id, getCountryData);
  }

  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setViewState(newValue);
  };
}

export default ShippingController;
// Customizable Area End